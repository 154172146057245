import React, { useEffect, useMemo, useState } from "react";

import { Typography, Button, List } from "antd";
import GoodFaithTermsModal from "./components/GoodFaithTermsModal";
import GoodFaithPolicyModal from "./components/GoodFaithPolicyModal";
import { useDispatch, useSelector } from "react-redux";
import { getAllGoodFaithPolicies } from "services/misc.service";
import GoodFaithPolicyCard from "./components/GoodFaithCard";

const { Text, Link } = Typography;

export default function GoodFaith(props) {
  const dispatch = useDispatch();

  const [showGoodFaithTermsModal, setShowGoodFaithTermsModal] = useState(false);
  const [showAddEditModal, setShowAddEditModal] = useState({
    visible: false,
    data: null,
  });

  const [goodFaithPolicies, setGoodFaithPolicies] = useState({
    isLoading: true,
    data: [],
  });

  const { allFacilities } = useSelector((state) => ({
    allFacilities: state.facilities.allFacilities,
  }));

  const filteredFacilities = useMemo(() => {
    return allFacilities.filter(
      (facility) =>
        !goodFaithPolicies.data.some(
          (policy) => policy.facilityId === facility.facilityId
        ) || showAddEditModal.data?.facilityId
    );
  }, [goodFaithPolicies.data, allFacilities, showAddEditModal.data]);

  function loadGoodFaithPolicies() {
    dispatch(getAllGoodFaithPolicies())
      .then((res) => {
        if (res?.status === "success") {
          setGoodFaithPolicies({
            isLoading: false,
            data: res.data,
          });
        }
      })
      .finally(() => {
        setGoodFaithPolicies((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  }

  useEffect(() => {
    loadGoodFaithPolicies();
  }, []);

  return (
    <div className={`cancellationPolicy miscCancellationPolicy`}>
      <div className="cancellationPolicyHead">
        <Text>Locations</Text>
        <Button
          type="text"
          style={{ padding: 0, height: "auto" }}
          onClick={() => setShowGoodFaithTermsModal(true)}
        >
          Terms and Conditions
        </Button>
        <Button
          type="text"
          style={{ padding: 0, height: "auto" }}
          onClick={() =>
            setShowAddEditModal({
              visible: true,
              data: null,
            })
          }
        >
          Add Good Faith Fee Policy
        </Button>
      </div>

      <List
        loading={goodFaithPolicies.isLoading}
        locale={{ emptyText: "No Policies" }}
        className="cancellationPolicyList"
        itemLayout="vertical"
        size="large"
        dataSource={goodFaithPolicies.data}
        renderItem={(policy, index) => (
          <List.Item key={index}>
            <GoodFaithPolicyCard
              key={policy.id}
              data={policy}
              onEdit={(data) => {
                setShowAddEditModal({
                  visible: true,
                  data,
                });
              }}
              onToggle={() => {
                loadGoodFaithPolicies();
              }}
            />
          </List.Item>
        )}
      />
      <GoodFaithPolicyModal
        visible={showAddEditModal.visible}
        data={showAddEditModal.data}
        onCancel={() =>
          setShowAddEditModal({
            visible: false,
            data: null,
          })
        }
        onSuccess={() => {
          setShowAddEditModal({
            visible: false,
            data: null,
          });
          loadGoodFaithPolicies();
        }}
        facilities={filteredFacilities}
      />
      <GoodFaithTermsModal
        visible={showGoodFaithTermsModal}
        onCancel={() => setShowGoodFaithTermsModal(false)}
      />
    </div>
  );
}
