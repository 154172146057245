import React, { useState } from "react";
import { Avatar, Row, Col, Space, Typography, Spin, Modal } from "antd";
import Tag from "components/Tag/Tag";

import { ReactComponent as EmailIco } from "assets/icons/email.svg";
import { ReactComponent as MobileIco } from "assets/icons/mobile.svg";

import "./UserInfo.scss";
import {
  EnvironmentOutlined,
  MailOutlined,
  MobileOutlined,
  SolutionOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { openNotificationWithIcon } from "utils/Notification";
import GoogleMap, { GoogleMapModal } from "components/GoogleMap/GoogleMap";
import { MEET_PROVIDER } from "utils/constants";
import { MeetingVendor } from "utils/enums";

const { Title, Text, Link } = Typography;

function UserInfo({
  isTelehealth,
  startUrl,
  name,
  isFirstVisit,
  address,
  operationType,
  email,
  phoneNumber,
  loading,
  style,
  onClick,
  meetingVendor,
}) {
  // undefined or string
  const [showGoogleMap, setshowGoogleMap] = useState();

  return (
    <Row className="userInfoContainer" gutter={[16, 8]} style={style}>
      <Col span={24} style={{ marginBottom: 8 }}>
        <Space size={16}>
          <Space align="center">
            <Avatar size={45} icon={<UserOutlined />} />
            <Title
              onClick={onClick}
              level={4}
              style={{
                marginBottom: 0,
                color: "#161F3A",
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              {name}
            </Title>
          </Space>
          {isFirstVisit && <Tag>First Visit</Tag>}
        </Space>
      </Col>
      {loading ? (
        <Col span={24} style={{ paddingLeft: 16 }}>
          <Spin />
        </Col>
      ) : (
        <>
          <Col span={24} style={{ paddingLeft: 16 }}>
            <Space size={20}>
              <MailOutlined style={{ fontSize: 17 }} />
              <Link
                href="mailto:scheduling@liquidmobileiv.com"
                className="color-black"
                style={{
                  fontSize: 17,
                  fontFamily: "tradeGothic",
                  textDecoration: "underline",
                }}
              >
                {email}
              </Link>
            </Space>
          </Col>
          <Col span={24} style={{ paddingLeft: 16 }}>
            <Space size={20} style={{ marginTop: 6 }}>
              <MobileOutlined style={{ fontSize: 17 }} />
              <Link
                href={`tel:${phoneNumber}`}
                className="color-black"
                style={{
                  fontSize: 17,
                  fontFamily: "tradeGothic",
                  textDecoration: "underline",
                }}
              >
                {phoneNumber}
              </Link>
            </Space>
          </Col>

          {isTelehealth ? (
            <Col span={24} style={{ paddingLeft: 16 }}>
              <Space
                size={20}
                align="start"
                style={{
                  cursor: "pointer",
                  marginTop: 6,
                  marginBottom: 10,
                }}
                onClick={() => {
                  window.open(startUrl, "_blank");
                }}
              >
                <VideoCameraOutlined style={{ fontSize: 17 }} />
                <Text
                  style={{
                    display: "inline-block",
                    marginTop: -5,
                    lineHeight: 1.5,
                    fontSize: 17,
                    fontFamily: "tradeGothic",
                    textDecoration: "underline",
                  }}
                >
                  Start{" "}
                  {meetingVendor !== MeetingVendor.Zoom
                    ? MEET_PROVIDER
                    : "Zoom"}{" "}
                  Call
                </Text>
              </Space>
            </Col>
          ) : (
            <Col span={24} style={{ paddingLeft: 16 }}>
              <Space
                size={20}
                align="start"
                style={{
                  cursor: "pointer",
                  marginTop: 6,
                  marginBottom: 10,
                }}
                onClick={() => {
                  setshowGoogleMap(address);
                  // navigator.clipboard.writeText(address);
                  // openNotificationWithIcon(
                  //   "info",
                  //   "Success!",
                  //   "Address has been copied."
                  // );
                }}
              >
                <EnvironmentOutlined style={{ fontSize: 17 }} />
                <Text
                  style={{
                    display: "inline-block",
                    marginTop: -5,
                    lineHeight: 1.5,
                    fontSize: 17,
                    fontFamily: "tradeGothic",
                    textDecoration: "underline",
                  }}
                >
                  {address} ({operationType} Service)
                </Text>
              </Space>
            </Col>
          )}
        </>
      )}
      <GoogleMapModal
        visible={showGoogleMap}
        streetAddress={showGoogleMap}
        onCancel={() => {
          setshowGoogleMap(false);
        }}
      />
    </Row>
  );
}

export default UserInfo;
