import React from "react";
import PropTypes from "prop-types";

import { Row, Col, Form, Typography, Divider, Grid } from "antd";

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import "./Stripe.scss";

import { FormInput } from "components/FormItems/FlatFormItems";

const { useBreakpoint } = Grid;
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: "Roboto, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "14px",
      "::placeholder": {
        fontSize: 14,
        color: "#A8ACB3",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function Stripe({
  children,
  isRequired = true,
  onChange,
  fullWidthColumns = false,
}) {
  const screens = useBreakpoint();
  return (
    <Row gutter={[24, 16]}>
      <Col span={!screens.md || fullWidthColumns ? 24 : 12}>
        <Typography className="stripeElementsLabel">Card Number</Typography>
        <CardNumberElement
          onChange={onChange}
          options={{ ...CARD_ELEMENT_OPTIONS, showIcon: true }}
        />
      </Col>
      <Col span={!screens.md || fullWidthColumns ? 24 : 12}>
        <FormInput
          name="name"
          placeholder="Card Holder Name"
          label="Card Holder Name"
          required={true}
          variant="underlined"
          formItemStyles={{ marginBottom: 0 }}
          rules={[
            {
              message: "Please enter a valid name.",
              validator: (_, value) => {
                if (isRequired && !value) {
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            },
          ]}
        />
      </Col>

      <Col span={!screens.md || fullWidthColumns ? 12 : 9}>
        <Typography className="stripeElementsLabel">Expiration Date</Typography>
        <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
      </Col>
      <Col span={screens.md || fullWidthColumns ? 7 : 6}>
        <Typography className="stripeElementsLabel">CVC</Typography>
        <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
      </Col>
      {children}
    </Row>
  );
}

Stripe.propTypes = {};

export default Stripe;
