import OutlinedSelectorFormItem from "components/EventDrawer/components/OutlinedSelector";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchLots } from "services/inventory.service";
import { createPortal } from "react-dom";
import { Button, Select } from "antd";
import FormItem from "antd/es/form/FormItem";

const { Option } = Select;

export default function LotNumber({
  facilityProductId,
  kitNo,
  index,
  lot,
  onChangeLot,
  disabled,
  form,
  addSkuLot,
  removeSkuLot,
  lots: lotsProp,
  keyProp,
  required,
}) {
  const disaptch = useDispatch();

  const [lots, setLots] = useState({
    data: lotsProp ?? [],
    isLoading: lotsProp ? false : true,
  });

  useEffect(() => {
    if (!lotsProp)
      disaptch(fetchLots(kitNo, facilityProductId))
        .then((res) => {
          if (res?.status === "success") {
            setLots((state) => ({
              ...state,
              data: res?.data ?? [],
            }));
            if (res?.data?.length === 1) {
              // let transactions = form.getFieldsValue("transactions");

              // if (
              //   transactions &&
              //   Array.isArray(transactions) &&
              //   transactions.length
              // ) {
              //   transactions[index].lots = res?.data[0]?.inventoryId;
              // } else {
              //   transactions[index] = { lots: res?.data[0]?.inventoryId };
              // }
              onChangeLot(res?.data[0]?.inventoryId, res?.data[0]);
              form.setFieldsValue({
                [`transactions[${keyProp}].lot`]: res?.data[0]?.inventoryId,
              });
            } else if (res?.data?.length > 1) {
              let allEmpty = res?.data?.every(
                (item) =>
                  item.lotNumber === null ||
                  item.lotNumber === undefined ||
                  item.lotNumber === ""
              );
              if (allEmpty) {
                let maxLot = res?.data?.[0];
                for (let i = 0; i < res?.data?.length; i++) {
                  let item = res?.data?.[i];
                  if (item.quantity > maxLot.quantity) {
                    maxLot = item;
                  }
                }

                onChangeLot(maxLot?.inventoryId, maxLot);
                form.setFieldsValue({
                  [`transactions[${keyProp}].lot`]: maxLot?.inventoryId,
                });
              }
            }
          }
        })
        .finally(() => {
          setLots((state) => ({
            ...state,
            isLoading: false,
          }));
        });
  }, []);
  return (
    <div style={{ display: "flex", gap: 8 }}>
      <FormItem
        name={`transactions[${keyProp}].lot`}
        style={{ marginBottom: 0 }}
        rules={[
          {
            message: "Required",
            validator: (_, value, optiom) => {
              if (lots?.isLoading) {
                return Promise.reject("Please wait while we are fetching lots");
              } else if (required === false) {
                return Promise.resolve();
              } else if (lots?.data?.length <= 1) {
                return Promise.resolve();
              } else if (lots?.data?.length > 1 && !value) {
                // const lot = lots?.data?.find(
                //   (lot) => lot.inventoryId === value
                // );
                // if (
                //   lot?.lotNumber === "" ||
                //   lot?.lotNumber === null ||
                //   lot?.lotNumber === undefined
                // ) {
                return Promise.reject("Required");
                // } else {
                //   return Promise.resolve();
                // }
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}
        className={`flatFormItem outlinedSelector
       `}
      >
        <Select
          className="outlinedSelector"
          placeholder={"Select Lot"}
          disabled={disabled}
          loading={lots?.isLoading}
          value={lot}
          onChange={(value) => {
            const lot = lots?.data?.find((lot) => lot.inventoryId === value);
            onChangeLot(value, lot);
          }}
        >
          {lots?.data?.map?.((opt) => (
            <Option
              key={opt["inventoryId"]}
              value={opt["inventoryId"]}
              label={"lotNumber"}
            >
              <> {opt["lotNumber"]}</>
            </Option>
          ))}
        </Select>
      </FormItem>

      <div className="flex items-center justify-center">
        {lotsProp ? (
          <Button
            type="primary"
            size="small"
            onClick={() => removeSkuLot(index)}
            disabled={disabled || lots?.isLoading || lots?.data?.length < 2}
          >
            -
          </Button>
        ) : (
          <Button
            type="primary"
            size="small"
            onClick={() => addSkuLot(index, lots?.data ?? [])}
            disabled={disabled || lots?.isLoading || lots?.data?.length < 2}
          >
            +
          </Button>
        )}
      </div>
    </div>
  );
}
