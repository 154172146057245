import { actions } from "../actions/charts.action";

const INITIAL_STATE = {
  allCharts: [],
  currentChart: null,
};

const reducer = (state = INITIAL_STATE, data) => {
  switch (data.type) {
    case actions.SET_ALL_CHARTS:
      return { ...state, allCharts: data.data };
    case actions.SET_CURRENT_CHART:
      return { ...state, currentChart: data.data };
    case "RESET":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
