import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import moment from "moment-timezone";

import { Row, Col, Grid, Space, Button, Typography } from "antd";
import CheckCircleOutlined from "@ant-design/icons/CheckCircleOutlined";
import ExclamationCircleFilled from "@ant-design/icons/ExclamationCircleFilled";

import AuditList from "./AuditList";
import { is } from "date-fns/locale";

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

function IntakeFormCard({
  isExpired,
  isLegacy,
  isOutdated,
  title,
  date,
  isFilled,
  type,
  modalVisibilityHander,
  user,
}) {
  const screens = useBreakpoint();
  const isSmallDevice = !screens.md;

  const [showAudit, setShowAudit] = useState(false);
  useEffect(() => {}, []);

  const Controls = (
    <>
      <Button
        type="text"
        onClick={() =>
          modalVisibilityHander({
            visible: true,
            isEdit: false,
          })
        }
      >
        View
      </Button>
      <Button type="text" onClick={() => setShowAudit(true)}>
        History
      </Button>
    </>
  );

  return (
    <>
      <Row className={`intakeFormCardContainer `}>
        <>
          <Col>
            <Title level={4} className="tradeGothic colorPrimary">
              {title}
            </Title>
          </Col>
          <Col flex="auto">
            <div className="informFormCardControls">
              {(isExpired || isLegacy || isOutdated || !isFilled) && (
                <Button
                  type="text"
                  onClick={() =>
                    modalVisibilityHander({
                      visible: true,
                      isEdit: true,
                    })
                  }
                >
                  Edit
                </Button>
              )}
              {isFilled && <>{Controls}</>}
            </div>
          </Col>
        </>
        <Col span={24}>
          <Space align={isSmallDevice ? "start" : "center"}>
            {isExpired ? (
              <ExclamationCircleFilled style={{ color: "#faad14" }} />
            ) : (
              <CheckCircleOutlined
                style={{ color: isFilled ? " #27CA2D" : "#dddddd" }}
              />
            )}

            <Title
              level={5}
              className="tradeGothic "
              style={{ marginBottom: 0 }}
            >
              {title}
            </Title>
            {isFilled && (
              <Text
                className="tradeGothic "
                style={{
                  fontSize: 14,
                  textAlign: "right",
                  display: "inline-block",
                }}
              >
                {date ? (
                  isExpired ? (
                    <>Expired</>
                  ) : (
                    <>
                      {moment.utc(date).local().format("MMM DD, YYYY h:mm A")} (
                      {moment.tz.guess()})
                    </>
                  )
                ) : (
                  ""
                )}
              </Text>
            )}
          </Space>
        </Col>
      </Row>
      <AuditList
        user={user}
        open={showAudit}
        onCancel={() => setShowAudit(false)}
        type={type}
        itemTitle={title}
      />
    </>
  );
}

IntakeFormCard.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string | PropTypes.any, // prob datetime
  isFilled: PropTypes.bool,
  type: PropTypes.string,
  modalVisibilityHander: PropTypes.bool,
  variant: PropTypes.string,
};

export default IntakeFormCard;
