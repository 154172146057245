import React, { useEffect, useRef, useState } from "react";
import { Form, Row, Col, Typography, Button as AntdButton } from "antd";
import { Button } from "components/Buttons/Buttons";
import Modal from "components/Modal/Modal";
import {
  FormDatePicker,
  FormHiddenSubmitButton,
  FormInput,
  FormInputArea,
  FormInputNumber,
} from "components/FormItems/FlatFormItems";
import { useForm } from "antd/lib/form/Form";
import { FormSelect } from "components/FormItems/FlatFormItems";
import moment from "moment";
import { Carousel } from "antd";
import { useDispatch } from "react-redux";
import { createNotificationTrigger } from "services/communicationServices";
import { EXCLUSION_TYPE } from "utils/enums";

const { Title, Text } = Typography;

const exclusionTypes = Object.entries(EXCLUSION_TYPE).map((it) => {
  return {
    label: it[0],
    value: it[1],
  };
});

function CommunicationModal({
  visible,
  onCancel,
  templates,
  triggers = [],
  facilities = [],
  onSuccess,
  services,
  isServicesLoading,
}) {
  // props destruct start
  // props destruct end

  // hooks that bring data start
  const submitBtnRef = useRef();
  const dispatch = useDispatch();
  // hooks that bring data end

  // states start
  // states end

  // hooks that dont bring data start
  const [form] = useForm();

  useEffect(() => {
    if (visible == false) form.resetFields();
  }, [visible]);

  // hooks that dont bring data end

  // variables end
  const productExcludeType = Form.useWatch("productExcludeType", form);

  return (
    <Modal
      //   closable={closable}
      destroyOnClose={true}
      //   headerBorder={false}
      width="720px"
      title="Add Notification Trigger"
      onCancel={() => onCancel()}
      visible={visible}
      onOk={() => console.log("ok")}
      okText="Add"
      centered={true}
      footer={
        <div className="d-flex justify-between align-center">
          <AntdButton type="text">Cancel</AntdButton>
          <Button
            style={{ height: 50 }}
            rounded={true}
            onClick={() => {
              submitBtnRef?.current?.click();
            }}

            //   disabled={!Boolean(setupIntent)}
          >
            Save
          </Button>
        </div>
      }
      //   cancelText="No"
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={{
          productExcludeType: 1,
        }}
        onFinish={(values) => {
          dispatch(createNotificationTrigger(values)).then((res) => {
            if (res?.status === "success") {
              onSuccess(res?.data);
            }
          });
        }}
      >
        <Row gutter={[20, 20]}>
          <Col xs={24}>
            <FormSelect
              required
              variant="underlined"
              showSearch={true}
              optionLabelProp="label"
              filterOption={(input, option) => {
                return option?.label
                  ?.toLowerCase?.()
                  .includes?.(input.toLowerCase());
              }}
              name="templateId"
              label="Template"
              placeholder={"Select an option"}
              renderLabel="name"
              renderValue="id"
              options={templates}
              formItemStyles={{
                margin: 0,
              }}
            />
          </Col>
          <Col xs={24}>
            <FormSelect
              required
              variant="underlined"
              name="triggerId"
              label="Trigger"
              placeholder={"Select an option"}
              renderLabel="triggerName"
              renderValue="triggerId"
              options={triggers}
              formItemStyles={{
                margin: 0,
              }}
            />
          </Col>
          <Col xs={8}>
            <FormSelect
              required
              variant="underlined"
              name="facilityId"
              label="Facility"
              placeholder={"Select an option"}
              renderLabel="facilityName"
              renderValue="facilityId"
              options={[
                {
                  facilityName: "All",
                  facilityId: -1,
                },
                ...facilities,
              ]}
              formItemStyles={{
                margin: 0,
              }}
            />
          </Col>
          <Col xs={8}>
            <FormSelect
              required
              variant="underlined"
              name="operationTypeId"
              label="Operation Type"
              placeholder={"Select an option"}
              renderLabel="operationTypeName"
              renderValue="operationTypeId"
              options={[
                {
                  operationTypeName: "All",
                  operationTypeId: -1,
                },
                ...[
                  {
                    operationTypeName: "Mobile",
                    operationTypeId: 1,
                  },
                  {
                    operationTypeName: "Clinic",
                    operationTypeId: 2,
                  },
                  {
                    operationTypeName: "Telehealth",
                    operationTypeId: 3,
                  },
                ],
              ]}
              formItemStyles={{
                margin: 0,
              }}
            />
          </Col>
          <Col xs={8}>
            <FormSelect
              required
              variant="underlined"
              name="type"
              label="Type"
              placeholder={"Select an option"}
              renderLabel="label"
              renderValue="value"
              options={[
                {
                  value: 1,
                  label: "Email",
                },
                {
                  value: 2,
                  label: "SMS",
                },
              ]}
              formItemStyles={{
                margin: 0,
              }}
            />
          </Col>
          <Col xs={8}>
            <FormSelect
              required
              variant="underlined"
              name="recipientPlaceholders"
              label="Recipient Placeholder"
              placeholder={"Select an option"}
              renderLabel="label"
              renderValue="value"
              options={[
                {
                  value: "{Customer.Email}",
                  label: "Customer Email",
                },
                {
                  value: "{Customer.PhoneNumber}",
                  label: "Customer PhoneNumber",
                },
                {
                  value: "{Staff.Email}",
                  label: "Staff Email",
                },
                {
                  value: "{Staff.PhoneNumber}",
                  label: "Staff PhoneNumber",
                },
              ]}
              formItemStyles={{
                margin: 0,
              }}
            />
          </Col>
          <Col xs={8}>
            <FormSelect
              variant="underlined"
              name="sendingDateTimePlaceholders"
              label="Schedule Time"
              placeholder={"Select an option"}
              renderLabel="label"
              renderValue="value"
              initialValue=""
              options={[
                {
                  value: "",
                  label: "Now",
                },
                // {
                //   value: "{Order.ServiceDate}",
                //   label: "Service Date",
                // },
                {
                  value: "{Order.ServiceDateUTC}",
                  label: "Service Date",
                },
              ]}
              formItemStyles={{
                margin: 0,
              }}
            />
          </Col>
          <Col xs={6}>
            <FormInputNumber
              required={true}
              // min={0}
              variant="underlined"
              name="timeInterval"
              label="Time"
              formItemStyles={{
                margin: 0,
              }}
            />
          </Col>
          <Col xs={8}>
            <FormSelect
              required
              variant="underlined"
              name="timeUnit"
              label="Time Unit"
              placeholder={"Select an option"}
              renderLabel="label"
              renderValue="value"
              options={[
                {
                  label: "Minutes",
                  value: 1,
                },
                {
                  label: "Hours",
                  value: 2,
                },
                {
                  label: "Days",
                  value: 3,
                },
                {
                  label: "Weeks",
                  value: 4,
                },
                {
                  label: "Months",
                  value: 5,
                },
              ]}
              formItemStyles={{
                margin: 0,
              }}
            />
          </Col>
          <Col xs={24} md={8}>
            <FormSelect
              variant="underlined"
              name="productExcludeType"
              label="Product Exclusion Type"
              placeholder="Select exclusion type"
              formItemStyles={{ marginBottom: 0 }}
              options={exclusionTypes}
              // isOptionDisabled={(it) => !it.isActive}
              filterOption={(input, option) => {
                return option?.label
                  ?.toLowerCase?.()
                  .includes?.(input.toLowerCase());
              }}
              renderLabel="label"
              renderValue="value"
              required
            />
          </Col>
          <Col xs={24} md={8}>
            <FormSelect
              variant="underlined"
              name="productIds"
              label="Services"
              placeholder="Select services"
              mode="multiple"
              formItemStyles={{ marginBottom: 0 }}
              options={services?.filter((it) => {
                return it?.isActive;
              })}
              // isOptionDisabled={(it) => !it.isActive}
              filterOption={(input, option) => {
                return option?.label
                  ?.toLowerCase?.()
                  .includes?.(input.toLowerCase());
              }}
              renderLabel="productName"
              renderValue="productId"
              disabled={productExcludeType === EXCLUSION_TYPE["All Included"]}
              required={productExcludeType !== EXCLUSION_TYPE["All Included"]}
              loading={isServicesLoading}
            />
          </Col>
        </Row>
        <FormHiddenSubmitButton submitRef={submitBtnRef} />
      </Form>
    </Modal>
  );
}

CommunicationModal.propTypes = {};

export default CommunicationModal;
