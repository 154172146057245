import { MoreOutlined } from "@ant-design/icons";
import { DatePicker, Dropdown, Empty, Menu, Space, Table, Grid } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentDistributor,
  setCurrentStaff,
} from "../../redux/actions/user.actions";
import { setLoading } from "../../redux/actions/app.actions";
import { ReactComponent as ChevronLeft } from "assets/icons/chevronLeftIco.svg";
import { ReactComponent as ChevronRight } from "assets/icons/chevronRightIco.svg";

import {
  addNewStaff,
  getAllStaff,
  updateStaff,
} from "../../services/staffs.services";
import { push } from "connected-react-router";
import { Button } from "../../components/Buttons/Buttons";
import Toolbar from "components/Calendar/components/Toolbar";
import moment from "moment-timezone";
import { getAllOrders } from "services/order.services";
import { setCurrentOrder } from "redux/actions/order.actions";
import AppointmentDrawer from "containers/ResourceScheduler/components/AppointmentDrawer";
import GuestDrawer from "containers/ResourceScheduler/components/GuestDrawer";

import {
  CarOutlined,
  CloseCircleOutlined,
  CloudOutlined,
  CustomerServiceOutlined,
  ExclamationCircleOutlined,
  ShopOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";

import "./Appointment.scss";
import EventDrawer from "components/EventDrawer/EventDrawer";
import { setCurrentBooking } from "redux/actions/booking.actions";

import { findWindows } from "windows-iana";
import {
  addonsTextReducer,
  checkIsClinicBooking,
  checkIsMobileBooking,
  checkIsTeleHealthBooking,
  convertMinsToHrsMins,
} from "utils/common";
import { uniqBy } from "lodash";

const { useBreakpoint } = Grid;

const dataSource = [
  {
    orderId: 618,
    serviceAddress: "R-299 sector 10 north karachi",
    voucherCode: null,
    voucherId: 1,
    orderStatus: "Pending",
    totalBill: 0,
    date: "2022-07-05T07:12:02.163745+00:00",
    serviceDate: "2022-07-19T19:00:00",
    facilityId: 1,
    operationTypeId: 1,
    staffId: null,
    lineItems: [
      {
        lineItemId: 2065,
        productId: 9,
        customerProfileId: 156,
        sellingPrice: 1,
        productName: "Covid Testing",
        addOns: [],
      },
    ],
  },
];
const OrderStatus = {
  Pending: 1,
  Cancel: 2,
  Completed: 3,
  "No Show": 4,
  Arrived: 5,
};

const Menue = (record, dispatch, setModalVisible) => (
  <Menu style={{ borderRadius: 6, border: "2px solid #CDD5D8 " }}>
    <Menu.Item
      onClick={() => {
        dispatch(setCurrentStaff(record));

        setModalVisible(true);
        // console.log(record)
      }}
    >
      s Edit
    </Menu.Item>
  </Menu>
);
let bgColor = "";
let tooltip = "";

const Appointments = ({ date: propsDate }) => {
  const abortRef = useRef();
  const intervalRef = useRef();
  const screens = useBreakpoint();
  const isSmallDevice = !screens.md;

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(moment());
  const [modalVisible, setModalVisible] = useState(false);

  const [showAppointmentDrawer, setAppointmentDrawer] = useState(false);
  const [showGuestDrawer, setshowGuestDrawer] = useState(false);

  const allStaffs = useSelector((state) => state.user.allStaff);
  const allOrders = useSelector((state) => state.orders.allOrders);
  const currentStaff = useSelector((state) => state.user.currentUser);
  const currentOrder = useSelector((state) => state.orders.currentOrder);

  useEffect(() => {
    if (currentStaff?.staffId) {
      if (abortRef.current) abortRef.current.abort();
      abortRef.current = new AbortController();
      getData(true, abortRef?.current?.signal);
      intervalRef.current = setInterval(() => {
        getData(false, abortRef?.current?.signal);
      }, 20000); // 10 seconds
    }

    return () => {
      if (intervalRef?.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [date, currentStaff]);

  useEffect(() => {
    if (currentStaff?.staffId)
      dispatch(
        getAllStaff(null, ["Nurse", "Nurse Lead", "Nurse Practitioner"])
      );
  }, [currentStaff?.staffId]);

  const getOrderStatus = (statusId, order) => {
    for (const key in OrderStatus) {
      if (statusId === OrderStatus[key]) {
        return key;
      }
    }
  };
  const getData = async (showLoading = true, signal) => {
    const windowsTimezone = findWindows(moment.tz.guess());
    if (showLoading) dispatch(setLoading(true));
    await dispatch(
      getAllOrders(
        {
          // facilityId: currentStaff?.facilityId,
          filterType: 1,
          requestedDate: moment.utc(date).local().format("YYYY-MM-DD"),
          staffAssigned: true,
          nurseId: currentStaff?.staffId,
          timezone: windowsTimezone?.[0],
          facilityIds: [],
        },
        showLoading,
        signal
      )
    );
    if (showLoading) dispatch(setLoading(false));
  };

  const onAddHandler = async (values) => {
    dispatch(setLoading(true));
    await dispatch(addNewStaff({ ...values, facilityId: 1 }, allStaffs));
    dispatch(setLoading(false));
    dispatch(setCurrentStaff(null));
  };

  const onUpdateHandler = async (values) => {
    dispatch(setLoading(true));
    await dispatch(
      updateStaff(
        {
          ...values,
          staffId: currentStaff?.staffId,
          userId: currentStaff?.userId,
          facilityId: 1,
        },
        allStaffs
      )
    );
    dispatch(setLoading(false));
    dispatch(setCurrentDistributor(null));
  };

  const getFormatedDate = (date) => {
    const str = moment(date).fromNow();
    if (str && str.includes("ago")) {
      return `Past ${moment(date).fromNow()}`;
    } else {
      return `Due ${moment(date).fromNow()}`;
    }
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      // render: (text, record) => (
      //   <div style={{ display: 'flex', alignItems: 'center', }}>
      //     <div style={{ height: 40, width: 40, borderRadius: 20, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      //       <img src='https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80' style={{ width: '100%', }} />
      //     </div>
      //     <p style={{ margin: 0, marginLeft: 15, fontFamily: 'tradeGothic', fontSize: 16 }}> {`${record.firstName}  ${record.lastName}`}</p>
      //   </div>
      // )
    },
    {
      title: "Permissions",
      dataIndex: "permissions",
      key: "permissions",
      render: (text) =>
        text.map((p) => <span style={{ margin: "0px 4px" }}>{p}</span>),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },

    {
      title: "Action",
      key: "action",
      align: "right",
      width: 180,
      render: (text, record) => (
        <Dropdown
          overlay={() => Menue(record, dispatch, setModalVisible)}
          trigger={["click"]}
          getPopupContainer={(triggerNode) => triggerNode?.parentNode}
        >
          <a onClick={(e) => e.preventDefault()}>
            <MoreOutlined style={{ fontSize: 18 }} />
          </a>
        </Dropdown>
      ),
    },
  ];

  const onNavigate = async (value) => {
    if (value === "add") {
      const newDate = moment(date).add(1, "day");
      setDate(newDate);
    } else {
      const newDate = moment(date).subtract(1, "day");
      setDate(newDate);
    }
  };
  // TODO: DONT USE ORDERSTATUS_STRING
  const renderAppointments = () => {
    if (allOrders.length) {
      return uniqBy(allOrders, "orderId")
        .filter((appointment) => {
          return (
            moment(appointment.serviceDate).format("YYYY-MM-DD") ===
              moment(date).format("YYYY-MM-DD") &&
            currentStaff?.facilityIds?.includes(appointment?.facilityId)
          );
        })
        .map((order) => {
          if (
            order.orderStatusString === "Pending" &&
            moment().isAfter(moment(order?.serviceDate).add(15, "minutes"))
          ) {
            bgColor = "cl-red";
            tooltip = "Nurse has not yet arrived.";
          } else if (order.orderStatusString === "Arrived") {
            bgColor = "cl-green";
            tooltip = "Nurse has arrived.";
          } else if (order.orderStatusString === "NoShow") {
            bgColor = "cl-orange";
            tooltip = "Nurse has marked 'No Show'.";
          } else if (order.orderStatusString === "Cancel") {
            bgColor = "cl-orange";
            tooltip = "Appointment Cancelled.";
          } else if (order.orderStatusString === "Completed") {
            bgColor = "cl-lgreen";
          } else if (order.orderStatusString !== "Arrived") {
            bgColor = "cl-blue";
            tooltip = "Pending booking.";
          }

          console.log("order", order);
          return (
            <div
              onClick={() => {
                // dispatch(setCurrentOrder(order)); // is it needed?
                dispatch(setCurrentBooking(order));
                setModalVisible(true);
              }}
              style={{
                width: "100%",
                minHeight: 149,
                borderRadius: 4,
                boxShadow: "0px 10px 10px rgba(22, 31, 58, 0.08)",
                background: "#fff",
                margin: "12px 0px",
                padding: 16,
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="flex gap-12">
                  <p
                    style={{
                      margin: 0,
                      fontSize: 16,
                      fontFamily: "tradeGothic",
                    }}
                  >
                    Booking ID: {order.orderId}
                  </p>
                  <Space align="center">
                    {/* source 1 for adming, 2 for guest */}
                    {order?.source === 2 && (
                      <CloudOutlined
                        style={{
                          color: "#000",
                          display: "inline-flex",
                          fontSize: 18,
                        }}
                      />
                    )}
                    {order?.operationTypeId === 1 && (
                      <CarOutlined
                        style={{
                          color: "#000",
                          display: "inline-flex",
                          fontSize: 18,
                        }}
                      />
                    )}
                    {order?.operationTypeId === 2 && (
                      <ShopOutlined
                        style={{
                          color: "#000",
                          display: "inline-flex",
                          fontSize: 17,
                        }}
                      />
                    )}
                    {order?.operationTypeId === 3 && (
                      <VideoCameraOutlined
                        style={{
                          color: "#000",
                          fontSize: 17,
                        }}
                      />
                    )}
                    {order?.orderStatus === 2 && (
                      <CloseCircleOutlined
                        style={{
                          color: "#000",
                          fontSize: 17,
                        }}
                      />
                    )}
                    {order?.hasContraindication && (
                      <ExclamationCircleOutlined
                        style={{
                          color: "#000",
                          fontSize: 17,
                        }}
                      />
                    )}
                  </Space>
                </div>
                <p
                  style={{ margin: 0, fontSize: 16, fontFamily: "tradeGothic" }}
                >
                  {getFormatedDate(order?.serviceDate)}
                </p>
              </div>
              <p
                style={{
                  color: "#486BAF",
                  fontSize: 16,
                  fontFamily: "tradeGothic",
                  margin: 0,
                }}
              >
                {moment(order?.serviceDate).format("h:mm a")}
              </p>
              <p
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  fontFamily: "tradeGothic",
                  margin: 0,
                }}
              >
                {order.customerFullName}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p
                  style={{ margin: 0, fontSize: 16, fontFamily: "tradeGothic" }}
                >
                  {order?.lineItems?.[0]?.productName}
                  {order?.lineItems?.[0]?.durationHours !== undefined &&
                    order?.lineItems?.[0]?.durationHours >= 1 &&
                    ` ${convertMinsToHrsMins(
                      Number(order?.lineItems?.[0]?.durationHours) * 60
                    )}`}
                  {order?.lineItems?.[0]?.addOns?.length > 0 &&
                    ` with ${order?.lineItems?.[0]?.addOns?.reduce(
                      addonsTextReducer,
                      ""
                    )} `}
                </p>
                <p
                  style={{
                    margin: 0,
                    fontSize: 16,
                    fontWeight: "bold",
                    fontFamily: "tradeGothic",
                  }}
                >
                  Status:{" "}
                  <span className={`${bgColor}`}>
                    {getOrderStatus(order?.orderStatus) === "Cancel"
                      ? "Cancelled"
                      : getOrderStatus(order?.orderStatus)}
                    {/* TODO: get baackend team to update */}
                  </span>
                </p>
              </div>
            </div>
          );
        });
    } else {
      return (
        <div style={{ height: 300, display: "flex", alignItems: "center" }}>
          <Empty description="No Appointments" />
        </div>
      );
    }
  };

  return (
    <div>
      <EventDrawer
        order={currentOrder}
        setShowEventDrawer={setModalVisible}
        visible={modalVisible}
        onClose={() => {
          setModalVisible(false);
          dispatch(setCurrentStaff(null));
        }}
        getData={getData}
      />
      <div
        style={{
          height: screens.md ? 80 : "auto",
          background: "#e5e8ee",
          display: "flex",
          flexDirection: screens.md ? "row" : "column",
          justifyContent: "space-between",
          alignItems: screens.md ? "center" : "flex-start",
        }}
      >
        <h2
          style={{
            color: "#15213B",
            fontFamily: "tradeGothicBold",
            margin: 0,
            marginBottom: screens.md ? 0 : 12,
          }}
        >{`Appointments (${allOrders?.length})`}</h2>
        <div className="dayWeekSelectorContainer">
          <button
            className="arrows"
            onClick={() => {
              onNavigate("less");
            }}
          >
            <ChevronLeft />
          </button>
          <div style={{ position: "relative" }}>
            <DatePicker
              dropdownClassName="dayWeekSelectorDropdown"
              defaultValue={date}
              value={date}
              placement="bottomLeft"
              bordered={false}
              open={open}
              className="hiddenDatePicker"
              style={{ visibility: "hidden", width: 0 }}
              onOpenChange={(open) => setOpen(open)}
              dropdownAlign={{
                offset: [-68, 14],
              }}
              onChange={async (date) => {
                setDate(date);
              }}
            />
            <button className="dateButton" onClick={() => setOpen(!open)}>
              {date.format("DD MMM YYYY")}
            </button>
          </div>
          <button
            className="arrows"
            onClick={() => {
              onNavigate("add");
            }}
          >
            <ChevronRight />
          </button>
        </div>
        {screens.md && <div style={{ width: 230 }}>&nbsp;</div>}
      </div>
      <div
        style={{
          height: isSmallDevice
            ? "calc(100vh - 135px - 16px - 30px)"
            : "calc(100vh - 80px - 16px - 80px)",
          background: "#e5e8ee",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: 30,
          overflow: "auto",
        }}
      >
        {renderAppointments()}
      </div>
    </div>
  );
};

export default Appointments;
