import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { FormPassword } from "components/FormItems/FlatFormItems";
import { Dropdown, Form, Space, Typography } from "antd";

import "./PasswordWithDropdown.scss";
import { CheckCircleOutlined } from "@ant-design/icons";

const { Text } = Typography;

function PasswordWithDropdown({
  name,
  variant,
  label,
  placeholder = "",
  rules = [],
  form,
  ...props
}) {
  const menu = <div className="passwordPopup">asdasdasd</div>;

  const [isFocused, setIsFocused] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  useEffect(() => {
    setIsFocused(true);
  }, []);
  const password = Form.useWatch(name, form) || "";
  const { isDecimal, isUpperCase, isLowerCase, isSpecial, isLength } =
    useMemo(() => {
      const isDecimal = /\d+/.test(password);
      const isUpperCase = /[A-Z]+/.test(password);
      const isLowerCase = /[a-z]+/.test(password);
      const isSpecial = /[^A-Za-z0-9_]+/.test(password);
      const isLength = password.length >= 8;
      return { isDecimal, isUpperCase, isLowerCase, isSpecial, isLength };
    }, [password]);

  const errorsView = (
    <>
      {isTouched && (
        <div className="passwordErrors">
          <Text className="passwordErrorsInfo">Password must Contain:</Text>
          <br />
          <Space className="passwordErrorLine">
            <CheckCircleOutlined
              style={{
                fontSize: 18,
                color: isLength ? "#49cc90" : "#000",
              }}
            />
            <Text>Minimum of 8 Characters</Text>
          </Space>
          <Space className="passwordErrorLine">
            <CheckCircleOutlined
              style={{
                fontSize: 18,
                color: isUpperCase ? "#49cc90" : "#000",
              }}
            />
            <Text>Minimum of 1 Uppercase Character</Text>
          </Space>
          <Space className="passwordErrorLine">
            <CheckCircleOutlined
              style={{
                fontSize: 18,
                color: isLowerCase ? "#49cc90" : "#000",
              }}
            />
            <Text>Minimum of 1 Lowercase Character</Text>
          </Space>
          <Space className="passwordErrorLine">
            <CheckCircleOutlined
              style={{
                fontSize: 18,
                color: isSpecial ? "#49cc90" : "#000",
              }}
            />
            <Text>Minimum of 1 Special Character</Text>
          </Space>
          <Space className="passwordErrorLine">
            <CheckCircleOutlined
              style={{
                fontSize: 18,
                color: isDecimal ? "#49cc90" : "#000",
              }}
            />
            <Text>Minimum of 1 Number</Text>
          </Space>
        </div>
      )}
    </>
  );

  return (
    <>
      <FormPassword
        className="passwordWithDropdown"
        required
        variant="underlined"
        name={name}
        label={label}
        placeholder={placeholder}
        formItemStyles={{ marginBottom: 8 }}
        rules={[
          ...rules,
          {
            message: errorsView,
            validator: (_, value) => {
              const regex = new RegExp(
                /(?=.*\d)(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{8,}/g
              );

              const isMatch = value.match(regex);
              if (isMatch) return Promise.resolve();
              return Promise.reject();
              // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
            },
          },
        ]}
        onFocus={() => {
          setIsFocused(true);
          setIsTouched(true);
        }}
        onBlur={() => setIsFocused(false)}
      />
    </>
  );
}

PasswordWithDropdown.propTypes = {};

export default PasswordWithDropdown;
