import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Drawer,
  Form,
  Row,
  Typography,
  Button as AntdButton,
  Upload,
  Avatar,
} from "antd";
import { CloseOutlined, UserOutlined } from "@ant-design/icons";
import {
  FormInput,
  FormInputArea,
  FormHiddenSubmitButton,
  FormPhoneNumber,
  FormSelect,
  FormTimeRangePicker,
} from "components/FormItems/FlatFormItems";
import { useForm } from "antd/lib/form/Form";
import { Button } from "components/Buttons/Buttons";
import FilesUploader from "components/FilesUploader/FilesUploader";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllShiftSchedules,
  getLocationById,
} from "services/locationSettings.service";
import { getAllServiceTypes } from "services/serviceTypeSettings.service";
import { findWindows } from "windows-iana";

const { Title, Text } = Typography;
const { Dragger } = Upload;

export default function LocationDrawer({
  visible,
  onClose,
  data,
  onSuccess,
  priceSchedules = [],
  shiftSchedules = [],
  isPriceSchedulesLoading,
  isShiftSchedulesLoading,
}) {
  const [form] = useForm();
  const submitBtnRef = useRef();

  const priceScheduleId = useForm("priceScheduleId", form);
  const facilityScheduleId = useForm("facilityScheduleId", form);

  const { states, statesLoading } = useSelector((state) => state.location);

  const dispatch = useDispatch();

  const [serviceTypes, setServiceTypes] = useState([]);

  const getData = (id) => {
    dispatch(getLocationById(id)).then((result) => {
      if (result?.status === "success") {
        form.setFieldsValue({
          ...result.data,
        });
      }
    });
  };

  useEffect(() => {
    if (visible === false) {
      form.resetFields();
    } else if (data?.facilityId !== undefined) {
      form.setFieldsValue({
        ...data,
      });
      getData(data?.facilityId);
    }
  }, [visible]);

  return (
    <Drawer
      destroyOnClose
      headerStyle={{ border: "none" }}
      className="locationDrawer"
      title={
        <div className="locationDrawerTitle">
          <p>
            {visible && (data?.facilityId !== undefined ? "Edit" : "Create")}{" "}
            Location
          </p>
          <CloseOutlined onClick={onClose} />
        </div>
      }
      placement="right"
      onClose={onClose}
      visible={visible}
      closable={false}
      footer={
        <div className="locationDrawerFooter">
          <AntdButton type="text" onClick={onClose}>
            Cancel
          </AntdButton>
          <Button
            rounded
            type={"primary"}
            style={{ height: 50, width: "50%" }}
            onClick={() => submitBtnRef?.current?.click()}
          >
            Save
          </Button>
        </div>
      }
    >
      <Form
        preserve={false}
        layout="vertical"
        form={form}
        requiredMark={false}
        onFinish={(values) => {
          const windowsTimezone = findWindows(values.timezone);
          values.timezone = windowsTimezone?.[0];

          onSuccess(values);
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <FormInput
              variant="underlined"
              name="facilityName"
              label="Location Name"
              //   placeholder="Location Name"
              required={true}
              formItemStyles={{
                margin: 0,
              }}
            />
          </Col>
          <Col xs={24}>
            <FormInput
              variant="underlined"
              name="zipCode"
              label="Location ZIP Code"
              //   placeholder="Location Name"
              required={true}
              formItemStyles={{
                margin: 0,
              }}
            />
          </Col>
          <Col xs={24}>
            <FormInputArea
              label="Location Address"
              name={"facilityAddress"}
              variant="underlined"
              formItemStyles={{ width: "100%", margin: 0 }}
            />
          </Col>
          <Col xs={24}>
            <FormSelect
              required
              variant="underlined"
              name="stateId"
              label="State"
              placeholder={"Select State"}
              renderLabel="locationName"
              renderValue="locationId"
              styles={{ border: "none" }}
              loading={statesLoading}
              options={states}
              // isOptionDisabled={(it) => !it?.isActive}
              formItemStyles={{ margin: 0 }}
            />
          </Col>
          <Col xs={24}>
            <FormInputArea
              name={"surveyLocationId"}
              variant="underlined"
              label="Survey Location ID"
              placeholder="Survey Location ID"
              formItemStyles={{ width: "100%", margin: 0 }}
            />
          </Col>
          {/* <Col xs={24}>
            <FormInput
              max={5}
              name="zipCode"
              label="ZIP Code"
              placeholder="ZIP Code"
              required
              variant="underlined"
              formItemStyles={{
                margin: 0,
              }} 
            />
          </Col> */}
          <Col xs={24}>
            <FormPhoneNumber
              variant="underlined"
              name="mobileNumber"
              label="Phone Number"
              placeholder="Phone Number"
              required={true}
              formItemStyles={{ margin: 0 }}
            />
          </Col>
          <Col xs={24}>
            <FormInput
              variant="underlined"
              name="email"
              label="Email Address"
              type="email"
              required={true}
              formItemStyles={{ margin: 0 }}
            />
          </Col>
          <Col xs={24}>
            <FormSelect
              required
              variant="underlined"
              name="priceScheduleId"
              label="Pricing Schedule"
              placeholder={"Select"}
              renderLabel="priceScheduleName"
              renderValue="priceScheduleId"
              styles={{ border: "none" }}
              loading={isPriceSchedulesLoading}
              options={priceSchedules?.filter(
                (it) => it?.isActive || it?.priceScheduleId === priceScheduleId
              )}
              // isOptionDisabled={(it) => !it?.isActive}
              formItemStyles={{ margin: 0 }}
            />
          </Col>
          <Col xs={24}>
            <FormSelect
              required
              variant="underlined"
              renderLabel="scheduleName"
              renderValue="facilityScheduleId"
              name="facilityScheduleId"
              label="Select Business Hours Schedule"
              placeholder={"Select"}
              styles={{ border: "none" }}
              // formItemStyles={{ marginBottom: 12 }}
              options={shiftSchedules?.filter(
                (it) =>
                  it?.isActive || it?.facilityScheduleId === facilityScheduleId
              )}
              // isOptionDisabled={(it) => !it?.isActive}
              formItemStyles={{ margin: 0 }}
              loading={isShiftSchedulesLoading}
              // styles={{ background: '#ddd' }}
            />
          </Col>

          {/* <Col xs={24}>
            <FormSelect
              required
              variant="underlined"
              renderLabel="operationTypeName"
              renderValue="operationTypeId"
              name="operationTypeId"
              label="Operation Type"
              placeholder={"Select"}
              styles={{ border: "none" }}
              // formItemStyles={{ marginBottom: 12 }}
              options={[
                {
                  operationTypeId: 2,
                  operationTypeName: "Clinic",
                },
                {
                  operationTypeId: 1,
                  operationTypeName: "Mobile",
                },
              ]}
              isOptionDisabled={(option) => {
                if (option?.operationTypeName === "Mobile") return true;
                return false;
              }}
              formItemStyles={{ margin: 0 }}
              loading={isLoadingShiftSchedules}
              // styles={{ background: '#ddd' }}
            />
          </Col> */}

          {/* <Col xs={24}>
            <FormSelect
              required
              className={"searchWithIcon"}
              variant="underlined"
              name="timeZone"
              label="Timezone"
              placeholder={"Select"}
              showSearch={true}
              filterOption={(input, option) => {
                console.log("inp", input, "opt", option);
                return option?.value
                  ?.toLowerCase?.()
                  .includes?.(input.toLowerCase());
              }}
              // formItemStyles={{ marginBottom: 12 }}
              options={timezones}
              formItemStyles={{ margin: 0 }}

              // styles={{ background: '#ddd' }}
            />
          </Col> */}
          <FormHiddenSubmitButton submitRef={submitBtnRef} />
        </Row>
      </Form>
    </Drawer>
  );
}
