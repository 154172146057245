import React, { useRef } from "react";
import {
  Input,
  Form,
  DatePicker,
  Select,
  Switch,
  Button,
  TimePicker,
  InputNumber,
} from "antd";
// import "./RoundedFormItems.scss";
import IMask from "imask";

import moment from "moment";

const DATE_FORMAT = "MM/DD/YYYY";
const MASKED = IMask.createMask({
  blocks: {
    DD: { from: 1, mask: IMask.MaskedRange, to: 31 },
    MM: { from: 1, mask: IMask.MaskedRange, to: 12 },
    YYYY: { from: 1900, mask: IMask.MaskedRange, to: Number.MAX_VALUE },
  },
  format: (date) => moment(date).format(DATE_FORMAT),
  mask: Date,
  parse: (date) => moment(date, DATE_FORMAT),
  pattern: DATE_FORMAT,
});

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;

export const FormInput = ({
  name,
  required,
  type,
  label,
  placeholder,
  initialValue,
  styles,
  disabled,
  max,
}) => {
  return (
    <FormItem
      className="noError roundedSelect"
      style={{
        border: "2px solid #DBDFEA",
        margin: "8px 10px",
        borderRadius: 14,
      }}
      initialValue={initialValue}
      label={label}
      name={name}
      rules={[
        {
          required,
          message: "Required",
        },

        {
          type: type === "email" ? "email" : null,
          message: "The input is not valid E-mail!",
        },
      ]}
    >
      <Input
        placeholder={placeholder}
        className="roundedFormItem input tradeGothic"
        style={{ ...styles, border: "none",}}
        type={type === "email" ? "text" : type}
        disabled={disabled}
        maxLength={max}
      />
    </FormItem>
  );
};
export const FormInputNumber = ({
  name,
  required,
  type,
  label,
  placeholder,
  initialValue,
  styles,
  disabled,
  max,
  maxlength,
}) => {
  return (
    <FormItem
      initialValue={initialValue}
      label={label}
      name={name}
      rules={[
        {
          required,
          message: "Required",
        },
      ]}
    >
      <InputNumber
        placeholder={placeholder}
        className="roundedFormItem"
        style={styles}
        disabled={disabled}
        max={max}
        maxLength={maxlength}
      />
    </FormItem>
  );
};
export const FormDatePicker = ({
  name,
  required,
  label,
  initialValue,
  styles,
  onClick,
  disabledDate,
  onChange = () => undefined,
}) => {
  return (
    <FormItem
      style={{
        border: "2px solid #DBDFEA",
        flex: 1,
        margin: "8px 10px",
        borderRadius: 14,
      }}
      initialValue={initialValue}
      label={label}
      name={name}
      className="noError roundedSelect "
      rules={[
        {
          required: true,
          message: "Please input task",
        },
      ]}
    >
      <DatePicker
        format={DATE_FORMAT}
        onKeyDown={(event) => {
          const input = event.target;
          input.value = MASKED.resolve(input.value);
        }}
        placeholder={DATE_FORMAT.toLowerCase()}
        disabledDate={disabledDate}
        onClick={onClick && onClick}
        className="home_select"
        style={{
          ...styles,
          marginRight: 12,
          borderRadius: 14,
          width: "100%",
          paddingBottom: 12,
          fontSize: 18,
        }}
        onChange={onChange}
      />
    </FormItem>
  );
};
export const FormTimePicker = ({
  name,
  required,
  label,
  initialValue,
  styles,
}) => {
  return (
    <FormItem
      initialValue={initialValue}
      label={label}
      name={name}
      rules={[
        {
          required,
          message: "Please input task",
        },
      ]}
    >
      <TimePicker className="roundedFormItem" format={"HH:mm"} style={styles} />
    </FormItem>
  );
};

export const FormSelect = ({
  name,
  required,
  label,
  placeholder,
  options,
  initialValue,
  styles,
  onChange,
  disabled,
  dropdownStyle,
  mode,
  onClick,
  renderLabel,
  renderValue,
}) => {
  const ref = useRef();

  return (
    <FormItem
      style={{
        flex: 1,
        margin: "8px 10px",
        borderRadius: 14,
      }}
      label={label}
      initialValue={initialValue}
      name={name}
      className="roundedSelect noError"
      rules={[
        {
          required,
          message: false,
        },
      ]}
    >
      <Select
        ref={ref}
        onClick={onClick && onClick}
        mode={mode && mode}
        dropdownStyle={{ borderRadius: 14, ...dropdownStyle }}
        className={`home_select hideValue  ${
          options.length > 0 ? "showValue" : ""
        } `}
        placeholder={placeholder}
        style={styles}
        onChange={onChange}
      >
        {options?.map?.((opt) => (
          <Option
            key={renderValue ? opt[renderValue] : opt}
            value={renderValue ? opt[renderValue] : opt}
          >
            {renderLabel ? opt[renderLabel] : opt}
          </Option>
        ))}
      </Select>
    </FormItem>
  );
};

export const FormSwitch = ({ name, required, label, initialValue }) => {
  return (
    <FormItem
      initialValue={initialValue}
      label={label}
      name={name}
      valuePropName="checked"
      rules={[
        {
          required,
          message: "Please input task",
        },
      ]}
    >
      <Switch />
    </FormItem>
  );
};
export const FormHiddenSubmitButton = ({ submitRef }) => {
  return (
    <Form.Item
      style={{ display: "none" }}
      wrapperCol={{
        offset: 8,
        span: 16,
      }}
    >
      <Button ref={submitRef} type="primary" htmlType="submit"></Button>
    </Form.Item>
  );
};

export const FormInputArea = ({
  name,
  required,
  label,
  placeholder,
  message,
  initialValue,
  styles,
}) => {
  return (
    <FormItem
      initialValue={initialValue}
      label={label}
      name={name}
      rules={[
        {
          required,
          message: "Required",
        },
      ]}
    >
      <TextArea placeholder={placeholder} style={styles} />
    </FormItem>
  );
};
