import React, { useState } from "react";
import { Layout, Menu, Grid, Space, Avatar, Typography } from "antd";
import PropTypes from "prop-types";
import {
  CalendarOutlined,
  EditOutlined,
  LogoutOutlined,
  SettingOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";

import SidebarDrawer from "./components/SidebarDrawer";
import logo from "../../assets/images/logo.png";
import "./Menu.scss";
import { Button } from "components/Buttons/Buttons";

import { useDispatch } from "react-redux";
import { clearStorage } from "utils/storage";
import { push, replace } from "connected-react-router";
import ChangePasswordModal from "components/ChangePasswordModal/ChangePasswordModal";
import { useLocation } from "react-router";
import { setAppointmentDrawer } from "redux/actions/drawer.actions";

const { Sider } = Layout;
const { useBreakpoint } = Grid;

const { Text, Title } = Typography;

// Sider Menu Component
const SideMenu = ({
  className,
  collapsed,
  toggleCollapse,
  onMenuSelect,
  user,
}) => {
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const location = useLocation();
  const isPermissionForSecduler = user?.permissions?.find((permission) => {
    return permission?.permissionName === "Scheduling";
  });
  const isPermissionForSettings = user?.permissions?.find((permission) => {
    return permission?.permissionName === "Settings";
  });
  const isPermissionForGuests = user?.permissions?.find((permission) => {
    return permission?.permissionName === "Guest Profiles";
  });
  const isPermissionForAvailability = user?.permissions?.find((permission) => {
    return permission?.permissionName === "Nurse Availability";
  });
  const isPermissionForChart = user?.permissions?.find((permission) => {
    return permission?.permissionName === "Chart";
  });
  const isPermissionForShift = user?.permissions?.find((permission) => {
    return permission?.permissionName === "Shift";
  });

  const [showPasswordEdit, setShowPasswordEdit] = useState(false);

  const renderMenus = (isMobile) => {
    const roleName = user?.roleName?.trim?.() || "";
    const menuSelectedKeys = location.pathname.includes("/dashboard/settings")
      ? ["/dashboard/settings"]
      : [window.location.pathname ?? "/dasboard"];

    return (
      <Menu
        mode="inline"
        // defaultSelectedKeys={["1"]}
        onSelect={({ key }) => {
          onMenuSelect(key);
          if (!screens.md) toggleCollapse();
        }}
        selectedKeys={menuSelectedKeys}
      >
        {/* <Menu.Item key="/dashboard">
          <PieChartOutlined />
          <span>Dashboard</span>
        </Menu.Item> */}
        {(roleName === "Nurse" ||
          roleName === "Nurse Practitioner" ||
          roleName === "Nurse Lead") && (
          <>
            <Menu.Item key="/dashboard/appointments">
              <UsergroupAddOutlined />
              <span>Appointments</span>
            </Menu.Item>
          </>
        )}
        {isPermissionForSecduler && (
          <Menu.Item key="/dashboard/calendar">
            <CalendarOutlined />
            <span>Calendar</span>
          </Menu.Item>
        )}
        {isPermissionForShift && (
          <Menu.Item key="/dashboard/shifts">
            <CalendarOutlined />
            <span>Shifts</span>
          </Menu.Item>
        )}

        {isPermissionForGuests && (
          <Menu.Item key="/dashboard/guests">
            <UsergroupAddOutlined />
            <span>Guests</span>
          </Menu.Item>
        )}

        {isPermissionForAvailability && (
          <Menu.Item key="/dashboard/availability">
            <UsergroupAddOutlined />
            <span>Availability</span>
          </Menu.Item>
        )}

        {isPermissionForSettings && (
          <>
            <Menu.Item key="/dashboard/staff">
              <UserAddOutlined />
              <span>Staff</span>
            </Menu.Item>
            <Menu.Item key="/dashboard/roles">
              <UserSwitchOutlined />
              <span>Roles</span>
            </Menu.Item>
          </>
        )}

        <Menu.Item key="/dashboard/charts">
          <UsergroupAddOutlined />
          <span>Charts</span>
        </Menu.Item>

        {/* <Menu.Item key="/dashboard/forms-managment">
          <FormOutlined />
          <span>Forms Management</span>
        </Menu.Item> */}
        {isPermissionForSettings && (
          <Menu.Item
            key="/dashboard/settings"
            onClick={() => dispatch(push("/dashboard/settings"))}
          >
            <SettingOutlined />
            <span>Settings</span>
          </Menu.Item>
        )}
        {/* <Menu.Item
          key="/dashboard/inventory"
          onClick={() => dispatch(push("/dashboard/inventory"))}
        >
          <InboxOutlined />
          <span>Inventory</span>
        </Menu.Item> */}

        {isMobile && (
          <li
            className="ant-menu-item"
            onClick={() => {
              setShowPasswordEdit(true);
            }}
          >
            <EditOutlined />
            <span>Change Password</span>
          </li>
        )}
      </Menu>
    );
  };

  return (
    <>
      {screens.md ? (
        <Sider
          className={"desktopSidebar"}
          style={{ background: "#fff" }}
          trigger={null}
          collapsible
          collapsed={collapsed}
          width={220}
        >
          <div className="logo">
            <img src={logo} alt="logo" />
            {/* <span style={{ fontSize: 16, fontWeight: 600, letterSpacing: 0.5, marginLeft: 8 }}> BPAYS101 </span> */}
          </div>
          {renderMenus()}
        </Sider>
      ) : (
        <SidebarDrawer visible={!collapsed} onClose={toggleCollapse}>
          <div
            style={{
              display: "flex",
              height: "100%",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Space size={12} style={{ overflow: "hidden", width: "100%" }}>
                <Avatar
                  src={user?.profileImageUrl}
                  size={50}
                  style={{ backgroundColor: "#88ebc2" }}
                >
                  {user?.firstName?.[0]} {user?.lastName?.[0]}
                </Avatar>

                <div
                  style={{
                    lineHeight: 1,
                    maxWidth: "200px",
                    overflow: "hidden",
                  }}
                >
                  <Title
                    level={4}
                    style={{
                      margin: 0,
                      color: "#fff",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    {user?.firstName} {user?.lastName}
                  </Title>
                  {user?.roleName && (
                    <Text style={{ margin: 0, color: "#CCCED4" }}>
                      {user?.roleName}
                    </Text>
                  )}
                </div>
              </Space>
              <div
                style={{
                  maxWidth: 231,
                  overflow: "hidden",
                }}
              >
                <Button
                  rounded
                  style={{
                    height: 50,
                    fontWeight: 700,
                    marginTop: 32,
                    marginBottom: 16,
                    paddingLeft: 0,
                    paddingRight: 0,
                    width: "100%",
                  }}
                  onClick={() => {
                    dispatch(
                      setAppointmentDrawer({
                        show: true,
                        data: null,
                      })
                    );
                    toggleCollapse();
                  }}
                >
                  Book an Appointment
                </Button>
                {renderMenus(true)}
              </div>
            </div>

            <div>
              <div
                className="drawerFooterLink"
                onClick={() => {
                  clearStorage();
                  dispatch({ type: "RESET" });
                  dispatch(replace("/"));
                }}
              >
                <LogoutOutlined style={{ color: "#fff" }} />
                <Text style={{ color: "#CCCED4" }}>Logout</Text>
              </div>
            </div>
          </div>
        </SidebarDrawer>
      )}
      <ChangePasswordModal
        visible={showPasswordEdit}
        handleSave={() => setShowPasswordEdit(false)}
        onCancel={() => setShowPasswordEdit(false)}
      />
    </>
  );
};

SideMenu.propTypes = {
  onMenuSelect: PropTypes.func,
};

export default SideMenu;
