import React from "react";

import { Typography, Modal } from "antd";
import { Button } from "components/Buttons/Buttons";
import {
  isCancellationPolicyApplicable,
  updateOrderStatus,
  cancelOrder,
  getBookingDetails,
} from "services/booking.service";
import { useDispatch } from "react-redux";
import { setCurrentBooking } from "redux/actions/booking.actions";
import { CloseCircleOutlined } from "@ant-design/icons";

const { Text } = Typography;

export default function CancelButton({ booking, getData }) {
  const { orderStatus, orderId } = booking;
  const isOrderCompleted = orderStatus === 3;

  const dispatch = useDispatch();

  async function checkIsCancelFeeApplicable() {
    return dispatch(isCancellationPolicyApplicable(orderId));
  }

  async function cancelTheOrder(shouldCharge, callback) {
    await dispatch(cancelOrder(booking, shouldCharge));
    if (callback) {
      callback();
    }
    if (getData) getData();
    else {
      // refresh the booking details
      dispatch(
        getBookingDetails(booking.orderId, (booking) => {
          dispatch(setCurrentBooking(booking));
        })
      );
    }
  }

  async function handleCancel() {
    let response = await checkIsCancelFeeApplicable();
    if (response.status === "success") {
      let isCancelFeeApplicable = response.data;
      if (isCancelFeeApplicable) {
        Modal.confirm({
          title: "Cancellation Fee Applicable",
          okText: "Yes",
          cancelText: "No",
          cancelButtonProps: {
            onClick: () => {
              cancelTheOrder(false, () => {
                Modal.destroyAll();
              });
            },
          },
          content: "Do you want to charge a cancellation fee?",
          onOk: () => {
            cancelTheOrder(true);
          },
          closeIcon: <CloseCircleOutlined />,
          maskClosable: true,
          closable: true,
        });
      } else {
        Modal.confirm({
          title: "Cancellation Fee Not Applicable",
          okText: "Yes",
          cancelText: "No",
          content: "Are you sure you want to cancel this appointment?",
          onOk: () => {
            cancelTheOrder(true);
          },
          closeIcon: <CloseCircleOutlined />,
          maskClosable: true,
          closable: true,
        });
      }
    }
  }

  return (
    <>
      {orderStatus === 2 ? (
        <Text style={{ color: "#ca2727" }}>Cancelled</Text>
      ) : (
        <Button
          className="btn-danger"
          rounded
          disabled={isOrderCompleted}
          style={{
            height: 50,
          }}
          onClick={async () => {
            handleCancel();
          }}
        >
          Cancel
        </Button>
      )}
    </>
  );
}
