export const actions = {
  SET_ALL_ORDERS: "SET_ALL_ORDERS",
  SET_CURRENT_ORDER: "SET_CURRENT_ORDER",
  ADD_NEW_ORDER: "ADD_NEW_ORDER",
};

export const setAllOrders = (data) => ({
  type: actions.SET_ALL_ORDERS,
  data,
});
export const setCurrentOrder = (data) => ({
  type: actions.SET_CURRENT_ORDER,
  data,
});
export const addNewOrder = (data) => ({
  type: actions.ADD_NEW_ORDER,
  data,
});
