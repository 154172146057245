import { setAllOrders } from "redux/actions/order.actions";
import { setAllPermissions, setAllRoles } from "redux/actions/roles.actions";
import { setAllDealerActivities } from "../redux/actions/activity.actions";
import { setAllStaff } from "../redux/actions/user.actions";
import { openNotificationWithIcon } from "../utils/Notification";
import { request } from "./verb.services";

export const getAllRoles = (data) => {
  return (dispatch) => {
    return request("Roles/", "get", null, true, null)
      .then(({ data }) => {
        dispatch(setAllRoles(data));
        return data;
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};
export const getAllPermissions = (data) => {
  return (dispatch) => {
    return request("Permissions", "get", null, true, null)
      .then(({ data }) => {
        dispatch(setAllPermissions(data));
        return data;
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};
export const addNewRole = (data, prevSource) => {
  return (dispatch) => {
    return request("Roles/", "post", data, true)
      .then(({ data }) => {
        openNotificationWithIcon(
          "success",
          "Success!",
          "Role added successfully"
        );
        // dispatch(setAllRoles([{ ...data }, ...prevSource]))
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};

export const updateRole = (id, data, prevSource) => {
  return (dispatch) => {
    return request(`Roles/${id}`, "put", data, true)
      .then(({ data }) => {
        openNotificationWithIcon(
          "success",
          "Success!",
          "Role updated successfully"
        );
        // let newSource = [...prevSource]
        // newSource = newSource.map(o => {
        //   if (o.staffId === data.staffId) {
        //     return { ...data }
        //   }
        //   return o;
        // })
        // dispatch(setAllStaff(newSource))
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};

export const deleteRole = (id, prevSource) => {
  return (dispatch) => {
    return request(`Roles/${id}`, "delete", null, true)
      .then(() => {
        openNotificationWithIcon(
          "success",
          "Success!",
          "Role deleted successfully"
        );
        // let newSource = [...prevSource]
        // newSource = newSource.filter(o => o.staffId !== id)
        // dispatch(setAllStaff(newSource))
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data || e?.response?.data?.message
        );
      });
  };
};

export const updateDealerAmount = (id, data, prevSource) => {
  return (dispatch) => {
    return request(`wallets/${id}`, "put", data, true)
      .then(({ data }) => {
        let newSource = [...prevSource];
        newSource = newSource.map((o) => {
          if (o._id === id) {
            return { ...data.data };
          }
          return o;
        });
        dispatch(setAllStaff(newSource));
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};

export const getDealerPaymentActivities = () => {
  return (dispatch) => {
    return request("activities", "get", null, true, { role: "dealer" })
      .then(({ data }) => {
        dispatch(setAllDealerActivities(data.data));
        return data.data;
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};
