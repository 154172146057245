import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import useSettingsHeader from "hooks/useSettingsHeader";
import { Space, Button as AntdButton, Row, Form, Col, Typography } from "antd";
import { Button } from "components/Buttons/Buttons";
import { useDispatch } from "react-redux";
import { push, replace } from "connected-react-router";
import { useHistory, useLocation, useParams } from "react-router";
import { useForm } from "antd/lib/form/Form";
import {
  FormCheckbox,
  FormHiddenSubmitButton,
  FormInput,
  FormInputArea,
  FormInputNumber,
  FormSelect,
  FormTimePicker,
  FormTimeRangePicker,
} from "components/FormItems/FlatFormItems";
import moment from "moment";
import DayShiftHoursField from "./components/DayShiftHoursField";
import Exclusions from "./components/Exclusions";
import {
  createShiftSchedule,
  getShiftScheduleById,
  updateShiftSchedule,
} from "services/locationSettings.service";
import { findWindows } from "windows-iana";

const { Title, Text } = Typography;

function CreateEditShiftSchedule(props) {
  const [form] = useForm();
  const submitBtnRef = useRef();
  const [timezones, setTimezones] = useState(moment.tz.names());
  const [exclusions, setExclusions] = useState([]);

  const windowsTimezones = useMemo(() => {
    const timezones = (moment.tz.names() ?? [])?.filter((it) =>
      it?.startsWith("US")
    );
    const windowsTz = [];
    for (let i = 0; i < timezones.length; i++) {
      windowsTz.push(findWindows(timezones[i])?.[0]);
    }
    const windowsTzSet = new Set(windowsTz);
    return [...windowsTzSet];
  });

  const dispatch = useDispatch();
  const params = useParams();
  const isEdit = params.id !== undefined;

  const getData = async () => {
    const result = await dispatch(getShiftScheduleById(params.id));
    // Extract Complex Controls Data
    if (result?.status === "success") {
      form.setFieldsValue({
        ...result.data,
      });
      setExclusions(result?.data?.exclusions);
    }
  };

  useEffect(() => {
    if (params.id !== undefined) {
      getData();
    }
  }, [params]);

  const handleSubmit = useCallback(
    (values) => {
      if (isEdit) {
        handleUpdate(params.id, values);
      } else {
        handleAdd(values);
      }
    },
    [isEdit]
  );

  const handleAdd = (values) => {
    dispatch(createShiftSchedule(values)).then((result) => {
      if (result?.status === "success") {
        dispatch(
          replace(
            `/dashboard/settings/shift-schedule/${result?.data?.facilityScheduleId}`
          )
        );
        // loadData();
      }
    });
  };

  const handleUpdate = (id, values) => {
    dispatch(updateShiftSchedule(id, values)).then((result) => {
      if (result?.status === "success") {
      }
    });
  };

  const settingHeaderContent = useSettingsHeader(
    <Space align='center' size='large'>
      <AntdButton
        type='text'
        style={{ padding: 0, lineHeight: 1 }}
        onClick={() => dispatch(push("/dashboard/settings/shift-schedules"))}
      >
        Cancel
      </AntdButton>
      <Button rounded={true} onClick={() => submitBtnRef?.current?.click()}>
        {/* {isEdit ? "Edit" : "Create"} */}
        Save
      </Button>
    </Space>
  );

  return (
    <div>
      {settingHeaderContent}
      <div style={{ padding: 24, paddingTop: 16 }}>
        <Form
          preserve={false}
          layout='vertical'
          form={form}
          requiredMark={false}
          onFinish={(values) => {
            const {
              scheduleName,
              timeZone,
              description,
              clinicLeadTime,
              mobileLeadTime,
              telehealthLeadTime,
              scheduleDetail = [],
            } = values;
            const formData = [];
            for (let i = 0; i < scheduleDetail.length; i++) {
              const {
                startTime,
                endTime,
                isClosed,
                dayName,
                callToBook,
                preHoursOfOperationStartTime,
                preHoursOfOperationEndTime,
                postHoursOfOperationStartTime,
                postHoursOfOperationEndTime,
              } = scheduleDetail?.[i] ?? {};
              const formItem = {
                startTime,
                endTime,
                isClosed,
                dayName,
                callToBook,
                preHoursOfOperationStartTime,
                preHoursOfOperationEndTime,
                postHoursOfOperationStartTime,
                postHoursOfOperationEndTime,
              };
              if (!!startTime) {
                formItem.startTime = moment(startTime).format("HH:mm");
              }
              if (!!endTime) {
                formItem.endTime = moment(endTime).format("HH:mm");
              }
              if (!!preHoursOfOperationStartTime) {
                formItem.preHoursOfOperationStartTime = moment(
                  preHoursOfOperationStartTime
                ).format("HH:mm");
              }
              if (!!preHoursOfOperationEndTime) {
                formItem.preHoursOfOperationEndTime = moment(
                  preHoursOfOperationEndTime
                ).format("HH:mm");
              }
              if (!!postHoursOfOperationStartTime) {
                formItem.postHoursOfOperationStartTime = moment(
                  postHoursOfOperationStartTime
                ).format("HH:mm");
              }
              if (!!postHoursOfOperationEndTime) {
                formItem.postHoursOfOperationEndTime = moment(
                  postHoursOfOperationEndTime
                ).format("HH:mm");
              }

              formData.push(formItem);
            }

            handleSubmit({
              scheduleName,
              timeZone,
              description,
              clinicLeadTime,
              mobileLeadTime,
              telehealthLeadTime,
              scheduleDetail: formData,
            });
          }}
          onFinishFailed={({ values, errorFields, outOfDate }) => {
            form.scrollToField(errorFields[0].name, {
              scrollMode: "if-needed",
              block: "center",
              behavior: "smooth",
            });
          }}
        >
          <Row gutter={[24, 11]}>
            <Col xs={24} md={10}>
              <Row gutter={[24, 11]}>
                <Col xs={24}>
                  <FormInput
                    variant='underlined'
                    name='scheduleName'
                    label='Title'
                    placeholder='Enter Title'
                    required
                    formItemStyles={{ marginBottom: 0 }}
                  />
                </Col>
                <Col xs={24}>
                  <FormSelect
                    required
                    className={"searchWithIcon"}
                    variant='underlined'
                    name='timeZone'
                    label='Timezone'
                    placeholder={"Select"}
                    showSearch={true}
                    filterOption={(input, option) => {
                      return option?.value
                        ?.toLowerCase?.()
                        .includes?.(input.toLowerCase());
                    }}
                    // formItemStyles={{ marginBottom: 12 }}
                    options={windowsTimezones}
                    formItemStyles={{ margin: 0 }}

                    // styles={{ background: '#ddd' }}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={14}>
              <FormInputArea
                variant='underlined'
                rows={6}
                name='description'
                label='Description'
                placeholder='Description'
                formItemStyles={{ marginBottom: 0 }}
              />
            </Col>
            <Col xs={24} md={8}>
              <FormInputNumber
                initialValue={1}
                variant='underlined'
                name='clinicLeadTime'
                label='Clinic Lead Time (Hours)'
                placeholder='Enter lead hours'
                required
                min={0}
                formItemStyles={{ marginBottom: 0 }}
              />
            </Col>
            <Col xs={24} md={8}>
              <FormInputNumber
                initialValue={1}
                variant='underlined'
                name='mobileLeadTime'
                label='Mobile Lead Time (Hours)'
                placeholder='Enter lead hours'
                required
                min={0}
                formItemStyles={{ marginBottom: 0 }}
              />
            </Col>
            <Col xs={24} md={8}>
              <FormInputNumber
                initialValue={1}
                variant='underlined'
                name='telehealthLeadTime'
                label='Telehealth Lead Time (Hours)'
                placeholder='Enter lead hours'
                required
                min={0}
                formItemStyles={{ marginBottom: 0 }}
              />
            </Col>
          </Row>
          <Title
            level={4}
            style={{ margin: "16px 0px", color: "#000", paddingTop: 4 }}
          >
            Hours of Operation
          </Title>

          <Row style={{ maxWidth: 720 }} gutter={[0, 16]}>
            <Col xs={24}>
              <DayShiftHoursField
                index={0}
                form={form}
                label='Sunday'
                namePrefix='sunday'
              />
            </Col>
            <Col xs={24}>
              <DayShiftHoursField
                index={1}
                form={form}
                label='Monday'
                namePrefix='monday'
              />
            </Col>
            <Col xs={24}>
              <DayShiftHoursField
                index={2}
                form={form}
                label='Tuesday'
                namePrefix='tuesday'
              />
            </Col>
            <Col xs={24}>
              <DayShiftHoursField
                index={3}
                form={form}
                label='Wednesday'
                namePrefix='wednesday'
              />
            </Col>
            <Col xs={24}>
              <DayShiftHoursField
                index={4}
                form={form}
                label='Thursday'
                namePrefix='thursday'
              />
            </Col>
            <Col xs={24}>
              <DayShiftHoursField
                index={5}
                form={form}
                label='Friday'
                namePrefix='friday'
              />
            </Col>
            <Col xs={24}>
              <DayShiftHoursField
                index={6}
                form={form}
                label='Saturday'
                namePrefix='saturday'
              />
            </Col>
          </Row>
          <FormHiddenSubmitButton submitRef={submitBtnRef} />
        </Form>
        <Exclusions
          shiftScheduleId={params.id}
          disabled={!isEdit}
          data={exclusions}
          getData={getData}
        />
      </div>
    </div>
  );
}

CreateEditShiftSchedule.propTypes = {};

export default CreateEditShiftSchedule;
