import React from "react";
import PropTypes from "prop-types";

import "./AppLoading.style.scss";
import Logo from "assets/images/logo.png";

function AppLoading(props) {
  return (
    <div className="appLoader">
      <img width={260} src={Logo} alt="logo" />
    </div>
  );
}

AppLoading.propTypes = {};

export default AppLoading;
