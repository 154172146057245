import { cloneDeep } from "lodash";
import moment from "moment";
import { setLoading } from "redux/actions/app.actions";
import { openNotificationWithIcon } from "utils/Notification";
import { request } from "./verb.services";

export const getFormByType = (type, showLoading) => {
  return (dispatch) => {
    if (showLoading) dispatch(setLoading(true));
    return request(`Forms?type=${type}`, "get", null, true)
      .then((response) => {
        return {
          data: response.data,
          status: "success",
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        if (showLoading) dispatch(setLoading(false));
      });
  };
};

export const getAllForms = (callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request("Forms", "get", null, true, null)
      .then((res) => {
        return {
          status: "success",
          data: res.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const createForm = (values, showFeedbacks = true, callback) => {
  return (dispatch) => {
    if (showFeedbacks) dispatch(setLoading(true));
    return request(`Forms`, "post", values, true)
      .then((res) => {
        if (showFeedbacks)
          openNotificationWithIcon(
            "success",
            "Success",
            "Form created successfully"
          );
        return { status: "success", data: res?.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        if (showFeedbacks) dispatch(setLoading(false));
      });
  };
};

export const updateForm = (formId, data, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Forms/${formId}`, "put", data, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Form information updated successfully"
        );
        return { status: "success", data: res?.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getFormById = (id, showFeedbacks = true, callback) => {
  return (dispatch) => {
    if (showFeedbacks) dispatch(setLoading(true));
    return request(`Forms/${id}`, "get", null, true)
      .then((res) => {
        return { status: "success", data: res.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        if (showFeedbacks) dispatch(setLoading(false));
      });
  };
};

export const postFormVersion = (id, data = "", showFeedbacks = true) => {
  return (dispatch) => {
    if (showFeedbacks) dispatch(setLoading(true));
    return request(
      `Forms/${id}/Versions`,
      "post",
      { formJSON: data || "{}" },
      true
    )
      .then((res) => {
        if (showFeedbacks)
          openNotificationWithIcon(
            "success",
            "Success",
            "Form design updated successfully"
          );
        return { status: "success", data: res.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        if (showFeedbacks) dispatch(setLoading(false));
      });
  };
};

export const putFormVersion = (id, versionId, data = "") => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Forms/${id}/Versions/${versionId}`,
      "put",
      { formJSON: data || "{}" },
      true
    )
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Form design updated successfully"
        );
        return { status: "success", data: res.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const toggleFormActiveStatus = (id, currentStatus) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Forms/${id}?status=${!currentStatus}`, "patch", null, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Form status updated successfully"
        );
        return { status: "success", data: res.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
export const getChart = (orderId, lineItemId, showFeedback = true) => {
  return (dispatch) => {
    if (showFeedback) dispatch(setLoading(true));
    return request(
      `Charts?orderId=${orderId}&lineItemId=${lineItemId}`,
      "get",
      null,
      true
    )
      .then((res) => {
        return { status: "success", data: res.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        if (showFeedback) dispatch(setLoading(false));
      });
  };
};

export const postChartValues = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Charts/`, "post", data, true, null)
      .then(({ data }) => {
        openNotificationWithIcon(
          "success",
          "Saved",
          "Chart saved successfully!"
        );
        return {
          status: "success",
          data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const putChartValues = (data, chartId) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Charts?chartId=${chartId}`, "put", data, true, { chartId })
      .then(({ data }) => {
        openNotificationWithIcon(
          "success",
          "Saved",
          "Chart saved successfully!"
        );
        return {
          status: "success",
          data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };
};
/**
 * getForm gets the consent/intake form data based on formVersionId
 * @param {formId: 0, versionId: 0, formValueId: 0} params
 * @returns
 */
export const getForm = (
  params = {
    formId: 0,
    versionId: 0,
    formValueId: 0,
  }
) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Forms/Values/${params?.formValueId}`, "get", null, true)
      .then((res) => {
        return { status: "success", data: res.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const postFormValues = (formId, versionId, data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `/api/Forms/${formId}/Versions/${versionId}/Values`,
      "post",
      data,
      true,
      null
    )
      .then(({ data }) => {
        openNotificationWithIcon(
          "success",
          "Saved",
          "Form saved successfully!"
        );
        return {
          status: "success",
          data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const putFormValues = (data, chartId) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Charts?chartId=${chartId}`, "put", data, true, { chartId })
      .then(({ data }) => {
        openNotificationWithIcon(
          "success",
          "Saved",
          "Chart saved successfully!"
        );
        return {
          status: "success",
          data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const copyForm = (data, formId, callback) => {
  return (dispatch) => {
    Promise.all([
      dispatch(createForm(data, false)),
      dispatch(getFormById(formId, false)),
    ])
      .then((responses) => {
        const res1 = responses?.[0];
        const res2 = responses?.[1];
        if (res1?.status === "success" && res2?.status === "success") {
          const newFormId = res1?.data?.formId;
          const designJSON = res2?.data?.designJSON;
          dispatch(postFormVersion(newFormId, designJSON, false)).then(
            (res) => {
              if (res?.status === "success") {
                openNotificationWithIcon(
                  "success",
                  "Saved",
                  "Form copied successfully!"
                );
                callback?.();
              }
            }
          );
        } else {
          dispatch(setLoading(false));
          callback?.();
          throw new Error();
        }
      })
      .catch((e) => {
        dispatch(setLoading(false));
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};

export const getFormsAuditHistory = (guestId, formType, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Guests/${guestId}/FormTypes/${formType}`,
      "get",
      null,
      true,
      null
    )
      .then((res) => {
        return {
          status: "success",
          data: res.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
