import React, { useState, useEffect, useMemo } from "react";
import {
  Typography,
  Space,
  Row,
  Col,
  Form,
  Button as AntdButton,
  Avatar,
  Popover,
} from "antd";

// TODO: make outlined selector a generic component
import OutlinedSelector from "components/EventDrawer/components/OutlinedSelector";
import { Button } from "components/Buttons/Buttons";

import moment from "moment";
import FormItem from "antd/lib/form/FormItem";
import { useDispatch, useSelector } from "react-redux";
import { assignNurseToBooking } from "services/booking.service";
import {
  ExclamationCircleOutlined,
  PhoneOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import {
  addonsTextReducer,
  convertMinsToHrsMins,
  isNurseRole,
} from "utils/common";
import { ProductType } from "utils/enums";

const { Title, Text } = Typography;
export default function NewBookingCard({
  booking,
  staff,
  facilities,
  appointmentDetailClick,
}) {
  const {
    orderId,
    serviceDate,
    facilityId,
    customerFullName,
    lineItems,
    operationTypeId,
    orderDuration,
    hasContraindication,
    isMultipleGuestBooking,
  } = booking;

  const [isFocused, setIsFocused] = useState(false);
  const facility = useMemo(() => {
    return facilities.find((facility) => {
      return facility.facilityId === facilityId;
    });
  }, [facilityId, facilities]);
  const dispatch = useDispatch();

  const { allRoles } = useSelector((state) => ({
    allRoles: state.roles.allRoles,
  }));

  const facilityServiceDate = moment(serviceDate);

  const getBorder = () => {
    if (isMultipleGuestBooking) return "#240cd5";
    else if (hasContraindication) return "#ff4d4f";
    else return "#d8dce4";
  };
  const getBackground = () => {
    if (isMultipleGuestBooking) return "#f0e3ff";
    else if (hasContraindication) return "#fff1f0";
    else return "#fff";
  };

  return (
    <div
      onClick={() => {
        setIsFocused((state) => !state);
      }}
      style={{
        borderColor: getBorder(),
        background: getBackground(),
      }}
      className={`newBookingCard ${isFocused ? "focused" : ""}`}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 12,
        }}
      >
        <Title
          level={4}
          style={{
            margin: 0,
            fontSize: 14,
            color: "#323232",
          }}
        >
          {facilityServiceDate.format("h:mm A")} -{" "}
          {facilityServiceDate.add(orderDuration ?? 1, "hour").format("h:mm A")}{" "}
          {facilityServiceDate.format("[(]DD MMM YYYY[)]")}
        </Title>
        {operationTypeId !== ProductType.Telehealth ? (
          <Title
            level={4}
            style={{
              margin: 0,
              fontSize: 14,
              color: "#323232",
              textAlign: "right",
            }}
          >
            {facility?.facilityName}
          </Title>
        ) : (
          <VideoCameraOutlined style={{ fontSize: 17 }} />
        )}
      </div>
      <Title
        level={4}
        style={{
          color: "#323232",
          marginBottom: 0,
          lineHeight: 1,
        }}
      >
        {customerFullName}
      </Title>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Text style={{ paddingTop: 4 }}>
          {lineItems?.[0]?.productName}
          {lineItems?.[0]?.durationHours !== undefined &&
            lineItems?.[0]?.durationHours >= 1 &&
            ` ${convertMinsToHrsMins(
              Number(lineItems?.[0]?.durationHours) * 60
            )}`}
          {lineItems?.[0]?.addOns?.length > 0 &&
            ` with ${lineItems?.[0]?.addOns?.reduce?.(addonsTextReducer, "")} `}
        </Text>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 4,
          }}
        >
          {hasContraindication && (
            <Popover
              content={"Order has a contraindication."}
              title="Warning"
              trigger="hover"
            >
              <ExclamationCircleOutlined
                style={{ color: "#323232", fontSize: 17 }}
              />
            </Popover>
          )}

          <AntdButton
            style={{ padding: 0, marginLeft: 12 }}
            type="text"
            onClick={(e) => {
              e.stopPropagation();
              appointmentDetailClick();
            }}
          >
            View Details
          </AntdButton>
        </div>
      </div>

      {isFocused && (
        <Form
          requiredMark={false}
          layout="vertical"
          style={{
            display: "flex",
            // alignItems: "flex-end",
            justifyContent: "space-between",
            cursor: "default",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onFinish={(values) => {
            dispatch(assignNurseToBooking(booking, values.staffId));
            // TODO: Attach nurse to booking call
          }}
        >
          <OutlinedSelector
            label="Assign Clinician"
            options={staff.filter(
              (staff) =>
                (staff.facilityIds?.includes(booking.facilityId) &&
                  staff.isActive &&
                  isNurseRole(staff, allRoles)) ||
                (staff.roleName === "Admin" && staff.isActive)
            )}
            formStyle={{ marginBottom: 0, marginRight: 16 }}
            name="staffId"
            required={true}
            renderValue="staffId"
            renderLabel="name"
            isSearch
            showSearch={true}
            optionLabelProp="label"
            filterOption={(input, option) => {
              return option?.label
                ?.toLowerCase?.()
                .includes?.(input.toLowerCase());
            }}
            renderOption={(opt) => {
              return (
                <div>
                  <Avatar
                    src={opt?.profileImageUrl}
                    icon={<UserOutlined />}
                    style={{ marginRight: 6 }}
                  />
                  {opt["name"]}
                </div>
              );
            }}
          />

          <Button
            htmlType={"submit"}
            rounded={true}
            style={{ height: 50, marginTop: 35 }}
          >
            Assign
          </Button>
        </Form>
      )}
    </div>
  );
}
