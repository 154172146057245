import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { Row, Col, Upload, Input, List, Typography, Empty } from "antd";
import { Button } from "components/Buttons/Buttons";

// import { uploadFile } from "services/common.services";
import { useDispatch } from "react-redux";

import SearchOutlined from "@ant-design/icons/SearchOutlined";
import { setLoading } from "redux/actions/app.actions";
import { getCustomerFiles, uploadCustomerFile } from "services/guests.services";
import { FormSelect } from "components/FormItems/FlatFormItems";
import { getChartsByUser } from "services/charts.services";
import moment from "moment";
import { openChartURL } from "utils/common";

const { Title, Text } = Typography;

function ChartsTab({ user }) {
  const dispatch = useDispatch();

  const [filesList, setFilesList] = useState([]);
  const [search, setSearch] = useState("");
  const [file, setFile] = useState(null);
  const [base64, setBase64] = useState(null);

  const ref = useRef();

  const getData = async () => {
    dispatch(setLoading(true));
    const data = await dispatch(getChartsByUser(user?.customerId));
    setFilesList(data.reverse());
    dispatch(setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  const getChartUrl = (orderId, formValueId, name) => {
    switch (name) {
      case "Abdominal Discomfort":
        return `/dashboard/charts/${orderId}/abdominalDiscomfort/${formValueId}`;
      case "Allergies":
        return `/dashboard/charts/${orderId}/allergies/${formValueId}`;
      case "Cold & Flu":
        return `/dashboard/charts/${orderId}/coldAndFlu/${formValueId}`;
      case "Covid Testing":
        return `/dashboard/charts/${orderId}/covidTesting/${formValueId}`;
      case "Dehydration":
        return `/dashboard/charts/${orderId}/dehydration/${formValueId}`;
      case "Energy":
        return `/dashboard/charts/${orderId}/energyBoost/${formValueId}`;
      case "Fatigue & Jet Lag":
        return `/dashboard/charts/${orderId}/fatigueAndJetLag/${formValueId}`;
      case "Hangover Relief":
        return `/dashboard/charts/${orderId}/hangoverRelief/${formValueId}`;
      case "NAD+ (High Dose)":
        return `/dashboard/charts/${orderId}/highDoseNAD/${formValueId}`;
      case "Hydration":
        return `/dashboard/charts/${orderId}/hydration/${formValueId}`;
      case "IM Injection":
        return `/dashboard/charts/${orderId}/iMInjection/${formValueId}`;
      case "Migraine":
        return `/dashboard/charts/${orderId}/migraine/${formValueId}`;
      case "Mental Clarity":
        return `/dashboard/charts/${orderId}/mentalClarity/${formValueId}`;
      case "Immunity & Defense":
        return `/dashboard/charts/${orderId}/immunityBoostDefense/${formValueId}`;
      case "NAD+ (Low Dose)":
        return `/dashboard/charts/${orderId}/lowDoseNAD/${formValueId}`;
      case "Myers Cocktail":
        return `/dashboard/charts/${orderId}/myersCocktail/${formValueId}`;
      case "Nausea & Vomiting Relief":
        return `/dashboard/charts/${orderId}/nauseaAndVomiting/${formValueId}`;
      case "Performance & Recovery":
        return `/dashboard/charts/${orderId}/performanceAndRecovery/${formValueId}`;
      case "Weight Management":
        return `/dashboard/charts/${orderId}/weightManagement/${formValueId}`;
      default:
        return `/dashboard/charts/${orderId}/beautifyAndGlow/${formValueId}`;
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <Row>
        <Col xs={24} md={24}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={24}>
              <Input
                onChange={(e) => setSearch(e.target.value)}
                className="documentsSearchbar"
                placeholder="Search Chart"
                prefix={<SearchOutlined />}
              />
            </Col>

            <Col span={24}>
              {filesList.length ? (
                <List
                  className="documentsList"
                  header={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Title
                        level={5}
                        className="tradeGothic colorPrimary"
                        style={{ margin: 0 }}
                      >
                        Chart Name
                      </Title>
                      <Title
                        level={5}
                        className="tradeGothic colorPrimary"
                        style={{ margin: 0 }}
                      >
                        Signed By
                      </Title>
                    </div>
                  }
                  dataSource={
                    !search
                      ? filesList
                      : filesList.filter((d) =>
                          d.chartName
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        )
                  }
                  renderItem={(item, index) => {
                    return (
                      <div
                        className="documentListItem"
                        onClick={() =>
                          openChartURL(
                            item.orderId,
                            item.orderLineItemId,
                            item.chartName,
                            item
                          )
                        }
                      >
                        <div>
                          <Text style={{ fontSize: 17, cursor: "pointer" }}>
                            {" "}
                            {item.chartName
                              ? item.chartName
                              : `Chart ${index + 1}`}
                          </Text>
                          <Text style={{ fontSize: 17, cursor: "pointer" }}>
                            {" "}
                            {item.staffSignedOffDate &&
                              moment(item.staffSignedOffDate).format(
                                "DD MMM YYYY"
                              )}
                          </Text>
                        </div>
                        {item.physicianSignature && (
                          <Text style={{ fontSize: 14, color: "#7F889A" }}>
                            Signed By: {item.staffName}
                          </Text>
                        )}
                      </div>
                    );
                  }}
                />
              ) : (
                <Empty description={"No Charts Available"} />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

ChartsTab.propTypes = {};

export default ChartsTab;
