import React from "react";
import PropTypes from "prop-types";
import { Button } from "components/Buttons/Buttons";
import Modal from "components/Modal/Modal";

function PlaceHolderModal({ open, onClose, data }) {
  return (
    <Modal
      destroyOnClose={true}
      width="940px"
      className="communicationPlaceholderModal"
      title="Placeholders Info"
      onCancel={onClose}
      visible={open}
      onOk={() => console.log("ok")}
      okText="Add"
      centered={true}
      footer={
        <div className="d-flex justify-end align-center">
          <Button
            style={{ height: 50 }}
            rounded={true}
            onClick={onClose}
            //   disabled={!Boolean(setupIntent)}
          >
            Close
          </Button>
        </div>
      }
      //   cancelText="No"
    >
      <div style={{display:'block'}}>
      {data.map((it) => {
        return (
          <div key={it.id} className="communicationsPlaceholderItem">
            <div>{it.title}</div>
            <div>{it.description}</div>
          </div>
        );
      })}
      </div>
    </Modal>
  );
}

PlaceHolderModal.propTypes = {};

export default PlaceHolderModal;
