import { setLoading } from "redux/actions/app.actions";
import { openNotificationWithIcon } from "../utils/Notification";
import { request } from "./verb.services";
import { getStateAndCountryFromZip } from "./locationCenter.services";

export const getAllAddresses = (guestId, showLoading = true) => {
  return (dispatch) => {
    if (showLoading) dispatch(setLoading(true));
    return request(`Guests/Addresses?guestId=${guestId}`, "get", null, true)
      .then((response) => {
        // dispatch(setAddresses(response.data));
        return {
          status: "success",
          data: response.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        if (showLoading) dispatch(setLoading(false));
      });
  };
};

export const createAddress = (guestId, data) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    const { state, country, zipCode } = await getStateAndCountryFromZip(
      data.address
    );
    if (!state || !country || !zipCode) {
      dispatch(setLoading(false));
      openNotificationWithIcon(
        "error",
        "Error!",
        "Please enter a valid street address"
      );
      return;
    }
    return request(
      `Guests/Addresses?guestId=${guestId}`,
      "post",
      {
        address: data.address,
        state: state,
        country: country,
        zipCode: zipCode,
      },
      {
        contentType: "application/json",
      }
    )
      .then((response) => {
        // dispatch(getAllAddresses());
        openNotificationWithIcon(
          "success",
          "Success!",
          "Your address has been added successfully"
        );
        return { status: "success", data: response?.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const setPrimaryAddress = (guestId, addressId) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Guests/Addresses/${addressId}/Primary?guestId=${guestId}`,
      "patch",
      null,
      true
    )
      .then((response) => {
        // dispatch(setPrimaryAddressAction(addressId));
        openNotificationWithIcon(
          "success",
          "Success!",
          "Your primary address has been updated successfully"
        );
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const setAddressInstruction = (guestId, addressId, data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Guests/Addresses/${addressId}/Instructions?guestId=${guestId}`,
      "patch",
      JSON.stringify(data.instructions),
      {
        contentType: "application/json",
      }
    )
      .then((response) => {
        // dispatch(
        //   updateAddressAction({
        //     instructions: data.instructions,
        //     addressId: addressId,
        //   })
        // );
        openNotificationWithIcon(
          "success",
          "Success!",
          "Instructions for the address have been updated successfully"
        );
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const updateAddress = (guestId, addressId, data) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    const { state, country, zipCode } = await getStateAndCountryFromZip(
      data.address
    );
    if (!state || !country || !zipCode) {
      dispatch(setLoading(false));
      openNotificationWithIcon(
        "error",
        "Error!",
        "Please enter a valid street address"
      );
      return;
    }
    return request(
      `Guests/Addresses/${addressId}?guestId=${guestId}`,
      "put",
      {
        address: data.address,
        state,
        country,
        zipCode,
      },
      true
    )
      .then((response) => {
        openNotificationWithIcon(
          "success",
          "Success!",
          "Address has been updated successfully"
        );
        return { status: "success", data: response?.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message || "Networ k error has occured"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const deleteAddress = (guestId, id) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Guests/Addresses/${id}?guestId=${guestId}`,
      "delete",
      null,
      true
    )
      .then((response) => {
        // dispatch(deleteAddressAction(id));
        openNotificationWithIcon(
          "success",
          "Success!",
          "Address removed successfully"
        );
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };
};
