import { CalenderOperationType } from "utils/enums";
import { setAllDealerActivities } from "../redux/actions/activity.actions";
import {
  addStaff,
  setAllStaff,
  setWholeStaff,
} from "../redux/actions/user.actions";
import { openNotificationWithIcon } from "../utils/Notification";
import { request } from "./verb.services";
import { setLoading } from "redux/actions/app.actions";

export const getAllStaff = (facilityId, roleNames = []) => {
  const roleNamesQs =
    typeof roleNames === "object" ? roleNames?.join("&roleNames=") : roleNames;

  return (dispatch) => {
    return request(
      `Staffs?facilityId=${facilityId ?? ""}&roleNames=${
        roleNamesQs.length ? roleNamesQs : ""
      }`,
      "get",
      null,
      true
    )
      .then(({ data }) => {
        // TODO: get name from backend
        data.forEach(
          (staff) => (staff.name = staff.firstName + " " + staff.lastName)
        );
        if (facilityId) dispatch(setAllStaff(data));
        else dispatch(setWholeStaff(data));
        return data;
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};

// This api gets filtered staff per facilityId and Day
// IT REQUIRES facilityId, rolesNames and date
export const getCalendarStaff = (facilityId, roleNames = [], date) => {
  const roleNamesQs =
    typeof roleNames === "object" ? roleNames?.join("&roleNames=") : roleNames;

  let facilityIdQs =
    typeof facilityId === "object"
      ? `facilityIds=${facilityId.join("&facilityIds=")}`
      : `facilityIds=${facilityId}`;

  if (!facilityId || facilityId?.length < 1) {
    facilityIdQs = "";
  }

  return (dispatch) => {
    return request(
      `Staffs/Filtered?${facilityIdQs}&roleNames=${
        roleNamesQs.length ? roleNamesQs : ""
      }&rosterDate=${date}`,
      "get",
      null,
      true
    )
      .then(({ data }) => {
        // TODO: get name from backend
        data.forEach(
          (staff) => (staff.name = staff.firstName + " " + staff.lastName)
        );
        if (facilityId) dispatch(setAllStaff(data));
        else dispatch(setWholeStaff(data));
        return data;
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};

export const getAllTelehealthStaff = (roleNames = []) => {
  const roleNamesQs =
    typeof roleNames === "object" ? roleNames?.join("&roleNames=") : roleNames;

  return (dispatch) => {
    return request(
      `Staffs/OperationType/${CalenderOperationType.Telehealth}?roleNames=${
        roleNamesQs.length ? roleNamesQs : ""
      }`,
      "get",
      null,
      true
    )
      .then(({ data }) => {
        // TODO: get name from backend
        data.forEach(
          (staff) => (staff.name = staff.firstName + " " + staff.lastName)
        );
        dispatch(setAllStaff(data));
        return data;
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};

export const addNewStaff = (data, prevSource) => {
  return (dispatch) => {
    if (data.imageBase64String) {
      data.imageBase64String = data.imageBase64String.split("base64,")[1];
    }
    // dispatch(setAllDealer([...prevSource, { ...data }]))
    return request("Staffs/", "post", data, true)
      .then(({ data }) => {
        // data.name = data?.firstName + " " + data?.lastName;
        // dispatch(addStaff(data));
        openNotificationWithIcon(
          "success",
          "Success",
          "Staff added successfully"
        );
        return { status: "success", data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] || e?.response?.data?.message
        );
      });
  };
};

export const updateStaff = (id, data, prevSource) => {
  if (data.imageBase64String) {
    data.imageBase64String = data.imageBase64String.split("base64,")[1];
  }
  return (dispatch) => {
    return request(`Staffs/${id}`, "put", data, true)
      .then(({ data }) => {
        // let newSource = [...prevSource];
        // data.name = data.firstName + " " + data.lastName;
        // newSource = newSource.map((o) => {
        //   if (o.staffId === data.staffId) {
        //     return { ...data };
        //   }
        //   return o;
        // });
        dispatch(setLoading(true));
        dispatch(getAllStaff()).finally(() => dispatch(setLoading(false)));

        openNotificationWithIcon(
          "success",
          "Success",
          "Staff updated successfully"
        );
        // dispatch(setWholeStaff(newSource));
        return { status: "success", data };
      })
      .catch((e) => {
        console.log(e);
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};

export const inactivateStaff = (id, prevSource) => {
  return (dispatch) => {
    return request(`Staffs/${id}/Status`, "patch", null, true)
      .then(() => {
        // let newSource = [...prevSource];
        // newSource = newSource.filter((o) => o.staffId !== id);
        openNotificationWithIcon(
          "success",
          "Success",
          "Staff inactivated successfully"
        );
        // dispatch(setWholeStaff(newSource));
        return { status: "success" };
      })
      .catch((e) => {
        console.log(e);
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};

export const restoreStaff = (id, prevSource) => {
  return (dispatch) => {
    return request(`Staffs/${id}/Status`, "patch", null, true)
      .then(() => {
        // let newSource = [...prevSource];
        // newSource = newSource.filter((o) => o.staffId !== id);
        openNotificationWithIcon(
          "success",
          "Success",
          "Staff activated successfully"
        );
        // dispatch(setWholeStaff(newSource));
        return { status: "success" };
      })
      .catch((e) => {
        console.log(e);
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};

export const deleteStaff = (id) => {
  return (dispatch) => {
    return request(`Staffs/${id}`, "delete", null, true)
      .then(() => {
        // let newSource = [...prevSource];
        // newSource = newSource.filter((o) => o.staffId !== id);
        openNotificationWithIcon(
          "success",
          "Success",
          "Staff deleted successfully"
        );
        // dispatch(setWholeStaff(newSource));
        return { status: "success" };
      })
      .catch((e) => {
        console.log(e);
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};

export const updateDealerAmount = (id, data, prevSource) => {
  return (dispatch) => {
    return request(`wallets/${id}`, "put", data, true)
      .then(({ data }) => {
        let newSource = [...prevSource];
        newSource = newSource.map((o) => {
          if (o._id === id) {
            return { ...data.data };
          }
          return o;
        });
        dispatch(setAllStaff(newSource));
      })
      .catch((e) => {
        console.log(e);
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};

export const getDealerPaymentActivities = () => {
  return (dispatch) => {
    return request("activities", "get", null, true, { role: "dealer" })
      .then(({ data }) => {
        dispatch(setAllDealerActivities(data.data));
        return data.data;
      })
      .catch((e) => {
        console.log(e);
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};

// facilityId
//           ? `?facilityId=${facilityId}${
//               roleNamesQs !== undefined &&
//               roleNamesQs !== null &&
//               roleNamesQs !== ""
//                 ? `&roleNames=${roleNamesQs}`
//                 : ""
//             }`
//           : ""
