import { setUser } from "../redux/actions/user.actions";
import { openNotificationWithIcon } from "../utils/Notification";
import { getItem, setItem } from "../utils/storage";
import { request } from "./verb.services";
import { setLoading } from "redux/actions/app.actions";
import { push } from "connected-react-router";
import queryString from "query-string";

export const changePassword = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request("Account/Password", "patch", data, true)
      .then((response) => {
        openNotificationWithIcon(
          "success",
          "Password Updated!",
          "Password has been changed successfully"
        );
        return { status: "success" };
      })
      .catch((e, f) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.message ||
            e?.response?.data?.[0]?.description ||
            "Your Password is incorrect"
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const staffInviteReset = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Account/ResetPassword`, "put", data, false)
      .then(async (response) => {
        const loginData = {
          username: data.userName,
          password: data.newPassword,
        };
        await dispatch(loginUser(loginData));
        dispatch(push("/"));
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data ??
            e?.response?.data?.[0]?.code ??
            "Network error has occured"
        );
        dispatch(setLoading(false));
      });
  };
};

export const loginUser = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request("Account/Login", "post", data, false)
      .then(({ data }) => {
        dispatch(setUser(data.user));
        setItem("user", data.user);
        setItem("tokenContainer", { token: data.token });
      })
      .catch((e) => {
        let errorMesssage = e?.response?.data?.message ?? e.message;
        if (e.response.status === 401 || e.response.status === 403) {
          errorMesssage = "Incorrect username or password";
        }
        openNotificationWithIcon(
          "error",
          "Error!",
          errorMesssage || "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const verifyUser = () => {
  return (dispatch) => {
    return request("Account/Verify", "get", null, true)
      .then((result) => {
        // TODO: REMOVE THIS OPTION OBJ
        const { data } = result;
        dispatch(
          setUser(
            data || {
              emai: "test@test.com",
              firstName: "Test",
              lastname: "Test",
              userName: "Test",
            }
          )
        );
        setItem(
          "user",
          data || {
            emai: "test@test.com",
            firstName: "Test",
            lastname: "Test",
            userName: "Test",
          }
        );
        return {
          status: "success",
          data: data || {
            emai: "test@test.com",
            firstName: "Test",
            lastname: "Test",
            userName: "Test",
          },
        };
      })
      .catch((e) => {
        console.log(e);
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message || "Unable to verify authentication token"
        );
      });
  };
};

export const resetPassword = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Account/ResetPassword`, "put", data, false)
      .then((response) => {
        openNotificationWithIcon(
          "success",
          "Success!",
          "Password has been changed successfully"
        );
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0]?.code ?? "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const forgotPassword = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Account/ForgotPassword?${queryString.stringify(data)}`,
      "put",
      {},
      false
    )
      .then((response) => {
        openNotificationWithIcon(
          "info",
          "Reset email sent",
          "Password reset email sent successfully"
        );
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data ?? "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
