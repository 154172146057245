import React, { useEffect, useReducer, useState } from "react";

import "./ProductContraindications.style.scss";
import { Button } from "components/Buttons/Buttons";
import { Dropdown, Menu, Modal, Select, Space, Table, Typography } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import useSettingsHeader from "hooks/useSettingsHeader";
import ProductContraindicationsDrawer from "./components/ProductContraindicationsDrawer";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  createProductContraindication,
  deleteProductContraindication,
  getAllProductContraindications,
  restoreProductContraindication,
  updateProductContraindication,
} from "services/productContraindications.service";
import confirm from "antd/lib/modal/confirm";
import usePortal from "hooks/usePortal";
import SearchAndFilterColumns from "components/SearchAndFilterColumns/SearchAndFilterColumns";
import { push } from "connected-react-router";
import { getAllServices } from "services/servicesAndBoostsSettings.service";
import { getAllContraindications } from "services/contraindications.service";
const { Title, Text, Link } = Typography;

const reducer = (state, action) => {
  switch (action.type) {
    case "SET":
      return action.data;
    case "ADD":
      return [...state, action.data];
    case "UPDATE":
      return state.map((data) => {
        if (
          data.productContraindicationId ===
          action.data.productContraindicationId
        )
          return action.data;
        return data;
      });
    case "DELETE":
      return state.filter((data) => {
        if (
          data.productContraindicationId ===
          action.data.productContraindicationId
        )
          return false;
        return true;
      });
    default:
      return state;
  }
};

export default function ProductContraindications() {
  //states start
  const [showAddEditModal, setShowAddEditModal] = useState();
  const [data, localDispatch] = useReducer(reducer, []);
  const [services, setServices] = useState({
    data: [],
    isLoading: true,
  });
  const [contraindications, setContraindications] = useState({
    data: [],
    isLoading: true,
  });

  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [activeFilter, setActiveFilter] = useState("active"); // all, active, inactive
  const [searchText, setSearchText] = useState("");
  //states end

  // non-data hooks start
  const dispatch = useDispatch();
  // non-data hooks end

  // use effect starts
  const loadData = async () => {
    const result = await dispatch(getAllProductContraindications());
    if (result?.status === "success") {
      localDispatch({
        type: "SET",
        data: result.data,
      });
    }
  };
  useEffect(() => {
    loadData();
    dispatch(getAllServices(null, false)).then((result) => {
      if (result?.status === "success") {
        setServices({
          data: result?.data ?? [],
          isLoading: false,
        });
      }
    });
    dispatch(getAllContraindications(null, false)).then((result) => {
      if (result?.status === "success") {
        setContraindications({
          data: result?.data ?? [],
          isLoading: false,
        });
      }
    });
  }, []);
  // use effects end

  // functions start
  const handleAdd = (values) => {
    dispatch(createProductContraindication(values)).then((result) => {
      if (result?.status === "success") {
        setShowAddEditModal();
        loadData();
        // loadData();
      }
    });
  };
  const handleUpdate = (id, values) => {
    dispatch(updateProductContraindication(id, values)).then((result) => {
      if (result?.status === "success") {
        setShowAddEditModal();
        loadData();
      }
    });
  };
  const handleDelete = (id) => {
    dispatch(deleteProductContraindication(id)).then((result) => {
      if (result?.status === "success") {
        loadData();
      }
    });
  };
  const handleRestore = (id) => {
    dispatch(restoreProductContraindication(id)).then((result) => {
      if (result?.status === "success") {
        loadData();
      }
    });
  };
  const deleteConfirmation = (id) => ({
    title: "Inactivate Contraindication",
    content: (
      <>Are you sure that you want to inactivate the contraindication?</>
    ),
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      handleDelete(id);
      close();
    },
  });

  const restoreConfirmation = (id) => ({
    title: "Activate Contraindication",
    content: <>Are you sure that you want to activate the contraindication?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      handleRestore(id);
      close();
    },
  });

  const checkActiveFilter = (item) => {
    if (activeFilter === "all") return true;
    else if (activeFilter === "active" && item?.isActive) return true;
    else if (activeFilter === "inactive" && !item?.isActive) return true;
    return false;
  };
  // functions end

  const settingHeaderContent = useSettingsHeader(
    <Button
      rounded={true}
      onClick={() => {
        setShowAddEditModal(true);
      }}
    >
      Create Contraindication Rule
    </Button>
  );

  const columns = [
    {
      title: "Service Name",
      dataIndex: "productName",
      key: "productName",
      width: 240,

      sorter: (a, b) => {
        if (a.productName?.toLowerCase?.() < b.productName?.toLowerCase?.())
          return -1;
        if (a.productName?.toLowerCase?.() > b.productName?.toLowerCase?.())
          return 1;
        return 0;
      },
    },
    {
      title: "Contraindication",
      dataIndex: "contraindicationName",
      key: "contraindicationName",

      sorter: (a, b) => {
        if (
          a.contraindicationName?.toLowerCase?.() <
          b.contraindicationName?.toLowerCase?.()
        )
          return -1;
        if (
          a.contraindicationName?.toLowerCase?.() >
          b.contraindicationName?.toLowerCase?.()
        )
          return 1;
        return 0;
      },
    },
    {
      title: "Active",
      key: "active",
      width: 100,
      render: (data) => {
        if (data?.isActive) return "Active";
        return "Inactive";
      },
    },
    {
      title: "",
      key: "action",
      align: "right",
      width: 80,
      render: (data) => {
        return (
          <Dropdown
            overlay={
              <Menu
                style={{ borderRadius: 6, border: "2px solid #CDD5D8 " }}
                key="edit"
              >
                <Menu.Item onClick={() => setShowAddEditModal(data)}>
                  Edit
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    if (data?.isActive)
                      Modal.warning(
                        deleteConfirmation(data.productContraindicationId)
                      );
                    else
                      Modal.warning(
                        restoreConfirmation(data.productContraindicationId)
                      );
                  }}
                  key="delete"
                >
                  {data?.isActive ? "Inactivate" : "Activate"}
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
            getPopupContainer={(triggerNode) => triggerNode?.parentNode}
          >
            <MoreOutlined style={{ fontSize: 18 }} />
          </Dropdown>
        );
      },
    },
  ];

  const searchAndFiltersContent = usePortal(
    "#settingHeader .settingsHeaderLeftSideAddon",
    <Space>
      <SearchAndFilterColumns
        columns={columns}
        hiddenColumns={hiddenColumns}
        setHiddenColumns={(columnTitles) => {
          setHiddenColumns(columnTitles);
        }}
        searchText={searchText}
        setSearchText={(text) => {
          setSearchText(text);
        }}
      />
      <Select
        style={{ width: 120 }}
        value={activeFilter}
        onChange={(value) => {
          setActiveFilter(value);
        }}
        options={[
          {
            value: "all",
            label: "All",
          },
          {
            value: "active",
            label: "Active",
          },
          {
            value: "inactive",
            label: "Inactive",
          },
        ]}
      />
    </Space>
  );

  return (
    <div>
      {settingHeaderContent}
      {searchAndFiltersContent}
      <Table
        tableLayout="auto"
        locale={{ emptyText: "No Contraindications" }}
        dataSource={data?.filter(
          (it) =>
            checkActiveFilter(it) &&
            it?.productName
              ?.toLowerCase?.()
              .includes?.(searchText.toLowerCase())
        )}
        rowKey={(record) => record.productContraindicationId}
        columns={columns.filter((column) => {
          if (!hiddenColumns.includes(column.title)) return true;
          return false;
        })}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: [15, 25, 50, 100, 250],
        }}
        // scroll={{ y: "calc(100vh - 80px - 96px - 60px - 100px)", x: true }}
      />

      <ProductContraindicationsDrawer
        data={showAddEditModal}
        services={services?.data}
        isServicesLoading={services?.isLoading}
        contraindications={contraindications?.data}
        isContraindicationsLoading={contraindications?.isLoading}
        visible={showAddEditModal}
        onClose={() => {
          setShowAddEditModal();
        }}
        onSuccess={(values) => {
          const isEdit =
            showAddEditModal?.productContraindicationId !== undefined;
          if (isEdit)
            handleUpdate(showAddEditModal?.productContraindicationId, values);
          else handleAdd(values);
        }}
      />
    </div>
  );
}
