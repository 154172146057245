import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Form, Row, Col, Button as AntdButton, Tabs, Typography } from "antd";
import { Button } from "components/Buttons/Buttons";
import Modal from "components/Modal/Modal";
import { useForm } from "antd/lib/form/Form";
import {
  FormHiddenSubmitButton,
  FormInput,
  FormInputArea,
  FormSelect,
} from "components/FormItems/FlatFormItems";
import TabPane from "antd/lib/tabs/TabPane";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewCancellationPolicy,
  updateCancellationPolicy,
} from "services/misc.service";
import { hoursList } from "utils/common";

const { Text, Title } = Typography;

function CancellationPolicyModal({
  visible,
  onCancel,
  formData2,
  setRefreshPolicies,
}) {
  const dispatch = useDispatch();
  const [form] = useForm();
  const submitBtnRef = useRef();
  const [activeTabKey, setActiveTabKey] = useState("2");
  const [operationTypeIds, setOperationTypeIds] = useState([]);
  const { allFacilities } = useSelector((state) => ({
    allFacilities: state.facilities.allFacilities,
  }));

  useEffect(() => {
    if (visible && formData2 && Object.keys(formData2).length > 0) {
      form.setFieldsValue({
        facilityId: formData2?.facilityId,
        feesClinic: formData2?.clinic?.fees.toString(),
        preAppointmentTimeClinic: formData2?.clinic?.preAppointmentTime,
        postAppointmentTimeClinic: formData2?.clinic?.postAppointmentTime,
        feesMobile: formData2?.mobile?.fees.toString(),
        preAppointmentTimeMobile: formData2?.mobile?.preAppointmentTime,
        postAppointmentTimeMobile: formData2?.mobile?.postAppointmentTime,
        feesTelehealth: formData2?.telehealth?.fees.toString(),
        preAppointmentTimeTelehealth: formData2?.telehealth?.preAppointmentTime,
        postAppointmentTimeTelehealth:
          formData2?.telehealth?.postAppointmentTime,
      });
      getOperationTypeIds(formData2.facilityId);
    }
  }, [formData2, visible]);

  const getOperationTypeIds = (facilityId) => {
    const selectedFac = allFacilities.find(
      (res) => res.facilityId == facilityId
    );
    if (selectedFac.operationTypeIds.length > 0) {
      setActiveTabKey(selectedFac.operationTypeIds[0].toString());
    } else {
      setActiveTabKey("4");
    }
    setOperationTypeIds(selectedFac.operationTypeIds);
  };

  return (
    <Modal
      //   closable={closable}
      destroyOnClose={true}
      //   headerBorder={false}
      width='720px'
      className='cancellationPolicyModal'
      title={`${formData2 ? "Edit" : "Add"} Cancellation Policy`}
      onCancel={() => {
        onCancel();
        form.resetFields();
        setOperationTypeIds([]);
      }}
      visible={visible}
      onOk={() => console.log("ok")}
      okText='Add'
      centered={true}
      footer={
        <div className='d-flex justify-between align-center'>
          <AntdButton
            type='text'
            onClick={() => {
              onCancel();
              form.resetFields();
            }}
          >
            Cancel
          </AntdButton>
          <Button
            style={{ height: 50 }}
            rounded={true}
            //   disabled={!Boolean(setupIntent)}
            onClick={() => submitBtnRef?.current?.click()}
          >
            Save
          </Button>
        </div>
      }
      //   cancelText="No"
    >
      <Form
        form={form}
        initialValues={{}}
        layout='vertical'
        requiredMark={false}
        onFinish={(values) => {
          if (formData2) {
            // update cancellation policy
            const updateObj = {
              cancellationPolicies: [
                {
                  operationTypeId: 2,
                  fees: values?.feesClinic ? values.feesClinic : 0,
                  preAppointmentTime: values?.preAppointmentTimeClinic
                    ? values.preAppointmentTimeClinic
                    : 0,
                  postAppointmentTime: values?.postAppointmentTimeClinic
                    ? values.postAppointmentTimeClinic
                    : 0,
                },
                {
                  operationTypeId: 1,
                  fees: values?.feesMobile ? values.feesMobile : 0,
                  preAppointmentTime: values?.preAppointmentTimeMobile
                    ? values.preAppointmentTimeMobile
                    : 0,
                  postAppointmentTime: values?.postAppointmentTimeMobile
                    ? values.postAppointmentTimeMobile
                    : 0,
                },
                {
                  operationTypeId: 3,
                  fees: values?.feesTelehealth ? values.feesTelehealth : 0,
                  preAppointmentTime: values?.preAppointmentTimeTelehealth
                    ? values.preAppointmentTimeTelehealth
                    : 0,
                  postAppointmentTime: values?.postAppointmentTimeTelehealth
                    ? values.postAppointmentTimeTelehealth
                    : 0,
                },
              ],
            };

            dispatch(
              updateCancellationPolicy(values.facilityId, {
                ...updateObj,
              })
            ).then((result) => {
              if (result?.status === "success") {
                form.resetFields();
                setRefreshPolicies(true);
                onCancel();
              }
            });
          } else {
            // add cancellation policy

            const addObj = {
              facilityId: values.facilityId,
              cancellationPolicies: [
                {
                  operationTypeId: 2,
                  fees: values?.feesClinic ? values.feesClinic : 0,
                  preAppointmentTime: values?.preAppointmentTimeClinic
                    ? values.preAppointmentTimeClinic
                    : 0,
                  postAppointmentTime: values?.postAppointmentTimeClinic
                    ? values.postAppointmentTimeClinic
                    : 0,
                },
                {
                  operationTypeId: 1,
                  fees: values?.feesMobile ? values.feesMobile : 0,
                  preAppointmentTime: values?.preAppointmentTimeMobile
                    ? values.preAppointmentTimeMobile
                    : 0,
                  postAppointmentTime: values?.postAppointmentTimeMobile
                    ? values.postAppointmentTimeMobile
                    : 0,
                },
                {
                  operationTypeId: 3,
                  fees: values?.feesTelehealth ? values.feesTelehealth : 0,
                  preAppointmentTime: values?.preAppointmentTimeTelehealth
                    ? values.preAppointmentTimeTelehealth
                    : 0,
                  postAppointmentTime: values?.postAppointmentTimeTelehealth
                    ? values.postAppointmentTimeTelehealth
                    : 0,
                },
              ],
            };

            dispatch(
              addNewCancellationPolicy({
                ...addObj,
              })
            ).then((result) => {
              if (result?.status === "success") {
                form.resetFields();
                setRefreshPolicies(true);
                onCancel();
              }
            });
          }
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={12}>
            <FormSelect
              disabled={formData2?.facilityId ? true : false}
              required
              variant='underlined'
              name={"facilityId"}
              formItemStyles={{
                marginBottom: 0,
              }}
              // options={facilities ?? []}
              options={allFacilities}
              renderValue='facilityId'
              placeholder={"Select Facilities"}
              renderLabel='facilityName'
              emptyMessage='No Faciliteis'
              onChange={(value) => {
                getOperationTypeIds(value);
                // dispatch(setTimer(moment().add(15, "minutes")));
              }}
              rules={[
                {
                  required: true,
                  message: false,
                },
              ]}
            />
          </Col>

          <Col xs={24}>
            <Tabs
              className='cancellationTab'
              onChange={(activeKey) => {
                setActiveTabKey(activeKey);
              }}
              // defaultActiveKey={activeTabKey}
              defaultActiveKey={activeTabKey}
              activeKey={activeTabKey}
            >
              <TabPane
                tab='Clinic'
                key={2}
                disabled={!operationTypeIds.includes(2)}
                forceRender={operationTypeIds.includes(2)}
              >
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={12}>
                    <FormInput
                      prefix='$'
                      variant='underlined'
                      name='feesClinic'
                      label='Cancellation Fee'
                      placeholder='Cancellation Fee'
                      required={activeTabKey == 2}
                      formItemStyles={{
                        margin: 0,
                      }}
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <FormSelect
                      required={activeTabKey == 2}
                      variant='underlined'
                      name={"preAppointmentTimeClinic"}
                      placeholder={"Select Pre Cancellation time"}
                      renderLabel='label'
                      renderValue={"value"}
                      formItemStyles={{
                        marginBottom: 0,
                      }}
                      // options={facilities ?? []}
                      options={hoursList(49, "Hours")}
                      label={"Cancellation Period Before Appointment"}
                      //   placeholder={"Select Cancellation Period"}
                      emptyMessage='No Program Type Available'
                      onChange={(value) => {
                        // dispatch(setTimer(moment().add(15, "minutes")));
                      }}
                      rules={[
                        {
                          required: activeTabKey == 2,
                          message: false,
                        },
                      ]}
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <FormSelect
                      required={activeTabKey == 2}
                      variant='underlined'
                      placeholder={"Select Post Cancellation time"}
                      name={"postAppointmentTimeClinic"}
                      renderLabel='label'
                      renderValue={"value"}
                      formItemStyles={{
                        marginBottom: 0,
                      }}
                      // options={facilities ?? []}
                      options={hoursList(49, "Hours")}
                      label={"Cancellation Period After Appointment"}
                      //   placeholder={"Select Cancellation Period"}
                      emptyMessage='No Program Type Available'
                      onChange={(value) => {
                        // dispatch(setTimer(moment().add(15, "minutes")));
                      }}
                      rules={[
                        {
                          required: activeTabKey == 2,
                          message: false,
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane
                tab='Mobile'
                key={1}
                disabled={!operationTypeIds.includes(1)}
                forceRender={operationTypeIds.includes(1)}
              >
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={12}>
                    <FormInput
                      prefix='$'
                      variant='underlined'
                      name='feesMobile'
                      label='Cancellation Fee'
                      placeholder='Cancellation Fee'
                      required={activeTabKey == 1}
                      formItemStyles={{
                        margin: 0,
                      }}
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <FormSelect
                      required={activeTabKey == 1}
                      variant='underlined'
                      name={"preAppointmentTimeMobile"}
                      placeholder={"Select Pre Cancellation time"}
                      renderLabel='label'
                      renderValue={"value"}
                      formItemStyles={{
                        marginBottom: 0,
                      }}
                      // options={facilities ?? []}

                      options={hoursList(49, "Hours")}
                      label={"Cancellation Period Before Appointment"}
                      //   placeholder={"Select Cancellation Period"}
                      emptyMessage='No Program Type Available'
                      onChange={(value) => {
                        // dispatch(setTimer(moment().add(15, "minutes")));
                      }}
                      rules={[
                        {
                          required: activeTabKey == 1,
                          message: false,
                        },
                      ]}
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <FormSelect
                      required={activeTabKey == 1}
                      variant='underlined'
                      placeholder={"Select Post Cancellation time"}
                      name={"postAppointmentTimeMobile"}
                      renderLabel='label'
                      renderValue={"value"}
                      formItemStyles={{
                        marginBottom: 0,
                      }}
                      // options={facilities ?? []}

                      options={hoursList(49, "Hours")}
                      label={"Cancellation Period After Appointment"}
                      //   placeholder={"Select Cancellation Period"}
                      emptyMessage='No Program Type Available'
                      onChange={(value) => {
                        // dispatch(setTimer(moment().add(15, "minutes")));
                      }}
                      rules={[
                        {
                          required: activeTabKey == 1,
                          message: false,
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane
                tab='Telehealth'
                key={3}
                disabled={!operationTypeIds.includes(3)}
                forceRender={operationTypeIds.includes(3)}
              >
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={12}>
                    <FormInput
                      prefix='$'
                      variant='underlined'
                      name='feesTelehealth'
                      label='Cancellation Fee'
                      placeholder='Cancellation Fee'
                      required={activeTabKey == 3}
                      formItemStyles={{
                        margin: 0,
                      }}
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <FormSelect
                      required={activeTabKey == 3}
                      variant='underlined'
                      name={"preAppointmentTimeTelehealth"}
                      placeholder={"Select Pre Cancellation time"}
                      renderLabel='label'
                      renderValue={"value"}
                      formItemStyles={{
                        marginBottom: 0,
                      }}
                      // options={facilities ?? []}

                      options={hoursList(49, "Hours")}
                      label={"Cancellation Period Before Appointment"}
                      //   placeholder={"Select Cancellation Period"}
                      emptyMessage='No Program Type Available'
                      onChange={(value) => {
                        // dispatch(setTimer(moment().add(15, "minutes")));
                      }}
                      rules={[
                        {
                          required: activeTabKey == 3,
                          message: false,
                        },
                      ]}
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <FormSelect
                      required={activeTabKey == 3}
                      variant='underlined'
                      placeholder={"Select Post Cancellation time"}
                      name={"postAppointmentTimeTelehealth"}
                      renderLabel='label'
                      renderValue={"value"}
                      formItemStyles={{
                        marginBottom: 0,
                      }}
                      // options={facilities ?? []}

                      options={hoursList(49, "Hours")}
                      label={"Cancellation Period After Appointment"}
                      //   placeholder={"Select Cancellation Period"}
                      emptyMessage='No Program Type Available'
                      onChange={(value) => {
                        // dispatch(setTimer(moment().add(15, "minutes")));
                      }}
                      rules={[
                        {
                          required: activeTabKey == 3,
                          message: false,
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <FormHiddenSubmitButton submitRef={submitBtnRef} />
        </Row>
      </Form>
    </Modal>
  );
}

CancellationPolicyModal.propTypes = {};

export default CancellationPolicyModal;
