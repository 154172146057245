import React, { useState } from "react";

import "./Quickbooks.style.scss";
import { Dropdown, Menu, Table } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import AccountIntegrationDrawer from "./components/AccountIntegrationDrawer";
import ClassIntegrationDrawer from "./components/ClassIntegrationDrawer";
import SubclassIntegrationDrawer from "./components/SubclassIntegrationDrawer";
import CustomerIntegrationDrawer from "./components/CustomerIntegrationDrawer";
import RunApiIntegrationDrawer from "./components/RunApiIntegrationDrawer";

const { Column } = Table;
export default function Quickbooks() {
  const [showAccountsDrawer, setShowAccountsDrawer] = useState(false);
  const [showClassesDrawer, setShowClassesDrawer] = useState(false);
  const [showSubclassesDrawer, setShowSubclassesDrawer] = useState(false);
  const [showCustomersDrawer, setShowCustomersDrawer] = useState(false);
  const [showRunAPIDrawer, setShowRunAPIDrawer] = useState(false);
  return (
    <div>
      <Table
        tableLayout="auto"
        locale={{ emptyText: "No Settings Available" }}
        pagination={false}
        columns={[
          {
            title: "Domains",
            dataIndex: "domain",
            key: "domain",
          },
          {
            title: "",
            key: "action",
            align: "right",
            width: 80,
            render: (record) => (
              <Dropdown
                overlay={
                  <Menu
                    style={{ borderRadius: 6, border: "2px solid #CDD5D8 " }}
                  >
                    <Menu.Item onClick={record.onViewClick}>View</Menu.Item>
                  </Menu>
                }
                dataSource={[]}
                trigger={["click"]}
                getPopupContainer={(triggerNode) => triggerNode?.parentNode}
              >
                <MoreOutlined style={{ fontSize: 18 }} />
              </Dropdown>
            ),
          },
        ]}
        dataSource={[
          {
            domain: "Account",
            onViewClick: () => {
              setShowAccountsDrawer(true);
            },
          },
          {
            domain: "Class",
            onViewClick: () => {
              setShowClassesDrawer(true);
            },
          },
          {
            domain: "Subclass",
            onViewClick: () => {
              setShowSubclassesDrawer(true);
            },
          },
          {
            domain: "Customer Name",
            onViewClick: () => {
              setShowCustomersDrawer(true);
            },
          },
          {
            domain: "Run API",
            onViewClick: () => {
              setShowRunAPIDrawer(true);
            },
          },
        ]}
        // scroll={{ y: "calc(100vh - 80px - 96px - 60px - 100px)", x: true }}
      />
      <AccountIntegrationDrawer
        visible={showAccountsDrawer}
        onClose={() => setShowAccountsDrawer(false)}
      />
      <ClassIntegrationDrawer
        visible={showClassesDrawer}
        onClose={() => setShowClassesDrawer(false)}
      />
      <SubclassIntegrationDrawer
        visible={showSubclassesDrawer}
        onClose={() => setShowSubclassesDrawer(false)}
      />
      <CustomerIntegrationDrawer
        visible={showCustomersDrawer}
        onClose={() => setShowCustomersDrawer(false)}
      />
      <RunApiIntegrationDrawer
        visible={showRunAPIDrawer}
        onClose={() => setShowRunAPIDrawer(false)}
      />
    </div>
  );
}
