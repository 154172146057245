import React, { Fragment, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import "./ShiftScheduler.scss";
import ShiftSchedulerItem from "./components/ShiftSchedulerItem";
import {
  Button as AntdButton,
  DatePicker,
  Dropdown,
  Modal,
  Popover,
  Select,
  Space,
  Tag,
  Typography,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import DayWeekSelector from "components/Calendar/components/DayWeekSelector";
import MonthSelector from "./components/MonthSelector";
import { Button } from "components/Buttons/Buttons";
import ScheduleShiftModal from "./components/ScheduleShiftModal";
import { useDispatch, useSelector } from "react-redux";
import { getAllLocations } from "services/locationSettings.service";
import { getRosters, rejectStaffRoster } from "services/roster.service";
import { RosterFilterType } from "utils/enums";
import { findWindows } from "windows-iana";
import { ReactComponent as DayViewIcon } from "assets/icons/DayViewIco.svg";
import { ReactComponent as WeekViewIcon } from "assets/icons/WeekViewIco.svg";
import WeekSelector from "./components/WeekSelector";
import AvailabilityModal from "containers/Availability/components/AvailabilityModal";

const { Text } = Typography;

const mLocalizer = momentLocalizer(moment);
const { Option } = Select;
function ViewSelector({
  view,
  onView,
  views = ["month", "agenda"],
  // maxWidth = 230,
}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
        maxWidth: 240,
      }}
    >
      <div
        className="viewSelector"
        // style={{
        //   maxWidth,
        // }}
      >
        {views.includes("month") && (
          <button
            // style={{
            //   display: "flex",
            //   alignItems: "center",
            //   justifyContent: "center",
            //   // flexGrow: 1,
            // }}
            onClick={() => onView("month")}
            className={`views ${view === "month" ? "active" : ""} `}
          >
            <DayViewIcon />
          </button>
        )}
        {views.includes("week") && (
          <button
            // style={{
            //   display: "flex",
            //   alignItems: "center",
            //   justifyContent: "center",
            //   // flexGrow: 1,
            // }}
            onClick={() => onView("week")}
            className={`views ${view === "week" ? "active" : ""} `}
          >
            Week
          </button>
        )}

        {views.includes("agenda") && (
          <button
            // style={{
            //   display: "flex",
            //   alignItems: "center",
            //   justifyContent: "center",
            //   flexGrow: 1,
            // }}
            onClick={() => onView("agenda")}
            className={`views ${view === "agenda" ? "active" : ""} `}
          >
            <WeekViewIcon />
          </button>
        )}
      </div>
    </div>
  );
}

const AgendaTime = (params) => {
  const { label, event } = params;
  const windowsTimezone = findWindows(moment.tz.guess());

  return (
    <div>
      {label} <br />{" "}
      <span className="firstLetterUppercase"> {windowsTimezone}</span>
    </div>
  );
};
export const Agenda = ({ appointmentDetailClick, loadData, ...params }) => {
  const { event } = params;
  const dispatch = useDispatch();
  const currentStaff = useSelector((state) => state.user.currentUser);

  const handleReject = (rosterId) => {
    dispatch(rejectStaffRoster(currentStaff?.staffId, rosterId)).then(() => {
      loadData();
    });
  };
  const rejectConfirmation = (id) => ({
    title: "Delete Shift",
    content: <>Are you sure you want to delete this shift?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      handleReject(id);
      close();
    },
  });
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "5px 5px",
      }}
    >
      <div>
        <Text
          style={{
            paddingRight: 8,
          }}
        >
          {event?.staffName}
        </Text>
        {!!event?.isRejected && <Tag color="red">Rejected</Tag>}
        {!!event?.isAbsent && <Tag color="yellow">Absent</Tag>}
      </div>

      <AntdButton
        type="text"
        onClick={async () => {
          Modal.warning(rejectConfirmation(event.staffRosterId));
        }}
        style={{
          color: "red",
        }}
      >
        Reject
      </AntdButton>
    </div>
  );
};
function ShiftScheduler({ ...props }) {
  const [viewMoreEvents, setViewMoreEvents] = useState([]);
  const [view, setView] = useState("month");
  const [showSchedulerModal, setShowSchedulerModal] = useState(false);

  const dispatch = useDispatch();
  const [date, setDate] = useState(moment());
  const [facilityId, setFacilityId] = useState();
  const [facilities, setFacilities] = useState({
    data: [],
    isLoading: true,
  });
  const [shifts, setShifts] = useState({
    data: [],
    isLoading: true,
  });
  const [events, setEvents] = useState([]);

  const [showAddAvailabilityModal, setshowAddAvailabilityModal] =
    useState(false);

  useEffect(() => {
    dispatch(getAllLocations(), true, true).then((res) => {
      if (res?.status === "success") {
        const facilities = res?.data?.filter((facility) => facility?.isActive);
        setFacilities({
          data: facilities,
          isLoading: false,
        });
        const shifts = [];
        for (let facility of facilities) {
          for (let shift of facility?.shifts ?? []) {
            if (shift?.isActive)
              shifts.push({
                shiftId: shift?.shiftId,
                shiftName: shift?.shiftName,
                facilityName: facility?.facilityName,
                facilityId: facility?.facilityId,
              });
          }
        }
        setShifts({
          data: shifts,
          isLoading: false,
        });
      } else {
        setFacilities({
          data: [],
          isLoading: false,
        });
        setShifts({
          data: [],
          isLoading: false,
        });
      }
    });
  }, []);

  const loadData = () => {
    if (!facilityId) return;

    dispatch(
      getRosters({
        facilityId: facilityId,
        shiftDate: `${date?.format("YYYY-MM")}-01`,
        filterType:
          view === "week" ? RosterFilterType?.Weekly : RosterFilterType.Monthly,
      })
    ).then((res) => {
      if (res?.status === "success") {
        const events = [];
        for (let roster of res?.data?.filter((it) => !it?.isRejected)) {
          const {
            startTime,
            endTime,
            staffName,
            shiftName,
            rosterDate,
            isAbsent,
            isRejected,
            staffSequence,
            staffRosterId,
          } = roster ?? {};
          const startMoment = moment(
            `${rosterDate.split("T")[0]} ${startTime}`,
            "YYYY-MM-DD HH:mm"
          );
          const endMoment = moment(
            `${rosterDate.split("T")[0]} ${endTime}`,
            "YYYY-MM-DD HH:mm"
          );
          events.push({
            start: startMoment,
            end: endMoment,
            staffName,
            shiftName,
            isAbsent,
            isRejected,
            staffSequence,
            staffRosterId,
          });
        }
        setEvents(events);
      }
    });
  };

  useEffect(() => {
    loadData();
  }, [facilityId, view, date]);

  const components = useMemo(() => {
    return {
      localizer: momentLocalizer(moment),
      toolbar: (params) => {
        return (
          <div className="shiftSchedulerToolbar">
            <Space className="locationSelectorContainer">
              <span className="label">Location: </span>
              <Select
                // allowClear={
                //   currentOperationType === CalenderOperationType.Telehealth
                // }
                loading={facilities?.isLoading}
                value={facilityId}
                onChange={(id) => {
                  setFacilityId(id);
                }}
                className="locationSelector"
                dropdownClassName="locationSelectorDropdown"
                showSearch
                placeholder="Select location center"
                optionFilterProp="children"
                // value={facilityId}
                // onChange={setFacility}
                onSearch={() => {}}
                // loading={isFetchingFacilities}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {facilities?.data?.map((facility) => (
                  <Option
                    key={facility?.["facilityId"]}
                    value={facility?.["facilityId"]}
                    label={facility?.["facilityName"]}
                  >
                    {facility?.["facilityName"]}
                  </Option>
                ))}
              </Select>
            </Space>
            {view === "week" ? (
              <WeekSelector {...params} />
            ) : (
              <MonthSelector {...params} />
            )}

            {/* <div style={{ width: 230 }}>&nbsp;</div> */}
            <div style={{ display: "flex", gap: 16 }}>
              <ViewSelector
                // maxWidth={120}
                view={view}
                onView={(view) => setView(view)}
                views={["month", "agenda"]}
              />

              <Button rounded onClick={() => setShowSchedulerModal(true)}>
                Scheduling Sequence
              </Button>
            </div>
          </div>
        );
      },
      dateCellWrapper: (params) => {
        const { children, range, value } = params;

        return children;
      },
      eventWrapper: (param) => {
        const { event } = param;
        return (
          <ShiftSchedulerItem
            param={param}
            event={event}
            containerClass="paddedContainer"
            loadData={loadData}
          />
        );
      },
      month: {
        header: (param) => {
          return (
            <div className="shiftHeaderCell">
              {moment(param.date).format("dddd")}
            </div>
          );
        },
        dateHeader: (param) => {
          let isDayPassed = moment(param?.date).isBefore(moment(), "day");
          let dateMoment = moment(param?.date);
          return (
            <div
              className="shiftCellDay"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>{dateMoment.format("D")}</div>
              {!isDayPassed && (
                <div
                  style={{
                    display: "flex",
                    background: "#dae1ef",
                    width: 20,
                    height: 20,
                    borderRadius: 10,
                    cursor: "pointer",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setshowAddAvailabilityModal(dateMoment);
                  }}
                >
                  +
                </div>
              )}
            </div>
          );
        },
        event: (param) => {
          return <div>AAAA</div>;
        },
      },
      agenda: {
        time: (params) => <AgendaTime {...params} />,
        event: (params) => <Agenda {...params} loadData={loadData} />, // with the agenda view use a different component to render events
      },
    };
  }, [facilityId, facilities, date, loadData, view]);

  const renderViewMorePopover = () => {
    return viewMoreEvents?.map((it) => {
      return <ShiftSchedulerItem event={it} loadData={loadData} />;
    });
  };

  return (
    <div className="shiftScheduler">
      <div className="height600" {...props}>
        <Calendar
          length={30}
          view={view}
          onNavigate={(date) => {
            console.log("changed", date);

            setDate(moment(`${date.format("YYYY-MM")}-01`, "YYYY-MM-DD"));
          }}
          date={moment(`${date.format("YYYY-MM")}-01`, "YYYY-MM-DD")}
          popup={false}
          doShowMoreDrillDown={false}
          components={components}
          // formats={
          //   {
          //     monthHeaderFormat: (day)=>{
          //       return moment(day).format("dddd")
          //     }
          //   }
          // }
          onShowMore={(events) => {
            setViewMoreEvents(events?.filter((_, index) => index > 1));
          }}
          messages={{
            showMore: (total) => (
              <div style={{ position: "relative" }}>
                <Popover
                  overlayClassName="shiftScheduleShowMorePopover"
                  onOpenChange={(open) => {
                    if (open === false) setViewMoreEvents([]);
                  }}
                  content={renderViewMorePopover}
                  placement="bottom"
                  title=""
                  trigger="click"
                >
                  <span className="shiftScheduleShowMore">+{total} more </span>
                </Popover>
              </div>
            ),
          }}
          events={events}
          views={{
            // week: true,
            month: true,
            // day: true,
            agenda: true,
          }}
          onView={(view) => {
            setView(view);
          }}
          startAccessor={(event) => {
            return moment.utc(event.start).local().toDate();
          }}
          endAccessor={(event) => {
            let date = moment.utc(event.end).local();
            return date.toDate();
          }}
          localizer={mLocalizer}
        />
      </div>
      <ScheduleShiftModal
        schdulerFacilityId={facilityId}
        loadData={loadData}
        facilities={facilities?.data}
        isFacilitiesLoadings={facilities?.isLoading}
        shifts={shifts?.data}
        isShiftsLoading={shifts?.isLoading}
        visible={showSchedulerModal}
        onCancel={() => setShowSchedulerModal(false)}
      />
      <AvailabilityModal
        date={showAddAvailabilityModal}
        shifts={shifts?.data}
        facilityId={facilityId}
        visible={showAddAvailabilityModal}
        onCancel={() => {
          setshowAddAvailabilityModal();
        }}
        facilities={facilities?.data ?? []}
        onSuccess={() => {
          setshowAddAvailabilityModal();
          loadData();
        }}
      />
    </div>
  );
}

ShiftScheduler.propTypes = {};

export default ShiftScheduler;
