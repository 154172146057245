import { Form, Modal } from "antd";
import React from "react";
import PinModal from "./components/PinModel";
import Pin from "./components/Pin";

import { useState } from "react";

export default function ImageAnnotator({ image, form, name, disabled }) {
  const imageRef = React.useRef(null);
  const [initialRender, setInitialRender] = useState(false);

  const data = form?.getFieldsValue?.(true)?.[name];
  const [showModal, setShowModal] = React.useState(false);
  const [showEditModal, setShowEditModal] = React.useState(null);
  const [content, setContent] = React.useState([
    // {
    //   text: "Test",
    //   location: {
    //     x: 0,
    //     y: 0,
    //   },
    // },
  ]);

  React.useEffect(() => {
    async function setContentFromForm() {
      if (data) {
        setContent(await JSON.parse(data));
        setInitialRender(true);
      }
    }
    if (!initialRender) setContentFromForm();
  }, [data, initialRender]);

  function addPin(text, x, y) {
    const newContent = [
      ...content,
      {
        text,
        location: {
          x,
          y,
        },
      },
    ];
    setContent(newContent);
    if (form && name)
      form.setFieldsValue({
        [name]: JSON.stringify(newContent),
      });
  }

  function updatePin(index, text) {
    const newContent = [...content];
    newContent[index].text = text;
    setContent(newContent);
    if (form && name)
      form.setFieldsValue({
        [name]: JSON.stringify(newContent),
      });
  }

  function deletePin(index) {
    const newContent = [...content];
    newContent.splice(index, 1);
    setContent(newContent);
    if (form && name)
      form.setFieldsValue({
        [name]: JSON.stringify(newContent),
      });
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        className="imageContainer"
        style={{
          position: "relative",
        }}
      >
        {content.map((pin, index) => {
          return (
            <Pin
              key={index}
              pin={pin}
              onClick={() => {
                if (disabled) return;
                setShowEditModal({
                  index,
                  text: pin.text,
                });
              }}
            />
          );
        })}
        <img
          src={image}
          alt="face"
          style={{
            height: 500,
          }}
          ref={imageRef}
          onClick={(e) => {
            //   setShowModal(true);
            if (disabled) return;

            const rect = e.target.getBoundingClientRect();

            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;
            console.log("image frame ref x and y ", x, y);
            setShowModal({
              x,
              y,
            });
          }}
        />
      </div>
      <PinModal
        open={showModal}
        onCancel={() => setShowModal(false)}
        onOk={(text) => {
          addPin(text, showModal.x, showModal.y);
          setShowModal(false);
        }}
      />
      <PinModal
        open={showEditModal}
        onCancel={() => setShowEditModal(null)}
        onDelete={() => {
          deletePin(showEditModal.index);
          setShowEditModal(null);
        }}
        onOk={(text) => {
          updatePin(showEditModal.index, text);
          setShowEditModal(null);
        }}
        input={showEditModal?.text}
      />
    </div>
  );
}
