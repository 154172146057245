import React from "react";
import PropTypes from "prop-types";

import { Tag as AntdTag } from "antd";
import "./Tag.scss"

function Tag(props) {
  const { children, className } = props;
  return <AntdTag className={`tag ${className}`}>{children}</AntdTag>;
}

Tag.propTypes = {};

export default Tag;
