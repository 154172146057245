import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "components/Modal/Modal";
import { Button } from "components/Buttons/Buttons";

import { postForm, getForm } from "services/intakeForm.service";
import Signature, { useSignature } from "components/Signature/Signature";

import { openNotificationWithIcon } from "utils/Notification";

import { Col, Form, Row } from "antd";
import {
  FormInputArea,
  FormCheckboxGroup,
  FormSlider,
  FormRadioGroup,
  FormInput,
  FormDatePicker,
  FormInputNumber,
  FormSelect,
  FormCheckbox,
} from "components/FormItems/FlatFormItems";

import moment from "moment";
import {
  submitChart,
  updateChart,
  getLegacyChart,
  getChartNotes,
  addChartNotes,
} from "services/charts.services";
import { setLoading } from "redux/actions/app.actions";
import { isUserNurse } from "utils/common";

function HistoryIntakeForm({ visible, onSubmit, onClose, user }) {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const currentStaff = useSelector((state) => state.user.currentUser);
  const [chart, setChart] = useState([]);
  const [chartNotes, setChartNotes] = useState([]);
  useEffect(async () => {
    const orderId = window.location.pathname.split("/")[3];
    const lineItemId = window.location.pathname.split("/")[5];
    dispatch(setLoading(true));
    const chart = await dispatch(getLegacyChart(orderId, lineItemId));
    if (chart.length) {
      if (chart[0].chart) {
        const data = JSON.parse(chart[0].chart);
        setFormChecked(data);
        chart[0].chart = data;
        setChart(chart);
        form.setFieldsValue({
          ...data,
          expirationDate1: moment(data.expirationDate1),
          expirationDate2: moment(data.expirationDate2),
          expirationDate3: moment(data.expirationDate3),
        });
        setSignatureValue(data.signature);
        setSignatureValue1(chart[0].physicianSignature);
      } else {
        setChart(chart);
      }
    }
    const notes = await getChartNotes(chart[0].chartId);
    setChartNotes(notes);
    dispatch(setLoading(false));
  }, []);

  const [signature, getSignatureValue, clearSignatureValue, setSignatureValue] =
    useSignature("intakeForm", form);
  const [
    signature1,
    getSignatureValue1,
    clearSignatureValue1,
    setSignatureValue1,
  ] = useSignature("intakeForm1", form, "signature1");
  const [formChecked, setFormChecked] = useState({
    nameChecked: false,
    dobChecked: false,
    infusionChecked: false,
    historyReviewedChecked: false,
    consentsSignedChecked: false,
    meetSanitationPolicyChecked: false,
    covidSwabChecked: false,
    familyMember1Checked: false,
    familyMember2Checked: false,
    familyMember3Checked: false,
    familyMember4Checked: false,
    familyMember5Checked: false,
  });

  return (
    <Form
      initialValues={{}}
      // requiredMark={false}
      form={form}
      layout="vertical"
      className=""
      onFinishFailed={({ values, errorFields, outOfDate }) => {
        console.log(form.getFieldValue("infusionChecked"));
        form.scrollToField(errorFields[0].name, {
          scrollMode: "if-needed",
          block: "center",
          behavior: "smooth",
        });
      }}
      onFinish={async (values) => {
        values.signature = getSignatureValue();
        const data = {
          orderId: window.location.pathname.split("/")[3],
          orderLineItemId: window.location.pathname.split("/")[5],
          chart: JSON.stringify(values),
        };
        if (
          currentStaff?.staffId === chart[0]?.staffId &&
          !chart[0].staffSignature
        ) {
          if (getSignatureValue()) {
            data.staffSignature = getSignatureValue();
          } else {
            return openNotificationWithIcon(
              "warning",
              "Signature Required",
              "Please sign the chart before submitting"
            );
          }
        } else if (currentStaff?.staffId === chart[0]?.staffPhysicianId) {
          if (getSignatureValue1()) {
            data.physicianSignature = getSignatureValue1();
          } else {
            return openNotificationWithIcon(
              "warning",
              "Signature Required",
              "Please sign the chart before submitting"
            );
          }
        }
        dispatch(setLoading(true));
        if (chart.length) {
          await dispatch(updateChart(data, chart[0].chartId));
        } else {
          await dispatch(submitChart(data));
        }
        dispatch(setLoading(false));
        setTimeout(() => {
          window.close();
        }, 3000);
      }}
    >
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Guest Information:
      </p>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"nameChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({ ...formChecked, nameChecked: e.target.checked })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.nameChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="name"
            label="Confirmed Name?"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"dobChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({ ...formChecked, dobChecked: e.target.checked })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.dobChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="dob"
            label="Confirmed DOB?"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"infusionChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                infusionChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.infusionChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="dateOfTest"
            label="Date of test?"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"historyReviewedChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                historyReviewedChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.historyReviewedChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="historyReviewed"
            label="History and Physical Reviewed? Changes (document in box if yes)?"
            placeholder="Enter here..."
          />
        </Col>
      </Row>

      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"consentsSignedChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                consentsSignedChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.consentsSignedChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="consentsSigned"
            label="Consents and Waivers Signed?"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"meetSanitationPolicyChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                meetSanitationPolicyChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.meetSanitationPolicyChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="meetSanitationPolicy"
            label="Environment meets sanitation policy?"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Lab (Place brand of test, lot number and test results in note section)*
      </p>

      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"covidSwabChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                covidSwabChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.covidSwabChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="covidSwab"
            label="Covid Swab Nasopharygneal 1 swab Insert swab into both nasal cavities and swab for 20 seconds"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Additional Family Members Tested (Include in notes name and test result)
      </p>

      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"familyMember1Checked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                familyMember1Checked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={false}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="familyMember1"
            label="Family Member #1"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"familyMember2Checked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                familyMember2Checked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={false}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="familyMember2"
            label="Family Member #2"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"familyMember3Checked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                familyMember3Checked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={false}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="familyMember3"
            label="Family Member #3"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"familyMember4Checked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                familyMember4Checked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={false}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="familyMember4"
            label="Family Member #4"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"familyMember5Checked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                familyMember5Checked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={false}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="familyMember5"
            label="Family Member #5"
            placeholder="Enter here..."
          />
        </Col>
      </Row>

      <div
        style={{
          borderBottom: "2px solid #EFF1F4",
          padding: "20px 0px",
          marginBottom: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "2px solid #EFF1F4",
            padding: "20px 0px",
            marginBottom: 20,
          }}
        >
          <h1
            style={{
              fontFamily: "tradeGothic",
              fontWeight: "bold",
              fontSize: 20,
              color: "#000",
            }}
          >
            Notes
          </h1>
        </div>
        <div>
          <div>
            {chartNotes.map((note) => (
              <div style={{ marginBottom: 16 }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p
                    style={{
                      fontFamily: "tradeGothic",
                      fontSize: 18,
                      marginBottom: 0,
                    }}
                  >
                    {note.noteDescription}
                  </p>
                  <span>
                    Created on{" "}
                    {moment
                      .utc(note.createdOn)
                      .local()
                      .format("D MMM YYYY h:mm A")}
                  </span>
                </div>
                (Created By: {note.userName})
              </div>
            ))}
          </div>
          <FormInputArea
            name={"noteDescription"}
            label={"Note"}
            disabled={true}
          />
          <Button
            rounded={true}
            disabled={true}
            onClick={async () => {
              const noteDescription = form.getFieldValue("noteDescription");
              if (
                noteDescription === undefined ||
                noteDescription?.trim() === ""
              ) {
                openNotificationWithIcon(
                  "error",
                  "Error",
                  "Please enter a note description"
                );
                return;
              }
              dispatch(setLoading(true));
              await addChartNotes(chart[0].chartId, { noteDescription });
              const notes = await getChartNotes(chart[0].chartId);
              setChartNotes(notes);
              form.setFieldsValue({ noteDescription: "" });
              dispatch(setLoading(false));
            }}
          >
            Add Note
          </Button>
        </div>
      </div>

      <h1
        style={{
          fontFamily: "tradeGothic",
          fontWeight: "bold",
          fontSize: 20,
          color: "#000",
          marginTop: 28,
        }}
      >
        Clinician Signature
      </h1>
      <Signature name="sigature" signature={signature} disabled={true} />

      <h1
        style={{
          fontFamily: "tradeGothic",
          fontWeight: "bold",
          fontSize: 20,
          color: "#000",
          marginTop: 28,
        }}
      >
        Nurse Practitioner/Physician Signature
      </h1>
      <Signature name="signature1" signature={signature1} disabled={true} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          maxWidth: 367,
          paddingBottom: 20,
        }}
      >
        <Button
          rounded={true}
          style={{ marginRight: 20 }}
          disabled={true}
          onClick={async () => {
            const values = form.getFieldsValue(true);
            // form.getFieldsValue(async (values) => {
            console.log(values);
            dispatch(setLoading(true));
            values.signature = getSignatureValue();
            const data = {
              orderId: window.location.pathname.split("/")[3],
              orderLineItemId: window.location.pathname.split("/")[5],
              chart: JSON.stringify(values),
            };
            if (chart.length) {
              await dispatch(updateChart(data, chart[0].chartId));
            } else {
              await dispatch(submitChart(data));
            }
            dispatch(setLoading(false));
          }}
        >
          Save
        </Button>
        <Button rounded={true} htmlType="submit" style={{}} disabled={true}>
          Save & Submit
        </Button>
      </div>
    </Form>
  );
}

export default function CovidTesting() {
  return (
    <div style={{ overflow: "auto", height: "90vh", padding: 24 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "2px solid #EFF1F4",
          padding: "20px 0px",
          marginBottom: 20,
        }}
      >
        <h1
          style={{
            fontFamily: "tradeGothic",
            fontWeight: "bold",
            fontSize: 24,
            color: "#000",
          }}
        >
          Covid Testing
        </h1>
      </div>
      <HistoryIntakeForm />
    </div>
  );
}
