import React, { useEffect, useMemo, useRef, useState } from "react";
import { Col, Form, Typography } from "antd";
import Drawer from "components/Drawer/Drawer";
import ShiftAvailabiltyWidget from "./ShiftAvailabiltyWidget";
import {
  FormHiddenSubmitButton,
  FormSelect,
} from "components/FormItems/FlatFormItems";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "components/Buttons/Buttons";
import { Button as AntdButton } from "antd";
import moment from "moment";
import { getDefaultDaySlots } from "utils/common";
import {
  createRoster,
  getCalendarRosters,
  getRosters,
} from "services/roster.service";
import { RosterFilterType } from "utils/enums";
import { Tabs } from "antd";
import AvailabilityReview from "./AvailabilityReview";
import { uniqBy } from "lodash";
import { SyncOutlined } from "@ant-design/icons";
import { setLoading } from "redux/actions/app.actions";

const Colors = [
  { color: "#ffccc7", describtion: "Unavailable" },
  // { color: "#ffec3d", describtion: "Absent" },
  { color: "#e6f4ff", describtion: "Availability Scheduled" },
  { color: "#d9f7be", describtion: "Slot Available" },
  { color: "#f8f8f8", describtion: "No work day" },
];

export default function AvailabilityDrawer({
  facilityShifts,
  isLoadingFacilityShifts,
  visible,
  onClose,
  monthOptions,
  data,
}) {
  const [form] = Form.useForm();
  const { currentUser } = useSelector((state) => state.user);

  const [reviewMode, setReviewMode] = useState(false);
  const dispatch = useDispatch();

  const [rosters, setRosters] = useState({
    data: [],
    isLoading: false,
  });
  const [calendarRosters, setCalendarRosters] = useState({
    data: [],
    isLoading: false,
  });
  const [daySlots, setDaySlots] = useState([]);

  const submitBtnRef = useRef();

  const generateDaySlots = (month, disabled = false) => {
    const getDaysByMonth = (month) => {
      const daysInMonth = moment(month, "MM").daysInMonth();

      const year =
        month < moment().month() + 1 ? moment().year() + 1 : moment().year();

      const slots = [];

      for (let i = 1; i <= daysInMonth; i++) {
        const day = moment(`${year}-${month}-${i}`, "yyyy-MM-DD");
        if (i === 1) {
          const dayOfWeek = day?.day();
          const prevMonth = month - 1 <= 0 ? 12 : month - 1;
          const prevYear = month - 1 <= 0 ? year - 1 : year;
          const daysInPrevMonth = moment(
            `${prevYear}-${prevMonth}`,
            "yyyy-MM"
          ).daysInMonth();
          for (let j = dayOfWeek; j > 0; j--) {
            slots.push({
              index: slots.length,
              value: daysInPrevMonth - (j - 1),
              disabled: true,
              date: moment(`${prevYear}-${prevMonth}-${j - 1}`, "yyyy-MM-DD"),
            });
          }
        }
        const date = moment(`${year}-${month}-${i}`, "yyyy-MM-DD");
        slots.push({
          index: slots.length,
          value: i,
          disabled:
            date < moment(moment().format("yyyy-MM-DD"), "yyyy-MM-DD") ||
            disabled,
          date: date,
        });
      }
      const remainSlotsCount = 7 - (slots?.length % 7);
      for (let i = 0; i < remainSlotsCount; i++) {
        slots.push({
          index: slots.length,
          value: "",
          disabled: disabled ?? true,
          date: "",
        });
      }

      return slots;
    };
    if (month) return getDaysByMonth(month);
    return getDefaultDaySlots();
  };

  const shiftsList = useMemo(() => {
    return uniqBy(facilityShifts, "shiftId");
  }, [facilityShifts]);

  const month = Form.useWatch("month", form);
  const shift = Form.useWatch("shift", form);

  const selectedFacilityId = facilityShifts?.find?.(
    (facilityShift) => facilityShift.shiftId === shift
  )?.facilityId;

  useEffect(() => {
    if (visible) {
      const slots = generateDaySlots(data.month, true);
      setDaySlots(slots);
      if (data?.month) {
        form.setFieldsValue({
          month: data.month,
        });
      }
    } else {
      setReviewMode(false);
      setDaySlots([]);
      setRosters({
        data: [],
        isLoading: true,
      });
    }
  }, [visible]);

  const loadData = () => {
    if (!visible || !shift) return;
    const daySlots = generateDaySlots(month);
    setDaySlots(daySlots);
    const year =
      month < moment().month() + 1 ? moment().year() + 1 : moment().year();
    const monthDate = moment(`${year}-01-${month}`, "YYYY-DD-MM");
    setRosters((data) => ({
      ...data,
      isLoading: true,
    }));
    setCalendarRosters((data) => ({
      ...data,
      isLoading: true,
    }));

    const startDate = moment(`${year}-${month}-01`, "YYYY-MM-DD").startOf(
      "month"
    );
    const endDate = moment(`${year}-${month}-01`, "YYYY-MM-DD").endOf("month");
    dispatch(setLoading(true));
    Promise.all([
      dispatch(
        getRosters(
          {
            staffId: currentUser?.staffId,
            shiftDate: monthDate?.format("YYYY-MM-DD"),
            filterType: RosterFilterType.Monthly,
            shiftId: shift,
          },
          false
        )
      ),
      dispatch(
        getCalendarRosters(
          selectedFacilityId,
          shift,
          startDate?.format("YYYY-MM-DD"),
          endDate?.format("YYYY-MM-DD"),
          false
        )
      ),
    ])
      .then((results) => {
        const res = results[0];
        const calendarRes = results[1];

        if (res?.status === "success" && calendarRes?.status === "success") {
          const data = res?.data;
          const newShifts = [];

          for (let i = 0; i < daySlots?.length; i++) {
            const daySlot = daySlots[i];
            const day = daySlots[i]?.date?.format?.("dddd");

            let shiftFound = data?.find(
              (shift) =>
                moment(shift?.rosterDate).format("YYYY-MM-DD") ===
                daySlot?.date?.format?.("YYYY-MM-DD")
            );
            if (shiftFound) {
              newShifts.push({
                ...shiftFound,
                isBackendFetched: true,
              });
            } else if (shiftFound?.selectedFromField) {
              shiftFound = {
                ...shiftFound,
                shiftId: shift,
                selectedFromField: true,
              };
            }
          }

          setRosters({
            data: newShifts,
            isLoading: false,
          });
          setCalendarRosters({
            data: calendarRes?.data,
            isLoading: false,
          });
        } else {
          setRosters({
            data: [],
            isLoading: false,
          });
          setCalendarRosters({
            data: [],
            isLoading: false,
          });
        }
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (!visible || !shift) return;
    loadData();
  }, [shift, month, visible]);

  const handleCreate = (rosters) => {
    dispatch(createRoster(rosters)).then((res) => {
      if (res?.status === "success") {
        loadData();
        // onClose();
      }
    });
  };

  return (
    <Drawer
      className="categoriesDrawer"
      width={"min(100vw, 735px)"}
      size="large"
      visible={visible}
      onClose={onClose}
      text="Create Availability Request"
      destroyOnClose={true}
      footer={
        <div className="categoriesDrawerFooter">
          <AntdButton type="text" onClick={onClose}>
            Cancel
          </AntdButton>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              gap: 12,
            }}
          >
            {/* <AntdButton type="primary" icon={<SyncOutlined />} shape="circle" /> */}

            <Button
              rounded
              type={"primary"}
              style={{ height: 50 }}
              onClick={() => setReviewMode((state) => !state)}
            >
              {reviewMode ? "Edit" : "Review"}
            </Button>
            <Button
              rounded
              type={"primary"}
              style={{ height: 50, width: 160 }}
              onClick={() => submitBtnRef?.current?.click()}
            >
              Save
            </Button>
          </div>
        </div>
      }
    >
      <Form
        style={{
          display: reviewMode ? "none" : "block",
        }}
        preserve={false}
        layout="vertical"
        form={form}
        requiredMark={false}
        onFinish={(values) => {
          const submittableData = [];
          for (let shift of rosters?.data?.filter(
            (data) => !data?.isBackendFetched
          ) ?? []) {
            const day = moment(shift.rosterDate).format("dddd");
            const facilityShift =
              facilityShifts?.find(
                (facilityShift) =>
                  facilityShift.day === day &&
                  facilityShift.shiftId === shift?.shiftId
              ) ?? {};
            submittableData.push({
              rosterDate: shift?.rosterDate,
              facilityShiftId: facilityShift?.facilityShiftId,
              shiftId: shift?.shiftId,
              operationTypeId: shift?.operationTypeId,
              staffId: currentUser?.staffId,
              facilityId: selectedFacilityId,
            });
          }
          handleCreate(submittableData);
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
      >
        <Col>
          <FormSelect
            label="Select Month"
            name="month"
            placeholder={"Select Month"}
            required
            variant="underlined"
            renderLabel="label"
            renderValue="value"
            options={monthOptions}
            formItemStyles={{ marginBottom: 12 }}
            onChange={(e) => {
              if (!shift) {
                const slots = generateDaySlots(e, true);
                setDaySlots(slots);
              }
            }}
          />
        </Col>
        <Col>
          <FormSelect
            label="Select Shift"
            name="shift"
            placeholder={"Select Shift"}
            required
            variant="underlined"
            renderLabel="shiftDefinition"
            renderValue="shiftId"
            loading={isLoadingFacilityShifts}
            options={shiftsList?.filter((it) => it?.isActive)}
          />
        </Col>
        <ShiftAvailabiltyWidget
          shift={shift}
          rosters={rosters}
          setRosters={setRosters}
          calendarRosters={calendarRosters?.data ?? []}
          isCalendarRostersLoading={calendarRosters?.isLoading ?? []}
          monthOptions={monthOptions}
          facilityShifts={facilityShifts}
          isLoadingFacilityShifts={isLoadingFacilityShifts}
          daySlots={daySlots}
        />
        <FormHiddenSubmitButton submitRef={submitBtnRef} />
      </Form>
      {reviewMode && (
        <AvailabilityReview
          rosters={rosters}
          facilityShifts={facilityShifts}
          month={month}
          shift={shift}
          daySlots={daySlots}
        />
      )}
      {!reviewMode && (
        <div style={{ marginTop: 24 }}>
          <Typography.Title
            style={{
              fontSize: 19,
              color: "#000",
            }}
          >
            Color Codes
          </Typography.Title>
          <div>
            {Colors.map((item) => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      height: 20,
                      width: 20,
                      display: "inline-block",
                      backgroundColor: item.color,
                      marginBottom: 4,
                    }}
                  >
                    &nbsp;
                  </span>
                  <span style={{ marginLeft: 12 }}>{item.describtion}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </Drawer>
  );
}
