import React, { useState } from "react";
import { Space, Typography, Button as AntdButton } from "antd";
import Drawer from "components/Drawer/Drawer";
import ProgramsList from "./ProgramsList";

const { Title, Link } = Typography;

export default function ProgramRow({ customerId, paymentMethodId, refreshRewards }) {
  const [show, setShow] = useState(false);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Title
        style={{
          fontSize: 17,
          color: "#000",
          margin: 0,
        }}
      >
        Programs & Memberships
      </Title>
      <AntdButton
        type="text"
        hidden={!customerId}
        onClick={() => {
          setShow(true);
        }}
      >
        View
      </AntdButton>
      <Drawer
        className="eventDrawer"
        visible={show}
        onClose={() => setShow(false)}
        text={
          <Space>
            <span className="eventDrawerTitle">
              Purchased Programs & Memberships
            </span>
          </Space>
        }
        destroyOnClose={true}
      >
        <ProgramsList
          customerId={customerId}
          paymentMethodId={paymentMethodId}
          onSave={refreshRewards}
        />
      </Drawer>
    </div>
  );
}
