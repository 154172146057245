import { openNotificationWithIcon } from "utils/Notification";
import { request } from "./verb.services";
import { setLoading } from "redux/actions/app.actions";

export const getKitsInfo = (productId, kit, productName, isAddon) => {
  return (dispatch) => {
    // dispatch(setLoading(true));
    return request(
      `products/${productId}/PreferenceCard?kitNo=${kit}`,
      "get",
      null,
      true
    )
      .then((response) => {
        for (let product of response?.data?.inventoryProducts) {
          product.maxQuantity = product.quantity;
        }
        return {
          status: "success",
          data: response?.data ?? [],
        };
      })
      .catch((e, a) => {
        openNotificationWithIcon(
          "error",
          `${productName} ${isAddon ? "(Add-on)" : "(Service)"}`,
          `Preference card no found or some internal error has occured` ||
            e?.response?.Message ||
            e?.response?.data?.message ||
            "Network error has occured"
        );
      })
      .finally(() => {
        // dispatch(setLoading(false));
      });
  };
};

export const fetchLots = (kitNo, facilityProductId) => {
  return (dispatch) => {
    return request(
      `products/lots?kitNo=${kitNo}&facilityProductId=${facilityProductId}`,
      "get",
      null,
      true
    )
      .then((response) => {
        return {
          status: "success",
          data: response?.data ?? [],
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {});
  };
};

export const updateInventoryProduct = (
  orderid,
  kitNo,
  data
  // inventoryFacilityId,
  // inventoryId,
  // quantity
) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Orders/${orderid}/disposeInventory?kitNo=${kitNo}`,
      "patch",
      data,
      true
    )
      .then((response) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Inventory updated successfully"
        );
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getSnapshotInfo = (orderId) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Orders/${orderId}/DisposedInventories`, "get", null, true)
      .then((response) => {
        return {
          status: "success",
          data: response?.data ?? [],
        };
      })
      .catch((e, a) => {
        openNotificationWithIcon(
          "error",
          `Error`,
          e?.response?.Message ||
            e?.response?.data?.message ||
            "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
