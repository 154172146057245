import {
  setCenters,
  setOperationTypes,
  setProducts,
  setAddons,
  setFetching,
} from "../redux/actions/locationCenter.action";
import { openNotificationWithIcon } from "utils/Notification";
import { request } from "./verb.services";
import { Result } from "antd";

import { CalenderOperationType, ProductType } from "utils/enums";
import { getProductTypeFromOperation } from "utils/common";
import { GOOGLE_MAP_KEY } from "utils/constants";

export const getFacilitiesByStreetAddress = (
  streetAddress = "",
  { lat, lng } = {}
) => {
  return (dispatch) => {
    // dispatch(setFetching("isFetchingCenters", true));
    return request(
      `Facilities?streetAddress=${streetAddress}${
        lat ? `&lat=${escape(lat)}` : ""
      }${lng ? `&lng=${escape(lng)}` : ""}`,
      "get",
      {
        streetAddress,
      },
      true
    )
      .then((response) => {
        // TODO: contact backend and have it return array instead of object
        // then use it directly instead of array and object checks
        let data =
          response.data.constructor === Object
            ? [response.data]
            : response.data;
        // dispatch(setCenters(data, clearCascadingSelectors));
        return data;
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        // dispatch(setFetching("isFetchingCenters", false));
      });
  };
};

export const getOperationTypesByFacility = (facilityId) => {
  return (dispatch) => {
    // dispatch(setFetching("isFetchingOperationTypes", true));

    return request(`Facilities/${facilityId}/OperationTypes`, "get", null, true)
      .then((response) => {
        // TODO: contact backend and have it return array instead of object
        // then use it directly instead of array and object checks
        let data =
          response.data.constructor === Object
            ? [response.data]
            : response.data;
        return data;
        // dispatch(setOperationTypes(data, clearCascadingSelectors));
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        // dispatch(setFetching("isFetchingOperationTypes", false));
      });
  };
};
export const getProducts = (
  facilityId,
  operationTypeId,
  clearCascadingSelectors = false
) => {
  return (dispatch) => {
    dispatch(setFetching("isFetchingProducts", true));
    const productType = getProductTypeFromOperation(operationTypeId);
    return request(
      `Facilities/${facilityId}/OperationTypes/${operationTypeId}/Products?productType=${productType}`,
      "get",
      null,
      false
    )
      .then((response) => {
        if (response?.data?.sort)
          response.data.sort((a, b) => {
            return `${a.productCategoryName}`.localeCompare(
              b.productCategoryName
            );
          });
        dispatch(setProducts(response.data, clearCascadingSelectors));

        return response.data;
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setFetching("isFetchingProducts", false));
      });
  };
};

export const getAddons = (
  facilityId,
  operationTypeId,
  productId,
  setLoader
) => {
  return (dispatch) => {
    if (setLoader) dispatch(setFetching("isFetchingAddons", true));

    return request(
      `Facilities/${facilityId}/OperationTypes/${operationTypeId}/Products/${productId}/AddOns`,
      "get",
      null,
      false
    )
      .then((response) => {
        let data = response.data;
        // dispatch(setAddons(data));
        return data;
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        if (setLoader) dispatch(setFetching("isFetchingAddons", false));
      });
  };
};

export const getFacilityTimings = (scheduledDate, facilityId, callback) => {
  return (dispatch) => {
    return request(
      `Facilities/${facilityId}/OperationalTimings?serviceDate=${scheduledDate}`,
      "get",
      null,
      true
    )
      .then((response) => {
        let data = response.data;
        // dispatch(setAddons(data));
        return {
          status: "success",
          data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            "Network error has occured while fetching shift timings."
        );
      })
      .finally(() => {
        callback?.();
      });
  };
};

export const getStateAndCountryFromZip = (address) => {
  const urlEncodedStreetAddress = encodeURIComponent(address);
  return fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_MAP_KEY}&fields=formatted_address&address=${urlEncodedStreetAddress}`
  )
    .then((response) => response.json())
    .then((data) => {
      if (data?.length === 0) return {};
      else {
        let result = data.results[0];
        let state = "";
        let country = "US";
        let zipCode = "";
        for (let addressComponent of result?.["address_components"]) {
          if (
            addressComponent?.["types"]?.includes("administrative_area_level_1")
          )
            state = addressComponent?.["short_name"];
          else if (addressComponent?.["types"]?.includes("country"))
            country = addressComponent?.["short_name"];
          else if (addressComponent?.["types"]?.includes("postal_code"))
            zipCode = addressComponent?.["short_name"];
        }
        return {
          state,
          country,
          zipCode,
        };
      }
    })
    .catch(() => {
      return {};
    });
};
