import React, { useRef } from "react";
import PropTypes from "prop-types";

import { Form, Row, Col } from "antd";
import { Button } from "components/Buttons/Buttons";

import Modal from "components/Modal/Modal";
import {
  FormInput,
  FormInputArea,
  FormButton,
  FormSelect,
  FormHiddenSubmitButton,
} from "components/FormItems/FlatFormItems";
import { createAddress, updateAddress } from "services/addresses.service";
import { useDispatch, useSelector } from "react-redux";
import SearchLocationInput from "components/LocationInput/SearchLocationInput";
import { openNotificationWithIcon } from "utils/Notification";
import LocationDropdown from "components/LocationDropdown/LocationDropdownNew";
function AddAddressModal({
  visible,
  title,
  handleSave,
  onCancel,
  type = "add",
  data = {},
}) {
  const [form] = Form.useForm();
  const submitBtnRef = useRef();

  const dispatch = useDispatch();

  const { addressId, address, customerId } = data;

  return (
    <Modal
      destroyOnClose={true}
      headerBorder={false}
      width="420px"
      title={title}
      onCancel={onCancel}
      visible={visible}
      okText="Save"
      footer={[
        <Button rounded={true} onClick={() => submitBtnRef?.current?.click()}>
          Save
        </Button>,
      ]}
    >
      <Form
        preserve={false}
        form={form}
        requiredMark={false}
        layout="vertical"
        initialValues={{
          address: address,
        }}
        onFinish={async (values) => {
          if (values.address) {
            const callback =
              type === "add"
                ? () => dispatch(createAddress(customerId, values))
                : () => dispatch(updateAddress(customerId, addressId, values));

            const result = await callback();
            if (result?.status === "success")
              handleSave?.({
                values,
              });
          } else {
            openNotificationWithIcon(
              "warning",
              "Invalid Street Address",
              "Please enter a valid address"
            );
          }
        }}
        onValuesChange={(changedValues, allValues) => {}}
      >
        <Row gutter={[24, 16]}>
          <Col xs={24}>
            {/* <FormInput
              variant="underlined"
              name="address"
              label="Street Address"
              placeholder="Street address"
              formItemStyles={{ marginBottom: 0 }}
            /> */}
            <LocationDropdown
              // inputValue={address}
              name={"address"}
              form={form}
              
              // onChange={(facilityId, value) => {
              //   form.setFieldsValue({ address: value });
              // }}
              className={"flatFormItem underlined addressLocation"}
              label={"Street Address"}
            />
          </Col>

          <Col xs={0} md={12}></Col>
          {/* <Col span={24}>
            <FormInputArea
              variant="underlined"
              rows={6}
              name="detail"
              label="Instructions"
              placeholder="General Instructions if any!"
              formItemStyles={{ marginBottom: 0 }}
            />
          </Col> */}
        </Row>

        <FormHiddenSubmitButton submitRef={submitBtnRef} />
      </Form>
    </Modal>
  );
}

AddAddressModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.any,
  handleSave: PropTypes.any,
  title: PropTypes.string,
  type: PropTypes.oneOf(["add", "edit"]),
};

export default AddAddressModal;
