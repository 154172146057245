import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

import { useDispatch, useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CardNumberElement, CardExpiryElement } from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";

import {
  Row,
  Col,
  Form,
  Typography,
  Divider,
  Button as AntdButton,
} from "antd";
import {
  FormInput,
  FormButton,
  FormCheckbox,
} from "components/FormItems/FlatFormItems";

import Stripe from "components/Stripe/Stripe";
import InfoText from "components/InfoText/InfoText";

// import {
//   getAllPaymentMethods,
//   getSetupIntent,
// } from "services/paymentMethod.service";
import { openNotificationWithIcon } from "utils/Notification";
import { setLoading, setSubmitting } from "redux/actions/app.actions";

import { Button } from "components/Buttons/Buttons";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

function StripePayment({
  saveText = "Add & Order",
  onSuccess,
  submitting,
  isRequired = true,
  handleSubmit: handleSubmitProp,
  stripeErrorListener,
  onClose,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [form] = Form.useForm();
  const [isCardEmpty, setisCardEmpty] = useState(true);

  const dispatch = useDispatch();

  const submitBtnRef = useRef();
  const [setupIntent, setSetupIntent] = useState(undefined);

  const { currentUser } = useSelector((state) => {
    return {
      currentUser: state.user.currentUser,
    };
  });

  useEffect(() => {
    // dispatch(getSetupIntent()).then((token) => {
    //   setSetupIntent(token);
    // });
  }, []);

  const handleSubmit = async (data) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    // event.preventDefault();
    if (handleSubmitProp) {
      handleSubmitProp(stripe, elements, data.name);
      return;
    }

    if (!stripe || !elements) {
      console.log("Stripe or elements not loaded");
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    dispatch(setLoading(true));
    const result = await stripe.confirmCardSetup(setupIntent, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: data.name,
          email: currentUser?.email,
        },
        metadata: {
          isPrimary: true,
        },
      },
    });
    if (result?.error) {
      // Show error to your customer (for example, insufficient funds)
      openNotificationWithIcon(
        "error",
        "Error!",
        result?.error?.message || "Network error has occured"
      );
      dispatch(setLoading(false));
    } else {
      // The payment has been processed!
      if (result.setupIntent.status === "succeeded") {
        if (onSuccess)
          onSuccess({
            isChecked: data.optNewsletter ?? false,
          });
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }
    }
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      requiredMark={false}
      className="stripeForm"
      initialValues={{ optNewsletter: true }}
    >
      <Row gutter={[24, 16]}>
        <Col xs={24}>
          <Stripe isRequired={isRequired} onChange={stripeErrorListener} />
        </Col>
        <Col
          xs={24}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <AntdButton type="text" onClick={onClose}>
            Cancel
          </AntdButton>
          <Button htmlType="submit" rounded style={{ height: 50 }}>
            Save & Continue
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

StripePayment.propTypes = {};

const StripePaymentWrapper = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <StripePayment {...props} />
    </Elements>
  );
};

export default StripePaymentWrapper;
