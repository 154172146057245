import { useMemo } from "react";
import { openChartURL } from "utils/common";
import { Button, Tooltip } from "antd";
import { is } from "date-fns/locale";

function FillChartBtn({ chart, orderId, lineItemId, customer }) {
  const {
    isPhysicalHistoryExpired,
    isConsentExpired,
    intakeFormSubmitted,
    isConsentFormSubmitted,
  } = customer || {};
  const text = useMemo(() => {
    if (!customer) {
      return "Customer information not loaded yet.";
    }

    let text = "";
    if (isPhysicalHistoryExpired) {
      text = "Physical History is expired. ";
    } else if (!intakeFormSubmitted) {
      text = "Intake form is not submitted. ";
    }
    if (isConsentExpired) {
      text += "Consent is expired. ";
    } else if (!isConsentFormSubmitted) {
      text += "Consent form is not submitted. ";
    }

    return text;
  }, [
    customer,
    isPhysicalHistoryExpired,
    isConsentExpired,
    intakeFormSubmitted,
    isConsentFormSubmitted,
  ]);
  console.log("text", text);
  return (
    <Tooltip title={text}>
      <Button
        type="text"
        style={{ padding: 0 }}
        disabled={
          chart?.isLoading ||
          !chart?.data?.chartId ||
          !customer ||
          isPhysicalHistoryExpired ||
          isConsentExpired ||
          !intakeFormSubmitted ||
          !isConsentFormSubmitted
        }
        onClick={() => {
          openChartURL(orderId, lineItemId, {
            isLegacy: chart?.data?.isLegacy,
            productName: chart?.data?.legacyChartName,
          });
        }}
        loading={chart?.isLoading || !customer}
      >
        Fill Chart
      </Button>
    </Tooltip>
  );
}

export default FillChartBtn;
