import React, { useEffect, useReducer, useState } from "react";

import "./Programs.style.scss";
import { Button } from "components/Buttons/Buttons";
import { Dropdown, Menu, Modal, Select, Space, Table } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import ProgramViewModal from "./components/ProgramViewModal";
import ProgramCreateModal from "./components/ProgramCreateModal";
import useSettingsHeader from "hooks/useSettingsHeader";
import { useDispatch } from "react-redux";
import {
  createProgram,
  deleteProgram,
  getAllPrograms,
  restoreProgram,
  updateProgram,
  getAllReferralPolicies,
  deleteReferral,
  restoreReferral,
  getAllVouchers,
  deleteVoucher,
  restoreVoucher,
} from "services/programsAndMembership.services";
import {
  getAllBoosts,
  getAllServices,
} from "services/servicesAndBoostsSettings.service";
import SearchAndFilterColumns from "components/SearchAndFilterColumns/SearchAndFilterColumns";
import usePortal from "hooks/usePortal";
import { getEnumKeyByValue, PROGRAM_TYPE } from "utils/enums";
import { getAllTaxCategories } from "services/taxation.service";
import moment from "moment";
import { setLoading } from "redux/actions/app.actions";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET":
      return action.data;
    case "ADD":
      return [...state, action.data];
    case "UPDATE":
      return state.map((data) => {
        if (data.categoryId === action.data.categoryId) return action.data;
        return data;
      });
    case "DELETE":
      return state.filter((data) => {
        if (data.categoryId === action.data.categoryId) return false;
        return true;
      });
    default:
      return state;
  }
};

export default function Programs() {
  //states start
  const [showAddEditModal, setShowAddEditModal] = useState();
  const [showDetails, setShowDetails] = useState();
  const [data, localDispatch] = useReducer(reducer, []);

  const [products, setProducts] = useState([]);
  const [addOns, setAddOns] = useState([]);
  const [taxCategories, setTaxCategories] = useState([]);

  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [activeFilter, setActiveFilter] = useState("active"); // all, active, inactive
  const [searchText, setSearchText] = useState("");
  //states end

  // non-data hooks start
  const dispatch = useDispatch();
  // non-data hooks end

  // use effect starts
  const loadData = () => {
    dispatch(setLoading(true));
    Promise.all([
      dispatch(getAllPrograms()),
      dispatch(getAllReferralPolicies()),
      dispatch(getAllVouchers()),
    ])
      .then((res) => {
        const [result, resultReferral, resultVouchers] = res;
        const referals =
          resultReferral?.data?.map?.((res) => {
            return {
              ...res,
              programType: PROGRAM_TYPE.Referral,
              programName: res.referralPolicyName,
            };
          }) ?? [];

        const vouchers =
          resultVouchers?.data?.map?.((res) => {
            return {
              ...res,
              programType: PROGRAM_TYPE["Voucher Code"],
              programName: res.voucherCode,
              fromDate: res.validFrom ? moment(res.validFrom) : undefined,
              tillDate: res.validFrom ? moment(res.validTill) : undefined,
              benefitType: res.discountType,
              sellingPrice: res.discount,
            };
          }) ?? [];

        if (result?.status === "success") {
          localDispatch({
            type: "SET",
            data: [...result.data, ...referals, ...vouchers],
          });
        }
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
    // const refArr = [];
    // refArr.push(resultReferral.data);
  };
  useEffect(() => {
    loadData();
    Promise.all([
      dispatch(getAllServices(false)),
      dispatch(getAllBoosts(false)),
    ]).then((result) => {
      const [resultServices, resultBoosts] = result;

      if (resultServices?.status === "success") {
        setProducts(
          resultServices.data?.map?.((product) => {
            product.productName = product.productName + " - " + "Service";
            return product;
          }) ?? []
        );
      }
      if (resultBoosts?.status === "success") {
        setAddOns(
          resultBoosts.data?.map?.((product) => {
            product.productName = product.productName + " - " + "Boost";
            return product;
          }) ?? []
        );
      }
    });

    dispatch(getAllTaxCategories(false)).then((result) => {
      if (result?.status === "success") {
        setTaxCategories(result.data);
      }
    });
  }, []);

  // use effects end

  // functions start
  const handleDelete = (id, type = "program") => {
    if (type == "program") {
      dispatch(deleteProgram(id)).then((result) => {
        if (result?.status === "success") {
          loadData();
        }
      });
    } else if (type == "voucher") {
      dispatch(deleteVoucher(id)).then((result) => {
        if (result?.status === "success") {
          loadData();
        }
      });
    } else {
      dispatch(deleteReferral(id)).then((result) => {
        if (result?.status === "success") {
          loadData();
        }
      });
    }
  };
  const handleRestore = (id, type = "program") => {
    if (type == "program") {
      dispatch(restoreProgram(id)).then((result) => {
        if (result?.status === "success") {
          loadData();
        }
      });
    } else if (type == "voucher") {
      dispatch(restoreVoucher(id)).then((result) => {
        if (result?.status === "success") {
          loadData();
        }
      });
    } else {
      dispatch(restoreReferral(id)).then((result) => {
        if (result?.status === "success") {
          loadData();
        }
      });
    }
  };

  const deleteConfirmation = (id, type = "program") => ({
    title: "Inactivate Program",
    content: <>{`Are you sure that you want to inactivate the ${type}?`}</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      if (type == "program") {
        handleDelete(id);
      } else if (type === "voucher") {
        handleDelete(id, "voucher");
      } else {
        handleDelete(id, " referral");
      }
      close();
    },
  });

  const restoreConfirmation = (id, type = "program") => ({
    title: "Activate Program",
    content: <>{`Are you sure that you want to activate the ${type}?`}</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      if (type == "program") {
        handleRestore(id);
      } else if (type === "voucher") {
        handleRestore(id, "voucher");
      } else {
        handleRestore(id, "referral");
      }
      close();
    },
  });

  const checkActiveFilter = (item) => {
    if (activeFilter === "all") return true;
    else if (activeFilter === "active" && item?.isActive) return true;
    else if (activeFilter === "inactive" && !item?.isActive) return true;
    return false;
  };

  const settingHeaderContent = useSettingsHeader(
    <Button
      rounded={true}
      onClick={() => {
        setShowAddEditModal(true);
      }}
    >
      Create Program
    </Button>
  );

  const columns = [
    {
      title: "Program Name",
      dataIndex: "programName",
      key: "programName",
      sorter: (a, b) => {
        if (a.programName?.toLowerCase?.() < b.programName?.toLowerCase?.())
          return -1;
        if (a.programName?.toLowerCase?.() > b.programName?.toLowerCase?.())
          return 1;
        return 0;
      },
    },
    {
      title: "Program Type",
      key: "programType",
      render: (record) => {
        if (record?.programType) {
          const programType = getEnumKeyByValue(
            PROGRAM_TYPE,
            record.programType
          );
          return programType;
        }
      },
      sorter: (a, b) => {
        const pType1 = getEnumKeyByValue(PROGRAM_TYPE, a.programType);
        const pType2 = getEnumKeyByValue(PROGRAM_TYPE, b.programType);
        if (pType1?.toLowerCase?.() < pType2?.toLowerCase?.()) return -1;
        if (pType1?.toLowerCase?.() > pType2?.toLowerCase?.()) return 1;
        return 0;
      },
    },
    {
      title: "Status",
      key: "status",
      render: (record) => {
        return record?.isActive ? "Active" : "Inactive";
      },
    },
    {
      title: "",
      key: "action",
      align: "right",
      width: 80,
      render: (record) => {
        return (
          <Dropdown
            overlay={
              <Menu style={{ borderRadius: 6, border: "2px solid #CDD5D8 " }}>
                <Menu.Item
                  onClick={() => setShowAddEditModal(record)}
                  key="edit"
                >
                  Edit
                </Menu.Item>
                <Menu.Item
                  key="delete"
                  onClick={() => {
                    if (record.programType == PROGRAM_TYPE["Voucher Code"]) {
                      if (record?.isActive)
                        Modal.warning(
                          deleteConfirmation(record.voucherId, "voucher")
                        );
                      else
                        Modal.warning(
                          restoreConfirmation(record.voucherId, "voucher")
                        );
                    } else if (record.programType == PROGRAM_TYPE.Referral) {
                      if (record?.isActive)
                        Modal.warning(
                          deleteConfirmation(
                            record.referralPolicyId,
                            "referral"
                          )
                        );
                      else
                        Modal.warning(
                          restoreConfirmation(
                            record.referralPolicyId,
                            "referral"
                          )
                        );
                    } else {
                      if (record?.isActive)
                        Modal.warning(deleteConfirmation(record.programId));
                      else Modal.warning(restoreConfirmation(record.programId));
                    }
                  }}
                >
                  {record?.isActive ? "Inactivate" : "Activate"}
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
            getPopupContainer={(triggerNode) => triggerNode?.parentNode}
          >
            <MoreOutlined style={{ fontSize: 18 }} />
          </Dropdown>
        );
      },
    },
  ];

  const searchAndFiltersContent = usePortal(
    "#settingHeader .settingsHeaderLeftSideAddon",
    <Space>
      <SearchAndFilterColumns
        columns={columns}
        hiddenColumns={hiddenColumns}
        setHiddenColumns={(columnTitles) => {
          setHiddenColumns(columnTitles);
        }}
        searchText={searchText}
        setSearchText={(text) => {
          setSearchText(text);
        }}
      />
      <Select
        style={{ width: 120 }}
        value={activeFilter}
        onChange={(value) => {
          setActiveFilter(value);
        }}
        options={[
          {
            value: "all",
            label: "All",
          },
          {
            value: "active",
            label: "Active",
          },
          {
            value: "inactive",
            label: "Inactive",
          },
        ]}
      />
    </Space>
  );

  return (
    <div>
      {settingHeaderContent}
      {searchAndFiltersContent}
      <Table
        tableLayout="auto"
        locale={{ emptyText: "No Programs" }}
        columns={columns.filter((column) => {
          if (!hiddenColumns.includes(column.title)) return true;
          return false;
        })}
        rowKey={(data) => data.programId}
        dataSource={data?.filter(
          (it) =>
            checkActiveFilter(it) &&
            it?.programName
              ?.toLowerCase?.()
              .includes?.(searchText.toLowerCase())
        )}
        // dataSource={allGuests.filter((data) => {
        //   return data.name.toLowerCase().includes(searchText.toLowerCase());
        // })}
        // columns={columns.filter((column) => {
        //   if (!hiddenColumns.includes(column.title)) return true;
        //   return false;
        // })}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: [15, 25, 50, 100, 250],
        }}
        // scroll={{ y: "calc(100vh - 80px - 96px - 60px - 100px)", x: true }}
      />
      <ProgramViewModal
        visible={showDetails}
        onClose={() => {
          setShowDetails(false);
        }}
      />
      <ProgramCreateModal
        data={showAddEditModal}
        products={[...products, ...addOns]}
        visible={showAddEditModal}
        taxCategories={taxCategories}
        onCancel={() => {
          setShowAddEditModal();
        }}
        onSuccess={() => {
          setShowAddEditModal();
          loadData();
        }}
      />
    </div>
  );
}
