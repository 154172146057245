import { Form, Spin, Typography, Row, Col } from "antd";
import "./Login.style.scss";
import { useDispatch } from "react-redux";
import { push, replace } from "connected-react-router";
import {
  FormInput,
  FormCheckbox,
  FormButton,
  FormPassword,
} from "../../components/FormItems/FlatFormItems";
import { Button } from "components/Buttons/Buttons";
import { loginUser } from "../../services/auth.services";
import { useSelector } from "react-redux";

import AuthLayout from "components/AuthLayout/AuthLayout";

const Login = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { loading, message } = useSelector((state) => state.app);

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  return (
    <AuthLayout loading={loading} message={message}>
      <Form
        requiredMark={false}
        className="loginForm"
        layout="vertical"
        form={form}
        preserve={false}
        onFinishFailed={onFinishFailed}
        onFinish={(values) => {
          form
            .validateFields()
            .then(async (values) => {
              await dispatch(loginUser(values));
              dispatch(replace("/"));
            })
            .catch((err) => {
              form.scrollToField(err?.errorFields?.[0]);
            });
        }}
      >
        <FormInput
          label="Username / Email"
          name="username"
          required
          placeholder="Username"
          initialValue={""}
          styles={null}
          type={"text"}
        />
        <FormInput
          label="Password"
          name="password"
          required
          placeholder="Password"
          initialValue={""}
          styles={null}
          type={"password"}
        />
        <Button
          style={{ height: 50, width: '100%' }}
          onClick={() => {}}
          type="primary"
          htmlType="submit"
        >
          {"Login"}
        </Button>
      </Form>
      <Typography.Link
        underline
        className="forgotPasswordText"
        onClick={() => dispatch(push("reset"))}
      >
        Forgot Password?
      </Typography.Link>
    </AuthLayout>
  );
};

export default Login;
