import { setDailyBookings } from "redux/actions/booking.actions";
import { setAllOrders } from "redux/actions/order.actions";
import { setAllDealerActivities } from "../redux/actions/activity.actions";
import { setAllStaff } from "../redux/actions/user.actions";
import { openNotificationWithIcon } from "../utils/Notification";
import { request } from "./verb.services";

export const getAllOrders = (data) => {
  return (dispatch) => {
    return request("Orders/FetchOrders", "post", data, true, null)
      .then(({ data }) => {
        data?.forEach?.((booking) => {
          delete booking.amountPaidUntilNow;
        });
        dispatch(setAllOrders(data));
        dispatch(setDailyBookings(data)); // JUGGAR
        return data;
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};

export const addNewStaff = (data, prevSource) => {
  return (dispatch) => {
    if (data.imageBase64String) {
      data.imageBase64String = data.imageBase64String.split("base64,")[1];
    }
    // dispatch(setAllDealer([...prevSource, { ...data }]))
    return request("Staffs/", "post", data, true)
      .then(({ data }) => {
        dispatch(setAllStaff([{ ...data }, ...prevSource]));
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};

export const updateStaff = (id, data, prevSource) => {
  if (data.imageBase64String) {
    data.imageBase64String = data.imageBase64String.split("base64,")[1];
  }
  return (dispatch) => {
    return request(`Staffs/${id}`, "put", data, true)
      .then(({ data }) => {
        let newSource = [...prevSource];
        newSource = newSource.map((o) => {
          if (o.staffId === data.staffId) {
            return { ...data };
          }
          return o;
        });
        dispatch(setAllStaff(newSource));
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};

export const updateDealerAmount = (id, data, prevSource) => {
  return (dispatch) => {
    return request(`wallets/${id}`, "put", data, true)
      .then(({ data }) => {
        let newSource = [...prevSource];
        newSource = newSource.map((o) => {
          if (o._id === id) {
            return { ...data.data };
          }
          return o;
        });
        dispatch(setAllStaff(newSource));
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};

export const getDealerPaymentActivities = () => {
  return (dispatch) => {
    return request("activities", "get", null, true, { role: "dealer" })
      .then(({ data }) => {
        dispatch(setAllDealerActivities(data.data));
        return data.data;
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};
