import React, { useEffect, useReducer, useRef, useState } from "react";
import moment from "moment";
import { MoreOutlined } from "@ant-design/icons";
import {
  Dropdown,
  Menu,
  Space,
  Table,
  Button as AntdButton,
  Modal,
  Select,
} from "antd";
import { Button } from "components/Buttons/Buttons";
import { DropdownButton } from "components/DropdownButton/DropdownButton";
import useSettingsHeader from "hooks/useSettingsHeader";

import "./ServicesAndBoosts.style.scss";
import ServicesDrawer from "./components/ServicesDrawer";
import BoostDrawer from "./components/BoostDrawer";
import { useDispatch, useSelector } from "react-redux";
import { push, replace } from "connected-react-router";
import { getAllCategories } from "services/categoriesSettings.services";
import {
  createBoost,
  createService,
  deleteService,
  getAllBoosts,
  getAllServices,
  restoreService,
  updateService,
} from "services/servicesAndBoostsSettings.service";
import { arrayCommaSeperatedReducer } from "utils/common";
import SearchAndFilterColumns from "components/SearchAndFilterColumns/SearchAndFilterColumns";
import usePortal from "hooks/usePortal";
import { useLocation } from "react-router";

import qs from "qs";
import { getAllSkills } from "services/skills.services";
import { getAllTaxCategories } from "services/taxation.service";
import { getAllAccounts } from "services/quickbook.service";
import { result } from "lodash";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET":
      return action.data;
    case "ADD":
      return [...state, action.data];
    case "UPDATE":
      return state.map((data) => {
        if (data.categoryId === action.data.categoryId) return action.data;
        return data;
      });
    case "DELETE":
      return state.filter((data) => {
        if (data.categoryId === action.data.categoryId) return false;
        return true;
      });
    default:
      return state;
  }
};

export default function ServicesAndBoosts() {
  const [showServiceModal, setShowServiceModal] = useState();
  const [showBoostModal, setShowBoostModal] = useState();

  const [categories, setCategories] = useState([]);
  const [boosts, setBoosts] = useState([]);
  const [taxCategories, setTaxCategories] = useState([]);
  const [accounts, setAccounts] = useState({
    data: [],
    loading: true,
  });

  const dispatch = useDispatch();
  const [data, localDispatch] = useReducer(reducer, []);

  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [activeFilter, setActiveFilter] = useState("active"); // all, active, inactive
  const [searchText, setSearchText] = useState("");

  const { skills } = useSelector((state) => ({
    skills: state.skills.allSkills,
  }));

  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const categorySearch =
    query?.category !== undefined ? Number(query.category) : undefined;

  // use effects start
  const loadData = async () => {
    const result = await dispatch(
      getAllServices((data) => {
        localDispatch({ action: "SET", data });
      })
    );
    if (result?.status === "success") {
      localDispatch({
        type: "SET",
        data: result.data,
      });
    }
  };

  useEffect(() => {
    loadData();
    dispatch(getAllSkills());

    dispatch(getAllCategories(false)).then((result) => {
      if (result?.status === "success") {
        setCategories(result.data);
      }
    });
    dispatch(getAllServices()).then((result) => {
      if (result?.status === "success") {
        localDispatch({ type: "SET", data: result.data });
      }
    });
    dispatch(getAllBoosts(false)).then((result) => {
      if (result?.status === "success") {
        setBoosts(result.data);
      }
    });
    dispatch(getAllTaxCategories(false)).then((result) => {
      if (result?.status === "success") {
        setTaxCategories(result.data);
      }
    });
    dispatch(getAllAccounts(false)).then((res) => {
      if (res?.status === "success") {
        setAccounts({
          data: res.data,
          loading: false,
        });
      } else {
        setAccounts({
          data: [],
          loading: false,
        });
      }
    });
    // dispatch(getAllSkills());
  }, []);

  // use effects end
  // functions start
  const onMenuClick = (e) => {
    if (e.key === "1") {
      setShowServiceModal(true);
    } else if (e.key === "2") {
      setShowBoostModal(true);
    } else if (e.key === "3") {
      setShowServiceModal("3");
    }
  };

  const handleAdd = (values) => {
    dispatch(createService(values)).then((result) => {
      if (result?.status === "success") {
        setShowServiceModal();
        loadData();
        // loadData();
      }
    });
  };

  const handleAddBoost = (values) => {
    dispatch(createBoost(values)).then((result) => {
      if (result?.status === "success") {
        setShowBoostModal();
        dispatch(getAllBoosts()).then((result) => {
          if (result?.status === "success") {
            setBoosts(result.data);
          }
        });
        // loadData();
      }
    });
  };

  const handleUpdate = (id, values) => {
    dispatch(updateService(id, values)).then((result) => {
      if (result?.status === "success") {
        setShowServiceModal();
        loadData();
      }
    });
  };
  const handleDelete = (id) => {
    dispatch(deleteService(id)).then((result) => {
      if (result?.status === "success") {
        loadData();
      }
    });
  };
  const handleRestore = (id) => {
    dispatch(restoreService(id)).then((result) => {
      if (result?.status === "success") {
        loadData();
      }
    });
  };
  const deleteConfirmation = (id) => ({
    title: "Inactivate Service",
    content: <>Are you sure that you want to inactivate the service?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      handleDelete(id);
      close();
    },
  });

  const restoreConfirmation = (id) => ({
    title: "Activate Service",
    content: <>Are you sure that you want to activate the service?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      handleRestore(id);
      close();
    },
  });

  const checkActiveFilter = (item) => {
    if (activeFilter === "all") return true;
    else if (activeFilter === "active" && item?.isActive) return true;
    else if (activeFilter === "inactive" && !item?.isActive) return true;
    return false;
  };
  // functions end

  const columns = [
    {
      title: "Service Name",
      dataIndex: "productName",
      key: "productName",
      sorter: (a, b) => {
        if (a.productName?.toLowerCase?.() < b.productName?.toLowerCase?.())
          return -1;
        if (a.productName?.toLowerCase?.() > b.productName?.toLowerCase?.())
          return 1;
        return 0;
      },
    },
    {
      title: "Category",
      key: "categoryName",
      render: (record) => {
        const categoriesFound = categories
          ?.filter?.((it) =>
            record?.productCategoryIds?.includes?.(it.categoryId)
          )
          ?.map?.((it) => it?.categoryName);
        return categoriesFound.join(", ");
      },

      // sorter: (a, b) => {
      //   const categoryA = categories?.find?.(
      //     (it) => it.categoryId === a.productCategoryId
      //   );
      //   const categoryB = categories?.find?.(
      //     (it) => it.categoryId === b.productCategoryId
      //   );
      //   if (categoryA.categoryName < categoryB.categoryName) return -1;
      //   if (categoryA.categoryName > categoryB.categoryName) return 1;
      //   return 0;
      // },
    },
    {
      title: "Add-ons",
      // dataIndex: "boosts",
      key: "boosts",
      render: (record) => {
        return <>{record.addOnsString} </>;
      },
    },
    {
      title: "Type",
      key: "isTele",
      width: 150,
      render: (data) => {
        if (data?.productType === 3) return "Telehealth";
        return "Clinic/Mobile";
      },
      sorter: (a, b) => {
        if (a.productType < b.productType) return -1;
        if (a.productType > b.productType) return 1;
        return 0;
      },
    },
    {
      title: "Active",
      key: "active",
      width: 100,
      render: (data) => {
        if (data?.isActive) return "Active";
        return "Inactive";
      },
    },
    {
      title: "",
      key: "action",
      align: "right",
      width: 80,
      render: (data) => (
        <Dropdown
          overlay={
            <Menu style={{ borderRadius: 6, border: "2px solid #CDD5D8 " }}>
              <Menu.Item onClick={() => setShowServiceModal(data)} key="edit">
                Edit
              </Menu.Item>
              <Menu.Item
                key="delete"
                onClick={() => {
                  if (data?.isActive)
                    Modal.warning(deleteConfirmation(data.productId));
                  else Modal.warning(restoreConfirmation(data.productId));
                }}
              >
                {data?.isActive ? "Inactivate" : "Activate"}
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
          getPopupContainer={(triggerNode) => triggerNode?.parentNode}
        >
          <MoreOutlined style={{ fontSize: 18 }} />
        </Dropdown>
      ),
    },
  ];

  const menu = (
    <Menu
      onClick={onMenuClick}
      items={[
        {
          key: "1",
          label: "Add New Service",
        },
        {
          key: "2",
          label: "Add New Add-on",
        },
        // {
        //   key: "3",
        //   label: "Add New Telehealth Service",
        // },
      ]}
    />
  );

  const settingHeaderContent = useSettingsHeader(
    <Space align="center" size="large">
      <AntdButton
        type="text"
        style={{ padding: 0, lineHeight: 1 }}
        onClick={() => dispatch(push("/dashboard/settings/boosts"))}
      >
        Manage Add-ons
      </AntdButton>
      <DropdownButton
        menu={menu}
        rounded={true}
        onClick={() => {
          setShowServiceModal(true);
        }}
      >
        Add New Service
      </DropdownButton>
    </Space>
  );

  const searchAndFiltersContent = usePortal(
    "#settingHeader .settingsHeaderLeftSideAddon",
    <Space>
      <SearchAndFilterColumns
        columns={columns}
        hiddenColumns={hiddenColumns}
        setHiddenColumns={(columnTitles) => {
          setHiddenColumns(columnTitles);
        }}
        searchText={searchText}
        setSearchText={(text) => {
          setSearchText(text);
        }}
      />
      <Select
        style={{ width: 120 }}
        value={activeFilter}
        onChange={(value) => {
          setActiveFilter(value);
        }}
        options={[
          {
            value: "all",
            label: "All",
          },
          {
            value: "active",
            label: "Active",
          },
          {
            value: "inactive",
            label: "Inactive",
          },
        ]}
      />
      <Select
        style={{ width: 160 }}
        value={query.category ?? "all"}
        onChange={(value) => {
          const categoryFilterString =
            value === "all" ? "" : `?category=${value}`;
          dispatch(replace(`${location.pathname}${categoryFilterString}`));
        }}
        defaultActiveFirstOption={true}
        options={[
          {
            value: "all",
            label: "All Categories",
          },
          ...categories?.map((it) => ({
            value: `${it?.categoryId}`,
            label: it?.categoryName,
          })),
        ]}
      />
    </Space>
  );

  return (
    <div>
      {settingHeaderContent}
      {searchAndFiltersContent}
      <BoostDrawer
        visible={showBoostModal}
        onClose={() => setShowBoostModal()}
        onSuccess={(values) => {
          handleAddBoost(values);
        }}
        taxCategories={taxCategories}
        accounts={accounts.data}
        isAccountsLoading={accounts.loading}
      />
      <ServicesDrawer
        taxCategories={taxCategories}
        isTelehealth={showServiceModal?.productType === 3}
        data={showServiceModal}
        boosts={boosts}
        categories={categories}
        skills={skills}
        visible={showServiceModal}
        onClose={() => setShowServiceModal()}
        onSuccess={(values) => {
          const isEdit = showServiceModal?.productId !== undefined;
          if (isEdit) handleUpdate(showServiceModal?.productId, values);
          else handleAdd(values);
        }}
        accounts={accounts.data}
        isAccountsLoading={accounts.loading}
      />
      <Table
        rowKey="productId"
        tableLayout="auto"
        locale={{ emptyText: "No Services" }}
        dataSource={data?.filter(
          (it) =>
            checkActiveFilter(it) &&
            it?.productName
              ?.toLowerCase?.()
              .includes?.(searchText.toLowerCase()) &&
            (categorySearch !== undefined
              ? it?.productCategoryIds?.includes?.(categorySearch)
              : true)
        )}
        columns={columns.filter((column) => {
          if (!hiddenColumns.includes(column.title)) return true;
          return false;
        })}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: [15, 25, 50, 100, 250],
        }}
        // scroll={{ y: "calc(100vh - 80px - 96px - 60px - 100px)", x: true }}
      />
    </div>
  );
}
