import { actions } from "../actions/roles.actions";

const INITIAL_STATE = {
  allRoles: [],
  currentRole: null,
  allPermissions: [],
};

const reducer = (state = INITIAL_STATE, { type, data }) => {
  switch (type) {
    case actions.SET_ALL_ROLES:
      return { ...state, allRoles: data };
    case actions.SET_CURRENT_ROLE:
      return { ...state, currentRole: data };
    case actions.SET_ALL_PERMISSIONS:
      return { ...state, allPermissions: data };
    case "RESET":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
