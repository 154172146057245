import { setLoading } from "redux/actions/app.actions";
import { request } from "./verb.services";
import { openNotificationWithIcon } from "utils/Notification";
import moment from "moment";

export const getRosters = (filter = {}, showLoading = true) => {
  return (dispatch) => {
    if (showLoading) dispatch(setLoading(true));
    return request("Filtered", "post", filter, true, null)
      .then((res) => {
        let rosters = res?.data ?? [];
        rosters = rosters.map((roster) => ({
          ...roster,
          isBackendFetched: true,
          rosterDate: moment(roster?.rosterDate).format("YYYY-MM-DD"),
        }));
        return {
          status: "success",
          data: rosters,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        if (showLoading) dispatch(setLoading(false));
      });
  };
};

export const createRoster = (data, showLoading = true) => {
  return (dispatch) => {
    if (showLoading) dispatch(setLoading(true));
    return request("StaffRosters", "post", data, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Roster entries created successfully"
        );
        return {
          status: "success",
          data: res?.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        if (showLoading) dispatch(setLoading(false));
      });
  };
};

export const getCalendarRosters = (
  facilityId,
  shiftId,
  fromDate,
  toDate,
  showLoading = true
) => {
  return (dispatch) => {
    if (showLoading) dispatch(setLoading(true));
    return request(
      `Facilities/${facilityId}/Shifts/${shiftId}/CalendarRoster?fromDate=${fromDate}&toDate=${toDate}`,
      "get",
      null,
      true,
      null
    )
      .then((res) => {
        let rosters = res?.data ?? [];
        rosters = rosters.map((roster) => ({
          ...roster,
          isBackendFetched: true,
          rosterDate: moment(roster?.rosterDate).format("YYYY-MM-DD"),
        }));
        return {
          status: "success",
          data: rosters,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        if (showLoading) dispatch(setLoading(false));
      });
  };
};

export const updateRosterSequence = (data, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request("Sequence", "patch", data, true, null)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Roster updated successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const rejectStaffRoster = (staffId, rosterId, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Staffs/${staffId}/Rosters/${rosterId}/Reject`,
      "patch",
      null,
      true,
      null
    )
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Roster entry rejection toggled successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
