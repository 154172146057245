import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocationsByStreetAddress } from "services/locationCenter.services";
import "./SearchLocationInput.style.scss";
import { getItem, setItem, removeItem } from "utils/storage";

import { Input, Tooltip } from "antd";
import Spinner from "components/Spinner/Spinner";
import { debounce } from "utils/debounce";

let autoComplete;

function SearchLocationInput({
  guests,
  name,
  form,
  className = "search-location-input",
  selectOnChange,
  loading,
  ...props
}) {
  const { value, onChange } = props ?? {};

  const autoCompleteRef = useRef(null);
  const [tooltip, setTooltip] = useState("");

  useEffect(() => {
    handleScriptLoad(autoCompleteRef);
  }, []);

  function handleScriptLoad(autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current
    );
    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", handlePlaceSelect);
  }

  // Manually Replicate all other functionality
  async function handlePlaceSelect() {
    const place = autoComplete.getPlace();
    const address = place?.formatted_address;
    form?.setFieldsValue({
      [name]: address || "",
    });
    setTooltip(address);
    selectOnChange?.({
      target: {
        value: address || "",
      },
    });
  }
  useEffect(() => {
    setTooltip(value);
  }, [value]);


  return (
    <div className={`locationDropdown ${className}`}>
      <Tooltip placement="topLeft" title={tooltip}>
        <input
          ref={autoCompleteRef}
          placeholder="Enter Address"
          style={{ maxWidth: 500 }}
          className={"locationInput"}
          {...props}

        />
      </Tooltip>
      {loading && (
        <div className="suffixIcon">
          <Spinner />
        </div>
      )}
    </div>
  );
}

export default SearchLocationInput;
