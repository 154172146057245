import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as DayViewIcon } from "assets/icons/DayViewIco.svg";
import { ReactComponent as WeekViewIcon } from "assets/icons/WeekViewIco.svg";

function ViewSelector({ view, onView, views = ["day", "agenda"] }) {
  return (
    <div className="viewSelector">
      {views.includes("day") && (
        <button
          onClick={() => onView("day")}
          className={`views ${view === "day" ? "active" : ""} `}
        >
          <DayViewIcon />
        </button>
      )}
      {views.includes("agenda") && (
        <button
          onClick={() => onView("agenda")}
          className={`views ${view === "agenda" ? "active" : ""} `}
        >
          <WeekViewIcon />
        </button>
      )}
    </div>
  );
}

ViewSelector.propTypes = {};

export default ViewSelector;
