import React, { useEffect, useLayoutEffect, useState } from "react";

import "./App.scss";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Menu, Spin, Space, Avatar, Typography, Grid } from "antd";
import SiderMenu from "../../components/Menu/Menu";
import { push, replace } from "connected-react-router";
import { clearStorage } from "../../utils/storage";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
// import { getAppConfigs } from '../../services/app.services';
import moment from "moment";

import { ReactComponent as ChevronDown } from "assets/icons/chevronDownIco.svg";
import NewBookingButton from "components/NewBookingButton/NewBookingButton";
import { getAllRoles } from "services/roles.services";

import {
  getClinicFacilities,
  getFacilities,
  getMobileFacilities,
} from "services/facilities.services";
import ChangePasswordModal from "components/ChangePasswordModal/ChangePasswordModal";
import { Button } from "components/Buttons/Buttons";
import AppointmentDrawer from "containers/ResourceScheduler/components/AppointmentDrawer";
import GuestDrawer from "containers/ResourceScheduler/components/GuestDrawer";
import {
  setAppointmentDrawer,
  setProfileDrawer,
} from "redux/actions/drawer.actions";

const { Text, Title } = Typography;

const { Header, Content, Footer } = Layout;
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const { useBreakpoint } = Grid;

const AppSider = ({ collapsed, toggleCollapse, onMenuSelect, user }) => {
  return (
    <SiderMenu
      collapsed={collapsed}
      toggleCollapse={toggleCollapse}
      onMenuSelect={onMenuSelect}
      user={user}
    />
  );
};

const AppHeader = ({ collapsed, toggle, logout, props, user: currentUser }) => {
  const screens = useBreakpoint();
  const dispatch = useDispatch();

  const { profileDrawer, appointmentDrawer } = useSelector(
    (state) => state.drawers
  );

  useEffect(() => {
    dispatch(getFacilities());
  }, []);

  const [showPasswordEdit, setShowPasswordEdit] = useState(false);
  return (
    <Header
      style={{
        background: "#fff",
        padding: "0px 16px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: 79,
      }}
    >
      <Space size="large">
        <span onClick={toggle} style={{ cursor: "pointer", paddingLeft: 10 }}>
          {collapsed ? (
            <MenuUnfoldOutlined style={{ fontSize: 18 }} />
          ) : (
            <MenuFoldOutlined style={{ fontSize: 18 }} />
          )}
        </span>
        {/* <MainSearchbar /> */}
      </Space>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Space>
          {screens.md && (
            <Button
              rounded
              style={{
                height: 50,
                marginRight: 12,
              }}
              className="appBookAppointmentButton"
              onClick={() => {
                dispatch(
                  setAppointmentDrawer({
                    show: true,
                    data: null,
                  })
                );
              }}
            >
              Book an Appointment
            </Button>
          )}
          {currentUser?.roleName !== "Nurse" && <NewBookingButton />}
          {screens.md && (
            <>
              <Space size={12}>
                <Avatar
                  src={currentUser?.profileImageUrl}
                  size={50}
                  style={{ backgroundColor: "#88ebc2" }}
                >
                  {currentUser?.firstName?.[0]} {currentUser?.lastName?.[0]}
                </Avatar>

                <div style={{ lineHeight: 1 }}>
                  <Title level={4} style={{ margin: 0 }}>
                    {currentUser?.firstName}
                  </Title>
                  {currentUser?.roleName && (
                    <Text style={{ margin: 0 }}>{currentUser?.roleName}</Text>
                  )}
                </div>
              </Space>

              <Menu mode="horizontal">
                <SubMenu
                  style={{ padding: `0px ${screens.md ? 20 : 8}px` }}
                  icon={<ChevronDown style={{ paddingLeft: 8 }} />}
                >
                  <Menu.Item key="setting:1">
                    <p
                      onClick={() => {
                        setShowPasswordEdit(true);
                      }}
                    >
                      Change Password
                    </p>
                  </Menu.Item>
                  <Menu.Item key="setting:2">
                    <p onClick={logout}>Logout</p>
                  </Menu.Item>
                </SubMenu>
              </Menu>
            </>
          )}
        </Space>
      </div>
      <ChangePasswordModal
        visible={showPasswordEdit}
        handleSave={() => setShowPasswordEdit(false)}
        onCancel={() => setShowPasswordEdit(false)}
      />
      <AppointmentDrawer
        addGuestClick={() => {
          dispatch(
            setAppointmentDrawer({
              show: false,
            })
          );
          dispatch(
            setProfileDrawer({
              show: true,
              data: null,
              showAppointmentDrawerOnClose: true,
            })
          );
        }}
        data={appointmentDrawer.data}
        visible={appointmentDrawer.show}
        onClose={() => {
          dispatch(
            setAppointmentDrawer({
              show: false,
              data: null,
            })
          );
        }}
      />
      <GuestDrawer
        showAppointmentDrawerOnClose={
          profileDrawer.showAppointmentDrawerOnClose
        }
        visible={profileDrawer.show}
        onClose={() => {
          dispatch(setProfileDrawer({ show: false, data: null }));
        }}
      />
    </Header>
  );
};

const AppFooter = () => {
  return (
    <Footer
      style={{
        textAlign: "center",
        fontFamily: "Arimo",
        fontSize: "16px",
        padding: "8px 50px",
      }}
    >
      <p style={{ margin: 0 }}>
        Business hours: <b>Monday</b> till <b>Sunday</b>{" "}
        {moment("2022-03-09 13:00:00.000Z")
          .tz("america/chicago")
          .add(1, "hour")
          .format("h:mm a")}{" "}
        (CT) till{" "}
        {moment("2022-03-09 03:00:00.000Z")
          .tz("america/chicago")
          .add(1, "hour")
          .format("h:mm a")}{" "}
        (CT){" "}
      </p>
    </Footer>
  );
};

const App = (props) => {
  const screens = useBreakpoint();
  const [collapsed, setCollapsed] = useState(false);
  const user = useSelector((state) => state.user.currentUser);
  const { loading, message } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      dispatch(getClinicFacilities());
      dispatch(getMobileFacilities());
    }
  }, [user]);

  useLayoutEffect(() => {
    if (!screens.md) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [screens.md]);

  useEffect(() => {
    dispatch(getAllRoles());
  }, []);

  const onMenuSelect = (key) => {
    dispatch(push(key));
  };
  const toggle = () => {
    setCollapsed(!collapsed);
  };
  const logout = () => {
    clearStorage();
    dispatch({ type: "RESET" });
    dispatch(replace("/"));
  };

  return (
    <div className="reactRoot">
      <div
        style={{
          backgroundColor: "#00000057",
          height: "100vh",
          position: "absolute",
          width: "100%",
          zIndex: 10000000,
          display: loading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Spin spinning={loading} size="large" style={{ color: "#D4008E" }} />
        <h1 style={{ color: "#D4008E", marginLeft: 8, marginBottom: 2 }}>
          {message}
        </h1>
      </div>
      <Layout style={{ height: "100vh" }}>
        <AppSider
          collapsed={collapsed}
          toggleCollapse={toggle}
          onMenuSelect={onMenuSelect}
          user={user}
        />
        <Layout style={{ background: "#e5e8ee" }}>
          <AppHeader
            collapsed={collapsed}
            toggle={toggle}
            logout={logout}
            props={props}
            user={user}
            dispatch={dispatch}
          />
          <Content
            id="content-container"
            style={{
              margin: "16px 16px 0px 16px",
              background: "#fff",
              minHeight: 280,
              overflow: "hidden",
            }}
          >
            {props.children}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default App;
