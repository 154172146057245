import { cloneDeep } from "lodash";
import moment from "moment";
import { setLoading } from "redux/actions/app.actions";
import { openNotificationWithIcon } from "utils/Notification";
import { request } from "./verb.services";

export const getAllGoodFaithPolicies = (callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request("GoodFaithPolicies", "get", null, true, null)
      .then((res) => {
        return {
          status: "success",
          data: res.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getSingleGoodFaithPolicy = (policyId) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`GoodFaithPolicies/${policyId}`, "get", null, true, null)
      .then((res) => {
        return {
          status: "success",
          data: res.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const addNewGoodFaithPolicy = (values, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`GoodFaithPolicies`, "post", values, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Good Faith Policy added successfully"
        );
        return { status: "success", data: res?.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const updateGoodFaithPolicy = (referralPolicyId, data, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`GoodFaithPolicies/${referralPolicyId}`, "put", data, true)
      .then(() => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Good Faith Policy updated successfully"
        );
        callback?.();
        return {
          status: "success",
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const toogleGoodFaithPolicy = (id, currentActiveStatus, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`GoodFaithPolicies/${id}`, "patch", null, true)
      .then((res) => {
        let message = "";
        if (currentActiveStatus) {
          message = "Good Faith Policy deactivated successfully";
        } else {
          message = "Good Faith Policy activated successfully";
        }
        openNotificationWithIcon("success", "Success", message);
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getAllCancellationPolicies = (callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request("CancellationPolicies", "get", null, true, null)
      .then((res) => {
        return {
          status: "success",
          data: res.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getSingleCancellationPolicy = (facilityId) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `CancellationPolicies/facilities/${facilityId}`,
      "get",
      null,
      true,
      null
    )
      .then((res) => {
        return {
          status: "success",
          data: res.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const addNewCancellationPolicy = (values, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`CancellationPolicies`, "post", values, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Cancellation Policy added successfully"
        );
        return { status: "success", data: res?.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const updateCancellationPolicy = (facilityId, data, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `CancellationPolicies/facilities/${facilityId}`,
      "put",
      data,
      true
    )
      .then(() => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Cancellation Policy updated successfully"
        );
        callback?.();
        return {
          status: "success",
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getConfigByKey = (key, showLoading = true) => {
  return (dispatch) => {
    if (showLoading) dispatch(setLoading(true));
    return request(`Configurations/${key}`, "get", null, true, null)
      .then((res) => {
        return {
          status: "success",
          data: res.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        if (showLoading) dispatch(setLoading(false));
      });
  };
};

export const updateConfigByKey = (key, value) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Configurations/${key}`,
      "patch",
      `"${value}"`,
      {
        contentType: "application/json",
      },
      null
    )
      .then((res) => {
        return {
          status: "success",
          data: res.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
