import { Switch } from "antd";
import { replace } from "connected-react-router";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/actions/app.actions";
import { updateAppConfig } from "../../services/app.services";
import "./Dashboard.scss";

const Dashboard = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.currentUser)
  useEffect(() => {
    // fix an issue in some mobiles
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    const roleName = user?.roleName?.trim?.() || ""
    if (user) {
      switch (roleName) {
        case 'Nurse':
          dispatch(replace('/dashboard/appointments'))
          break;
        case 'Nurse Practitioner':
          dispatch(replace('/dashboard/appointments'))
          break;
        case 'Admin':
          dispatch(replace('/dashboard/calendar'))
          break;
        case 'Nurse Lead':
          dispatch(replace('/dashboard/appointments'))
          break;
        case 'Physician':
          dispatch(replace('/dashboard/charts'))
          break;
      }
    }
  }, [user])

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        fontSize: 32,
        fontFamily: "tradeGothic",
      }}
    >
      {/* <p>Dashboard</p> */}
    </div>
  );
};

export default Dashboard;
