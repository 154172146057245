import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, List, Space, Typography } from "antd";

import "./CancellationPolicy.style.scss";
import CancellationPolicyCard from "./components/CancellationPolicyCard";
import CancellationPolicyModal from "./components/CancellationPolicyModal";
import TermsAndConditionsModal from "./components/TermsAndConditionsModal";

const { Text } = Typography;

function CancellationPolicy(props) {
  const { className, cancellationPolicies, setRefreshPolicies } = props;

  const [showAddEditModal, setShowAddEditModal] = useState();
  const [
    cancellationTermsAndConditiontModal,
    setCancellationTermsAndConditiontModal,
  ] = useState();

  return (
    <div className={`cancellationPolicy ${className ? className : ""}`}>
      <div className='cancellationPolicyHead'>
        <Text>Locations</Text>
        <Button
          type='text'
          style={{ padding: 0, height: "auto" }}
          onClick={() => setCancellationTermsAndConditiontModal(true)}
        >
          Terms and Conditions
        </Button>
        <Button
          type='text'
          style={{ padding: 0, height: "auto" }}
          onClick={() => setShowAddEditModal(true)}
        >
          Add New Cancellation Policy
        </Button>
      </div>

      <List
        locale={{ emptyText: "No Policies" }}
        className='cancellationPolicyList'
        itemLayout='vertical'
        size='large'
        dataSource={cancellationPolicies.data}
        renderItem={(policy, index) => (
          <List.Item key={index}>
            <CancellationPolicyCard
              key={policy.id}
              data={policy}
              setRefreshPolicies={setRefreshPolicies}
            />
          </List.Item>
        )}
      />
      {showAddEditModal && (
        <CancellationPolicyModal
          visible={showAddEditModal}
          onCancel={() => setShowAddEditModal(false)}
          setRefreshPolicies={setRefreshPolicies}
        />
      )}
      {cancellationTermsAndConditiontModal && (
        <TermsAndConditionsModal
          visible={cancellationTermsAndConditiontModal}
          onCancel={() => setCancellationTermsAndConditiontModal(false)}
          setRefreshPolicies={setRefreshPolicies}
        />
      )}
    </div>
  );
}

CancellationPolicy.propTypes = {};

export default CancellationPolicy;
