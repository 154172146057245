import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import moment from "moment";
import {
  FormInput,
  FormInputArea,
  FormButton,
  FormPassword,
  FormSelect,
  FormPhoneNumber,
  FormDatePicker,
} from "components/FormItems/FlatFormItems";
import {
  Space,
  Form,
  Row,
  Col,
  Typography,
  Button as AntdButton,
  Avatar,
  Empty,
} from "antd";
import { Button } from "components/Buttons/Buttons";
import Drawer from "components/Drawer/Drawer";
import "./NewBookingsDrawer.scss";
import NewBookingCard from "./components/NewBookingCard";
import EventDrawer from "components/EventDrawer/EventDrawer";
import { setCurrentBooking } from "redux/actions/booking.actions";

const { Title, Text } = Typography;
// import NewBookingCard from "./components/NewBookingCard"

export default function NewBookingsDrawer({
  facilities,
  staff,
  groupedBookings,
  visible,
  onClose,
}) {
  const dispatch = useDispatch();
  const [showEventDrawer, setShowEventDrawer] = useState(false);

  return (
    <Drawer
      className="newBookingDrawer"
      // width={720}
      visible={visible}
      onClose={onClose}
      text="New Bookings"
    >
      <Row gutter={[0, 0]}>
        <Col span={24}>
          <Row gutter={[16, 16]}>
            {groupedBookings?.length === 0 && (
              <Empty style={{ width: "100%" }} description="No New Bookings" />
            )}

            {groupedBookings.map(([group, bookings], index) => {
              return (
                <React.Fragment key={group}>
                  <Col span={24}>
                    <Title
                      level={4}
                      style={{
                        color: "#000000",
                        margin: 0,
                      }}
                    >
                      {group}
                    </Title>
                  </Col>
                  {bookings.map((booking) => {
                    return (
                      <Col span={24} key={booking.orderId}>
                        <NewBookingCard
                          key={booking.orderId}
                          facilities={facilities}
                          booking={booking}
                          staff={staff}
                          appointmentDetailClick={async () => {
                            await dispatch(setCurrentBooking(booking));
                            setShowEventDrawer(true);
                          }}
                        />
                      </Col>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </Row>
        </Col>
      </Row>
      <EventDrawer
        visible={showEventDrawer}
        setShowEventDrawer={setShowEventDrawer}
        onClose={() => setShowEventDrawer(false)}
      />
    </Drawer>
  );
}
