import React, { useEffect, useRef } from "react";
import { Form, Row, Col, Button as AntdButton, Typography } from "antd";
import { Button } from "components/Buttons/Buttons";
import Modal from "components/Modal/Modal";
import { useForm } from "antd/lib/form/Form";
import {
  FormHiddenSubmitButton,
  FormInput,
  FormInputArea,
} from "components/FormItems/FlatFormItems";
import { useDispatch } from "react-redux";
import { updateConfigByKey, getConfigByKey } from "services/misc.service";

const GoodFaithExamPolicyKey = "GoodFaithExamPolicy";

function GoodFaithTermsModal({ visible, onCancel }) {
  const dispatch = useDispatch();
  const [form] = useForm();
  const submitBtnRef = useRef();

  useEffect(() => {
    if (visible) loadTermsAndConditions(GoodFaithExamPolicyKey);
  }, [visible]);

  const loadTermsAndConditions = async (ckey = "") => {
    const result = await dispatch(getConfigByKey(ckey));
    if (result?.status === "success") {
      form.setFieldsValue({
        GoodFaithExamPolicyKey: result.data.value,
      });
    }
  };

  return (
    <Modal
      //   closable={closable}
      destroyOnClose={true}
      //   headerBorder={false}
      width="720px"
      className="cancellationPolicyModal"
      title={`Good Faith Exam Policy`}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      visible={visible}
      onOk={() => console.log("ok")}
      okText="Add"
      centered={true}
      footer={
        <div className="d-flex justify-between align-center">
          <AntdButton
            type="text"
            onClick={() => {
              onCancel();
              form.resetFields();
            }}
          >
            Cancel
          </AntdButton>
          <Button
            style={{ height: 50 }}
            rounded={true}
            //   disabled={!Boolean(setupIntent)}
            onClick={() => submitBtnRef?.current?.click()}
          >
            Save
          </Button>
        </div>
      }
      //   cancelText="No"
    >
      <Form
        form={form}
        initialValues={{}}
        layout="vertical"
        requiredMark={false}
        onFinish={(values) => {
          // add cancellation policy
          dispatch(
            updateConfigByKey(
              GoodFaithExamPolicyKey,
              values.GoodFaithExamPolicyKey
            )
          ).then((result) => {
            if (result?.status === "success") {
              form.resetFields();
              onCancel();
            }
          });
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <FormInputArea
              label="Good Faith Exam Policy"
              required={true}
              name={"GoodFaithExamPolicyKey"}
              variant="underlined"
              formItemStyles={{ width: "100%" }}
              // initialValue={notes[0] ? notes[0].noteDescription : ''}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <FormHiddenSubmitButton submitRef={submitBtnRef} />
        </Row>
      </Form>
    </Modal>
  );
}

export default GoodFaithTermsModal;
