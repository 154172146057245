import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import user from "./user.reducer";
import locationCenter from "./locationCenter.reducer";
import bookings from "./bookings.reducer";
import facilities from "./facilities.reducer";
import location from "./location.reducer";
import app from "./app.reducer";
import roles from "./roles.reducer";
import orders from "./order.reducer";
import scheduler from "./scheduler.reducer";
import intakeForms from "./intakeForms.reducer";
import charts from "./charts.reducer";
import customerInfo from "./customerInfo.reducer";
import skills from "./skills.reducer";
import calendar from "./calendar.reducer";
import drawers from "./drawers.reducer";

// Root Reducer.
const reducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    locationCenter,
    bookings,
    facilities,
    location,
    app,
    orders,
    roles,
    scheduler,
    intakeForms,
    charts,
    customerInfo,
    skills,
    calendar,
    drawers,
  });

export default reducer;
