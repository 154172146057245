import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Calendar as BigCalendar, Views } from "react-big-calendar";
import { ReactComponent as EventIcon } from "assets/icons/eventIco.svg";
import { momentLocalizer } from "react-big-calendar";
import moment from "moment";

import Tag from "components/Tag/Tag";
import ArrowButton from "components/ArrowButton/ArrowButton";

import {
  Space,
  Avatar,
  Select,
  Typography,
  Dropdown,
  DatePicker,
  Tooltip,
  Button,
  Divider,
  Popover,
} from "antd";
import {
  addonsTextReducer,
  checkIsClinicBooking,
  checkIsMobileBooking,
  checkIsTeleHealthBooking,
  convertMinsToHrsMins,
} from "utils/common";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentBooking } from "redux/actions/booking.actions";
import {
  CarOutlined,
  CloseCircleOutlined,
  CloudOutlined,
  CustomerServiceOutlined,
  ExclamationCircleOutlined,
  ShopOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { ORDER_STATUS_MAPPING } from "utils/enums";
import { findWindows } from "windows-iana";
const { Option } = Select;
const { Text, Title } = Typography;

const EventDropdown = ({ event, appointmentDetailClick, ...params }) => {
  const dispatch = useDispatch();
  return (
    <div
      style={{
        padding: 20,
        backgroundColor: "#fff",
        minWidth: 360,
        maxWidth: 380,
        width: "100%",
        border: "2px solid #CDD5D8",
        boxShadow: "0px 15px 35px rgba(168, 190, 177, 0.4)",
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "2px solid #EFF1F4",
          paddingBottom: 16,
        }}
      >
        <div style={{ display: "flex", alignItems: "center", maxWidth: 196 }}>
          <Avatar
            icon={<UserOutlined />}
            size="large"
            style={{
              // backgroundColor: "#88ebc2",
              marginRight: 8,
              minWidth: 40,
            }}
          ></Avatar>

          <Title
            level={4}
            style={{
              marginBottom: 0,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {event?.customerFullName}
          </Title>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {event?.isFirstVisit && <Tag> First Visit </Tag>}

          <ArrowButton
            onClick={async () => {
              await dispatch(setCurrentBooking(event));
              appointmentDetailClick();
            }}
          />
        </div>
      </div>
      <div
        style={{
          padding: "12px 0px",
        }}
      >
        <Title
          level={5}
          style={{
            fontSize: 14,
          }}
        >
          {params?.label}
        </Title>

        {event?.lineItems?.map((lineItem, index) => {
          let { productName, addOns, durationHours } = lineItem;
          return (
            <div style={{ marginBlock: 12, marginBottom: 0 }}>
              <Title
                level={4}
                style={{
                  fontSize: 16,
                  color: "#486BAF",
                  marginBottom: 2,
                }}
              >
                Service {index + 1}
              </Title>
              <Text>
                {productName}
                {durationHours !== undefined &&
                  durationHours > 0 &&
                  ` ${convertMinsToHrsMins(Number(durationHours) * 60)}`}
                {addOns?.length > 0 &&
                  ` with ${addOns?.reduce(addonsTextReducer, "")} `}
              </Text>
            </div>
          );
        })}
        {event?.notes?.length > 0 && event?.notes?.[0] && (
          <div style={{ marginBlock: 12 }}>
            <Title
              level={4}
              style={{
                fontSize: 16,
                color: "#486BAF",
                marginBottom: 2,
              }}
            >
              Address Instructions
            </Title>
            <Text>{event?.notes?.[0]}</Text>
          </div>
        )}
      </div>
    </div>
  );
};

export const ResourceEvent = ({ appointmentDetailClick, ...params }) => {
  const { event } = params;

  const dispatch = useDispatch();

  const { productName, addOns, durationHours } = useMemo(() => {
    const productName = event?.lineItems?.[0]?.productName;
    const addOns = event?.lineItems?.[0]?.addOns;
    const durationHours = event?.lineItems?.[0]?.durationHours;
    return { productName, addOns, durationHours };
  }, [event]);

  const isMobileBooking = checkIsMobileBooking(event);
  const isClinicBooking = checkIsClinicBooking(event);
  const isTeleHealthBooking = checkIsTeleHealthBooking(event);

  let bgColor = "";
  let tooltip = "";
  if (
    moment().isAfter(
      moment(event.serviceDate).add(15, "minutes")
    )
  ) {
    bgColor = "bg-red";
    tooltip = "Nurse has not yet arrived.";
  } else if (ORDER_STATUS_MAPPING[event?.orderStatus]?.text === "Arrived") {
    bgColor = "bg-green";
    tooltip = "Nurse has arrived.";
  } else if (ORDER_STATUS_MAPPING[event?.orderStatus]?.text === "No Show") {
    bgColor = "bg-orange";
    tooltip = "Nurse has marked 'No Show'.";
  } else if (ORDER_STATUS_MAPPING[event?.orderStatus]?.text === "Cancelled") {
    bgColor = "bg-orange";
    tooltip = "Appointment Cancelled.";
  } else if (ORDER_STATUS_MAPPING[event?.orderStatus]?.text === "Completed") {
    bgColor = "bg-lime";
    tooltip = "Completed.";
  } else if (ORDER_STATUS_MAPPING[event?.orderStatus]?.text !== "Arrived") {
    bgColor = "bg-blue";
    tooltip = "Pending booking.";
  }
  //TODO: group booking
  // calc(100% - 0px)

  let widthPercentage = useMemo(() => {
    let pattern = /calc\((\d*\.?\d*)% - \d*px\)/;
    let widthMatch = pattern.exec(params?.style?.width);
    let percentage = 100;
    if (widthMatch) {
      percentage = Number(widthMatch[1]);
    }

    return percentage;
  }, [params?.style]);

  console.log("params?.style?.height", params?.style?.height);
  return (
    <div id={`event.${params?.event?.orderId}`} key={event?.orderId}>
      <Dropdown
        trigger="click"
        overlay={
          <EventDropdown
            event={event}
            appointmentDetailClick={appointmentDetailClick}
            {...params}
          />
        }
        placement="bottomLeft"
        getPopupContainer={() =>
          document.getElementById(`event.${params?.event?.id}`)
        }
      >
        {/* <Tooltip
            title={tooltip}
            getPopupContainer={() =>
              document.getElementById(`event.${params?.event?.id}`)
            }
          > */}
        <div
          style={{
            top: `${
              params?.style?.top !== undefined ? params?.style?.top : 3000
            }%`,
            height: `${params?.style?.height}`,
            width: `calc(${widthPercentage}%)`,
            left: `${params?.style?.left}%`,
            padding: "14px 16px 14px 20px",
            flexWrap: "nowrap",
            border: "1px solid #fff",
          }}
          className={`rbc-event ${bgColor} ${event?.orderStatus}`}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 2,
            }}
          >
            <Text
              style={{
                color: "#fff",
                fontWeight: 700,
                fontFamily: "tradeGothic",
                fontSize: Math.max(14 * (widthPercentage / 100), 11),
                lineHeight: 1,
              }}
            >
              {params.label}
            </Text>
            <Space align="center">
              {/* source 1 for adming, 2 for guest */}
              {event?.source === 2 && (
                <CloudOutlined
                  style={{
                    color: "#fff",
                    display: "inline-flex",
                    fontSize: Math.max(18 * (widthPercentage / 100), 13),
                  }}
                />
              )}
              {isMobileBooking && (
                <CarOutlined
                  style={{
                    color: "#fff",
                    display: "inline-flex",
                    fontSize: Math.max(18 * (widthPercentage / 100), 13),
                  }}
                />
              )}
              {isClinicBooking && (
                <ShopOutlined
                  style={{
                    color: "#fff",
                    display: "inline-flex",
                    fontSize: Math.max(17 * (widthPercentage / 100), 12),
                  }}
                />
              )}
              {isTeleHealthBooking && (
                <VideoCameraOutlined
                  style={{
                    color: "#fff",
                    fontSize: Math.max(19 * (widthPercentage / 100), 14),
                  }}
                />
              )}
              {event?.orderStatus === 2 && (
                <CloseCircleOutlined
                  style={{
                    color: "#fff",
                    fontSize: Math.max(17 * (widthPercentage / 100), 12),
                  }}
                />
              )}
              {event?.hasContraindication && (
                <ExclamationCircleOutlined
                  style={{
                    color: "#fff",
                    fontSize: Math.max(17 * (widthPercentage / 100), 12),
                  }}
                />
              )}
            </Space>
          </div>
          <div
            style={{
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              width: "100%",
            }}
          >
            <Title
              level={4}
              style={{
                color: "#fff ",
                fontSize: Math.max(18 * (widthPercentage / 100), 14),
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {event?.customerFullName}
            </Title>
            <Text
              style={{
                color: "#fff",
                fontFamily: "tradeGothic",
                fontSize: Math.max(14 * (widthPercentage / 100), 11),
                maxHeight: 136,
                maxWidth: "100%",
                overflow: "hidden",
              }}
            >
              {productName}
              {durationHours !== undefined &&
                durationHours > 0 &&
                ` ${convertMinsToHrsMins(Number(durationHours) * 60)}`}
              {addOns?.length > 0 &&
                ` with ${addOns?.reduce(addonsTextReducer, "")} `}
            </Text>
          </div>
        </div>
        {/* </Tooltip> */}
      </Dropdown>
    </div>
  );
};

export const TimeSlot = ({
  bookAnAppointmentClick,
  facilityId,
  nurse,
  ...params
}) => {
  const { wholeStaff } = useSelector((state) => {
    return {
      wholeStaff: state.user.wholeStaff,
    };
  });

  if (!params.resource) {
    const date = moment(params.value);
    return (
      <div style={{ padding: "0px 16px 20px 20px" }}>
        <div>{date.format("h:mm")}</div>
        <div>{date.format("A")}</div>
      </div>
    );
  } else {
    const time = moment(params.value);
    let bgColor = "#fff";
    for (let i = 0; i < nurse?.facilityShifts?.length; i++) {
      const shift = nurse?.facilityShifts?.[i];
      const shiftTimeStart = moment(
        `${time.format("YYYY-DD-MM")} ${shift.startTime}`,
        "YYYY-DD-MM hh:mm"
      );
      const shiftTimeEnd = moment(
        `${time.format("YYYY-DD-MM")} ${shift.endTime}`,
        "YYYY-DD-MM hh:mm"
      );

      if (time >= shiftTimeStart && time <= shiftTimeEnd) {
        bgColor = "#c7f2e0";
      }
    }
    // if (time >= moment("9:00 AM", "HH:mm A") && time <= moment("5:00 PM",  "HH:mm A")) {
    //   bgColor = "#88ebc2";
    // }

    return (
      <div
        style={{ flex: 1, backgroundColor: bgColor }}
        onDoubleClick={(e) => {
          const staff = wholeStaff.find(
            (staff) => staff.staffId === params.resource
          );
          bookAnAppointmentClick(e, {
            date: moment(params.value),
            staffId: params.resource,
            facilityId,
          });
        }}
      >
        {params.children}
      </div>
    );
  }
};

export const ResourceHeader = ({ ...params }) => {
  return (
    <div
      style={{
        padding: "10px 20px",
        display: "flex",
        alignItems: "center",
        borderBottom: "none",
      }}
    >
      <Space size={16}>
        <Avatar
          src={params?.resource?.profileImageUrl}
          size="large"
          style={{ backgroundColor: "#88ebc2" }}
        >
          {params?.resource?.firstName?.[0]} {params?.resource?.lastName?.[0]}
        </Avatar>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div style={{ fontWeight: 600 }}>{params?.resource?.name}</div>
          {/* <div style={{ fontWeight: 400 }}>9:00-1:00 AM</div> */}
        </div>
      </Space>
    </div>
  );
};

export const Agenda = ({ appointmentDetailClick, ...params }) => {
  const { event } = params;
  const dispatch = useDispatch();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "5px 5px",
      }}
    >
      <div>
        {event?.orderStatus === 2 && <Tag className="dangerTag">Cancelled</Tag>}
        {event?.customerFullName}'s booking for{" "}
        {event?.lineItems?.[0]?.productName}
      </div>
      <Button
        type="text"
        style={{ height: "auto", padding: 0 }}
        onClick={async () => {
          await dispatch(setCurrentBooking(event));
          appointmentDetailClick();
        }}
      >
        View Details
      </Button>
    </div>
  );
};

export const AgendaTime = (params) => {
  const { label, event } = params;
  const windowsTimezone = findWindows(moment.tz.guess());

  return (
    <div>
      {label} <br />{" "}
      {/* <span className="firstLetterUppercase"> {windowsTimezone}</span> */}
    </div>
  );
};
