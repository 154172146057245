import Modal from "components/Modal/Modal";
import React, { useEffect, useRef, useState } from "react";
import { Button as AntdButton, Col, Form, Row, Typography } from "antd";
import { Button } from "components/Buttons/Buttons";
import { useDispatch } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import {
  FormHiddenSubmitButton,
  FormSwitch,
} from "components/FormItems/FlatFormItems";
import { getConfigByKey, updateConfigByKey } from "services/misc.service";
import { openNotificationWithIcon } from "utils/Notification";
import Switch from "components/Switch/Switch.index";
import Spinner from "components/Spinner/Spinner";

const { Text } = Typography;

export default function ConfigAutoSequencingModal({
  visible,
  onCancel,
  onSave,
}) {
  const dispatch = useDispatch();
  const [form] = useForm();
  const submitBtnRef = useRef();

  useEffect(() => {
    // dispatch(getConfigByKey("autoSequencingKey")).then((res) => {
    //   if (res?.status === "success") {
    //     const data = JSON.parse(res?.data ?? "{}");
    //     form.setFieldsValue({
    //       ...data,
    //     });
    //   }
    // });
  }, []);

  return (
    <Modal
      className="ant-modal-title-tradeGothic"
      //   closable={closable}
      destroyOnClose={true}
      //   headerBorder={false}
      width="520px"
      title="Auto Scheduling"
      onCancel={() => onCancel()}
      visible={visible}
      onOk={() => console.log("ok")}
      okText="Add"
      centered={true}
      footer={
        <></>
        // <div className="d-flex justify-between align-center">
        //   <AntdButton type="text" style={{ padding: 0 }}>
        //     Cancel
        //   </AntdButton>
        //   <Button
        //     style={{ height: 50 }}
        //     rounded={true}
        //     onClick={() => {
        //       submitBtnRef?.current?.click();
        //     }}
        //     //   disabled={!Boolean(setupIntent)}
        //   >
        //     Save
        //   </Button>
        // </div>
      }
    >
      <Row gutter={[0, 0]}>
        <Col xs={24}>
          <MSwitch name="" label="Clinic" apiName="AutomaticSchedulingClinic" />
        </Col>
        <Col xs={24}>
          <MSwitch name="" label="Mobile" apiName="AutomaticSchedulingMobile" />
        </Col>
        <Col xs={24}>
          <MSwitch
            name=""
            label="Telehealth"
            apiName="AutomaticSchedulingTelehealth"
          />
        </Col>
      </Row>
      {/* <FormHiddenSubmitButton submitRef={submitBtnRef} /> */}
      {/* </Form> */}
    </Modal>
  );
}

const MSwitch = ({ label, apiName }) => {
  const [checked, setChecked] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getConfigByKey(apiName, false)).then((res) => {
      if (res.status === "success") {
        console.log("res.data", res.data);
        setChecked(res?.data?.value === "true" ?? false);
        setIsFetched(true);
      }
    });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: 150,
        marginBottom: 16,
      }}
    >
      <Text>{label}:</Text>
      {isFetched ? (
        <Switch
          checkedChildren="ON"
          unCheckedChildren="OFF"
          onChange={(checked) => {
            setChecked(checked);
            dispatch(updateConfigByKey(apiName, checked))
              .then((res) => {
                if (res.status === "success") {
                  openNotificationWithIcon(
                    "success",
                    "Auto Scheduling",
                    "Auto Scheduling updated successfully"
                  );
                } else {
                  setChecked(!checked);
                }
              })
              .catch(() => {
                setChecked(!checked);
              });
          }}
          checked={!!checked}
        />
      ) : (
        <Spinner />
      )}
    </div>
  );
};
