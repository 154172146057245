import React, { useState } from "react";
import PropTypes from "prop-types";
import { FilterOutlined } from "@ant-design/icons";
import Modal from "components/Modal/Modal";
import NurseSelector from "components/NurseSelector/NurseSelector";
import { useSelector } from "react-redux";
import { Button } from "components/Buttons/Buttons";

function CalendarFilters({showFilter, setShowFilter}) {

  const { currentStaff } = useSelector((state) => ({
    currentStaff: state.calendar.currentStaff,
  }));

  const isActive = currentStaff?.length > 0;

  return (
    <>
      <button
        className={`calenderFilterBtn ${isActive ? "active" : ""}`}
        onClick={() => {
          setShowFilter(true);
        }}
      >
        <FilterOutlined style={{ fontSize: 18 }} />
      </button>
    </>
  );
}

CalendarFilters.propTypes = {};

export default CalendarFilters;
