import { setLoading } from "redux/actions/app.actions";
import { request } from "./verb.services";
import { openNotificationWithIcon } from "utils/Notification";

export const getAllTaxCategories = (showLoading = true, callback) => {
  return (dispatch) => {
    if (showLoading) dispatch(setLoading(true));
    return request("Products/TaxCategories", "get", null, true, null)
      .then((res) => {
        callback?.(res?.data || []);
        return {
          status: "success",
          data: res?.data || [],
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        if (showLoading) dispatch(setLoading(false));
      });
  };
};
