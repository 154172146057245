import React, { useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";
import useSettingsHeader from "hooks/useSettingsHeader";
import { Button } from "components/Buttons/Buttons";

import { Dropdown, Menu, Modal, Select, Space, Table } from "antd";
import RestrictionsDrawer from "./components/RestrictionsDrawer";
import { RestrictionType } from "../../../../utils/enums";
import usePortal from "hooks/usePortal";
import SearchAndFilterColumns from "components/SearchAndFilterColumns/SearchAndFilterColumns";

import "./Restrictions.style.scss";
import { useDispatch } from "react-redux";
import {
  createRestriction,
  deleteRestriction,
  getAllRestrictions,
  updateRestrictions,
} from "services/restrictions.service";
import {
  getAllBoosts,
  getAllServices,
} from "services/servicesAndBoostsSettings.service";
import { MoreOutlined } from "@ant-design/icons";
import { get } from "lodash";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET":
      return action.data;
    case "ADD":
      return [...state, action.data];
    case "UPDATE":
      return state.map((data) => {
        if (data.categoryId === action.data.categoryId) return action.data;
        return data;
      });
    case "DELETE":
      return state.filter((data) => {
        if (data.categoryId === action.data.categoryId) return false;
        return true;
      });
    default:
      return state;
  }
};
function Restrictions(props) {
  const dispatch = useDispatch();
  const [data, localDispatch] = useReducer(reducer, []);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [activeFilter, setActiveFilter] = useState("active"); // all, active, inactive
  const [showAddEditDrawer, setShowAddEditDrawer] = useState(false);

  const [services, setServices] = useState({
    isLoading: false,
    data: [],
  });
  const [boosts, setBoosts] = useState({
    isLoading: false,
    data: [],
  });

  const loadData = async (loadAll = false) => {
    const result = await dispatch(
      getAllRestrictions((res) => {
        if (res?.status === "success")
          localDispatch({ action: "SET", data: res?.data || [] });
      })
    );
    if (result?.status === "success") {
      localDispatch({
        type: "SET",
        data: result.data,
      });
    }

    if (loadAll) {
      dispatch(getAllServices()).then((result) => {
        if (result?.status === "success") {
          result?.data?.sort((a, b) => {
            return `${a.productName}`.localeCompare(b.productName);
          });
          setServices({
            isLoading: false,
            data: result.data,
          });
        }
      });
      dispatch(getAllBoosts()).then((result) => {
        if (result?.status === "success") {
          result?.data?.sort((a, b) => {
            return `${a.productName}`.localeCompare(b.productName);
          });
          setBoosts({
            isLoading: false,
            data: result.data,
          });
        }
      });
    }
  };

  useEffect(() => {
    loadData(true);
  }, []);

  // functions start
  const handleAdd = (values) => {
    if (values.type === 1) {
      delete values.fromAge;
      delete values.toAge;
    } else if (values.type === 2) {
      delete values.fromAge;
      delete values.toAge;
    } else if (values.type === 3) {
      values.restrictedProductIds = values.productId;
      delete values.timeInterval;
      delete values.productId;
    }

    if (values.type !== 3 && !!values.timeInterval) {
      values.type = 2;
    } else if (values.type !== 3 && !values.timeInterval) {
      values.type = 1;
      delete values.timeInterval;
    }

    dispatch(createRestriction(values)).then((result) => {
      if (result?.status === "success") {
        setShowAddEditDrawer(false);
        loadData();
      }
    });
  };
  const handleUpdate = (id, values) => {
    if (values.type !== 3) {
      values.restrictedProductId =
        values.restrictedProductIds.length > 0
          ? values.restrictedProductIds[0]
          : values.restrictedProductIds;
      delete values.restrictedProductIds;
    }

    if (values.type === 1) {
      delete values.fromAge;
      delete values.toAge;
    } else if (values.type === 2) {
      delete values.fromAge;
      delete values.toAge;
    } else if (values.type === 3) {
      // values.restrictedProductIds = values.productId;
      delete values.timeInterval;
      // delete values.productId;
    }

    if (values.type !== 3 && !!values.timeInterval) {
      values.type = 2;
    } else if (values.type !== 3 && !values.timeInterval) {
      values.type = 1;
      delete values.timeInterval;
    }

    dispatch(updateRestrictions(id, values)).then((result) => {
      if (result?.status === "success") {
        setShowAddEditDrawer(false);
        loadData();
      }
    });
  };
  const handleDelete = (id) => {
    dispatch(deleteRestriction(id)).then((result) => {
      if (result?.status === "success") {
        loadData();
      }
    });
  };
  const deleteConfirmation = (id, title = "") => ({
    title: `${title} Restriction`,
    content: <>Are you sure that you want to {title} the restriction?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      handleDelete(id);
      close();
    },
  });
  const checkActiveFilter = (item) => {
    if (activeFilter === "all") return true;
    else if (activeFilter === "active" && item?.isActive) return true;
    else if (activeFilter === "inactive" && !item?.isActive) return true;
    return false;
  };

  const getRecordTypeString = (record) => {
    let rType = "";
    rType =
      record.type == 3
        ? "Age"
        : record.productType == 1
        ? "Service to Service"
        : "Add-ons to Add-ons";
    return record.timeInterval ? "Time Interval" : rType;
  };
  const getTimeIntervalString = (record) => {
    if (typeof record.timeInterval !== "number") {
      return "NA";
    } else {
      return record?.timeInterval + " hours";
    }
  };

  const getAgeString = (record) => {
    const fromAge = record?.fromAge;
    const toAge = record?.toAge;
    let text = "N/A";
    if (!!fromAge && !!toAge) text = `${fromAge}-${toAge}`;
    else if (!!fromAge) text = `>${fromAge}`;
    else if (!!toAge) text = `<${toAge}`;
    return text;
  };

  // functions end
  const columns = [
    {
      key: "type",
      title: "Type",
      render: (record) => {
        let rType = getRecordTypeString(record);
        return <span style={{ textTransform: "capitalize" }}>{rType}</span>;
      },
      sorter: (a, b) => {
        const aType = getRecordTypeString(a);
        const bType = getRecordTypeString(b);

        if (aType?.toLowerCase?.() < bType?.toLowerCase?.()) return -1;
        if (aType?.toLowerCase?.() > bType?.toLowerCase?.()) return 1;
        return 0;
      },
    },
    {
      key: "type",
      title: "Name",
      render: (record) => {
        return (
          <span style={{ textTransform: "capitalize" }}>
            {record.productName}
          </span>
        );
      },
      sorter: (a, b) => {
        if (a.productName?.toLowerCase?.() < b.productName?.toLowerCase?.())
          return -1;
        if (a.productName?.toLowerCase?.() > b.productName?.toLowerCase?.())
          return 1;
        return 0;
      },
    },
    {
      key: "restrictedProductName",
      title: "Restricted Product Name",
      render: (record) => {
        return (
          <span style={{ textTransform: "capitalize" }}>
            {record.restrictedProductName}
          </span>
        );
      },
      sorter: (a, b) => {
        if (
          a.restrictedProductName?.toLowerCase?.() <
          b.restrictedProductName?.toLowerCase?.()
        )
          return -1;
        if (
          a.restrictedProductName?.toLowerCase?.() >
          b.restrictedProductName?.toLowerCase?.()
        )
          return 1;
        return 0;
      },
    },
    {
      key: "timeInterval",
      title: "Time interval",
      render: (record) => {
        return getTimeIntervalString(record);
      },
      sorter: (a, b) => {
        const aTimeInterval = getTimeIntervalString(a) || "";
        const bTimeInterval = getTimeIntervalString(b) || "";

        if (aTimeInterval?.toLowerCase?.() < bTimeInterval?.toLowerCase?.())
          return -1;
        if (aTimeInterval?.toLowerCase?.() > bTimeInterval?.toLowerCase?.())
          return 1;
        return 0;
      },
    },
    {
      key: "restrictedProductId",
      title: "Age",
      width: 100,
      render: (record) => {
        return getAgeString(record);
      },
      sorter: (a, b) => {
        const aAge = getAgeString(a) || "";
        const bAge = getAgeString(b) || "";
        if (aAge?.toLowerCase?.() < bAge?.toLowerCase?.()) return -1;
        if (aAge?.toLowerCase?.() > bAge?.toLowerCase?.()) return 1;
        return 0;
      },
    },
    {
      key: "rules",
      title: "Rules",
      render: (record) => {
        let restrictedProduct = "";
        let selectedProduct = "";
        let timeInterval = "NA";

        if (record.productType === 1) {
          restrictedProduct = services.data.find(
            (it) => it.productId === record.productRestrictionId
          );
          selectedProduct = services.data.find(
            (it) => it.productId === record.selectProductId
          );
        } else {
          restrictedProduct = boosts.data.find(
            (it) => it.productId === record.productRestrictionId
          );
          selectedProduct = boosts.data.find(
            (it) => it.productId === record.selectProductId
          );
        }

        if (typeof record.timeInterval !== "number") {
          timeInterval = "N/A";
        } else {
          timeInterval = record?.timeInterval + " hours";
        }

        let fromToString = "";
        if (!!record.fromAge)
          fromToString = `From Age: ${
            record.fromAge ? record.fromAge : "N/A"
          } `;
        if (!!record.toAge)
          fromToString += `To Age: ${record.toAge ? record.toAge : "N/A"} `;

        return `${record.productName}, Time interval: ${timeInterval} ${
          fromToString.length > 0 ? "," : ""
        } ${fromToString} `;
      },
    },

    {
      key: "isActive",
      title: "Status",
      render: (record) => {
        return (
          <span style={{ textTransform: "capitalize" }}>
            {record.isActive ? "Active" : "In-Active"}
          </span>
        );
      },
    },
    {
      title: "",
      key: "action",
      align: "right",
      width: 80,
      render: (record) => {
        return (
          <Dropdown
            overlay={
              <Menu
                style={{ borderRadius: 6, border: "2px solid #CDD5D8 " }}
                key="edit"
              >
                <Menu.Item onClick={() => setShowAddEditDrawer(record)}>
                  Edit
                </Menu.Item>
                <Menu.Item
                  key="delete"
                  onClick={() =>
                    Modal.warning(
                      deleteConfirmation(
                        record.productRestrictionId,
                        record.isActive ? "Inactivate " : "Active"
                      )
                    )
                  }
                >
                  {record.isActive ? "Inactivate " : "Active"}
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
            getPopupContainer={(triggerNode) => triggerNode?.parentNode}
          >
            <MoreOutlined style={{ fontSize: 18 }} />
          </Dropdown>
        );
      },
    },
  ];

  const settingHeaderContent = useSettingsHeader(
    <Button
      rounded
      onClick={() => {
        setShowAddEditDrawer(true);
      }}
    >
      Add Restriction
    </Button>
  );

  const searchAndFiltersContent = usePortal(
    "#settingHeader .settingsHeaderLeftSideAddon",
    <Space>
      <SearchAndFilterColumns
        columns={columns}
        hiddenColumns={hiddenColumns}
        setHiddenColumns={(columnTitles) => {
          setHiddenColumns(columnTitles);
        }}
        searchText={searchText}
        setSearchText={(text) => {
          setSearchText(text);
        }}
      />
      <Select
        style={{ width: 120 }}
        value={activeFilter}
        onChange={(value) => {
          setActiveFilter(value);
        }}
        options={[
          {
            value: "all",
            label: "All",
          },
          {
            value: "active",
            label: "Active",
          },
          {
            value: "inactive",
            label: "Inactive",
          },
        ]}
      />
    </Space>
  );
  return (
    <div>
      {settingHeaderContent}

      {searchAndFiltersContent}
      <Table
        tableLayout="auto"
        locale={{ emptyText: "No Restrictions" }}
        columns={columns.filter((column) => {
          if (!hiddenColumns.includes(column.title)) return true;
          return false;
        })}
        // dataSource={data}
        rowKey={(data) => data.productRestrictionId}
        dataSource={data?.filter(
          (it) =>
            checkActiveFilter(it) &&
            it?.productName
              ?.toLowerCase?.()
              .includes?.(searchText.toLowerCase())
        )}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: [15, 25, 50, 100, 250],
        }}
      />
      <RestrictionsDrawer
        services={services?.data?.filter?.((it) => it.isActive)}
        boosts={boosts?.data?.filter?.((it) => it.isActive)}
        visible={showAddEditDrawer}
        data={showAddEditDrawer}
        onClose={() => setShowAddEditDrawer(false)}
        onSuccess={(values) => {
          const isEdit = showAddEditDrawer?.productRestrictionId !== undefined;
          if (isEdit)
            handleUpdate(showAddEditDrawer?.productRestrictionId, values);
          else handleAdd(values);
        }}
      />
    </div>
  );
}

Restrictions.propTypes = {};

export default Restrictions;
