import React, { useRef } from "react";
import Modal from "components/Modal/Modal";
import { Button } from "components/Buttons/Buttons";
import { Col, Form, Input, Row, Button as AntdButton } from "antd";
import { FormInput } from "components/FormItems/FlatFormItems";
import {
  MinusCircleOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import styles from "./AddEmailAddresses.module.scss";
import { generateProfilePDF } from "services/guests.services";
import { useDispatch } from "react-redux";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};

export default function AddEmailAddresses({ guestId, visible, onClose }) {
  const submitBtnRef = useRef();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  return (
    <Modal
      destroyOnClose={true}
      headerBorder={false}
      width="420px"
      title={"Email PDF"}
      onCancel={onClose}
      visible={visible}
      okText="Save"
      footer={[
        <Button rounded={true} onClick={() => submitBtnRef?.current?.click()}>
          Send
        </Button>,
      ]}
    >
      <Form
        preserve={false}
        form={form}
        requiredMark={false}
        layout="vertical"
        initialValues={{
          emails: [""],
        }}
        onFinish={async (values) => {
          console.log("values", values);
          dispatch(generateProfilePDF(guestId, values?.emails ?? [], true)).then(
            () => {
              onClose();
            }
          );
        }}
        onValuesChange={(changedValues, allValues) => {}}
      >
        <p>Email Addresses</p>
        <Form.List
          name="emails"
          rules={[
            {
              validator: async (_, emails) => {
                if (!emails || emails.length < 1) {
                  return Promise.reject(new Error("At least 1 email"));
                }
              },
            },
          ]}
          initialValue={[""]}
        >
          {(fields, { add, remove }, { errors }) => {
            return (
              <>
                {fields.map((field, index) => (
                  <div className={styles.row}>
                    {/* <Form.Item
                      {...(index === 0
                        ? formItemLayout
                        : formItemLayoutWithOutLabel)}
                      required={true}
                      key={field.key}
                      style={{margin:0}}
                    > */}
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                      }}
                    >
                      <FormInput
                        {...field}
                        formItemStyles={{
                          width: "100%",
                        }}
                        placeholder={"Email"}
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            message: "Please input email.",
                          },
                          {
                            type: "email",
                            message: "Please enter a valid email.",
                          },
                        ]}
                      />
                    </div>
                    {/* </Form.Item> */}
                    <AntdButton
                      type="default"
                      size="large"
                      shape="circle"
                      danger={index !== 0}
                      style={{ marginTop: "4px" }}
                      icon={
                        index === 0 ? (
                          <PlusOutlined />
                        ) : (
                          <MinusOutlined
                            style={{
                              color: "red",
                            }}
                          />
                        )
                      }
                      onClick={() => {
                        if (index === 0) {
                          add();
                        } else {
                          remove(field.name);
                        }
                      }}
                    />
                  </div>
                ))}
                {/* <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{
                      width: "60%",
                    }}
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </Button>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add("The head item", 0);
                    }}
                    style={{
                      width: "60%",
                      marginTop: "20px",
                    }}
                    icon={<PlusOutlined />}
                  >
                    Add field at head
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item> */}
              </>
            );
          }}
        </Form.List>
        <button ref={submitBtnRef} type="submit" style={{ display: "none" }} />
      </Form>
    </Modal>
  );
}
