import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Switch } from "react-router";
import reportWebVitals from "./reportWebVitals";
import configureStore, { history } from "./redux/store/configureStore";
import Login from "./containers/Login/Login.index";
import Reset from "./containers/Reset/Reset.index";

// import './App.less';
import { PublicRoute } from "./components/PublicRoute/PublicRoute";
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";
import AppRoot from "./AppRoot";
import StaffInvite from "containers/StaffInvite/StaffInvite.index";

const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <PublicRoute path="/reset" component={Reset} exact strict />
          <PublicRoute
            path="/complete-staff-registration"
            component={StaffInvite}
            exact
            strict
          />
          <PublicRoute path="/" component={Login} exact strict />
          <PrivateRoute path="/dashboard" component={AppRoot} />
          <PrivateRoute path="*" component={null} exact strict />
        </Switch>
      </ConnectedRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
