import React from "react";
import PropTypes from "prop-types";
import { Card, Typography } from "antd";

import "./SettingCard.style.scss"

const { Title, Text } = Typography;

function SettingCard({ onClick, title, text }) {
  return (
    <Card className="settingCard" onClick={onClick}>
      <Title level={3} className="settingCardTitle">
        {title}
      </Title>
      <Text className="settingCardText">{text}</Text>
    </Card>
  );
}

export default SettingCard;
