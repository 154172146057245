import { openNotificationWithIcon } from "../utils/Notification";
import { request } from "./verb.services";

import { setStates, setCities } from "redux/actions/location.action";

export const getStates = (countryId) => {
  return (dispatch) => {
    return request(`Locations/${countryId}/States`, "get", null, false)
      .then((response) => {
        dispatch(setStates(response.data));
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
  e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};

export const getCities = (stateId) => {
  return (dispatch) => {
    return request(`Locations/Cities/${stateId}`, "get", null, false)
      .then((response) => {
        dispatch(setCities(response.data));
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
  e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};
