export const actions = {
  SET_SELECTED_GUEST: "SET_SELECTED_GUEST",
  SET_SELECTED_APPOINTMENT: "SET_SELECTED_APPOINTMENT",
};

export const setSelectedGuest = (data) => ({
  type: actions.SET_SELECTED_GUEST,
  data,
});

export const setSelectedAppointment = (data)=> ({
  type: actions.SET_SELECTED_APPOINTMENT,
  data,
})