import React, { useEffect, useReducer, useRef, useState } from "react";
import { Drawer, Button as AntdButton, Divider, List, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { useDispatch } from "react-redux";
import DataRow from "./DataRow";
import {
  createCustomer,
  deleteCustomer,
  getAllCustomers,
  updateCustomer,
} from "services/quickbook.service";
import CustomerAddEditDrawer from "./CustomerAddEditDrawer";

export default function CustomerIntegrationDrawer({ visible, onClose }) {
  //states start
  const [data, setData] = useState([]);
  const [showAddEditModal, setShowAddEditModal] = useState();
  //states end

  // non-data hooks start
  const dispatch = useDispatch();
  // non-data hooks end

  // use effect starts
  const loadData = async () => {
    const result = await dispatch(getAllCustomers());
    if (result?.status === "success") {
      setData(result?.data);
    }
  };
  useEffect(() => {
    if (visible) loadData();
  }, [visible]);
  // use effects end

  // functions start
  const handleAdd = (values) => {
    dispatch(createCustomer(values)).then((result) => {
      if (result?.status === "success") {
        setShowAddEditModal(false);
        loadData();
        // loadData();
      }
    });
  };
  const handleUpdate = (id, values) => {
    dispatch(updateCustomer(id, values)).then((result) => {
      if (result?.status === "success") {
        setShowAddEditModal(false);
        loadData();
      }
    });
  };
  const handleDelete = (id) => {
    dispatch(deleteCustomer(id)).then((result) => {
      if (result?.status === "success") {
        loadData();
      }
    });
  };
  const deleteConfirmation = (id) => ({
    title: "Delete Customer",
    content: <>Are you sure that you want to delete the customer?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      handleDelete(id);
      close();
    },
  });
  // functions end

  return (
    <Drawer
      destroyOnClose
      headerStyle={{ border: "none" }}
      className="integrationDrawer"
      width={"min(100vw, 420px)"}
      title={
        <div className="integrationDrawerTitle">
          <p>Customer</p>
          <CloseOutlined onClick={onClose} />
        </div>
      }
      placement="right"
      onClose={onClose}
      visible={visible}
      closable={false}
    >
      <AntdButton
        className="integrationAddBtn"
        onClick={() => {
          setShowAddEditModal(true);
        }}
        type="text"
      >
        Add New
      </AntdButton>
      <Divider className="integrationDivider" />
      <List
        className="integrationList"
        itemLayout="vertical"
        size="large"
        dataSource={data?.filter(it=>it?.isActive)}
        renderItem={(record, index) => (
          <List.Item key={index}>
            <DataRow
              name={record.customerName}
              onEdit={() => {
                setShowAddEditModal(record);
              }}
              onDelete={() => {
                Modal.warning(deleteConfirmation(record.customerId));
              }}
            />
          </List.Item>
        )}
      />
      <CustomerAddEditDrawer
        visible={showAddEditModal}
        onClose={() => {
          setShowAddEditModal(false);
        }}
        data={showAddEditModal}
        customers={data}
        onSuccess={(values) => {
          const isEdit = showAddEditModal?.customerId !== undefined;
          if (isEdit) handleUpdate(showAddEditModal?.customerId, values);
          else handleAdd(values);
        }}
      />
    </Drawer>
  );
}
