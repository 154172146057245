import React, { useState } from "react";
import { Dropdown, Menu, Typography } from "antd";
import { MoreOutlined } from "@ant-design/icons";

const { Title } = Typography;

export default function AvailabilityCard({ data, setVisible }) {
  const Menue = (record, dispatch, setModalVisible, onDeleteHandler) => (
    <Menu style={{ borderRadius: 6, border: "2px solid #CDD5D8 " }}>
      <Menu.Item
        onClick={() => {
          setVisible(true);
        }}
        // onClick={() => {
        //   dispatch(setCurrentStaff(record));
        //   setModalVisible(true);
        //   // console.log(record)
        // }}
      >
        Edit
      </Menu.Item>
      <Menu.Item
      // onClick={() => {
      //   dispatch(setCurrentStaff(record));
      //   Modal.confirm({
      //     title: "Confirm",
      //     okText: "Ok",
      //     cancelText: "Cancel",
      //     content: "Are you sure you want to delete this staff member?",
      //     onOk: onDeleteHandler,
      //   });
      //   // console.log(record)
      // }}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      className="availabilityCard"
      style={{ cursor: "pointer" }}
      onClick={() => {
        setVisible({
          month: data?.value,
          year: data?.year,
        });
      }}
    >
      <div className="availabilityCardLeft">
        <Title
          level={4}
          style={{
            margin: 0,
            color: "#15213b",
            lineHeight: 1,
          }}
        >
          {data?.label} Shift Detail
        </Title>
      </div>
      <div className="availabilityCardRight">
        {/* <Dropdown
          overlay={() => Menue()}
          trigger={["click"]}
          getPopupContainer={(triggerNode) => triggerNode?.parentNode}
        >
          <a onClick={(e) => e.preventDefault()}>
            <MoreOutlined style={{ fontSize: 18, color: "##e5e8ee" }} />
          </a>
        </Dropdown> */}
      </div>
    </div>
  );
}
