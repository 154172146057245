import React from "react";
import PropTypes from "prop-types";
import Drawer from "components/Drawer/Drawer";
import {
  Space,
  Form,
  Row,
  Col,
  Typography,
  Button as AntdButton,
  Avatar,
  Tag,
  Divider,
} from "antd";
import "../Programs.style.scss";
import { Button } from "components/Buttons/Buttons";
import moment from "moment";

const { Text, Title } = Typography;

function ProgramViewModal({ promoId, visible, onClose }) {
  return (
    <Drawer
      className="programDrawer"
      visible={visible}
      onClose={onClose}
      text={
        <Space>
          <span className="programDrawerTitle">PROMO NAME</span>
        </Space>
      }
      footer={
        <div className="programDrawerFooter">
          <AntdButton type="text">Cancel</AntdButton>
          <Button rounded={true}>Deactivate Code</Button>
        </div>
      }
      destroyOnClose={true}
    >
      <Space direction="vertical" className="programDrawerContent">
        <Text>Discount: 10%</Text>
        <Text>
          Promo Code: <b> Dummy Code </b>
        </Text>
        <Text>Active Since: {moment().format("dd MM YYYY hh:mm A")}</Text>
      </Space>
      <Divider />

      <Space size={4} direction="vertical">
        <Title level={2} style={{ margin: 0 }}>
          34
        </Title>
        <Text>Used</Text>
      </Space>
    </Drawer>
  );
}

ProgramViewModal.propTypes = {};

export default ProgramViewModal;
