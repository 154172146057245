import { cloneDeep, sortBy } from "lodash";
import moment from "moment";
import { setLoading } from "redux/actions/app.actions";
import { openNotificationWithIcon } from "utils/Notification";
import {
  DUMMY_EXCLUSIONS,
  DUMMY_LOCATIONS,
  DUMMY_SHIFT_SCHEDULE,
  setExclusions,
  setLocations,
  setShiftSchedule,
} from "./data";
import { request } from "./verb.services";
import { GOOGLE_MAP_KEY } from "utils/constants";
import { getStateAndCountryFromZip } from "./locationCenter.services";

export const getAllLocations = (
  callback,
  showLoading = true,
  includeOnlyActive = false
) => {
  return (dispatch) => {
    if (showLoading) dispatch(setLoading(true));
    return request(
      `Facilities?includeOnlyActive=${includeOnlyActive}`,
      "get",
      null,
      true,
      null
    )
      .then((res) => {
        const sortedData = sortBy(res.data ?? [], ["facilityName"]);

        return {
          status: "success",
          data: sortedData,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        if (showLoading) dispatch(setLoading(false));
      });
  };
};

export const getLocationById = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Facilities/${id}`, "get", null, true)
      .then((res) => {
        return { status: "success", data: res.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const createLocation = (values, loading = true, callback) => {
  return (dispatch) => {
    if (loading) dispatch(setLoading(true));

    return request(`Facilities`, "post", values, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Location created successfully"
        );
        return { status: "success", data: res.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        if (loading) dispatch(setLoading(false));
      });
  };
};

export const deleteLocation = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Facilities/${id}/InActive`, "patch", null, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Location inactivated successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const restoreLocation = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Facilities/${id}/Activate`, "patch", null, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Location activated successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const updateLocation = (id, data, loading = true, callback) => {
  return (dispatch) => {
    if (loading) dispatch(setLoading(true));
    return request(`Facilities/${id}`, "put", data, true)
      .then(() => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Location updated successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        if (loading) dispatch(setLoading(false));
      });
  };
};

// Shift Schedule Services Start
export const getAllShiftSchedules = (showLoader = true, callback) => {
  return (dispatch) => {
    if (showLoader) dispatch(setLoading(true));
    return request("FacilitySchedules", "get", null, true, null)
      .then((res) => {
        return {
          status: "success",
          data: res.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        if (showLoader) dispatch(setLoading(false));
      });
  };
};

export const getShiftScheduleById = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`FacilitySchedules/${id}`, "get", null, true)
      .then((res) => {
        let {
          scheduleName,
          timeZone,
          description,
          facilityScheduleId,
          clinicLeadTime,
          mobileLeadTime,
          telehealthLeadTime,
          exclusions,
          scheduleDetail = [],
        } = res?.data ?? {};
        const formData = [];
        scheduleDetail = scheduleDetail.sort((a, b) => {
          if (
            a?.dayName?.toLowerCase?.().includes("sunday") &&
            !b?.dayName?.toLowerCase?.().includes("sunday")
          )
            return 7;
          if (
            a?.dayName?.toLowerCase?.().includes("monday") &&
            !b?.dayName?.toLowerCase?.().includes("monday")
          )
            return 6;
          if (
            a?.dayName?.toLowerCase?.().includes("tuesday") &&
            !b?.dayName?.toLowerCase?.().includes("tuesday")
          )
            return 5;
          if (
            a?.dayName?.toLowerCase?.().includes("wednesday") &&
            !b?.dayName?.toLowerCase?.().includes("wednesday")
          )
            return 4;
          if (
            a?.dayName?.toLowerCase?.().includes("thursday") &&
            !b?.dayName?.toLowerCase?.().includes("thursday")
          )
            return 3;
          if (
            a?.dayName?.toLowerCase?.().includes("friday") &&
            !b?.dayName?.toLowerCase?.().includes("friday")
          )
            return 2;
          if (
            a?.dayName?.toLowerCase?.().includes("saturday") &&
            !b?.dayName?.toLowerCase?.().includes("saturday")
          )
            return 1;
          return 0;
        });
        for (let i = 0; i < scheduleDetail.length; i++) {
          const {
            startTime,
            endTime,
            isClosed,
            dayName,
            callToBook,
            preHoursOfOperationStartTime,
            preHoursOfOperationEndTime,
            postHoursOfOperationStartTime,
            postHoursOfOperationEndTime,
          } = scheduleDetail?.[i] ?? {};

          const formItem = {
            startTime,
            endTime,
            isClosed,
            dayName,
            callToBook,
            preHoursOfOperationStartTime,
            preHoursOfOperationEndTime,
            postHoursOfOperationStartTime,
            postHoursOfOperationEndTime,
          };
          if (!!startTime) {
            formItem.startTime = moment(startTime, "HH:mm");
          }
          if (!!endTime) {
            formItem.endTime = moment(endTime, "HH:mm");
          }
          if (!!preHoursOfOperationStartTime) {
            formItem.preHoursOfOperationStartTime = moment(
              preHoursOfOperationStartTime,
              "HH:mm"
            );
          }
          if (!!preHoursOfOperationEndTime) {
            formItem.preHoursOfOperationEndTime = moment(
              preHoursOfOperationEndTime,
              "HH:mm"
            );
          }
          if (!!postHoursOfOperationStartTime) {
            formItem.postHoursOfOperationStartTime = moment(
              postHoursOfOperationStartTime,
              "HH:mm"
            );
          }
          if (!!postHoursOfOperationEndTime) {
            formItem.postHoursOfOperationEndTime = moment(
              postHoursOfOperationEndTime,
              "HH:mm"
            );
          }

          formData.push(formItem);
        }

        return {
          status: "success",
          data: {
            scheduleName,
            timeZone,
            description,
            facilityScheduleId,
            exclusions,
            clinicLeadTime,
            mobileLeadTime,
            telehealthLeadTime,
            scheduleDetail: formData,
          },
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const createShiftSchedule = (values, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`FacilitySchedules`, "post", values, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Shift schedule created successfully"
        );
        return { status: "success", data: res?.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const deleteShiftSchedule = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `FacilitySchedules/status?facilityScheduleId=${id}`,
      "patch",
      null,
      true
    )
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Shift schedule inactivated successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const restoreShiftSchedule = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `FacilitySchedules/status?facilityScheduleId=${id}`,
      "patch",
      null,
      true
    )
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Shift schedule activated successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const updateShiftSchedule = (id, data, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`FacilitySchedules/${id}`, "put", data, true)
      .then(() => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Shift schedule updated successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
// Shift Schedule Services End

// Exclusions start

export const getAllExclusions = (
  shiftScheduleId,
  callback,
  showLoader = true
) => {
  return (dispatch) => {
    if (showLoader) dispatch(setLoading(true));
    return new Promise((resolve, reject) => {
      setTimeout(
        () =>
          resolve({
            status: "success",
            data: cloneDeep(
              DUMMY_EXCLUSIONS.filter(
                (iterator) =>
                  iterator.shiftScheduleId === Number(shiftScheduleId)
              )
            ),
          }),
        250
      );
    })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        if (showLoader) dispatch(setLoading(false));
      });
  };
};

export const getExclusionById = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return new Promise((resolve, reject) => {
      const record = DUMMY_EXCLUSIONS.find(
        (iterator) => iterator.exclusionId === Number(id)
      );
      // todo: use callback
      setTimeout(() => resolve({ status: "success", data: record }), 250);
    })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const createExclusion = (shiftScheduleId, values, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `FacilitySchedules/${shiftScheduleId}/Exclusions`,
      "post",
      values,
      true
    )
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Exclusion created successfully"
        );
        return { status: "success", data: res?.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const deleteExclusion = (facilityScheduleId, exclusionId, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `FacilitySchedules/${facilityScheduleId}/Exclusions/${exclusionId}`,
      "delete",
      null,
      true
    )
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Exclusion deleted successfully"
        );
        callback?.();
        return { status: "success" };
      })

      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const updateExclusion = (id, data, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return new Promise((resolve, reject) => {
      const DATA = DUMMY_EXCLUSIONS.map((iterator) => {
        if (iterator.exclusionId === Number(id))
          return {
            ...iterator,
            ...data,
          };
        return iterator;
      });
      setExclusions(DATA);

      setTimeout(() => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Exclusion updated successfully"
        );
        callback?.();
        resolve({ status: "success" });
      }, 250);
    })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
// Exlusions End
