import React, { useRef } from "react";
import { Col, Drawer, Form, Row, Space } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import {
  FormInput,
  FormInputArea,
  FormButton,
  FormPassword,
  FormSelect,
  FormPhoneNumber,
  FormHiddenSubmitButton,
  FormCheckbox,
} from "../../components/FormItems/FlatFormItems";
import { useForm } from "antd/lib/form/Form";
import { Button } from "../../components/Buttons/Buttons";
import { useSelector } from "react-redux";

export default function RolesDrawer({
  visible,
  onClose,
  onAddHandler,
  onUpdateHandler,
}) {
  const [form] = useForm();
  const submitBtnRef = useRef();
  const currentRole = useSelector((state) => state.roles.currentRole);
  const allPermissions = useSelector((state) => state.roles.allPermissions);
  return (
    <Drawer
      destroyOnClose
      headerStyle={{ border: "none" }}
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: 60,
            color: "#000",
            borderBottom: "2px solid #EFF1F4",
          }}
        >
          <p
            style={{
              margin: 0,
              fontSize: 24,
              fontFamily: "tradeGothic",
              fontWeight: "bold",
            }}
          >
            {currentRole ? "Edit Role" : "Create Role"}
          </p>
          <CloseOutlined onClick={onClose} />
        </div>
      }
      placement="right"
      onClose={onClose}
      visible={visible}
      closable={false}
      footer={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: 60,
            color: "#000",
          }}
        >
          <p
            style={{
              margin: 0,
              fontSize: 16,
              fontFamily: "tradeGothic",
              color: "#486BAF",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={onClose}
          >
            Cancel
          </p>
          <Button
            rounded
            type={"primary"}
            style={{ height: 50, width: "50%" }}
            onClick={() => submitBtnRef?.current?.click()}
          >
            Save
          </Button>
        </div>
      }
    >
      <Form
        preserve={false}
        layout="vertical"
        form={form}
        name={"basicInformationForm"}
        requiredMark={false}
        onFinish={(values) => {
          values.roleName = values.roleName?.trim?.() || "";
          currentRole ? onUpdateHandler(values) : onAddHandler(values);
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
      >
        <FormInput
          variant="underlined"
          name="roleName"
          label="Role Name"
          placeholder="Role Name"
          required={true}
          initialValue={currentRole?.roleName}
        />
        <FormSelect
          mode="multiple"
          feedBack={false}
          required={true}
          variant="underlined"
          name="permissionIds"
          label="Permissions"
          initialValue={currentRole?.permissions?.map(
            (item) => item.permissionId
          )}
          renderValue="value"
          placeholder={"Permissions"}
          renderLabel="label"
          styles={{ border: "none" }}
          className={"multiSelect"}
          options={allPermissions.map((item) => ({
            key: item.permissionId,
            label: item.permissionName,
            value: item.permissionId,
          }))}
          // styles={{ background: '#ddd' }}
        />

        <FormCheckbox
          name="isActive"
          label="Active"
          formItemStyles={{ marginBottom: 12 }}
          initialValue={currentRole ? currentRole.isActive : true}
        >
          Active
        </FormCheckbox>
        <FormHiddenSubmitButton submitRef={submitBtnRef} />
        {/* <FormButton
          text="Save"
          htmlType="submit"
          rounded
          type={'primary'}
        /> */}
      </Form>
    </Drawer>
  );
}
