import { Spin } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "redux/actions/app.actions";
import {
  getCustomerNoShowBookings,
  getCustomerTotalBookings,
  getCustomerUpcomingBookings,
} from "services/booking.service";

export default function CustomerBookingCounts({
  customerId,
  onAppointmentTypeClick,
}) {
  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    setIsFetching(true);
    Promise.all([
      dispatch(getCustomerTotalBookings(customerId)),
      dispatch(getCustomerUpcomingBookings(customerId)),
      dispatch(getCustomerNoShowBookings(customerId)),
    ]).finally(() => {
      setIsFetching(false);
    });
  }, []);

  const dispatch = useDispatch();
  const { customerInfo } = useSelector((state) => state);

  const { totalBookings, upcomingBookings, noShowBookings } = customerInfo;

  return (
    <div
      style={{
        padding: "12px 0px",
        paddingBottom: 16,
        borderBottom: "2px solid #EFF1F4",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        fontFamily: "tradeGothic",
        fontWeight: "normal",
        fontSize: 16,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {isFetching ? (
          <Spin />
        ) : (
          <p style={{ fontSize: 30, margin: 0 }}>
            {totalBookings?.length ?? 0}
          </p>
        )}

        <p
          style={{
            textAlign: "center",
            margin: 0,
            lineHeight: 1.2,
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => onAppointmentTypeClick(0)}
        >
          Total Bookings
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {isFetching ? (
          <Spin />
        ) : (
          <p style={{ fontSize: 30, margin: 0 }}>
            {upcomingBookings?.length ?? 0}
          </p>
        )}
        <p
          style={{
            textAlign: "center",
            margin: 0,
            lineHeight: 1.2,
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => onAppointmentTypeClick(1)}
        >
          Upcoming Appointments
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {isFetching ? (
          <Spin />
        ) : (
          <p style={{ fontSize: 30, margin: 0 }}>
            {noShowBookings?.length ?? 0}
          </p>
        )}
        <p
          style={{
            textAlign: "center",
            margin: 0,
            lineHeight: 1.2,
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => onAppointmentTypeClick(3)}
        >
          No Shows
        </p>
      </div>
    </div>
  );
}
