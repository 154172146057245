import React, { useEffect, useState } from "react";

import "./ManageRewardsPopup.scss";
import Modal from "components/Modal/Modal";
import { Space, Typography, Modal as AntModal } from "antd";
import { getProgramNameFromBenefitType, getRewardsText } from "utils/common";
import { useDispatch } from "react-redux";
import {
  applyRewardToLineitem,
  removeLineitemReward,
} from "services/booking.service";
import { openNotificationWithIcon } from "utils/Notification";
import RewardPopup from "components/RewardPopup/RewardPopup";
import { getProgramName } from "services/programsAndMembership.services";
import Spinner from "components/Spinner/Spinner";
import { getEnumKeyByValue } from "utils/enums";
import { PROGRAM_TYPE } from "utils/enums";

const { Title, Text, Link } = Typography;

const Name = ({ productName, rewardId, lineItemId }) => {
  const dispatch = useDispatch();
  const [programName, setProgramName] = useState({
    data: "",
    isLoading: false,
  });
  const createProgramName = (program) => {
    const type = getProgramNameFromBenefitType(program?.benefitType);
    if (!!type && !!program?.programName)
      return `(${type}: ${program?.programName})`;
    return "";
  };
  useEffect(() => {
    if (rewardId) {
      setProgramName((state) => ({
        ...state,
        isLoading: true,
      }));
      dispatch(getProgramName(rewardId))
        .then((res) => {
          if (res?.status === "success") {
            setProgramName((state) => ({
              data: createProgramName(res?.data),
              isLoading: false,
            }));
          }
        })
        .finally(() => {
          setProgramName((state) => ({
            ...state,
            isLoading: false,
          }));
        });
    }
  }, [lineItemId]);

  return (
    <Title
      level={5}
      className="tradeGothic"
      style={{ marginBottom: 0, fontSize: 17, color: "#000" }}
    >
      {productName}{" "}
      {!!rewardId &&
        (programName?.isLoading ? <Spinner /> : <>{programName?.data}</>)}
    </Title>
  );
};

export default function ManageRewardsPopup({
  orderId,
  visible,
  onCancel,
  handleSave,
  lineItem,
  refreshCustomerRewards,
  isRewardsLoading,
  rewards,
  availedRewards,
  facilityId,
}) {
  const dispatch = useDispatch();
  const {
    lineItemId,
    productName,
    durationHours,
    addOns = [],
    rewardId,
  } = lineItem;

  const [programNames, setProgramNames] = useState({
    data: [],
    isLoading: true,
  });

  const [showRewardPopup, setShowRewardPopup] = useState(false);

  const deleteConfirmation = (id) => ({
    title: "Remove Reward",
    content: <>Are you sure that you want to remove the reward?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      dispatch(removeLineitemReward(orderId, id)).then(() => {
        refreshCustomerRewards();
      });
      close();
    },
  });

  return (
    <Modal
      destroyOnClose={true}
      className="boostPopup"
      title="Manage Rewards"
      headerBorder={true}
      visible={visible}
      onCancel={onCancel}
      okText="Save & Order"
      onOk={handleSave}
      //   okButtonProps={{
      //     disabled: !initialValue || initialValue?.length <= 0,
      //   }}
      footer={<></>}
    >
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Name
            productName={productName}
            rewardId={rewardId}
            lineItemId={lineItemId}
          />
          {!rewardId && (
            <Link onClick={() => setShowRewardPopup(lineItem)} underline>
              Add Reward
            </Link>
          )}
        </div>
        {rewardId && (
          <div
            className="flex alignCenter justifySpaceBetween"
            style={{
              marginBottom: 14,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Space size={"middle"}>
              <Title
                level={5}
                className="tradeGothic"
                style={{
                  marginBottom: 0,
                  fontSize: 17,
                  color: "#27ca2d",
                }}
              >
                Rewards : {getRewardsText(lineItem)}
              </Title>
            </Space>
            <Link
              onClick={() => {
                if (isRewardsLoading) {
                  openNotificationWithIcon(
                    "error",
                    "Loading Rewards",
                    "Please wait while rewards are loading"
                  );
                  return;
                }
                AntModal.warning(deleteConfirmation(lineItem.lineItemId));
              }}
              underline
              style={{
                color: "#ea5961",
              }}
            >
              Remove
            </Link>
          </div>
        )}
      </div>
      <hr />
      {addOns?.length > 0 && (
        <>
          <Title
            className="tradeGothic"
            style={{
              marginBottom: 12,
              fontSize: 19,
              color: "rgb(72, 107, 175)",
            }}
          >
            Additional Addons
          </Title>
          <div>
            {addOns.map((addon) => {
              const { productName, lineItemId, rewardId } = addon;
              return (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Name
                      productName={productName}
                      rewardId={rewardId}
                      lineItemId={lineItemId}
                    />
                    {!rewardId && (
                      <Link underline onClick={() => setShowRewardPopup(addon)}>
                        Add Reward
                      </Link>
                    )}
                  </div>
                  {rewardId && (
                    <div
                      className="flex alignCenter justifySpaceBetween"
                      style={{
                        marginBottom: 14,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Space size={"middle"}>
                        <Title
                          level={5}
                          className="tradeGothic"
                          style={{
                            marginBottom: 0,
                            fontSize: 17,
                            color: "#27ca2d",
                          }}
                        >
                          Rewards : {getRewardsText(addon)}
                        </Title>
                      </Space>
                      <Link
                        onClick={() => {
                          if (isRewardsLoading) {
                            openNotificationWithIcon(
                              "error",
                              "Loading Rewards",
                              "Please wait while rewards are loading"
                            );
                            return;
                          }
                          AntModal.warning(deleteConfirmation(lineItemId));
                        }}
                        underline
                        style={{
                          color: "#ea5961",
                        }}
                      >
                        Remove
                      </Link>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </>
      )}
      <RewardPopup
        values={showRewardPopup}
        lineItemId={showRewardPopup}
        rewards={rewards ?? []}
        availedRewards={availedRewards}
        visible={showRewardPopup}
        onCancel={() => setShowRewardPopup(false)}
        facilityId={facilityId}
        onRedeem={(rewardId, lineItemId) => {
          dispatch(
            applyRewardToLineitem(
              orderId,
              showRewardPopup?.lineItemId,
              rewardId,
              () => {
                openNotificationWithIcon(
                  "success",
                  "Success",
                  "Reward applied successfully"
                );
                refreshCustomerRewards();
                setShowRewardPopup(false);
              }
            )
          );
        }}
      />
    </Modal>
  );
}
