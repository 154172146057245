
export const actions = {
  SET_ALL_ROLES: "SET_ALL_ROLES",
  SET_CURRENT_ROLE: "SET_CURRENT_ORDER",
  SET_ALL_PERMISSIONS: "SET_ALL_PERMISSIONS",
};

export const setAllRoles = data => ({
  type: actions.SET_ALL_ROLES,
  data
})
export const setCurrentRole = data => ({
  type: actions.SET_CURRENT_ROLE,
  data
})
export const setAllPermissions = data => ({
  type: actions.SET_ALL_PERMISSIONS,
  data
})