import Dashboard from "containers/Dashboard/Dashboard";
import Profile from "containers/Profile/Profile";
import Guests from "containers/Users/Guests/Guests.index";
import Staff from "containers/Users/Staff/Staff.index";
import ResourceScheduler from "containers/ResourceScheduler/ResourceScheduler";
import Roles from "containers/Roles/Roles.index";
import Appointments from "containers/Appointments/Appointments.index";
import BeautifyGlow from "containers/ChartForms/Beautify&Glow";
import CovidTesting from "containers/ChartForms/CovidTesting";
import Dehydration from "containers/ChartForms/Dehydration";
import EmergencyMeds from "containers/ChartForms/EmergencyMeds";
import HangoverRelief from "containers/ChartForms/HangoverRelief";
import Allergies from "containers/ChartForms/Allergies";
import ColdFLu from "containers/ChartForms/Cold&Flu";
import EnergyBoost from "containers/ChartForms/EnergyBoost";
import FatigueJetLag from "containers/ChartForms/Fatigue&JetLag";
import Charts from "containers/Charts/Charts.index";

import AbdominalDiscomfort from "containers/ChartForms/AbdominalDiscomfort";
import HighDoseNAD from "containers/ChartForms/HighDoseNAD";
import Hydration from "containers/ChartForms/Hydration";
import IMInjection from "containers/ChartForms/IMInjection";
import ImmunityBoostDefense from "containers/ChartForms/ImmunityBoostDefense";
import LowDoseNAD from "containers/ChartForms/LowDoseNAD";
import MentalClarity from "containers/ChartForms/MentalClarity";
import Migraine from "containers/ChartForms/Migrane";
import MyersLowDoseNAD from "containers/ChartForms/MyersLowDoseNAD";
import MyersCocktail from "containers/ChartForms/MyersCocktail";
import nauseaAndVomiting from "containers/ChartForms/NauseaAndVomitting";
import PerformanceAndRecovery from "containers/ChartForms/PerformanceAndRecovery";
import WeightManagement from "containers/ChartForms/WeightManagement";
import FormsManagement from "containers/FormsManagement/FormsManagement.index";
import FormEditCreate from "containers/FormsManagement/components/FormDesigner";
import Settings from "containers/Settings/Settings.index";
import Skills from "containers/Skills/Skills.index";
import ChartV2 from "containers/Charts/components/ChartV2";
import ShiftScheduler from "containers/ShiftScheduler/ShiftScheduler";
import Availability from "containers/Availability/Availability";
import Inventory from "containers/Settings/Screens/Inventory/Inventory";

// router configuration.
let routes = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    exact: true,
  },
  {
    path: "/dashboard/guests",
    name: "users",
    component: Guests,
    exact: true,
  },
  {
    path: "/dashboard/staff",
    name: "staff",
    component: Staff,
    exact: true,
  },
  {
    path: "/dashboard/skills",
    name: "skills",
    component: Skills,
    exact: true,
  },
  {
    path: "/dashboard/roles",
    name: "staff",
    component: Roles,
    exact: true,
  },
  {
    path: "/dashboard/charts",
    name: "charts",
    component: Charts,
    exact: true,
  },
  {
    path: "/dashboard/calendar",
    name: "calendar",
    component: ResourceScheduler,
  },
  {
    path: "/dashboard/shifts",
    name: "shiftsScheduler",
    component: ShiftScheduler,
  },
  {
    path: "/dashboard/appointments",
    name: "appointments",
    component: Appointments,
    exact: true,
  },
  {
    path: "/dashboard/forms-managment/edit/:id",
    name: "form-edit",
    component: FormEditCreate,
  },
  {
    path: "/dashboard/forms-managment",
    name: "formsManagment",
    component: FormsManagement,
    exact: true,
  },

  {
    path: "/dashboard/settings",
    name: "settings",
    component: Settings,
    exact: false,
  },
  {
    path: "/dashboard/chartsv2/:orderId/:lineItemId",
    name: "chartsv2",
    component: ChartV2,
    exact: false,
  },
  {
    path: "/dashboard/charts/:orderId/abdominalDiscomfort/:lineItemId",
    name: "abdominalDiscomfort",
    component: AbdominalDiscomfort,
    exact: true,
  },
  {
    path: "/dashboard/charts/:orderId/allergies/:lineItemId",
    name: "allergies",
    component: Allergies,
    exact: true,
  },
  {
    path: "/dashboard/charts/:orderId/beautifyAndGlow/:lineItemId",
    name: "beautify",
    component: BeautifyGlow,
    exact: true,
  },
  {
    path: "/dashboard/charts/:orderId/coldAndFlu/:lineItemId",
    name: "coldAndFlu",
    component: ColdFLu,
    exact: true,
  },
  {
    path: "/dashboard/charts/:orderId/covidTesting/:lineItemId",
    name: "covidTesting",
    component: CovidTesting,
    exact: true,
  },
  {
    path: "/dashboard/charts/:orderId/dehydration/:lineItemId",
    name: "dehydration",
    component: Dehydration,
    exact: true,
  },
  {
    path: "/dashboard/charts/:orderId/emergencyMeds/:lineItemId",
    name: "emergencyMeds",
    component: EmergencyMeds,
    exact: true,
  },
  {
    path: "/dashboard/charts/:orderId/energyBoost/:lineItemId",
    name: "energyBoost",
    component: EnergyBoost,
    exact: true,
  },
  {
    path: "/dashboard/charts/:orderId/fatigueAndJetLag/:lineItemId",
    name: "FatigueAndJetLag",
    component: FatigueJetLag,
    exact: true,
  },
  {
    path: "/dashboard/charts/:orderId/hangoverRelief/:lineItemId",
    name: "hangoverRelief",
    component: HangoverRelief,
    exact: true,
  },
  {
    path: "/dashboard/charts/:orderId/highDoseNAD/:lineItemId",
    name: "highDoseNAD",
    component: HighDoseNAD,
    exact: true,
  },
  {
    path: "/dashboard/charts/:orderId/hydration/:lineItemId",
    name: "hydration",
    component: Hydration,
    exact: true,
  },
  {
    path: "/dashboard/charts/:orderId/iMInjection/:lineItemId",
    name: "iMInjection",
    component: IMInjection,
    exact: true,
  },
  {
    path: "/dashboard/charts/:orderId/immunityBoostDefense/:lineItemId",
    name: "immunityBoostDefense",
    component: ImmunityBoostDefense,
    exact: true,
  },
  {
    path: "/dashboard/charts/:orderId/lowDoseNAD/:lineItemId",
    name: "lowDoseNAD",
    component: LowDoseNAD,
    exact: true,
  },
  {
    path: "/dashboard/charts/:orderId/mentalClarity/:lineItemId",
    name: "mentalClarity",
    component: MentalClarity,
    exact: true,
  },
  {
    path: "/dashboard/charts/:orderId/migraine/:lineItemId",
    name: "migraine",
    component: Migraine,
    exact: true,
  },
  {
    path: "/dashboard/charts/:orderId/myersLowDoseNAD/:lineItemId",
    name: "myersLowDoseNAD",
    component: MyersLowDoseNAD,
    exact: true,
  },
  {
    path: "/dashboard/charts/:orderId/myersCocktail/:lineItemId",
    name: "myersCocktail",
    component: MyersCocktail,
    exact: true,
  },
  {
    path: "/dashboard/charts/:orderId/nauseaAndVomiting/:lineItemId",
    name: "nauseaAndVomiting",
    component: nauseaAndVomiting,
    exact: true,
  },
  {
    path: "/dashboard/charts/:orderId/performanceAndRecovery/:lineItemId",
    name: "performanceAndRecovery",
    component: PerformanceAndRecovery,
    exact: true,
  },
  {
    path: "/dashboard/charts/:orderId/weightManagement/:lineItemId",
    name: "weightManagement",
    component: WeightManagement,
    exact: true,
  },
  {
    path: "/dashboard/availability",
    name: "availability",
    component: Availability,
    exact: true,
  },
  {
    path: "/dashboard/inventory/:orderId",
    name: "inventory",
    component: Inventory,
    exact: true,
  },
];

export default routes;
