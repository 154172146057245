import React from "react";
import { Radio, Form } from "antd";

const FormItem = Form.Item;

// TODO: Make a generic component for this
export default function StatusPill({ label, value, onChange, disabled }) {
  return (
    <FormItem
      className="flatFormItem statusPillContainer"
      label={label}
      style={{ marginBottom: 0 }}
    >
      <Radio.Group
        disabled={disabled}
        className="statusPill"
        onChange={(e, value) => {
          onChange(e.target.value);
        }}
        value={value}
      >
        <Radio.Button value={5}>Arrive</Radio.Button>
        <Radio.Button value={4}>No Show</Radio.Button>
      </Radio.Group>
    </FormItem>
  );
}
