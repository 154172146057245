import { cloneDeep, groupBy, uniqBy } from "lodash";
import moment from "moment";
import { setLoading } from "redux/actions/app.actions";
import { setAllShifts, setIsLoadingShift } from "redux/actions/shifts.action";
import { openNotificationWithIcon } from "utils/Notification";
import {
  DUMMY_SERVICES,
  DUMMY_CATEGORIES,
  DUMMY_BOOSTS,
  setServices,
  setBoosts,
} from "./data";
import { request } from "./verb.services";

/**
 * Function to get list of selectable shifts for a staff
 * @param {boolean} showLoading 
 * @param {function} callback 
 * @returns 
 */
export const getMyShifts = (showLoading = true, callback) => {
  return (dispatch) => {
    if (showLoading) dispatch(setLoading(true));
    dispatch(setIsLoadingShift(true));
    return request("MyfacilityShifts", "get", null, true, null)
      .then((res) => {
        dispatch(setAllShifts(res?.data));
        return {
          status: "success",
          data: res?.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        if (showLoading) dispatch(setLoading(false));
        dispatch(setIsLoadingShift(false));
      });
  };
};

/**
 * Useless function for now
 * @constructor
 */
export const getAllShifts = (showLoading = true, callback) => {
  return (dispatch) => {
    if (showLoading) dispatch(setLoading(true));
    dispatch(setIsLoadingShift(true));
    return request("MyFacilityShifts", "get", null, true, null)
      .then((res) => {
        dispatch(setAllShifts(res.data));
        return {
          status: "success",
          data: res?.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        if (showLoading) dispatch(setLoading(false));
        dispatch(setIsLoadingShift(false));
      });
  };
};

export const getShiftById = (facilityId, shiftId, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Facilities/${facilityId}/Shifts/${shiftId}`,
      "get",
      null,
      true
    )
      .then((res) => {
        return { status: "success", data: res.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const createShift = (values, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`FacilityShifts`, "post", values, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Shift created successfully"
        );
        return { status: "success", data: res.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const deleteShift = (facilityId, shiftId, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Facilities/${facilityId}/Shifts/${shiftId}`, "patch", null, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Shift inactivated successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const restoreShift = (facilityId, shiftId, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Facilities/${facilityId}/Shifts/${shiftId}`, "patch", null, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Shift activated successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const updateShift = (facilityId, shiftId, data, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Facilities/${facilityId}/Shifts/${shiftId}`,
      "put",
      data,
      true
    )
      .then(() => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Shift updated successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
