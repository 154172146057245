import React, { useEffect, useRef, useState } from "react";
import { Col, Drawer, Form, Row, Space, Tooltip } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllAccounts,
  getAllClasses,
  getAllCustomers,
  getAllJournalEntries,
  getAllSubclasses,
  runScript,
} from "services/quickbook.service";

import { useForm } from "antd/lib/form/Form";
import { Button } from "components/Buttons/Buttons";
import { FormRadioGroup } from "components/FormItems/FlatFormItems";
import moment from "moment";
import { JOUNRAL_ENTRY_STATUS } from "utils/enums";
import JournalEntryDetailDrawer from "./JournalEntryDetailDrawer";

export default function RunApiIntegrationDrawer({ visible, onClose }) {
  //states start
  const [data, setData] = useState([]);
  const [form] = useForm();
  //states end
  const selectedDate = Form.useWatch("date", form);
  const currentStaff = useSelector((state) => state.user.currentUser);

  const [showViewDrawer, setShowViewDrawer] = useState();

  const [accounts, setAccounts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [classes, setClasses] = useState([]);
  const [subClasses, setSubClasses] = useState([]);

  // non-data hooks start
  const dispatch = useDispatch();
  // non-data hooks end

  // use effect starts
  const loadData = async () => {
    const result = await dispatch(getAllJournalEntries());
    if (result?.status === "success") {
      setData(result?.data);
    }
  };
  useEffect(() => {
    if (visible) {
      loadData();
      dispatch(getAllAccounts()).then((res) => {
        if (res?.status === "success") {
          setAccounts(res.data);
        }
      });
      dispatch(getAllCustomers()).then((res) => {
        if (res?.status === "success") {
          setCustomers(res.data);
        }
      });
      dispatch(getAllClasses()).then((res) => {
        if (res?.status === "success") {
          setClasses(res.data);
        }
      });
      dispatch(getAllSubclasses()).then((res) => {
        if (res?.status === "success") {
          setSubClasses(res.data);
        }
      });
    }
  }, [visible]);
  // use effects end

  // functions start
  //   const handleAdd = (values) => {
  //     dispatch(createClass(values)).then((result) => {
  //       if (result?.status === "success") {
  //         setShowAddEditModal();
  //         loadData();
  //         // loadData();
  //       }
  //     });
  //   };
  //   const handleUpdate = (id, values) => {
  //     dispatch(updateClass(id, values)).then((result) => {
  //       if (result?.status === "success") {
  //         setShowAddEditModal();
  //         loadData();
  //       }
  //     });
  //   };
  //   const handleDelete = (id) => {
  //     dispatch(deleteClass(id)).then((result) => {
  //       if (result?.status === "success") {
  //         loadData();
  //       }
  //     });
  //   };
  //   const deleteConfirmation = (id) => ({
  //     title: "Delete",
  //     content: <>Are you sure that you want to delete?</>,
  //     centered: true,
  //     maskClosable: true,
  //     onOk: (close) => {
  //       handleDelete(id);
  //       close();
  //     },
  //   });
  // functions end

  return (
    <Drawer
      destroyOnClose
      headerStyle={{ border: "none" }}
      className="integrationDrawer"
      width={"min(100vw, 620px)"}
      title={
        <div className="integrationDrawerTitle">
          <p>Run API</p>
          <CloseOutlined onClick={onClose} />
        </div>
      }
      placement="right"
      onClose={onClose}
      visible={visible}
      closable={false}
    >
      <Form preserve={false} layout="vertical" form={form} requiredMark={false}>
        <Row gutter={[12, 12]}>
          <Col xs={24}>
            <Space>
              <Button
                disabled={!selectedDate}
                rounded
                type={"primary"}
                style={{ height: 50 }}
                onClick={() => {
                  setShowViewDrawer(selectedDate);
                }}
              >
                View
              </Button>
              <Button
                disabled={!selectedDate}
                rounded
                type={"primary"}
                style={{ height: 50 }}
                onClick={() => {
                  dispatch(runScript(selectedDate, currentStaff?.staffId));
                }}
              >
                Execute Now
              </Button>
              {/* <Button
                disabled={!selectedDate}
                htmlType="submit"
                rounded
                type={"primary"}
                style={{ height: 50 }}
                onClick={() => {

                }}
              >
                Activiy Log
              </Button> */}
            </Space>
          </Col>
          <Col xs={24}>
            <FormRadioGroup
              name="date"
              vertical={true}
              inline={false}
              label=""
              className="normalFontRadio"
              // options={[1, 2, 3, 4, 5].map((_, index) => {
              //   const date = moment().subtract(index, "days");
              //   return `${date.format("MM/DD/YYYY")} at 6:00am `;
              // })}
              options={data?.map((it) => {
                const date = moment(it?.journalDate);

                return {
                  value: it?.journalEntryHeaderId,
                  label: it?.journalDate ? (
                    <Space className="jounalEntryRadioContainer">
                      <span>
                        {`${date?.format(`MM/DD/YYYY`)} at ${date?.format(
                          "H:mma"
                        )}`}
                      </span>
                      {JOUNRAL_ENTRY_STATUS?.[it?.journalEntryStatusId] ===
                        "Posted" && (
                        <Tooltip title="Completed">
                          <CheckCircleOutlined style={{ color: "#389e0d" }} />
                        </Tooltip>
                      )}
                      {JOUNRAL_ENTRY_STATUS?.[it?.journalEntryStatusId] ===
                        "Failed" && (
                        <Tooltip title="Failed">
                          <CloseCircleOutlined style={{ color: "#f5222d" }} />
                        </Tooltip>
                      )}
                    </Space>
                  ) : (
                    "No date available"
                  ),
                };
              })}
            />
          </Col>
        </Row>
      </Form>
      <JournalEntryDetailDrawer
        visible={showViewDrawer}
        data={showViewDrawer}
        onClose={() => {
          setShowViewDrawer();
        }}
        accounts={accounts}
        classes={classes}
        subClasses={subClasses}
        customers={customers}
      />
    </Drawer>
  );
}
