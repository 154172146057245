import React from "react";
import { Typography } from "antd";
import { CrownOutlined } from "@ant-design/icons";

const { Title } = Typography;
export default function MembershipStatus({ hasMembership }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 8,
      }}
    >
      <Title
        style={{
          color: "#000",
          fontSize: 17,
          margin: 0,
        }}
      >
        Has Membership
      </Title>
      <CrownOutlined
        style={{
          fontSize: 20,
          color: "#ffa900",
        }}
      />
    </div>
  );
}
