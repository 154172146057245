import {
  InputNumber,
  Select,
  Space,
  Table,
  Button as AntdButton,
  Modal as AntdModal,
  Spin,
} from "antd";
import { Button } from "components/Buttons/Buttons";
import useSettingsHeader from "hooks/useSettingsHeader";
import React, { useEffect, useReducer, useState } from "react";
import { ReactComponent as ChevronDownIco } from "assets/icons/chevronDownIco.svg";

import "./Communications.style.scss";
import { CaretDownOutlined } from "@ant-design/icons";
import Modal from "components/Modal/Modal";
import PlaceHolderModal from "./components/PlaceHolderModal";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteNotificationTrigger,
  getAllTemplates,
  getAllTriggerNotifications,
  getAllTriggers,
  updateNotificationTrigger,
} from "services/communicationServices";
import { getFacilities } from "services/facilities.services";
import { cloneDeep } from "lodash";
import CommunicationModal from "./components/CommunicationModal";
import Spinner from "components/Spinner/Spinner";
import { getAllServices } from "services/servicesAndBoostsSettings.service";
import { EXCLUSION_TYPE } from "utils/enums";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET":
      return action.data;
    case "ADD":
      return [...state, action.data];
    case "UPDATE":
      return state.map((data) => {
        if (data.categoryId === action.data.categoryId) return action.data;
        return data;
      });
    case "DELETE":
      return state.filter((data) => {
        if (data.categoryId === action.data.categoryId) return false;
        return true;
      });
    default:
      return state;
  }
};

const exclusionTypes = Object.entries(EXCLUSION_TYPE).map((it) => {
  return {
    label: it[0],
    value: it[1],
  };
});

export default function Communcations() {
  // hooks
  const dispatch = useDispatch();

  // states
  const [showAddModal, setShowAddModal] = useState(false);

  const [originalData, setOriginalData] = useState([]);
  const [data, localDispatch] = useReducer(reducer, []);

  const [services, setServices] = useState({
    data: [],
    isLoading: true,
  });

  const [triggers, setTriggers] = useState({
    isLoading: true,
    data: [],
  });
  const [templates, setTemplates] = useState({
    isLoading: true,
    data: [],
  });
  const { allFacilities } = useSelector((state) => {
    return {
      allFacilities: state.facilities.allFacilities,
    };
  });
  const [showPlaceholderModal, setShowPlaceholderModal] = useState(false);

  const settingHeaderContent = useSettingsHeader(
    <Space align="center">
      {/* <AntdButton
        type="text"
        style={{ padding: 0, lineHeight: 1 }}
        onClick={() => setShowPlaceholderModal(true)}
      >
        Placeholders Info
      </AntdButton> */}
      <Button rounded onClick={() => setShowAddModal(true)}>
        Add Notification Trigger
      </Button>
    </Space>
  );
  // vars

  const loadData = async (loadAll = true) => {
    const result = await dispatch(getAllTriggerNotifications());
    if (result?.status === "success") {
      setOriginalData(result.data);
      localDispatch({
        type: "SET",
        data: result.data,
      });
    }
    if (loadAll) {
      dispatch(getAllTriggers()).then((data) => {
        setTriggers({
          isLoading: false,
          data: data?.data ?? [],
        });
      });

      dispatch(getAllTemplates()).then((data) => {
        setTemplates({
          isLoading: false,
          data: data?.data ?? [],
        });
      });

      dispatch(getAllServices(false)).then((data) => {
        setServices({
          isLoading: false,
          data: data?.data ?? [],
        });
      });

      dispatch(getFacilities());
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleDelete = (id) => {
    dispatch(deleteNotificationTrigger(id)).then((result) => {
      if (result?.status === "success") {
        const newData = data?.filter((it) => it.triggerNotificationId !== id);
        localDispatch({
          type: "SET",
          data: newData,
        });
        setOriginalData(
          originalData?.filter((it) => it.triggerNotificationId !== id)
        );
      }
    });
  };

  const deleteConfirmation = (id) => ({
    title: "Delete Category",
    content: <>Are you sure that you want to delete the category?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      handleDelete(id);
      close();
    },
  });
  return (
    <div>
      {settingHeaderContent}
      <Table
        scroll={{
          x: true,
          y: false,
        }}
        tableLayout="auto"
        locale={{ emptyText: "No Items" }}
        columns={[
          {
            width: 200,
            title: "Templates",
            key: "templateName",
            render: (record) => {
              if (templates?.isLoading) return <Spinner />;
              const template = templates?.data?.find?.(
                (it) => it?.id === record?.templateId
              );
              return template?.name;
            },
            sorter: (a, b) => {
              if (templates?.isLoading) return 0;
              const aTemplate = templates?.data?.find?.(
                (it) => it?.id === a?.templateId
              );
              const bTemplate = templates?.data?.find?.(
                (it) => it?.id === b?.templateId
              );

              console.log("aTemplate", aTemplate);
              console.log("bTemplate", bTemplate);
              if (
                aTemplate.name?.toLowerCase?.() <
                bTemplate.name?.toLowerCase?.()
              )
                return -1;
              if (
                aTemplate.name?.toLowerCase?.() >
                bTemplate.name?.toLowerCase?.()
              )
                return 1;
              return 0;
            },
          },
          {
            title: "Triggers",
            key: "triggers",
            width: 200,
            render: (record) => {
              const trigger = triggers?.data?.find?.(
                (it) => it.triggerId === record.triggerId
              );
              return (
                <Select
                  style={{
                    maxWidth: 220,
                  }}
                  className="communicationSelector"
                  value={record.triggerId}
                  onChange={(value) => {
                    const updatedData = data.map((it) => {
                      if (
                        record.triggerNotificationId ===
                        it.triggerNotificationId
                      ) {
                        return {
                          ...record,
                          triggerId: value,
                          dirty: true,
                        };
                      }
                      return it;
                    });
                    localDispatch({
                      type: "SET",
                      data: updatedData,
                    });
                    // setData(updatedData);
                  }}
                  options={triggers?.data?.map((it) => ({
                    value: it.triggerId,
                    label: it.triggerName,
                  }))}
                  suffixIcon={<CaretDownOutlined style={{}} />}
                />
              );
            },
          },
          {
            title: "Time",
            align: "center",
            key: "time",
            width: 240,
            render: (record, index) => {
              return (
                <Space size={18}>
                  <InputNumber
                    style={{
                      widows: 50,
                      maxWidth: 60,
                    }}
                    className="communicationInputNumber"
                    value={Number(record.timeInterval)}
                    onChange={(value) => {
                      const updatedData = data.map((it, index2) => {
                        if (
                          record.triggerNotificationId ===
                          it.triggerNotificationId
                        ) {
                          return {
                            ...record,
                            timeInterval: Number(value),
                            dirty: true,
                          };
                        }
                        return it;
                      });
                      localDispatch({
                        type: "SET",
                        data: updatedData,
                      });
                    }}
                  />
                  <Select
                    style={{ width: 100, maxWidth: 120 }}
                    className="communicationSelector"
                    value={record.timeUnit}
                    onChange={(value) => {
                      const updatedData = data.map((it) => {
                        if (
                          record.triggerNotificationId ===
                          it.triggerNotificationId
                        ) {
                          return {
                            ...record,
                            timeUnit: value,
                            dirty: true,
                          };
                        }
                        return it;
                      });
                      localDispatch({
                        type: "SET",
                        data: updatedData,
                      });
                    }}
                    options={[
                      {
                        label: "Minutes",
                        value: 1,
                      },
                      {
                        label: "Hours",
                        value: 2,
                      },
                      {
                        label: "Days",
                        value: 3,
                      },
                      {
                        label: "Weeks",
                        value: 4,
                      },
                      {
                        label: "Months",
                        value: 5,
                      },
                    ]}
                    suffixIcon={<CaretDownOutlined style={{}} />}
                  />
                </Space>
              );
            },
          },
          {
            title: "Schedule Time",
            width: 140,
            render: (record) => {
              return (
                <Select
                  style={{ width: 130, minWidth: 140 }}
                  className="communicationSelector"
                  value={record.sendingDateTimePlaceholders}
                  onChange={(value) => {
                    const updatedData = data.map((it) => {
                      if (
                        record.triggerNotificationId ===
                        it.triggerNotificationId
                      ) {
                        return {
                          ...record,
                          sendingDateTimePlaceholders: value,
                          dirty: true,
                        };
                      }
                      return it;
                    });
                    localDispatch({
                      type: "SET",
                      data: updatedData,
                    });
                  }}
                  options={[
                    {
                      value: "",
                      label: "Now",
                    },
                    // {
                    //   value: "{Order.ServiceDate}",
                    //   label: "Service Date",
                    // },
                    {
                      value: "{Order.ServiceDateUTC}",
                      label: "Service Date",
                    },
                  ]}
                  suffixIcon={<CaretDownOutlined style={{}} />}
                />
              );
            },
          },
          {
            title: "Facility",
            key: "time",
            width: 180,
            render: (record) => {
              const facilities = [
                {
                  facilityName: "All",
                  facilityId: -1,
                },
                ...allFacilities,
              ];

              return (
                <Select
                  style={{ width: 160, maxWidth: 170 }}
                  className="communicationSelector"
                  name="facilityId"
                  value={record.facilityId}
                  onChange={(value) => {
                    const updatedData = data.map((it) => {
                      if (
                        record.triggerNotificationId ===
                        it.triggerNotificationId
                      ) {
                        return {
                          ...record,
                          facilityId: value,
                          dirty: true,
                        };
                      }
                      return it;
                    });
                    localDispatch({
                      type: "SET",
                      data: updatedData,
                    });
                  }}
                  options={facilities?.map((it) => {
                    return {
                      value: it?.facilityId,
                      label: it?.facilityName,
                    };
                  })}
                  suffixIcon={<CaretDownOutlined style={{}} />}
                />
              );
            },
          },
          {
            title: "Operation Type",
            key: "time",
            width: 180,
            render: (record) => {
              const operationTypes = [
                {
                  operationTypeName: "All",
                  operationTypeId: -1,
                },
                ...[
                  {
                    operationTypeName: "Mobile",
                    operationTypeId: 1,
                  },
                  {
                    operationTypeName: "Clinic",
                    operationTypeId: 2,
                  },
                  {
                    operationTypeName: "Telehealth",
                    operationTypeId: 3,
                  },
                ],
              ];

              return (
                <Select
                  style={{ width: 160, maxWidth: 170 }}
                  className="communicationSelector"
                  name="operationTypeId"
                  value={record.operationTypeId}
                  onChange={(value) => {
                    const updatedData = data.map((it) => {
                      if (
                        record.triggerNotificationId ===
                        it.triggerNotificationId
                      ) {
                        return {
                          ...record,
                          operationTypeId: value,
                          dirty: true,
                        };
                      }
                      return it;
                    });
                    localDispatch({
                      type: "SET",
                      data: updatedData,
                    });
                  }}
                  options={operationTypes?.map((it) => {
                    return {
                      value: it?.operationTypeId,
                      label: it?.operationTypeName,
                    };
                  })}
                  suffixIcon={<CaretDownOutlined style={{}} />}
                />
              );
            },
          },
          {
            title: "Recipient",
            key: "recipient",
            render: (record) => {
              return (
                <Select
                  style={{ width: 130, minWidth: 140 }}
                  className="communicationSelector"
                  value={record.recipientPlaceholders}
                  onChange={(value) => {
                    const updatedData = data.map((it) => {
                      if (
                        record.triggerNotificationId ===
                        it.triggerNotificationId
                      ) {
                        return {
                          ...record,
                          recipientPlaceholders: value,
                          dirty: true,
                        };
                      }
                      return it;
                    });
                    localDispatch({
                      type: "SET",
                      data: updatedData,
                    });
                  }}
                  options={[
                    {
                      value: "{Customer.Email}",
                      label: "Customer Email",
                    },
                    {
                      value: "{Customer.PhoneNumber}",
                      label: "Customer PhoneNumber",
                    },
                    {
                      value: "{Staff.Email}",
                      label: "Staff Email",
                    },
                    {
                      value: "{Staff.PhoneNumber}",
                      label: "Staff PhoneNumber",
                    },
                  ]}
                  suffixIcon={<CaretDownOutlined style={{}} />}
                />
              );
            },
          },
          {
            title: "Product Exclusion Type",
            key: "productExcludeType",
            width: 220,
            render: (record) => {
              return (
                <Select
                  style={{ width: 160, maxWidth: 200 }}
                  className="communicationSelector"
                  name="productExcludeType"
                  value={record.productExcludeType}
                  onChange={(value) => {
                    const updatedData = data.map((it) => {
                      if (
                        record.triggerNotificationId ===
                        it.triggerNotificationId
                      ) {
                        return {
                          ...record,
                          productExcludeType: value,
                          dirty: true,
                        };
                      }
                      return it;
                    });
                    localDispatch({
                      type: "SET",
                      data: updatedData,
                    });
                  }}
                  options={exclusionTypes}
                  suffixIcon={<CaretDownOutlined style={{}} />}
                />
              );
            },
          },
          {
            title: "Products",
            key: "products",
            width: 200,
            render: (record) => {
              return (
                <Select
                  style={{ width: 160, maxWidth: 200 }}
                  variant="underlined"
                  name="productIds"
                  placeholder="Select services"
                  mode="multiple"
                  formItemStyles={{ marginBottom: 0 }}
                  options={services?.data?.filter((it) => {
                    return it?.isActive;
                  })}
                  // isOptionDisabled={(it) => !it.isActive}
                  filterOption={(input, option) => {
                    return option?.label
                      ?.toLowerCase?.()
                      .includes?.(input.toLowerCase());
                  }}
                  // renderLabel="productName"
                  // renderValue="productId"
                  fieldNames={{
                    label: "productName",
                    value: "productId",
                  }}
                  maxTagCount={1}
                  disabled={
                    record?.productExcludeType ===
                    EXCLUSION_TYPE["All Included"]
                  }
                  required={
                    record?.productExcludeType !==
                    EXCLUSION_TYPE["All Included"]
                  }
                  loading={services?.isServicesLoading}
                  value={record.productIds}
                  onChange={(values) => {
                    const updatedData = data.map((it) => {
                      if (
                        record.triggerNotificationId ===
                        it.triggerNotificationId
                      ) {
                        return {
                          ...record,
                          productIds: values,
                          dirty: true,
                        };
                      }
                      return it;
                    });
                    localDispatch({
                      type: "SET",
                      data: updatedData,
                    });
                  }}
                />
              );
            },
          },
          {
            title: "",
            align: "right",
            render: (record) => {
              const deleteButton = (
                <>
                  <AntdButton
                    danger={true}
                    type="text"
                    onClick={() => {
                      AntdModal.warning({
                        title: "Delete Trigger Notification",
                        content: (
                          <>
                            Are you sure that you want to delete the trigger
                            notification?
                          </>
                        ),
                        centered: true,
                        maskClosable: true,
                        onOk: (close) => {
                          dispatch(
                            deleteNotificationTrigger(
                              record?.triggerNotificationId
                            )
                          ).then((res) => {
                            if (res?.status === "success") {
                              loadData();
                            }
                          });
                          close();
                        },
                      });
                    }}
                  >
                    Delete
                  </AntdButton>
                </>
              );
              if (!record?.dirty) return deleteButton;

              return (
                <Space size="small">
                  <AntdButton
                    type="text"
                    onClick={() => {
                      const newOriginalData = originalData.map((it) => {
                        if (
                          it.triggerNotificationId ===
                          record.triggerNotificationId
                        ) {
                          return { ...it, ...record, dirty: false };
                        }
                        return it;
                      });

                      const {
                        triggerId,
                        templateId,
                        facilityId,
                        timeInterval,
                        timeUnit,
                        recipientPlaceholders,
                        operationTypeId,
                        type,
                        productExcludeType,
                        productIds,
                      } = record;
                      dispatch(
                        updateNotificationTrigger(
                          record?.triggerNotificationId,
                          {
                            triggerId,
                            templateId,
                            facilityId,
                            timeInterval,
                            timeUnit,
                            recipientPlaceholders,
                            operationTypeId,
                            type,
                            productExcludeType,
                            productIds,
                          }
                        )
                      ).then(() => {
                        localDispatch({
                          type: "SET",
                          data: newOriginalData,
                        });
                        setOriginalData(newOriginalData);
                      });
                    }}
                  >
                    Save
                  </AntdButton>
                  <AntdButton
                    type="text"
                    className="greyTextBtn"
                    onClick={() => {
                      const originalRecord = originalData.find(
                        (it) =>
                          it.triggerNotificationId ===
                          record.triggerNotificationId
                      );
                      const originalDataNew = originalData.map((it) => {
                        if (
                          it.triggerNotificationId ===
                          record.triggerNotificationId
                        ) {
                          return { ...record, ...originalRecord, dirty: false };
                        }
                        return it;
                      });
                      const restoreData = data.map((it) => {
                        if (
                          it.triggerNotificationId ===
                          record.triggerNotificationId
                        ) {
                          return { ...record, ...originalRecord, dirty: false };
                        }
                        return it;
                      });
                      setOriginalData(originalDataNew);
                      localDispatch({
                        type: "SET",
                        data: restoreData,
                      });
                    }}
                  >
                    Cancel
                  </AntdButton>
                  {deleteButton}
                </Space>
              );
            },
          },
        ]}
        dataSource={data}
        rowKey={(record) => record.triggerNotificationId}
        // dataSource={allGuests.filter((data) => {
        //   return data.name.toLowerCase().includes(searchText.toLowerCase());
        // })}
        // columns={columns.filter((column) => {
        //   if (!hiddenColumns.includes(column.title)) return true;
        //   return false;
        // })}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: [15, 25, 50, 100, 250],
        }}
      />
      <CommunicationModal
        templates={templates?.data}
        triggers={triggers?.data}
        facilities={allFacilities}
        visible={showAddModal}
        onCancel={() => {
          setShowAddModal(false);
        }}
        onSuccess={(newRecord) => {
          localDispatch({
            type: "SET",
            data: [...data, newRecord],
          });
          setOriginalData([...originalData, newRecord]);
          setShowAddModal(false);
        }}
        services={services?.data ?? []}
        isServicesLoading={services?.isLoading}
      />

      <PlaceHolderModal
        open={showPlaceholderModal}
        onClose={() => setShowPlaceholderModal(false)}
        data={[
          {
            id: 0,
            title: "*|BRAND:LOGO|*",
            description:
              "Adds a default logo placeholder to your campaign. Use this merge tag in place of an image URL in a custom-coded template or Code content block.",
          },
          {
            id: 1,
            title: "*|REWARDS|*",
            description: "Adds the Referral badge to your campaign.",
          },
          {
            id: 1,
            title: "*|FNAME|*",
            description:
              "Inserts your subscriber's first name if it's available in your audience.",
          },
          {
            id: 1,
            title: "*|LNAME|*",
            description:
              "Inserts your subscriber's last name if it's available in your audience.",
          },
          {
            id: 1,
            title: "*|EMAIL|*",
            description: "Inserts your subscriber's email address.",
          },
        ]}
      />
    </div>
  );
}
