import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Avatar, Form, Typography } from "antd";
import InventoryKitSearch from "./component/InventoryKitSearch";
import ProductsList from "./component/ProductsList";
import { useDispatch } from "react-redux";
import { getKitsInfo } from "services/inventory.service";
import { Button } from "components/Buttons/Buttons";
import { chunk, cloneDeep } from "lodash";
import { setLoading } from "redux/actions/app.actions";
import { openNotificationWithIcon } from "utils/Notification";
import { UserOutlined } from "@ant-design/icons";
import SubmittedList from "./component/SubmittedList";

const { Text, Title } = Typography;

// Controlled component
// will maintain state of kits values on save will send to server and trigger the success callback
const InventoryKits = forwardRef(
  (
    {
      productId,
      productName,
      hasPreferenceCard,
      onSuccess,
      customerName,
      orderId,
      isAddon = false,
      isOrderComplete = false,
      isInventorySubmitted,
      updateKit,
      kitNo,
      searchKitFlag,
      updateSubmissionKit,
      snapshotData,
      isViewOnly,
    },
    ref
  ) => {
    const [kits, setKits] = useState({
      data: [],
      isLoading: false,
      isFetched: false,
    });

    const formRef = useRef();
    ref.current[productId] = formRef;

    const [consumedInventory, setConsumedInventory] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
      if (searchKitFlag && kitNo && hasPreferenceCard) searchKits();
    }, [searchKitFlag, kitNo, hasPreferenceCard]);

    const searchKits = () => {
      if (formRef.current) formRef?.resetFields?.();

      updateSubmissionKit(productId, []);
      setKits((state) => ({
        ...state,
        isLoading: true,
      }));
      dispatch(getKitsInfo(productId, kitNo, productName, isAddon))
        .then((res) => {
          if (res?.status === "success") {
            if (res?.data && res?.data?.inventoryProducts)
              res.data.inventoryProducts =
                res?.data?.inventoryProducts?.map((it, index) => ({
                  ...it,
                  key: index,
                  originalQuantity: it?.quantity,
                })) ?? [];
            setKits((state) => ({
              ...state,
              data: { ...(res?.data ?? {}), kitNo } ?? [],
            }));
          } else {
            throw new Error("Error");
          }
        })
        .catch(() => {
          setKits((state) => ({
            ...state,
            data: { inventoryProducts: [] },
          }));
        })
        .finally(() => {
          setKits((state) => ({
            ...state,
            isFetched: true,
            isLoading: false,
          }));
        });
    };
    const removeSkuLot = (index) => {
      setKits((state) => ({
        ...state,
        data: {
          ...state.data,
          inventoryProducts: state?.data?.inventoryProducts?.filter(
            (it, index2) => index !== index2
          ),
        },
      }));
    };

    const addSkuLot = (index, lots) => {
      const kitsData = cloneDeep(kits?.data?.inventoryProducts ?? []);
      const kit = cloneDeep(kitsData?.[index]);
      kitsData.splice(index + 1, 0, kit);
      kitsData[index + 1].key = kitsData?.length;
      kitsData[index + 1].lot = undefined;
      kitsData[index + 1].lots = lots;
      kitsData[index + 1].quantity = kitsData[index + 1].originalQuantity;

      setKits((state) => ({
        ...state,
        data: {
          ...state.data,
          inventoryProducts: kitsData,
        },
      }));
    };

    const changeLot = (index, inventoryId, lot) => {
      setKits((state) => ({
        ...state,
        data: {
          ...state.data,
          inventoryProducts: state?.data?.inventoryProducts?.map(
            (product, index2) => {
              if (index === index2) {
                return {
                  ...product,
                  lotInventoryId: inventoryId,
                  maxQuantity: lot?.quantity,
                  lotNumber: lot?.lotNumber || "",
                  // quantity: lot?.quantity,
                  lot: inventoryId,
                  facilityProductId: lot?.facilityProductId,
                };
              }
              return product;
            }
          ),
        },
      }));
    };

    const changeQuantity = (index, quantity) => {
      setKits((state) => ({
        ...state,
        data: {
          ...state.data,
          inventoryProducts: state?.data?.inventoryProducts?.map(
            (product, index2) => {
              if (index === index2) {
                return {
                  ...product,
                  quantity: Number(quantity),
                };
              }
              return product;
            }
          ),
        },
      }));
    };

    const onSubmit = () => {
      const { inventoryProducts, kitNo } = kits?.data ?? {};
      const data = [];
      const fullData = [];

      inventoryProducts
        ?.filter?.(
          (inventoryProduct) =>
            inventoryProduct.quantity > 0 &&
            inventoryProduct?.lotInventoryId !== undefined
        )
        .forEach((product) => {
          const { lotInventoryId, quantity, facilityProductId, lotNumber } =
            product ?? {};
          // get inventoryFacilityId from lot

          const inventory = {
            inventoryId: lotInventoryId,
            quantity,
            facilityProductId,
            lotNumber,
          };
          data.push(inventory);
          fullData.push(product);
        });

      // submitKit(data);
      updateSubmissionKit(productId, data);

      return;
    };

    return (
      <>
        {isViewOnly ? (
          <SubmittedList
            snapshotData={snapshotData}
            kits={kits}
            productId={productId}
            productName={productName}
            hasPreferenceCard={hasPreferenceCard}
            kitNo={kitNo}
          />
        ) : (
          <ProductsList
            productId={productId}
            productName={productName}
            hasPreferenceCard={hasPreferenceCard}
            isLoading={kits?.isLoading}
            data={kits?.data?.inventoryProducts}
            isFetched={kits?.isFetched}
            onChangeQuantity={changeQuantity}
            onChangeLot={changeLot}
            addSkuLot={addSkuLot}
            removeSkuLot={removeSkuLot}
            onSubmit={onSubmit}
            isComplete={isInventorySubmitted || isOrderComplete}
            kitNo={kitNo}
            onRefresh={searchKits}
            ref={formRef}
            snapshotData={snapshotData}
            isInventorySubmitted={isInventorySubmitted}
          />
        )}

        {/* {kits?.isComplete &&
        consumedInventory?.map((inventory) => {
          return (
            <div style={{ marginTop: 16 }}>
              <Text style={{ fontSize: 16, color: "#000" }}>
                {inventory?.quantity} {inventory?.productName} consumed
              </Text>
            </div>
          );
        })} */}
      </>
    );
  }
);

export default InventoryKits;
