import {
  Avatar,
  Form,
  Pagination,
  Skeleton,
  Table,
  Typography,
  Button as AntdButton,
  Tooltip,
} from "antd";
import OutlinedSelectorFormItem from "components/EventDrawer/components/OutlinedSelector";
import Incrementor from "components/Incrementor/Incrementor";
import React, { forwardRef, useEffect } from "react";
import LotNumber from "./LotNumber";
import { Button } from "components/Buttons/Buttons";
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  SyncOutlined,
  UserOutlined,
} from "@ant-design/icons";
import AddonIcon from "assets/icons/addon.png";

const { Text, Title } = Typography;

const ProductsList = forwardRef(
  (
    {
      data = [],
      isLoading,
      isFetched,
      kitNo,
      addSkuLot,
      removeSkuLot,
      onChangeQuantity,
      onChangeLot,
      onSubmit,
      isComplete,
      productName,
      onRefresh,
      productId,
      isMarked,
      hasPreferenceCard,
    },
    ref
  ) => {
    const [form] = Form.useForm();

    useEffect(() => {}, []);
    // useEffect(() => {
    //   console.log("ref", ref);
    //   if (ref?.current) ref.current = form;
    // }, []);

    const columns = [
      {
        title: "SKU",
        key: "inventoryProductId",
        width: 160,
        render: (it) => {
          return (
            <div
              style={{
                display: "flex",
                gap: 8,
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 16,
                }}
              >
                {it.inventoryProductId}
              </Text>
              {isComplete && (
                <CheckCircleOutlined
                  style={{ fontSize: 20, color: "#52c41a" }}
                />
              )}
            </div>
          );
        },
      },
      {
        title: "Description",
        key: "productName",
        dataIndex: "productName",
      },
      {
        title: "Lot",
        key: "lot",
        width: 250,
        render: (it, _, index) => {
          return (
            <LotNumber
              form={form}
              disabled={isComplete}
              index={index}
              required={it?.quantity > 0}
              kitNo={kitNo}
              lot={it?.lot}
              lots={it?.lots}
              keyProp={it?.key}
              facilityProductId={it?.facilityProductId}
              addSkuLot={addSkuLot}
              removeSkuLot={removeSkuLot}
              onChangeLot={(inventoryId, lot) =>
                onChangeLot(index, inventoryId, lot)
              }
            />
          );
        },
      },
      // {
      //   title: "Unit",
      //   key: "unit",
      //   width: 80,
      //   render: (it) => {
      //     return 1;
      //   },
      // },
      {
        title: "Qty",
        width: 200,

        render: (it, _, index) => {
          return (
            <Incrementor
              disabled={isComplete}
              onChangeQuantity={(quantity) => onChangeQuantity(index, quantity)}
              maxQuantity={it?.maxQuantity}
              quantity={it?.quantity}
              index={index}
              form={form}
              keyProp={it?.key}
            />
          );
        },
      },
    ];

    return (
      <Form
        ref={ref}
        name={productId}
        preserve={false}
        form={form}
        onFinish={async () => {
          if (hasPreferenceCard) onSubmit();
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
      >
        <div
          id={`inventorySection-${productId}`}
          className="kitSearch"
          style={
            {
              // borderBottom: "2px solid #f0f0f0",
            }
          }
        >
          <div className="userInfo">
            <Avatar
              size={45}
              icon={<UserOutlined />}
              style={{
                backgroundColor: "#f5f2ef",
              }}
              src={AddonIcon}
            />
            <div className="names">
              <Title>{productName}</Title>
            </div>
          </div>
          <div
            className="controls"
            style={{
              display: "flex",
              gap: 16,
            }}
          >
            {!hasPreferenceCard && (
              <Tooltip title="Fetch preferennce card is disabled from product settings">
                <CloseCircleOutlined
                  style={{ color: "#f5222d", fontSize: 20 }}
                />
              </Tooltip>
            )}
          </div>
        </div>
        <Content
          form={form}
          isFetched={isFetched}
          isLoading={isLoading}
          data={data}
          columns={columns}
          onSubmit={onSubmit}
          isComplete={isComplete}
          hasPreferenceCard={hasPreferenceCard}
        />
      </Form>
    );
  }
);

function Content({
  isFetched,
  isLoading,
  form,
  data,
  columns,
  onSubmit,
  isComplete,
  hasPreferenceCard,
}) {
  if (isLoading)
    return (
      <div style={{ marginBottom: 16 }}>
        <Skeleton.Button
          active
          block={true}
          paragraph={true}
          style={{ marginBottom: 12 }}
        />
        <Skeleton.Button active block={true} paragraph={true} />
      </div>
    );
  if (!isFetched)
    return (
      <Text style={{ marginBottom: 16, display: "inline-block" }}>
        No content fetched yet.
      </Text>
    );

  return (
    <Table
      rowKey="key"
      tableLayout="auto"
      locale={{
        emptyText: !hasPreferenceCard
          ? "Preference Card Fetch Disabled"
          : "No Items",
      }}
      dataSource={data ?? []} // Replace with data
      columns={columns}
      pagination={false}
      scroll={{ x: true }}
      style={{ marginBottom: 16 }}
    />
  );
}

export default ProductsList;
