import React, { useCallback, useMemo } from "react";
import "./LocationSelector.scss";

import { Select, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentFacility,
  setCurrentFacilityIds,
  setCurrentTelehalthFacility,
} from "redux/actions/calendar.actions";
import { CalenderOperationType } from "utils/enums";
import { EnvironmentOutlined } from "@ant-design/icons";
import { groupBy } from "lodash";

const { Option } = Select;

export default function LocationSelector() {
  const {
    currentTeleFacility,
    currentFacilityIds,
    currentOperationType,
    facilities,
    isFetchingFacilities,
  } = useSelector((state) => {
    return {
      currentTeleFacility: state.calendar.currentTeleFacility,
      currentFacilityIds: state.calendar.currentFacilityIds,
      currentOperationType: state.calendar.currentOperationType,
      facilities: state.facilities.allFacilities,
      isFetchingFacilities: state.facilities.isFetchingFacilities,
    };
  });
  const dispatch = useDispatch();
  const facilityIds =
    currentOperationType === CalenderOperationType.Telehealth
      ? currentTeleFacility
      : currentFacilityIds;

  const isTeleFacility = (facility) => {
    return facility?.operationTypeIds?.includes?.(3);
  };

  const isNormalFacility = (facility) => {
    return (
      facility?.operationTypeIds?.includes?.(1) ||
      facility?.operationTypeIds?.includes?.(2)
    );
  };

  const filteredFacilities = useMemo(() => {
    let filteredFacilities = facilities.filter((facility) => {
      if(facility.isActive === false) return false;


      if (
        currentOperationType === CalenderOperationType.All &&
        isNormalFacility(facility)
      )
        return true;
      else if (
        currentOperationType === CalenderOperationType.Telehealth &&
        isTeleFacility(facility)
      )
        return true;
      else return false;
    });

    if (currentOperationType === CalenderOperationType.Telehealth) {
      return filteredFacilities.map((it) => {
        return {
          value: it.facilityId,
          label: it.facilityName,
        };
      });
    } else {
      let groupedFilteredFacilities = Object.entries(
        groupBy(filteredFacilities, "state")
      ).map(([state, facilities]) => {
        return {
          label: state,
          options: facilities.map((it) => {
            return {
              value: it.facilityId,
              label: it.facilityName,
            };
          }),
        };
      });
      return groupedFilteredFacilities;
    }
  }, [facilities, currentOperationType]);

  const setFacility = useCallback(
    (facilityIds) => {
      // if clinic or mobile is selected
      if (currentOperationType === CalenderOperationType.All) {
        let contitionalFacilityIds = facilityIds;
        // if more than one facility is selected
        if (facilityIds.length > 1) {
          let secondLastFacilityId = facilityIds[facilityIds.length - 2];
          let secondLastFacility = facilities.find(
            (facility) => facility.facilityId === secondLastFacilityId
          );
          let lastFacilityId = facilityIds[facilityIds.length - 1];
          let lastFacility = facilities.find(
            (facility) => facility.facilityId === lastFacilityId
          );
          // if a different state is selected
          if (secondLastFacility?.state !== lastFacility?.state) {
            contitionalFacilityIds = [lastFacilityId];
          }
        }

        dispatch(setCurrentFacilityIds(contitionalFacilityIds));
      } else if (currentOperationType === CalenderOperationType.Telehealth) {
        dispatch(setCurrentTelehalthFacility(facilityIds));
      }
    },
    [currentFacilityIds, currentOperationType, facilities]
  );

  return (
    <div className="locationSelectorContainer">
      <div className="locationSelectorIcon">
        <EnvironmentOutlined />
      </div>
      <Select
        // allowClear={currentOperationType === CalenderOperationType.Telehealth}
        className="locationSelector multiLocationSelector"
        dropdownClassName="locationSelectorDropdown"
        isSearch={true}
        placeholder="Select location center"
        optionFilterProp="children"
        value={facilityIds}
        onChange={setFacility}
        loading={isFetchingFacilities}
        filterOption={(input, option) => {
          return option.label?.toLowerCase?.().includes(input.toLowerCase());
        }}
        // optionLabelProp={"facilityName"}
        showSearch={true}
        options={filteredFacilities}
        mode="multiple"
        tagRender={(props) => {
          const { label, value, closable, onClose } = props;
          return <span>{label}</span>;
        }}
        maxTagPlaceholder={(values) => {
          return "+" + values.length;
        }}
        maxTagCount={1}
      />
      {/* {filteredFacilities.map((facility) => (
          <Option
            key={facility?.["facilityId"]}
            value={facility?.["facilityId"]}
            label={facility?.["facilityName"]}
          >
            {facility?.["facilityName"]}
          </Option>
        ))}
      </Select> */}
    </div>
  );
}
