import React, { useCallback, useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";

import { Dropdown, Menu, Modal, Table, Typography } from "antd";
import { Button } from "components/Buttons/Buttons";
import ExclusionDrawer from "./ExclusionsDrawer";
import { useDispatch } from "react-redux";
import {
  createExclusion,
  deleteExclusion,
  getAllExclusions,
  updateExclusion,
} from "services/locationSettings.service";
import moment from "moment";
import { MoreOutlined } from "@ant-design/icons";

const { Title } = Typography;

const reducer = (state, action) => {
  switch (action.type) {
    case "SET":
      return action.data;
    case "ADD":
      return [...state, action.data];
    case "UPDATE":
      return state.map((data) => {
        if (data.categoryId === action.data.categoryId) return action.data;
        return data;
      });
    case "DELETE":
      return state.filter((data) => {
        if (data.categoryId === action.data.categoryId) return false;
        return true;
      });
    default:
      return state;
  }
};

function Exclusions({ data, shiftScheduleId, disabled, getData }) {
  const [showAddEditDrawer, setShowAddEditDrawer] = useState();
  const [isLoading, setIsLoading] = useState(false);

  // const [data, localDispatch] = useReducer(reducer, []);

  // non-data hooks start
  const dispatch = useDispatch();
  // non-data hooks end

  // // use effect starts
  // const loadData = async (id) => {
  //   setIsLoading(true);
  //   const result = await dispatch(getAllExclusions(id, undefined, false));
  //   if (result?.status === "success") {
  //     localDispatch({
  //       type: "SET",
  //       data: result.data,
  //     });
  //   }
  //   setIsLoading(false);
  // };
  // useEffect(() => {
  //   if (shiftScheduleId) loadData(shiftScheduleId);
  // }, [shiftScheduleId]);
  // use effects end

  // functions start
  const handleAdd = useCallback(
    (values) => {
      dispatch(createExclusion(shiftScheduleId, [values])).then((result) => {
        if (result?.status === "success") {
          setShowAddEditDrawer();
          getData();
          // loadData();
          // loadData();
        }
      });
    },
    [shiftScheduleId]
  );
  const handleUpdate = (id, values) => {
    dispatch(updateExclusion(id, values)).then((result) => {
      if (result?.status === "success") {
        setShowAddEditDrawer();
        getData();
        // loadData(shiftScheduleId);
      }
    });
  };
  const handleDelete = (id) => {
    dispatch(deleteExclusion(shiftScheduleId, id)).then((result) => {
      if (result?.status === "success") {
        getData();
      }
    });
  };
  const deleteConfirmation = (id) => ({
    title: "Delete Exclusion",
    content: <>Are you sure that you want to delete the exclusion?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      handleDelete(id);
      close();
    },
  });
  // functions end

  return (
    <div>
      <div className="d-flex justify-between align-center mb-16 ">
        <Title
          level={4}
          style={{ margin: "16px 0px", color: "#000", paddingTop: 4 }}
        >
          Exclusions
        </Title>
        <Button
          disabled={disabled}
          rounded={true}
          onClick={() => setShowAddEditDrawer(true)}
        >
          Add Exclusion Date
        </Button>
      </div>

      <ExclusionDrawer
        data={showAddEditDrawer}
        visible={showAddEditDrawer}
        onClose={() => {
          setShowAddEditDrawer();
        }}
        onSuccess={(values) => {
          const isEdit = showAddEditDrawer?.exclusionId !== undefined;
          if (isEdit) handleUpdate(showAddEditDrawer?.exclusionId, values);
          else handleAdd(values);
        }}
        //   data, visible, onClose, onSuccess
      />

      <Table
        tableLayout="auto"
        locale={{ emptyText: "No items to display" }}
        columns={[
          {
            title: "Name",
            dataIndex: "exclusionName",
            key: "exclusionName",
          },
          {
            title: "Date",
            key: "date",
            render: (record) => {
              return moment(record.exclusionDate).format("L");
            },
          },
          {
            title: "From",
            key: "from",
            render: (record) => {
              if (record.isClosed) return "-";
              return record.startTime ? (
                moment(record.startTime, "HH:mm").format("LT")
              ) : (
                <></>
              );
            },
          },
          {
            title: "To",
            key: "to",
            render: (record) => {
              if (record.isClosed) return "-";
              return record.endTime ? (
                moment(record.endTime, "HH:mm").format("LT")
              ) : (
                <></>
              );
            },
          },
          {
            title: "Closed All Day",
            key: "isClosed",
            render: (record) => {
              if (record.isClosed) {
                return "Closed";
              } else {
                return "No";
              }
            },
          },
          {
            title: "",
            key: "action",
            align: "right",
            width: 80,
            render: (record) => (
              <Dropdown
                overlay={
                  <Menu
                    style={{ borderRadius: 6, border: "2px solid #CDD5D8 " }}
                  >
                    {/* <Menu.Item
                      onClick={() => {
                        setShowAddEditDrawer(record);
                      }}
                    >
                      Edit
                    </Menu.Item> */}
                    <Menu.Item
                      onClick={() =>
                        Modal.warning(deleteConfirmation(record.exclusionId))
                      }
                    >
                      Delete
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
                getPopupContainer={(triggerNode) => triggerNode?.parentNode}
              >
                <MoreOutlined style={{ fontSize: 18 }} />
              </Dropdown>
            ),
          },
        ]}
        loading={isLoading}
        dataSource={data}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: [15, 25, 50, 100, 250],
        }}
        // scroll={{ y: "calc(100vh - 80px - 96px - 60px - 100px)", x: true }}
      />
    </div>
  );
}

Exclusions.propTypes = {};

export default Exclusions;
