import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "components/Modal/Modal";
import { Button } from "components/Buttons/Buttons";

import { postForm, getForm } from "services/intakeForm.service";
import Signature, { useSignature } from "components/Signature/Signature";

import { openNotificationWithIcon } from "utils/Notification";

import { Col, Form, Row } from "antd";
import {
  FormInputArea,
  FormCheckboxGroup,
  FormSlider,
  FormRadioGroup,
  FormInput,
  FormDatePicker,
  FormInputNumber,
  FormSelect,
  FormCheckbox,
} from "components/FormItems/FlatFormItems";
import moment from "moment";
import {
  submitChart,
  updateChart,
  getLegacyChart,
  getChartNotes,
  addChartNotes,
} from "services/charts.services";
import { setLoading } from "redux/actions/app.actions";
import { isUserNurse } from "utils/common";

const FORM_NAME = "PhysicalHistory";
function HistoryIntakeForm({ visible, onSubmit, onClose, user }) {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const currentStaff = useSelector((state) => state.user.currentUser);

  const [chart, setChart] = useState([]);
  const [chartNotes, setChartNotes] = useState([]);
  useEffect(async () => {
    const orderId = window.location.pathname.split("/")[3];
    const lineItemId = window.location.pathname.split("/")[5];
    dispatch(setLoading(true));
    const chart = await dispatch(getLegacyChart(orderId, lineItemId));
    if (chart.length) {
      if (chart[0].chart) {
        const data = JSON.parse(chart[0].chart);
        setFormChecked(data);
        chart[0].chart = data;
        setChart(chart);
        form.setFieldsValue({
          ...data,
          additiveMineralBlendExpiry: data.additiveMineralBlendExpiry
            ? moment(data.additiveMineralBlendExpiry)
            : null,
          additiveVitaminDExpiry: data.additiveVitaminDExpiry
            ? moment(data.additiveVitaminDExpiry)
            : null,
          boostToradolKeterolacExpiry: data.boostToradolKeterolacExpiry
            ? moment(data.boostToradolKeterolacExpiry)
            : null,
          baseFluidNS500Expiry: data.baseFluidNS500Expiry
            ? moment(data.baseFluidNS500Expiry)
            : null,
          additiveAscorbicAcidExpiry: data.additiveAscorbicAcidExpiry
            ? moment(data.additiveAscorbicAcidExpiry)
            : null,
          additiveVitaComplexExpiry: data.additiveVitaComplexExpiry
            ? moment(data.additiveVitaComplexExpiry)
            : null,
          additiveGlutathioneExpiry: data.additiveGlutathioneExpiry
            ? moment(data.additiveGlutathioneExpiry)
            : null,
          additiveZincSulphateExpiry: data.additiveZincSulphateExpiry
            ? moment(data.additiveZincSulphateExpiry)
            : null,
          boostB12Expiry: data.boostB12Expiry
            ? moment(data.boostB12Expiry)
            : null,
          boostGlutathioneExpiry: data.boostGlutathioneExpiry
            ? moment(data.boostGlutathioneExpiry)
            : null,
          boostLipoMinoExpiry: data.boostLipoMinoExpiry
            ? moment(data.boostLipoMinoExpiry)
            : null,
          boostLipoPlusExpiry: data.boostLipoPlusExpiry
            ? moment(data.boostLipoPlusExpiry)
            : null,
          boostTriImmuneExpiry: data.boostTriImmuneExpiry
            ? moment(data.boostTriImmuneExpiry)
            : null,
          boostVitaminDExpiry: data.boostVitaminDExpiry
            ? moment(data.boostVitaminDExpiry)
            : null,
          boostZincSulphateExpiry: data.boostZincSulphateExpiry
            ? moment(data.boostZincSulphateExpiry)
            : null,
          boostNS500Expiry: data.boostNS500Expiry
            ? moment(data.boostNS500Expiry)
            : null,
          boostOndansetronZofranExpiry: data.boostOndansetronZofranExpiry
            ? moment(data.boostOndansetronZofranExpiry)
            : null,
          nicontinamideAdenineDinculceotideExpiry:
            data.nicontinamideAdenineDinculceotideExpiry
              ? moment(data.nicontinamideAdenineDinculceotideExpiry)
              : null,
          additiveDiluentExpiry: data.additiveDiluentExpiry
            ? moment(data.additiveDiluentExpiry)
            : null,
        });
        setSignatureValue(data.signature);
        setSignatureValue1(chart[0].physicianSignature);
      } else {
        setChart(chart);
      }
    }
    const notes = await getChartNotes(chart[0].chartId);
    setChartNotes(notes);
    dispatch(setLoading(false));
  }, []);

  const [signature, getSignatureValue, clearSignatureValue, setSignatureValue] =
    useSignature("intakeForm", form);
  const [
    signature1,
    getSignatureValue1,
    clearSignatureValue1,
    setSignatureValue1,
  ] = useSignature("intakeForm1", form, "signature1");
  const [formChecked, setFormChecked] = useState({
    nameChecked: false,
    dobChecked: false,
    injectionChecked: false,
    historyReviewedChecked: false,
    contraindicationsChecked: false,
    consentsSignedChecked: false,
    allergieReviewedChecked: false,
    meetSanitationPolicyChecked: false,
    baseFluidNS500Checked: false,
    AdditiveMyersCocktailChecked: false,
    boostB12Checked: false,
    boostB12HydroxocobalaminChecked: false,
    boostGlutathioneChecked: false,
    boostLipoMinoChecked: false,
    boostLipoPlus: false,
    boostToradolKeterolacChecked: false,
    boostTriImmuneChecked: false,
    boostVitaminDChecked: false,
    boostZincSulphateChecked: false,
    boostOndansetronZofranChecked: false,
    boostNS500Checked: false,
    attempt1Checked: false,
    attempt2Checked: false,
    instructionsReviewedChecked: false,
    vitalsChartedChecked: false,
    vitalStableChecked: false,
    removeIVandCleanChecked: false,
    complicationsNoted: false,
    alertAndOrientedChecked: false,
  });
  return (
    <Form
      initialValues={{}}
      form={form}
      layout="vertical"
      className=""
      onFinishFailed={({ values, errorFields, outOfDate }) => {
        console.log(form.getFieldValue("injectionChecked"));
        form.scrollToField(errorFields[0].name, {
          scrollMode: "if-needed",
          block: "center",
          behavior: "smooth",
        });
      }}
      onFinish={async (values) => {
        values.signature = getSignatureValue();
        const data = {
          orderId: window.location.pathname.split("/")[3],
          orderLineItemId: window.location.pathname.split("/")[5],
          chart: JSON.stringify(values),
        };
        if (
          currentStaff?.staffId === chart[0]?.staffId &&
          !chart[0].staffSignature
        ) {
          if (getSignatureValue()) {
            data.staffSignature = getSignatureValue();
          } else {
            return openNotificationWithIcon(
              "warning",
              "Signature Required",
              "Please sign the chart before submitting"
            );
          }
        } else if (currentStaff?.staffId === chart[0]?.staffPhysicianId) {
          if (getSignatureValue1()) {
            data.physicianSignature = getSignatureValue1();
          } else {
            return openNotificationWithIcon(
              "warning",
              "Signature Required",
              "Please sign the chart before submitting"
            );
          }
        }
        dispatch(setLoading(true));
        if (chart.length) {
          await dispatch(updateChart(data, chart[0].chartId));
        } else {
          await dispatch(submitChart(data));
        }
        dispatch(setLoading(false));
        setTimeout(() => {
          window.close();
        }, 3000);
      }}
    >
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Guest Information:
      </p>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"nameChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({ ...formChecked, nameChecked: e.target.checked })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.nameChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="name"
            label="Confirmed Name?"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"dobChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({ ...formChecked, dobChecked: e.target.checked })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.dobChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="dob"
            label="Confirmed DOB?"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"injectionChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                injectionChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.injectionChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="infusion"
            label="Date of Injection?"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"historyReviewedChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                historyReviewedChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.historyReviewedChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="historyReviewed"
            label="History and Physical Reviewed? Changes (document in box if yes)?"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"contraindicationsChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                contraindicationsChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.contraindicationsChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="contraindications"
            label="No Contraindications?"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"consentsSignedChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                consentsSignedChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.consentsSignedChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="consentsSigned"
            label="Consents and Waivers Signed?"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"allergieReviewedChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                allergieReviewedChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.allergieReviewedChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="allergieReviewed"
            label="Allergies Reviewed?"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"meetSanitationPolicyChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                meetSanitationPolicyChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.meetSanitationPolicyChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="meetSanitationPolicy"
            label="Environment meets sanitation policy?"
            placeholder="Enter here..."
          />
        </Col>
      </Row>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "2px solid #EFF1F4",
          padding: "20px 0px",
          marginBottom: 20,
        }}
      >
        <h1
          style={{
            fontFamily: "tradeGothic",
            fontWeight: "bold",
            fontSize: 20,
            color: "#000",
          }}
        >
          IM Injection ONLY
        </h1>
      </div>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Document in notes the following: Dose, Route, Lot# &amp; Expiration
        Date*
      </p>

      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"boostB12Checked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                boostB12Checked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={false}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="boostB12"
            label="B-12 (methylcobalamin): IM- 1 ml (no more than 1x/week)"
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"boostB12HydroxocobalaminChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                boostB12HydroxocobalaminChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={false}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="boostB12Hydroxocobalamin"
            label="B-12 (hydroxocobalamin): 1 ml. IM (no more than 1x/week)"
          />
        </Col>
      </Row>

      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"boostGlutathioneChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                boostGlutathioneChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={false}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="boostGlutathione"
            label="Glutathione:  1 ml. IM (no more than 1x/week)"
          />
        </Col>
      </Row>

      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"boostLipoMinoChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                boostLipoMinoChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={false}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="boostLipoMino"
            label="Lipo-Mino: 1ml IM (no more than 3x/week)"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row gutter={[36, 0]} style={{ marginBottom: 4 }}>
        <Col xs={24} md={6}>
          <FormDatePicker
            required={formChecked.boostLipoMinoChecked}
            styles={{
              width: "100%",
              fontSize: 16,
              height: 49,
              display: "flex",
              alignItems: "center",
              border: "1px solid #ddd",
              borderRadius: 4,
            }}
            name="boostLipoMinoExpiry"
            label="Expiration Date"
            form={form}
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            required={formChecked.boostLipoMinoChecked}
            name="boostLipoMinoLotNumber"
            label="Lot Number"
            placeholder=""
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            required={formChecked.boostLipoMinoChecked}
            name="boostLipoMinoRoute"
            label="Route"
            placeholder=""
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInputNumber
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={formChecked.boostLipoMinoChecked}
            name="boostLipoMinoDose"
            label="Dose (mL)"
            placeholder="Dose (mL)"
          />
        </Col>
      </Row>

      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"boostLipoPlusChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                boostLipoPlusChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={false}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="boostLipoMino"
            label="Lipo- Plus: 1ml IM (no more than 3x/week)"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row gutter={[36, 0]} style={{ marginBottom: 4 }}>
        <Col xs={24} md={6}>
          <FormDatePicker
            required={formChecked.boostLipoPlusChecked}
            styles={{
              width: "100%",
              fontSize: 16,
              height: 49,
              display: "flex",
              alignItems: "center",
              border: "1px solid #ddd",
              borderRadius: 4,
            }}
            name="boostLipoPlusExpiry"
            label="Expiration Date"
            form={form}
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            required={formChecked.boostLipoPlusChecked}
            name="boostLipoPlusLotNumber"
            label="Lot Number"
            placeholder=""
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            required={formChecked.boostLipoPlusChecked}
            name="boostLipoPlusRoute"
            label="Route"
            placeholder=""
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInputNumber
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={formChecked.boostLipoPlusChecked}
            name="boostLipoPlusDose"
            label="Dose (mL)"
            placeholder="Dose (mL)"
          />
        </Col>
      </Row>

      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"boostTriImmuneChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                boostTriImmuneChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={false}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="boostTriImmune"
            label="Tri-Immune Boost (Glutathione, ascorbic Acid, Zinc Sulfate): IV- 3 ml (slow push over 5 minutes longer if not tolerated by guest; no more than 3x/week) IM- 1 ml (no more than 1x/week)"
          />
        </Col>
      </Row>

      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"boostVitaminDChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                boostVitaminDChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={false}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="boostVitaminD"
            label="Vitamin D (empower 100,000iu/ml): 0.5 ml IM (no more than 1x/week)"
          />
        </Col>
      </Row>

      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Can only give one Lipo injection, do not give Glutathione and Tri Immune
        Boost at same time; no B12 if giving lipo mino
      </p>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "2px solid #EFF1F4",
          padding: "20px 0px",
          marginBottom: 20,
        }}
      >
        <h1
          style={{
            fontFamily: "tradeGothic",
            fontWeight: "bold",
            fontSize: 20,
            color: "#000",
          }}
        >
          Contraindications &amp; Precautions
        </h1>
      </div>

      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        &#8226; Renal Disease
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        &#8226; Kidney Disease
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        &#8226; Liver Disease
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        &#8226; Congestive Heart Failure
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        &#8226; Pregnant/Breastfeeding
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        &#8226; Glutathione: Do not use in asthma patients
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        &#8226; Methyl (B12): Do not use if current ongoing infection, low iron;
        taking PPI, alcohol, metformin. Avoid grapefruit consumption
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        &#8226; Vitamin D: Do not use if high calcium/phos blood levels,
        arteriosclerosis, kidney stones; currently taking anticonvulsants,
        lipitor, dovonex, digoxin, cardizem, tiazac, thiazide diuretics,
        steroids, stimulant laxatives.
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        &#8226; DO NOT GIVE LIPO INJECTIONS IF SULFA ALLERGY
      </p>

      <FormSelect
        feedBack={false}
        variant="underlined"
        disabled={chart[0]?.staffSignature}
        name="IMNeedleGuage"
        label="IM Needle Gauge"
        initialValue={null}
        renderValue="value"
        placeholder={"Select an option"}
        renderLabel="label"
        styles={{ border: "none" }}
        className={"multiSelect"}
        options={[
          { name: "25 Gauge" },
          { name: "27 Gauge" },
          { name: "Other" },
          { name: "N/A" },
        ].map((item) => ({
          key: item.name,
          label: item.name,
          value: item.name,
        }))}
      />
      <FormSelect
        feedBack={false}
        variant="underlined"
        disabled={chart[0]?.staffSignature}
        name="IMNeedleLength"
        label="IM Needle Length"
        initialValue={null}
        renderValue="value"
        placeholder={"Select an option"}
        renderLabel="label"
        styles={{ border: "none" }}
        className={"multiSelect"}
        options={[
          { name: "1 inch" },
          { name: "1.25 inch" },
          { name: "1.5 inch" },
          { name: "Other" },
          { name: "N/A" },
        ].map((item) => ({
          key: item.name,
          label: item.name,
          value: item.name,
        }))}
      />
      <FormSelect
        feedBack={false}
        variant="underlined"
        disabled={chart[0]?.staffSignature}
        name="IMLocation"
        label="IM Location"
        initialValue={null}
        renderValue="value"
        placeholder={"Select an option"}
        renderLabel="label"
        styles={{ border: "none" }}
        className={"multiSelect"}
        options={[
          { name: "Left Deltoid" },
          { name: "Right Deltoid" },
          { name: "Upper left quadrant of left dorsogluteal" },
          { name: "Upper right quadrant of right dorsogluteal" },
          { name: "Other location" },
          { name: "N/A" },
        ].map((item) => ({
          key: item.name,
          label: item.name,
          value: item.name,
        }))}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "2px solid #EFF1F4",
          padding: "20px 0px",
          marginBottom: 20,
        }}
      >
        <h1
          style={{
            fontFamily: "tradeGothic",
            fontWeight: "bold",
            fontSize: 20,
            color: "#000",
          }}
        >
          Discharge
        </h1>
      </div>

      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Check the following*
      </p>

      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"complicationsNoted"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                complicationsNoted: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.complicationsNoted}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="complications"
            label="No complications noted"
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"instructionsReviewedChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                instructionsReviewedChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.instructionsReviewedChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="instructionsReviewed"
            label="Discharge instructions reviewed"
          />
        </Col>
      </Row>

      <p
        style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}
      ></p>

      <p
        style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}
      ></p>

      <div
        style={{
          borderBottom: "2px solid #EFF1F4",
          padding: "20px 0px",
          marginBottom: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "2px solid #EFF1F4",
            padding: "20px 0px",
            marginBottom: 20,
          }}
        >
          <h1
            style={{
              fontFamily: "tradeGothic",
              fontWeight: "bold",
              fontSize: 20,
              color: "#000",
            }}
          >
            Notes
          </h1>
        </div>
        <div>
          <div>
            {chartNotes.map((note) => (
              <div style={{ marginBottom: 16 }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p
                    style={{
                      fontFamily: "tradeGothic",
                      fontSize: 18,
                      marginBottom: 0,
                    }}
                  >
                    {note.noteDescription}
                  </p>
                  <span>
                    Created on{" "}
                    {moment
                      .utc(note.createdOn)
                      .local()
                      .format("D MMM YYYY h:mm A")}
                  </span>
                </div>
                (Created By: {note.userName})
              </div>
            ))}
          </div>
          <FormInputArea
            name={"noteDescription"}
            label={"Note"}
            disabled={true}
          />
          <Button
            rounded={true}
            disabled={true}
            onClick={async () => {
              const noteDescription = form.getFieldValue("noteDescription");
              if (
                noteDescription === undefined ||
                noteDescription?.trim() === ""
              ) {
                openNotificationWithIcon(
                  "error",
                  "Error",
                  "Please enter a note description"
                );
                return;
              }
              dispatch(setLoading(true));
              await addChartNotes(chart[0].chartId, { noteDescription });
              const notes = await getChartNotes(chart[0].chartId);
              setChartNotes(notes);
              form.setFieldsValue({ noteDescription: "" });
              dispatch(setLoading(false));
            }}
          >
            Add Note
          </Button>
        </div>
      </div>

      <h1
        style={{
          fontFamily: "tradeGothic",
          fontWeight: "bold",
          fontSize: 20,
          color: "#000",
          marginTop: 28,
        }}
      >
        Clinician Signature
      </h1>
      <Signature name="sigature" signature={signature} disabled={true} />

      <h1
        style={{
          fontFamily: "tradeGothic",
          fontWeight: "bold",
          fontSize: 20,
          color: "#000",
          marginTop: 28,
        }}
      >
        Nurse Practitioner/Physician Signature
      </h1>
      <Signature name="signature1" signature={signature1} disabled={true} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          maxWidth: 367,
          paddingBottom: 20,
        }}
      >
        <Button
          rounded={true}
          style={{ marginRight: 20 }}
          disabled={true}
          onClick={async () => {
            const values = form.getFieldsValue(true);
            // form.getFieldsValue(async (values) => {
            console.log(values);
            dispatch(setLoading(true));
            values.signature = getSignatureValue();
            const data = {
              orderId: window.location.pathname.split("/")[3],
              orderLineItemId: window.location.pathname.split("/")[5],
              chart: JSON.stringify(values),
            };
            if (chart.length) {
              await dispatch(updateChart(data, chart[0].chartId));
            } else {
              await dispatch(submitChart(data));
            }
            dispatch(setLoading(false));
          }}
        >
          Save
        </Button>
        <Button rounded={true} htmlType="submit" style={{}} disabled={true}>
          Save & Submit
        </Button>
      </div>
    </Form>
  );
}

export default function IMInjection() {
  return (
    <div style={{ overflow: "auto", height: "90vh", padding: 24 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "2px solid #EFF1F4",
          padding: "20px 0px",
          marginBottom: 20,
        }}
      >
        <h1
          style={{
            fontFamily: "tradeGothic",
            fontWeight: "bold",
            fontSize: 24,
            color: "#000",
          }}
        >
          IM Injection Only
        </h1>
      </div>
      <HistoryIntakeForm />
    </div>
  );
}
