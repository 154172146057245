import React from "react";
import "./NurseSelector.scss";

import { Avatar, Select, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { UserOutlined } from "@ant-design/icons";
import { setCurrentStaffIds } from "redux/actions/calendar.actions";
import { FormSelect } from "components/FormItems/FlatFormItems";
const { Option } = Select;

export default function NurseSelector({ onChange }) {
  const { allStaff, currentStaff } = useSelector((state) => ({
    allStaff: state.user.allStaff,
    currentStaff: state.calendar.currentStaff,
  }));

  const dispatch = useDispatch();

  return (
    <FormSelect
      formItemStyles={{
        width: "100%",
      }}
      value={currentStaff}
      onChange={(value)=>{
        dispatch(setCurrentStaffIds(value))
      }}
      // disabled={isAppointmentFormDisabled}
      variant="underlined"
      label="Clinician"
      placeholder="Clinician"
      // required={true}
      // loading={isFetchingAddons}
      options={allStaff}
      renderValue="staffId"
      renderLabel="name"
      mode="multiple"
      optionLabelProp="label"
      filterOption={(input, option) => {
        return option?.label?.toLowerCase?.().includes?.(input.toLowerCase());
      }}
      renderOption={(opt) => {
        return opt["name"];
      }}
    />
  );
}
