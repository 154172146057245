import { MoreOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Modal, Space, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentDistributor,
  setCurrentStaff,
} from "../../redux/actions/user.actions";
import { setLoading } from "../../redux/actions/app.actions";

import RolesDrawer from "./RolesDrawer";

import { push } from "connected-react-router";
import { Button } from "../../components/Buttons/Buttons";
import {
  addNewRole,
  deleteRole,
  getAllPermissions,
  getAllRoles,
  updateRole,
} from "services/roles.services";
import { setCurrentRole } from "redux/actions/roles.actions";

const { Text } = Typography;

const Menue = (record, dispatch, setModalVisible, onDeleteHandler) => (
  <Menu style={{ borderRadius: 6, border: "2px solid #CDD5D8 " }}>
    <Menu.Item
      onClick={() => {
        dispatch(setCurrentRole(record));

        setModalVisible(true);
        // console.log(record)
      }}
    >
      Edit
    </Menu.Item>
    <Menu.Item
      onClick={() => {
        // dispatch(setCurrentRole(record))
        Modal.confirm({
          title: "Confirm",
          okText: "Ok",
          cancelText: "Cancel",
          content: "Are you sure you want to delete this role?",
          onOk: () => onDeleteHandler(record.roleId),
        });
        // console.log(record)
      }}
    >
      Delete
    </Menu.Item>
  </Menu>
);

const Roles = () => {
  const dispatch = useDispatch();

  const [modalVisible, setModalVisible] = useState(false);

  const allRoles = useSelector((state) => state.roles.allRoles);
  const allPermissions = useSelector((state) => state.roles.allPermissions);
  const currentRole = useSelector((state) => state.roles.currentRole);

  const getData = async () => {
    dispatch(setLoading(true));
    await dispatch(getAllRoles());
    await dispatch(getAllPermissions());
    dispatch(setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []); //eslint-disable-line

  const onAddHandler = async (values) => {
    dispatch(setLoading(true));
    await dispatch(addNewRole({ ...values }, allRoles));
    await getData();
    setModalVisible(false);
    dispatch(setLoading(false));
    dispatch(setCurrentRole(null));
  };

  const onUpdateHandler = async (values) => {
    dispatch(setLoading(true));
    await dispatch(updateRole(currentRole?.roleId, { ...values }, allRoles));
    await getData();
    setModalVisible(false);
    dispatch(setLoading(false));
    dispatch(setCurrentRole(null));
  };

  const onDeleteHandler = async (id) => {
    dispatch(setLoading(true));
    await dispatch(deleteRole(id, allRoles));
    getData();
    dispatch(setLoading(false));
    dispatch(setCurrentRole(null));
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "roleName",
      key: "name",
      // render: (text, record) => (
      //   <div style={{ display: 'flex', alignItems: 'center', }}>
      //     <div style={{ height: 40, width: 40, borderRadius: 20, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      //       <img src='https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80' style={{ width: '100%', }} />
      //     </div>
      //     <p style={{ margin: 0, marginLeft: 15, fontFamily: 'tradeGothic', fontSize: 16 }}> {`${record.firstName}  ${record.lastName}`}</p>
      //   </div>
      // )
    },
    {
      title: "Permissions",
      dataIndex: "permissions",
      key: "permissions",
      render: (text) =>
        text.map((p, index) => (
          <span style={{ margin: "0px 3px" }}>
            {p.permissionName}
            {index < text?.length - 1 && ","}
          </span>
        )),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Text> {record?.isActive ? "Active" : "Inactive"} </Text>
      ),
    },

    {
      title: "Action",
      key: "action",
      align: "right",
      width: 180,
      render: (text, record) => (
        <Dropdown
          overlay={() =>
            Menue(record, dispatch, setModalVisible, onDeleteHandler)
          }
          trigger={["click"]}
          getPopupContainer={(triggerNode) => triggerNode?.parentNode}
        >
          <a onClick={(e) => e.preventDefault()}>
            <MoreOutlined style={{ fontSize: 18 }} />
          </a>
        </Dropdown>
      ),
    },
  ];

  return (
    <div>
      <RolesDrawer
        visible={modalVisible}
        onClose={() => {
          setModalVisible(false);
          dispatch(setCurrentRole(null));
        }}
        onAddHandler={onAddHandler}
        onUpdateHandler={onUpdateHandler}
      />
      <div
        style={{
          height: 80,
          background: "#e5e8ee",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2
          style={{ color: "#15213B", fontFamily: "tradeGothicBold" }}
        >{`Roles (${allRoles?.length})`}</h2>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <p style={{ fontSize: 18, textDecoration: 'underline', margin: 0, marginRight:24, cursor:'pointer' }} onClick={()=>dispatch(push('/dashboard/roles'))}>Manage Roles</p> */}
          <Button
            variant="filled"
            type="primary"
            style={{ height: 50, fontSize: 18 }}
            rounded
            onClick={() => setModalVisible(true)}
          >
            Add New Role
          </Button>
        </div>
      </div>
      <div style={{ overflow: "auto" }}>
        <div style={{ overflow: "auto", height: "70vh" }}>
          <Table
            rowKey={(record) => record.roleId}
            locale={{ emptyText: "No Roles" }}
            dataSource={allRoles}
            columns={columns}
            pagination={false}
          />
        </div>
      </div>
    </div>
  );
};

export default Roles;
