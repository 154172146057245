import { actions } from "../actions/booking.actions";
import { cloneDeep } from "lodash";

const INITIAL_STATE = {
  allPendingBookings: [],
  monthlyBookings: [],
  weeklyBookings: [],
  dailyBookings: [],
  currentBooking: null,
};

const reducer = (state = INITIAL_STATE, data) => {
  switch (data.type) {
    case actions.SET_ALL_PENDING_BOOKINGS:
      const pendingBookingFound = data?.data?.find(
        (booking) => booking.orderId === state?.currentBooking?.orderId
      );
      return {
        ...state,
        allPendingBookings: data.data,
        currentBooking: pendingBookingFound
          ? { ...state.currentBooking, ...pendingBookingFound }
          : state.currentBooking,
      };
    // TODO: set currentBooking
    case actions.SET_MONTHLY_BOOKINGS:
      return { ...state, monthlyBookings: data.data };
    case actions.SET_WEEKLY_BOOKINGS:
      return { ...state, weeklyBookings: data.data };
    case actions.SET_DAILY_BOOKINGS:
      const dailyBookingFound = data?.data?.find(
        (booking) => booking.orderId === state?.currentBooking?.orderId
      );
      return {
        ...state,
        dailyBookings: data.data,
        currentBooking: dailyBookingFound
          ? { ...state.currentBooking, ...dailyBookingFound }
          : state.currentBooking,
      };
    case actions.SET_CURRENT_BOOKING:
      return { ...state, currentBooking: data.data };
    case actions.ASSIGN_NURSE_TO_BOOKING:
      const { booking, staffId } = data.data;
      const booking_updated = cloneDeep(booking);
      booking_updated.staffId = staffId;
      booking_updated.resourceId = staffId;

      const isCurrentBooking =
        booking.orderId === state.currentBooking?.orderId;

      return {
        ...state,
        allPendingBookings: state.allPendingBookings.filter(
          (booking) => booking.orderId !== booking_updated.orderId
        ),
        monthlyBookings: [...state.monthlyBookings, booking_updated],
        weeklyBookings: [...state.weeklyBookings, booking_updated],
        dailyBookings: [...state.dailyBookings, booking_updated],
        currentBooking: isCurrentBooking
          ? {
              ...state.currentBooking,
              ...booking_updated,
            }
          : state.currentBooking,
      };
    case actions.UPDATE_BOOKING:
      const updatedBooking = data.data;
      const isCurrentBookingUpdate =
        updatedBooking?.orderId === state.currentBooking?.orderId;
      return {
        ...state,
        currentBooking: isCurrentBookingUpdate
          ? {
              ...state.currentBooking,
              ...updatedBooking,
            }
          : state.currentBooking,
        allPendingBookings: state.allPendingBookings
          .map((booking) => {
            if (booking.orderId === data.data.orderId) {
              return data.data;
            }
            return booking;
          })
          .filter((booking) => booking.orderStatus === 1),
        monthlyBookings: state.monthlyBookings.map((booking) => {
          if (booking.orderId === data.data.orderId) {
            return data.data;
          }
          return booking;
        }),
        weeklyBookings: state.weeklyBookings.map((booking) => {
          if (booking.orderId === data.data.orderId) {
            return data.data;
          }
          return booking;
        }),
        dailyBookings: state.dailyBookings.map((booking) => {
          if (booking.orderId === data.data.orderId) {
            return data.data;
          }
          return booking;
        }),
      };
    case actions.ADD_BOOKING:
      const newBooking = data?.data ?? {};
      newBooking.resourceId = newBooking.staffId;
      return {
        ...state,
        allPendingBookings: data?.data?.staffId
          ? state.allPendingBookings
          : [...state.allPendingBookings, newBooking],
        monthlyBookings: [...state.monthlyBookings, newBooking],
        weeklyBookings: [...state.weeklyBookings, newBooking],
        dailyBookings: [...state.dailyBookings, newBooking],
      };
    case actions.allPendingBookings:
      return {
        ...state,
        allPendingBookings:
          data?.data?.staffId !== undefined
            ? [...state.allPendingBookings, data.data]
            : state.allPendingBookings,
        monthlyBookings: [...state.monthlyBookings, data.data],
        weeklyBookings: [...state.weeklyBookings, data.data],
        dailyBookings: [...state.dailyBookings, data.data],
      };
    case "RESET":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
