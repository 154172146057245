import React, { useEffect, useRef, useState } from "react";
import { Col, Drawer, Form, Row, Button as AntdButton } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import {
  FormInput,
  FormHiddenSubmitButton,
  FormSelect,
} from "components/FormItems/FlatFormItems";
import { useForm } from "antd/lib/form/Form";
import { Button } from "components/Buttons/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { getSubclassById } from "services/quickbook.service";

export default function SubclassAddEditDrawer({
  data,
  visible,
  onClose,
  onSuccess,
  classes = [],
}) {
  const [form] = useForm();
  const submitBtnRef = useRef();
  const isEdit = data?.classId !== undefined;

  const dispatch = useDispatch();

  const [completeData, setCompleteData] = useState({});
  const { allFacilities } = useSelector((state) => ({
    allFacilities: state.facilities.allFacilities,
  }));

  const getData = async (id) => {
    const result = await dispatch(getSubclassById(id));
    if (result?.status === "success") {
      setCompleteData(result.data);
      form.setFieldsValue({
        ...result.data,
      });
    }
  };

  useEffect(() => {
    if (visible === false) {
      setCompleteData({});
      form.resetFields();
    } else if (isEdit) {
      form.setFieldsValue({
        ...data,
      });
      getData(data?.classId);
    }
  }, [visible]);

  return (
    <Drawer
      destroyOnClose
      headerStyle={{ border: "none" }}
      className="integrationDrawer"
      width={"min(100vw, 420px)"}
      title={
        <div className="integrationDrawerTitle">
          <p>{visible && (isEdit ? "Edit" : "Create")} Subclass</p>
          <CloseOutlined onClick={onClose} />
        </div>
      }
      placement="right"
      onClose={onClose}
      visible={visible}
      closable={false}
      footer={
        <div className="integrationDrawerFooter">
          <AntdButton type="text" onClick={onClose}>
            Cancel
          </AntdButton>
          <Button
            rounded
            type={"primary"}
            style={{ height: 50, width: "50%" }}
            onClick={() => submitBtnRef?.current?.click()}
          >
            Save
          </Button>
        </div>
      }
    >
      <Form
        preserve={false}
        layout="vertical"
        form={form}
        requiredMark={false}
        onFinish={(values) => {
          onSuccess({
            classTypeId: 2,
            ...completeData,
            ...values,
          });
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
      >
        <Row gutter={[16, 16]}>
          {!!isEdit && (
            <Col xs={24}>
              <FormInput
                disabled={isEdit}
                name="referenceId"
                variant="underlined"
                label="Reference ID"
                required
                placeholder="Reference ID"
                formItemStyles={{
                  marginBottom: 0,
                }}
              />
            </Col>
          )}
          <Col xs={24}>
            <FormInput
              name="className"
              variant="underlined"
              label="Subclass Name"
              required
              placeholder="Subclass Name"
              formItemStyles={{
                marginBottom: 0,
              }}
            />
          </Col>
          <Col xs={24}>
            <FormInput
              name="fullyQualifiedName"
              variant="underlined"
              label="Fully Qualified Class Name"
              required
              placeholder="Fully Qualified Subclass Name"
              formItemStyles={{
                marginBottom: 0,
              }}
            />
          </Col>
          <Col xs={24}>
            <FormSelect
              name="parentClassId"
              variant="underlined"
              label="Parent Class"
              required
              placeholder="Parent Class"
              renderValue="classId"
              renderLabel="className"
              options={classes?.filter((it) => it?.isActive)}
              formItemStyles={{
                marginBottom: 0,
              }}
            />
          </Col>
          <Col xs={24}>
            <FormSelect
              allowClear
              // mode={"multiple"}
              // feedBack={false}
              variant="underlined"
              name="facilityId"
              label="Facilities"
              renderValue="facilityId"
              placeholder={"Select Facilities"}
              renderLabel="facilityName"
              styles={{ border: "none" }}
              // formItemStyles={{ marginBottom: 12 }}
              options={allFacilities}
              // styles={{ background: '#ddd' }}
              disabled={data?.classId}
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <FormHiddenSubmitButton submitRef={submitBtnRef} />
        </Row>
      </Form>
    </Drawer>
  );
}
