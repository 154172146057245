// const api = {
//   url: 'http://localhost:4000/api/',
//   // url: 'https://api-test.bpays101.com/api/'
// };

// export default api

const getEnv = () => {
  const appEnv = window.location.host.split(".")[0];
  switch (appEnv) {
    case "white-bush-0aac68910":
      return "test";
    case "zealous-beach-0b3172210":
      return "uat";
    case "lemon-beach-0e1dc1610":
      return "prod2";
    case "ashy-sand-0af3e9a10":
      return "predev";
    case "admin":
      return "prod";
    default:
      return "local";
  }
};

const apiUrl = () => {
  switch (getEnv()) {
    case "uat":
      return "https://uat-liquidmobileiv-backoffice-api.azurewebsites.net/api/";
    case "prod":
      return "https://prod-liquidmobileiv-backoffice-api.azurewebsites.net/api/";
    case "prod2":
      return "https://prod-liquidmobileiv-backoffice-api.azurewebsites.net/api/";
    case "predev":
      return "https://pre-dev-liquidmobile-backoffice-api.azurewebsites.net/api/";
    default:
      return "https://dev-backoffice-liquidmobile-web.azurewebsites.net/api/";
    // return 'http://localhost:4000/api/';
  }
};

const api = {
  url: apiUrl(),
};

export default api;
