import {
  DatePicker,
  Empty,
  Form,
  Menu,
  Select,
  Space,
  Grid,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentStaff,
} from "../../redux/actions/user.actions";
import { setLoading } from "../../redux/actions/app.actions";
import { ReactComponent as ChevronLeft } from "assets/icons/chevronLeftIco.svg";
import { ReactComponent as ChevronRight } from "assets/icons/chevronRightIco.svg";

import {
  getAllStaff,
} from "../../services/staffs.services";
import moment from "moment-timezone";
import AppointmentDrawer from "containers/ResourceScheduler/components/AppointmentDrawer";
import GuestDrawer from "containers/ResourceScheduler/components/GuestDrawer";
import { findWindows } from "windows-iana";
import EventDrawer from "components/EventDrawer/EventDrawer";
import {
  assignNursePractitioner,
  getChartsByStatus,
} from "services/charts.services";
import { openChartURL } from "utils/common";
const { Option } = Select;
const { useBreakpoint } = Grid;

const dataSource = [
  {
    orderId: 618,
    serviceAddress: "R-299 sector 10 north karachi",
    voucherCode: null,
    voucherId: 1,
    orderStatus: "Pending",
    totalBill: 0,
    date: "2022-07-05T07:12:02.163745+00:00",
    serviceDate: "2022-07-19T19:00:00",
    facilityId: 1,
    operationTypeId: 1,
    staffId: null,
    lineItems: [
      {
        lineItemId: 2065,
        productId: 9,
        customerProfileId: 156,
        sellingPrice: 1,
        productName: "Covid Testing",
        addOns: [],
      },
    ],
  },
];
const OrderStatus = {
  Pending: 1,
  Cancel: 2,
  Completed: 3,
  "No Show": 4,
  Arrived: 5,
};

const Menue = (record, dispatch, setModalVisible) => (
  <Menu style={{ borderRadius: 6, border: "2px solid #CDD5D8 " }}>
    <Menu.Item
      onClick={() => {
        dispatch(setCurrentStaff(record));

        setModalVisible(true);
        // console.log(record)
      }}
    >
      s Edit
    </Menu.Item>
  </Menu>
);
let bgColor = "";
let tooltip = "";

const Charts = ({ date: propsDate }) => {
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const [form] = Form.useForm();

  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(moment());
  const [modalVisible, setModalVisible] = useState(false);

  // const [showAppointmentDrawer, setAppointmentDrawer] = useState(false);
  // const [showGuestDrawer, setshowGuestDrawer] = useState(false);
  const [sorting, setSorting] = useState("all");

  const allStaffs = useSelector((state) => state.user.wholeStaff).filter(
    (staff) => {
      if (
        staff.roleName === "Nurse Practitioner" ||
        staff.roleName === "Physician" ||
        staff.roleName === "Nurse Lead"
      )
        return true;
      return false;
    }
  );
  const allCharts = useSelector((state) => state.charts.allCharts);
  const currentStaff = useSelector((state) => state.user.currentUser);
  const currentOrder = useSelector((state) => state.orders.currentOrder);

  const getAllPractitioners = async () => {
    dispatch(setLoading(true));
    await dispatch(getAllStaff(null, []));
    dispatch(setLoading(false));
  };

  useEffect(() => {
    if (currentStaff?.staffId) getData();
  }, [date, currentStaff]);

  const getData = async () => {
    const windowsTimezone = findWindows(moment.tz.guess());
    const roleName = currentStaff.roleName?.trim?.() || "";
    dispatch(setLoading(true));
    await dispatch(
      getChartsByStatus("Pending", {
        serviceDate: moment.utc(date).local().format("YYYY-MM-DD"),
        staffId:
          currentStaff &&
          !(
            roleName === "Admin" ||
            roleName === "Nurse Practitioner" ||
            roleName === "Nurse Lead"
          )
            ? currentStaff.staffId
            : null,
        timezone: windowsTimezone?.[0],
      })
    );
    // await dispatch(getAllStaff(currentStaff?.facilityId, ['Nurse Practitioner']))
    dispatch(setLoading(false));
  };

  useEffect(() => {
    if (currentStaff) {
      getData();
      getAllPractitioners();
    }
  }, [currentStaff]); //eslint-disable-line

  const onNavigate = async (value) => {
    // if (value === "add") {
    //   const newDate = moment(date).add(1, "day");
    //   setDate(newDate);
    //   dispatch(setLoading(true));
    //   await dispatch(
    //     getChartsByStatus('Pending', {
    //       serviceDate: date.format(),
    //       staffId: (currentStaff && currentStaff.roleName !== 'Admin') ? currentStaff.staffId : null
    //     })
    //   );
    //   dispatch(setLoading(false));
    // } else {
    //   const newDate = moment(date).subtract(1, "day");
    //   setDate(newDate);
    //   dispatch(setLoading(true));
    //   await dispatch(
    //     getChartsByStatus('Pending', {
    //       serviceDate: date.format(),
    //       staffId: (currentStaff && currentStaff.roleName !== 'Admin') ? currentStaff.staffId : null
    //     })
    //   );
    //   dispatch(setLoading(false));
    // }
    if (value === "add") {
      const newDate = moment(date).add(1, "day");
      setDate(newDate);
    } else {
      const newDate = moment(date).subtract(1, "day");
      setDate(newDate);
    }
  };

  const isSmallDevice = !screens.md;

  const renderAppointments = () => {
    const currentStaffRoleName = currentStaff?.roleName?.trim?.() || "";
    if (allCharts.length) {
      let data = [...allCharts];
      if (sorting === "pending") {
        data = data.filter((chart) => chart.staffName && !chart.staffSignature);
      } else if (sorting === "signedOff") {
        data = data.filter((chart) => chart.staffName && chart.staffSignature);
      } else if (sorting === "newestToOldest") {
        data = data.sort(
          (a, b) => moment(b.serviceDate) - moment(a.serviceDate)
        );
      } else if (sorting === "oldestToNewest") {
        data = data.sort(
          (a, b) => moment(a.serviceDate) - moment(b.serviceDate)
        );
      } else if (sorting === "inClinic") {
        data = data.filter((chart) => chart.operationType.name === "Clinic");
      } else if (sorting === "mobile") {
        data = data.filter((chart) => chart.operationType.name === "Mobile");
      } else if (sorting === "all") {
        data = [...allCharts];
      }

      return data.map((chart, id) => {
        return (
          <div
            key={chart.chartId}
            style={{
              width: "100%",
              borderRadius: 4,
              boxShadow: "0px 10px 10px rgba(22, 31, 58, 0.08)",
              background: "#fff",
              margin: "12px 0px",
              padding: 16,
              cursor: "pointer",
              display: "flex",
              alignItems: isSmallDevice ? "flex-start" : "center",
              justifyContent: "space-between",
              flexDirection: isSmallDevice ? "column" : "row",
            }}
          >
            <div
              style={{
                borderRight: isSmallDevice ? "none" : "2px solid #E5E8EE",
                borderBottom: isSmallDevice ? "2px solid #E5E8EE" : "none",
                justifyContent: isSmallDevice ? "flex-start" : "center",
                width: isSmallDevice ? "100%" : "auto",
                height: isSmallDevice ? "auto" : "100%",
                padding: isSmallDevice ? "10px 0px 16px 0px" : 0,
                paddingLeft: isSmallDevice ? 0 : 8,

                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
              onClick={() => {
                openChartURL(chart.orderId, chart.orderLineItemId, {
                  isLegacy: chart.isLegacy,
                  productName: chart.legacyChartName,
                });
              }}
            >
              <p
                style={{
                  fontFamily: "tradeGothic",
                  fontSize: 20,
                  fontWeight: "bold",
                  margin: 0,
                }}
              >
                {chart.guestName}
              </p>
              <p style={{ fontFamily: "tradeGothic", fontSize: 16, margin: 0 }}>
                {chart.chartName}
              </p>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                height: "100%",
                padding: isSmallDevice ? "16px 0px" : 0,
                paddingLeft: isSmallDevice ? 0 : 20,
                borderRight: isSmallDevice ? "none" : "2px solid #E5E8EE",
                borderBottom: isSmallDevice ? "2px solid #E5E8EE" : "none",
                justifyContent: isSmallDevice ? "center" : "space-between",
                width: isSmallDevice ? "100%" : "auto",
              }}
              onClick={() => {
                openChartURL(chart.orderId, chart.orderLineItemId, {
                  isLegacy: chart.isLegacy,
                  productName: chart.legacyChartName,
                });
              }}
            >
              <div style={{ marginBottom: isSmallDevice ? 8 : 16 }}>
                <p
                  style={{ fontFamily: "tradeGothic", fontSize: 16, margin: 0 }}
                >
                  Clinician
                </p>
                <p
                  style={{
                    fontFamily: "tradeGothic",
                    fontSize: 20,
                    fontWeight: "bold",
                    margin: 0,
                  }}
                >
                  {chart.staffName}
                </p>
              </div>
              <div>
                <p
                  style={{
                    fontFamily: "tradeGothic",
                    fontSize: 16,
                    fontWeight: "bold",
                    margin: 0,
                  }}
                >
                  Status
                </p>
                <div style={{ display: "flex" }}>
                  <p
                    style={{
                      fontFamily: "tradeGothic",
                      fontSize: 16,
                      fontWeight: "bold",
                      margin: 0,
                      color: "#27CA2D",
                    }}
                  >
                    {chart.staffName
                      ? chart.staffSignature
                        ? "Signed off"
                        : "Pending"
                      : "Unassigned"}
                  </p>
                  <p
                    style={{
                      fontSize: 14,
                      textDecoration: "italic",
                      margin: 0,
                      marginLeft: 16,
                      fontFamily: "tradeGothic",
                    }}
                  >
                    {chart.staffSignedOffDate &&
                      moment
                        .utc(chart.staffSignedOffDate)
                        .local()
                        .format("ddd Do MMM YYYY hh:mm a")}
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                flex: "2 1 0%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: isSmallDevice ? "100%" : "auto",

                height: isSmallDevice ? "auto" : "100%",
                padding: isSmallDevice ? "16px 0px" : 0,
                paddingLeft: isSmallDevice ? 0 : 20,
              }}
            >
              <div>
                <p
                  style={{ fontFamily: "tradeGothic", fontSize: 16, margin: 0 }}
                >
                  NP/Physician
                </p>

                <Select
                  className="home_select"
                  style={{
                    margin: "8px 0px 16px 0px",
                    display: "flex",
                    alignItems: "center",
                    border: "2px solid #DBDFEA",
                    borderRadius: 4,
                    height: 46,
                    maxWidth: 380,
                  }}
                  // options={allStaffs.map(staff => ({ label: staff.name, value: staff.staffId }))}
                  placeholder={"Select"}
                  value={chart.staffPhysicianId ? chart.staffPhysicianId : null}
                  disabled={
                    !(
                      currentStaffRoleName === "Nurse Practitioner" ||
                      currentStaffRoleName === "Nurse Lead" ||
                      currentStaffRoleName === "Admin"
                    ) ||
                    // chart.staffPhysicianId ||
                    !chart.staffSignature
                  }
                  onChange={async (val) => {
                    try {
                      dispatch(setLoading(true));
                      await assignNursePractitioner(val, chart.chartId);
                      getData();
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  {allStaffs.map((staff) => (
                    <Option key={staff.staffId} value={staff.staffId}>
                      {staff.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                {/* <div>
                  <p
                    style={{
                      fontFamily: "tradeGothic",
                      fontSize: 16,
                      fontWeight: "bold",
                      margin: 0,
                    }}
                  >
                    Status
                  </p>
                  <div style={{ display: "flex" }}>
                    <p
                      style={{
                        fontFamily: "tradeGothic",
                        fontSize: 16,
                        fontWeight: "bold",
                        margin: 0,
                        color: "#27CA2D",
                      }}
                    >
                      {chart.staffName
                        ? chart.staffSignature
                          ? "Signed off"
                          : "Pending"
                        : "Unassigned"}
                    </p>
                    <p
                      style={{
                        fontSize: 14,
                        textDecoration: "italic",
                        margin: 0,
                        marginLeft: 16,
                        fontFamily: "tradeGothic",
                      }}
                    >
                      {chart.staffSignedOffDate &&
                        moment
                          .utc(chart.staffSignedOffDate)
                          .local()
                          .format("ddd Do MMM YYYY hh:mm a")}
                    </p>
                  </div>
                </div> */}
                <div>
                  <p
                    style={{
                      fontFamily: "tradeGothic",
                      fontSize: 16,
                      fontWeight: "bold",
                      margin: 0,
                    }}
                  >
                    Status
                  </p>
                  <div style={{ display: "flex" }}>
                    <p
                      style={{
                        fontFamily: "tradeGothic",
                        fontSize: 16,
                        fontWeight: "bold",
                        margin: 0,
                        color: "#8E94AA",
                      }}
                    >
                      {chart.staffPhysicianId
                        ? chart.physicianSignature
                          ? "Signed off"
                          : "Pending"
                        : "Unassigned"}
                    </p>
                    <p
                      style={{
                        fontSize: 14,
                        textDecoration: "italic",
                        margin: 0,
                        marginLeft: 16,
                        fontFamily: "tradeGothic",
                      }}
                    >
                      {chart.physicianSignedOffDate &&
                        moment
                          .utc(chart.physicianSignedOffDate)
                          .local()
                          .format("ddd Do MMM YYYY hh:mm a")}
                    </p>
                  </div>
                </div>
                <div>
                  {chart.staffSignature &&
                    chart.staffSignedOffDate &&
                    !chart.physicianSignature && (
                      <div
                        style={{
                          width: 85,
                          height: 26,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background:
                            parseInt(
                              moment
                                .utc()
                                .local()
                                .diff(
                                  moment.utc(chart.staffSignedOffDate).local(),
                                  "hours"
                                )
                            ) < 24
                              ? "#27CA2D"
                              : parseInt(
                                  moment
                                    .utc()
                                    .local()
                                    .diff(
                                      moment
                                        .utc(chart.staffSignedOffDate)
                                        .local(),
                                      "hours"
                                    )
                                )
                              ? "#E4C865"
                              : "#FF0000",
                          borderRadius: 27,
                          fontFamily: "tradeGothic",
                          fontSize: 14,
                          fontWeight: "bold",
                          color: "#fff",
                        }}
                      >
                        {moment
                          .utc()
                          .local()
                          .diff(
                            moment.utc(chart.staffSignedOffDate).local(),
                            "hours"
                          )}{" "}
                        hours
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return (
        <div style={{ height: 300, display: "flex", alignItems: "center" }}>
          <Empty description="No Charts" />
        </div>
      );
    }
  };

  return (
    <div>
      {/* <AppointmentDrawer
        addGuestClick={() => {
          setAppointmentDrawer(false);
          setshowGuestDrawer(true);
        }}
        date={showAppointmentDrawer}
        visible={showAppointmentDrawer}
        onClose={() => {
          setAppointmentDrawer(false);
        }}
      /> */}
      {/* <GuestDrawer
        showAppointmentDrawer={() => {
          setAppointmentDrawer(true);
        }}
        visible={showGuestDrawer}
        onClose={() => setshowGuestDrawer(false)}
      /> */}
      <EventDrawer
        order={currentOrder}
        setShowEventDrawer={setModalVisible}
        visible={modalVisible}
        onClose={() => {
          setModalVisible(false);
          dispatch(setCurrentStaff(null));
        }}
        getData={getData}
      />
      <div
        style={{
          height: isSmallDevice ? "auto" : 80,
          background: "#e5e8ee",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: isSmallDevice ? "column" : "row",
          paddingBottom: isSmallDevice ? 16 : 0,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: isSmallDevice ? 16 : 0,
          }}
        >
          {!isSmallDevice && (
            <h2
              style={{
                color: "#15213B",
                fontFamily: "tradeGothicBold",
                margin: 0,
                marginRight: 16,
              }}
            >{`Charts `}</h2>
          )}

          <div className="dayWeekSelectorContainer">
            <button
              className="arrows"
              onClick={() => {
                onNavigate("less");
              }}
            >
              <ChevronLeft />
            </button>
            <div style={{ position: "relative" }}>
              <DatePicker
                dropdownClassName="dayWeekSelectorDropdown"
                defaultValue={date}
                value={date}
                placement="bottomLeft"
                bordered={false}
                open={open}
                className="hiddenDatePicker"
                style={{ visibility: "hidden", width: 0 }}
                onOpenChange={(open) => setOpen(open)}
                dropdownAlign={{
                  offset: [-68, 14],
                }}
                onChange={async (date) => {
                  setDate(date);
                  dispatch(setLoading(true));
                  await dispatch(
                    getChartsByStatus("Pending", {
                      serviceDate: date.format(),
                      staffId:
                        currentStaff && currentStaff.roleName !== "Admin"
                          ? currentStaff.staffId
                          : null,
                    })
                  );
                  dispatch(setLoading(false));
                }}
              />
              <button className="dateButton" onClick={() => setOpen(!open)}>
                {date.format("DD MMM YYYY")}
              </button>
            </div>
            <button
              className="arrows"
              onClick={() => {
                onNavigate("add");
              }}
            >
              <ChevronRight />
            </button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: isSmallDevice ? "space-between" : "center",
            alignItems: "center",
          }}
        >
          <Space className="locationSelectorContainer">
            <span className="label">Sort By: </span>
            <Select
              className="locationSelector"
              dropdownClassName="locationSelectorDropdown"
              // showSearch
              placeholder="Select"
              optionFilterProp="children"
              // value={facility}
              onChange={(val) => {
                setSorting(val);
              }}
              defaultValue={"all"}
              // loading={loading}
              options={[
                { label: "All", value: "all" },
                { label: "Pending", value: "pending" },
                { label: "Signed off", value: "signedOff" },
                { label: "Newest to oldest", value: "newestToOldest" },
                { label: "Oldest to newest", value: "oldestToNewest" },
                { label: "Clinic", value: "inClinic" },
                { label: "Mobile", value: "mobile" },
              ]}
            />
          </Space>
        </div>
      </div>

      <div
        style={{
          overflowY: "auto",
          height: isSmallDevice
            ? "calc(100vh - 135px - 16px - 80px)"
            : "calc(100vh - 80px - 16px - 80px)",
          background: "#e5e8ee",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: 30,
        }}
      >
        {renderAppointments()}
      </div>
    </div>
  );
};

export default Charts;
