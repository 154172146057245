import React, { useState } from "react";
import { Button, Modal, Popover, Tag, Typography } from "antd";
import moment from "moment";
import { EyeOutlined, InfoCircleOutlined } from "@ant-design/icons";
import GiftCardModal from "./GiftCardModal";
import { useDispatch } from "react-redux";
import { redeemGiftCard } from "services/giftCards.service";

const { Text, Title, Link } = Typography;

export default function GiftCardItem({
  giftCard = {},
  paymentMethod,
  customer,
  refreshGiftCards,
  refreshCredits,
}) {
  const {
    giftCardId,
    senderEmail,
    amount,
    createdOn,
    senderName,
    isDelivered,
    personalNotes,
    recipientEmail,
    hasInvalidEmail,
    isRedeemed,
  } = giftCard;

  const [showModal, setShowModal] = useState();
  const dispatch = useDispatch();
  return (
    <div className="giftCardItem">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Title
          style={{
            fontFamily: "tradeGothic",
            fontSize: 19,
            color: "#000",
            marginBottom: 0,
          }}
        >
          Sender: {senderName} for ${giftCard.amount}
        </Title>
        <div
          style={{
            display: "flex",
            gap: 8,
            alignItems: "center",
          }}
        >
          {hasInvalidEmail && (
            <Popover content={"Invalid Receiver Email Provided."}>
              <InfoCircleOutlined
                style={{
                  color: "#f5222d",
                  fontSize: 18,
                }}
              />
            </Popover>
          )}
          {isRedeemed ? (
            <Tag color="green">Redeemed</Tag>
          ) : (
            <Link
              onClick={() => {
                Modal.confirm({
                  title: "Confirm",
                  okText: "Ok",
                  cancelText: "Cancel",
                  content:
                    "Are you sure you want to redeem this giftcard for the customer?",
                  onOk: () => {
                    dispatch(redeemGiftCard(giftCardId)).then((res) => {
                      if (res?.status === "success") {
                        refreshGiftCards();
                        refreshCredits();
                      }
                    });
                  },
                });
                // console.log(record)
              }}
            >
              Redeem
            </Link>
          )}
          <Button
            shape="circle"
            icon={<EyeOutlined />}
            onClick={() => setShowModal(giftCard)}
          />
        </div>
      </div>
      <Text>Recipient: {recipientEmail ?? "Self"}</Text>
      <br />
      <Text>Sent On: {moment(createdOn).format("ll")}</Text>
      <GiftCardModal
        paymentMethod={paymentMethod}
        customer={customer}
        visible={showModal}
        data={showModal}
        onCancel={() => setShowModal()}
        onSave={() => {
          setShowModal();
          refreshGiftCards();
        }}
      />
    </div>
  );
}
