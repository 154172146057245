import React from "react";
import PropTypes from "prop-types";

import { Button as AntdButton, Row, Col, Typography } from "antd";
import "./ShiftItem.scss";
import {
  FormInput,
  FormInputNumber,
  FormTimePicker,
} from "components/FormItems/FlatFormItems";
import moment from "moment";
import ShiftItemDay from "./ShiftItemDay";

const { Text } = Typography;

function ShiftItem({form, index, isSmallDevice = false }) {
  return (
    <div className="shiftItemsContainer">
      {/* <div className="shiftItemControls">
        <AntdButton type="text">Add Shifts</AntdButton>
      </div> */}
      <div className="shiftItemTimings">
        <Row gutter={[24, isSmallDevice ? 12 : 24]}>
          <Col xs={12}>
            <FormInput
              required
              variant="underlined"
              label="Shift Name"
              name={["shiftDetail", index, "shiftName"]}
            />
          </Col>
          <Col xs={6}>
            <FormTimePicker
              allowClear={false}
              label="Start Time"
              variant="underlined"
              name={["shiftDetail", index, "startTime"]}
              initialValue={moment("9:00 am", "hh:mm a")}
              required
              formItemStyles={{ margin: 0 }}
            />
          </Col>
          <Col xs={6}>
            <FormTimePicker
              formItemStyles={{ margin: 0 }}
              allowClear={false}
              variant="underlined"
              name={["shiftDetail", index, "endTime"]}
              initialValue={moment("5:00 pm", "hh:mm a")}
              label="End Time"
              dependencies={[["shiftDetail", index, "startTime"]]}
              rules={[
                {
                  required:  true,
                  message: "Required",
                },
                {
                  message: "Invalid",
                  validator: (_, value) => {
  
                    const shiftStartValue = form.getFieldValue([
                      "shiftDetail",
                      index,
                      "startTime",
                    ]);
                    const shiftEndValue = form.getFieldValue([
                      "shiftDetail",
                      index,
                      "endTime",
                    ]);
  
                    if (
                      shiftStartValue >= shiftEndValue ||
                      shiftStartValue.format("LT") === shiftEndValue.format("LT")
                    ) {
                      return Promise.reject();
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            />
          </Col>
        </Row>
      </div>
      <div className="shiftItemDays">
        <Row gutter={[24, isSmallDevice ? 12 : 24]}>
          <ShiftItemDay shiftIndex={index} dayIndex={0} day="Sunday" />
          <ShiftItemDay shiftIndex={index} dayIndex={1} day="Monday" />
          <ShiftItemDay shiftIndex={index} dayIndex={2} day="Tuesday" />
          <ShiftItemDay shiftIndex={index} dayIndex={3} day="Wednesday" />
          <ShiftItemDay shiftIndex={index} dayIndex={4} day="Thursday" />
          <ShiftItemDay shiftIndex={index} dayIndex={5} day="Friday" />
          <ShiftItemDay shiftIndex={index} dayIndex={6} day="Saturday" />
        </Row>
      </div>
    </div>
  );
}

ShiftItem.propTypes = {};

export default ShiftItem;
