import React, { useEffect, useReducer, useState } from "react";
import moment from "moment";
import { MoreOutlined } from "@ant-design/icons";
import {
  Dropdown,
  Menu,
  Space,
  Table,
  Button as AntdButton,
  Modal,
  Select,
} from "antd";
import { Button } from "components/Buttons/Buttons";
import { DropdownButton } from "components/DropdownButton/DropdownButton";
import useSettingsHeader from "hooks/useSettingsHeader";

import "./ServicesAndBoosts.style.scss";
import ServicesDrawer from "./components/ServicesDrawer";
import BoostDrawer from "./components/BoostDrawer";
import { useDispatch } from "react-redux";
import {
  createBoost,
  deleteBoost,
  getAllBoosts,
  restoreBoost,
  updateBoost,
} from "services/servicesAndBoostsSettings.service";
import usePortal from "hooks/usePortal";
import SearchAndFilterColumns from "components/SearchAndFilterColumns/SearchAndFilterColumns";
import { getAllTaxCategories } from "services/taxation.service";
import { getAllAccounts } from "services/quickbook.service";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET":
      return action.data;
    case "ADD":
      return [...state, action.data];
    case "UPDATE":
      return state.map((data) => {
        if (data.categoryId === action.data.categoryId) return action.data;
        return data;
      });
    case "DELETE":
      return state.filter((data) => {
        if (data.categoryId === action.data.categoryId) return false;
        return true;
      });
    default:
      return state;
  }
};

export default function Boosts() {
  const [showBoostModal, setShowBoostModal] = useState();
  const [accounts, setAccounts] = useState({
    data: [],
    loading: true,
  });

  const dispatch = useDispatch();
  const [data, localDispatch] = useReducer(reducer, []);

  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [activeFilter, setActiveFilter] = useState("active"); // all, active, inactive
  const [searchText, setSearchText] = useState("");
  const [taxCategories, setTaxCategories] = useState([]);

  const loadData = async () => {
    const result = await dispatch(
      getAllBoosts((data) => {
        localDispatch({ action: "SET", data });
      })
    );
    if (result?.status === "success") {
      localDispatch({
        type: "SET",
        data: result.data,
      });
    }
  };

  useEffect(() => {
    loadData();
    dispatch(getAllTaxCategories(false)).then((result) => {
      if (result?.status === "success") {
        setTaxCategories(result.data);
      }
    });
    dispatch(getAllAccounts(false)).then((res) => {
      if (res?.status === "success") {
        setAccounts({
          data: res.data,
          loading: false,
        });
      } else {
        setAccounts({
          data: [],
          loading: false,
        });
      }
    });
  }, []);

  // functions start

  const handleAdd = (values) => {
    dispatch(createBoost(values)).then((result) => {
      if (result?.status === "success") {
        setShowBoostModal();
        loadData();
        // loadData();
      }
    });
  };

  const handleUpdate = (id, values) => {
    dispatch(updateBoost(id, values)).then((result) => {
      if (result?.status === "success") {
        setShowBoostModal();
        loadData();
      }
    });
  };
  const handleDelete = (id) => {
    dispatch(deleteBoost(id)).then((result) => {
      if (result?.status === "success") {
        loadData();
      }
    });
  };
  const handleRestore = (id) => {
    dispatch(restoreBoost(id)).then((result) => {
      if (result?.status === "success") {
        loadData();
      }
    });
  };
  const deleteConfirmation = (id) => ({
    title: "Inactivate Add-on",
    content: <>Are you sure that you want to inactivate the addon?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      handleDelete(id);
      close();
    },
  });

  const restoreConfirmation = (id) => ({
    title: "Activate Add-on",
    content: <>Are you sure that you want to activate the addon?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      handleRestore(id);
      close();
    },
  });

  const checkActiveFilter = (item) => {
    if (activeFilter === "all") return true;
    else if (activeFilter === "active" && item?.isActive) return true;
    else if (activeFilter === "inactive" && !item?.isActive) return true;
    return false;
  };
  // functions end

  const columns = [
    {
      title: "Add-on Name",
      dataIndex: "productName",
      key: "productName",
      sorter: (a, b) => {
        if (a.productName?.toLowerCase?.() < b.productName?.toLowerCase?.())
          return -1;
        if (a.productName?.toLowerCase?.() > b.productName?.toLowerCase?.())
          return 1;
        return 0;
      },
    },
    {
      title: "Active",
      key: "active",
      width: 100,
      render: (data) => {
        if (data?.isActive) return "Active";
        return "Inactive";
      },
    },

    {
      title: "",
      key: "action",
      align: "right",
      width: 80,
      render: (data) => (
        <Dropdown
          overlay={
            <Menu style={{ borderRadius: 6, border: "2px solid #CDD5D8 " }}>
              <Menu.Item onClick={() => setShowBoostModal(data)} key="edit">
                Edit
              </Menu.Item>
              <Menu.Item
                key="delete"
                onClick={() => {
                  if (data?.isActive)
                    Modal.warning(deleteConfirmation(data.productId));
                  else Modal.warning(restoreConfirmation(data.productId));
                }}
              >
                {data?.isActive ? "Inactivate" : "Activate"}
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
          getPopupContainer={(triggerNode) => triggerNode?.parentNode}
        >
          <MoreOutlined style={{ fontSize: 18 }} />
        </Dropdown>
      ),
    },
  ];

  const settingHeaderContent = useSettingsHeader(
    <Space align="center" size="large">
      <Button
        rounded={true}
        onClick={() => {
          setShowBoostModal(true);
        }}
      >
        Add New Add-on
      </Button>
    </Space>
  );

  const searchAndFiltersContent = usePortal(
    "#settingHeader .settingsHeaderLeftSideAddon",
    <Space>
      <SearchAndFilterColumns
        columns={columns}
        hiddenColumns={hiddenColumns}
        setHiddenColumns={(columnTitles) => {
          setHiddenColumns(columnTitles);
        }}
        searchText={searchText}
        setSearchText={(text) => {
          setSearchText(text);
        }}
      />
      <Select
        style={{ width: 120 }}
        value={activeFilter}
        onChange={(value) => {
          setActiveFilter(value);
        }}
        options={[
          {
            value: "all",
            label: "All",
          },
          {
            value: "active",
            label: "Active",
          },
          {
            value: "inactive",
            label: "Inactive",
          },
        ]}
      />
    </Space>
  );

  return (
    <div>
      {settingHeaderContent}
      {searchAndFiltersContent}
      <BoostDrawer
        taxCategories={taxCategories}
        visible={showBoostModal}
        onClose={() => setShowBoostModal()}
        data={showBoostModal}
        onSuccess={(values) => {
          const isEdit = showBoostModal?.productId !== undefined;
          if (isEdit) handleUpdate(showBoostModal?.productId, values);
          else handleAdd(values);
        }}
        accounts={accounts.data}
        isAccountsLoading={accounts.loading}
      />

      <Table
        tableLayout="auto"
        locale={{ emptyText: "No Locations" }}
        rowKey={(data) => data.productId}
        dataSource={data?.filter(
          (it) =>
            checkActiveFilter(it) &&
            it?.productName
              ?.toLowerCase?.()
              .includes?.(searchText.toLowerCase())
        )}
        columns={columns.filter((column) => {
          if (!hiddenColumns.includes(column.title)) return true;
          return false;
        })}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: [15, 25, 50, 100, 250],
        }}
        // scroll={{ y: "calc(100vh - 80px - 96px - 60px - 100px)", x: true }}
      />
    </div>
  );
}
