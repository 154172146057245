import React, { useEffect, useRef, useState } from "react";
import { Col, Drawer, Form, Row, Button as AntdButton } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import {
  FormInput,
  FormHiddenSubmitButton,
  FormSelect,
} from "components/FormItems/FlatFormItems";
import { useForm } from "antd/lib/form/Form";
import { Button } from "components/Buttons/Buttons";
import { useDispatch } from "react-redux";
import { getCustomerById } from "services/quickbook.service";

export default function CustomerAddEditDrawer({
  data,
  customers,
  visible,
  onClose,
  onSuccess,
}) {
  const [form] = useForm();
  const submitBtnRef = useRef();
  const isEdit = data?.customerId !== undefined;

  const dispatch = useDispatch();
  const [completeData, setCompleteData] = useState({});

  const getData = async (id) => {
    const result = await dispatch(getCustomerById(id));
    if (result?.status === "success") {
      setCompleteData(result.data);
      form.setFieldsValue({
        ...result.data,
      });
    }
  };

  useEffect(() => {
    if (visible === false) {
      setCompleteData({});
      form.resetFields();
    } else if (isEdit) {
      form.setFieldsValue({
        ...data,
      });
      getData(data?.customerId);
    }
  }, [visible]);

  return (
    <Drawer
      destroyOnClose
      headerStyle={{ border: "none" }}
      className="integrationDrawer"
      width={"min(100vw, 420px)"}
      title={
        <div className="integrationDrawerTitle">
          <p>{visible && (isEdit ? "Edit" : "Create")} Customer</p>
          <CloseOutlined onClick={onClose} />
        </div>
      }
      placement="right"
      onClose={onClose}
      visible={visible}
      closable={false}
      footer={
        <div className="integrationDrawerFooter">
          <AntdButton type="text" onClick={onClose}>
            Cancel
          </AntdButton>
          <Button
            rounded
            type={"primary"}
            style={{ height: 50, width: "50%" }}
            onClick={() => submitBtnRef?.current?.click()}
          >
            Save
          </Button>
        </div>
      }
    >
      <Form
        preserve={false}
        layout="vertical"
        form={form}
        requiredMark={false}
        onFinish={(values) => {
          onSuccess({
            ...completeData,
            ...values,
            customerTypeId:
              completeData?.customerTypeId === 0 ||
              completeData?.customerTypeId === undefined
                ? 1
                : completeData?.customerTypeId,
          });
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
      >
        <Row gutter={[16, 16]}>
          {!!isEdit && (
            <Col xs={24}>
              <FormInput
                disabled={isEdit}
                name="referenceId"
                variant="underlined"
                label="Reference ID"
                required
                placeholder="Reference ID"
                formItemStyles={{
                  marginBottom: 0,
                }}
              />
            </Col>
          )}
          <Col xs={24}>
            <FormInput
              name="customerName"
              variant="underlined"
              label="Customer Name"
              required
              placeholder="Customer Name"
              formItemStyles={{
                marginBottom: 0,
              }}
            />
          </Col>
          <Col xs={24}>
            <FormInput
              name="fullyQualifiedName"
              variant="underlined"
              label="Customer Full Name"
              required
              placeholder="Customer Full Name"
              formItemStyles={{
                marginBottom: 0,
              }}
            />
          </Col>
          <Col xs={24}>
            <FormInput
              type="email"
              name="customerEmail"
              variant="underlined"
              label="Customer Email"
              required
              placeholder="Customer Email"
              formItemStyles={{
                marginBottom: 0,
              }}
            />
          </Col>

          {/* <Col span={24}>
            <FormSelect
              // disabled={isAppointmentFormDisabled}
              variant="underlined"
              name="parentCustomerId"
              label="Parent Customer"
              placeholder="Select Parent Customer"
              // required={true}
              formItemStyles={{ marginBottom: 0 }}
              // loading={isFetchingAddons}
              options={customers?.filter(
                (it) => it?.customerId !== data?.customerId
              )}
              renderLabel="customerName"
              renderValue="customerId"
              // mode="multiple"
              optionLabelProp="label"
              // filterOption={(input, option) => {
              //   return option?.label
              //     ?.toLowerCase?.()
              //     .includes?.(input.toLowerCase());
              // }}
              // isOptionDisabled={(option) => {
              //   return checkIfAddonIsDisabled(option);
              // }}
            />
          </Col>
          <Col span={24}>
            <FormSelect
              // disabled={isAppointmentFormDisabled}
              variant="underlined"
              name="subCustomerIds"
              label="Sub Customers"
              placeholder="Select Sub Customer"
              // required={true}
              formItemStyles={{ marginBottom: 0 }}
              // loading={isFetchingAddons}
              options={customers?.filter(
                (it) => it?.customerId !== data?.customerId
              )}
              renderLabel="customerName"
              renderValue="customerId"
              mode="multiple"
              optionLabelProp="label"
              // filterOption={(input, option) => {
              //   return option?.label
              //     ?.toLowerCase?.()
              //     .includes?.(input.toLowerCase());
              // }}
              // isOptionDisabled={(option) => {
              //   return checkIfAddonIsDisabled(option);
              // }}
            />
          </Col> */}
        </Row>

        <Row gutter={[16, 16]}>
          <FormHiddenSubmitButton submitRef={submitBtnRef} />
        </Row>
      </Form>
    </Drawer>
  );
}
