import React, { useRef } from "react";
import PropTypes from "prop-types";
import Modal from "components/Modal/Modal";
import { Col, Form, Row } from "antd";
import {
  FormHiddenSubmitButton,
  FormSelect,
} from "components/FormItems/FlatFormItems";

function MobileFacilityPopup({ visible, onClose, facilities, onSave }) {
  const submitBtnRef = useRef();
  const [form] = Form.useForm();

  return (
    <Modal
      headerBorder={false}
      width="460px"
      title={`Choose Mobile Facility`}
      onCancel={onClose}
      visible={visible}
      onOk={() => submitBtnRef?.current?.click()}
      okText="Save"
      destroyOnClose
    >
      <Form
        requiredMark={false}
        form={form}
        layout="vertical"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          borderBottom: "2px solid #EFF1F4",
          paddingBottom: 12,
        }}
        preserve={false}
        onFinish={(values) => {
          onSave(values.facilityId);
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
      >
        <Row style={{ width: "100%" }}>
          <Col span={24}>
            <FormSelect
              // disabled={isAppointmentFormDisabled}
              variant="underlined"
              name="facilityId"
              label="Services"
              placeholder="Services"
              required={true}
              formItemStyles={{ marginBottom: 12 }}
              options={facilities}
              renderLabel="facilityName"
              renderValue="facilityId"
              showSearch={true}
              optionLabelProp="label"
              //   filterOption={(input, option) => {
              //     return option?.label
              //       ?.toLowerCase?.()
              //       .includes?.(input.toLowerCase());
              //   }}
            />
          </Col>
          <FormHiddenSubmitButton submitRef={submitBtnRef} />
        </Row>
      </Form>
    </Modal>
  );
}

MobileFacilityPopup.propTypes = {};

export default MobileFacilityPopup;
