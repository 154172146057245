import React, { useState } from "react";
import "./Incrementor.scss";
import { FormInput } from "components/FormItems/FlatFormItems";
import { Form, InputNumber } from "antd";

export default function Incrementor({
  initialValue = 1,
  index,
  quantity,
  maxQuantity,
  form,
  onChangeQuantity,
  disabled,
  keyProp,
}) {
  const FormItem = Form.Item;

  return (
    <div className="outlinedIncrementor">
      <div
        className={disabled ? "disabled" : ""}
        onClick={() => {
          if (disabled) return;
          // console.log(
          //   "form.getFieldValue",
          //   form.getFieldValue(["transactions", index, "quantity"])
          // );
          // const t = form.getFieldValue("transactions");
          // t[index].quantity = t[index].quantity - 1;
          // form.setFieldValue("transactions", t);
          // console.log('t', t)
          // form.setFieldsValue({
          //   [`transactions[${index}].quantity`]:
          //     form.getFieldValue(["transactions", index, "quantity"]) - 1,
          // });
          onChangeQuantity(Number(quantity) - 1);
        }}
      >
        -
      </div>
      <input
        disabled={disabled}
        min={0}
        max={maxQuantity}
        type="number"
        name={`transactions[${keyProp}].quantity`}
        value={quantity}
        onChange={(e) => onChangeQuantity(Number(e.target.value))}
      />
      {/* <FormItem
        name={["transactions", index, "quantity"]}
        value={value}
        onChange={(e) => setValue(Number(e.target.value))}
      >
        <InputNumber min={0} />
      </FormItem> */}

      <div
        className={disabled ? "disabled" : ""}
        onClick={() => {
          if (disabled) return;
          onChangeQuantity(Number(quantity) + 1);
        }}
      >
        +
      </div>
    </div>
  );
}
