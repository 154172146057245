import { setAllCharts, setCurrentChart } from "redux/actions/charts.action";
import { openNotificationWithIcon } from "utils/Notification";
import { request } from "./verb.services";

export const getChartsByStatus = (status, params = {}) => {
  return (dispatch) => {
    return request(`Charts/${status}`, "get", null, true, { ...params })
      .then(({ data }) => {
        dispatch(setAllCharts(data));
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};

export const getChartsByUser = (guestId) => {
  return (dispatch) => {
    return request(`Charts/Completed`, "get", null, true, { guestId })
      .then(({ data }) => {
        dispatch(setAllCharts(data));
        return data;
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};

export const getLegacyChart = (orderId, lineItemId) => {
  return (dispatch) => {
    return request(`Charts/`, "get", null, true, { orderId, lineItemId })
      .then(({ data }) => {
        setCurrentChart([data]);
        return [data];
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};

export const submitChart = (data) => {
  return (dispatch) => {
    return request(`Charts/`, "post", data, true, null)
      .then(({ data }) => {
        dispatch(setCurrentChart(data));
        openNotificationWithIcon(
          "success",
          "Saved",
          "Chart saved successfully!"
        );
        return data;
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};

export const updateChart = (data, chartId) => {
  return (dispatch) => {
    return request(`Charts/`, "put", data, true, { chartId })
      .then(({ data }) => {
        dispatch(setCurrentChart(data));
        // console.log(data)
        openNotificationWithIcon(
          "success",
          "Saved",
          "Chart saved successfully!"
        );
        return data;
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};

export const assignNursePractitioner = (practionerStaffId, chartId) => {
  return request(`Charts/${chartId}/AssignPractioner`, "patch", null, true, {
    practionerStaffId,
  })
    .then(({ data }) => {
      // dispatch(setCurrentChart(data))
      // console.log(data)
      // openNotificationWithIcon('success', 'Submitted', 'Chart updated successfully!')
      return data;
    })
    .catch((e) => {
      openNotificationWithIcon(
        "error",
        "Error!",
        e?.response?.data?.message || "Network error has occured"
      );
    });
};

export const getChartNotes = (chartId) => {
  return request(`Charts/${chartId}/Notes`, "get", null, true, null)
    .then(({ data }) => {
      // dispatch(setCurrentChart(data))
      // console.log(data)
      // openNotificationWithIcon('success', 'Submitted', 'Chart updated successfully!')
      return data;
    })
    .catch((e) => {
      openNotificationWithIcon(
        "error",
        "Error!",
        e?.response?.data?.message || "Network error has occured"
      );
    });
};

export const addChartNotes = (chartId, data) => {
  return request(`Charts/${chartId}/Notes`, "post", data, true, null)
    .then(({ data }) => {
      // dispatch(setCurrentChart(data))
      // console.log(data)
      // openNotificationWithIcon('success', 'Submitted', 'Chart updated successfully!')
      return data;
    })
    .catch((e) => {
      openNotificationWithIcon(
        "error",
        "Error!",
        e?.response?.data?.message || "Network error has occured"
      );
    });
};
