export const actions = {
  SET_CURRENT_FACILITY_IDS: "SET_CURRENT_FACILITY_IDS",
  SET_CURRENT_FACILITY: "SET_CURRENT_FACILITY",
  SET_FACILITIES_LOADING: "SET_FACILITIES_LOADING",
  SET_CURRENT_STAFF_IDS: "SET_CURRENT_STAFF_IDS",
  SET_STAFF_LOADING: "SET_STAFF_LOADING",
  SET_CURRENT_OPERATION_TYPE: "SET_CURRENT_OPERATION_TYPE",
  SET_CURRENT_TELEHEALTH_FACILITY: "SET_CURRENT_TELEHEALTH_FACILITY",
};

export const setCurrentFacilityIds = (data) => ({
  type: actions.SET_CURRENT_FACILITY_IDS,
  data,
});

export const setCurrentFacility = (data) => ({
  type: actions.SET_CURRENT_FACILITY,
  data,
});

export const setCurrentTelehalthFacility = (data) => ({
  type: actions.SET_CURRENT_TELEHEALTH_FACILITY,
  data,
});

export const setFacilitiesLoading = (data) => ({
  type: actions.SET_FACILITIES_LOADING,
  data,
});

export const setCurrentStaffIds = (data) => ({
  type: actions.SET_CURRENT_STAFF_IDS,
  data,
});

export const setStaffLoading = (data) => ({
  type: actions.SET_STAFF_LOADING,
  data,
});

export const setCurrentOperationType = (data) => ({
  type: actions.SET_CURRENT_OPERATION_TYPE,
  data,
});
