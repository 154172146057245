import React, { useEffect, useState, useRef, useCallback } from "react";
import "./ProfilePhoto.style.scss";
import { Modal } from "antd";
import { openNotificationWithIcon } from "../../utils/Notification";
import ReactCrop from "react-image-crop";
import "react-image-crop/src/ReactCrop.scss";
import { Button } from "components/Buttons/Buttons";

const pixelRatio = window.devicePixelRatio || 1;

const PhotoUpload = ({
  isEdit,
  base64,
  setBase64,
  defaultImage,
  setFile,
  type,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const [upImg, setUpImg] = useState(null);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ aspect: 3 / 4 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [finalCrop, setFinalCrop] = useState("");

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile && setFile(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = "";
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    setFinalCrop(canvas.toDataURL());
  }, [completedCrop]);

  return (
    <div>
      <Modal
        visible={modalVisible}
        title={`${isEdit ? "Update" : "Upload"}  ${type} Picture`}
        maskClosable={false}
        onCancel={() => {
          setModalVisible(false);
          setUpImg("");
          setFinalCrop("");
        }}
        onOk={async () => {
          if (finalCrop) {
            // dispatch(setLoading(true))
            if (finalCrop.includes("base64")) {
              setBase64(finalCrop);
              setModalVisible(false);
              setUpImg("");
              // dispatch(setLoading(false))
            } else {
              // dispatch(setLoading(false))
              return openNotificationWithIcon(
                "warning",
                "Warning!",
                "Please crop picture area"
              );
            }
          } else {
            openNotificationWithIcon(
              "warning",
              "Warning!",
              "Please upload a valid file"
            );
          }
        }}
      >
        <ReactCrop
          src={upImg}
          onImageLoaded={onLoad}
          crop={crop}
          keepSelection={false}
          // maxWidth={325}
          // maxHeight={325}
          onChange={(c) => setCrop(c)}
          onComplete={(c) => setCompletedCrop(c)}
          circularCrop
        />
        {!upImg ? (
          <div className="btn-uploader-holder">
            <label className="label-profile">
              <input type="file" accept="image/*" onChange={onSelectFile} />
              <span className="label-profile__text">Browse for File</span>
            </label>
          </div>
        ) : (
          <p>
            *Crop image by hovering over the image and selecting the desired
            area.
          </p>
        )}
        <div>
          <canvas
            ref={previewCanvasRef}
            // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
            style={{
              width: Math.round(completedCrop?.width ?? 0),
              height: Math.round(completedCrop?.height ?? 0),
              display: "none",
            }}
          />
        </div>
      </Modal>
      {/* <p style={{ fontSize: 16, margin: 0 }} onClick={() => {
        setCrop({ unit: "px", aspect: 1 / 1, })
        setModalVisible(true)
      }}>Upload Profile Photo</p> */}
      <div
        style={{
          height: 220,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img
          src={base64 ? base64 : defaultImage}
          width={160}
          style={{ borderRadius: "50%" }}
          alt=""
        />
        <Button
          className="uploadImageBtn"
          style={{ marginTop: 12 }}
          onClick={() => {
            setCrop({ unit: "px", aspect: 1 / 1 });
            setModalVisible(true);
          }}
        >
          {isEdit ? "Update" : "Upload"} Image
        </Button>
      </div>
    </div>
  );
};
export default PhotoUpload;
