import { Radio } from "antd";
import React from "react";
import { CalenderOperationType } from "utils/enums";

export default function OperationTypeSelector({ value, onChange }) {
  return (
    <Radio.Group
      value={value}
      onChange={(e)=>{
        onChange(e.target.value)
      }}
      options={[
        {
          label: "Location",
          value: CalenderOperationType.All,
        },
        {
          label: "Telehealth",
          value: CalenderOperationType.Telehealth,
        },
      ]}
    />
  );
}
