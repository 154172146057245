export const actions = {
  SET_CURRENT_USER: "SET_CURRENT_USER",
  SET_ALL_DISTRIBUTORS: "SET_ALL_DISTRIBUTORS",
  SET_CURRENT_DISTRIBUTOR: "SET_CURRENT_DISTRIBUTOR",
  SET_ALL_SUB_DISTRIBUTORS: "SET_ALL_SUB_DISTRIBUTORS",
  SET_CURRENT_SUB_DISTRIBUTOR: "SET_CURRENT_SUB_DISTRIBUTOR",
  SET_ALL_STAFF: "SET_ALL_STAFF",
  SET_CURRENT_STAFF: "SET_CURRENT_STAFF",
  SET_ALL_GUESTS: "SET_ALL_GUESTS",
  UPDATE_GUEST: "UPDATE_GUEST",
  SET_CURRENT_GUEST: "SET_CURRENT_GUEST",
  SET_WHOLE_STAFF: "SET_WHOLE_STAFF",
  ADD_GUEST: "ADD_GUEST",
  ADD_STAFF: "ADD_STAFF",
  CLEAR_ALL_STAFF: "CLEAR_ALL_STAFF",
  SET_USER_NOTES: "SET_USER_NOTES",
};

export const setUser = (data) => ({
  type: actions.SET_CURRENT_USER,
  data,
});

export const setUserNotes = (data) => ({
  type: actions.SET_USER_NOTES,
  data,
});

export const setAllDistributors = (data) => ({
  type: actions.SET_ALL_DISTRIBUTORS,
  data,
});

export const setCurrentDistributor = (data) => ({
  type: actions.SET_CURRENT_DISTRIBUTOR,
  data,
});

export const setAllSubDistributors = (data) => ({
  type: actions.SET_ALL_SUB_DISTRIBUTORS,
  data,
});

export const setCurrentSubDistributor = (data) => ({
  type: actions.SET_CURRENT_SUB_DISTRIBUTOR,
  data,
});

export const setAllStaff = (data) => ({
  type: actions.SET_ALL_STAFF,
  data,
});

export const setWholeStaff = (data) => ({
  type: actions.SET_WHOLE_STAFF,
  data,
});

export const setCurrentStaff = (data) => ({
  type: actions.SET_CURRENT_STAFF,
  data,
});

export const setAllGuests = (data) => ({
  type: actions.SET_ALL_GUESTS,
  data,
});

export const setCurrentGuest = (data) => ({
  type: actions.SET_CURRENT_GUEST,
  data,
});
export const updateGuest = (data) => ({
  type: actions.UPDATE_GUEST,
  data,
});

export const addGuest = (data) => ({
  type: actions.ADD_GUEST,
  data,
});

export const addStaff = (data) => ({
  type: actions.ADD_STAFF,
  data,
});

export const clearAllStaff = () => ({
  type: actions.CLEAR_ALL_STAFF,
});
