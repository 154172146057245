import React from "react";
import PinImage from "assets/images/pin.png";

export default function Pin({ pin, onClick }) {
  return (
    <div
      onClick={onClick}
      style={{
        position: "absolute",
        top: pin.location.y,
        left: pin.location.x,
        cursor: "pointer",
        // backgroundColor: "red",
        // width: 10,
        // height: 10,
      }}
    >
      <div
        style={{
          background: "rgba(0, 0, 0, 0.5)",
        }}
      >
        {/* <img
          src={PinImage}
          style={{
            height: 24,
          }}
        /> */}
        <div
          style={{
            height: 10,
            width: 10,
            background: "red",
            borderRadius: "50%",
          }}
        >
          &nbsp;
        </div>
        <span style={{ color: "#fff" }}>{pin.text}</span>
      </div>
    </div>
  );
}
