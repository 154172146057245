import React from "react";
import PropTypes from "prop-types";
import { Col, Typography } from "antd";
import { FormInput, FormInputNumber } from "components/FormItems/FlatFormItems";

const { Text } = Typography;

function ShiftItemDay({ shiftIndex, dayIndex, day }) {
  const isFirstDay = dayIndex === 0;
  return (
    <>
      <Col
        xs={18}
        style={{
          paddingTop: isFirstDay ? 46 : 16,
        }}
      >
        <Text className="firstLetterUppercase">{day}</Text>
      </Col>
      <Col xs={6}>
        <FormInputNumber
          min={0}
          // initialValue={0}
          variant="underlined"
          label={isFirstDay ? "No of Shifts" : ""}
          formItemClassName={isFirstDay ? "" : "label-d-none"}
          name={[
            "shiftDetail",
            shiftIndex,
            "facilityShiftDetails",
            dayIndex,
            "maximumShiftsAllowed",
          ]}
          formItemStyles={{
            marginBottom: 0,
          }}
        />
        {/* Hidden Fields Start */}
        <FormInput
          hidden={true}
          initialValue={day}
          name={[
            "shiftDetail",
            shiftIndex,
            "facilityShiftDetails",
            dayIndex,
            "day",
          ]}
        />
        <FormInput
         type="text"
          hidden={true}
          name={[
            "shiftDetail",
            shiftIndex,
            "facilityShiftDetails",
            dayIndex,
            "shiftId",
          ]}
        />
        <FormInput
          type="text"
          hidden={true}
          name={[
            "shiftDetail",
            shiftIndex,
            "facilityShiftDetails",
            dayIndex,
            "facilityShiftId",
          ]}
        />
        {/* Hidden Fields End */}
      </Col>
    </>
  );
}

ShiftItemDay.propTypes = {};

export default ShiftItemDay;
