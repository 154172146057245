import { actions } from "../actions/location.action";

const INITIAL_STATE = {
  states: [],
  statesLoading: true,
  cities: [],
  citiesLoading: true,
};

const reducer = (state = INITIAL_STATE, data) => {
  switch (data.type) {
    case actions.SET_STATES:
      return { ...state, states: data.data, statesLoading: false };
    case actions.SET_CITIES:
      return { ...state, cities: data.data, citiesLoading: false };
    case "RESET":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
