import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Modal from "components/Modal/Modal";

import { Form, Typography, Checkbox } from "antd";

import "./ConsentForm.scss";
import { postForm, getLegacyForm } from "services/intakeForm.service";
import Signature, { useSignature } from "components/Signature/Signature";
import { openNotificationWithIcon } from "utils/Notification";

const { Title, Text } = Typography;

const FORM_NAME = "LegacyConsent";

export default function ConsentForm({
  visible,
  onSubmit,
  onClose,
  initialValues = {},
  user,
}) {
  const [form] = Form.useForm();
  const submitBtnRef = useRef();
  const consentOfAge = Form.useWatch("consentOfRecipientAge", form);

  const dispatch = useDispatch();
  const { intakeForms } = useSelector((state) => {
    return { intakeForms: state.intakeForms };
  });

  const { isSubmitting, consent } = intakeForms;
  const [signature, getSignatureValue, clearSignatureValue, setSignatureValue] =
    useSignature("consentModal", form);
  const { legacyConsentFormId } = user;
  const isFormSubmitted = user?.isConsentFormSubmitted && legacyConsentFormId;

  const [agreeAllConsensts, setAgreeAllConsents] = useState(isFormSubmitted);

  useEffect(() => {
    if (isFormSubmitted) {
      // get data
      dispatch(getLegacyForm(user?.customerId, FORM_NAME));
    }
  }, [user]);
  useEffect(() => {
    if (isFormSubmitted) {
      form.setFieldsValue({
        consentOfRecipientAge: isFormSubmitted
          ? consent.consentOfRecipientAge
          : false,
      });
      clearSignatureValue()
      setSignatureValue(consent?.customerSignature);
    }

  }, [consent]);

  useEffect(() => {
    clearSignatureValue()
    setSignatureValue(consent?.customerSignature);
  }, [visible]);

  return (
    <Modal
      className="intakeForm consentModal"
      title="Consents"
      visible={visible}
      width={812}
      onCancel={onClose}
      footer={
        <div
          className="flex "
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Checkbox
            className="checkboxRegular"
            checked={
              agreeAllConsensts
              // (consentOfAge === true || consentOfAge === false) &&
              // (photographyConsent === true || photographyConsent === false)
            }
            disabled
            formItemStyles={{ marginBottom: 0 }}
            onChange={(e) => {
              setAgreeAllConsents((state) => {
                return !state;
              });
              // if (e.target.checked)
              //   form.setFieldsValue({
              //     consentOfRecipientAge: true,
              //     photographyConsent: true,
              //   });
              // else {
              //   form.setFieldsValue({
              //     consentOfRecipientAge: undefined,
              //     photographyConsent: undefined,
              //   });
              //   clearSignatureValue();
              // }
              if (e.target.checked === false) {
                clearSignatureValue();
              }
            }}
          >
            I agree to all consents
          </Checkbox>
          {/* <Button
            onClick={() => submitBtnRef?.current?.click()}
            disabled={
              // !(consentOfAge === true || consentOfAge === false) ||
              !agreeAllConsensts
            }
            loading={isSubmitting}
            rounded={true}
            type="submit"
            style={{ paddingLeft: 60, paddingRight: 60 }}
          >
            Save
          </Button> */}
        </div>
      }
    >
      <Form
        disabled
        form={form}
        layout="vertical"
        className="intakeFormWrapper consentForm"
        initialValues={{
          consentOfRecipientAge: undefined,
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
        onFinish={(values) => {
          const signature = getSignatureValue();
          if (signature === "") {
            openNotificationWithIcon(
              "error",
              "Error!",
              "Please sign the consent form"
            );
            return;
          }
          values.customerSignature = signature;

          // dispatch(postForm(user?.customerId, FORM_NAME, values)).then(() => {
          //   if (onSubmit) onSubmit();
          // });
        }}
      >
        <Title level={5} className="tradeGothic">
          Cancellation Policy
        </Title>
        <Text className="tradeGothic">
          Your appointment time is reserved just for you. A late cancellation or
          missed visit leaves a hole in the nurse’s day that could have been
          filled by another patient. The nurse may have already traveled to your
          location, and your service may have already been dispensed. As such,
          we ask for 24 hours notice for any cancellations or changes to your
          appointment. Mobile guests who provide less than 24 hours notice or
          miss their mobile appointment, may be charged a $100 cancellation fee.
        </Text>
        {/* <FormCheckbox
        >
          I am aware of the Cancellation Policy.<span>- Required</span>
        </FormCheckbox> */}

        <Title level={5} className="tradeGothic">
          Notice of Privacy Practices
        </Title>
        <Text className="tradeGothic">
          <span>
            THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE
            OBTAINED, USED AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS
            INFORMATION.
          </span>
          <span>PLEASE REVIEW IT CAREFULLY.</span>
          <span>
            Treatment: Your health information may be used by staff members or
            disclosed to other health care professionals for the purpose of
            reviewing your health, and providing therapies. Liquid, LLC., its
            agents and assigns do not evaluate health or diagnose medical
            conditions. For example, results of laboratory tests and procedures
            will be available in your medical record to all health professionals
            and Liquid employees and affiliates who may provide therapies or who
            may be consulted by staff members.
          </span>
          <span>
            Payment: Your health information may be used to seek payment from
            your HSA, banking institutions and credit card companies that you
            may use to pay for services. Your health information may also be
            disclosed to other health care providers to assist them in obtaining
            payment for services they have provided to you.
          </span>
          <span>
            Health care operations: Your health information may be used as
            necessary to review and adjust the day-to-day activities and
            management of Liquid. For example, information on the services you
            received may be used to support budgeting and financial reporting,
            fraud and abuse detection and compliance programs, and activities to
            evaluate and promote quality. We may also share your health
            information with other healthcare providers when they request this
            information to help them with their quality assessment and
            improvement activities, their patient-safety activities, their
            population-based efforts to improve health or reduce health care
            costs, their protocol development, case management or
            care-coordination activities, their review of competence,
            qualifications and performance of health care professionals, their
            training programs, their accreditation, certification or licensing
            activities, or their health care fraud and abuse detection and
            compliance efforts. We may also share your medical information with
            our “business associates” that perform administrative services for
            us. We have a written contract with each of these business
            associates that contains terms requiring them and their
            subcontractors to protect the confidentiality and security of your
            medical information.
          </span>
          <span>
            Law Enforcement: Your health information may be disclosed to law
            enforcement agencies, without your permission, to support government
            audits and inspections, to facilitate law-enforcement
            investigations, to comply with government mandated reporting, and
            for other law enforcement purposes.
          </span>
          <span>
            Public health reporting: Your health information may be disclosed to
            public health agencies as required by law. For example, we are
            required to report certain communicable diseases to the state’s
            public health department. When we report suspected elder or
            dependent adult abuse or domestic violence, we will inform you or
            your personal representative promptly unless in our best
            professional judgment, we believe the notification would place you
            at risk of serious harm or would require informing a personal
            representative we believe is responsible for the abuse or harm.
          </span>
          <span>
            Required by Law: As required by law in certain circumstances other
            than public health reporting, your health information may be used
            and disclosed by our staff, but such use and disclosure will be
            limited to the relevant requirements of the law concerning such
            specific circumstances. Further, in the case of a breach of
            unsecured protected health information, we will notify you as
            required by law.
          </span>
          <span> Additional Uses of Information</span>
          <span>
            Appointment reminders: Your health information will be used by our
            staff to send you appointment reminders.
          </span>
          <span>
            Information about therapies and services: Your health information
            may be used to send you information on the therapies and services
            available and general health information that you may find to be of
            interest. We may also send you information describing other
            health-related goods and service that we believe may interest you.
          </span>
          <span> Other Uses and Disclosures Require your Authorization</span>
          <span>
            Disclosure of your health information or its use for any purpose
            other than those listed above requires your specific written
            authorization. Even if you provide this written authorization, you
            are always free to change your mind at a later date regarding the
            authorization. If you change your mind after authorizing a use or
            disclosure of your information, you may submit a written revocation
            of the authorization. However, your decision to revoke the
            authorization will not affect or undo any use or disclosure of
            information that occurred before you notified us of your decision.
          </span>
          <span> Individual Rights</span>
          <span>
            You have certain rights under the federal privacy standards. These
            include:
          </span>
          <ul>
            <li>
              • &nbsp; The right to request restrictions on the use and
              disclosure of your protected health information by a written
              request specifying what information you want to limit, and what
              limitations on our use or disclosure of that information you wish
              to have imposed. We may not be required to agree to the
              restriction that you requested due to limitations contained in the
              applicable laws and we will notify you of our decision to reject
              your request;
            </li>
            <li>
              • &nbsp; The right to receive communications from us concerning
              the service and therapies received by you and your health
              information through reasonable, confidential alternative means
              selected by you;
            </li>
            <li>
              • &nbsp; The right to inspect and copy your protected health
              information. To access your medical information, you must submit a
              written request detailing what information you want access to,
              whether you want to inspect it or get a copy of it, and if you
              want a copy, your preferred form and format. We will provide
              copies in your requested form and format if it is readily
              producible, or we will provide you with an alternative format you
              find acceptable. We will charge a reasonable fee which covers our
              costs for labor, supplies, postage, and if requested and agreed to
              in advance, the cost of preparing an explanation or summary, as
              allowed by applicable law. We may deny your request under limited
              circumstances;
            </li>
            <li>
              • &nbsp; The right to amend or submit corrections to your
              protected health information by submitting a written request
              including the reasons you believe the information is incorrect or
              incomplete. We are not required to change your health information
              and will provide you with information regarding our denial of such
              requested amendment. If we deny your request, you may submit a
              written statement of your disagreement with that decision, and we
              may, in turn, prepare a written rebuttal.
            </li>
          </ul>
          <span> Individual Rights </span>
          <span>
            You have certain rights under the federal privacy standards. These
            include:
          </span>
          <ul>
            <li>
              • &nbsp; The right to request restrictions on the use and
              disclosure of your protected health information by a written
              request specifying what information you want to limit, and what
              limitations on our use or disclosure of that information you wish
              to have imposed. We may not be required to agree to the
              restriction that you requested due to limitations contained in the
              applicable laws and we will notify you of our decision to reject
              your request;
            </li>
            <li>
              • &nbsp; The right to receive communications from us concerning
              the service and therapies received by you and your health
              information through reasonable, confidential alternative means
              selected by you;
            </li>
            <li>
              • &nbsp; The right to inspect and copy your protected health
              information. To access your medical information, you must submit a
              written request detailing what information you want access to,
              whether you want to inspect it or get a copy of it, and if you
              want a copy, your preferred form and format. We will provide
              copies in your requested form and format if it is readily
              producible, or we will provide you with an alternative format you
              find acceptable. We will charge a reasonable fee which covers our
              costs for labor, supplies, postage, and if requested and agreed to
              in advance, the cost of preparing an explanation or summary, as
              allowed by applicable law. We may deny your request under limited
              circumstances;
            </li>
            <li>
              • &nbsp; The right to amend or submit corrections to your
              protected health information by submitting a written request
              including the reasons you believe the information is incorrect or
              incomplete. We are not required to change your health information
              and will provide you with information regarding our denial of such
              requested amendment. If we deny your request, you may submit a
              written statement of your disagreement with that decision, and we
              may, in turn, prepare a written rebuttal.
            </li>
            <li>
              • &nbsp; The right to receive an accounting of how and to whom
              your protected health information has been disclosed; provided,
              however, we are not required to provide to you an accounting of
              disclosures made for the purposes of treatment, payment, health
              care operations, information provided directly to you, information
              provided pursuant to your written authorization, and certain
              government functions.
            </li>
            <li>
              • &nbsp; The right to receive a printed copy of this notice.
            </li>
            <li>
              • &nbsp; If for any reason there is an unauthorized use/disclosure
              of your protected health information, you will receive written
              communication from our office.
            </li>
          </ul>
          <span>Special Situations</span>
          <ul>
            <li>
              • &nbsp; Military, Veterans, National Security and Intelligence:
              If you are or were a member of the armed forces, or part of the
              national security or intelligence communities, we may be required
              by military command or other government agencies to disclose your
              medical information.
            </li>
            <li>
              • &nbsp; Worker’s Compensation: We may release protected health
              information about you for worker’s compensation or similar
              programs. These programs provide benefits for work-related
              injuries or illness.
            </li>
            <li>
              • &nbsp; Health Oversight Activities: We may disclose protected
              health information to a health oversight agency for audits,
              investigations, inspections, or licensing purposes. These
              disclosures may be necessary for certain state and federal
              agencies to monitor the health care system, government programs,
              and compliance with civil rights laws.
            </li>
            <li>
              • &nbsp; Lawsuits and Disputes: If you are involved in a lawsuit
              or a dispute, we may disclose health information about you in
              response to a court or administrative order. Subject to all
              applicable legal requirements, we may also disclose health
              information about you in response to a subpoena.
            </li>
            <li>
              • &nbsp; Law Enforcement: We may release protected health
              information if asked to do so by a law enforcement official in
              response to a court order, subpoena, warrant, summons or similar
              process, subject to all applicable legal requirements.
            </li>
          </ul>
          <span> Liquid Duties</span>
          <ul>
            <li>
              • &nbsp; We are required by law to maintain the privacy of your
              protected health information and to provide you with this notice
              of privacy practices.
            </li>
            <li>
              • &nbsp; We also are required to abide by the privacy policies and
              practices that are outlined in this notice.
            </li>
          </ul>
          <span>Rights to Revise Privacy Practices</span>
          <span>
            As permitted by law, we reserve the right to amend or modify our
            privacy policies and practices. These changes in our policies and
            practices may be required by changes in federal and state laws and
            regulations. Whatever the reason for these revisions, we will
            provide you with a revised notice on your next office visit. The
            revised policies and practices will be applied to all protected
            health information that we maintain, regardless of when it was
            created or received.
          </span>
          <span>Requests to Inspect Protected Health Information</span>
          <span>
            As permitted by federal regulation, we require that requests to
            inspect or copy protected health information be submitted in
            writing. You may obtain a form to request access to your records by
            contacting us.
          </span>
          <span>Complaints</span>
          <span>
            If you would like to submit a comment or complaint about our privacy
            practices, you can do so by sending a letter outlining your
            concerns. You also have the right to submit a complaint to the
            Secretary of the Department of Health and Human Services if you
            believe your privacy rights have been violated.
          </span>
          <span> Our contact information is:</span>
          <span>
            Liquid Mobile IV <br />
            11775 W. 112th St. <br />
            Overland Park, KS 66210
          </span>
        </Text>
        {/* <FormCheckbox>
          I acknowledge that I have seen this form ad had an opportunity to ask
          any questions.<span>- Required</span>
        </FormCheckbox> */}

        <Title level={5} className="tradeGothic">
          Informed Consent - COVID 19 Pandemic
        </Title>
        <Text>
          <span>
            I understand that I am opting for a service with Liquid Mobile IV
            that is not urgent and may not be medically necessary.
          </span>
          <span>
            I also understand that the novel coronavirus, COVID-19, has been
            declared a worldwide pandemic by the World Health Organization. I
            further understand that COVID-19 is extremely contagious and is
            believed to spread by person-to-person contact; and, as a result,
            federal and state health agencies recommend social distancing. I
            recognize that the physician and all staff at Liquid Mobile IV are
            closely monitoring this situation and have put in place reasonable
            preventative measures aimed to reduce the spread of COVID-19.
            However, given the nature of the virus, I understand there is an
            inherent risk of becoming infected with COVID-19 by virtue of
            proceeding with this service. I hereby acknowledge and assume the
            risk of becoming infected with COVID-19 through this service, and I
            give my express permission for Liquid Mobile IV physician and staff
            to proceed with the same.
          </span>
          <span>
            I understand that, even if I have been tested for COVID and received
            a negative test result, the tests in some cases may fail to detect
            the virus or I may have contracted COVID after the test.
          </span>
          <span>
            I understand that, if I have a COVID-19 infection, and even if I do
            not have any symptoms for the same, proceeding with this service can
            lead to a higher chance of complication and death.
          </span>
          <span>
            I understand that possible exposure to COVID-19 before/during/after
            my service may result in the following: a positive COVID-19
            diagnosis, extended quarantine/self-isolation, additional tests,
            hospitalization that may require medical therapy, Intensive Care
            treatment, possible need for intubation/ventilator support,
            short-term or long-term intubation, other potential complications,
            and the risk of death. In addition, after my elective
            treatment/procedure/surgery, I may need additional care that may
            require me to go to an emergency room or a hospital.
          </span>
          <span>
            I understand that COVID-19 may cause additional risks, some or many
            of which may not currently be known at this time, in addition to the
            risks described herein, as well as those risks for the service
            itself.
          </span>
          <span>
            I have been given the option to defer the service to a later date.
            However, I understand all the potential risks, including but not
            limited to the potential short-term and long-term complications
            related to COVID-19, and I would like to proceed with my desired
            service.
          </span>
        </Text>
        {/* <FormCheckbox>
          I UNDERSTAND THE EXPLANATION AND HAVE NO MORE QUESTIONS AND CONSENT TO
          THE PROCEDURE.<span>- Required</span>
        </FormCheckbox> */}

        <Title level={5} className="tradeGothic">
          Liquid Practice Policy and Acknowledgement of Provider
        </Title>
        <Text>
          <span>
            You will be evaluated by a trained and licensed nurse that is
            overseen by a provider to ensure that it is appropriate to provide
            you with this service. We wish to take this opportunity to welcome
            you and to state some basic principles we believe are essential in
            establishing a good relationship between us. Please read through
            this information, ensure that you are comfortable with it and feel
            free to ask questions, as needed.
          </span>
          <span>
            1. &nbsp; INITIAL INTERVIEW: Your first history and physical is
            considered an evaluation interview and exam. At the time of this
            appointment, the following decisions will be made with you regarding
            the provision of our services:
          </span>
          <ol>
            <li>
              a) &nbsp; If your requested therapy is appropriate based on the
              information you disclosed
            </li>
            <li> b) &nbsp; Frequency of the sessions</li>
            <li>
              c) &nbsp; Goals of therapy (what you hope to gain from this
              process)
            </li>
          </ol>
          <span>
            2. &nbsp; APPOINTMENTS: Each appointment varies in length depending
            on your request. Typically, infusion appointments take just under 1
            hour. At the end of each appointment, you can make arrangement for
            your next appointment, or you may also book all your appointments at
            once.
          </span>
          <span>
            3. &nbsp; CANCELLATIONS: If you find that you need to cancel a
            future appointment, please give as much notice as possible so that
            we can schedule other clients that are on our waiting list. If you
            fail to cancel your appointment at least 24 hours in advance of our
            appointment date and time, you will be personally charged for the
            full price of your scheduled appointment.
          </span>
          <span>
            4. &nbsp; PAYMENTS: We would greatly appreciate payment in full for
            each appointment is required prior to the start of your appointment.
            We accept credit cards, electronic payments, as well as cash.
          </span>
          <span>
            5. &nbsp; NO INSURANCE: We do not currently bill for or accept
            insurance payments for our services.
          </span>
          <span>
            6. &nbsp; PETS: Pets must be put away, and not have access to the
            Guest being assisted, the nurse or the supplies. This is for the
            safety of everyone involved.
          </span>
          <span>
            7. &nbsp; CONFIDENTIALITY: All information regarding the specific
            nature of your treatment is maintained within the HIPAA compliant
            Electronic Medical Record and is considered confidential, and will
            not be disclosed to anyone. unless otherwise authorized by law or by
            written authorization by you. However, each provider at this office
            reserves the right to use specialty consultation with other medical
            providers affiliated with Liquid. We follow all requirements and
            guidelines of HIPAA regarding protected health care information and
            security of that information.
          </span>
        </Text>
        {/* <FormCheckbox>
          I acknowledge that I have read, obtained and understand all of the
          foregoing statements and that my signature below indicates that I
          agree to abide by all of the above conditions, and that I have seen
          this form and had an opportunity to ask any questions.
          <span>- Required</span>
        </FormCheckbox> */}
        <Title level={5} className="tradeGothic">
          Membership Consent
        </Title>
        <Text>
          If enrolled in a membership, I authorize Liquid Mobile IV to bill and
          generate charges to my credit card of record for all relevant services
          provided and to automatically renew my membership fee unless prior
          written cancellation is received prior to the due date of membership
          payment.
        </Text>
        <Title level={5} className="tradeGothic">
          Informed Consent for Treatment
        </Title>
        <Text>
          <span>
            Liquid provides intravenous and intramuscular therapies, and lab
            tests. A trained Liquid practitioner administers the lab testing,
            and intravenous and intramuscular injection therapies under the
            direction of a licensed physician. You have the right to be fully
            informed of the detailed process of the therapy, as well as the
            benefits and any potential risks of the therapy and may refuse to
            proceed with the therapy at any time during the process.
          </span>
          <span>
            Intravenous and intramuscular therapy involves inserting an
            intravenous catheter and administering intravenous fluid through the
            vein. The intramuscular injection therapy involves injecting
            nutrients directly into a muscle. Lab tests involves but is not
            limited to swaps, blood draw. These therapies are recommended for
            replacement of essential nutrients, correction of nutritional
            deficiencies, and for other health promoting effects, such as
            improving immune function, improving antioxidant status, reducing
            oxidative damage, and improving energy.
          </span>
          <span>
            These therapies are considered medically unnecessary and are not
            warrantied or guaranteed to cure any disease or ailment that you may
            have. They may not mitigate, alleviate, or treat any medical
            condition. These therapies have been recommended for their health
            promoting benefits and their use is intended to support and improve
            the condition of your overall health.
          </span>
          <span>
            Benefits of intravenous, intramuscular therapy and lab tests
            include:
          </span>
          <ul>
            <li> • &nbsp; Increases Hydration</li>
            <li> • &nbsp;Increases cellular metabolism and health</li>
            <li>
              • &nbsp;Helps achieve optimum health by restoring depleted vitamin
              and mineral levels
            </li>
            <li> • &nbsp;Supports the immune system</li>
            <li> • &nbsp;Verify status blood levels</li>
          </ul>
          <span>Side Effects/Risks</span>
          <span>
            Although rare, there are some risks associated with intravenous and
            intramuscular therapy. Including:
          </span>
          <span>
            1. The procedure involves inserting a needle into a vein and
            injecting the prescribed solution.
          </span>
          <span>
            2. Alternatives to intravenous therapy are oral supplementation and
            / or dietary and lifestyle changes.
          </span>
          <span>
            3. Risks of intravenous and intramuscular therapy and lab testing
            include but not limited to:
          </span>
          <ol>
            <li>
              a. Occasionally to commonly:
              <ol>
                <li>
                  i. Discomfort, bruising and pain at the site of injection.
                </li>
                <li>
                  ii. General feeling of warmth during and after injection
                </li>
              </ol>
            </li>
            <li>
              b. Rarely:
              <ol>
                <li>
                  i. Inflammation of the vein used for injection, phlebitis,
                  metabolic disturbances, and injury.{" "}
                </li>
                <li>
                  ii. Reactive Hypotension (or rapid drop in blood pressure)
                </li>
                <li>
                  iii. Reactive Hypoglycemia (or rapid drop in blood sugar)
                </li>
              </ol>
            </li>
            <li>
              c. Extremely Rarely: Severe allergic reaction, anaphylaxis,
              infection, cardiac arrest and death.
            </li>
          </ol>
          <span> The Procedure</span>
          <span>
            The IV intravenous procedure involves inserting a needle into your
            vein and infusing the solution of prescribed nutrients (vitamins,
            minerals, amino acids) or chelation agents over a determined period
            of time. An intramuscular injection involves injecting the agent
            into the muscle. A lab test involves but is not limited to swaps and
            blood draws.
          </span>
          <span>
            Your vitals will be measured prior to and after your therapy.
          </span>
          <span>What Safety Precautions Must You Take? </span>
          <ul>
            <li>
              • &nbsp; After therapy is provided, monitor the insertion site for
              signs and symptoms of infection (redness, swelling, discharge). If
              any of these signs are identified, notify Liquid immediately. If
              your experience a sustained fever greater than 101, do not delay
              treatment and go to the ER as this can be a sign of sepsis.
            </li>
            <li>
              • &nbsp; If you experience a minor side effect while you are at
              home, you should contact the Liquid, otherwise contact your
              medical provider or call 911.
            </li>
            <li>
              • &nbsp; After therapy is provided, monitor the insertion site for
              signs and symptoms of infection (redness, swelling, discharge). If
              any of these signs are identified, notify Liquid immediately. If
              your experience a sustained fever greater than 101, do not delay
              treatment and go to the ER as this can be a sign of sepsis.
            </li>
            <li>• For emergent situations, dial 911.</li>
          </ul>
          <span>
            My Consent for Nutrient Infusion, Intramuscular Injections, Lab
            Testing is Voluntary. My request for nutrient infusion,
            intramuscular injections, lab testing as described above is entirely
            voluntary and I have not been offered any inducement to participate
            or provide this consent. I understand that I may refuse to
            participate in this therapy at any time during this process.
          </span>
        </Text>
        {/* <FormCheckbox>
          I have read this consent form and understand the information contained
          in it. I understand the benefits and risks and have had the
          opportunity to have all my questions answered to my satisfaction prior
          to participation. I am aware that other unforeseeable complications
          could occur. I do no expect the provider(s) to anticipate and or
          explain all risks or possible complications to me. I rely on the
          provider(s) to exercise reasonable judgement during the course of
          treatment with regards to my procedure. My signature on this form
          affirms that I give my consent to IV nutrient, intramuscular, testing
          therapy.
          <span>- Required</span>
        </FormCheckbox> */}

        <Title level={5} className="tradeGothic">
          Release of Medical Information
        </Title>
        <Text>
          I hereby authorize Liquid to disclose my medical records Liquid staff
          or contracted workers, to EMS, my spouse, and emergency contact. I
          also authorize Liquid to discuss my care and share my medical
          information for the purposes of monitoring, quality control or safety
          concerns
        </Text>
        {/* <FormCheckbox>
          I hereby authorize Liquid to disclose my medical records to Liquid
          staff or contract workers, to EMS, my spouse and emergency contact. I
          also authorize Liquid to discuss my care and share my medical
          information for the purpose of monitoring, quality control or safety
          concerns
          <span>- Required</span>
        </FormCheckbox> */}

        <Title level={5} className="tradeGothic">
          Age Consent{" "}
        </Title>
        <Text>I am 18 or older.</Text>
        {/* <FormRadioGroup
          required
          className="boldRadio"
          name="consentOfRecipientAge"
          //   label="How many days a week do you exercise?"
          options={[
            { label: "I am over the age of 18", value: true },
            {
              label:
                "I hereby acknowledge that I am the legal guardian for the individual listed above who is going to receive IV therapy and by my signature below, hereby cerify that I am authorized to make health care decisions for this individual and do so voluntarily and with full knowledge of the services being provided, including potential risks and side effects. I hereby agree that no guarantees have been made to me as to my effect services on my child. I hereby give consent to treat.",
              value: false,
            },
          ]}
        /> */}

        <Signature signature={signature} disabled />

        {/* <FormHiddenSubmitButton submitRef={submitBtnRef} /> */}
      </Form>
    </Modal>
  );
}

ConsentForm.prototype = {};
