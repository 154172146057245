import React, { useEffect, useRef } from "react";
import {
  Col,
  Drawer,
  Form,
  Row,
  Typography,
  Button as AntdButton,
  Upload,
  Space,
  Divider,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import {
  FormInput,
  FormInputArea,
  FormHiddenSubmitButton,
  FormPhoneNumber,
  FormSelect,
  FormCheckbox,
} from "components/FormItems/FlatFormItems";
import { useForm } from "antd/lib/form/Form";
import { Button } from "components/Buttons/Buttons";
import FilesUploader from "components/FilesUploader/FilesUploader";
import { useDispatch } from "react-redux";
import { getServiceTypeById } from "services/serviceTypeSettings.service";

const { Title, Text } = Typography;
const { Dragger } = Upload;

const PRICINGS = [1, 2, 3];

export default function ServiceTypeDrawer({
  visible,
  onClose,
  data,
  onSuccess,
}) {
  const [form] = useForm();
  const submitBtnRef = useRef();

  const dispatch = useDispatch();

  const getData = async (id) => {
    const result = await dispatch(getServiceTypeById(id));
    if (result?.status === "success") {
      form.setFieldsValue({
        ...result.data,
      });
    }
  };

  useEffect(() => {
    if (visible === false) {
      form.resetFields();
    } else if (data?.serviceTypeId !== undefined) {
      form.setFieldsValue({
        ...data,
      });
      getData(data?.serviceTypeId);
    }
  }, [visible]);

  return (
    <Drawer
      destroyOnClose
      headerStyle={{ border: "none" }}
      className="serviceTypeDrawer"
      width={"min(100vw, 420px)"}
      title={
        <div className="serviceTypeDrawerTitle">
          <p>
            {visible && (data?.serviceTypeId !== undefined ? "Edit" : "Create")}{" "}
            Service Type
          </p>
          <CloseOutlined onClick={onClose} />
        </div>
      }
      placement="right"
      onClose={onClose}
      visible={visible}
      closable={false}
      footer={
        <div className="serviceTypeDrawerFooter">
          <AntdButton type="text" onClick={onClose}>
            Cancel
          </AntdButton>
          <Button
            rounded
            type={"primary"}
            style={{ height: 50, width: "50%" }}
            onClick={() => submitBtnRef?.current?.click()}
          >
            Save
          </Button>
        </div>
      }
    >
      <Form
        preserve={false}
        layout="vertical"
        form={form}
        requiredMark={false}
        onFinish={(values) => {
          onSuccess(values);
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
      >
        <Row gutter={[24, 24]}>
          <Col xs={24}>
            <FormInput
              required
              name="serviceTypeName"
              variant="underlined"
              label="Service Type Name"
              placeholder="Category Name"
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <FormHiddenSubmitButton submitRef={submitBtnRef} />
        </Row>
      </Form>
    </Drawer>
  );
}
