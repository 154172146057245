import React, { useEffect, useReducer, useState } from "react";

import "./Categories.style.scss";
import { Button } from "components/Buttons/Buttons";
import { Dropdown, Menu, Modal, Select, Space, Table, Typography } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import useSettingsHeader from "hooks/useSettingsHeader";
import CategoriesDrawer from "./components/CategoriesDrawer";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  createCategory,
  deleteCategory,
  getAllCategories,
  restoreCategory,
  updateCategory,
} from "services/categoriesSettings.services";
import confirm from "antd/lib/modal/confirm";
import usePortal from "hooks/usePortal";
import SearchAndFilterColumns from "components/SearchAndFilterColumns/SearchAndFilterColumns";
import { push } from "connected-react-router";
const { Title, Text, Link } = Typography;

const reducer = (state, action) => {
  switch (action.type) {
    case "SET":
      return action.data;
    case "ADD":
      return [...state, action.data];
    case "UPDATE":
      return state.map((data) => {
        if (data.categoryId === action.data.categoryId) return action.data;
        return data;
      });
    case "DELETE":
      return state.filter((data) => {
        if (data.categoryId === action.data.categoryId) return false;
        return true;
      });
    default:
      return state;
  }
};

export default function Categories() {
  //states start
  const [showAddEditModal, setShowAddEditModal] = useState();
  const [data, localDispatch] = useReducer(reducer, []);

  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [activeFilter, setActiveFilter] = useState("active"); // all, active, inactive
  const [searchText, setSearchText] = useState("");
  //states end

  // non-data hooks start
  const dispatch = useDispatch();
  // non-data hooks end

  // use effect starts
  const loadData = async () => {
    const result = await dispatch(getAllCategories());
    if (result?.status === "success") {
      localDispatch({
        type: "SET",
        data: result.data,
      });
    }
  };
  useEffect(() => {
    loadData();
  }, []);
  // use effects end

  // functions start
  const handleAdd = (values) => {
    dispatch(createCategory(values)).then((result) => {
      if (result?.status === "success") {
        setShowAddEditModal();
        loadData();
        // loadData();
      }
    });
  };
  const handleUpdate = (id, values) => {
    dispatch(updateCategory(id, values)).then((result) => {
      if (result?.status === "success") {
        setShowAddEditModal();
        loadData();
      }
    });
  };
  const handleDelete = (id) => {
    dispatch(deleteCategory(id)).then((result) => {
      if (result?.status === "success") {
        loadData();
      }
    });
  };
  const handleRestore = (id) => {
    dispatch(restoreCategory(id)).then((result) => {
      if (result?.status === "success") {
        loadData();
      }
    });
  };
  const deleteConfirmation = (id) => ({
    title: "Inactivate Category",
    content: <>Are you sure that you want to inactivate the category?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      handleDelete(id);
      close();
    },
  });

  const restoreConfirmation = (id) => ({
    title: "Activate Category",
    content: <>Are you sure that you want to activate the category?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      handleRestore(id);
      close();
    },
  });

  const checkActiveFilter = (item) => {
    if (activeFilter === "all") return true;
    else if (activeFilter === "active" && item?.isActive) return true;
    else if (activeFilter === "inactive" && !item?.isActive) return true;
    return false;
  };
  // functions end

  const settingHeaderContent = useSettingsHeader(
    <Button
      rounded={true}
      onClick={() => {
        setShowAddEditModal(true);
      }}
    >
      Create Category
    </Button>
  );

  const columns = [
    {
      title: "Category Name",
      dataIndex: "categoryName",
      key: "categoryName",

      sorter: (a, b) => {
        if (a.categoryName?.toLowerCase?.() < b.categoryName?.toLowerCase?.())
          return -1;
        if (a.categoryName?.toLowerCase?.() > b.categoryName?.toLowerCase?.())
          return 1;
        return 0;
      },
    },
    // {
    //   title: "Date Created",
    //   render: (data) => {
    //     const date = moment(data.createdOn);
    //     return <>{date.format("L")}</>;
    //   },
    // },
    // {
    //   title: "Date Updated",
    //   render: (data) => {
    //     const date = moment(data.modifiedOn);
    //     return <>{!!data.modifiedOn ? date.format("L") : "NA"}</>;
    //   },
    // },
    {
      title: "Services Count",
      key: "servicesCount",
      width: 180,
      align: "center",
      sorter: (a, b) => {
        if (a.categoryServiceCount < b.categoryServiceCount) return -1;
        if (a.categoryServiceCount > b.categoryServiceCount) return 1;
        return 0;
      },
      render: (data) => {
        return (
          <Link
            disabled={!data?.isActive}
            onClick={() => {
              if (data?.isActive)
                dispatch(
                  push(
                    `/dashboard/settings/services-and-boosts?category=${data?.categoryId}`
                  )
                );
            }}
          >
            {data?.categoryServiceCount}
          </Link>
        );
      },
    },
    {
      title: "Active",
      key: "active",
      width: 100,
      render: (data) => {
        if (data?.isActive) return "Active";
        return "Inactive";
      },
    },
    {
      title: "",
      key: "action",
      align: "right",
      width: 80,
      render: (data) => {
        return (
          <Dropdown
            overlay={
              <Menu style={{ borderRadius: 6, border: "2px solid #CDD5D8 " }}>
                <Menu.Item onClick={() => setShowAddEditModal(data)} key="edit">
                  Edit
                </Menu.Item>
                <Menu.Item
                  key="delete"
                  onClick={() => {
                    if (data?.isActive)
                      Modal.warning(deleteConfirmation(data.categoryId));
                    else Modal.warning(restoreConfirmation(data.categoryId));
                  }}
                >
                  {data?.isActive ? "Inactivate" : "Activate"}
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
            getPopupContainer={(triggerNode) => triggerNode?.parentNode}
          >
            <MoreOutlined style={{ fontSize: 18 }} />
          </Dropdown>
        );
      },
    },
  ];

  const searchAndFiltersContent = usePortal(
    "#settingHeader .settingsHeaderLeftSideAddon",
    <Space>
      <SearchAndFilterColumns
        columns={columns}
        hiddenColumns={hiddenColumns}
        setHiddenColumns={(columnTitles) => {
          setHiddenColumns(columnTitles);
        }}
        searchText={searchText}
        setSearchText={(text) => {
          setSearchText(text);
        }}
      />
      <Select
        style={{ width: 120 }}
        value={activeFilter}
        onChange={(value) => {
          setActiveFilter(value);
        }}
        options={[
          {
            value: "all",
            label: "All",
          },
          {
            value: "active",
            label: "Active",
          },
          {
            value: "inactive",
            label: "Inactive",
          },
        ]}
      />
    </Space>
  );

  return (
    <div>
      {settingHeaderContent}
      {searchAndFiltersContent}
      <Table
        rowKey={(data) => data.categoryId}
        tableLayout="auto"
        locale={{ emptyText: "No Category" }}
        dataSource={data?.filter(
          (it) =>
            checkActiveFilter(it) &&
            it?.categoryName
              ?.toLowerCase?.()
              .includes?.(searchText.toLowerCase())
        )}
        columns={columns.filter((column) => {
          if (!hiddenColumns.includes(column.title)) return true;
          return false;
        })}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: [15, 25, 50, 100, 250],
        }}
        // scroll={{ y: "calc(100vh - 80px - 96px - 60px - 100px)", x: true }}
      />

      <CategoriesDrawer
        data={showAddEditModal}
        visible={showAddEditModal}
        onClose={() => {
          setShowAddEditModal();
        }}
        onSuccess={(values) => {
          const isEdit = showAddEditModal?.categoryId !== undefined;
          if (isEdit) handleUpdate(showAddEditModal?.categoryId, values);
          else handleAdd(values);
        }}
      />
    </div>
  );
}
