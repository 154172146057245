import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  FormInput,
  FormInputArea,
  FormButton,
  FormPassword,
  FormSelect,
  FormPhoneNumber,
  FormDatePicker,
} from "components/FormItems/FlatFormItems";
import {
  Space,
  Form,
  Row,
  Col,
  Typography,
  Button as AntdButton,
  Avatar,
} from "antd";
import { Button } from "components/Buttons/Buttons";
import Drawer from "components/Drawer/Drawer";
import moment from "moment";

import StripePayment from "./Stripe";
import {
  createGuest,
  getCustomerSetupIntent,
  postPaymentMethodId,
  setPrimaryPaymentMethod,
} from "services/guests.services";
import { CardNumberElement, CardExpiryElement } from "@stripe/react-stripe-js";
import { openNotificationWithIcon } from "utils/Notification";
import { setLoading } from "redux/actions/app.actions";
import useLocation from "hooks/useLocation";
import LocationDropdown from "components/LocationDropdown/LocationDropdown";
import { setSelectedGuest } from "redux/actions/scheduler.actions";
import { getStateAndCountryFromZip } from "services/locationCenter.services";
import { setAppointmentDrawer } from "redux/actions/drawer.actions";

function GuestDrawer({ visible, onClose, showAppointmentDrawerOnClose }) {
  const [form] = Form.useForm();

  const [isCardEmpty, setisCardEmpty] = useState(true);
  const dispatch = useDispatch();

  const stripeErrorListener = (event) => {
    setisCardEmpty(event.empty);
  };

  useEffect(() => {
    if (visible === false) {
      form.resetFields();
      setisCardEmpty(true);
    }
    return () => {};
  }, [visible]);

  return (
    <Drawer
      className="guestDrawer"
      width={"min(100vw, 735px)"}
      size="large"
      visible={visible}
      onClose={onClose}
      text="Add New Guest"
      destroyOnClose={false}
    >
      <Form
        preserve={false}
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
        onFinish={(values) => {}}
      >
        <Row gutter={[24, 16]}>
          <Col span={24}>
            <Space size={14}>
              <Avatar size={50}>1</Avatar>
              <Typography style={{ fontSize: 18 }}>
                Personal Information
              </Typography>
            </Space>
          </Col>
          <Col xs={24} md={12}>
            <FormInput
              variant="underlined"
              name="firstName"
              label="First Name"
              placeholder="First Name"
              required={true}
              formItemStyles={{ marginBottom: 0 }}
            />
          </Col>
          <Col xs={24} md={12}>
            <FormInput
              variant="underlined"
              name="lastName"
              label="Last Name"
              placeholder="Last Name"
              required={true}
              formItemStyles={{ marginBottom: 0 }}
            />
          </Col>

          <Col xs={24} md={12}>
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <FormInput
                  variant="underlined"
                  name="email"
                  label="Email Address"
                  type="email"
                  placeholder="Email Address"
                  formItemStyles={{ marginBottom: 0 }}
                  required={true}
                />
              </Col>
            </Row>
          </Col>

          <Col xs={24} md={12}>
            <Row gutter={[0, 16]}>
              <Col span={24}>
                <FormPhoneNumber
                  variant="underlined"
                  name="phoneNo"
                  label="Mobile Number"
                  placeholder="Mobile Number"
                  formName="basicInformationForm"
                  formItemStyles={{ marginBottom: 0 }}
                  country="us"
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={12}>
            <FormDatePicker
              required={true}
              form={form}
              name="dob"
              label="Date of Birth"
              variant="underlined"
              formItemStyles={{ marginBottom: 0 }}
              disabledDate={(current) =>
                current.isSameOrAfter(
                  moment().set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                    millisecond: 0,
                  })
                )
              }
            />
          </Col>

          <Col xs={24} md={12}>
            <LocationDropdown
              required
              className={"flatFormItem underlined addressLocation"}
              name={"streetAddress1"}
              form={form}
              onChange={(e) => {
                form.setFieldsValue({
                  streetAddress1: e.target.value,
                });
              }}
              label={"Street Address"}
            />
          </Col>
          <Col xs={0} md={12}></Col>
          <Col span={24}>
            <FormInputArea
              variant="underlined"
              rows={6}
              name="instructions"
              label="Address Instructions"
              placeholder="Add instructions"
              formItemStyles={{ marginBottom: 0 }}
            />
          </Col>
        </Row>
        <Row gutter={[24, 16]} style={{ paddingTop: 20 }}>
          <Col span={24}>
            <Space size={14}>
              <Avatar size={50}>2</Avatar>
              <Typography style={{ fontSize: 18 }}>
                Payment Information (Optional)
              </Typography>
            </Space>
          </Col>
        </Row>
      </Form>
      <div style={{ marginTop: 16 }}>
        {visible && (
          <StripePayment
            onClose={onClose}
            isRequired={!isCardEmpty}
            stripeErrorListener={stripeErrorListener}
            handleSubmit={(stripe, elements, cardholderName) => {
              // form.validateFields()
              // if()
              form
                .validateFields()
                .then(async (values) => {
                  values.userName = values?.email;
                  values.dob = values.dob
                    ? moment(values.dob).toISOString()
                    : undefined;
                  values.phoneNo =
                    values?.phoneNo === "1" ? undefined : values?.phoneNo;
                  values.countryId = 3;

                  dispatch(setLoading(true));
                  const { state, country, zipCode } =
                    (await getStateAndCountryFromZip(values.streetAddress1)) ??
                    {};

                  values.state = state || "";
                  values.country = country || "";
                  values.zipCode = zipCode || "";

                  if (
                    state === undefined ||
                    country === undefined ||
                    zipCode === undefined
                  ) {
                    openNotificationWithIcon(
                      "error",
                      "Error",
                      "Please provide a valid street address"
                    );
                    return;
                  }

                  let createGuestResponse = await dispatch(createGuest(values));
                  if (createGuestResponse?.status === "success") {
                    const customerId = createGuestResponse.data.customerId;

                    dispatch(setLoading(true));
                    if (isCardEmpty) {
                      dispatch(setSelectedGuest(createGuestResponse.data));
                      if (showAppointmentDrawerOnClose) {
                        dispatch(
                          setAppointmentDrawer({
                            show: true,
                          })
                        );
                      }
                      onClose();
                      form.resetFields();

                      return;
                    }
                    openNotificationWithIcon(
                      "info",
                      "Creating Payment Method",
                      "Please wait. Adding payment method for guest"
                    );

                    const response2 = await dispatch(
                      getCustomerSetupIntent(customerId)
                    );
                    if (response2?.status === "success") {
                      const setupIntent = response2.data;
                      const result = await stripe.confirmCardSetup(
                        setupIntent,
                        {
                          payment_method: {
                            card: elements.getElement(CardNumberElement),
                            billing_details: {
                              name: cardholderName,
                              email: values?.email,
                            },
                            metadata: {
                              isPrimary: true,
                            },
                          },
                        }
                      );
                      if (result?.error) {
                        // Show error to your customer (for example, insufficient funds)
                        openNotificationWithIcon(
                          "error",
                          "Error!",
                          result?.error?.message ||
                            "Network error has occured while adding payment method"
                        );
                      } else {
                        const postedPaymentMethodResult = await dispatch(
                          postPaymentMethodId(
                            result.setupIntent.payment_method,
                            customerId
                          )
                        );

                        if (postedPaymentMethodResult?.status === "success") {
                          const primaryPaymentMethodResult = await dispatch(
                            setPrimaryPaymentMethod(
                              postedPaymentMethodResult?.response
                                ?.paymentMethodId,
                              customerId
                            )
                          );
                          if (
                            primaryPaymentMethodResult?.status === "success"
                          ) {
                            openNotificationWithIcon(
                              "success",
                              "Success!",
                              "Payment method added successfully"
                            );
                            dispatch(
                              setSelectedGuest(createGuestResponse.data)
                            );
                            onClose();
                            form.resetFields();
                            if (showAppointmentDrawerOnClose) {
                              dispatch(
                                setAppointmentDrawer({
                                  show: true,
                                })
                              );
                            }
                          }
                        } else {
                          openNotificationWithIcon(
                            "error",
                            "Error!",
                            result?.error?.message ||
                              "Network error has occured while adding payment method"
                          );
                        }
                      }
                    } else {
                      openNotificationWithIcon(
                        "error",
                        "Error!",
                        "Network error has occured while adding payment method"
                      );
                      return;
                    }
                  }
                  // TODO: close Modal and Reset Fields
                  // Or Maybe Back to Add Appointment Modal
                  // form.resetFields();
                })
                .catch((e) => {
                  form.scrollToField("firstName", {
                    scrollMode: "if-needed",
                    block: "center",
                    behavior: "smooth",
                  });
                  // openNotificationWithIcon(
                  //   "error",
                  //   "Error!",
                  //   "Kindly fill the details of guest"
                  // );
                })
                .finally(() => {
                  dispatch(setLoading(false));
                });
            }}
          />
        )}
      </div>
    </Drawer>
  );
}

GuestDrawer.propTypes = {};

export default GuestDrawer;
