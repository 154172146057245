import React, { useEffect, useReducer, useState } from "react";

import "./ServiceType.style.scss";
import { Button } from "components/Buttons/Buttons";
import { Dropdown, Menu, Modal, Table } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import useSettingsHeader from "hooks/useSettingsHeader";
import ServiceTypeDrawer from "./components/ServiceTypeDrawer";
import { useDispatch } from "react-redux";
import {
  createServiceType,
  deleteServiceType,
  getAllServiceTypes,
  updateServiceType,
} from "services/serviceTypeSettings.service";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET":
      return action.data;
    case "ADD":
      return [...state, action.data];
    case "UPDATE":
      return state.map((data) => {
        if (data.categoryId === action.data.categoryId) return action.data;
        return data;
      });
    case "DELETE":
      return state.filter((data) => {
        if (data.categoryId === action.data.categoryId) return false;
        return true;
      });
    default:
      return state;
  }
};

export default function ServiceType() {
  const [showDetails, setShowDetails] = useState(false);
  const [showAddEditModal, setShowAddEditModal] = useState();

  const [data, localDispatch] = useReducer(reducer, []);
  //states end

  // non-data hooks start
  const dispatch = useDispatch();
  // non-data hooks end

  // use effect starts
  const loadData = async () => {
    const result = await dispatch(
      getAllServiceTypes((data) => {
        localDispatch({ action: "SET", data });
      })
    );
    if (result?.status === "success") {
      localDispatch({
        type: "SET",
        data: result.data,
      });
    }
  };
  useEffect(() => {
    loadData();
  }, []);
  // use effects end

  // functions start
  const handleAdd = (values) => {
    dispatch(createServiceType(values)).then((result) => {
      if (result?.status === "success") {
        setShowAddEditModal();
        loadData();
        // loadData();
      }
    });
  };
  const handleUpdate = (id, values) => {
    dispatch(updateServiceType(id, values)).then((result) => {
      if (result?.status === "success") {
        setShowAddEditModal();
        loadData();
      }
    });
  };
  const handleDelete = (id) => {
    dispatch(deleteServiceType(id)).then((result) => {
      if (result?.status === "success") {
        loadData();
      }
    });
  };
  const deleteConfirmation = (id) => ({
    title: "Delete Service type",
    content: <>Are you sure that you want to delete the service type?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      handleDelete(id);
      close();
    },
  });
  // functions end

  const settingHeaderContent = useSettingsHeader(
    // <Button
    //   rounded={true}
    //   onClick={() => {
    //     setShowAddEditModal(true);
    //   }}
    // >
    //   Create Service Type
    // </Button>
  );

  return (
    <div>
      {settingHeaderContent}
      <Table
        tableLayout="auto"
        locale={{ emptyText: "No Programs" }}
        columns={[
          {
            title: "Service Type Name",
            dataIndex: "serviceTypeName",
            key: "serviceTypeName",
          },

          // {
          //   title: "",
          //   key: "action",
          //   align: "right",
          //   width: 80,
          //   render: (record) => (
          //     <Dropdown
          //       overlay={
          //         <Menu
          //           style={{ borderRadius: 6, border: "2px solid #CDD5D8 " }}
          //         >
          //           <Menu.Item onClick={() => setShowAddEditModal(record)}>
          //             Edit
          //           </Menu.Item>
          //           <Menu.Item
          //             onClick={() =>
          //               Modal.warning(deleteConfirmation(data.serviceTypeId))
          //             }
          //           >
          //             Delete
          //           </Menu.Item>
          //         </Menu>
          //       }
          //       trigger={["click"]}
          //       getPopupContainer={(triggerNode) => triggerNode?.parentNode}
          //     >
          //       <MoreOutlined style={{ fontSize: 18 }} />
          //     </Dropdown>
          //   ),
          // },
        ]}
        dataSource={data}
        // dataSource={allGuests.filter((data) => {
        //   return data.name.toLowerCase().includes(searchText.toLowerCase());
        // })}
        // columns={columns.filter((column) => {
        //   if (!hiddenColumns.includes(column.title)) return true;
        //   return false;
        // })}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: [15, 25, 50, 100, 250],
        }}
        // scroll={{ y: "calc(100vh - 80px - 96px - 60px - 100px)", x: true }}
      />

      <ServiceTypeDrawer
        data={showAddEditModal}
        visible={showAddEditModal}
        onClose={() => {
          setShowAddEditModal();
        }}
        onSuccess={(values) => {
          const isEdit = showAddEditModal?.serviceTypeId !== undefined;
          if (isEdit) handleUpdate(showAddEditModal?.serviceTypeId, values);
          else handleAdd(values);
        }}
      />
    </div>
  );
}
