import React, { useEffect, useReducer, useState } from "react";
import "./Skills.style.scss";
import { Button } from "components/Buttons/Buttons";
import { Dropdown, Menu, Modal, Select, Space, Table, Typography } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import SkillsDrawer from "./components/SkillsDrawer";
import { useDispatch, useSelector } from "react-redux";

import {
  createSkill,
  deleteSkill,
  getAllSkills,
  restoreSkill,
  updateSkill,
} from "services/skills.services";
import usePortal from "hooks/usePortal";
import SearchAndFilterColumns from "components/SearchAndFilterColumns/SearchAndFilterColumns";
import { push } from "connected-react-router";
import useSettingsHeader from "hooks/useSettingsHeader";

const { Title, Text, Link } = Typography;

export default function Skills() {
  //states start
  const [showAddEditModal, setShowAddEditModal] = useState();
  const data = useSelector((state) => state.skills.allSkills);

  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [activeFilter, setActiveFilter] = useState("active"); // all, active, inactive
  const [searchText, setSearchText] = useState("");
  //states end

  // non-data hooks start
  const dispatch = useDispatch();
  // non-data hooks end

  // use effect starts
  const loadData = async () => {
    dispatch(getAllSkills());
  };
  useEffect(() => {
    loadData();
  }, []);
  // use effects end

  // functions start
  const handleAdd = (values) => {
    dispatch(createSkill(values)).then((result) => {
      if (result?.status === "success") {
        setShowAddEditModal();
        loadData();
        // loadData();
      }
    });
  };
  const handleUpdate = (id, values) => {
    dispatch(updateSkill(id, values)).then((result) => {
      if (result?.status === "success") {
        setShowAddEditModal();
        loadData();
      }
    });
  };
  const handleDelete = (id) => {
    dispatch(deleteSkill(id)).then((result) => {
      if (result?.status === "success") {
        loadData();
      }
    });
  };
  const handleRestore = (id) => {
    dispatch(restoreSkill(id)).then((result) => {
      if (result?.status === "success") {
        loadData();
      }
    });
  };
  const deleteConfirmation = (id) => ({
    title: "Inactivate Skill",
    content: <>Are you sure that you want to inactivate the skill?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      handleDelete(id);
      close();
    },
  });

  const restoreConfirmation = (id) => ({
    title: "Activate Skill",
    content: <>Are you sure that you want to activate the skill?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      handleRestore(id);
      close();
    },
  });

  const checkActiveFilter = (item) => {
    if (activeFilter === "all") return true;
    else if (activeFilter === "active" && item?.isActive) return true;
    else if (activeFilter === "inactive" && !item?.isActive) return true;
    return false;
  };
  // functions end

  const columns = [
    {
      title: "Skill Name",
      dataIndex: "name",
      key: "skillName",

      sorter: (a, b) => {
        if (a.name?.toLowerCase?.() < b.name?.toLowerCase?.()) return -1;
        if (a.name?.toLowerCase?.() > b.name?.toLowerCase?.()) return 1;
        return 0;
      },
    },
    {
      title: "Active",
      key: "active",
      width: 100,
      render: (data) => {
        if (data?.isActive) return "Active";
        return "Inactive";
      },
    },
    {
      title: "",
      key: "action",
      align: "right",
      width: 80,
      render: (data) => {
        return (
          <Dropdown
            overlay={
              <Menu style={{ borderRadius: 6, border: "2px solid #CDD5D8 " }}>
                <Menu.Item onClick={() => setShowAddEditModal(data)} key="edit">
                  Edit
                </Menu.Item>
                <Menu.Item
                  key="delete"
                  onClick={() => {
                    if (data?.isActive)
                      Modal.warning(deleteConfirmation(data.id));
                    else Modal.warning(restoreConfirmation(data.id));
                  }}
                >
                  {data?.isActive ? "Inactivate" : "Activate"}
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
            getPopupContainer={(triggerNode) => triggerNode?.parentNode}
          >
            <MoreOutlined style={{ fontSize: 18 }} />
          </Dropdown>
        );
      },
    },
  ];

  const searchAndFiltersContent = usePortal(
    "#settingHeader .settingsHeaderLeftSideAddon",
    <Space>
      <SearchAndFilterColumns
        columns={columns}
        hiddenColumns={hiddenColumns}
        setHiddenColumns={(columnTitles) => {
          setHiddenColumns(columnTitles);
        }}
        searchText={searchText}
        setSearchText={(text) => {
          setSearchText(text);
        }}
      />
      <Select
        style={{ width: 120 }}
        value={activeFilter}
        onChange={(value) => {
          setActiveFilter(value);
        }}
        options={[
          {
            value: "all",
            label: "All",
          },
          {
            value: "active",
            label: "Active",
          },
          {
            value: "inactive",
            label: "Inactive",
          },
        ]}
      />
    </Space>
  );

  const settingHeaderContent = useSettingsHeader(
    <Button
      rounded={true}
      onClick={() => {
        setShowAddEditModal(true);
      }}
    >
      Create Skills
    </Button>
  );

  return (
    <div>
      {settingHeaderContent}
      {searchAndFiltersContent}
      <Table
        rowKey={"id"}
        tableLayout="auto"
        locale={{ emptyText: "No Skill" }}
        dataSource={data?.filter(
          (it) =>
            checkActiveFilter(it) &&
            it?.name?.toLowerCase?.().includes?.(searchText.toLowerCase())
        )}
        columns={columns.filter((column) => {
          if (!hiddenColumns.includes(column.title)) return true;
          return false;
        })}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: [15, 25, 50, 100, 250],
        }}
        // scroll={{ y: "calc(100vh - 80px - 96px - 60px - 100px)", x: true }}
      />
      <SkillsDrawer
        data={showAddEditModal}
        visible={showAddEditModal}
        onClose={() => {
          setShowAddEditModal();
        }}
        onSuccess={(values) => {
          const isEdit = showAddEditModal?.id !== undefined;
          if (isEdit) handleUpdate(showAddEditModal?.id, values);
          else handleAdd(values);
        }}
      />
    </div>
  );
}
