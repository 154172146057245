import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  GoogleMap as GoogleMapWidget,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import { GOOGLE_MAP_KEY } from "utils/constants";
import { Modal, Spin } from "antd";
import { openNotificationWithIcon } from "utils/Notification";

import "./GoogleMap.scss";
import { Redirect } from "react-router";

const GoogleMap = withScriptjs(
  withGoogleMap((props) => {
    const { defaultCenter } = props;
    return (
      <GoogleMapWidget
        {...props}
        defaultZoom={18}
        defaultCenter={defaultCenter}
      >
        {defaultCenter && <Marker position={defaultCenter} />}
      </GoogleMapWidget>
    );
  })
);

const GoogleMapHOC = (props) => {
  const { streetAddress } = props;
  const [isFetchingCoords, setIsFetchingCoords] = useState();
  const [error, setError] = useState();
  const [latLng, setLatLng] = useState();

  useEffect(() => {
    setIsFetchingCoords(true);
    const urlEncodedStreetAddress = escape(streetAddress);
    return fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_MAP_KEY}&fields=formatted_address&address=${urlEncodedStreetAddress}`
    )
      .then((response) => response.json())
      .then((data) => {
        const lat = data.results[0]?.geometry?.location?.lat;
        const lng = data.results[0]?.geometry?.location?.lng;
        if (lat !== undefined && lng !== undefined) {
          setError();
          setLatLng({ lat, lng });
        } else {
          setError("Unable to fetch details of the address.");
        }
      })
      .catch((e) => {
        setError(
          e?.message || "Network request failed while fetching address details."
        );
      })
      .finally((e) => {
        setIsFetchingCoords(false);
      });
  }, []);

  useEffect(() => {
    if (error) {
      openNotificationWithIcon("error", "Error", error);
    }
  }, [error]);
  useEffect(() => {
    if (latLng)
      if (
        navigator.platform.indexOf("iPhone") !== -1 ||
        navigator.platform.indexOf("iPod") !== -1
      ) {
        // If it's an iPhone..
        function iOSversion() {
          if (/iP(hone|od|ad)/.test(navigator.platform)) {
            // supports iOS 2.0 and later
            var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
            return [
              parseInt(v[1], 10),
              parseInt(v[2], 10),
              parseInt(v[3] || 0, 10),
            ];
          }
        }
        var ver = iOSversion() || [0];

        var protocol = "http://";
        if (ver[0] >= 6) {
          protocol = "maps://";
        }
        window.location =
          protocol +
          "maps.apple.com/maps?daddr=" +
          latLng?.lat +
          "," +
          latLng?.lng +
          "&amp;ll=";
      } else if (/mobile/i.test(navigator.userAgent)) {
        window.open(
          `https://www.google.com/maps/dir/?api=1&destination=${latLng?.lat},${latLng?.lng}
         `
        );
      }
  }, [latLng]);

  return (
    <>
      {isFetchingCoords ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 400,
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : (
        <GoogleMap
          defaultCenter={latLng}
          isMarkerShown
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${GOOGLE_MAP_KEY}&libraries=geometry,drawing,places`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `400px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      )}
    </>
  );
};

export const GoogleMapModal = ({
  visible,
  onCancel,
  streetAddress,
  ...props
}) => {
  return (
    <Modal
      className="googleMapModal"
      width="700px"
      title="Service Location"
      visible={visible}
      footer={null}
      onCancel={onCancel}
      destroyOnClose={true}
      {...props}
    >
      <GoogleMapHOC streetAddress={streetAddress} />
    </Modal>
  );
};

export default GoogleMapHOC;
