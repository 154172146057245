import React, { useRef } from "react";
import { Button } from "components/Buttons/Buttons";
import Modal from "components/Modal/Modal";
import { Form, Row, Col, Button as AntdButton } from "antd";
import {
  FormHiddenSubmitButton,
  FormInput,
  FormInputArea,
  FormSelect,
} from "components/FormItems/FlatFormItems";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { createForm } from "services/forms.service";
import { FormType } from "utils/enums";

export default function FormCreate({ products = [], visible, onCancel }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const submitBtnRef = useRef();
  const selectedCategory = Form.useWatch("category", form);

  return (
    <Modal
      className="formCreateModal"
      destroyOnClose={true}
      width="700px"
      title={"Add Form"}
      onCancel={onCancel}
      visible={visible}
      okText="Save"
      footer={[
        <div className="d-flex justify-between align-center">
          <AntdButton type="text">Cancel</AntdButton>
          <Button rounded={true} onClick={() => submitBtnRef?.current?.click()}>
            Save
          </Button>
        </div>,
      ]}
    >
      <Form
        preserve={false}
        layout="vertical"
        form={form}
        requiredMark={false}
        onFinish={(values) => {
          let formData = {
            formName: values.formName,
            formDescription: values.formDescription,
          };

          if (values.category === "intake") {
            if (values.chartOrForm === 3) {
              formData.formType = FormType.consent;
            } else if (values.chartOrForm === 2) {
              formData.formType = FormType.historyOrPhysical;
            } else if (values.chartOrForm === 4) {
              formData.formType = FormType.goodFaithExam;
            }
          } else {
            formData.referenceId = values.chartOrForm;
            formData.formType = FormType.chart;
          }

          dispatch(createForm(formData)).then((res) => {
            if (res.status === "success") {
              dispatch(
                push(
                  `/dashboard/settings/forms-managment/edit/${res?.data?.formId}`
                )
              );
            }
          });
          //   handleSubmit(values);
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <FormInput
              variant="underlined"
              required={true}
              formItemStyles={{ margin: 0 }}
              name="formName"
              label="Form Name"
              placeholder="Enter here..."
            />
          </Col>
          <Col xs={24}>
            <FormInputArea
              label={"Form Description"}
              name={"formDescription"}
              variant="underlined"
              formItemStyles={{ margin: 0 }}
              // initialValue={notes[0] ? notes[0].noteDescription : ''}
            />
          </Col>
          <Col xs={12}>
            <FormSelect
              // disabled={isAppointmentFormDisabled}
              variant="underlined"
              name="category"
              label="Category"
              placeholder="Select"
              required={true}
              formItemStyles={{ marginBottom: 12 }}
              options={[
                { label: "Intake Form", value: "intake" },
                { label: "Chart", value: "chart" },
              ]}
              renderLabel="label"
              renderValue="value"
              onChange={(value) => {
                form.setFieldsValue({
                  chartOrForm: undefined,
                });
              }}
            />
          </Col>
          <Col xs={12}>
            <FormSelect
              // disabled={isAppointmentFormDisabled}
              variant="underlined"
              name="chartOrForm"
              label="Chart or Form" // dynamic
              placeholder="Select"
              required={true}
              formItemStyles={{ marginBottom: 12 }}
              options={
                selectedCategory === "chart"
                  ? products
                  : [
                      {
                        value: 2,
                        label: "History & Physical Form",
                      },
                      {
                        value: 3,
                        label: "Consent Intake Form",
                      },
                      {
                        value: 4,
                        label: "Good Faith Exam",
                      },
                    ]
              }
              showSearch
              filterOption={(input, option) => {
                return option?.label
                  ?.toLowerCase?.()
                  .includes?.(input.toLowerCase());
              }}
              renderLabel="label"
              renderValue="value"
              // onChange={(value) => {}}
            />
          </Col>
          <FormHiddenSubmitButton submitRef={submitBtnRef} />
        </Row>
      </Form>
    </Modal>
  );
}
