export const actions = {
  SET_FACILITIES: "SET_FACILITIES",
  SET_FETCHING_FACILITIES: "SET_FETCHING_FACILITIES",
  SET_CLINIC_FACILITIES: "SET_CLINIC_FACILITIES",
  SET_MOBILE_FACILITIES: "SET_MOBILE_FACILITIES",
};

export const setAllFacilities = (data) => ({
  type: actions.SET_FACILITIES,
  data,
});

export const setIsFetchingFacilities = (isFetching) => ({
  type: actions.SET_FETCHING_FACILITIES,
  isFetchingFacilities: isFetching,
});

export const setClinicFacilities = (data) => ({
  type: actions.SET_CLINIC_FACILITIES,
  data,
});

export const setMobileFacilities = (data) => ({
  type: actions.SET_MOBILE_FACILITIES,
  data,
});

