import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "components/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  FormInput,
  FormInputArea,
  FormButton,
} from "components/FormItems/FlatFormItems";
import { Radio, Input, Form, Typography, Row, Col, Skeleton, Alert } from "antd";
import { FormDatePicker } from "components/FormItems/FlatFormItems";
import {
  getGiftCardById,
  getGiftCardDesign,
  purchaseGiftCard,
  updateGiftCard,
} from "services/giftCards.service";
import { get } from "lodash";
import Spinner from "components/Spinner/Spinner";

// Hardcoded ID provided by the backend to be used as productID for giftcard purchase
const GIFT_CARD_ID = 1148;

const { Title } = Typography;

function GiftCardModal({ visible, onCancel, onSave, paymentMethod, customer }) {
  const isEdit = visible?.giftCardId;

  const [form] = Form.useForm();
  const value = Form.useWatch("amount", form);

  const [design, setdesign] = useState({
    data: null,
    isLoading: true,
  });
  const [showTCs, setShowTCs] = useState(false);
  const [formInfo, setFormInfo] = useState({
    data: null,
    isLoading: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGiftCardDesign()).then((res) => {
      if (res?.status === "success") {
        setdesign({
          data: res?.data,
          isLoading: false,
        });
      }
    });
  }, []);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setFormInfo({
        data: null,
        isLoading: false,
      });
    } else if (visible.giftCardId) {
      setFormInfo((state) => ({ ...state, isLoading: true }));

      dispatch(getGiftCardById(visible.giftCardId))
        .then((res) => {
          if (res?.status === "success") {
            const data = res?.data ?? {};
            setFormInfo((state) => ({ ...state, data }));
            form.setFieldsValue({
              ...data,
              scheduledSendDate: data.scheduledSendDate
                ? moment(res.scheduledSendDate)
                : null,
            });
          }
        })
        .finally(() => {
          setFormInfo((state) => ({ ...state, isLoading: false }));
        });
    }
  }, [visible]);

  const renderForm = () => {
    const {
      sellingPrices = [50, 100, 150],
      customValueEnabled = true,
      notes,
      minimumValue = 200,
    } = design.data ?? {};
    return (
      <>
        {formInfo?.data?.hasInvalidEmail && (
          <Alert
            message="Invalid Receiver Email Provided."
            type="error"
            className="giftcardFeedbackAlert"
            closable
          />
        )}
        <Title
          level={4}
          className="tradeGothic"
          style={{
            color: "#000",
          }}
        >
          Gift Card Amount
        </Title>
        {isEdit ? (
          <>
            {formInfo?.isLoading ? (
              <Spinner />
            ) : (
              <Typography.Text>${formInfo?.data?.amount}</Typography.Text>
            )}
          </>
        ) : (
          <Form.Item
            name="amount"
            style={{ marginBottom: 20 }}
            rules={[
              {
                required: true,
                message: "amount required",
              },
            ]}
            disabled={isEdit}
          >
            <Radio.Group
              disabled={isEdit}
              className="giftCardValues"
              // onChange={(e, value) => setValue(e.target.value)}
            >
              {sellingPrices?.map((price) => {
                return <Radio value={price}>${price}</Radio>;
              })}
              {customValueEnabled && (
                <Radio value={"custom"}>
                  {value === "custom" ? (
                    <Form.Item
                      className="no-error-field"
                      style={{ margin: 0 }}
                      rules={[
                        {
                          required: true,
                        },
                        {
                          min: 1,
                        },
                      ]}
                      name="customValue"
                      required
                    >
                      <Input
                        disabled={isEdit}
                        min={minimumValue}
                        prefix="$"
                        type="number"

                        // onChange={(e) => setCustomValue(Number(e.target.value))}
                        // value={customValue}
                      />
                    </Form.Item>
                  ) : (
                    <> Custom</>
                  )}
                </Radio>
              )}
            </Radio.Group>
          </Form.Item>
        )}
        <FormInputArea
          disabled={isEdit}
          label="Personal Notes"
          name="personalNotes"
          variant="underlined"
        />

        {/* <Title level={4} className="tradeGothic">
      From
    </Title>

    <Row gutter={16}>
      <Col xs={12}>
        <FormInput
          label="First Name"
          variant="underlined"
          name="firstName"
          placeholder={"First name"}
          formItemStyles={{ marginBottom: 14 }}
          required={true}
        />
      </Col>
      <Col xs={12}>
        <FormInput
          label="Last Name"
          variant="underlined"
          name="lastName"
          placeholder={"Last name"}
          formItemStyles={{ marginBottom: 14 }}
          required={true}
        />
      </Col>
    </Row> */}
        <FormDatePicker
          form={form}
          disabled={isEdit}
          placeholder={"Send Date"}
          label={"Send Date"}
          name="scheduledSendDate"
          variant="underlined"
          formItemStyles={{ marginBottom: 14 }}
          required
          validateFirst
          // defaultValue={moment("01-01-1994", "DD-MM-YYYY")}
          rules={[
            {
              required: true,
              message: "*Required",
            },
            // {
            //   message:
            //     "Your age should be between 18 and 85. Kindly contact our support.",
            //   validator: (_, value) => {
            //     if (
            //       value.isSameOrBefore(moment().subtract(18, "years")) &&
            //       value.isAfter(moment().subtract(85, "years"))
            //     )
            //       return Promise.resolve();
            //     else return Promise.reject();
            //   },
            // },
          ]}
          disabledDate={(current) =>
            current.isSameOrBefore(
              moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            )
          }
        />
        <FormButton
          type="submit"
          htmlType="submit"
          text={isEdit ? "Update" : "Make Payment"}
          rounded={true}
          disabled={isEdit || !paymentMethod}
          formItemStyles={{ marginBottom: 12 }}
        />
        <Typography.Link
          disabled={!design?.data?.notes}
          underline
          onClick={() => setShowTCs(true)}
        >
          Giftcard Terms & Conditions
        </Typography.Link>
      </>
    );
  };

  const renderLoader = () => {
    return (
      <div>
        <Skeleton
          active
          paragraph={{
            rows: 6,
          }}
        />
      </div>
    );
  };

  return (
    <Modal
      className="giftCardModal"
      width="500px"
      title={isEdit ? "Update a Gift Card" : "Send a Gift Card"}
      footer={null}
      onCancel={() => onCancel()}
      visible={visible}
      //   onOk={() => console.log("ok")}
      //   okText="Add"
      centered={true}
      //   footer={[<Button rounded={true}>Save</Button>]}
      //   cancelText="No"
    >
      <Form
        form={form}
        initialValues={
          {
            // firstName: user.firstName,
            // lastName: user.lastName,
          }
        }
        requiredMark={false}
        layout="vertical"
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          console.log("err is human", errorFields);
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
        onFinish={(formValues) => {
          const { scheduledSendDate, amount, customValue, ...values } =
            formValues;
          let data = {
            ...values,
            giftCardPrice: 0,

          };
          data.scheduledSendDate = moment(scheduledSendDate).format();
          if (amount === "custom") {
            data.giftCardPrice = formValues.customValue;
          } else {
            data.giftCardPrice = formValues.amount;
          }
          data.paymentMethodId = paymentMethod?.paymentMethodId;
          data.senderCustomerId = customer.customerId;
          data.recipientCustomerId = customer.customerId;
          data.recipientFirstName = customer.firstName;
          data.recipientLastName = customer.lastName;
          data.recipientUserName = customer.userName;
          data.email = customer.email;


          if (isEdit) {
            const { firstName, lastName } = data;
            dispatch(
              updateGiftCard(visible.giftCardId, { firstName, lastName })
            ).then((res) => {
              if (res?.status === "success") {
                onSave();
              }
            });
          } else {
            dispatch(purchaseGiftCard(data)).then((res) => {
              if (res?.status === "success") {
                onSave();
              }
            });
          }
        }}
      >
        {design?.isLoading ? renderLoader() : renderForm()}
      </Form>
      <Modal
        title="Giftcard Terms & Conditions"
        visible={showTCs}
        onCancel={() => setShowTCs(false)}
        footer={null}
      >
        <Typography.Text level={4} className="tradeGothic">
          {design?.data?.notes}
        </Typography.Text>
      </Modal>
    </Modal>
  );
}

GiftCardModal.propTypes = {};

export default GiftCardModal;
