import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Form,
  InputNumber,
  Modal,
  Row,
  Space,
  Typography,
  Tooltip,
} from "antd";
import {
  FormCheckbox,
  FormHiddenSubmitButton,
  FormInput,
  FormInputNumber,
  FormSelect,
} from "components/FormItems/FlatFormItems";
import { ReactComponent as CheckMarkIcon } from "assets/icons/checkMarkIco.svg";
import { ReactComponent as CloseIcon } from "assets/icons/closeIco.svg";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "redux/actions/app.actions";
import {
  applyRewardToLineitem,
  applyVoucher,
  changeWalletPreferance,
  completeOrder,
  removeLineitemReward,
  removeVoucher,
  updateOrderPaymentStatus,
} from "services/booking.service";
import { getRewardsText } from "utils/common";
import { openNotificationWithIcon } from "utils/Notification";
import RewardPopup from "components/RewardPopup/RewardPopup";
import { min } from "lodash";
import { push } from "connected-react-router";
import QueryString from "qs";
import Tag from "components/Tag/Tag";
import Spinner from "components/Spinner/Spinner";
import PaymentInfoRow from "./PaymentInfoRow";

const { Title, Link } = Typography;

export default function OrderPaymentDrawer({
  visible,
  onClose,
  order,
  selectedPaymentMethod,
  onSuccess,
  setPaymentVisible,
  isRewardsLoading,
  refreshCustomerRewards,
  rewards,
  availedRewards = [],
  willHostPay,
  customer,
  credit,
  closeCompletely,
  isCreditLoading,
}) {
  const submitBtnRef = useRef();
  const [form] = Form.useForm();
  const [showRewardPopup, setShowRewardPopup] = useState(false);
  const isCashChecked = Form.useWatch("payment", form);
  const useWallet = Form.useWatch("useWallet", form);
  const isOrderCancelled = order?.orderStatus === 2;

  const dispatch = useDispatch();

  const [tip, setTip] = useState(null);

  const [customTip, setCustomTip] = useState(false);
  const isOrderCompleted = order?.orderStatus === 3;
  const total = useMemo(() => {
    let total = 0;
    let newTip = 0;
    for (let i = 0; i < order?.lineItems?.length; i++) {
      total = total + order?.lineItems[i].subTotal;
      for (let j = 0; j < order?.lineItems[i].addOns?.length; j++) {
        total = total + order?.lineItems[i].addOns[j].subTotal;
      }
    }
    let cost = total;
    if (order?.voucherCode && order?.discount) {
      total = total - (Number(order?.discount) ?? 0);
    }


    // total = total + Number(order?.taxAmount || 0);

    if (order?.goodFaithPolicyFee && order?.goodFaithPolicyFee > 0) {
      total = total + order?.goodFaithPolicyFee;
    }

    if (tip) {
      if (customTip) {
        total = total + tip;
        newTip = tip;
      } else {
        newTip = (total * tip) / 100;
        total = total + newTip;
      }
    }

    let giftcard = 0;
    if (useWallet) {
      const amount = min([total ?? 0, credit ?? 0]);
      giftcard = isOrderCompleted
        ? order?.giftCardAmount
        : amount < 0
        ? 0
        : amount;
    }
    total = total - giftcard;

    if (!(isOrderCompleted || isOrderCancelled) && order?.amountPaidUntilNow) {
      total = total - order?.amountPaidUntilNow;
    }

    // total is just the cancellation amount if cancellated
    if (isOrderCancelled) {
      total = order?.cancellationFee;
      giftcard = 0;
    }

    return {
      total,
      cost,
      voucher: Number(order?.discount ?? 0),
      tip: newTip,
      giftcard,
      amountPaidUntilNow:
        isOrderCompleted || isOrderCancelled
          ? 0
          : Number(order?.amountPaidUntilNow ?? 0),
      goodFaithPolicyFee: Number(order?.goodFaithPolicyFee) ?? 0,
    };
  }, [
    tip,
    order,
    order?.discount,
    useWallet,
    credit,
    isOrderCompleted,
    isOrderCancelled,
  ]);

  useEffect(() => {
    if (order && order.tipAmount * 1 && !tip) {
      setCustomTip(true);
      setTip(order.tipAmount * 1);
    }
  }, [order]);

  // useEffect(() => {
  //   if (!customTip) {
  //     setTip(null);
  //   }
  // }, [customTip]);

  const onTipChange = (value) => {
    const pTip = tip;
    setCustomTip(false);
    setTip(null);
    if (pTip !== value) {
      setTip(value);
    }
  };

  // render variables start
  const isMobile = order?.operationTypeId === 1;

  // render variables end

  const deleteConfirmation = (id) => ({
    title: "Remove Reward",
    content: <>Are you sure that you want to remove the reward?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      dispatch(removeLineitemReward(order?.orderId, id)).then(() => {
        refreshCustomerRewards();
      });
      close();
    },
  });

  const isTipDisabled =
    isOrderCompleted || isOrderCancelled || !selectedPaymentMethod;

  return (
    <Drawer
      size="large"
      width={"min(100vw, 700px)"}
      destroyOnClose
      headerStyle={{ border: "none" }}
      onClose={onClose}
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: 68,
            color: "#000",
            borderBottom: "2px solid #EFF1F4",
          }}
        >
          <div>
            <div>
              <Space>
                <p
                  style={{
                    margin: 0,
                    fontSize: 24,
                    fontFamily: "tradeGothic",
                    fontWeight: "bold",
                  }}
                >
                  {"Payments"}
                </p>
                {isOrderCancelled && <Tag className="dangerTag">Cancelled</Tag>}
              </Space>
              <p style={{ marginTop: 12 }}>{`Booking ID: ${order?.orderId}`}</p>
            </div>
          </div>
          <CloseOutlined onClick={() => setPaymentVisible(false)} />
        </div>
      }
      placement="right"
      visible={visible}
      footerStyle={{ padding: "24px 24px 0px 24px" }}
      footer={
        <div>
          <div style={{ borderBottom: "2px solid #EFF1F4", paddingBottom: 20 }}>
            <PaymentInfoRow title="Cost" cost={`$${total.cost.toFixed(2)}`} />
            {order?.voucherCode && (
              <PaymentInfoRow
                title="Voucher"
                cost={
                  `${total.voucher > 0 ? "-" : ""}` +
                  "$" +
                  Math.abs(total.voucher).toFixed(2)
                }
              />
            )}
            {tip && (
              <PaymentInfoRow title="Tip" cost={`$${total.tip.toFixed(2)}`} />
            )}
            <PaymentInfoRow
              title="Tax"
              cost={`$${Number(order?.taxAmount || 0)?.toFixed?.(2)}`}
            />
            {isCreditLoading && (
              <Tooltip title="Loading Giftcard Credits Information">
                <Spinner />
              </Tooltip>
            )}
            {((useWallet && credit > 0 && total?.giftcard > 0) ||
              (isOrderCompleted && total.giftcard > 0)) && (
              <PaymentInfoRow
                title="Gift Card"
                cost={`-$${Number(total?.giftcard ?? 0).toFixed(2)}`}
              />
            )}
            {isOrderCancelled && (
              <PaymentInfoRow
                title="Cancellation Fee"
                cost={`$${Number(order?.cancellationFee || 0).toFixed(2)}`}
              />
            )}
            {total?.amountPaidUntilNow > 0 && (
              <PaymentInfoRow
                title="Amount Paid Until Now"
                cost={`-$${total.amountPaidUntilNow.toFixed(2)}`}
              />
            )}
            {total?.goodFaithPolicyFee > 0 && (
              <PaymentInfoRow
                title="Good Faith Exam Fee"
                cost={`$${total.goodFaithPolicyFee.toFixed(2)}`}
              />
            )}
            <PaymentInfoRow title="Total" cost={`$${total.total.toFixed(2)}`} />
          </div>
          <div
            style={{ borderBottom: "2px solid #EFF1F4", padding: "20px 0px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: 60,
                color: "#000",
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: 16,
                  fontFamily: "tradeGothic",
                  color: "#486BAF",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => setPaymentVisible(false)}
              >
                Go Back
              </p>
              <Button
                rounded
                type={"primary"}
                style={{ height: 50, width: "50%", borderRadius: 30 }}
                onClick={async () => {
                  const qs = QueryString.stringify({
                    tip: total?.tip,
                  });

                  // dispatch(
                  //   push(
                  //     `/dashboard/inventory/${order?.orderId}?${encodeURI(qs)}`
                  //   )
                  // );
                  // closeCompletely();
                  let result = await dispatch(
                    completeOrder(order, {
                      tipAmount: total.tip?.toFixed(2) || 0,
                    })
                  );
                  onSuccess();
                }}
                disabled={
                  isOrderCancelled ||
                  isOrderCompleted ||
                  !(selectedPaymentMethod || willHostPay)
                  // (!isMobile && !isCashChecked && !selectedPaymentMethod) || // is clinic on card payment but has no card
                  // (isMobile && !selectedPaymentMethod) // is mobile and has no card
                }
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      }
      closable={false}
      bodyStyle={{ paddingTop: 0 }}
    >
      <div>
        <div style={{ borderBottom: "2px solid #EFF1F4", paddingBottom: 20 }}>
          {order?.lineItems.map((item, id) => {
            let { productName, addOns, rewardId, lineItemId } = item;
            return (
              <div style={{ margin: "12px 0px" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p
                    style={{
                      margin: 0,
                      fontSize: 16,
                      fontFamily: "tradeGothic",
                      fontWeight: "bold",
                      color: "#486BAF",
                    }}
                  >
                    {`Service ${id + 1}`}
                  </p>
                  <p
                    style={{
                      margin: 0,
                      fontSize: 16,
                      fontFamily: "tradeGothic",
                      fontWeight: "bold",
                      color: "#486BAF",
                    }}
                  >
                    Amount
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <p
                      style={{
                        margin: 0,
                        fontSize: 18,
                        fontFamily: "tradeGothic",
                        fontWeight: "bold",
                      }}
                    >
                      {productName}
                    </p>
                  </div>
                  <div>
                    <p
                      style={{
                        margin: 0,
                        fontSize: 18,
                        fontFamily: "tradeGothic",
                        fontWeight: "bold",
                      }}
                    >
                      ${Number(item.sellingPrice ?? 0)?.toFixed?.(2)}{" "}
                      {/* {item?.taxAmount > 0 &&
                        ` + $${Number(item?.taxAmount ?? 0)?.toFixed?.(2)}`} */}
                    </p>
                  </div>
                </div>
                {rewardId && (
                  <div
                    className="flex alignCenter justifySpaceBetween"
                    style={{
                      marginBottom: 4,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Space size={"middle"}>
                      <Title
                        level={5}
                        className="tradeGothic"
                        style={{
                          marginBottom: 0,
                          fontSize: 17,
                          color: "#27ca2d",
                        }}
                      >
                        Rewards : {getRewardsText(item)}
                      </Title>
                    </Space>
                    <Space>
                      <Button
                        size="small"
                        shape="circle"
                        danger
                        disabled={isOrderCompleted || isOrderCancelled}
                        icon={
                          <DeleteOutlined
                            style={{
                              color:
                                isOrderCompleted || isOrderCancelled
                                  ? "#000"
                                  : "#ea5961",
                            }}
                          />
                        }
                        onClick={() => {
                          if (isRewardsLoading) {
                            openNotificationWithIcon(
                              "error",
                              "Loading Rewards",
                              "Please wait while rewards are loading"
                            );
                            return;
                          }
                          Modal.warning(deleteConfirmation(lineItemId));
                        }}
                      />
                      <span
                        style={{
                          color: "#27ca2d",
                          fontSize: 18,
                          fontFamily: "tradeGothic",
                          fontWeight: "bold",
                          // margin: 0px; font-size: 18px; font-family: tradeGothic; font-weight: bold;
                        }}
                      >
                        {item?.discountValue > 0 &&
                          ` -$${Number(item?.discountValue ?? 0)?.toFixed?.(
                            2
                          )}`}
                      </span>
                    </Space>
                  </div>
                )}
                {!rewardId && (
                  <Link
                    onClick={() => setShowRewardPopup(item)}
                    underline
                    disabled={isOrderCompleted || isOrderCancelled}
                  >
                    Add Reward
                  </Link>
                )}

                <div>
                  {addOns.length
                    ? addOns.map((addon) => {
                        const { rewardId, lineItemId } = addon;
                        return (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <p
                                  style={{
                                    margin: 0,
                                    fontSize: 18,
                                    fontFamily: "tradeGothic",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {addon.productName}
                                </p>
                              </div>
                              <div>
                                <p
                                  style={{
                                    margin: 0,
                                    fontSize: 18,
                                    fontFamily: "tradeGothic",
                                    fontWeight: "bold",
                                  }}
                                >
                                  $
                                  {Number(addon.sellingPrice ?? 0)?.toFixed?.(
                                    2
                                  )}{" "}
                                  {/* {addon?.taxAmount > 0 &&
                                    ` + $${Number(
                                      addon?.taxAmount ?? 0
                                    )?.toFixed?.(2)}`} */}
                                </p>
                              </div>
                            </div>
                            {rewardId && (
                              <div
                                className="flex alignCenter justifySpaceBetween"
                                style={{
                                  marginBottom: 14,
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Space size={"middle"}>
                                  <Title
                                    level={5}
                                    className="tradeGothic"
                                    style={{
                                      marginBottom: 0,
                                      fontSize: 17,
                                      color: "#27ca2d",
                                    }}
                                  >
                                    Rewards : {getRewardsText(addon)}
                                  </Title>
                                </Space>
                                <Space>
                                  <Button
                                    disabled={
                                      isOrderCompleted || isOrderCancelled
                                    }
                                    size="small"
                                    shape="circle"
                                    danger
                                    icon={
                                      <DeleteOutlined
                                        style={{
                                          color:
                                            isOrderCompleted || isOrderCancelled
                                              ? "#000"
                                              : "#ea5961",
                                        }}
                                      />
                                    }
                                    onClick={() => {
                                      if (isRewardsLoading) {
                                        openNotificationWithIcon(
                                          "error",
                                          "Loading Rewards",
                                          "Please wait while rewards are loading"
                                        );
                                        return;
                                      }
                                      Modal.warning(
                                        deleteConfirmation(lineItemId)
                                      );
                                    }}
                                  />
                                  <span
                                    style={{
                                      color: "#27ca2d",
                                      fontSize: 18,
                                      fontFamily: "tradeGothic",
                                      fontWeight: "bold",
                                      // margin: 0px; font-size: 18px; font-family: tradeGothic; font-weight: bold;
                                    }}
                                  >
                                    {addon?.discountValue > 0 &&
                                      ` -$${Number(
                                        addon?.discountValue ?? 0
                                      )?.toFixed?.(2)}`}
                                  </span>
                                </Space>
                              </div>
                            )}
                            {!rewardId && (
                              <Link
                                onClick={() => setShowRewardPopup(addon)}
                                underline
                                disabled={isOrderCompleted || isOrderCancelled}
                              >
                                Add Reward
                              </Link>
                            )}
                          </>
                        );
                      })
                    : null}
                </div>
              </div>
            );
          })}
          {/* <Button style={{ background: '#D8DCE4', height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center', width: 260, fontSize: 16, fontFamily: "tradeGothic", fontWeight: 'bold', borderRadius: 8 }}>+ Add Service or Membership</Button> */}
        </div>

        <div style={{ borderBottom: "2px solid #EFF1F4", padding: "20px 0px" }}>
          <Tooltip
            title={
              isTipDisabled
                ? "A card must be on file for a guest to leave a tip."
                : ""
            }
            placement="topLeft"
          >
            <p
              style={{
                margin: 0,
                fontSize: 20,
                fontFamily: "tradeGothic",
                fontWeight: "bold",
                marginBottom: 14,
              }}
            >
              Do you want to leave a tip?
            </p>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 16,
              }}
            >
              <button
                disabled={isTipDisabled}
                style={{
                  width: 80,
                  height: 60,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 8,
                  background: isTipDisabled ? "#f5f5f5" : "#fff",
                  cursor: isTipDisabled ? "not-allowed" : "pointer",

                  border:
                    tip === 15 && !customTip
                      ? "2px solid #486BAF"
                      : "2px solid #D8DCE4",
                }}
                onClick={() => {
                  if (!isOrderCompleted) onTipChange(15);
                }}
              >
                15%
              </button>
              <button
                disabled={isTipDisabled}
                style={{
                  width: 80,
                  height: 60,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 8,
                  background: isTipDisabled ? "#f5f5f5" : "#fff",
                  cursor: isTipDisabled ? "not-allowed" : "pointer",

                  border:
                    tip === 18 && !customTip
                      ? "2px solid #486BAF"
                      : "2px solid #D8DCE4",
                }}
                onClick={() => {
                  if (!isOrderCompleted) onTipChange(18);
                }}
              >
                18%
              </button>
              <button
                disabled={isTipDisabled}
                style={{
                  width: 80,
                  height: 60,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 8,
                  background: isTipDisabled ? "#f5f5f5" : "#fff",
                  cursor: isTipDisabled ? "not-allowed" : "pointer",

                  border:
                    tip === 20 && !customTip
                      ? "2px solid #486BAF"
                      : "2px solid #D8DCE4",
                }}
                onClick={() => {
                  if (!isOrderCompleted) onTipChange(20);
                }}
              >
                20%
              </button>
              <button
                disabled={isTipDisabled}
                style={{
                  width: 80,
                  height: 60,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 8,
                  background: isTipDisabled ? "#f5f5f5" : "#fff",
                  cursor: isTipDisabled ? "not-allowed" : "pointer",
                  border: customTip ? "2px solid #486BAF" : "2px solid #D8DCE4",
                }}
                onClick={() => {
                  if (!customTip) {
                    setTip(1);
                  }
                  setCustomTip(!customTip);
                }}
              >
                Custom
              </button>
              {customTip && (
                <InputNumber
                  disabled={isTipDisabled}
                  placeholder="Tip"
                  min={1}
                  value={tip}
                  style={{
                    marginBottom: 0,
                    marginTop: 0,
                    height: 60,
                    borderRadius: 8,
                    border: "2px solid #D8DCE4",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  // initialValue={order?.voucherCode}
                  onChange={(e) => setTip(e)}
                />
              )}
            </div>
          </Tooltip>
        </div>
        <div style={{ padding: "20px 0px" }}>
          <p
            style={{
              margin: 0,
              fontSize: 20,
              fontFamily: "tradeGothic",
              fontWeight: "bold",
              marginBottom: 12,
            }}
          >
            Adjustments
          </p>
          <Form
            name="voucherForm"
            layout="vertical"
            form={form}
            initialValues={{
              payment: order?.paymentType === 1 ? true : false,
              useWallet: order?.useWallet,
            }}
            requiredMark={false}
            onFinishFailed={({ values, errorFields, outOfDate }) => {
              form.scrollToField(errorFields[0].name, {
                scrollMode: "if-needed",
                block: "center",
                behavior: "smooth",
              });
            }}
            onFinish={async (values) => {
              let response = await dispatch(
                applyVoucher(order.orderId, values.voucher)
              );
              if (
                response?.status === "error" &&
                response?.type === "redemptionLimitExceeded"
              ) {
                Modal.confirm({
                  title: "Redemption Limit Reached",
                  okText: "Yes",
                  cancelText: "No",
                  content:
                    "This voucher has reached its redemption limit. Do you want to still want to apply the voucher? ",
                  onOk: async () => {
                    await dispatch(
                      applyVoucher(order.orderId, values.voucher, true)
                    );
                  },
                  closeIcon: <CloseCircleOutlined />,
                  maskClosable: true,
                  closable: true,
                });
              }
            }}
          >
            <Row gutter={[16, 4]} className="couponsRow">
              {!isMobile && (
                <Col span={16}>
                  <FormCheckbox
                    name="payment"
                    label="Payment"
                    formItemStyles={{ marginBottom: 0 }}
                    disabled={isOrderCompleted || isOrderCancelled}
                    onChange={async (e) => {
                      if (e.target.checked) {
                        dispatch(setLoading(true));
                        await dispatch(updateOrderPaymentStatus(order, 1));
                        dispatch(setLoading(false));
                      } else {
                        dispatch(setLoading(true));
                        await dispatch(updateOrderPaymentStatus(order, 2));
                        dispatch(setLoading(false));
                      }
                    }}
                  >
                    Cash
                  </FormCheckbox>

                  {/* <FormSelect
                  feedBack={false}
                  variant="underlined"
                  name="payment"
                  label="Payment"
                  renderValue="value"
                  placeholder={"Select Payment"}
                  renderLabel="label"
                  styles={{ border: "none" }}
                  initialValue={order?.paymentType}
                  formItemStyles={{ marginBottom: 0, marginLeft: 0 }}
                  onChange={async (value) => {
                    dispatch(setLoading(true))
                    await dispatch(updateOrderPaymentStatus(order, value))
                    dispatch(setLoading(false))
                    onClose()

                  }}
                  options={[
                    { key: 1, label: 'Cash', value: 1 },
                    { key: 2, label: 'Pay', value: 2 },
                  ]}
                /> */}
                </Col>
              )}
              <Col xs={24}>
                <FormCheckbox
                  hidden={
                    credit === 0 && !(isOrderCompleted && total?.giftcard > 0)
                  }
                  checked={order?.useWallet}
                  name="useWallet"
                  label="Use Wallet"
                  formItemStyles={{ marginBottom: 12 }}
                  disabled={isOrderCompleted || isOrderCancelled}
                  onChange={async (e) => {
                    dispatch(
                      changeWalletPreferance(
                        order?.orderId,
                        order?.useWallet ? false : true
                      )
                    );
                  }}
                >
                  Use Wallet
                </FormCheckbox>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className="couponsRow">
              <Col span={16}>
                <FormInput
                  name="voucher"
                  disabled={
                    order?.voucherCode || isOrderCompleted || isOrderCancelled
                      ? true
                      : false
                  }
                  label="Apply Coupon"
                  variant="underlined"
                  placeholder="Eg: Code"
                  formItemStyles={{ marginBottom: 0, marginTop: 8 }}
                  initialValue={order?.voucherCode}
                />
                <FormHiddenSubmitButton submitRef={submitBtnRef}>
                  submit
                </FormHiddenSubmitButton>
              </Col>
              <Col span={8} style={{ display: "flex", alignItems: "center" }}>
                {order?.voucherCode ? (
                  <Space className="voucherAppliedContainer">
                    <div className="checkMarkOutlined">
                      <CheckMarkIcon />
                    </div>
                    <p className="tradeGothic availedText">Applied</p>
                    <Button
                      disabled={isOrderCompleted || isOrderCancelled}
                      shape="circle"
                      className="voucherCloseBtn"
                      onClick={() => {
                        dispatch(removeVoucher(order?.orderId));
                      }}
                    >
                      <CloseIcon />
                    </Button>
                  </Space>
                ) : (
                  <Link
                    disabled={isOrderCompleted || isOrderCancelled}
                    underline
                    style={{
                      paddingTop: 35,
                      fontSize: 16,
                      textDecoration: "underline",
                    }}
                    className="couponsRowApplyBtn"
                    onClick={() => {
                      if (submitBtnRef.current && !order?.voucherCode)
                        submitBtnRef.current.click();
                    }}
                  >
                    Apply Now
                  </Link>
                )}
              </Col>
              {/* <Col span={24}>
                <div style={{ display: "flex", gap: 16 }}>
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 8 }}
                  >
                    {order?.useWallet ? (
                      <CheckCircleOutlined
                        style={{
                          color: "#52c41a",
                          fontSize: 20,
                        }}
                      />
                    ) : (
                      <CloseCircleOutlined
                        style={{
                          color: "#ff4d4f",
                          fontSize: 20,
                        }}
                      />
                    )}
                    <Title
                      style={{
                        fontSize: 16,
                        color: "#000",
                        fontFamily: "tradeGothic",
                        margin: 0,
                      }}
                    >
                      Use Gift Card Wallet
                    </Title>
                  </div>
                  <Link
                    underline
                    style={{
                      fontSize: 16,
                      textDecoration: "underline",
                    }}
                    className="couponsRowApplyBtn"
                  >
                    Toggle
                  </Link>
                </div>
              </Col> */}
            </Row>
          </Form>
        </div>
      </div>
      <RewardPopup
        values={showRewardPopup}
        lineItemId={showRewardPopup?.lineItemId}
        rewards={rewards ?? []}
        availedRewards={availedRewards}
        visible={showRewardPopup}
        onCancel={() => setShowRewardPopup(false)}
        onRedeem={(rewardId, lineItemId) => {
          dispatch(
            applyRewardToLineitem(
              order?.orderId,
              showRewardPopup?.lineItemId,
              rewardId,
              () => {
                openNotificationWithIcon(
                  "success",
                  "Success",
                  "Reward applied successfully"
                );
                refreshCustomerRewards();
                setShowRewardPopup(false);
              }
            )
          );
        }}
      />
    </Drawer>
  );
}
