import React from "react";
import { Radio, Form, Select } from "antd";

import { ReactComponent as ChevronDownIco } from "assets/icons/chevronDownIco.svg";
import { ReactComponent as SearchIco } from "assets/icons/searchIco.svg";

const FormItem = Form.Item;
const Option = Select.Option;
// TODO: Make a generic component for this
export default function OutlinedSelectorFormItem({
  name,
  required,
  label,
  placeholder,
  options,
  initialValue,
  styles,
  onChange,
  disabled,
  dropdownStyle,
  mode,
  onClick,
  variant,
  formItemStyles,
  renderLabel,
  renderValue,
  isSearch = false,
  feedBack = true,
  className,
  rules,
  loading,
  onSearch,
  filterOption,
  showSearch,
  optionLabelProp,
  renderOption,
  formStyle,
  value,
}) {
  return (
    <FormItem
      name={name}
      rules={
        rules ?? [
          {
            required,
            message: "Required",
          },
        ]
      }
      style={formStyle}
      label={label}
      className={`flatFormItem outlinedSelector
      ${isSearch ? "searchField" : ""} ${className}
      `}
      initialValue={initialValue}
    >
      <Select
        value={value}
        onChange={onChange}
        className="outlinedSelector"
        placeholder={placeholder ?? "Select"}
        // suffixIcon={
        //   <div className="iconContainer">
        //     <ChevronDownIco />
        //   </div>
        // }
        style={styles}
        disabled={disabled}
        loading={loading}
        suffixIcon={isSearch ? <SearchIco /> : undefined}
        showSearch={showSearch}
        onSearch={onSearch}
        filterOption={filterOption}
        optionLabelProp={optionLabelProp}
        // showArrow={isSearch ? false : true}
      >
        {options?.map?.((opt) => (
          <Option
            key={renderValue ? opt[renderValue] : opt}
            value={renderValue ? opt[renderValue] : opt}
            label={renderValue ? opt[renderLabel] : opt}
          >
            {renderOption ? (
              renderOption(opt)
            ) : (
              <> {renderLabel ? opt[renderLabel] : opt}</>
            )}
          </Option>
        ))}
      </Select>
    </FormItem>
  );
}

