import { actions } from "../actions/drawer.actions";

const INITIAL_STATE = {
  profileDrawer: {
    show: false,
    showAppointmentDrawerOnClose: false,
    data: null,
  },
  appointmentDrawer: {
    show: false,
    data: null,
  },
};

const reducer = (state = INITIAL_STATE, data) => {
  switch (data.type) {
    case actions.SET_APPOINTMENT_DRAWER:
      return {
        ...state,
        appointmentDrawer: {
          ...INITIAL_STATE.appointmentDrawer,
          show: data?.data?.show,
          data:
            data?.data?.data === undefined
              ? state?.appointmentDrawer?.data
              : data?.data?.data,
        },
      };
    case actions.SET_PROFILE_DRAWER:
      return {
        ...state,
        profileDrawer: {
          ...INITIAL_STATE.profileDrawer,
          ...(data?.data ?? {}),
        },
      };
    case "RESET":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
