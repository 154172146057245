const TitleStyle = {
  margin: 0,
  fontSize: 16,
  fontWeight: 700,
  color: "#486BAF",
  marginBottom: 4,
};

const RowStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  width: "100%",
};

const GreenText = {
  marginBottom: 0,
  fontSize: 17,
  color: "#27ca2d",
};

export { TitleStyle, RowStyle, GreenText };
