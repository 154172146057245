import { UserOutlined } from "@ant-design/icons";
import { Avatar, Form, Typography } from "antd";
import { Button } from "components/Buttons/Buttons";
import { FormInput } from "components/FormItems/FlatFormItems";
import React, { useEffect } from "react";

const { Title, Text } = Typography;

export default function InventoryKitSearch({
  booking,
  isComplete,
  customerName,
  orderId,
  onSubmit,
  isLoading,
  addonName,
  isFetched,
  onKitNumberChange
}) {
  const [form] = Form.useForm();
  const isAddon = !!addonName;

  useEffect(()=>{
    if(booking?.isInventorySubmitted){
      form.setFieldsValue({
        kit: booking?.inventoryKitNo
      })
      onSubmit(booking?.inventoryKitNo);
    }
  },[])

  return (
    <Form
      className="kitSearch"
      layout="horizontal"
      form={form}
      onFinish={async (values) => {
        onSubmit(values.kit);
      }}
      onFinishFailed={({ values, errorFields }) => {
        form.scrollToField(errorFields[0].name, {
          scrollMode: "if-needed",
          block: "center",
          behavior: "smooth",
        });
      }}
    >
      <div className="userInfo">
        <Avatar
          size={45}
          icon={<UserOutlined />}
          style={{
            backgroundColor: "#f5f2ef",
          }}
          src={undefined}
        />
        <div className="names">
          <Title>{isAddon ? addonName : customerName}</Title>
          {!isAddon && <Text>{orderId}</Text>}
        </div>
      </div>
      <div className="middle">
        <FormInput
          required
          formItemClassName={"flatLabel"}
          name="kit"
          variant="outlined"
          label="Kit Number"
          formItemStyles={{
            marginBottom: 0,
          }}
          onChange={onKitNumberChange}
        />
      </div>
      <div className="right">
        <Button
          rounded
          htmlType="submit"
          loading={isLoading}
          disabled={isComplete || isFetched || booking?.isInventorySubmitted}
        >
          Fetch
        </Button>
      </div>
    </Form>
  );
}
