import PropTypes from "prop-types";
import React, { useRef, useEffect, useState, useMemo } from "react";
import addClass from "dom-helpers/addClass";
import removeClass from "dom-helpers/removeClass";
import getWidth from "dom-helpers/width";
import scrollbarSize from "dom-helpers/scrollbarSize";
import findIndex from "lodash/findIndex";

import isEqual from "lodash/isEqual";
import { useSelector } from "react-redux";
import { Pagination, Table } from "antd";
import CustomAgendaSortHeader from "./CustomAgendaSortHeader";
import "./CustomAgendaView.scss";
import { CalenderOperationType } from "utils/enums";

let navigate = {
  PREVIOUS: "PREV",
  NEXT: "NEXT",
  TODAY: "TODAY",
  DATE: "DATE",
};

function isSelected(event, selected) {
  if (!event || selected == null) return false;
  return isEqual(event, selected);
}

function inRange(e, start, end, accessors, localizer) {
  const event = {
    start: accessors.start(e),
    end: accessors.end(e),
  };
  const range = { start, end };
  return localizer.inEventRange({ event, range });
}

export function segsOverlap(seg, otherSegs) {
  return otherSegs.some(
    (otherSeg) => otherSeg.left <= seg.right && otherSeg.right >= seg.left
  );
}

function MyCustomAgendaComponent({
  accessors,
  components,
  date,
  events,
  getters,
  length,
  localizer,
  onDoubleClickEvent,
  onSelectEvent,
  selected,
}) {
  const headerRef = useRef(null);
  const dateColRef = useRef(null);
  const timeColRef = useRef(null);
  const contentRef = useRef(null);
  const tbodyRef = useRef(null);

  // asc | desc

  const {
    currentFacilityIds,
    currentStaff,
    currentTeleFacility,
    currentOperationType,
  } = useSelector((state) => ({
    currentFacilityIds: state.calendar.currentFacilityIds,
    currentStaff: state.calendar.currentStaff,
    currentTeleFacility: state.calendar.currentTeleFacility,
    currentOperationType: state.calendar.currentOperationType,
  }));

  const timeRangeLabel = (event) => {
    let labelClass = "",
      TimeComponent = components.time,
      label = localizer.messages.allDay;

    let end = accessors.end(event);
    let start = accessors.start(event);

    if (!accessors.allDay(event)) {
      if (localizer.eq(start, end)) {
        label = localizer.format(start, "agendaTimeFormat");
      } else if (localizer.isSameDate(start, end)) {
        label = localizer.format({ start, end }, "agendaTimeRangeFormat");
      } else if (localizer.isSameDate(start)) {
        label = localizer.format(start, "agendaTimeFormat");
      } else if (localizer.isSameDate(end)) {
        label = localizer.format(end, "agendaTimeFormat");
      }
    }

    return (
      <span className={labelClass.trim()}>
        {TimeComponent ? <TimeComponent event={event} label={label} /> : label}
      </span>
    );
  };

  let { messages } = localizer;
  let end = localizer.add(date, length, "day");

  let range = localizer.range(date, end, "day");
  events = useMemo(() => {
    return events.filter(
      (event) =>
        inRange(
          event,
          localizer.startOf(date, "day"),
          localizer.endOf(end, "day"),
          accessors,
          localizer
        ) &&
        (currentStaff?.length === 0 ||
          currentStaff?.includes(event.staffId) ||
          (currentStaff?.roleName === "Admin" && currentStaff?.isActive)) &&
        ((currentOperationType === CalenderOperationType.Telehealth &&
          currentTeleFacility?.includes?.(event.facilityId)) ||
          (currentOperationType === CalenderOperationType.All &&
            currentFacilityIds?.includes?.(event.facilityId)))
    );
  }, [events, currentStaff, currentOperationType, currentTeleFacility]);

  events.sort((a, b) => +accessors.start(a) - +accessors.start(b));

  // const minPageIndex = (page - 1) * pageSize;
  // const maxPageIndex = page * pageSize;
  // const paginatedEvents = events.filter((event, index) => {
  //   if (index >= minPageIndex && index < maxPageIndex) return true;
  //   return false;
  // });

  return (
    <div className="rbc-agenda-view">
      <Table
        latout="auto"
        dataSource={events}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: [15, 25, 50, 100, 250],
        }}
        columns={[
          {
            title: "Time",
            key: "time",
            width: 240,
            sortDirections: ["ascend"],
            sorter: (a, b) => {
              return +accessors.start(a) - +accessors.start(b);
            },
            render: (event) => {
              return <>{timeRangeLabel(event)}</>;
            },
          },
          {
            title: "Clinician",
            key: "clincian",
            width: 200,
            render: (event) => {
              return <>{event.staffName}</>;
            },
            sorter: (a, b) => {
              if (a.staffName?.toLowerCase?.() < b.staffName?.toLowerCase?.())
                return -1;
              if (a.staffName?.toLowerCase?.() > b.staffName?.toLowerCase?.())
                return 1;
              return 0;
            },
          },
          {
            title: "Facility",
            key: "facility",
            width: 240,
            sorter: (a, b) => {
              if (
                a.facilityName?.toLowerCase?.() <
                b.facilityName?.toLowerCase?.()
              )
                return -1;
              if (
                a.facilityName?.toLowerCase?.() >
                b.facilityName?.toLowerCase?.()
              )
                return 1;
              return 0;
            },
            render: (event) => {
              return <>{event.facilityName}</>;
            },
          },
          {
            title: "Event",
            key: "event",
            render: (event) => {
              let title = accessors.title(event);
              const { event: Event, date: AgendaDate } = components;
              return Event ? <Event event={event} title={title} /> : title;
            },
          },
        ]}
      />
    </div>
  );
}

MyCustomAgendaComponent.propTypes = {
  accessors: PropTypes.object.isRequired,
  components: PropTypes.object.isRequired,
  date: PropTypes.instanceOf(Date),
  events: PropTypes.array,
  getters: PropTypes.object.isRequired,
  length: PropTypes.number.isRequired,
  localizer: PropTypes.object.isRequired,
  onSelectEvent: PropTypes.func,
  onDoubleClickEvent: PropTypes.func,
  selected: PropTypes.object,
};

MyCustomAgendaComponent.defaultProps = {
  length: 30,
};

MyCustomAgendaComponent.range = (
  start,
  { length = MyCustomAgendaComponent.defaultProps.length, localizer }
) => {
  let end = localizer.add(start, length, "day");
  return { start, end };
};

MyCustomAgendaComponent.navigate = (
  date,
  action,
  { length = MyCustomAgendaComponent.defaultProps.length, localizer }
) => {
  switch (action) {
    case navigate.PREVIOUS:
      return localizer.add(date, -length, "day");

    case navigate.NEXT:
      return localizer.add(date, length, "day");

    default:
      return date;
  }
};

MyCustomAgendaComponent.title = (
  start,
  { length = MyCustomAgendaComponent.defaultProps.length, localizer }
) => {
  let end = localizer.add(start, length, "day");
  return localizer.format({ start, end }, "agendaHeaderFormat");
};

export default MyCustomAgendaComponent;
