import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import FormBuilders from "../FormBuilder";
import * as variables from "../variables";

import store from "../FormBuilder/stores/store";
import { update } from "lodash";
import "./FormDesigner.scss";
import {
  Button as AntdButton,
  Col,
  Form,
  Modal,
  Row,
  Space,
  Tabs,
  Typography,
} from "antd";
import { goBack } from "connected-react-router";
import { useDispatch } from "react-redux";
import {
  createForm,
  getFormById,
  postFormVersion,
  putFormVersion,
  updateForm,
} from "services/forms.service";
import {
  FormCheckbox,
  FormCheckboxGroup,
  FormDatePicker,
  FormHiddenSubmitButton,
  FormInput,
  FormInputArea,
  FormInputNumber,
  FormPhoneNumber,
  FormRadioGroup,
  FormSelect,
  FormSlider,
} from "components/FormItems/FlatFormItems";
import FormElements from "../FormBuilder/sortable-form-elements";
import { useForm } from "antd/lib/form/Form";
import Signature, { useSignature } from "components/Signature/Signature";
import { Button } from "components/Buttons/Buttons";
import { useParams } from "react-router";
import { getAllServices } from "services/servicesAndBoostsSettings.service";
import FormParsedUI from "./FormParseUi";
import { FormType } from "utils/enums";
import { getAllContraindications } from "services/contraindications.service";

const { Header } = FormElements;

const url = "/api/formdata";
const saveUrl = "/api/formdata";

const { Text, Title } = Typography;

function FormDesigner(props) {
  const [id, setId] = useState();
  const [activeTab, setActiveTab] = useState();
  const [data, setData] = useState();
  const [preview, setPreview] = useState(false);
  store.subscribe((state) => update(state.data));
  const [formInfo] = useForm();
  const [form] = useForm();
  const submitBtnRef = useRef();

  const [contraindications, setContraindications] = useState([]);

  const [formInformation, setFormInformation] = useState();
  const [products, setProducts] = useState([]);
  const selectedCategory = Form.useWatch("category", formInfo);

  const [signature, getSignatureValue, clearSignatureValue, setSignatureValue] =
    useSignature("intakeForm", form);
  const [
    signature1,
    getSignatureValue1,
    clearSignatureValue1,
    setSignatureValue1,
  ] = useSignature("intakeForm1", form, "signature1");

  const dispatch = useDispatch();
  const params = useParams();

  const getData = async () => {
    const result = await dispatch(getFormById(params?.id));
    const { designJSON } = result?.data ?? {};
    if (designJSON !== null && designJSON !== undefined && designJSON !== "")
      store.dispatch("loadJsonData", {
        data: JSON.parse(result?.data?.designJSON ?? {}),
      });
    if (result?.status === "success") {
      setFormInformation({ ...result.data });
      let category = "intake";
      let chartOrForm = "intake";

      if (result?.data?.referenceId) {
        category = "chart";
        chartOrForm = result?.data?.referenceId;
      } else {
        chartOrForm = result?.data?.formType;
      }

      formInfo.setFieldsValue({
        ...result.data,
        category,
        chartOrForm,
      });
      if (designJSON !== null && designJSON !== undefined && designJSON !== "")
        setData(JSON.parse(result?.data?.designJSON ?? {}));
    }

    dispatch(getAllContraindications()).then((res) => {
      if (res?.status === "success") {
        setContraindications(res?.data ?? []);
      }
    });
  };

  useEffect(() => {
    getData();

    dispatch(getAllServices(null, false)).then((res) => {
      if (res?.status === "success") {
        setProducts(
          res?.data
            ?.filter?.((it) => it?.isActive)
            ?.map((it) => {
              return {
                value: it?.productId,
                label: it?.productName,
              };
            })
        );
      }
    });
  }, []);

  const update = (data) => {
    setData(data);
  };
  return (
    <div style={{ padding: 20, overflowY: "scroll" }}>
      <Tabs
        className="formDesignerTab"
        defaultActiveKey="2"
        activeKey={activeTab}
        onChange={(tab) => setActiveTab(tab)}
        tabBarExtraContent={
          activeTab !== "1" ? (
            <Button
              style={{
                height: 40,
              }}
              rounded={true}
              disabled={formInformation?.isActive}
              onClick={() => {
                const { formId, latestVersionId } = formInformation ?? {};

                if (latestVersionId !== null && latestVersionId !== undefined)
                  dispatch(
                    putFormVersion(
                      formId,
                      latestVersionId,
                      JSON.stringify(data)
                    )
                  ).then(() => {});
                else
                  dispatch(postFormVersion(formId, JSON.stringify(data))).then(
                    () => {}
                  );
              }}
            >
              Save Design
            </Button>
          ) : (
            <></>
          )
        }
      >
        <Tabs.TabPane tab="Form Information" key="1">
          <Form
            style={{ maxWidth: 560 }}
            preserve={false}
            layout="vertical"
            form={formInfo}
            requiredMark={false}
            onFinish={(values) => {
              let formData = {
                formName: values.formName,
                formDescription: values.formDescription,
                isActive: values.isActive,
              };

              if (values.category === "intake") {
                if (values.chartOrForm === 3) {
                  formData.formType = FormType.consent;
                } else if (values.chartOrForm === 2) {
                  formData.formType = FormType.historyOrPhysical;
                } else if (values.chartOrForm === 4) {
                  formData.formType = FormType.goodFaithExam;
                }
              } else {
                formData.referenceId = values.chartOrForm;
                formData.formType = FormType.chart;
              }

              dispatch(updateForm(formInformation?.formId, formData));
            }}
            onFinishFailed={({ values, errorFields, outOfDate }) => {
              form.scrollToField(errorFields[0].name, {
                scrollMode: "if-needed",
                block: "center",
                behavior: "smooth",
              });
            }}
          >
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <FormInput
                  variant="underlined"
                  required={true}
                  formItemStyles={{ margin: 0 }}
                  name="formName"
                  label="Form Name"
                  placeholder="Enter here..."
                />
              </Col>
              <Col xs={24}>
                <FormInputArea
                  label={"Form Description"}
                  name={"formDescription"}
                  variant="underlined"
                  formItemStyles={{ margin: 0 }}
                  // initialValue={notes[0] ? notes[0].noteDescription : ''}
                />
              </Col>
              <Col xs={12}>
                <FormSelect
                  // disabled={isAppointmentFormDisabled}
                  variant="underlined"
                  name="category"
                  label="Category"
                  placeholder="Select"
                  required={true}
                  formItemStyles={{ marginBottom: 12 }}
                  options={[
                    { label: "Intake Form", value: "intake" },
                    { label: "Chart", value: "chart" },
                  ]}
                  renderLabel="label"
                  renderValue="value"
                  onChange={(value) => {
                    form.setFieldsValue({
                      chartOrForm: undefined,
                    });
                  }}
                />
              </Col>
              <Col xs={12}>
                <FormSelect
                  // disabled={isAppointmentFormDisabled}
                  variant="underlined"
                  name="chartOrForm"
                  label="Chart or Form" // dynamic
                  placeholder="Select"
                  required={true}
                  formItemStyles={{ marginBottom: 12 }}
                  options={
                    selectedCategory === "chart"
                      ? products
                      : [
                          {
                            value: 2,
                            label: "History & Physical Form",
                          },
                          {
                            value: 3,
                            label: "Consent Intake Form",
                          },
                          {
                            value: 4,
                            label: "Good Faith Exam",
                          },
                        ]
                  }
                  showSearch
                  filterOption={(input, option) => {
                    return option?.label
                      ?.toLowerCase?.()
                      .includes?.(input.toLowerCase());
                  }}
                  renderLabel="label"
                  renderValue="value"
                  // onChange={(value) => {}}
                />
              </Col>
              <FormCheckbox hidden={true} name="isActive" disabled={true}>
                Active
              </FormCheckbox>
              <Col xs={24}>
                <Button
                  rounded={true}
                  disabled={formInformation?.isActive}
                  htmlType="submit"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Form Designer" key="2">
          <form className="ant-form ant-form-vertical" onSubmit={() => {}}>
            <FormBuilders.ReactFormBuilder
              contraindications={contraindications}
              variables={variables}
              // url={url}
              // saveUrl={saveUrl}
              locale="en"
              saveAlways={false}
              // toolbarItems={items}
            />
          </form>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Preview" key="3">
          <Form
            form={form}
            layout="vertical"
            requiredMark={true}
            style={{
              overflow: "hidden",
              overflowY: "scroll",
              height: "calc(100vh - 86px - 120px)",
              minHeight: 400,
              paddingBottom: 300,
              paddingRight: 20,
            }}
          >
            <FormParsedUI
              data={data}
              form={form}
              signature={signature}
              getSignatureValue={getSignatureValue}
              clearSignatureValue={clearSignatureValue}
              setSignatureValue={setSignatureValue}
              signature1={signature1}
              getSignatureValue1={getSignatureValue1}
              clearSignatureValue1={clearSignatureValue1}
              setSignatureValue1={setSignatureValue1}
            />
          </Form>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

FormDesigner.propTypes = {};

export default FormDesigner;
