/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */

import Axios from "axios";
import moment from "moment";
import apiObj from "../utils/api";
import { clearStorage, getItem } from "../utils/storage";
import { setupCache } from "axios-cache-interceptor";

const api = apiObj.url;

export const fetchToken = () => {
  const tokenContainer = getItem("tokenContainer");
  return `Bearer ${tokenContainer.token}`;
};

let lastApiTime = moment();

let timeOut = null;

const updateLastActive = () => {
  if (timeOut) {
    clearTimeout(timeOut);
    timeOut = setTimeout(() => {
      if (moment().diff(lastApiTime, "minutes") >= 5) {
        clearStorage();
      }
    }, 300000);
  } else {
    timeOut = setTimeout(() => {
      if (moment().diff(lastApiTime, "minutes") >= 5) {
        clearStorage();
      }
    }, 300000);
  }
};

export const request = (
  url,
  type,
  data,
  headers,
  params,
  extraOptions = null
) =>
  new Promise((resolve, reject) => {
    // lastApiTime = moment()
    // updateLastActive()

    const request = {
      method: type,
      url: api + url,
      headers: {
        accept: "*/*",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "any",
      },
      ...extraOptions,
    };
    if (headers) {
      request.headers = {
        Authorization: fetchToken(),
        "ngrok-skip-browser-warning": "any",
      };
      if (headers.contentType) {
        request.headers["content-type"] = headers.contentType;
      }
    }
    type !== "get" && (request.data = data);
    params && (request.params = params);
    if (!request.url.includes("refresh")) {
      Axios(request).then(resolve, reject);
    }
  });

const instance = Axios.create();
const cachedAxios = setupCache(instance, {
  debug: console.log,
  ttl: 1000 * 30 * 1,
});

export const cachedRequest = (
  url,
  type,
  data,
  headers,
  params,
  extraOptions = null
) =>
  new Promise(async (resolve, reject) => {
    // lastApiTime = moment()
    // updateLastActive()

    const request = {
      method: type,
      url: api + url,
      headers: {
        accept: "*/*",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "any",
      },
      ...extraOptions,
    };
    if (headers) {
      request.headers = {
        Authorization: fetchToken(),
        "ngrok-skip-browser-warning": "any",
      };
      if (headers.contentType) {
        request.headers["content-type"] = headers.contentType;
      }
    }
    type !== "get" && (request.data = data);
    params && (request.params = params);
    if (!request.url.includes("refresh")) {
      cachedAxios(request).then(resolve, reject);
    }
  });
