import React from "react";
import PropTypes from "prop-types";

import "./DropdownButton.scss";

import { Button as AntdButton, Dropdown } from "antd";
import { ReactComponent as ChevronDownIco } from "assets/icons/chevronDownIco.svg";


export const DropdownButton = ({
  htmlType,
  children,
  size,
  style,
  onClick,
  loading,
  disabled,
  variant = "filled",
  className,
  rounded = false,
  icon = null,
  type,
  menu,
}) => {
  return <Dropdown.Button
  className="dropdownButton"
  transitionName="none"
  overlay={menu}
  onClick={onClick}
  icon={<ChevronDownIco/>}
  >{children}</Dropdown.Button>;
};

DropdownButton.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
  menu: PropTypes.any,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  style: PropTypes.object,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(["filled", "outlined"]),
  className: PropTypes.string,
  rounded: PropTypes.bool,
};
