import React from "react";
import ReactDOM from "react-dom";

export default function useSettingsHeader(content) {
  // states start
  const [hasMounted, setHasMounted] = React.useState(false);
  // states end
  React.useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }


  return ReactDOM.createPortal(
    content,
    document.querySelector(`#settingHeader .settingsHeaderRightSide`)
  );
}
