let DUMMY_CATEGORIES = [
  {
    categoryId: 0,
    categoryName: "Anti-aging",
    services: 10,
    dateCreated: "10/9/2022",
    dateUpdated: "10/9/2022",
  },
  {
    categoryId: 1,
    categoryName: "Mental Clarity",
    services: 20,
    dateCreated: "10/9/2022",
    dateUpdated: "10/9/2022",
  },
];

const setCategories = (data) => {
  DUMMY_CATEGORIES = data;
};

let DUMMY_BOOSTS = [
  {
    boostId: 0,
    boostName: "Glutathione",
    boostImage: "",
    dateCreated: "10/9/2022",
    dateUpdated: "10/9/2022",
  },
  {
    boostId: 1,
    boostName: "Zinc",
    boostImage: "",
    dateCreated: "10/9/2022",
    dateUpdated: "10/9/2022",
  },
  {
    boostId: 2,
    boostName: "Tri-immune Boost",
    boostImage: "",
    dateCreated: "10/9/2022",
    dateUpdated: "10/9/2022",
  },
];

const setBoosts = (data) => {
  DUMMY_BOOSTS = data;
};

let DUMMY_SERVICES = [
  {
    serviceId: 0,
    serviceName: "Beauty & Glow",
    categoryId: 0,
    serviceImage: "",
    boostIds: [1, 2],
    dateCreated: "10/9/2022",
    dateUpdated: "10/9/2022",
    get boosts() {
      return this.boostIds.map((boostId) => {
        const boost = DUMMY_BOOSTS.find((boost) => boost.boostId === boostId);
        return boost?.boostName || "NA";
      });
    },
    get categoryName() {
      return (
        DUMMY_CATEGORIES.find(
          (category) => this.categoryId === category.categoryId
        )?.categoryName || "NA"
      );
    },
  },
  {
    serviceId: 1,
    serviceName: "Mental Clarity",
    categoryId: 1,
    serviceImage: "",
    boostIds: [0, 1, 2],
    dateCreated: "10/9/2022",
    dateUpdated: "10/9/2022",
    get boosts() {
      return this.boostIds.map((boostId) => {
        const boost = DUMMY_BOOSTS.find((boost) => boost.boostId === boostId);
        return boost?.boostName || "NA";
      });
    },
    get categoryName() {
      return (
        DUMMY_CATEGORIES.find(
          (category) => this.categoryId === category.categoryId
        )?.categoryName || "NA"
      );
    },
  },
];

const setServices = (data) => {
  DUMMY_SERVICES = data;
};

let DUMMY_LOCATIONS = [
  {
    facilityId: 0,
    facilityName: "Phoenix",
    shiftScheduleId: 0,
    staff: 10,
    notes: "",
    phoneNumber: "1234567891",
    email: "phoenix@test.com",
    time: "aasdas",
    shiftStart: "12:00 A",
    shiftEnd: "8:00 A",
    serviceTypeId: 0,
  },
];

const setLocations = (data) => {
  DUMMY_LOCATIONS = data;
};

let DUMMY_SERVICE_TYPES = [
  {
    serviceTypeId: 0,
    serviceTypeName: "Clinic",
    dateCreated: "10/9/2022",
    dateUpdated: "10/9/2022",
  },
  {
    serviceTypeId: 1,
    serviceTypeName: "Mobile",
    dateCreated: "10/9/2022",
    dateUpdated: "10/9/2022",
  },
  {
    serviceTypeId: 1,
    serviceTypeName: "Telehealth",
    dateCreated: "10/9/2022",
    dateUpdated: "10/9/2022",
  },
];

const setServiceTypes = (data) => {
  DUMMY_SERVICE_TYPES = data;
};

let DUMMY_SHIFT_SCHEDULE = [
  {
    shiftScheduleId: 0,
    shiftScheduleName: "Morning Shift",
  },
];

const setShiftSchedule = (data) => {
  DUMMY_SHIFT_SCHEDULE = data;
};

let DUMMY_EXCLUSIONS = [];

const setExclusions = (data) => {
  DUMMY_EXCLUSIONS = data;
};

export {
  DUMMY_CATEGORIES,
  setCategories,
  DUMMY_SERVICES,
  setServices,
  DUMMY_BOOSTS,
  setBoosts,
  DUMMY_LOCATIONS,
  setLocations,
  DUMMY_SERVICE_TYPES,
  setServiceTypes,
  DUMMY_SHIFT_SCHEDULE,
  setShiftSchedule,
  DUMMY_EXCLUSIONS,
  setExclusions,
};
