import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Drawer,
  Form,
  Row,
  Typography,
  Button as AntdButton,
  Upload,
  Space,
  Divider,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import {
  FormInput,
  FormInputArea,
  FormHiddenSubmitButton,
  FormPhoneNumber,
  FormSelect,
  FormCheckbox,
  FormRadioGroup,
  FormInputNumber,
} from "components/FormItems/FlatFormItems";
import { useForm } from "antd/lib/form/Form";
import { Button } from "components/Buttons/Buttons";
import FilesUploader from "components/FilesUploader/FilesUploader";
import { getCategoryById } from "services/categoriesSettings.services";
import { useDispatch } from "react-redux";
import { getRestrictionsById } from "services/restrictions.service";
import { openNotificationWithIcon } from "utils/Notification";

function RestrictionsDrawer({
  visible,
  data,
  onClose,
  onSuccess,
  services = [],
  boosts = [],
}) {
  const [form] = useForm();
  const submitBtnRef = useRef();

  const isEdit = data?.productRestrictionId !== undefined;

  const dispatch = useDispatch();
  const [clearFiels, setClearFields] = useState(null);
  const [inputMode, setInputMode] = useState("multiple");
  const [disableOnEdit, setDisableOnEdit] = useState(null);
  const selectedType = Form.useWatch("type", form);
  const selectProductId = Form.useWatch("productId", form);
  const myProductType = Form.useWatch("myProductType", form);
  const timeInterval = Form.useWatch("timeInterval", form);
  const restrictedProductId = Form.useWatch("restrictedProductIds", form);

  const getData = async (id) => {
    const result = await dispatch(getRestrictionsById(id));
    if (result?.status === "success") {
      // const mytype = result.data.fromAge ? 3 : result.data.productType;

      form.setFieldsValue({
        ...result.data,
        restrictedProductIds: [result.data.restrictedProductId],
        timeInterval: result.data.timeInterval,
        // type: mytype,
        myProductType: result.data.productType,
        type: result?.data?.type !== 3 ? result?.data?.productType : 3,

      });
      setDisableOnEdit(result?.data?.type !== 3 ? result?.data?.productType : 3);
    }
  };
  useEffect(() => {
    // form.setFieldsValue({ ["productId"]: null });
  }, [clearFiels]);
  useEffect(() => {
    if (visible === false) {
      form.resetFields();
      setDisableOnEdit(null);
    } else if (data?.productRestrictionId !== undefined) {
      form.setFieldsValue({
        ...data,
        type: data?.type !== 3 ? data?.productType : 3,
      });
      setInputMode("single");
      getData(data?.productRestrictionId);
    } else {
      setInputMode("multiple");
      form.setFieldsValue({
        type: 1,
      });

      setDisableOnEdit(null);
    }
  }, [visible]);

  return (
    <Drawer
      destroyOnClose
      headerStyle={{ border: "none" }}
      className="restrictionsDrawer"
      width={"min(100vw, 420px)"}
      title={
        <div className="restrictionsDrawerTitle">
          <p>{visible && (isEdit ? "Edit" : "Create")} Restrictions</p>
          <CloseOutlined onClick={onClose} />
        </div>
      }
      placement="right"
      onClose={onClose}
      visible={visible}
      closable={false}
      footer={
        <div className="restrictionsDrawerFooter">
          <AntdButton type="text" onClick={onClose}>
            Cancel
          </AntdButton>
          <Button
            rounded
            type={"primary"}
            style={{ height: 50, width: "50%" }}
            onClick={() => submitBtnRef?.current?.click()}
          >
            Save
          </Button>
        </div>
      }
    >
      <Form
        preserve={false}
        layout="vertical"
        form={form}
        requiredMark={false}
        onFinish={(values) => {
          if (selectedType === 3 && !values.fromAge && !values.toAge) {
            form.scrollToField("fromAge", {
              scrollMode: "if-needed",
              block: "center",
              behavior: "smooth",
            });
            openNotificationWithIcon(
              "error",
              "Error ",
              "No age restrictions provided."
            );
            return;
          }
          onSuccess(values);
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
      >
        <Row gutter={[20, 20]}>
          <Col xs={24}>
            <FormRadioGroup
              name="type"
              label="Service Type"
              initialValue="service"
              required
              inline
              options={[
                {
                  label: "Service",
                  value: 1,
                  disabled: disableOnEdit && disableOnEdit != 1 ? true : false,
                },
                {
                  label: "Add-ons",
                  value: 2,
                  disabled: disableOnEdit && disableOnEdit != 2 ? true : false,
                },
                {
                  label: "Age",
                  value: 3,
                  disabled: disableOnEdit && disableOnEdit != 3 ? true : false,
                },
              ]}
              style={{
                marginBottom: 0,
              }}
              onChangeHandler={() => {
                form.setFieldsValue({ ["productId"]: null });
                form.setFieldsValue({ ["restrictedProductIds"]: [] });
                form.setFieldsValue({ ["timeInterval"]: null });
              }}
            />
          </Col>
          {selectedType === 3 && (
            <>
              <Col xs={24}>
                <FormSelect
                  required
                  allowClear
                  variant="underlined"
                  name="myProductType"
                  label={"Product Type"}
                  placeholder={"Select"}
                  renderLabel="label"
                  renderValue="value"
                  styles={{ border: "none" }}
                  options={[
                    {
                      label: "Service",
                      value: 1,
                    },
                    {
                      label: "Add-ons",
                      value: 2,
                    },
                  ]}
                  formItemStyles={{ margin: 0 }}
                  showSearch={true}
                  filterOption={(input, option) => {
                    return option?.label
                      ?.toLowerCase?.()
                      .includes?.(input.toLowerCase());
                  }}
                />
              </Col>
              <Col xs={24}>
                <FormSelect
                  required
                  mode={selectedType === 3 && isEdit ? "single" : "multiple"}
                  variant="underlined"
                  name="productId"
                  label={
                    myProductType === 1
                      ? "Service"
                      : myProductType === 2
                      ? "Add-ons"
                      : "Services/Boosts"
                  }
                  placeholder={"Select"}
                  renderLabel="productName"
                  renderValue="productId"
                  options={
                    myProductType === 1
                      ? services
                      : myProductType === 2
                      ? boosts
                      : []
                  }
                  formItemStyles={{ margin: 0 }}
                  filterOption={(input, option) => {
                    return option?.label
                      ?.toLowerCase?.()
                      .includes?.(input.toLowerCase());
                  }}
                />
              </Col>
            </>
          )}

          {selectedType !== 3 && (
            <>
              <Col xs={24}>
                <FormSelect
                  required
                  variant="underlined"
                  name="productId"
                  label={
                    selectedType === 1
                      ? "Service"
                      : selectedType === 2
                      ? "Add-ons"
                      : "Services/Boosts"
                  }
                  placeholder={"Select"}
                  renderLabel="productName"
                  renderValue="productId"
                  options={
                    selectedType === 1
                      ? services
                      : selectedType === 2
                      ? boosts
                      : [...services, ...boosts]
                  }
                  formItemStyles={{ margin: 0 }}
                  showSearch={true}
                  filterOption={(input, option) => {
                    return option?.label
                      ?.toLowerCase?.()
                      .includes?.(input.toLowerCase());
                  }}
                />
              </Col>
              <Col xs={24}>
                <FormInputNumber
                  variant="underlined"
                  name="timeInterval"
                  label={"Time Interval (In Hours)"}
                  placeholder="Time Interval"
                  min={0}
                  required={selectedType === 1 ? true : false}
                  formItemStyles={{
                    margin: 0,
                  }}
                />
              </Col>
              <Col xs={24}>
                <FormSelect
                  required
                  variant="underlined"
                  name="restrictedProductIds"
                  mode={isEdit ? "single" : "multiple"}
                  label={
                    selectedType === 1
                      ? "Service"
                      : selectedType === 2
                      ? "Add-ons"
                      : "Services/Boosts"
                  }
                  placeholder={"Select"}
                  renderLabel="productName"
                  renderValue="productId"
                  options={
                    selectedType === 1
                      ? services
                      : selectedType === 2
                      ? boosts
                      : [...services, ...boosts]
                  }
                  showSearch={true}
                  filterOption={(input, option) => {
                    return option?.label
                      ?.toLowerCase?.()
                      .includes?.(input.toLowerCase());
                  }}
                  formItemStyles={{ margin: 0 }}
                  // isOptionDisabled={(option) => {
                  //   if (option.productId == selectProductId) {
                  //     return true;
                  //   } else {
                  //     return false;
                  //   }
                  // }}
                />
              </Col>
            </>
          )}

          {selectedType === 3 && (
            <>
              <Col xs={24}>
                <FormInputNumber
                  variant="underlined"
                  // required
                  name="fromAge"
                  label="Above Age"
                  placeholder="Above Age"
                  min={0}
                  formItemStyles={{ margin: 0 }}
                  dependencies={["toAge"]}
                  rules={[
                    {
                      validator: (_, value) => {
                        const toAge = form.getFieldValue(["toAge"]);
                        if (!value && !toAge)
                          return Promise.reject(
                            "Please enter valid age fields"
                          );

                        if (
                          typeof value === "number" &&
                          typeof toAge === "number" &&
                          Number(value) >= Number(toAge)
                        )
                          return Promise.reject("Please enter correct values");

                        return Promise.resolve();
                      },
                    },
                  ]}
                />
              </Col>
              <Col xs={24}>
                <FormInputNumber
                  variant="underlined"
                  // required
                  name="toAge"
                  label="Below Age"
                  placeholder="Below Age"
                  min={0}
                  dependencies={["fromAge"]}
                  rules={[
                    {
                      validator: (_, value) => {
                        const fromAge = form.getFieldValue(["fromAge"]);
                        if (
                          typeof value === "number" &&
                          typeof fromAge === "number" &&
                          Number(value) <= Number(fromAge)
                        )
                          return Promise.reject("Please enter correct values");
                        return Promise.resolve();
                      },
                    },
                  ]}
                />
              </Col>
            </>
          )}
        </Row>

        <Row gutter={[16, 16]}>
          <FormHiddenSubmitButton submitRef={submitBtnRef} />
        </Row>
      </Form>
    </Drawer>
  );
}

RestrictionsDrawer.propTypes = {};

export default RestrictionsDrawer;
