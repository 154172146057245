export const actions = {
  SET_STATES: "SET_STATES",
  SET_CITIES: "SET_CITIES",
};

export const setStates = (data) => ({
  type: actions.SET_STATES,
  data,
});

export const setCities = (data) => ({
  type: actions.SET_CITIES,
  data,
});
