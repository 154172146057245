import React from "react";
import PropTypes from "prop-types";

import { Modal as AntdModal } from "antd";
import { ReactComponent as CloseIcon } from "assets/icons/closeIco.svg";

// Modal that customizes antd modal based on designs
function Modal(props) {
  const { headerBorder = true, footer } = props;

  return (
    <AntdModal
      {...props}
      closeIcon={<CloseIcon />}
      className={`antdModal ${props.className ? props.className : ""}
  ${!headerBorder ? "noHeaderBorder" : "headerBorder"}
  ${!footer ? "" : "hasFooter"}
  `}
    />
  );
}

Modal.propTypes = {
  headerBorder: PropTypes.bool,
  footer: PropTypes.node,
  // rest of the props from ANTD MODAL
};

export default Modal;
