import { actions } from "../actions/order.actions";

const INITIAL_STATE = {
  allOrders: [],
  currentOrder: null,
};

const reducer = (state = INITIAL_STATE, { type, data }) => {
  switch (type) {
    case actions.SET_ALL_ORDERS:
      return { ...state, allOrders: data };
    case actions.SET_CURRENT_ORDER:
      return { ...state, currentOrder: data };
    case actions.ADD_NEW_ORDER:
      return { ...state, allOrders: [...state.allOrders, data] };
    case "RESET":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
