import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Col,
  Drawer,
  Form,
  Row,
  Typography,
  Button as AntdButton,
  Upload,
  Space,
  Divider,
  Tag,
  Alert,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import {
  FormInput,
  FormInputArea,
  FormHiddenSubmitButton,
  FormPhoneNumber,
  FormSelect,
  FormCheckbox,
} from "components/FormItems/FlatFormItems";
import { useForm } from "antd/lib/form/Form";
import { Button } from "components/Buttons/Buttons";
import FilesUploader from "components/FilesUploader/FilesUploader";
import { getCategoryById } from "services/categoriesSettings.services";
import { useDispatch } from "react-redux";
import { getAllPrograms } from "services/programsAndMembership.services";
import Modal from "components/Modal/Modal";
import ReviewProgram from "components/ReviewProgram/ReviewProgram.index";
import { PROGRAM_TYPE, getEnumKeyByValue } from "utils/enums";
import {
  cancelMembership,
  getProgramsAndMemberships,
  purchaseProgram,
} from "services/guests.services";
import { findWindows } from "windows-iana";
import moment from "moment";

const { Title, Text, Link } = Typography;
const { Dragger } = Upload;

const PRICINGS = [1, 2, 3];

export default function ProgramDrawer({
  customerId,
  visible,
  onClose,
  onSuccess,
  purchasedPrograms,
  paymentMethodId,
}) {
  const dispatch = useDispatch();

  const [data, setData] = useState({
    data: [],
    isLoading: true,
  });
  const disaptch = useDispatch();
  const [showDetail, setShowDetail] = useState();

  const isMembershipCancellable =
    showDetail?.programType === PROGRAM_TYPE.Membership &&
    !!showDetail?.isPurchased &&
    !showDetail?.isCancelled;

  // const isPurchased = useMemo(() => {
  //   if (
  //     purchasedPrograms?.find((it) => it?.programId === showDetail?.programId)
  //   )
  //     return true;
  //   return false;
  // }, [showDetail, data, purchasedPrograms]);
  useEffect(() => {
    if (visible) {
      disaptch(getProgramsAndMemberships(customerId, false))
        .then((res) => {
          if (res?.status === "success")
            setData({
              data: res?.data,
              isLoading: false,
            });
        })
        .finally(() => {
          setData((state) => ({
            ...state,
            isLoading: false,
          }));
        });
    } else {
      setShowDetail();
    }
  }, [visible]);

  const handlePurchase = () => {
    dispatch(
      purchaseProgram({
        customerId,
        paymentMethodId: paymentMethodId,
        timeZone: findWindows(moment?.tz?.guess?.() || "")?.[0],
        product: {
          productId: showDetail?.productId,
          paymentGatewayPriceId: showDetail?.paymentGatewayPriceId,
        },
      })
    ).then((res) => {
      if (res?.status === "success") {
        onSuccess();
      }
    });
  };

  const handleCancellation = () => {
    dispatch(cancelMembership(showDetail?.customerProgramId, customerId)).then(
      (res) => {
        if (res?.status === "success") {
          onSuccess();
        }
      }
    );
  };

  return (
    <Drawer
      destroyOnClose
      headerStyle={{ border: "none" }}
      className="skillsDrawer"
      width={"min(100vw, 520px)"}
      title={
        <div className="skillsDrawerTitle">
          <p>Purchase Programs & Memberships</p>
          <CloseOutlined onClick={onClose} />
        </div>
      }
      placement="right"
      onClose={onClose}
      visible={visible}
      closable={false}
      footer={<></>}
    >
      <div>
        {data?.data?.map((it) => {
          // const isPurchased = purchasedPrograms?.find(
          //   (it2) => it?.programId === it2.programId
          // );
          // console.log("purchased", isPurchased);
          return (
            <div
              style={{
                marginBottom: 12,
                paddingBottom: 12,
                borderBottom: "1px solid #EFF1F4",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Title
                  style={{
                    fontSize: 16,
                    color: "#000",
                    marginBottom: 4,
                  }}
                >
                  {it?.programName}
                </Title>
                <Link onClick={() => setShowDetail(it)}>Details</Link>
              </div>
              <Text style={{ paddingRight: 6 }}>${it?.sellingPrice}</Text>{" "}
              {!!it?.isPurchased && !it?.isCancelled && (
                <Tag color="green">Purchased</Tag>
              )}
              {!!it?.isPurchased && !!it?.isCancelled && (
                <Tag color="orange">Cancelled</Tag>
              )}
            </div>
          );
        })}
      </div>
      <Modal
        closable={true}
        destroyOnClose={true}
        className="stripeAddCardModal"
        headerBorder={false}
        width="420px"
        title="Program/Membership Details"
        onCancel={() => setShowDetail()}
        visible={showDetail}
        onOk={() => console.log("ok")}
        okText="Add"
        centered={true}
        footer={[
          <Button
            style={{ height: 50 }}
            rounded={true}
            disabled={
              (!paymentMethodId && !isMembershipCancellable) ||
              (!!showDetail?.isPurchased &&
                showDetail?.programType !== PROGRAM_TYPE.Membership) ||
              (showDetail?.programType === PROGRAM_TYPE.Membership &&
                !!showDetail?.isCancelled)
            } // is already purchased
            onClick={() => {
              if (
                showDetail?.programType === PROGRAM_TYPE.Membership &&
                !!showDetail?.isPurchased
              )
                handleCancellation(showDetail?.programId);
              else handlePurchase();
            }}
          >
            {isMembershipCancellable ? "Cancel Membership" : "Purchase"}
          </Button>,
        ]}
        //   cancelText="No"
      >
        <div className="reviewProgram">
          {!paymentMethodId && (
            <Alert
              style={{ marginBottom: 12 }}
              type="warning"
              message="Please add a payment method to purchase."
              closable
            />
          )}
          <Row gutter={[32, 16]}>
            <Col xs={12}>
              <Title>Program Type</Title>
              <Text>
                {getEnumKeyByValue(PROGRAM_TYPE, showDetail?.programType)}
              </Text>
            </Col>
            <Col xs={12}>
              <Title>Program Name</Title>
              <Text>{showDetail?.programName}</Text>
            </Col>
            <Col xs={24}>
              <Title>Notes</Title>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {showDetail?.notes &&
                  showDetail?.notes.split("\n").map((ele) => {
                    return (
                      <Text
                        style={{
                          fontFamily: "tradeGothic",
                        }}
                      >
                        {ele}
                      </Text>
                    );
                  })}
              </div>
              {showDetail?.isPurchased &&
                showDetail?.programType === PROGRAM_TYPE.Membership && (
                  <>
                    <hr />
                    <Text
                      style={{
                        fontFamily: "tradeGothic",
                        marginBottom: 16,
                        fontSize: 17,
                        textAlign: "left",
                      }}
                    >
                      <b
                        style={{
                          fontWeight: 500,
                          color: "#000",
                          fontFamily: "tradeGothic",
                        }}
                      >
                        Billing Cycle:
                      </b>{" "}
                      {moment(showDetail?.fromDate).format("DD MMM YYYY")}
                      &nbsp;-&nbsp;
                      {moment(showDetail?.tillDate).format("DD MMM YYYY")}
                      <br />
                      Charged: $
                      {Number(showDetail?.sellingPrice ?? 0)?.toFixed?.(2)}{" "}
                      <br />
                      {showDetail?.isCancelled && (
                        <>
                          Cancelled on{" "}
                          {moment(showDetail?.cancellationDate).format(
                            "DD MMM YYYY"
                          )}
                          <br />
                        </>
                      )}
                    </Text>
                  </>
                )}
              {showDetail?.isPurchased &&
                showDetail?.programType === PROGRAM_TYPE.Program && (
                  <Text>
                    <b
                      style={{
                        fontWeight: 500,
                        color: "#000",
                        fontFamily: "tradeGothic",
                      }}
                    >
                      Purchased On:{" "}
                      {moment(showDetail?.purchasedDate)?.format("DD MMM YYYY")}
                    </b>
                    <br />
                  </Text>
                )}
              {showDetail?.isPurchased && (
                <>
                  <Text>
                    <b
                      style={{
                        fontWeight: 500,
                        color: "#000",
                        fontFamily: "tradeGothic",
                      }}
                    >
                      Purchased by: {showDetail?.purchasedBy || "Self"}
                    </b>
                    <br />
                  </Text>
                  <Text>
                    <b
                      style={{
                        fontWeight: 500,
                        color: "#000",
                        fontFamily: "tradeGothic",
                      }}
                    >
                      Remaining Items: {showDetail?.rewardsRemaining ?? "NA"}
                    </b>
                  </Text>
                </>
              )}
            </Col>
          </Row>
        </div>
      </Modal>
    </Drawer>
  );
}
