import { actions } from "../actions/calendar.actions";

const INITIAL_STATE = {
  currentFacilityIds: [],
  currentFacility: null,
  currentTeleFacility: [],
  isFetchingFacilities: true,
  currentStaff: [],
  isFetchingStaff: true,
  currentOperationType: null,
};

const reducer = (state = INITIAL_STATE, data) => {
  switch (data.type) {
    case actions.SET_CURRENT_FACILITY_IDS:
      return { ...state, currentFacilityIds: data?.data };
    case actions.SET_CURRENT_FACILITY:
      return { ...state, currentFacility: data?.data };
    case actions.SET_CURRENT_TELEHEALTH_FACILITY:
      return { ...state, currentTeleFacility: data?.data };
    case actions.SET_CURRENT_STAFF_IDS:
      return { ...state, currentStaff: data?.data };
    case actions.SET_CURRENT_OPERATION_TYPE:
      return { ...state, currentOperationType: data?.data };
    case actions.SET_FACILITIES_LOADING:
      return { ...state, isFetchingFacilities: data?.isFetchingFacilities };
    case actions.SET_STAFF_LOADING:
      return { ...state, isFetchingStaff: data?.isFetchingStaff };
    case "RESET":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
