import React, { useEffect, useRef, useState } from "react";
import { Col, Drawer, Form, Row, Button as AntdButton } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import {
  FormInput,
  FormHiddenSubmitButton,
  FormSelect,
} from "components/FormItems/FlatFormItems";
import { useForm } from "antd/lib/form/Form";
import { Button } from "components/Buttons/Buttons";
import { useDispatch } from "react-redux";
import { getAccountById } from "services/quickbook.service";

export default function AccountAddEditDrawer({
  data,
  visible,
  onClose,
  onSuccess,
  accounts,
}) {
  const [form] = useForm();
  const submitBtnRef = useRef();
  const dispatch = useDispatch();
  const isEdit = data?.accountId !== undefined;

  const [completeData, setCompleteData] = useState({});

  const getData = async (id) => {
    const result = await dispatch(getAccountById(id));
    if (result?.status === "success") {
      setCompleteData(result.data);
      form.setFieldsValue({
        ...result.data,
        parentAccountId:
          result?.data?.parentAccountId === 0
            ? undefined
            : result?.data?.parentAccountId,
      });
    }
  };

  useEffect(() => {
    if (visible === false) {
      setCompleteData({});
      form.resetFields();
    } else if (isEdit) {
      form.setFieldsValue({
        ...data,
        parentAccountId:
          data?.parentAccountId === 0 ? undefined : data?.parentAccountId,
      });
      getData(data?.accountId);
    }
  }, [visible]);

  return (
    <Drawer
      destroyOnClose
      headerStyle={{ border: "none" }}
      className="integrationDrawer"
      width={"min(100vw, 420px)"}
      title={
        <div className="integrationDrawerTitle">
          <p>{visible && (isEdit ? "Edit" : "Create")} Account</p>
          <CloseOutlined onClick={onClose} />
        </div>
      }
      placement="right"
      onClose={onClose}
      visible={visible}
      closable={false}
      footer={
        <div className="integrationDrawerFooter">
          <AntdButton type="text" onClick={onClose}>
            Cancel
          </AntdButton>
          <Button
            rounded
            type={"primary"}
            style={{ height: 50, width: "50%" }}
            onClick={() => submitBtnRef?.current?.click()}
          >
            Save
          </Button>
        </div>
      }
    >
      <Form
        preserve={false}
        layout="vertical"
        form={form}
        requiredMark={false}
        onFinish={(values) => {
          onSuccess({
            subAccounts: null,
            ...completeData,
            // chaipi:

            parentAccountId:
              completeData?.parentAccountId === 0 ||
              completeData?.parentAccountId === undefined
                ? null
                : completeData?.parentAccountId,
            ...values,
          });
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
      >
        <Row gutter={[16, 16]}>
          {!!isEdit && (
            <Col xs={24}>
              <FormInput
                disabled={isEdit}
                name="referenceId"
                variant="underlined"
                label="Reference ID"
                required
                placeholder="Reference ID"
                formItemStyles={{
                  marginBottom: 0,
                }}
              />
            </Col>
          )}
          <Col xs={24}>
            <FormInput
              name="accountName"
              variant="underlined"
              label="Account Name"
              required
              placeholder="Account Name"
              formItemStyles={{
                marginBottom: 0,
              }}
            />
          </Col>
          <Col xs={24}>
            <FormInput
              name="fullyQualifiedName"
              variant="underlined"
              label="Fully Qualified Account Name"
              required
              placeholder="Fully Qualified Account Name"
              formItemStyles={{
                marginBottom: 0,
              }}
            />
          </Col>
          {/* <Col xs={24}>
            <FormInput
              name="accountCode"
              variant="underlined"
              label="Account Code"
              required
              placeholder="Account Code"
              formItemStyles={{
                marginBottom: 0,
              }}
            />
          </Col>
          <Col xs={24}>
            <FormInput
              name="accountLongCode"
              variant="underlined"
              label="Account Long Code"
              required
              placeholder="Account Long Code"
              formItemStyles={{
                marginBottom: 0,
              }}
            />
          </Col> */}
          <Col xs={24}>
            <FormInput
              name="classification"
              variant="underlined"
              label="Classification"
              required
              placeholder="Classification"
              formItemStyles={{
                marginBottom: 0,
              }}
            />
          </Col>
          <Col xs={24}>
            <FormSelect
              required
              feedBack={false}
              variant="underlined"
              name="accountTypeId"
              label="Account Type"
              placeholder={"Select Account Type"}
              renderValue="value"
              renderLabel="label"
              styles={{ border: "none" }}
              formItemStyles={{ marginBottom: 0 }}
              options={[
                {
                  label: "Asset",
                  value: 1,
                },
                {
                  label: "Liability",
                  value: 2,
                },
                {
                  label: "Equity",
                  value: 3,
                },
                {
                  label: "Income",
                  value: 4,
                },
                {
                  label: "Expense",
                  value: 5,
                },
              ]}
              // styles={{ background: '#ddd' }}
            />
          </Col>
          <Col xs={24}>
            <FormSelect
              allowClear={true}
              name="parentAccountId"
              variant="underlined"
              label="Parent Account"
              placeholder="Parent Account"
              renderValue="accountId"
              renderLabel="accountName"
              options={accounts?.filter(
                (it) => it?.accountId !== data?.accountId && it?.isActive
              )}
              formItemStyles={{
                marginBottom: 0,
              }}
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <FormHiddenSubmitButton submitRef={submitBtnRef} />
        </Row>
      </Form>
    </Drawer>
  );
}
