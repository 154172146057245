import React, { useEffect, useMemo, useRef, useState } from "react";
import { Row, Col, Form } from "antd";
import Modal from "components/Modal/Modal";
import moment from "moment";

import {
  FormHiddenSubmitButton,
  FormDatePicker,
  FormSelect,
} from "components/FormItems/FlatFormItems";
import "./ChangeAppointmentModal.scss";
import { generateHourlyTimeslotsAfterNHours } from "utils/common";

export default function ChangeAppointmentModal({
  booking,
  visible,
  onClose,
  onSave,
}) {
  const [form] = Form.useForm();
  const submitBtnRef = useRef();

  const [scheduledDate, setScheduledDate] = useState();
  const timeSlots = useMemo(() => {
    if (scheduledDate) {
      return generateHourlyTimeslotsAfterNHours(
        0,
        scheduledDate,
        booking?.operationTypeId === 1
      );
    }
    return [];
  }, [scheduledDate]);

  useEffect(() => {
    if (visible && !!booking?.serviceDate) {
      const facilityServiceDate = moment(booking?.serviceDate);
      setScheduledDate(facilityServiceDate);
      form.setFieldsValue({
        date: facilityServiceDate,
        time: facilityServiceDate.format("LT"),
      });
    } else {
      form.resetFields();
    }
  }, [visible]);

  return (
    <Modal
      headerBorder={false}
      width="460px"
      title={"Edit Appointment"}
      onCancel={onClose}
      visible={visible}
      onOk={() => submitBtnRef?.current?.click()}
      okText="Save"
      destroyOnClose
    >
      <Form
        requiredMark={false}
        form={form}
        layout="vertical"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          borderBottom: "2px solid #EFF1F4",
          paddingBottom: 12,
        }}
        preserve={false}
        onFinish={async (values) => {
          onSave(booking?.orderId, values);
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
      >
        <Row gutter={[16, 16]} style={{ width: "100%" }}>
          <Col xs={24} md={24}>
            <FormDatePicker
              form={form}
              name="date"
              label="Select Date"
              variant="underlined"
              formItemStyles={{ marginBottom: 0 }}
              disabledDate={(current) =>
                current.isBefore(moment().subtract(1, "day"))
              }
              onChange={(value, date) => {
                setScheduledDate(value);
              }}
              required
            />
          </Col>
          <Col xs={24} md={24}>
            <FormSelect
              variant="underlined"
              name="time"
              label="Select Time"
              placeholder="Select Time"
              required={true}
              renderLabel="label"
              renderValue="value"
              formItemStyles={{ marginBottom: 0 }}
              options={timeSlots}
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            />
          </Col>
        </Row>

        <FormHiddenSubmitButton submitRef={submitBtnRef} />
      </Form>
    </Modal>
  );
}
