import { Form, Spin } from "antd";
import { useDispatch } from "react-redux";
import { replace } from "connected-react-router";
import {
  FormInput,
  FormCheckbox,
  FormButton,
  FormPassword,
} from "../../components/FormItems/FlatFormItems";
import { loginUser, staffInviteReset } from "../../services/auth.services";
import { useSelector } from "react-redux";
import { Button } from "components/Buttons/Buttons";

import { useLocation } from "react-router";
import queryString from "query-string";
import AuthLayout from "components/AuthLayout/AuthLayout";
import PasswordWithDropdown from "components/PasswordWithDropdown/PasswordWithDropdown";

const StaffInvite = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { loading, message } = useSelector((state) => state.app);

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  const location = useLocation();
  const qs = queryString.parse(location.search);

  return (
    <AuthLayout loading={loading} message={message}>
      <Form
        requiredMark={false}
        className="loginForm"
        layout="vertical"
        form={form}
        initialValues={{
          userName: qs.userName,
        }}
        preserve={false}
        onFinishFailed={onFinishFailed}
        onFinish={(values) => {
          form
            .validateFields()
            .then(async (values) => {
              const requestData = {
                newPassword: values.password,
                resetToken: window.location.search.split("token=")[1],
                userName: values.userName,
              };
              await dispatch(staffInviteReset(requestData));
            })
            .catch((err) => {
              form.scrollToField(err?.errorFields[0]);
            });
        }}
      >
        <FormInput
          label="Email"
          name="userName"
          required
          placeholder="Username"
          initialValue={""}
          styles={null}
          type={"text"}
          readOnly={true}
          formItemStyles={{ marginBottom: 12 }}
        />
        <PasswordWithDropdown
          form={form}
          required={true}
          name="password"
          label="New Password"
          placeholder=""
          formItemStyles={{ marginBottom: 12 }}
        />
        <FormPassword
          name="confirmPassword"
          label="Confirm Password"
          placeholder=""
          formItemStyles={{ marginBottom: 20 }}
          rules={[
            {
              required: true,
              message: "Required",
            },
            {
              message: "Password and Confirm Password don't match.",
              validator: (_, value) => {
                const password = form.getFieldValue("password");
                const confirmPassword = form.getFieldValue("confirmPassword");
                if (password !== confirmPassword) {
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            },
          ]}
        />
        <Button
          style={{ height: 50, width: "100%" }}
          onClick={() => {}}
          type="primary"
          htmlType="submit"
        >
          {"Save"}
        </Button>
      </Form>
    </AuthLayout>
  );
};

export default StaffInvite;
