import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Col,
  Drawer,
  Form,
  Row,
  Typography,
  Button as AntdButton,
  Upload,
  Space,
  Divider,
  Pagination,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import {
  FormInput,
  FormInputArea,
  FormHiddenSubmitButton,
  FormPhoneNumber,
  FormSelect,
  FormCheckbox,
  FormInputNumber,
} from "components/FormItems/FlatFormItems";
import { useForm } from "antd/lib/form/Form";
import { Button } from "components/Buttons/Buttons";
import FilesUploader from "components/FilesUploader/FilesUploader";
import { useDispatch } from "react-redux";
import {
  createPricingSchedule,
  getAllPricingScheduleById,
} from "services/pricingSchedule.service";
import { groupBy } from "lodash";
import { object } from "prop-types";

const { Title, Text } = Typography;
const { Dragger } = Upload;

const PAGINATE = true;
const PRODUCTS_PER_PAGE = 5;

const BoostCheckbox = ({
  index,
  boost,
  parentIndex,
  parentProduct,
  form,
  hidden,
}) => {
  const isEnabled = Form.useWatch(
    ["prices", parentIndex, "addOns", index, "isEnabled"],
    form
  );

  return (
    <Row
      hidden={hidden}
      wrap={false}
      gutter={[24, 24]}
      key={boost?.productId}
      style={{ marginBottom: 16 }}
    >
      <Col xs={10} style={{ overflow: "hidden" }}>
        <div
          style={{
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            paddingLeft: 30,
          }}
        >
          <FormInput
            type="text"
            hidden={true}
            name={["prices", parentIndex, "addOns", index, "productId"]}
            initialValue={boost?.productId}
          />
          <FormInput
            type="text"
            hidden={true}
            name={[
              "prices",
              parentIndex,
              "addOns",
              index,
              "paymentGatewayProductId",
            ]}
            initialValue={boost?.paymentGatewayProductId}
          />
          <FormInput
            type="text"
            hidden={true}
            name={["prices", parentIndex, "addOns", index, "parentProductId"]}
            initialValue={parentProduct?.productId}
          />
          <FormCheckbox
            // name={`products[${index}].productId`}
            name={["prices", parentIndex, "addOns", index, "isEnabled"]}
            label={`Service`}
            formItemStyles={{ marginBottom: 0, marginRight: 8 }}
          />
          <label
            for={`prices_${parentIndex}_addOns_${index}_isEnabled`}
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              cursor: "pointer",
            }}
          >
            <Text>{boost?.productName} </Text>
          </label>
        </div>
      </Col>
      <Col xs={14}>
        <Row gutter={[16, 16]}>
          <Col xs={8}>
            <FormInputNumber
              min={0}
              disabled={!isEnabled || parentProduct?.productType !== 3}
              name={[
                "prices",
                parentIndex,
                "addOns",
                index,
                "pricings",
                0,
                "sellingPrice",
              ]}
              variant="underlined"
              formItemStyles={{ marginBottom: 0 }}
            />
          </Col>
          <Col xs={8}>
            <FormInputNumber
              min={0}
              disabled={!isEnabled || parentProduct?.productType === 3}
              name={[
                "prices",
                parentIndex,
                "addOns",
                index,
                "pricings",
                1,
                "sellingPrice",
              ]}
              variant="underlined"
              formItemStyles={{ marginBottom: 0 }}
            />
          </Col>
          <Col xs={8}>
            <FormInputNumber
              min={0}
              disabled={!isEnabled || parentProduct?.productType === 3}
              name={[
                "prices",
                parentIndex,
                "addOns",
                index,
                "pricings",
                2,
                "sellingPrice",
              ]}
              variant="underlined"
              formItemStyles={{ marginBottom: 0 }}
            />
          </Col>
          {/* <Col xs={6}>
            <FormSwitch
              name={[
                "prices",
                parentIndex,
                "addOns",
                index,
                "pricings",
                2,
                "isTaxable",
              ]}
            />
          </Col> */}
        </Row>
      </Col>
    </Row>
  );
};

const ProductServicesCheckbox = ({
  index,
  form,
  product,
  boosts = [],
  hidden,
}) => {
  const isEnabled = Form.useWatch(["prices", index, "isEnabled"], form);
  // const productBoosts = boosts.filter(it=>{
  //   it.
  // })

  return (
    <>
      <Row
        hidden={hidden}
        wrap={false}
        gutter={[24, 24]}
        key={product?.productId}
        style={{ marginBottom: 16 }}
      >
        <Col xs={10} style={{ overflow: "hidden" }}>
          <div
            style={{
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormInput
              type="text"
              hidden={true}
              name={["prices", index, "productId"]}
              initialValue={product?.productId}
            />
            <FormInput
              type="text"
              hidden={true}
              name={["prices", index, "paymentGatewayProductId"]}
              initialValue={product?.paymentGatewayProductId}
            />
            <FormCheckbox
              // name={`products[${index}].productId`}
              name={["prices", index, "isEnabled"]}
              label={`Service`}
              formItemStyles={{ marginBottom: 0, marginRight: 8 }}
            />
            <label
              for={`prices_${index}_isEnabled`}
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
            >
              <Text>{product?.productName}</Text>
            </label>
          </div>
        </Col>
        <Col xs={14}>
          <Row gutter={[16, 16]}>
            <Col xs={8}>
              <FormInputNumber
                min={0}
                disabled={!isEnabled || product?.productType !== 3}
                name={["prices", index, "pricings", 0, "sellingPrice"]}
                variant="underlined"
                formItemStyles={{ marginBottom: 0 }}
              />
            </Col>
            <Col xs={8}>
              <FormInputNumber
                min={0}
                disabled={!isEnabled || product?.productType === 3}
                name={["prices", index, "pricings", 1, "sellingPrice"]}
                variant="underlined"
                formItemStyles={{ marginBottom: 0 }}
              />
            </Col>
            <Col xs={8}>
              <FormInputNumber
                min={0}
                disabled={!isEnabled || product?.productType === 3}
                name={["prices", index, "pricings", 2, "sellingPrice"]}
                variant="underlined"
                formItemStyles={{ marginBottom: 0 }}
              />
            </Col>
            {/* <Col xs={6}>
              <FormSwitch
                name={["prices", index, "pricings", 2, "isTaxable"]}
              />
            </Col> */}
          </Row>
        </Col>
      </Row>
      {isEnabled && (
        <div>
          {product.addOnIds
            ?.filter((addonId) => boosts?.find((it) => it.productId == addonId))
            ?.map?.((addonId, index2) => {
              const boost = boosts?.find((it) => it.productId == addonId);

              return (
                <BoostCheckbox
                  key={boost?.productId}
                  boost={boost}
                  form={form}
                  index={index2}
                  parentProduct={product}
                  parentIndex={index}
                  hidden={hidden}
                />
              );
            })}
        </div>
      )}
    </>
  );
};

const ProductServicesCheckboxWrapper = ({
  index,
  form,
  product,
  boosts = [],
  hidden,
}) => {
  if (hidden) return <></>;
  return (
    <ProductServicesCheckbox
      index={index}
      form={form}
      product={product}
      boosts={boosts}
      hidden={hidden}
    />
  );
};

export default function PricingDrawer({
  data,
  visible,
  products,
  boosts,
  onClose,
  onSuccess,
}) {
  const [form] = useForm();
  const submitBtnRef = useRef();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PRODUCTS_PER_PAGE);

  const submittableDataRef = useRef([]);

  const dispatch = useDispatch();

  const getData = async (id) => {
    const result = await dispatch(getAllPricingScheduleById(id));

    const generateAddonsData = (addOns = [], parentProductId) => {
      const addOnsData = [];
      const pricesGroupedByProductId = Object.entries(
        groupBy(addOns, "productId")
      );
      const product = products.find((p) => p.productId == parentProductId);

      for (let i = 0; i < product?.addOnIds?.length; i++) {
        const addonId = product.addOnIds[i];
        const addon = boosts.find((b) => b.productId == addonId);

        if (!addon) continue;
        const formItem = {
          parentProductId: parentProductId,
          productId: addon?.productId,
          paymentGatewayProductId: addon?.paymentGatewayProductId,
          isEnabled: false,
          pricings: [
            {
              operationTypeId: 3,
              sellingPrice: undefined,
              paymentGatewayProductId: undefined,
            },
            {
              operationTypeId: 2,
              sellingPrice: undefined,
              paymentGatewayProductId: undefined,
            },
            {
              operationTypeId: 1,
              sellingPrice: undefined,
              paymentGatewayProductId: undefined,
            },
          ],
        };
        const idx = pricesGroupedByProductId.findIndex((it) => {
          if (it?.[0] == addonId) {
            return true;
          }
        });

        if (idx >= 0) {
          const [productId, pricingsByCategory] =
            pricesGroupedByProductId?.[idx];
          for (let j = 0; j < pricingsByCategory?.length; j++) {
            const item = pricingsByCategory?.[j];
            formItem.isEnabled = true;
            if (item?.operationTypeId == 3) {
              formItem.pricings[0] = {
                // operationTypeId: 2,
                sellingPrice: item?.sellingPrice,
                paymentGatewayProductId: item?.paymentGatewayProductId,
              };
            } else if (item?.operationTypeId == 2) {
              formItem.pricings[1] = {
                // operationTypeId: 2,
                sellingPrice: item?.sellingPrice,
                paymentGatewayProductId: item?.paymentGatewayProductId,
              };
            } else if (item?.operationTypeId == 1) {
              formItem.pricings[2] = {
                // operationTypeId: 2,
                sellingPrice: item?.sellingPrice,
                paymentGatewayProductId: item?.paymentGatewayProductId,
              };
            }
          }
        }
        addOnsData.push(formItem);
      }

      return addOnsData;
    };
    if (result?.status === "success") {
      const { prices = [], priceScheduleName } = result?.data ?? {};
      const pricesGroupedByProductId = Object.entries(
        groupBy(prices, "productId")
      );
      const formData = [];
      for (let i = 0; i < products.length; i++) {
        const { productId, paymentGatewayProductId } = products[i];

        const formItem = {
          productId: productId,
          paymentGatewayProductId: paymentGatewayProductId,
          isEnabled: false,
          pricings: [
            {
              operationTypeId: 3,
              sellingPrice: undefined,
              // paymentGatewayProductId: undefined,
            },
            {
              operationTypeId: 2,
              sellingPrice: undefined,
              // paymentGatewayProductId: undefined,
            },
            {
              operationTypeId: 1,
              sellingPrice: undefined,
              // paymentGatewayProductId: undefined,
            },
          ],
        };
        const idx = pricesGroupedByProductId.findIndex((it) => {
          if (it?.[0] == productId) {
            return true;
          }
        });
        if (idx >= 0) {
          const [productId, pricingsByCategory] =
            pricesGroupedByProductId?.[idx];

          for (let j = 0; j < pricingsByCategory?.length; j++) {
            const item = pricingsByCategory?.[j];
            formItem.isEnabled = true;
            formItem.addOns = generateAddonsData(item.addOns, item.productId);
            if (item?.operationTypeId === 3) {
              formItem.pricings[0] = {
                // operationTypeId: 2,
                sellingPrice: item?.sellingPrice,
                // paymentGatewayProductId: item?.paymentGatewayProductId,
              };
            } else if (item?.operationTypeId === 2) {
              formItem.pricings[1] = {
                // operationTypeId: 2,
                sellingPrice: item?.sellingPrice,
                // paymentGatewayProductId: item?.paymentGatewayProductId,
              };
            } else if (item?.operationTypeId === 1) {
              formItem.pricings[2] = {
                // operationTypeId: 2,
                sellingPrice: item?.sellingPrice,
                // paymentGatewayProductId: item?.paymentGatewayProductId,
              };
            }
          }
        }
        formData.push(formItem);
      }

      submittableDataRef.current = formData;
      form.setFieldsValue({ priceScheduleName, prices: formData });
      // convert data
      // form.setFieldsValue({
      //   ...result.data,
      // });
    }
  };

  // use effects start
  useEffect(() => {
    if (visible === false) {
      form.resetFields();
    } else if (data?.priceScheduleId !== undefined) {
      getData(data?.priceScheduleId);
    }
  }, [visible]);

  const formFinish = (priceScheduleName, prices = []) => {
    const entries = [];
    const generateAddonsData = (addOns = []) => {
      const apiAddons = [];
      for (let i = 0; i < addOns.length; i++) {
        const item = addOns[i];
        if (item?.isEnabled === true) {
          if (
            item?.pricings?.[0]?.sellingPrice !== undefined &&
            item?.pricings?.[0]?.sellingPrice !== null
          ) {
            apiAddons.push({
              productId: item.productId,
              paymentGatewayProductId: item?.paymentGatewayProductId,
              parentProductId: item.parentProductId,
              sellingPrice: item?.pricings?.[0]?.sellingPrice,
              operationTypeId: 3,
            });
          }
          if (
            item?.pricings?.[1]?.sellingPrice !== undefined &&
            item?.pricings?.[1]?.sellingPrice !== null
          ) {
            apiAddons.push({
              productId: item.productId,
              paymentGatewayProductId: item?.paymentGatewayProductId,
              parentProductId: item.parentProductId,
              sellingPrice: item?.pricings?.[1]?.sellingPrice,
              operationTypeId: 2,
            });
          }
          if (
            item?.pricings?.[2]?.sellingPrice !== undefined &&
            item?.pricings?.[2]?.sellingPrice !== null
          ) {
            apiAddons.push({
              productId: item.productId,
              paymentGatewayProductId: item?.paymentGatewayProductId,
              parentProductId: item.parentProductId,
              sellingPrice: item?.pricings?.[2]?.sellingPrice,
              operationTypeId: 1,
            });
          }
        }
      }

      return apiAddons;
    };

    for (let i = 0; i < prices?.length; i++) {
      const item = prices[i];
      if (item?.isEnabled === true) {
        if (
          item?.pricings?.[0]?.sellingPrice !== undefined &&
          item?.pricings?.[0]?.sellingPrice !== null
        ) {
          entries.push({
            productId: item.productId,
            paymentGatewayProductId: item?.paymentGatewayProductId,
            // "productName": "string",
            sellingPrice: item?.pricings?.[0]?.sellingPrice,
            operationTypeId: 3,
            addOns: generateAddonsData(item.addOns),
            // parentProductId: 0,????
          });
        }
        if (
          item?.pricings?.[1]?.sellingPrice !== undefined &&
          item?.pricings?.[1]?.sellingPrice !== null
        ) {
          entries.push({
            productId: item.productId,
            paymentGatewayProductId: item?.paymentGatewayProductId,
            // "productName": "string",
            sellingPrice: item?.pricings?.[1]?.sellingPrice,
            operationTypeId: 2,
            addOns: generateAddonsData(item.addOns),
            // parentProductId: 0,????
          });
        }
        if (
          item?.pricings?.[2]?.sellingPrice !== undefined &&
          item?.pricings?.[2]?.sellingPrice !== null
        ) {
          entries.push({
            productId: item.productId,
            paymentGatewayProductId: item?.paymentGatewayProductId,
            // "productName": "string",
            sellingPrice: item?.pricings?.[2]?.sellingPrice,
            operationTypeId: 1,
            addOns: generateAddonsData(item.addOns),
            // parentProductId: 0,????
          });
        }
      }
    }

    onSuccess({
      priceScheduleName,
      prices: entries,
    });
  };

  function onShowSizeChange(current, pageSize) {
    console.log("current, pageSize", current, pageSize);
  }

  let start = (page - 1) * pageSize;
  let end = Math.min(products.length, page * pageSize);
  return (
    <Drawer
      destroyOnClose
      headerStyle={{ border: "none" }}
      className="pricingDrawer"
      width={"min(100vw, 720px)"}
      title={
        <div className="pricingDrawerTitle">
          <p>
            {data?.priceScheduleId ? "Edit" : "Create New "} Pricing Schedule
          </p>
          <CloseOutlined onClick={onClose} />
        </div>
      }
      placement="right"
      onClose={onClose}
      visible={visible}
      closable={false}
      footer={
        <div className="pricingDrawerFooter">
          <AntdButton type="text" onClick={onClose}>
            Cancel
          </AntdButton>
          <Button
            rounded
            type={"primary"}
            style={{ height: 50, width: "50%", maxWidth: 250 }}
            onClick={() => submitBtnRef?.current?.click()}
          >
            Save
          </Button>
        </div>
      }
    >
      {visible && (
        <Form
          layout="vertical"
          form={form}
          preserve={true}
          requiredMark={false}
          onChange={(e) => {
            // update current prices with new prices

            let name = e.target.id;
            let value = e.target.value;
            if (value !== undefined && value !== null) {
              let prices = form.getFieldValue("prices");
              for (let i = start; i < end; i++) {
                submittableDataRef.current[i] = prices[i];
              }
            }
          }}
          onFinish={(values) => {
            formFinish(
              values.priceScheduleName,
              submittableDataRef.current ?? []
            );
          }}
          onFinishFailed={({ values, errorFields, outOfDate }) => {
            form.scrollToField(errorFields[0].name, {
              scrollMode: "if-needed",
              block: "center",
              behavior: "smooth",
            });
          }}
        >
          <Row gutter={[24, 4]}>
            <Col xs={24}>
              <FormInput
                required
                name="priceScheduleName"
                variant="underlined"
                label="Pricing Schedule Name"
                formItemStyles={{
                  marginBottom: 12,
                }}
              />
            </Col>
            <Col xs={24}>
              <Title level={5} style={{ marginBottom: 0 }}>
                Services Available
              </Title>
            </Col>
            <Col offset={10} xs={14}>
              <Row gutter={[16, 16]} id="pricingScheduleOperationTypes">
                <Col xs={8}>Telehealth</Col>
                <Col xs={8}>Clinic</Col>
                <Col xs={8}>Mobile</Col>
                {/* <Col xs={8}>Skills</Col> */}
                {/* <Col xs={6}>Taxable</Col> */}
              </Row>
            </Col>
            <Col xs={24}>
              <Divider style={{ marginTop: 10, marginBottom: 16 }} />
            </Col>
          </Row>
          {products
            // .filter((it, index) => index >= start && index < end)
            .map((it, index) => (
              <ProductServicesCheckboxWrapper
                key={it?.productId}
                index={index}
                form={form}
                product={it}
                boosts={boosts}
                hidden={index < start || index >= end}
              />
            ))}{" "}
          <Pagination
            current={page}
            onChange={(page) => {
              form.scrollToField("priceScheduleName", {
                scrollMode: "if-needed",
                block: "center",
                behavior: "smooth",
              });
              setPage(page);
            }}
            showSizeChanger
            pageSize={pageSize}
            total={products.length}
            defaultPageSize={PRODUCTS_PER_PAGE}
            pageSizeOptions={[1, 5, 10, 30, 50, 100]}
            onShowSizeChange={(current, pageSize) => {
              setPageSize(pageSize);
            }}
          />
          <Row gutter={[16, 16]}>
            <FormHiddenSubmitButton submitRef={submitBtnRef} />
          </Row>
        </Form>
      )}
    </Drawer>
  );
}
