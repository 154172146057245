import React from "react";
import PropTypes from "prop-types";
import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";

function CustomAgendaSortHeader({ className = "", style = {}, ref, children }) {
  return (
    <th className={`${className}  `} style={style} ref={ref}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span>{children}</span>
        <span
          style={{
            display: "inline-flex",
            flexDirection: "column",
          }}
        >
          <CaretUpFilled
            style={{ lineHeight: 1, fontSize: 11, color: "#fafaf !important", background:'red' }}
          />
          <CaretDownFilled
            style={{
              lineHeight: 1,
              fontSize: 11,
              marginTop: -5,
              color: "#fafaf",
            }}
          />
        </span>
      </div>
    </th>
  );
}

CustomAgendaSortHeader.propTypes = {};

export default CustomAgendaSortHeader;
