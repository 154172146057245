import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, useRouter } from "react-router";
import { useDispatch } from "react-redux";
import { Row, Col, Card, Typography } from "antd";
import { push, replace } from "connected-react-router";
import SettingCard from "components/SettingCard/SettingCard.index";

import settingRoutes from "utils/settingRoutes";

import "./Settings.style.scss";
import SettingsMain from "./Screens/SettingsMain/SettingsMain.index";
import SettingsLayout from "components/SettingsLayout/SettingsLayout.index";

const { Title, Text } = Typography;

function Settings() {
  return (
    <Switch>
      {settingRoutes.map((route, index) => {
        const { title, goBackURL, showHeaders = true } = route;
        return (
          <Route
            key={index}
            path={route.path}
            render={(props) => (
              <SettingsLayout
                title={title}
                goBackURL={goBackURL}
                showHeaders={showHeaders}
              >
                <route.component {...props} />
              </SettingsLayout>
            )}
          />
        );
      })}
      <Route
        path="/dashboard/settings"
        name="settingsMain"
        exact
        component={SettingsMain}
      />
    </Switch>
  );
}

Settings.propTypes = {};

export default Settings;
