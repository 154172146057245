import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
  deleteAddress,
  getAllAddresses,
  setPrimaryAddress,
} from "services/addresses.service";
import "./AddressesInformation.scss";

import { Button, Empty, Modal, Space, Spin, Typography } from "antd";
import PrimaryCheckMark from "components/PrimaryCheckMark/PrimaryCheckMark";
import { EnvironmentOutlined } from "@ant-design/icons";
import AddAddressModal from "./components/AddAddressModal";
import AddInstructionsModal from "./components/AddInstructionsModal";

import { Button as RoundedButton } from "components/Buttons/Buttons";

const { Title, Text } = Typography;
const { confirm, warning, success, error } = Modal;

const Address = ({
  address,
  addressId,
  instructions,
  isPrimary,
  customerId,
  handleSave,
}) => {
  const [showAddressUpdate, setShowAddressUpdate] = useState(false);
  const [showInstructionsUpdate, setShowInstructionsUpdate] = useState(false);
  const dispatch = useDispatch();

  const deleteAddressModal = {
    title: "Delete Address",
    content: <>Are you sure that you want to delete the address?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      dispatch(deleteAddress(customerId, addressId)).then((result) => {
        if (result?.status === "success") {
          handleSave();
          close();
        }
      });
    },
  };

  return (
    <div className="addressCard">
      <div className="addressCardFirstRow">
        <Space>
          <Text style={{ fontSize: 14 }}>{address}</Text>
          {isPrimary && <PrimaryCheckMark />}
        </Space>
        <Space style={{ marginLeft: 8, alignItems: "flex-start" }}>
          {!isPrimary && (
            <>
              <Button
                type="text"
                onClick={() => {
                  dispatch(
                    setPrimaryAddress(customerId, addressId, "Primary")
                  ).then((result) => {
                    if (result?.status === "success") handleSave();
                  });
                }}
              >
                Set as primary
              </Button>
              <Button
                type="text"
                danger
                onClick={() => {
                  confirm(deleteAddressModal);
                }}
              >
                Delete
              </Button>
            </>
          )}

          <Button type="text" onClick={() => setShowAddressUpdate(true)}>
            Edit
          </Button>
        </Space>
      </div>
      <div className="addressSecondRow">
        {instructions ? (
          <Text className="addressInstructions">
            <span style={{ fontWeight: "bold" }}>Instructions: &nbsp;</span>
            {instructions}
          </Text>
        ) : (
          <span></span>
        )}
        <Button type="text" onClick={() => setShowInstructionsUpdate(true)}>
          {instructions ? "Edit Instructions" : "Add Address Instructions"}
        </Button>
      </div>
      {
        <AddInstructionsModal
          visible={showInstructionsUpdate}
          handleSave={() => {
            setShowInstructionsUpdate(false);
            handleSave();
          }}
          onCancel={() => setShowInstructionsUpdate(false)}
          data={{
            customerId,
            addressId,
            instructions,
          }}
        />
      }
      <AddAddressModal
        type="edit"
        title="Edit Address"
        visible={showAddressUpdate}
        handleSave={() => {
          setShowAddressUpdate(false);
          handleSave();
        }}
        onCancel={() => setShowAddressUpdate(false)}
        data={{
          addressId,
          address,
          instructions,
          isPrimary,
          customerId,
        }}
      />
    </div>
  );
};

function AddressesInformation({ customerId }) {
  const [addresses, setAddresses] = useState([]);
  const dispatch = useDispatch();
  const [showAddressAdd, setShowAddressAdd] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  const getData = () => {
    if (customerId) {
      return dispatch(getAllAddresses(customerId)).then((response) => {
        setIsFetching(false);
        if (response?.status === "success") {
          setAddresses(response?.data);
        }
      });
    }
  };

  useEffect(() => {
    getData()
  }, [customerId]);

  const handleAdd = (data) => {};
  const handleUpdate = (data) => {
    getData();
  };

  return (
    <>
      <div
        className="addressesContainer"
        style={{ display: "flex", marginBottom: 16 }}
      >
        <EnvironmentOutlined
          style={{ fontSize: 17, marginRight: 8, paddingTop: 6 }}
        />
        <Space direction="vertical" size="small" style={{ width: "100%" }}>
          {isFetching && (
            <div class="addressesLoading">
              <Spin />
            </div>
          )}
          {addresses.map((address, index) => {
            return (
              <Address
                key={address?.address}
                {...address}
                customerId={customerId}
                handleSave={handleUpdate}
              />
            );
          })}
        </Space>
      </div>
      <RoundedButton
        style={{ marginLeft: 24 }}
        rounded
        onClick={() => setShowAddressAdd(true)}
      >
        Add New Address
      </RoundedButton>
      {showAddressAdd && (
        <AddAddressModal
          data={{ customerId }}
          visible={showAddressAdd}
          onCancel={() => setShowAddressAdd(false)}
          handleSave={() => {
            getData();
            setShowAddressAdd(false);
          }}
          title={"Add New Address"}
          type="add"
        />
      )}
    </>
  );
}

AddressesInformation.propTypes = {};

export default AddressesInformation;
