import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { Button, Modal, Space, Typography } from "antd";
import {
  getSingleGoodFaithPolicy,
  toogleGoodFaithPolicy,
} from "services/misc.service";

const { Text } = Typography;

function GoodFaithPolicyCard({ data, onEdit, onToggle }) {
  const dispatch = useDispatch();
  const { allFacilities } = useSelector((state) => ({
    allFacilities: state.facilities.allFacilities,
  }));

  const facilityName = useMemo(() => {
    let facility = allFacilities.find(
      (facility) => facility.facilityId === data.facilityId
    );
    return facility?.facilityName;
  }, [allFacilities, data.facilityId]);

  return (
    <div className="cancellationPolicyCard">
      <Text>{facilityName}</Text>
      <Space>
        <Button
          type="text"
          danger={data.isActive ? true : false}
          onClick={() => {
            Modal.confirm({
              title: `${
                data.isActive ? "Inactivate" : "Activate"
              } Good Faith Policy`,
              content: `Are you sure you want to ${
                data.isActive ? "inactivate" : "activate"
              } this Good Faith Policy?`,
              onOk() {
                dispatch(
                  toogleGoodFaithPolicy(data.goodFaithPolicyId, data.isActive)
                ).then(() => {
                  onToggle(data.goodFaithPolicyId);
                });
              },
              onCancel() {
                console.log("Cancel");
              },
              closable: true,
              maskClosable: true,
            });
          }}
        >
          {data.isActive ? "Inactivate" : "Activate"}
        </Button>
        <Button
          type="text"
          style={{
            padding: 0,
            height: "auto",
          }}
          onClick={() => {
            onEdit(data);
          }}
        >
          Edit
        </Button>
      </Space>
    </div>
  );
}

GoodFaithPolicyCard.propTypes = {};

export default GoodFaithPolicyCard;
