import { actions } from "../actions/skills.action";

const INITIAL_STATE = {
  allSkills: [],
  isSkillsLoading: false,
};

const reducer = (state = INITIAL_STATE, data) => {
  switch (data.type) {
    case actions.SET_ALL_SKILLS:
      return { ...state, allSkills: data.data };
    case actions.SET_IS_LOADING_SKILL:
      return { ...state, isSkillsLoading: data.status };
    case "RESET":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
