import React from "react";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload, Typography } from "antd";
const { Dragger } = Upload;
const { Text, Title } = Typography;

const props = {
  name: "file",
  multiple: true,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",

  onChange(info) {
    const { status } = info.file;

    if (status !== "uploading") {
      // console.log(info.file, info.fileList);
    }

    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },

  onDrop(e) {
    // console.log("Dropped files", e.dataTransfer.files);
  },
};

function FilesUploader(props) {
  const { name, multiple, onChange, onDrop } = props;
  return (
    <Dragger
      {...props}
      name={name}
      multiple={multiple}
      onChange={onChange}
      onDrop={onDrop}
    >
      <div>
        <InboxOutlined
          style={{
            fontSize: 36,
            color: "#4A6AAF",
          }}
        />
      </div>
      <Text style={{ fontSize: 14, paddingBottom: 8 }}>
        Drag and drop or <b style={{ color: "#4A6AAF" }}>Browse</b> your files
      </Text>
    </Dragger>
  );
}

FilesUploader.propTypes = {};

export default FilesUploader;
