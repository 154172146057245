export const actions = {
  SET_ALL_SKILLS: "SET_ALL_SKILLS",
  SET_IS_LOADING_SKILL: "SET_IS_LOADING_SKILL",
};

export const setAllSkills = (data) => ({
  type: actions.SET_ALL_SKILLS,
  data,
});

export const setIsLoadingSkill = (status) => ({
  type: actions.SET_IS_LOADING_SKILL,
  status: status,
});
