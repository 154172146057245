import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "components/Modal/Modal";
import moment from "moment";
import { List, Skeleton, Space } from "antd";
import AuditListItem from "./AuditListItem";
import { useDispatch } from "react-redux";
import { getFormsAuditHistory } from "services/forms.service";
import IntakeFormModal from "./IntakeFormModal";
import { FormType } from "utils/enums";
import HistoryIntakeForm from "components/HistoryIntakeForm/HistoryIntakeForm";
import ConsentForm from "components/ConsentForm/ConsentForm";

const INITIAL_STATE = {
  data: [],
  isLoading: true,
};
const INITIAL_MODAL_STATE = {
  open: false,
  formType: undefined,
  isLegacy: false,
  formValueId: undefined,
  isSubmitted: true,
};
function AuditList({
  user,
  type,
  open,
  onCancel,
  title = "History",
  itemTitle,
  afterClose,
}) {
  const [data, setData] = useState(INITIAL_STATE);
  const [showModal, setShowModal] = useState(INITIAL_MODAL_STATE);
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      loadData();
    }
  }, [open]);

  const appendLegacyForm = (data) => {
    const {
      legacyConsentFormId,
      legacyConsentSubmittedOn,
      legacyIntakeFormId,
      legacyPhysicalHistorySubmittedOn,
    } = user;
    if (type === FormType?.consent) {
      if (legacyConsentFormId !== null && legacyConsentFormId !== undefined) {
        const expiryDate = moment(legacyConsentSubmittedOn)?.add(365, "days");
        const isExpired = moment() >= expiryDate;
        return [
          ...data,
          {
            isLegacy: true,
            legacyConsentFormId,
            createdOn: legacyConsentSubmittedOn,
            isExpired,
          },
        ];
      }
    } else if (type === FormType?.historyOrPhysical) {
      if (legacyIntakeFormId !== null && legacyIntakeFormId !== undefined) {
        const expiryDate = moment(legacyPhysicalHistorySubmittedOn)?.add(
          365,
          "days"
        );
        const isExpired = moment() >= expiryDate;
        return [
          ...data,
          {
            isLegacy: true,
            legacyIntakeFormId,
            createdOn: legacyPhysicalHistorySubmittedOn,
            isExpired,
          },
        ];
      }
    }
    return data;
  };

  const loadData = () => {
    setData(INITIAL_STATE);
    dispatch(getFormsAuditHistory(user?.customerId, type))
      .then((res) => {
        if (res?.status === "success") {
          const data = appendLegacyForm(res?.data);
          setData({
            data: data?.filter((it, index) => index !== 0),
            isLoading: false,
          });
        }
      })
      .finally(() => {
        setData((prev) => ({
          ...prev,
          isLoading: false,
        }));
      });
  };

  const renderLoading = () => {
    return (
      <>
        <Skeleton.Button block size="small" active />
        <br />
        <br />
        <Skeleton.Button block size="small" active />
        <br />
        <br />
        <Skeleton.Button block size="small" active />
        <br />
        <br />
        <Skeleton.Button block size="small" active />
      </>
    );
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      destroyOnClose={true}
      headerBorder={true}
      width={420}
      className="auditsModal"
      centered={true}
      title={title}
      footer={<></>}
    >
      {data?.isLoading ? (
        renderLoading()
      ) : (
        <List
          // loading={data?.isLoading}
          dataSource={data?.data ?? []}
          renderItem={(it, index) => {
            return (
              <List.Item key={it?.id}>
                <AuditListItem
                  data={it}
                  title={itemTitle}
                  onClick={() => {
                    setShowModal({
                      open: true,
                      formType: type,
                      isLegacy: it?.isLegacy,
                      formValueId: it?.formValueId,
                      isSubmitted: true,
                    });
                  }}
                />
              </List.Item>
            );
          }}
        />
      )}
      <IntakeFormModal
        visible={showModal?.open && !showModal?.isLegacy}
        title={
          showModal?.formType === FormType?.historyOrPhysical
            ? "History/Physical and Consents"
            : "Consents"
        }
        onSubmit={() => {}}
        onClose={() => setShowModal(INITIAL_MODAL_STATE)}
        isSubmitted={showModal?.isSubmitted}
        formId={showModal?.formValueId}
        disabled
      />
      <HistoryIntakeForm
        visible={
          showModal?.open &&
          showModal?.formType === FormType.historyOrPhysical &&
          showModal?.isLegacy
        }
        onSubmit={() => {}}
        onClose={() => setShowModal(INITIAL_MODAL_STATE)}
        user={user}
        disabled
      />
      <ConsentForm
        visible={
          showModal?.open &&
          showModal?.formType === FormType.consent &&
          showModal?.isLegacy
        }
        onSubmit={() => {}}
        onClose={() => setShowModal(INITIAL_MODAL_STATE)}
        user={user}
        disabled
      />
    </Modal>
  );
}

AuditList.propTypes = {};

export default AuditList;
