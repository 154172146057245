import React, { useEffect, useMemo, useRef, useState } from "react";
import { Col, Drawer, Form, Row, Space, Typography } from "antd";
import { CameraFilled, CloseOutlined } from "@ant-design/icons";
import {
  FormInput,
  FormInputArea,
  FormButton,
  FormPassword,
  FormSelect,
  FormPhoneNumber,
  FormHiddenSubmitButton,
  FormCheckbox,
} from "../../../components/FormItems/FlatFormItems";
import { useForm } from "antd/lib/form/Form";
import { Button } from "../../../components/Buttons/Buttons";
import { useDispatch, useSelector } from "react-redux";
import PhotoUpload from "components/PhotoUpload/PhotoUpload";
import userDp from "../../../assets/images/user-dp.png";
import { openNotificationWithIcon } from "utils/Notification";

const { Title } = Typography;

export default function StaffDrawer({
  visible,
  onClose,
  onAddHandler,
  onUpdateHandler,
}) {
  const [form] = useForm();
  const submitBtnRef = useRef();
  const {
    currentStaff,
    roles,
    allFacilities,
    isFetchingFacilities,
    allSkills,
  } = useSelector((state) => ({
    currentStaff: state.user.currentStaff,
    roles: state.roles.allRoles,
    allFacilities: state.facilities.allFacilities,
    isFetchingFacilities: state.facilities.isFetchingFacilities,
    allSkills: state.skills.allSkills,
  }));
  const [base64, setBase64] = useState("");
  const [file, setFile] = useState(null);

  const disaptch = useDispatch();
  const activeRoles = useMemo(() => {
    return roles.filter((role) => role.isActive);
  }, [roles]);

  useEffect(() => {
    if (currentStaff?.profileImageUrl) {
      setBase64(currentStaff.profileImageUrl);
    }
  }, [currentStaff]);
  useEffect(() => {
    if (visible === false) setBase64("");
  }, [visible]);

  // useEffect(() => {
  //   if (visible) {
  //     disaptch(getStaff);
  //   } else {
  //     form.resetFields();
  //   }
  // }, [visible]);

  return (
    <Drawer
      destroyOnClose
      headerStyle={{ border: "none" }}
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: 60,
            color: "#000",
            borderBottom: "2px solid #EFF1F4",
          }}
        >
          <p
            style={{
              margin: 0,
              fontSize: 24,
              fontFamily: "tradeGothic",
              fontWeight: "bold",
            }}
          >
            {currentStaff ? "Edit Staff" : "Create Staff"}
          </p>
          <CloseOutlined
            onClick={() => {
              onClose();
            }}
          />
        </div>
      }
      placement="right"
      onClose={() => {
        onClose();
      }}
      visible={visible}
      closable={false}
      footer={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: 60,
            color: "#000",
          }}
        >
          <p
            style={{
              margin: 0,
              fontSize: 16,
              fontFamily: "tradeGothic",
              color: "#486BAF",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </p>
          <Button
            rounded
            type={"primary"}
            style={{ height: 50, width: "50%" }}
            onClick={() => submitBtnRef?.current?.click()}
          >
            Save
          </Button>
        </div>
      }
    >
      <Form
        preserve={false}
        layout="vertical"
        form={form}
        name={"basicInformationForm"}
        requiredMark={false}
        onFinish={(values) => {
          currentStaff
            ? onUpdateHandler({
                ...values,
                imageBase64String: base64,
                ext: file?.type?.split("/")[1],
              })
            : onAddHandler({
                ...values,
                imageBase64String: base64,
                ext: file?.type?.split("/")[1],
              });
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
      >
        <PhotoUpload
          type="Profile"
          base64={base64}
          setBase64={setBase64}
          defaultImage={userDp}
          setFile={setFile}
          isEdit={currentStaff}
        />

        {currentStaff && (
          <FormInputArea
            variant="underlined"
            rows={6}
            name="surveyUserId"
            label="Survey System User ID"
            placeholder="Survey System User ID"
            formItemStyles={{ marginBottom: 0 }}
            initialValue={currentStaff?.surveyUserId}
          />
        )}

        <FormInput
          variant="underlined"
          name="firstName"
          label="First Name"
          placeholder="First Name"
          required={true}
          initialValue={currentStaff?.firstName}
        />
        <FormInput
          variant="underlined"
          name="lastName"
          label="Last Name"
          placeholder="Last Name"
          required={true}
          initialValue={currentStaff?.lastName}
        />
        <FormInput
          variant="underlined"
          name="email"
          label="Email Address"
          type="email"
          placeholder="Email Address"
          initialValue={currentStaff?.email}
          required={true}
        />
        <FormPhoneNumber
          variant="underlined"
          name="phoneNumber"
          label="Mobile Number"
          placeholder="Mobile Number"
          required={true}
          initialValue={currentStaff?.phoneNumber}
        />
        <FormSelect
          required
          feedBack={false}
          variant="underlined"
          name="roleId"
          label="Role"
          renderValue="roleId"
          placeholder={"Select Role"}
          renderLabel="roleName"
          styles={{ border: "none" }}
          initialValue={currentStaff?.roleId}
          // formItemStyles={{ marginBottom: 12 }}
          options={activeRoles}
          // styles={{ background: '#ddd' }}
        />
        <FormSelect
          mode={"multiple"}
          required
          feedBack={false}
          variant="underlined"
          name="facilityIds"
          loading={isFetchingFacilities}
          label="Facilities"
          renderValue="facilityId"
          placeholder={"Select Facilities"}
          renderLabel="facilityName"
          styles={{ border: "none" }}
          initialValue={currentStaff?.facilityIds}
          // formItemStyles={{ marginBottom: 12 }}
          options={allFacilities?.filter(
            (it) =>
              it?.isActive ||
              currentStaff?.facilityIds?.includes?.(it?.facilityId)
          )}
          optionLabelProp="label"
          filterOption={(input, option) => {
            return option?.label
              ?.toLowerCase?.()
              .includes?.(input.toLowerCase());
          }}
          // styles={{ background: '#ddd' }}
        />
        <FormSelect
          mode={"multiple"}
          required
          feedBack={false}
          variant="underlined"
          name="staffSkillIds"
          // loading={isFetchingFacilities}
          label="Skills"
          renderValue="id"
          placeholder={"Select Skills"}
          renderLabel="name"
          styles={{ border: "none" }}
          initialValue={
            currentStaff?.skillIds ?? currentStaff?.staffSkillIds ?? []
          }
          // formItemStyles={{ marginBottom: 12 }}
          options={allSkills?.filter(
            (it) =>
              it?.isActive || currentStaff?.staffSkillIds?.includes?.(it?.id)
          )}
          optionLabelProp="label"
          filterOption={(input, option) => {
            return option?.label
              ?.toLowerCase?.()
              .includes?.(input.toLowerCase());
          }}
          // styles={{ background: '#ddd' }}
        />
        <FormSelect
          required
          feedBack={false}
          variant="underlined"
          name="maxNurseAvailabilityInAdvance"
          label="Scheduling Availability Months"
          renderValue="value"
          placeholder={"Select Value"}
          renderLabel="label"
          styles={{ border: "none" }}
          initialValue={currentStaff?.maxNurseAvailabilityInAdvance || 2}
          // formItemStyles={{ marginBottom: 12 }}
          options={Months.map((month) => ({
            label: month,
            value: Number(month),
          }))}
          // styles={{ background: '#ddd' }}
        />
        {/* <FormSelect
          mode={"multiple"}
          required
          feedBack={false}
          variant="underlined"
          name="technicalSkillIds"
          loading={isFetchingFacilities}
          label="Technical Skills"
          renderValue="skillId"
          placeholder={"Select Technical Skills"}
          renderLabel="skillName"
          styles={{ border: "none" }}
          initialValue={currentStaff?.skillIds}
          // formItemStyles={{ marginBottom: 12 }}
          options={allSkills?.filter(
            (it) =>
              it?.isActive || currentStaff?.skillIds?.includes?.(it?.skillId)
          )}
          optionLabelProp="label"
          filterOption={(input, option) => {
            return option?.label
              ?.toLowerCase?.()
              .includes?.(input.toLowerCase());
          }}
          // styles={{ background: '#ddd' }}
        />
        <FormSelect
          mode={"multiple"}
          required
          feedBack={false}
          variant="underlined"
          name="locationSkillIds"
          loading={isFetchingFacilities}
          label="Locational Skills"
          renderValue="skillId"
          placeholder={"Select Locational Skills"}
          renderLabel="skillName"
          styles={{ border: "none" }}
          initialValue={currentStaff?.skillIds}
          // formItemStyles={{ marginBottom: 12 }} 
          options={allSkills?.filter(
            (it) =>
              it?.isActive || currentStaff?.skillIds?.includes?.(it?.skillId)
          )}
          optionLabelProp="label"
          filterOption={(input, option) => {
            return option?.label
              ?.toLowerCase?.()
              .includes?.(input.toLowerCase());
          }}
          // styles={{ background: '#ddd' }}
        /> */}
        <FormCheckbox
          name="isActive"
          label="Active"
          formItemStyles={{ marginBottom: 12 }}
          initialValue={currentStaff ? currentStaff.isActive : true}
        >
          Active
        </FormCheckbox>
        <FormHiddenSubmitButton submitRef={submitBtnRef} />
        {/* <FormButton
          text="Save"
          htmlType="submit"
          rounded
          type={'primary'}
        /> */}
      </Form>
    </Drawer>
  );
}

const Months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
