import React, { useState } from "react";
import PropTypes from "prop-types";
import { Space, Input, Dropdown, Popover, Checkbox, Typography } from "antd";
import { Button } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import "./SearchAndFilterColumns.scss";

import debounce from "lodash/debounce";

const { Search } = Input;
const { Text } = Typography;

function SearchAndFilterColumns({
  placeholder = "Search",
  columns = [],
  hiddenColumns,
  setHiddenColumns,
  searchText,
  setSearchText,
  cancelDebounce = false,
  debouceTimeout = 250,
}) {
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);

  const debouncedChange = debounce(setSearchText, debouceTimeout);

  return (
    <Space className="tableSearchboxContainer">
      <Search
        className={`tableSearchbox ${searchText !== "" ? "hasValue" : ""}`}
        defaultValue={searchText}
        onChange={(e) => {
          if (cancelDebounce) debouncedChange.cancel();
          // debounce(() => setSearchText(e.target.value), 250);
          debouncedChange(e.target.value);
        }}
        placeholder={placeholder}
        // onSearch={onSearch}
        style={{ width: 200 }}
        allowClear
      />

      <Dropdown
        trigger="click"
        placement="bottom"
        overlay={
          <div
            style={{
              background: "#fff",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.25)",
              borderRadius: "6px",
              padding: "20px",
            }}
          >
            {columns
              ?.filter((column) => !!column.title)
              .map((column) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "4px",
                      cursor: "pointer",
                    }}
                    key={column.title}
                    onClick={(e) => {
                      e.stopPropagation();
                      const isHidden = hiddenColumns.includes(column.title);
                      if (!isHidden) {
                        setHiddenColumns([...hiddenColumns, column.title]);
                      } else {
                        setHiddenColumns(
                          hiddenColumns.filter((item) => item !== column.title)
                        );
                      }
                    }}
                  >
                    <Checkbox
                      checked={!hiddenColumns.includes(column.title)}
                      name={column.title}
                      style={{ marginRight: 16 }}
                    />
                    <Text>{column.title}</Text>
                  </div>
                );
              })}
          </div>
        }
        title="Filters"
      >
        <Button
          className={`tableFilterButton ${
            hiddenColumns?.length > 0 ? "hasValue" : ""
          }`}
          icon={<FilterOutlined />}
          onClick={() => setShowFilterDropdown((value) => !value)}
        />
      </Dropdown>
    </Space>
  );
}

SearchAndFilterColumns.propTypes = {};

export default SearchAndFilterColumns;
