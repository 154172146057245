export const actions = {
    SET_ALL_SHIFTS: "SET_ALL_SHIFTS",
    SET_IS_LOADING_SHIFT: "SET_IS_LOADING_SHIFT",
  };
  
  export const setAllShifts = (data) => ({
    type: actions.SET_ALL_SHIFTS,
    data,
  });
  
  export const setIsLoadingShift = (status) => ({
    type: actions.SET_IS_LOADING_SHIFT,
    status: status,
  });
  