import React, { useEffect, useRef, useState } from "react";

import { Form, Spin, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { push, replace } from "connected-react-router";
import {
  FormInput,
  FormPassword,
  FormHiddenSubmitButton,
} from "components/FormItems/FlatFormItems";
import { Button } from "components/Buttons/Buttons";
import {
  forgotPassword,
  loginUser,
  resetPassword,
} from "../../services/auth.services";
import Modal from "components/Modal/Modal";

import AuthLayout from "components/AuthLayout/AuthLayout";
import PasswordWithDropdown from "components/PasswordWithDropdown/PasswordWithDropdown";

const Reset = () => {
  const dispatch = useDispatch();
  const [forgetForm] = Form.useForm();
  const [resetForm] = Form.useForm();
  const { loading, message } = useSelector((state) => state.app);

  const submitBtnRef = useRef();
  const [resetCode, setResetCode] = useState({ username: "", code: "" });
  const [showResetModal, setShowResetModal] = useState(false);

  const onFinishFailed = ({ errorFields }) => {
    forgetForm.scrollToField(errorFields[0].name);
  };

  useEffect(() => {
    if (window.location.search) {
      const code = window.location.search.split("token=")[1];
      const username = window.location.search
        .split("userName=")[1]
        .split("&")[0];
      setResetCode({ username, code });
      setShowResetModal(true);
    }
  }, []);

  return (
    <AuthLayout loading={loading} message={message}>
      <Form
        requiredMark={false}
        className="loginForm"
        layout="vertical"
        form={forgetForm}
        preserve={false}
        onFinishFailed={onFinishFailed}
        onFinish={(values) => {
          forgetForm
            .validateFields()
            .then(async (values) => {
              const result = await dispatch(forgotPassword(values));
              if (result?.status === "success") {
                dispatch(replace("/"));
              }
            })
            .catch((err) => {
              forgetForm.scrollToField(err?.errorFields[0]);
            });
        }}
      >
        <FormInput
          label="Email"
          type="email"
          name="emailAddress"
          required
          placeholder="Email address"
          initialValue={""}
          styles={null}
        />

        <Button
          type="primary"
          htmlType="submit"
          style={{ height: 50, width: "100%" }}
        >
          {"Reset"}
        </Button>
      </Form>
      <Typography.Link
        underline
        className="forgotPasswordText"
        onClick={() => dispatch(push("login"))}
      >
        Return to Login
      </Typography.Link>
      <Modal
        headerBorder={false}
        width="460px"
        title="Reset Password"
        onCancel={() => setShowResetModal(false)}
        visible={showResetModal}
        // onOk={() => handleSave()}
        okText="Save"
        footer={[
          <Button
            style={{ height: 50 }}
            rounded={true}
            onClick={() => submitBtnRef?.current?.click()}
          >
            Save
          </Button>,
        ]}
      >
        <Form
          form={resetForm}
          requiredMark={false}
          layout="vertical"
          onFinish={async (values) => {
            values.userName = showResetModal;

            dispatch(
              resetPassword({
                ...values,
                resetToken: resetCode.code,
                userName: resetCode.username,
              })
            ).then((response) => {
              if (response?.status === "success") {
                setShowResetModal(false);
                dispatch(push("/login"));
              }
            });
            // const { currentPassword, newPassword } = values;
            // const result = await dispatch(
            //   changePassword({ currentPassword, newPassword })
            // );
            // if (result?.status === "success") handleSave?.();
          }}
          onFinishFailed={({ values, errorFields, outOfDate }) => {
            resetForm.scrollToField(errorFields[0].name, {
              scrollMode: "if-needed",
              block: "center",
              behavior: "smooth",
            });
          }}
        >
          {/* <Text style={{ display: "inline-block", marginBottom: 12 }}>
            A reset token has been sent to your email.
          </Text> */}
          {/* <FormInput
            required
            variant="underlined"
            name="resetToken"
            label="Reset Token"
            placeholder=""
            formItemStyles={{ marginBottom: 12 }}
          /> */}

          <PasswordWithDropdown
            form={resetForm}
            variant="underlined"
            name="newPassword"
            label="New Password"
            placeholder=""
            formItemStyles={{ marginBottom: 12 }}
          />
          <FormPassword
            required
            variant="underlined"
            name="confirmPassword"
            label="Confirm New Password"
            placeholder=""
            dependencies={["newPassword"]}
            formItemStyles={{ marginBottom: 12 }}
            rules={[
              {
                required: true,
                message: "Required",
              },
              {
                message: "New and confirm passwords don't match",
                validator: (_, value) => {
                  const newPassword = resetForm.getFieldValue("newPassword");
                  const confirmPassword =
                    resetForm.getFieldValue("confirmPassword");
                  if (newPassword !== confirmPassword) {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
              },
            ]}
          />
          <FormHiddenSubmitButton submitRef={submitBtnRef} />
        </Form>
      </Modal>
    </AuthLayout>
  );
};

export default Reset;
