import React from "react";
import { Col, Collapse, Form, Grid, Row, Typography } from "antd";
import {
  FormCheckbox,
  FormInput,
  FormTimePicker,
} from "components/FormItems/FlatFormItems";
import moment from "moment";

const { useBreakpoint } = Grid;
const { Text } = Typography;

export default function DayShiftHoursField({
  form,
  label,
  namePrefix = "",
  index,
}) {
  const screens = useBreakpoint();
  const isSmallDevice = !screens.md;

  const nameShiftStart = ["scheduleDetail", index, "startTime"];
  const nameShiftEnd = ["scheduleDetail", index, "endTime"];

  const isClosed = Form.useWatch(["scheduleDetail", index, "isClosed"], form);
  const isCallToBookEnabled = Form.useWatch(
    ["scheduleDetail", index, "callToBook"],
    form
  );

  return (
    <>
      <Row gutter={[24, isSmallDevice ? 12 : 24]}>
        <FormInput
          hidden
          name={["scheduleDetail", index, "dayName"]}
          initialValue={label}
        />
        <Col xs={isSmallDevice ? 24 : undefined}>
          <Text className="dayShiftHoursLabel"> {label}</Text>
        </Col>
        <Col xs={isSmallDevice ? 12 : undefined}>
          <FormTimePicker
            allowClear={false}
            disabled={isClosed}
            variant="underlined"
            name={["scheduleDetail", index, "startTime"]}
            initialValue={moment("9:00 am", "hh:mm a")}
            label=""
            //   placeholder="Email Address"
            required={isClosed ? false : true}
            dependencies={[["scheduleDetail", index, "isClosed"]]}
            formItemStyles={{ margin: 0 }}
          />
        </Col>
        <Col xs={isSmallDevice ? 12 : undefined}>
          <FormTimePicker
            allowClear={false}
            disabled={isClosed}
            variant="underlined"
            name={["scheduleDetail", index, "endTime"]}
            initialValue={moment("5:00 pm", "hh:mm a")}
            label=""
            //   placeholder="Email Address"
            formItemStyles={{ margin: 0 }}
            validateFirst={true}
            dependencies={[
              nameShiftStart,
              ["scheduleDetail", index, "isClosed"],
            ]}
            rules={[
              {
                required: isClosed ? false : true,
                message: "Required",
              },
              {
                message: "Invalid",
                validator: (_, value) => {
                  if (isClosed) {
                    return Promise.resolve();
                  }

                  const shiftStartValue = form.getFieldValue([
                    "scheduleDetail",
                    index,
                    "startTime",
                  ]);
                  const shiftEndValue = form.getFieldValue([
                    "scheduleDetail",
                    index,
                    "endTime",
                  ]);

                  if (
                    shiftStartValue >= shiftEndValue ||
                    shiftStartValue.format("LT") === shiftEndValue.format("LT")
                  ) {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
              },
            ]}
          />
        </Col>
        <Col>
          <FormCheckbox
            initialValue={false}
            className="flatCheckbox"
            name={["scheduleDetail", index, "isClosed"]}
            formItemStyles={{ margin: 0, paddingLeft: 12 }}
          >
            Closed All Day
          </FormCheckbox>
        </Col>
      </Row>
      <Row gutter={[24, isSmallDevice ? 12 : 24]}>
        <FormCheckbox
          disabled={isClosed}
          initialValue={false}
          className="flatCheckbox"
          name={["scheduleDetail", index, "callToBook"]}
          formItemStyles={{ margin: 0, paddingLeft: 12 }}
        >
          Call to book
        </FormCheckbox>
      </Row>
      {/* Pre booking hours start */}
      <Row gutter={[24, isSmallDevice ? 12 : 24]}>
        <Col xs={isSmallDevice ? 24 : undefined}>
          {isCallToBookEnabled && (
            <Text className="dayShiftHoursLabel" style={{ textAlign: "right" }}>
              Before
            </Text>
          )}
        </Col>
        <Col xs={isSmallDevice ? 12 : undefined}>
          <FormTimePicker
            allowClear={true}
            disabled={!isCallToBookEnabled}
            hidden={!isCallToBookEnabled || isClosed}
            variant="underlined"
            name={["scheduleDetail", index, "preHoursOfOperationStartTime"]}
            initialValue={moment("9:00 am", "hh:mm a")}
            label=""
            //   placeholder="Email Address"
            // required={isCallToBookEnabled ? true : false}
            dependencies={[["scheduleDetail", index, "callToBook"]]}
            formItemStyles={{ marginBottom: 16 }}
          />
        </Col>
        <Col xs={isSmallDevice ? 12 : undefined}>
          <FormTimePicker
            allowClear={true}
            disabled={!isCallToBookEnabled}
            hidden={!isCallToBookEnabled || isClosed}
            variant="underlined"
            name={["scheduleDetail", index, "preHoursOfOperationEndTime"]}
            initialValue={moment("5:00 pm", "hh:mm a")}
            label=""
            //   placeholder="Email Address"
            formItemStyles={{ margin: 0 }}
            validateFirst={true}
            dependencies={[
              ["scheduleDetail", index, "startTime"],
              ["scheduleDetail", index, "endTime"],
              ["scheduleDetail", index, "preHoursOfOperationStartTime"],
              ["scheduleDetail", index, "postHoursOfOperationStartTime"],
              ["scheduleDetail", index, "postHoursOfOperationEndTime"],
              ["scheduleDetail", index, "callToBook"],
            ]}
            rules={[
              {
                message: "Invalid",
                validator: (_, value) => {
                  if (!isCallToBookEnabled || isClosed) {
                    return Promise.resolve();
                  }

                  const shiftStartValue = form.getFieldValue([
                    "scheduleDetail",
                    index,
                    "startTime",
                  ]);
                  const shiftEndValue = form.getFieldValue([
                    "scheduleDetail",
                    index,
                    "endTime",
                  ]);
                  const preHoursOfOperationStartTime = form.getFieldValue([
                    "scheduleDetail",
                    index,
                    "preHoursOfOperationStartTime",
                  ]);
                  const preHoursOfOperationEndTime = form.getFieldValue([
                    "scheduleDetail",
                    index,
                    "preHoursOfOperationEndTime",
                  ]);
                  const postHoursOfOperationStartTime = form.getFieldValue([
                    "scheduleDetail",
                    index,
                    "postHoursOfOperationStartTime",
                  ]);
                  const postHoursOfOperationEndTime = form.getFieldValue([
                    "scheduleDetail",
                    index,
                    "postHoursOfOperationEndTime",
                  ]);

                  if (
                    !preHoursOfOperationStartTime &&
                    !preHoursOfOperationEndTime &&
                    !postHoursOfOperationStartTime &&
                    !postHoursOfOperationEndTime
                  )
                    return Promise.reject();

                  if (
                    !preHoursOfOperationStartTime &&
                    !preHoursOfOperationEndTime
                  )
                    return Promise.resolve();

                  if (
                    preHoursOfOperationStartTime >=
                      preHoursOfOperationEndTime ||
                    preHoursOfOperationStartTime.format("LT") ===
                      preHoursOfOperationEndTime.format("LT")
                  ) {
                    return Promise.reject();
                  }

                  if (
                    preHoursOfOperationEndTime >= shiftStartValue ||
                    preHoursOfOperationEndTime >= shiftStartValue
                  )
                    return Promise.reject();

                  return Promise.resolve();
                },
              },
            ]}
          />
        </Col>
      </Row>
      {/* Pre booking hours end */}
      {/* Post booking hours start */}
      <Row gutter={[24, isSmallDevice ? 12 : 24]}>
        <Col xs={isSmallDevice ? 24 : undefined}>
          {isCallToBookEnabled && (
            <Text className="dayShiftHoursLabel" style={{ textAlign: "right" }}>
              After
            </Text>
          )}
        </Col>
        <Col xs={isSmallDevice ? 12 : undefined}>
          <FormTimePicker
            allowClear={true}
            disabled={!isCallToBookEnabled}
            hidden={!isCallToBookEnabled || isClosed}
            variant="underlined"
            name={["scheduleDetail", index, "postHoursOfOperationStartTime"]}
            initialValue={moment("9:00 am", "hh:mm a")}
            label=""
            //   placeholder="Email Address"
            // required={isCallToBookEnabled ? false : true}
            dependencies={[["scheduleDetail", index, "callToBook"]]}
            formItemStyles={{ margin: 0 }}
          />
        </Col>
        <Col xs={isSmallDevice ? 12 : undefined}>
          <FormTimePicker
            allowClear={true}
            disabled={!isCallToBookEnabled}
            hidden={!isCallToBookEnabled || isClosed}
            variant="underlined"
            name={["scheduleDetail", index, "postHoursOfOperationEndTime"]}
            initialValue={moment("5:00 pm", "hh:mm a")}
            label=""
            //   placeholder="Email Address"
            formItemStyles={{ margin: 0 }}
            validateFirst={true}
            dependencies={[
              ["scheduleDetail", index, "startTime"],
              ["scheduleDetail", index, "endTime"],
              ["scheduleDetail", index, "preHoursOfOperationStartTime"],
              ["scheduleDetail", index, "preHoursOfOperationEndTime"],
              ["scheduleDetail", index, "postHoursOfOperationStartTime"],
              ["scheduleDetail", index, "callToBook"],
            ]}
            rules={[
              // {
              //   required: isCallToBookEnabled ? false : true,
              //   message: "Required",
              // },
              {
                message: "Invalid",
                validator: (_, value) => {
                  if (!isCallToBookEnabled || isClosed) {
                    return Promise.resolve();
                  }

                  const shiftStartValue = form.getFieldValue([
                    "scheduleDetail",
                    index,
                    "startTime",
                  ]);
                  const shiftEndValue = form.getFieldValue([
                    "scheduleDetail",
                    index,
                    "endTime",
                  ]);
                  const preHoursOfOperationStartTime = form.getFieldValue([
                    "scheduleDetail",
                    index,
                    "preHoursOfOperationStartTime",
                  ]);
                  const preHoursOfOperationEndTime = form.getFieldValue([
                    "scheduleDetail",
                    index,
                    "preHoursOfOperationEndTime",
                  ]);
                  const postHoursOfOperationStartTime = form.getFieldValue([
                    "scheduleDetail",
                    index,
                    "postHoursOfOperationStartTime",
                  ]);
                  const postHoursOfOperationEndTime = form.getFieldValue([
                    "scheduleDetail",
                    index,
                    "postHoursOfOperationEndTime",
                  ]);

                  if (
                    !preHoursOfOperationStartTime &&
                    !preHoursOfOperationEndTime &&
                    !postHoursOfOperationStartTime &&
                    !postHoursOfOperationEndTime
                  )
                    return Promise.reject();

                  if (
                    !postHoursOfOperationStartTime &&
                    !postHoursOfOperationEndTime
                  )
                    return Promise.resolve();

                  if (
                    postHoursOfOperationStartTime >=
                      postHoursOfOperationEndTime ||
                    postHoursOfOperationStartTime.format("LT") ===
                      postHoursOfOperationEndTime.format("LT")
                  ) {
                    return Promise.reject();
                  }

                  if (
                    postHoursOfOperationStartTime <= shiftEndValue  ||
                    postHoursOfOperationStartTime <= shiftEndValue
                  )
                    return Promise.reject();

                  return Promise.resolve();
                },
              },
            ]}
          />
        </Col>
      </Row>
      {/* Post booking hours end */}
    </>
  );
}
