import { actions } from "../actions/facilities.action";

const INITIAL_STATE = {
  allFacilities: [],
  clinicFacilities: [],
  mobileFacilities: [],
  isFetchingFacilities: false,
};

const reducer = (state = INITIAL_STATE, data) => {
  switch (data.type) {
    case actions.SET_FACILITIES:
      return { ...state, allFacilities: data.data };
    case actions.SET_CLINIC_FACILITIES:
      return { ...state, clinicFacilities: data.data };
    case actions.SET_MOBILE_FACILITIES:
      return { ...state, mobileFacilities: data.data };
    case actions.SET_FETCHING_FACILITIES:
      return { ...state, isFetchingFacilities: data.isFetchingFacilities };
    case "RESET":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
