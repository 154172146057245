import { cloneDeep } from "lodash";
import moment from "moment";
import { setLoading } from "redux/actions/app.actions";
import { openNotificationWithIcon } from "utils/Notification";
import { DUMMY_CATEGORIES, DUMMY_SERVICES, setCategories } from "./data";
import { request } from "./verb.services";

export const getAllDiscorverySources = (callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request("Configurations/MarketingSources", "get", null, true, null)
      .then((res) => {
        console.log("res", res);

        let str = res.data.value || "";
        let value = decodeURI(str);
        let json = JSON.parse(value || "[]") ?? [];

        return {
          status: "success",
          data: json,
        };
      })
      .catch((e) => {
        console.error(e);

        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const createDiscoverySource = (values, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Configurations/MarketingSources`,
      "patch",
      `"${values}"`,
      {
        contentType: "application/json",
      },
      null
    )
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Marketing source created successfully"
        );
        return { status: "success", data: res?.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const updateDiscoverySource = (values, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Configurations/MarketingSources`,
      "patch",
      `"${values}"`,
      {
        contentType: "application/json",
      },
      null
    )
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Marketing source updated successfully"
        );
        return { status: "success", data: res?.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const deleteDiscoverySource = (values) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Configurations/MarketingSources`,
      "patch",
      `"${values}"`,
      {
        contentType: "application/json",
      },
      null
    )
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Marketing source deleted successfully"
        );
        return { status: "success", data: res?.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
