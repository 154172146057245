import { MoreOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Modal, Select, Space, Table } from "antd";
import { Button } from "components/Buttons/Buttons";
import SearchAndFilterColumns from "components/SearchAndFilterColumns/SearchAndFilterColumns";
import usePortal from "hooks/usePortal";
import useSettingsHeader from "hooks/useSettingsHeader";
import moment from "moment";
import React, { useEffect, useReducer, useState } from "react";
import { useDispatch } from "react-redux";
import {
  copyPricingSchedule,
  createPricingSchedule,
  deletePricingSchedule,
  getAllPricingSchedules,
  restorePricingSchedule,
  updatePricingSchedule,
} from "services/pricingSchedule.service";
import {
  getAllBoosts,
  getAllServices,
} from "services/servicesAndBoostsSettings.service";
import PricingDrawer from "./components/PricingDrawer.index";

import "./PricingScheduler.style.scss";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET":
      return action.data;
    case "ADD":
      return [...state, action.data];
    case "UPDATE":
      return state.map((data) => {
        if (data.priceScheduleId === action.data.priceScheduleId)
          return action.data;
        return data;
      });
    case "DELETE":
      return state.filter((data) => {
        if (data.priceScheduleId === action.data.priceScheduleId) return false;
        return true;
      });
    default:
      return state;
  }
};

export default function PricingScheduler() {
  const [showDetails, setShowDetails] = useState(false);
  const [showAddEditModal, setShowAddEditModal] = useState();

  const [data, localDispatch] = useReducer(reducer, []);
  const [products, setProducts] = useState([]);
  const [boosts, setBoosts] = useState([]);

  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [activeFilter, setActiveFilter] = useState("active"); // all, active, inactive
  const [searchText, setSearchText] = useState("");

  //states end

  // non-data hooks start
  const dispatch = useDispatch();
  // non-data hooks end

  // use effect starts
  const loadData = async () => {
    const result = await dispatch(getAllPricingSchedules());
    if (result?.status === "success") {
      localDispatch({
        type: "SET",
        data: result.data,
      });
    }
  };
  useEffect(() => {
    loadData();
    dispatch(getAllServices()).then((res) => {
      if (res?.status === "success") {
        setProducts(res.data?.filter((it) => it?.isActive));
      }
    });
    dispatch(getAllBoosts()).then((res) => {
      if (res?.status === "success") {
        setBoosts(res.data?.filter((it) => it?.isActive));
      }
    });
  }, []);
  // use effects end

  const handleUpdate = (id, values) => {
    dispatch(updatePricingSchedule(id, values)).then((result) => {
      if (result?.status === "success") {
        setShowAddEditModal();
        loadData();
      }
    });
  };
  const handleAdd = (values) => {
    dispatch(createPricingSchedule(values)).then((result) => {
      if (result?.status === "success") {
        setShowAddEditModal();
        loadData();
        // loadData();
      }
    });
  };
  const handleDelete = (id) => {
    dispatch(deletePricingSchedule(id)).then((result) => {
      if (result?.status === "success") {
        loadData();
      }
    });
  };
  const handleRestore = (id) => {
    dispatch(restorePricingSchedule(id)).then((result) => {
      if (result?.status === "success") {
        loadData();
      }
    });
  };
  const handleCopy = (id) => {
    dispatch(copyPricingSchedule(id)).then((result) => {
      if (result?.status === "success") {
        loadData();
      }
    });
  };

  const deleteConfirmation = (id) => ({
    title: "Inactivate Pricing Schedule",
    content: (
      <>Are you sure that you want to inactivate the pricing schedule?</>
    ),
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      handleDelete(id);
      close();
    },
  });
  const restoreConfirmation = (id) => ({
    title: "Activate Pricing Schedule",
    content: <>Are you sure that you want to activate the pricing schedule?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      handleRestore(id);
      close();
    },
  });

  const checkActiveFilter = (item) => {
    if (activeFilter === "all") return true;
    else if (activeFilter === "active" && item?.isActive) return true;
    else if (activeFilter === "inactive" && !item?.isActive) return true;
    return false;
  };

  const columns = [
    {
      title: "Schedule Name",
      dataIndex: "priceScheduleName",
      key: "priceScheduleName",
      sorter: (a, b) => {
        if (
          a.priceScheduleName?.toLowerCase?.() <
          b.priceScheduleName?.toLowerCase?.()
        )
          return -1;
        if (
          a.priceScheduleName?.toLowerCase?.() >
          b.priceScheduleName?.toLowerCase?.()
        )
          return 1;
        return 0;
      },
    },
    {
      title: "Active Services",
      dataIndex: "activeServicesCount",
      key: "servicesCount",
      width: 180,
      align: "center",
      sorter: (a, b) => {
        if (a.servicesCount < b.servicesCount) return -1;
        if (a.servicesCount > b.servicesCount) return 1;
        return 0;
      },
    },
    {
      title: "Active",
      key: "active",
      width: 100,
      render: (data) => {
        if (data?.isActive) return "Active";
        return "Inactive";
      },
    },

    {
      title: "",
      key: "action",
      align: "right",
      width: 80,
      render: (record) => (
        <Dropdown
          overlay={
            <Menu style={{ borderRadius: 6, border: "2px solid #CDD5D8 " }}>
              <Menu.Item onClick={() => setShowAddEditModal(record)} key="edit">
                Edit
              </Menu.Item>
              <Menu.Item
                onClick={() => handleCopy(record?.priceScheduleId)}
                key="copy"
              >
                Make a Copy
              </Menu.Item>
              <Menu.Item
                key="delete"
                onClick={() => {
                  if (record?.isActive)
                    Modal.warning(deleteConfirmation(record?.priceScheduleId));
                  else
                    Modal.warning(restoreConfirmation(record?.priceScheduleId));
                }}
              >
                {record?.isActive ? "Inactivate" : "Activate"}
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
          getPopupContainer={(triggerNode) => triggerNode?.parentNode}
        >
          <MoreOutlined style={{ fontSize: 18 }} />
        </Dropdown>
      ),
    },
  ];

  const settingHeaderContent = useSettingsHeader(
    <Button
      rounded={true}
      onClick={() => {
        setShowAddEditModal(true);
      }}
    >
      Create Schedule
    </Button>
  );

  const searchAndFiltersContent = usePortal(
    "#settingHeader .settingsHeaderLeftSideAddon",
    <Space>
      <SearchAndFilterColumns
        columns={columns}
        hiddenColumns={hiddenColumns}
        setHiddenColumns={(columnTitles) => {
          setHiddenColumns(columnTitles);
        }}
        searchText={searchText}
        setSearchText={(text) => {
          setSearchText(text);
        }}
      />
      <Select
        style={{ width: 120 }}
        value={activeFilter}
        onChange={(value) => {
          setActiveFilter(value);
        }}
        options={[
          {
            value: "all",
            label: "All",
          },
          {
            value: "active",
            label: "Active",
          },
          {
            value: "inactive",
            label: "Inactive",
          },
        ]}
      />
    </Space>
  );

  return (
    <div>
      {settingHeaderContent}
      {searchAndFiltersContent}
      {showAddEditModal && (
        <PricingDrawer
          data={showAddEditModal}
          products={products}
          boosts={boosts}
          visible={showAddEditModal}
          onClose={() => {
            setShowAddEditModal();
          }}
          onSuccess={(values) => {
            const isEdit = showAddEditModal?.priceScheduleId !== undefined;
            if (isEdit) handleUpdate(showAddEditModal?.priceScheduleId, values);
            else handleAdd(values);
          }}
        />
      )}

      <Table
        tableLayout="auto"
        locale={{ emptyText: "No Locations" }}
        rowKey={(data) => data.priceScheduleId}
        dataSource={data?.filter(
          (it) =>
            checkActiveFilter(it) &&
            it?.priceScheduleName
              ?.toLowerCase?.()
              .includes?.(searchText.toLowerCase())
        )}
        columns={columns.filter((column) => {
          if (!hiddenColumns.includes(column.title)) return true;
          return false;
        })}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: [15, 25, 50, 100, 250],
        }}
        // scroll={{ y: "calc(100vh - 80px - 96px - 60px - 100px)", x: true }}
      />
    </div>
  );
}
