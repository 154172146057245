import Programs from "containers/Settings/Screens/Programs/Programs.index";
import AdminProfile from "containers/Settings/Screens/AdminProfile/AdminProfile.index";
import Communications from "containers/Settings/Screens/Communications/Communications.index";
import Miscellaneous from "containers/Settings/Screens/Miscellaneous/Miscellaneous.index";
import PricingScheduler from "containers/Settings/Screens/PricingScheduler/PricingScheduler.index";
import Locations from "containers/Settings/Screens/Locations/Locations.index";
import ServicesAndBoosts from "containers/Settings/Screens/ServicesAndBoosts/ServicesAndBoosts.index";
import ServiceType from "containers/Settings/Screens/ServiceType/ServiceType.index";
import Categories from "containers/Settings/Screens/Categories/Categories.index";
import Boosts from "containers/Settings/Screens/ServicesAndBoosts/Boosts.index";
import ShiftsSchedule from "containers/Settings/Screens/Locations/ShiftsSchedule";
import CreateEditShiftSchedule from "containers/Settings/Screens/Locations/CreateEditShiftSchedule";
import Quickbooks from "containers/Settings/Screens/Quickbooks/Quickbooks.index";
import Restrictions from "containers/Settings/Screens/Restrictions/Restrictions.index";
import FormsManagement from "containers/FormsManagement/FormsManagement.index";
import FormEditCreate from "containers/FormsManagement/components/FormDesigner";
import Shifts from "containers/Settings/Screens/Shifts/Shifts.index";
import Contraindictions from "containers/Settings/Screens/Contraindications/Containdications.index";
import ProductContraindications from "containers/Settings/Screens/ProductContraindications/ProductContraindications.index";
import Skills from "containers/Skills/Skills.index";
import Marketting from "containers/Settings/Screens/Marketting/Marketting.index";
//  using  goBackURL because history.goBack takes user out of site when back btn in setting layout is clicked on direct settings page visit

let settingRoutes = [
  {
    path: "/dashboard/settings/programs",
    goBackURL: "/dashboard/settings",
    name: "programSettings",
    component: Programs,
    exact: true,
    title: "Programs",
    text: "Manage and configure Programs, Memberships, Voucher codes and Referrals.",
  },
  {
    path: "/dashboard/settings/pricing",
    goBackURL: "/dashboard/settings",
    name: "pricingSchedulerSettings",
    component: PricingScheduler,
    exact: true,
    title: "Pricing",
    text: "Create Pricing Schedule and manage Services and Add-ons by location.",
  },
  {
    path: "/dashboard/settings/services-and-boosts",
    goBackURL: "/dashboard/settings",
    name: "servicesAndBoosts",
    component: ServicesAndBoosts,
    exact: true,
    title: "Services & Add-ons",
    text: "Create and configure Services and Add-ons.",
  },
  {
    path: "/dashboard/settings/boosts",
    goBackURL: "/dashboard/settings/services-and-boosts",
    name: "boosts",
    component: Boosts,
    showCard: false,
    exact: true,
    title: "Add-ons",
  },
  {
    path: "/dashboard/settings/service-types",
    goBackURL: "/dashboard/settings",
    name: "serviceTypes",
    component: ServiceType,
    exact: true,
    title: "Service Type",
    text: "View Service Types.",
  },
  {
    path: "/dashboard/settings/categories",
    goBackURL: "/dashboard/settings",
    name: "categories",
    component: Categories,
    exact: true,
    title: "Categories",
    text: "Manage and configure Categories.",
  },
  {
    path: "/dashboard/settings/locations",
    goBackURL: "/dashboard/settings",
    name: "locationSettings",
    component: Locations,
    exact: true,
    title: "Locations",
    text: "Create, manage and configure Locations and Business Hours.",
  },
  {
    path: "/dashboard/settings/shift-schedules",
    goBackURL: "/dashboard/settings/locations",
    name: "shiftSchedule",
    component: ShiftsSchedule,
    showCard: false,
    exact: true,
    title: "Manage Business Hours",
  },
  {
    path: "/dashboard/settings/shift-schedule/:id",
    goBackURL: "/dashboard/settings/shift-schedules",
    name: "createEditShiftSchedule",
    component: CreateEditShiftSchedule,
    showCard: false,
    exact: true,
    title: "Edit Schedule",
  },
  {
    path: "/dashboard/settings/shift-schedule",
    goBackURL: "/dashboard/settings/shift-schedules",
    name: "createEditShiftSchedule",
    component: CreateEditShiftSchedule,
    showCard: false,
    exact: true,
    title: "Create Schedule",
  },
  {
    path: "/dashboard/settings/forms-managment/edit/:id",
    name: "form-edit",
    component: FormEditCreate,
    showCard: false,
    title: "Edit Form",
    showHeaders: false,
  },
  {
    path: "/dashboard/settings/forms-managment",
    name: "formsManagment",
    component: FormsManagement,
    exact: true,
    title: "Forms Management",
    text: "Create and Manage Charts and Consent Forms.",
  },
 
  // {
  //   path: "/dashboard/settings/admin-profile",
  //   name: "adminProfileSettings",
  //   component: AdminProfile,
  //   exact: true,
  //   title: "Admin Profile",
  //   text: "lorem ipsum dolor sit amet, consectetur adip lorem ipsum dolor sit amet, consectetur adip lorem ipsum dolor sit amet, consectetur lorem ipsum dolor sit amet, consectetur adip adips",
  // },
  {
    path: "/dashboard/settings/misc",
    goBackURL: "/dashboard/settings",
    name: "miscellaneousSettings",
    component: Miscellaneous,
    exact: true,
    title: "Miscellaneous",
    text: "Administer Shift Availablility, Cancellation Policies and Booking Session Timeout.",
  },
  {
    path: "/dashboard/settings/shifts",
    name: "shifts",
    component: Shifts,
    exact: true,
    title: "Shifts",
    text: "Create and Manage Shifts.",
  },
  {
    path: "/dashboard/settings/contraindications-rules",
    name: "contraindications-rules",
    component: ProductContraindications,
    exact: true,
    title: "Contraindication Rules",
    text: "Create and Manage Contraindication Rules.",
  },
  {
    path: "/dashboard/settings/contraindications",
    name: "contraindications",
    component: Contraindictions,
    exact: true,
    title: "Contraindications",
    text: "Create and Manage Contraindications.",
  },
  {
    path: "/dashboard/settings/communication",
    goBackURL: "/dashboard/settings",
    name: "coomunicationsSettings",
    component: Communications,
    exact: true,
    title: "Communications",
    text: "Manage email and text triggers.",
  },
  {
    path: "/dashboard/settings/quikbooks",
    goBackURL: "/dashboard/settings",
    name: "quikbooksSettings",
    component: Quickbooks,
    exact: true,
    title: "Manage QuickBooks Online",
    text: "Manage QuickBooks...",
  },
  {
    path: "/dashboard/settings/restrictions",
    goBackURL: "/dashboard/settings",
    name: "ServicesAndBoostsRestrictions",
    component: Restrictions,
    exact: true,
    title: "Restrictions",
    text: "Create and Manage Restrictions.",
  },
  {
    path: "/dashboard/settings/skills",
    goBackURL: "/dashboard/settings",
    name: "Skills",
    component: Skills,
    exact: true,
    title: "Skills",
    text: "Create and Manage Skills.",
  },
  {
    path: "/dashboard/settings/marketing",
    goBackURL: "/dashboard/settings",
    name: "markettingSettings",
    component: Marketting,
    exact: true,
    title: "Marketing",
    text: "Will add list of items to display in the 'How did you hear from us?'",
  },
];

export default settingRoutes;
