import { cloneDeep } from "lodash";
import moment from "moment";
import { setLoading } from "redux/actions/app.actions";
import { setAllSkills, setIsLoadingSkill } from "redux/actions/skills.action";
import { openNotificationWithIcon } from "utils/Notification";
import {
  DUMMY_SERVICES,
  DUMMY_CATEGORIES,
  DUMMY_BOOSTS,
  setServices,
  setBoosts,
} from "./data";
import { request } from "./verb.services";

export const getAllSkills = (showLoading = true, callback) => {
  return (dispatch) => {
    if (showLoading) dispatch(setLoading(true));
    dispatch(setIsLoadingSkill(true));
    return request("Skills", "get", null, true, null)
      .then((res) => {
        dispatch(setAllSkills(res.data));
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        if (showLoading) dispatch(setLoading(false));
        dispatch(setIsLoadingSkill(false));
      });
  };
};

export const getSkillById = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Skills/${id}`, "get", null, true)
      .then((res) => {
        return { status: "success", data: res.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const createSkill = (values, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Skills`, "post", values, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Skill created successfully"
        );
        return { status: "success", data: res.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const deleteSkill = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`skills/${id}/status`, "patch", null, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Skill inactivated successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const restoreSkill = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`skills/${id}/status`, "patch", null, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Skill activated successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const updateSkill = (id, data, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Skills/${id}`, "put", data, true)
      .then(() => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Skill updated successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
