import React, { useMemo, useState } from "react";
import { Calendar as BigCalendar } from "react-big-calendar";

import { momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "./styles/styles.scss";
import "./styles/custom.scss";

import {
  TimeSlot,
  ResourceHeader,
  ResourceEvent,
  Agenda,
  AgendaTime,
} from "./components/Misc";
import Toolbar from "./components/Toolbar";

import { useSelector } from "react-redux";
import { uniqBy } from "lodash";
import { CalenderOperationType } from "utils/enums";
import MyCustomAgendaComponent from "components/CustomAgendaView/CustomAgendaView";

function Calendar({
  showFilter,
  setShowFilter,
  style = {},
  staff,
  facilities = [],
  isFetchingFacilities,
  date,
  setDate,
  currentFacilityIds,
  setFacility,
  bookAnAppointmentClick,
  appointmentDetailClick,
  operationType,
  setOperationType,
  view,
  setView,
}) {
  const { bookings } = useSelector((state) => {
    return {
      bookings: state.bookings,
    };
  });
  const { monthlyBookings, weeklyBookings, dailyBookings } = bookings;
  const { components, localizer } = useMemo(
    () => ({
      components: {
        toolbar: (params) => (
          <Toolbar
            {...params}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            currentFacilityIds={currentFacilityIds}
            facilities={facilities?.filter((it) => it.isActive)}
            isFetchingFacilities={isFetchingFacilities}
            setFacility={setFacility}
            bookAnAppointmentClick={bookAnAppointmentClick}
            operationType={operationType}
            setOperationType={(value) => {
              setOperationType(value);
              if (value === CalenderOperationType.Telehealth) setView("agenda");
              else setView("day");
            }}
          />
        ),
        timeSlotWrapper: (params) => {
          const nurse = staff?.find?.(
            (resource) => resource.staffId === params.resource
          );

          return (
            <TimeSlot
              {...params}
              currentFacilityIds={currentFacilityIds}
              nurse={nurse}
              bookAnAppointmentClick={bookAnAppointmentClick}
            />
          );
        },
        resourceHeader: ResourceHeader,
        eventWrapper: (params) => (
          <ResourceEvent
            {...params}
            appointmentDetailClick={appointmentDetailClick}
          />
        ),
        agenda: {
          time: (params) => <AgendaTime {...params} />,
          event: (params) => (
            <Agenda
              {...params}
              appointmentDetailClick={appointmentDetailClick}
            />
          ), // with the agenda view use a different component to render events
        },
      },
      localizer: momentLocalizer(moment),
    }),
    [facilities, isFetchingFacilities, operationType, setView, view]
  );
  const { views } = useMemo(
    () => ({
      views: [
        "day",
        "agenda",
        {
          agenda: Agenda,
        },
      ],
    }),
    []
  );
  return (
    <BigCalendar
      scrollToTime={new Date()}
      // selectable={true}
      elementProps={{
        style: {
          width: 200,
        },
      }}
      components={components}
      timeslots={1}
      slotPropGetter={(date, staffId, ...x) => {
        const dateMoment = moment(date);
        let hasEvent = false;
        const resource = staff?.find?.(
          (resource) => resource.staffId === staffId
        );
        if (resource?.shiftStart && resource?.shiftEnd) {
          let startShift = moment(dateMoment);
          const startShiftTime = resource.shiftStart?.split(":");
          startShift.set({
            hour: startShiftTime[0],
            minute: startShiftTime[1],
          });
          let endShift = moment(dateMoment);
          const endShiftTime = resource.shiftEnd?.split(":");
          endShift.set({ hour: endShiftTime[0], minute: endShiftTime[1] });
          if (
            dateMoment.isSameOrAfter(startShift) &&
            dateMoment.isSameOrBefore(endShift)
          ) {
            hasEvent = true;
          }
        }
        if (hasEvent) {
          return {
            className: "shiftAvailable",
            style: { backgroundColor: "#F1FDF6 !important" }, //  "#F1FDF6"
          };
        } else {
          return {};
        }
      }}
      onNavigate={(date) => {
        setDate(date);
      }}
      date={date}
      view={view}
      onView={(view) => {
        setView(view);
      }}
      events={uniqBy(dailyBookings, "orderId")?.filter((booking) => {
        if (operationType === CalenderOperationType.All)
          return currentFacilityIds.includes(booking?.facilityId);
        else {
          return booking?.operationTypeId === CalenderOperationType.Telehealth;
        }
      })}
      startAccessor={(event) => {
        return moment(event.serviceDate).toDate();
      }}
      endAccessor={(event) => {
        const durationHours = Number(event.orderDuration ?? 0);
        let date = moment(event.serviceDate);
        if (date.hours() === 23) date = date.add(59, "minutes");
        else date = date.add(durationHours > 0 ? durationHours : 1, "hour");
        return date.toDate();
      }}
      localizer={localizer}
      resources={staff}
      resourceIdAccessor={(resource) => {
        return resource.staffId;
      }}
      resourceTitleAccessor="firstName"
      views={{
        week: true,
        month: true,
        day: true,
        agenda: MyCustomAgendaComponent,
      }}
      dayLayoutAlgorithm="no-overlap"
    />
  );
}

Calendar.propTypes = {};

export default Calendar;
