import React, { useState, useMemo, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import {
  Form,
  Grid,
  Row,
  Col,
  Typography,
  Button as AntdButton,
  Avatar,
  Select,
  Space,
  Collapse,
  Table,
  Modal,
  Checkbox,
  Spin,
} from "antd";
import { Button } from "components/Buttons/Buttons";
import Drawer from "components/Drawer/Drawer";
import {
  FormSelect,
  FormInput,
  FormDatePicker,
  FormInputArea,
  FormCheckbox,
  FormHiddenSubmitButton,
} from "components/FormItems/FlatFormItems";
import moment from "moment-timezone";

import {
  checkIsMobileBooking,
  checkIsTeleHealthBooking,
  generateHourlySlots,
  generateHourlyTimeslotsAfterNHours,
  generateTimeslots,
  getRewardId,
  isNurseRole,
} from "utils/common";
import LocationDropdown from "components/LocationDropdown/LocationDropdown";
import useLocationCenter from "hooks/useLocationCenter";

import {
  clearCustomerNotes,
  createCustomerNotes,
  getAllGuests,
  getCustomerDetails,
  getCustomerNotes,
  getCustomerPaymentMethods,
  getCustomerWalletCredits,
  getCachedGuests,
  getRewards,
  updateCustomerNotes,
} from "services/guests.services";
import { useDispatch, useSelector } from "react-redux";
import {
  CrownOutlined,
  DeleteOutlined,
  EditOutlined,
  StarOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { createBooking } from "services/booking.service";
import MiniPaymentRow from "components/MiniPaymentRow/MiniPaymentRow";
import { openNotificationWithIcon } from "utils/Notification";
import { setSelectedGuest } from "redux/actions/scheduler.actions";
import { set, uniqBy } from "lodash";
import { setLoading } from "redux/actions/app.actions";
import MobileFacilityPopup from "./MobileFacilityPopup";
import {
  getFacilityTimings,
  getStateAndCountryFromZip,
} from "services/locationCenter.services";
import { validateRestriction } from "services/restrictions.service";
import { getAllSkills } from "services/skills.services";
import { GOOGLE_MAP_KEY } from "utils/constants";
import { getFaclityInZone } from "services/facilities.services";
import { CalenderOperationType } from "utils/enums";
import { deleteNote } from "services/guests.services";
import NotesGrid from "components/NotesGrid/NotesGrid";
import GiftCardRow from "components/GiftCardRow/GiftCardRow";
import { getCustomerGiftCards } from "services/giftCards.service";
import MembershipStatus from "components/MembershipStatus/MembershipStatus";
import ProgramsList from "components/ProgramsList/ProgramsList";
import ProgramRow from "components/ProgramsList/ProgramRow";
import { intakeFormEmailReminder } from "services/intakeForm.service";

const { Title, Text, Link } = Typography;
const Option = Select.Option;
const { useBreakpoint } = Grid;

const APPOINTMENT_HOURS_DIFF = 2;

const PAGE_SIZE = 10;

function AppointmentDrawer({ visible, onClose, data, addGuestClick }) {
  const {
    wholeStaff,
    // guests,
    allRoles,
    selectedGuest,
    clinicFacilities,
    mobileFacilities,
    skills,
    isLoadingSkills,
  } = useSelector((state) => {
    return {
      wholeStaff: state.user.wholeStaff,
      // guests: state.user.allGuests,
      allRoles: state.roles.allRoles,
      selectedGuest: state.scheduler.selectedGuest,
      clinicFacilities: state.facilities.clinicFacilities,
      mobileFacilities: state.facilities.mobileFacilities,
      skills: state.skills.allSkills,
      isLoadingSkills: state.skills.isSkillsLoading,
    };
  });

  const screens = useBreakpoint();
  const abortConRef = useRef();

  const submitBtnRef = useRef();
  const [guestSearchForm] = Form.useForm();
  const [appointmentForm] = Form.useForm();
  const [notesForm] = Form.useForm();
  const operationTypeId = Form.useWatch("operationTypeId", appointmentForm);

  const customerId = Form.useWatch("customerId", guestSearchForm);
  // const skillIds = Form.useWatch("skillIds", appointmentForm);

  const dispatch = useDispatch();

  const [paymentMethods, setPaymentMethods] = useState();
  // const [rewards, setRewards] = useState({
  //   data: [],
  //   isLoading: false,
  // });
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [fetchingPaymentMethods, setFetchingPaymentMethods] = useState(false);
  const [notes, setNotes] = useState([]);
  const [submitOnFinish, setSubmitOnFinish] = useState(false);

  const [guestSearch, setGuestSearch] = useState("");

  const [giftCards, setGiftCards] = useState({
    data: [],
    isLoading: false,
  });
  const [credit, setCredits] = useState({
    data: 0,
    isLoading: true,
  });
  const [customerDetails, setCustomerDetails] = useState(null);

  const currentUser = useSelector((state) => state.user.currentUser);
  // const notes = useSelector((state) => state.user.userNotes);

  const [guests, setGuests] = useState({
    data: [],
    isLoading: true,
    totalRecords: 0,
    currentPage: 1,
  });

  const [scheduledDate, setScheduledDate] = useState();
  const getCustomerPaymentMethodsLists = async (
    customerId,
    newPaymentMethodId
  ) => {
    setFetchingPaymentMethods(true);
    dispatch(
      getCustomerPaymentMethods(customerId, (paymentMethods) => {
        setPaymentMethods(paymentMethods);

        if (newPaymentMethodId) {
          setSelectedPaymentMethod(newPaymentMethodId);
        } else {
          const primaryPaymentMethod = paymentMethods?.find?.(
            (paymentMethod) => paymentMethod?.isPrimary === true
          );
          if (!primaryPaymentMethod)
            setSelectedPaymentMethod(paymentMethods?.[0]?.paymentMethodId);
          else setSelectedPaymentMethod(primaryPaymentMethod?.paymentMethodId);
        }
      })
    ).finally(() => {
      setFetchingPaymentMethods(false);
    });
  };
  const popululateCustomerDetails = async (customerId) => {
    if (operationTypeId === 1) {
      appointmentForm.setFieldsValue({
        productId: undefined,
        addOns: undefined,
        date: undefined,
        time: undefined,
      });
    }

    // setRewards((state) => ({ ...state, isLoading: true }));
    getCustomerPaymentMethodsLists(customerId);
    // dispatch(getRewards(customerId))
    //   .then((res) => {
    //     if (res?.status === "success")
    //       setRewards((state) => ({ ...state, data: res?.data ?? [] }));
    //   })
    //   .finally(() => {
    //     setRewards((state) => ({ ...state, isLoading: false }));
    //   });
    dispatch(
      getCustomerDetails(customerId, (customerDetail) => {
        setCustomerDetails(customerDetail);
        appointmentForm.setFieldsValue({
          serviceAddress: customerDetail?.primaryAddress,
          note: customerDetail?.instruction,
        });

        if (operationTypeId === 1) {
          clearLocationCenterData();
          setStreetAddress(customerDetail?.primaryAddress, operationTypeId);
        }
      })
    );
  };

  const populateCustomerGiftCards = (customerId) => {
    setGiftCards((data) => ({ ...data, isLoading: true }));
    dispatch(getCustomerGiftCards(customerId))
      .then((res) => {
        if (res?.status === "success") {
          setGiftCards((data) => ({ data: res?.data ?? [], isLoading: false }));
        }
      })
      .finally(() => {
        setGiftCards((data) => ({ ...data, isLoading: false }));
      });
  };

  const populateWalletCredits = (customerId) => {
    if (customerId) {
      setCredits((state) => ({ ...state, isLoading: true }));
      dispatch(getCustomerWalletCredits(customerId))
        .then((res) => {
          if (res?.status === "success") {
            setCredits((state) => ({ ...state, data: res?.data }));
          }
        })
        .finally(() => {
          setCredits((state) => ({ ...state, isLoading: false }));
        });
    }
  };

  // functions start
  const getData = (page, sortBy, ascend, searchText, active) => {
    setGuests((state) => ({
      ...state,
      isLoading: true,
    }));
    if (abortConRef.current) abortConRef.current.abort();
    abortConRef.current = new AbortController();

    if (
      (page - 1) * PAGE_SIZE >= guests.totalRecords &&
      guests.totalRecords !== 0
    ) {
      setGuests((state) => ({
        ...state,
        isLoading: false,
      }));
      return;
    }

    dispatch(
      getCachedGuests(
        (page - 1) * PAGE_SIZE,
        PAGE_SIZE,
        sortBy,
        ascend,
        searchText,
        active,
        abortConRef?.current?.signal,
        false
      )
    )
      .then((res) => {
        if (res?.status === "success") {
          setGuests((state) => ({
            ...state,
            data: page > 1 ? [...state.data, ...res.data] : res?.data,
            totalRecords: res?.totalRecords,
            currentPage: page + 1,
          }));
        }
      })
      .finally(() => {
        setGuests((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  };
  // functions end

  // get payment methods
  useEffect(() => {
    const { date, staffId, dateOnly = false } = data ?? {};
    if (visible && !!data && !!data.date) {
      const providedDate = moment(date);
      providedDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      setScheduledDate(providedDate);
      appointmentForm.setFieldsValue({
        date: providedDate,
        time: !dateOnly ? date?.format("LT") : undefined,
        staffId,
      });
    }

    if (visible === false) {
      setCustomerDetails();
      appointmentForm.resetFields();
      setPaymentMethods([]);
      setGiftCards({ data: [], isLoading: false });
      // setRewards({
      //   data: [],
      //   isLoading: false,
      // });
      setCredits({
        data: 0,
        isLoading: false,
      });
    }
  }, [visible]);

  // useEffects Start
  useEffect(() => {
    // let active = true;
    // if (activeFilter === "active") {
    //   active = true;
    // } else if (activeFilter === "inactive") {
    //   active = false;
    // } else {
    //   active = null;
    // }
    if (visible && !selectedGuest)
      getData(1, "FirstName", true, guestSearch, true);
    else if (visible && selectedGuest) {
      setGuests((state) => ({
        ...state,
        data: [
          {
            ...selectedGuest,
            name: `${selectedGuest?.firstName} ${selectedGuest?.lastName}`,
          },
        ],
        totalRecords: 1,
        currentPage: 1,
        isLoading: false,
      }));
    }
  }, [guestSearch, selectedGuest, visible]); //eslint-disable-line
  // useEffects End

  // const selectedAddOns = Form.useWatch("addOns", appointmentForm);
  const {
    facilityId,
    locationCenters,
    setStreetAddress,
    setOperationTypeId,
    setProduct,
    addOns,
    error: streetAddressError,
    isFetchingCoords,
    clearLocationCenterData,
    setFacility,
    outZoneError,
    clearOutZoneError,
  } = useLocationCenter();
  const { products, isFetchingProducts, isFetchingAddons } = locationCenters;
  const isAppointmentFormDisabled = customerId === undefined;
  const [showMobileFacilities, setShowMobileFacilities] = useState(false);

  const timeSlots = useMemo(() => {
    if (scheduledDate && operationTypeId) {
      const scheduledDate = appointmentForm.getFieldValue("date");

      return generateHourlyTimeslotsAfterNHours(
        0,
        scheduledDate,
        operationTypeId === 1
      );
    }
    return [];
  }, [scheduledDate, operationTypeId]);

  useEffect(() => {
    if (
      outZoneError !== undefined &&
      operationTypeId === CalenderOperationType.Mobile
    ) {
      setShowMobileFacilities(true);
    }
  }, [outZoneError]);

  useEffect(() => {
    if (visible && selectedGuest) {
      guestSearchForm.setFieldsValue({
        customerId: selectedGuest?.customerId,
      });
      popululateCustomerDetails(selectedGuest?.customerId);
      populateCustomerGiftCards(selectedGuest?.customerId);
    } else if (visible === false) {
      dispatch(setSelectedGuest());
    }
  }, [visible]);

  useEffect(() => {
    if (streetAddressError) appointmentForm.validateFields(["serviceaddress"]);
  }, [streetAddressError]);
  useEffect(() => {
    appointmentForm.setFieldsValue({
      facilityId,
    });
  }, [facilityId]);

  const callCreateOrder = async () => {
    const values = appointmentForm.getFieldsValue(true);
    const isMobileBooking = checkIsMobileBooking(values);
    const customerId = guestSearchForm.getFieldValue("customerId");

    if (
      currentUser?.roleName === "Nurse" &&
      !currentUser?.facilityIds?.includes(values?.facilityId)
    ) {
      openNotificationWithIcon(
        "error",
        "Error",
        "Selected facility is not assigned to the nurse."
      );
      return;
    }
    if (!customerId) {
      openNotificationWithIcon(
        "error",
        "Error",
        "Select a guest to book an appointment"
      );
      return;
    }
    if (!selectedPaymentMethod) {
      openNotificationWithIcon(
        "error",
        "Error",
        "Payment method is mandatory for booking a service. "
      );
      return;
    }

    if (values.operationTypeId !== CalenderOperationType.Clinic) {
      dispatch(setLoading(true));
      let address =
        values.operationTypeId === CalenderOperationType.Mobile
          ? values.serviceAddress
          : `USA ${values.zipCode}`;
      const { state, country, zipCode } =
        (await getStateAndCountryFromZip(address)) ?? {};

      if (state && country) {
        values.state = state || "";
        values.country = country || "";
        values.zipCode = zipCode || "";
        dispatch(setLoading(false));
      } else {
        dispatch(setLoading(false));
        openNotificationWithIcon(
          "error",
          "Invalid Address/ZIP Code",
          "Unable to find the address. Please enter a valid address."
        );
        return;
      }
    }

    if (!customerDetails) {
      openNotificationWithIcon(
        "error",
        "Error",
        "Customer details loading in progress. Please wait a moment."
      );
      return;
    }

    if (
      customerDetails?.isPhysicalHistoryExpired ||
      customerDetails?.isConsentExpired
    ) {
      Modal.confirm({
        title: "Expired intake forms",
        content: (
          <div>
            <Text>
              This guest has expired intake forms. Click OK to continue booking
              and send a reminder to the guest.
            </Text>
            <br />
            <Link
              onClick={() => {
                sendCreateRequest(values);
                Modal.destroyAll();
              }}
              style={{
                fontFamily: '"tradeGothic", sans-serif',
                fontSize: 16,
              }}
            >
              Continue without sending reminder email
            </Link>
          </div>
        ),
        centered: true,
        maskClosable: true,
        afterClose: () => {},
        onOk: async (close) => {
          close();
          await dispatch(intakeFormEmailReminder(customerId));
          sendCreateRequest(values);
        },
        onCancel(close) {
          close();
        },
      });
      return;
    } else if (
      customerDetails?.intakeFormSubmitted === false ||
      customerDetails?.isConsentFormSubmitted === false
    ) {
      Modal.confirm({
        title: "Intake Forms Missing",
        content: (
          <div>
            <Text>
              This guest has missing intake forms. Click OK to continue booking
              and send a reminder to the guest.
            </Text>
            <br />
            <Link
              onClick={() => {
                sendCreateRequest(values);
                Modal.destroyAll();
              }}
              style={{
                fontFamily: '"tradeGothic", sans-serif',
                fontSize: 16,
              }}
            >
              Continue without sending reminder email
            </Link>
          </div>
        ),
        centered: true,
        maskClosable: true,
        afterClose: () => {},
        onOk: async (close) => {
          close();
          await dispatch(intakeFormEmailReminder(customerId));
          sendCreateRequest(values);
        },
        onCancel(close) {
          close();
        },
      });
      return;
    }

    sendCreateRequest(values);
  };

  async function sendCreateRequest(values) {
    const customer = guests?.data?.find?.(
      (guest) => guest.customerId === customerId
    );
    values.customerId = customerId;

    // let availedRewards = [];
    // const mainServiceRewardId = getRewardId(
    //   rewards?.data ?? [],
    //   values,
    //   availedRewards
    // );
    // if (mainServiceRewardId) availedRewards.push(mainServiceRewardId);

    values.serviceDateUTC = moment(values.date.format("L") + " " + values.time)
      .utc()
      .format();
    values.serviceDate = moment(values.date.format("L") + " " + values.time)
      .local()
      .format("YYYY-MM-DDTHH:mm:ss[Z]");
    values.lineItems = [
      {
        productId: values.productId,
        addOns:
          values.addOns?.map((addon) => {
            // const rewardId = getRewardId(
            //   rewards?.data ?? [],
            //   { productId: addon },
            //   availedRewards
            // );
            // if (rewardId) availedRewards.push(rewardId);

            return {
              ...(addOns.find((addonItem) => addonItem.productId === addon) ?? {
                productId: addon,
              }),
              // rewardId,
              productType: 2,
            };
          }) ?? [],
        firstName: customer?.firstName || "",
        lastName: customer?.lastName || "",
        // rewardId: mainServiceRewardId,
      },
    ];
    values.paymentMethodId = selectedPaymentMethod;
    if (currentUser?.roleName === "Nurse") {
      values.staffId = currentUser.staffId;
    }
    dispatch(setLoading(true));

    dispatch(setLoading(false));
    dispatch(createBooking(values)).then((result) => {
      if (result?.status === "success") {
        appointmentForm.resetFields();
        onClose();
      }
    });
  }

  const createOrderConfirmation = () => ({
    title: "Create order",
    content: (
      <>
        This Order has restrictions, Are you sure that you want to create this
        order?
      </>
    ),
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      callCreateOrder();
      close();
    },
    onCancel(close) {
      close();
    },
  });

  return (
    <Drawer
      visible={visible}
      onClose={() => {
        onClose();
      }}
      text={"New Appointment"}
      width={"min(100vw, 600px)"}
      className="appointmentDrawer"
      destroyOnClose={true}
    >
      <Form
        form={guestSearchForm}
        layout="vertical"
        style={{
          display: "flex",
          flexDirection: screens.md ? "row" : "column",
          justifyContent: "space-between",
          alignItems: screens.md ? "flex-end" : "flex-start",
          borderBottom: "2px solid #EFF1F4",
          paddingBottom: 20,
        }}
        preserve={false}
      >
        <FormSelect
          formItemStyles={{
            marginBottom: screens.md ? 0 : 16,
            marginRight: screens.md ? 48 : 0,
          }}
          disabled={!!selectedGuest}
          isSearch
          // allowClear={false}
          autoClearSearchValue={false}
          label="Search Guest"
          variant="outlined"
          name="customerId"
          placeholder="Enter a Guest Name"
          options={guests.data}
          loading={guests.isLoading}
          renderValue="customerId"
          renderLabel="name"
          showSearch={true}
          searchValue={guestSearch}
          onSearch={(value) => {
            setGuestSearch(value);
          }}
          optionLabelProp="label"
          onChange={async (value) => {
            popululateCustomerDetails(value);
            populateCustomerGiftCards(value);
            populateWalletCredits(value);
          }}
          notFoundContent={
            guests.isLoading ? <>Searching</> : <>No Data Found</>
          }
          filterOption={(input, option) => {
            const fullName = option.label.toLowerCase();
            const searchValue = input.toLowerCase();
            return fullName.includes(searchValue);
          }}
          renderOption={(opt) => {
            return (
              <div>
                <Avatar icon={<UserOutlined />} style={{ marginRight: 6 }} />
                {opt["name"]}
              </div>
            );
          }}
          onPopupScroll={(event) => {
            const target = event.target;
            if (
              target.scrollTop + target.clientHeight ===
              target.scrollHeight
            ) {
              getData(
                guests.currentPage + 1,
                "FirstName",
                true,
                guestSearch,
                true
              );
            }
          }}
        />
        <Button
          onClick={addGuestClick}
          rounded
          style={{ height: 50, fontSize: 14, fontWeight: 700 }}
        >
          Add New Guest
        </Button>
      </Form>
      <Form
        preserve={true}
        form={appointmentForm}
        layout="vertical"
        requiredMark={false}
        style={{ paddingTop: 16 }}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          guestSearchForm.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
        onFinish={
          async (values) => {
            const customerId = guestSearchForm.getFieldValue("customerId");
            let dateTime = moment(
              values.date.format("L") + " " + values.time ?? ""
            ).format();

            const valuesToUpdate = [
              {
                lineItems: [
                  {
                    productId: values.productId,
                    firstName: "string",
                    lastName: "string",
                    addOns:
                      values.addOns?.map?.((addOn) => ({
                        productId: addOn,
                        productType: 2,
                      })) ?? [],
                  },
                ],
                customerId: customerId,
                // facilityId: values.facilityId,
                // timeZone: "Alaskan Standard Time",
                serviceDate: dateTime,
              },
            ];

            if (!customerDetails) {
              openNotificationWithIcon(
                "warning",
                "Customer Details Loading",
                "Please wait while customer information is loading"
              );
              return;
            }
            dispatch(validateRestriction(valuesToUpdate, facilityId)).then(
              (result) => {
                if (!result.mstatus) {
                  Modal.confirm(createOrderConfirmation());
                } else {
                  callCreateOrder();
                }
              }
            );
            // if (
            //   customerDetails?.isPhysicalHistoryExpired ||
            //   customerDetails?.isConsentExpired
            // ) {
            //   Modal.confirm({
            //     title: "Expired intake forms",
            //     content:
            //       "This guest has expired intake forms. Click OK to continue booking and send a reminder to the guest.",
            //     centered: true,
            //     maskClosable: true,
            //     onOk: async (close) => {
            //       close();

            //     },
            //     onCancel(close) {
            //       close();
            //     },
            //   });
            //   return;
            // } else {
            //   dispatch(validateRestriction(valuesToUpdate, facilityId)).then(
            //     (result) => {
            //       if (!result.mstatus) {
            //         Modal.confirm(createOrderConfirmation());
            //       } else {
            //         callCreateOrder();
            //       }
            //     }
            //   );
            // }
          }
          // setSubmitOnFinish(true);
        }
      >
        <Row gutter={[24, 16]}>
          <Col xs={24} md={12}>
            <FormSelect
              required
              variant="underlined"
              formItemStyles={{
                marginBottom: 0,
              }}
              // disabled={isFetchingCoords}
              // suffixIcon={isFetchingOperationTypes ? <Spinner /> : undefined}
              name={"operationTypeId"}
              options={[
                { operationTypeId: 2, operationTypeName: "Clinic" },
                { operationTypeId: 1, operationTypeName: "Mobile" },
                { operationTypeId: 3, operationTypeName: "Telehealth" },
              ]}
              label={"Service Type"}
              placeholder={"Select Type"}
              renderLabel="operationTypeName"
              renderValue="operationTypeId"
              // loading={isFetchingCenters}
              onChange={(value) => {
                setOperationTypeId(value);
                clearOutZoneError();
                setScheduledDate();
                appointmentForm.setFieldsValue({
                  productId: undefined,
                  addOns: undefined,
                  guestsNo: undefined,
                  date: undefined,
                  time: undefined,
                });
                clearLocationCenterData();
                const serviceAddress =
                  appointmentForm.getFieldValue("serviceAddress");

                if (value === 1 && serviceAddress) {
                  setStreetAddress(serviceAddress, value);
                }
                // if (value === 3) dispatch(getAllSkills(false));
              }}
            />
          </Col>

          {operationTypeId === 1 && (
            <Col xs={24} md={12}>
              <LocationDropdown
                required
                className={"flatFormItem underlined addressLocation"}
                name={"serviceAddress"}
                form={appointmentForm}
                guests
                formItemStyles={{
                  marginBottom: 0,
                }}
                // loading={isFetchingCoords}
                onChange={(e) => {
                  clearLocationCenterData();
                  appointmentForm.setFieldsValue({
                    // facilityId: undefined,
                    // operationTypeId: undefined,
                    productId: undefined,
                    addOns: undefined,
                    guestsNo: undefined,
                  });

                  if (streetAddressError)
                    appointmentForm.validateFields(["serviceaddress"]);
                  // const add = addItemFunctionRef.current;
                  // add(0);
                  // setLocation(value);
                  // setLocationId(value);
                  // // if (streetAddressError)
                  // //   form.validateFields(["serviceaddress"]);
                }}
                onBlur={(e) => {
                  setStreetAddress(e.target.value, operationTypeId);
                }}
                // selectOnChange={(e) => {
                //   console.log("select Blur triggered", e.target.value);
                //   // setStreetAddress(e.target.value, operationTypeId);
                // }}
                rules={[
                  {
                    required: true,
                    message: "*Required",
                  },
                  {
                    message: "Invalid Street Address",
                    validator: (_, value) => {
                      if (streetAddressError) {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                round
                label={"Service Address"}
              />
            </Col>
          )}
          {(operationTypeId === 2 ||
            operationTypeId === undefined ||
            operationTypeId === null) && (
            <Col xs={24} md={12}>
              <FormSelect
                required
                variant="underlined"
                name={"facilityId"}
                renderLabel="facilityName"
                renderValue={"facilityId"}
                formItemStyles={{
                  marginBottom: 0,
                }}
                // // options={facilities ?? []}
                options={clinicFacilities}
                label={"Facility"}
                placeholder={"Select Facility"}
                emptyMessage="No Facility Available"
                onChange={(value) => {
                  setFacility(value);
                  appointmentForm.setFieldsValue({
                    productId: undefined,
                    addOns: undefined,
                    guestsNo: undefined,
                    date: undefined,
                    time: undefined,
                  });
                  // dispatch(setTimer(moment().add(15, "minutes")));
                }}
                rules={[
                  {
                    required: true,
                    message: false,
                  },
                ]}
              />
            </Col>
          )}
          <FormInput rules={[]} type="text" name="facilityId" hidden={true} />

          {operationTypeId === 3 && (
            <Col xs={24} md={12}>
              <FormInput
                max={5}
                variant="underlined"
                label="ZIP Code"
                placeholder="ZIP Code"
                type="text"
                name="zipCode"
                required={true}
                formItemStyles={{
                  margin: 0,
                }}
                onBlur={(e, v) => {
                  const zipCode = e.target.value;
                  clearLocationCenterData();
                  setStreetAddress(zipCode, 3);
                }}
              />
            </Col>
          )}
          <FormInput rules={[]} type="text" name="facilityId" hidden={true} />
          <Col xs={24} md={12}>
            <FormSelect
              // disabled={isAppointmentFormDisabled}
              variant="underlined"
              name="productId"
              label="Services"
              placeholder="Services"
              required={true}
              formItemStyles={{ marginBottom: 0 }}
              options={products}
              loading={isFetchingProducts}
              renderLabel="productName"
              renderValue="productId"
              onChange={(value) => {
                appointmentForm.setFieldsValue({
                  addOns: undefined,
                });
                setProduct(value);
              }}
              showSearch={true}
              optionLabelProp="label"
              filterOption={(input, option) => {
                return option?.label
                  ?.toLowerCase?.()
                  .includes?.(input.toLowerCase());
              }}
            />
            {/* <FormSelect
                // disabled={isAppointmentFormDisabled}
                mode="multiple"
                variant="underlined"
                name="skillIds"
                label="Skills"
                placeholder="Skills"
                required={true}
                loading={isLoadingSkills}
                formItemStyles={{ marginBottom: 0 }}
                options={skills}
                renderLabel="skillName"
                renderValue="skillId"
                showSearch={true}
                optionLabelProp="label"
                filterOption={(input, option) => {
                  return option?.label
                    ?.toLowerCase?.()
                    .includes?.(input.toLowerCase());
                }}
              /> */}
          </Col>
          <Col xs={24} md={12}>
            <FormSelect
              // disabled={isAppointmentFormDisabled}
              variant="underlined"
              name="addOns"
              label="Add-ons"
              placeholder="Add-ons"
              // required={true}
              formItemStyles={{ marginBottom: 0 }}
              loading={isFetchingAddons}
              options={addOns}
              renderLabel="productName"
              renderValue="productId"
              mode="multiple"
              optionLabelProp="label"
              filterOption={(input, option) => {
                return option?.label
                  ?.toLowerCase?.()
                  .includes?.(input.toLowerCase());
              }}
            />
          </Col>

          <Col xs={12} md={12}>
            <FormDatePicker
              form={appointmentForm}
              // disabled={isAppointmentFormDisabled}
              name="date"
              label="Select Date"
              variant="underlined"
              formItemStyles={{ marginBottom: 0 }}
              disabledDate={(current) =>
                current.isBefore(moment().subtract(1, "day"))
              }
              onChange={(value, date) => {
                setScheduledDate(value);
              }}
              required
            />
          </Col>
          <Col xs={12} md={12}>
            <FormSelect
              // disabled={isAppointmentFormDisabled}
              variant="underlined"
              name="time"
              label="Select Time"
              placeholder="Select Time"
              required={true}
              renderLabel="label"
              renderValue="value"
              formItemStyles={{ marginBottom: 0 }}
              options={timeSlots}
              rules={[
                {
                  required: true,
                  message: "Required",
                },
                // {
                //   message: "Invalid Time Slot.",
                //   validator: (_, time) => {
                //     const operationTypeId =
                //       appointmentForm.getFieldValue("operationTypeId");
                //     let date = appointmentForm.getFieldValue("date");
                //     const serviceDate = moment(date.format("L") + " " + time);
                //     const hoursGap2 = moment().add(0, "hours");

                //     if (serviceDate.isAfter(hoursGap2))
                //       return Promise.resolve();
                //     else return Promise.reject();
                //   },
                // },
              ]}
            />
          </Col>
        </Row>
        {/* <Row gutter={[24, 16]} style={{ padding: "12px 0px", paddingTop: 24 }}>
          <Col md={24}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Title level={4} style={{ fontSize: 17 }}>
                Service Address
              </Title>
              <AntdButton type="text">Change</AntdButton>
            </div>
          </Col>
        </Row> */}
        <Row gutter={[24, 16]} style={{ padding: "12px 0px", paddingTop: 24 }}>
          {!!customerDetails?.hasMembership && (
            <Col xs={24}>
              <MembershipStatus
                hasMembership={customerDetails?.hasMembership}
              />
            </Col>
          )}
          <Col xs={24}>
            <GiftCardRow
              credit={credit?.data ?? 0}
              isCreditLoading={credit?.isLoading}
              paymentMethod={paymentMethods?.find?.(
                (paymentMethod) =>
                  selectedPaymentMethod === paymentMethod?.paymentMethodId
              )}
              refreshGiftCards={() => {
                populateCustomerGiftCards(customerId);
                populateCustomerGiftCards(customerId);
              }}
              customer={customerDetails}
              customerId={customerId}
              loading={giftCards.isLoading}
              giftCards={giftCards.data ?? []}
              refetchGiftCards={() => {}}
              onChange={() => {}}
              onSave={() => {}}
              refreshCredits={() => populateWalletCredits(customerId)}
            />
          </Col>
          <Col xs={24} md={24}>
            <MiniPaymentRow
              customerId={customerId}
              loading={fetchingPaymentMethods}
              paymentMethods={paymentMethods}
              paymentMethod={paymentMethods?.find?.(
                (paymentMethod) =>
                  selectedPaymentMethod === paymentMethod?.paymentMethodId
              )}
              refetchPaymentMethods={(paymentMethodId) => {
                getCustomerPaymentMethodsLists(customerId, paymentMethodId);
              }}
              onChange={setSelectedPaymentMethod}
            />
          </Col>
          <Col xs={24}>
            <ProgramRow
              customerId={customerId}
              paymentMethodId={selectedPaymentMethod}
            />
          </Col>
        </Row>
        <Row gutter={[24, 16]} style={{ paddingTop: 16 }}>
          {currentUser?.roleName !== "Nurse" && (
            <Col xs={24} md={12}>
              <FormSelect
                className={"searchWithIcon"}
                // disabled={isAppointmentFormDisabled}
                variant="underlined"
                name="staffId"
                label="Assign Clinician"
                placeholder="Select Clinician"
                rules={[
                  {
                    message:
                      "Select a clinician that belongs to the selected facility",
                    validator: (_, value) => {
                      if (value === undefined || facilityId === undefined) {
                        return Promise.resolve();
                      }
                      const staff = wholeStaff.find(
                        (staff) => staff.staffId === value
                      );
                      if (staff.facilityIds?.includes(facilityId))
                        return Promise.resolve();
                      else return Promise.reject();
                    },
                  },
                ]}
                // required={true}
                formItemStyles={{ marginBottom: 0 }}
                options={wholeStaff.filter((staff) => {
                  return (
                    ((facilityId
                      ? staff?.facilityIds?.includes(facilityId)
                      : staff?.facilityIds?.includes(data?.facilityId)) &&
                      staff?.isActive &&
                      isNurseRole(staff, allRoles)) ||
                    (staff.roleName === "Admin" && staff.isActive)
                  );
                })}
                renderLabel="name"
                renderValue="staffId"
                optionLabelProp="label"
                showSearch={true}
                filterOption={(input, option) => {
                  return option?.label
                    ?.toLowerCase?.()
                    .includes?.(input.toLowerCase());
                }}
                renderOption={(opt) => {
                  return (
                    <div>
                      <Avatar
                        src={opt?.profileImageUrl}
                        icon={<UserOutlined />}
                        style={{ marginRight: 6 }}
                      />
                      {opt["name"]}
                    </div>
                  );
                }}
              />
            </Col>
          )}
          {/* <Col xs={24} md={24}>
            <FormInputArea
              name='noteDescription'
              // disabled={isAppointmentFormDisabled}
              variant="underlined"
              label="Notes"
              placeholder={"Notes"}
              formItemStyles={{
                marginBottom: 0,
              }}
            />
          </Col> */}
          <Col xs={24} md={24}>
            <FormInputArea
              name="note"
              // disabled={isAppointmentFormDisabled}
              variant="underlined"
              label="Address Instructions"
              placeholder={"Add instructions"}
            />
          </Col>
        </Row>

        <FormHiddenSubmitButton submitRef={submitBtnRef} />
      </Form>
      <Row>
        <Col xs={24} md={24}>
          <Collapse
            expandIconPosition={"end"}
            style={{
              background: "#fff",
              border: "none",
              boxShadow: "none",
              padding: 0,
            }}
          >
            <Collapse.Panel
              key="notes"
              style={{ background: "#fff", boxShadow: "none" }}
              header={
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: 16,
                    fontFamily: "tradeGothic",
                    fontWeight: "bold",
                    color: "#000000",
                  }}
                >
                  Notes
                </p>
              }
            >
              <NotesGrid
                form={notesForm}
                customerId={customerId}
                isEditable={false}
              />
            </Collapse.Panel>
          </Collapse>
        </Col>
      </Row>
      <Row gutter={[24, 16]}>
        <Col
          xs={24}
          md={24}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          <AntdButton
            type="text"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </AntdButton>
          <Button
            rounded
            // htmlType={"submit"}
            style={{ height: 50, fontWeight: 700 }}
            onClick={() => {
              submitBtnRef?.current?.click();
            }}
          >
            Book Now
          </Button>
        </Col>
      </Row>
      <MobileFacilityPopup
        facilities={mobileFacilities}
        visible={showMobileFacilities}
        onSave={(facilityId) => {
          setFacility(facilityId);
          setShowMobileFacilities(false);
          clearOutZoneError();
        }}
        onClose={() => {
          clearOutZoneError();
          setShowMobileFacilities(false);
        }}
      />
    </Drawer>
  );
}

AppointmentDrawer.propTypes = {};

export default AppointmentDrawer;
