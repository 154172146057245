export const actions = {
  SET_ALL_PENDING_BOOKINGS: "SET_ALL_PENDING_BOOKINGS",
  SET_MONTHLY_BOOKINGS: "SET_MONTHLY_BOOKINGS",
  SET_WEEKLY_BOOKINGS: "SET_WEEKLY_BOOKINGS",
  SET_DAILY_BOOKINGS: "SET_DAILY_BOOKINGS",
  SET_CURRENT_BOOKING: "SET_CURRENT_BOOKING",
  ASSIGN_NURSE_TO_BOOKING: "ASSIGN_NURSE_TO_BOOKING",
  ADD_BOOKING: "ADD_BOOKING",
  UPDATE_BOOKING: "UPDATE_BOOKING",
};

export const setAllPendingBookings = (data) => ({
  type: actions.SET_ALL_PENDING_BOOKINGS,
  data,
});

export const setDailyBookings = (data) => ({
  type: actions.SET_DAILY_BOOKINGS,
  data
})

export const setWeeklyBookings = (data) => ({
  type: actions.SET_WEEKLY_BOOKINGS,
  data,
});

export const setMonthlyBookings = (data) => ({
  type: actions.SET_MONTHLY_BOOKINGS,
  data,
});

export const setCurrentBooking = (data) => ({
  type: actions.SET_CURRENT_BOOKING,
  data,
});

export const assignNurseToBooking = (booking, staffId) => ({
  type: actions.ASSIGN_NURSE_TO_BOOKING,
  data: {
    staffId: staffId,
    booking: booking,
  },
});

export const addBooking = (data) => ({
  type: actions.ADD_BOOKING,
  data,
});

export const updateBooking = (data) => ({
  type: actions.UPDATE_BOOKING,
  data,
});
