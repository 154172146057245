import React from "react";
import PropTypes from "prop-types";

import { Drawer as AntdDrawer } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { ReactComponent as CloseIcon } from "assets/icons/closeIco.svg";

function SidebarDrawer(props) {
  const {
    text,
    onClose,
    visible,
    footer,
    children,
    width,
    className,
    size,
    destroyOnClose = false,
  } = props;
  return (
    <AntdDrawer
      className={`mobileSideDrawer ${className}`}
      width={"min(80vw,320px)"}
      headerStyle={{ border: "none" }}
      size={size}
      placement={"left"}

      onClose={onClose}
      visible={visible}
      closable={false}
      footer={footer}
      destroyOnClose={destroyOnClose}
    >
      {children}
    </AntdDrawer>
  );
}

SidebarDrawer.propTypes = {};

export default SidebarDrawer;
