import React, { useEffect, useRef } from "react";
import { Col, Drawer, Form, Row, Button as AntdButton } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import {
  FormInput,
  FormHiddenSubmitButton,
  FormSelect,
} from "components/FormItems/FlatFormItems";
import { useForm } from "antd/lib/form/Form";
import { Button } from "components/Buttons/Buttons";
import { useDispatch } from "react-redux";
import { getProductContraindicationById } from "services/productContraindications.service";

export default function ProductContraindicationDrawer({
  data,
  visible,
  onClose,
  onSuccess,
  services,
  isServicesLoading,
  contraindications,
  isContraindicationsLoading,
}) {
  const [form] = useForm();
  const submitBtnRef = useRef();

  const dispatch = useDispatch();

  const productId = Form.useWatch("productId", form);
  const contraindicationId = Form.useWatch("contraindicationId", form);

  const isEdit = data?.productContraindicationId !== undefined;

  const getData = async (id) => {
    const result = await dispatch(getProductContraindicationById(id));
    if (result?.status === "success") {
      form.setFieldsValue({
        ...result.data,
      });
    }
  };

  useEffect(() => {
    if (visible === false) {
      form.resetFields();
    } else if (isEdit) {
      getData(data?.productContraindicationId);
      //
    }
  }, [visible]);

  return (
    <Drawer
      destroyOnClose
      headerStyle={{ border: "none" }}
      className="categoriesDrawer"
      width={"min(100vw, 420px)"}
      title={
        <div className="categoriesDrawerTitle">
          <p>{visible && (isEdit ? "Edit" : "Create")} Contraindication Rule</p>
          <CloseOutlined onClick={onClose} />
        </div>
      }
      placement="right"
      onClose={onClose}
      visible={visible}
      closable={false}
      footer={
        <div className="categoriesDrawerFooter">
          <AntdButton type="text" onClick={onClose}>
            Cancel
          </AntdButton>
          <Button
            rounded
            type={"primary"}
            style={{ height: 50, width: "50%" }}
            onClick={() => submitBtnRef?.current?.click()}
          >
            Save
          </Button>
        </div>
      }
    >
      <Form
        preserve={false}
        layout="vertical"
        form={form}
        requiredMark={false}
        onFinish={(values) => {
          onSuccess(values);
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
      >
        <Row gutter={[24, 24]}>
          <Col xs={24}>
            <FormSelect
              variant="underlined"
              name="productId"
              label="Select Service"
              placeholder="Select Service"
              formItemStyles={{ marginBottom: 0 }}
              loading={isServicesLoading}
              filterOption={(input, option) => {
                return option?.label
                  ?.toLowerCase?.()
                  .includes?.(input.toLowerCase());
              }}
              options={services?.filter((it) => {
                return it?.isActive || productId === it?.productId;
              })}
              // isOptionDisabled={(it) => !it.isActive}
              renderLabel="productName"
              renderValue="productId"
              optionLabelProp="label"
              //   mode="multiple"
            />
          </Col>
          <Col xs={24}>
            <FormSelect
              variant="underlined"
              name="contraindicationId"
              label="Select Contraindications"
              placeholder="Select Contraindications"
              formItemStyles={{ marginBottom: 0 }}
              filterOption={(input, option) => {
                return option?.label
                  ?.toLowerCase?.()
                  .includes?.(input.toLowerCase());
              }}
              options={contraindications?.filter((it) => {
                return (
                  it?.isActive ||
                  contraindicationId?.includes?.(it.contraindicationId)
                );
              })}
              loading={isContraindicationsLoading}
              // isOptionDisabled={(it) => !it.isActive}
              renderLabel="contraindicationName"
              renderValue="contraindicationId"
              optionLabelProp="label"
              mode={isEdit ? "" : "multiple"}
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <FormHiddenSubmitButton submitRef={submitBtnRef} />
        </Row>
      </Form>
    </Drawer>
  );
}
