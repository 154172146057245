import { actions } from "../actions/user.actions";

const INITIAL_STATE = {
  isTokenVerifying: true,
  currentUser: null,
  allDistributors: [],
  currentDistributor: null,
  allSubDistributors: [],
  currentSubDistributor: null,
  // TODO: DEAL WITH THIS allStaff and wholeStaff when backend pagination is implemented
  // All staff is only being used in Resrouce Scheduler ATM
  allStaff: [], // specific to facility
  wholeStaff: [], // staff of whole app
  currentStaff: null,
  allGuests: [],
  currentGuest: null,
  userNotes: [],
};

const reducer = (state = INITIAL_STATE, data) => {
  switch (data.type) {
    case actions.SET_CURRENT_USER:
      return { ...state, currentUser: data.data, isTokenVerifying: false };
    case actions.SET_ALL_DISTRIBUTORS:
      return { ...state, allDistributors: data.data };
    case actions.SET_CURRENT_DISTRIBUTOR:
      return { ...state, currentDistributor: data.data };
    case actions.SET_ALL_SUB_DISTRIBUTORS:
      return { ...state, allSubDistributors: data.data };
    case actions.SET_CURRENT_SUB_DISTRIBUTOR:
      return { ...state, currentSubDistributor: data.data };
    case actions.SET_ALL_STAFF: // STAFF BY FACILITY
      return { ...state, allStaff: data.data };
    case actions.SET_WHOLE_STAFF: // STAFF BY WHOLE APP
      return { ...state, wholeStaff: data.data };
    case actions.SET_CURRENT_STAFF:
      return { ...state, currentStaff: data.data };
    case actions.SET_ALL_GUESTS:
      return { ...state, allGuests: data.data };
    case actions.UPDATE_GUEST:
      return {
        ...state,
        allGuests: state?.allGuests.map((guest) => {
          if (data?.data?.customerId === guest?.customerId) {
            return { ...guest, ...data?.data };
          }
          return guest;
        }),
      };
    case actions.SET_CURRENT_GUEST:
      return { ...state, currentGuest: data.data };
    case actions.SET_USER_NOTES:
      return { ...state, userNotes: data.data };
    case actions.ADD_GUEST:
      return { ...state, allGuests: [...state.allGuests, data.data] };
    case actions.ADD_STAFF:
      return {
        ...state,
        allStaff: [...state.allStaff, data.data],
        wholeStaff: [...state.wholeStaff, data.data],
      };
    case actions.CLEAR_ALL_STAFF:
      return {
        ...state,
        allStaff: [],
      };
    case "RESET":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
