export const actions = {
  SET_PROFILE_DRAWER: "SET_PROFILE_DRAWER",
  SET_APPOINTMENT_DRAWER: "SET_APPOINTMENT_DRAWER",
};

export const setProfileDrawer = (data) => ({
  type: actions.SET_PROFILE_DRAWER,
  data,
});

export const setAppointmentDrawer = (data) => ({
  type: actions.SET_APPOINTMENT_DRAWER,
  data,
});
