import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Col,
  Drawer,
  Form,
  Row,
  Typography,
  Button as AntdButton,
  Upload,
  Space,
  Divider,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import {
  FormInput,
  FormInputArea,
  FormHiddenSubmitButton,
  FormPhoneNumber,
  FormSelect,
  FormCheckbox,
  FormInputNumber,
} from "components/FormItems/FlatFormItems";
import { useForm } from "antd/lib/form/Form";
import { Button } from "components/Buttons/Buttons";
import FilesUploader from "components/FilesUploader/FilesUploader";
import PhotoUpload from "components/PhotoUpload/PhotoUpload";
import userDp from "assets/images/user-dp.png";
import { useDispatch, useSelector } from "react-redux";
import { getServiceById } from "services/servicesAndBoostsSettings.service";
import { FormSwitch } from "components/FormItems/RoundedFormItems";

const { Title, Text } = Typography;
const { Dragger } = Upload;

export default function ServicesDrawer({
  visible,
  onClose,
  data,
  onSuccess,
  isTelehealth,
  skills = [],
  categories = [],
  boosts = [],
  taxCategories = [],
  accounts = [],
  isAccountsLoading = true,
}) {
  const [form] = useForm();
  const productCategoryIds = Form.useWatch("productCategoryIds", form);
  const addOnIds = Form.useWatch("addOnIds", form);
  const skillIds = Form.useWatch("productSkillIds", form);
  const submitBtnRef = useRef();
  const isEdit = !!data?.productId;

  const { allSkills } = useSelector((state) => ({
    allSkills: state.skills.allSkills,
  }));

  const [base64, setBase64] = useState("");
  const [file, setFile] = useState(null);

  const dispatch = useDispatch();

  const getData = async (id) => {
    const result = await dispatch(getServiceById(id));
    if (result?.status === "success") {
      form.setFieldsValue({
        ...result.data,
      });
      setBase64(result.data.url);
    }
  };

  useEffect(() => {
    if (visible === false) {
      form.resetFields();
      setBase64("");
    } else if (data?.productId !== undefined) {
      form.setFieldsValue({
        ...data,
      });
      setBase64(data.url);
      getData(data?.productId);
    } else {
      setBase64("");
    }
  }, [visible]);

  // const isTeleHealth = useMemo(() => {
  //   if (productCategoryIds)
  //     for (let categoryId of productCategoryIds) {
  //       const category = categories.find((it) => it.categoryId === categoryId);
  //       if (category?.categoryName === "TeleHealth") return true;
  //     }
  //   return false;
  // }, [productCategoryIds, categories]);

  return (
    <Drawer
      destroyOnClose
      headerStyle={{ border: "none" }}
      className="serviceDrawer"
      width={"min(100vw, 420px)"}
      title={
        <div className="drawerTitle">
          <p>
            {visible && (data?.productId !== undefined ? "Edit" : "Create")}{" "}
            {isTelehealth && "Telehealth "}
            Service
          </p>
          <CloseOutlined onClick={onClose} />
        </div>
      }
      placement="right"
      onClose={onClose}
      visible={visible}
      closable={false}
      footer={
        <div className="drawerFooter">
          <AntdButton type="text" onClick={onClose}>
            Cancel
          </AntdButton>
          <Button
            rounded
            type={"primary"}
            style={{ height: 50, width: "50%" }}
            onClick={() => submitBtnRef?.current?.click()}
          >
            Save
          </Button>
        </div>
      }
    >
      <Form
        preserve={false}
        layout="vertical"
        form={form}
        requiredMark={false}
        onFinish={(values) => {
          // values.productType = isTelehealth ? 3 : 1; // Service

          onSuccess({
            ...values,
            imageBase64String: base64?.split?.("base64,")?.[1],
            imageExt: file?.type?.split("/")[1],
          });
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
      >
        <Row gutter={[24, 16]}>
          <Col xs={24}>
            <FormInput type="hidden" name="url" />
            {visible && (
              <PhotoUpload
                type="Product"
                base64={base64}
                setBase64={setBase64}
                defaultImage={userDp}
                setFile={setFile}
                //   isEdit={currentStaff}
              />
            )}
          </Col>
          <Col xs={24}>
            <FormInput
              variant="underlined"
              name="productName"
              label="Service Name"
              placeholder="Enter service name"
              required
              formItemStyles={{ marginBottom: 0 }}
            />
          </Col>
          <Col xs={24}>
            <FormSelect
              disabled={isEdit}
              label="Service Type"
              name="productType"
              placeholder={"Select service type"}
              required
              formItemStyles={{ marginBottom: 0 }}
              variant="underlined"
              renderLabel="label"
              renderValue="value"
              options={[
                {
                  label: "Clinic/Mobile",
                  value: 1,
                },
                {
                  label: "Telehealth",
                  value: 3,
                },
              ]}
            />
          </Col>
          <Col xs={24}>
            <FormInputNumber
              variant="underlined"
              name="duration"
              label="Service Duration (minutes)"
              placeholder="Enter service duration"
              required
              min={0}
              formItemStyles={{ marginBottom: 0 }}
            />
          </Col>
          <Col xs={24}>
            <FormSelect
              variant="underlined"
              name="productCategoryIds"
              label="Service Category"
              placeholder="Select a category"
              mode="multiple"
              formItemStyles={{ marginBottom: 0 }}
              initialValue={[]}
              options={categories?.filter(
                (it) =>
                  it?.isActive || productCategoryIds?.includes?.(it?.categoryId)
              )}
              // isOptionDisabled={(it) => !it.isActive}
              filterOption={(input, option) => {
                return option?.label
                  ?.toLowerCase?.()
                  .includes?.(input.toLowerCase());
              }}
              renderLabel="categoryName"
              renderValue="categoryId"
              required
            />
          </Col>
          <Col xs={24}>
            <FormSelect
              variant="underlined"
              name="productTaxCategoryId"
              label="Tax Category"
              placeholder="Select a tax category"
              formItemStyles={{ marginBottom: 0 }}
              initialValue={[]}
              options={taxCategories}
              // isOptionDisabled={(it) => !it.isActive}
              filterOption={(input, option) => {
                return option?.label
                  ?.toLowerCase?.()
                  .includes?.(input.toLowerCase());
              }}
              renderLabel="taxCategoryName"
              renderValue="taxCategoryId"
              required
            />
          </Col>
          <Col xs={24}>
            <FormSelect
              variant="underlined"
              name="addOnIds"
              label="Select Add-ons"
              placeholder="Select Add-ons"
              formItemStyles={{ marginBottom: 0 }}
              filterOption={(input, option) => {
                return option?.label
                  ?.toLowerCase?.()
                  .includes?.(input.toLowerCase());
              }}
              options={boosts?.filter(
                (it) => it?.isActive || addOnIds?.includes?.(it?.productId)
              )}
              // isOptionDisabled={(it) => !it.isActive}
              renderLabel="productName"
              renderValue="productId"
              optionLabelProp="label"
              mode="multiple"
            />
          </Col>
          <Col xs={24}>
            <FormSelect
              mode={"multiple"}
              required
              feedBack={false}
              variant="underlined"
              name="productSkillIds"
              formItemStyles={{ marginBottom: 0 }}
              // loading={isFetchingFacilities}
              label="Skills"
              renderValue="id"
              placeholder={"Select Skills"}
              renderLabel="name"
              styles={{ border: "none" }}
              // initialValue={currentStaff?.skillIds}
              // formItemStyles={{ marginBottom: 12 }}
              options={allSkills?.filter(
                (it) =>
                  it?.isActive || data?.productSkillIds?.includes?.(it?.id)
              )}
              optionLabelProp="label"
              filterOption={(input, option) => {
                return option?.label
                  ?.toLowerCase?.()
                  .includes?.(input.toLowerCase());
              }}
              // styles={{ background: '#ddd' }}
            />
          </Col>
          <Col xs={24}>
            <FormSelect
              // mode={"multiple"}
              required
              feedBack={false}
              variant="underlined"
              name="accountId"
              // loading={isFetchingFacilities}
              label="Account"
              renderLabel="accountName"
              renderValue="accountId"
              placeholder={"Select Account"}
              styles={{ border: "none" }}
              // initialValue={currentStaff?.skillIds}
              // formItemStyles={{ marginBottom: 12 }}
              options={accounts}
              // optionLabelProp="label"
              // filterOption={(input, option) => {
              //   return option?.acountName
              //     ?.toLowerCase?.()
              //     .includes?.(input.toLowerCase());
              // }}
              loading={isAccountsLoading}
              // styles={{ background: '#ddd' }}
            />
          </Col>
          <Col xs={24}>
            <FormSwitch
              name="hasPreferenceCard"
              required
              label="Enable Fetch Preference Card"
              initialValue={false}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <FormHiddenSubmitButton submitRef={submitBtnRef} />
        </Row>
      </Form>
    </Drawer>
  );
}
