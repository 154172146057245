import React, { useEffect, useMemo, useRef, useState } from "react";
import { Checkbox, Form, Select, Dropdown, Empty } from "antd";
import Glutathione from "../../assets/images/Glutathione.svg";
import { CloseOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import SearchLocationInput from "components/LocationInput/SearchLocationInput";

import "./LocationDropdown.scss";
const CustomInput = ({ ...restProps }) => {
  return <input type="text" {...restProps} />;
};
const FormItem = Form.Item;
const Option = Select.Option;

const roundStyle = {
  border: "2px solid #DBDFEA",
  borderRadius: 14,
  height: 84,
  margin: 0,
  position: "relative",
  overflow: "hidden",
};
const flatStyle = {
  // border: "2px solid #DBDFEA",
  margin: 0,
  position: "relative",
  overflow: "hidden",
};
export default function LocationDropdown({
  form,
  name,
  onChange,
  className,
  label,
  round,
  initialValue,
  required = false,
  dependencies,
  rules,
  loading,
  onBlur,
  selectOnChange,
}) {
  return (
    <FormItem
      form
      style={flatStyle}
      label={label}
      className={`locationFormItem ${className} underlined`}
      name={name}
      initialValue={initialValue}
      rules={
        rules ?? [
          {
            required,
            message: "*Required",
          },
        ]
      }
      validateFirst={true}
      onBlur={onBlur}
      onChange={onChange}
      dependencies={dependencies}
    >
      <SearchLocationInput
        name={name}
        form={form}
        selectOnChange={selectOnChange ?? onChange}
        loading={loading}
      />
    </FormItem>
  );
}
