import React, { useEffect, useRef } from "react";
import {
  Col,
  Drawer,
  Form,
  Row,
  Button as AntdButton,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import {
  FormInput,
  FormHiddenSubmitButton,
} from "components/FormItems/FlatFormItems";
import { useForm } from "antd/lib/form/Form";
import { Button } from "components/Buttons/Buttons";
import { useDispatch } from "react-redux";

export default function CategoriesDrawer({
  data,
  visible,
  onClose,
  onSuccess,
}) {
  const [form] = useForm();
  const submitBtnRef = useRef();

  const dispatch = useDispatch();

  // const getData = async (id) => {
  //   const result = await dispatch(getCategoryById(id));
  //   if (result?.status === "success") {
  //     form.setFieldsValue({
  //       ...result.data,
  //     });
  //   }
  // };

  useEffect(() => {
    if (visible === false) {
      form.resetFields();
    } else if (data?.categoryId !== undefined) {
      form.setFieldsValue({
        ...data,
      });
      // getData(data?.categoryId);
    }
  }, [visible]);

  return (
    <Drawer
      destroyOnClose
      headerStyle={{ border: "none" }}
      className="categoriesDrawer"
      width={"min(100vw, 420px)"}
      title={
        <div className="categoriesDrawerTitle">
          <p>
            {visible && (data?.categoryId !== undefined ? "Edit" : "Create")}{" "}
            Category 
          </p>
          <CloseOutlined onClick={onClose} />
        </div>
      }
      placement="right"
      onClose={onClose}
      visible={visible}
      closable={false}
      footer={
        <div className="categoriesDrawerFooter">
          <AntdButton type="text" onClick={onClose}>
            Cancel
          </AntdButton>
          <Button
            rounded
            type={"primary"}
            style={{ height: 50, width: "50%" }}
            onClick={() => submitBtnRef?.current?.click()}
          >
            Save
          </Button>
        </div>
      }
    >
      <Form
        preserve={false}
        layout="vertical"
        form={form}
        requiredMark={false}
        onFinish={(values) => {
          onSuccess(values);
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
      >
        <Row gutter={[24, 24]}>
          <Col xs={24}>
            <FormInput
              name="categoryName"
              variant="underlined"
              label="Category Name"
              required
              placeholder="Category Name"
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <FormHiddenSubmitButton submitRef={submitBtnRef} />
        </Row>
      </Form>
    </Drawer>
  );
}
