import { actions } from "../actions/scheduler.actions";

const INITIAL_STATE = {
  selectedGuest: null,
  selectedAppointment: null,
};

const reducer = (state = INITIAL_STATE, { type, data }) => {
  switch (type) {
    case actions.SET_SELECTED_GUEST:
      return { ...state, selectedGuest: data };
    case actions.SET_SELECTED_APPOINTMENT:
      return { ...state, selectedAppointment: data };
    case "RESET":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
