import React, { useMemo } from "react";
import PropTypes from "prop-types";

import { useDispatch } from "react-redux";
import { Row, Col, Card, Typography } from "antd";
import { push, replace } from "connected-react-router";
import SettingCard from "components/SettingCard/SettingCard.index";
import settingRoutes from "utils/settingRoutes";

const { Title, Text } = Typography;

const SettingsMain = () => {
  const dispatch = useDispatch();
  const sortedSettingRoutes = useMemo(() => {
    const routes = [...settingRoutes];
    return routes.sort((a, b) => {
      // Converting to uppercase to have case-insensitive comparison
      const name1 = a.title.toUpperCase();
      const name2 = b.title.toUpperCase();

      let comparison = 0;

      if (name1 > name2) {
        comparison = 1;
      } else if (name1 < name2) {
        comparison = -1;
      }
      return comparison;
    });
  }, [settingRoutes]);

  return (
    <section className="settingsContainer">
      <Title
        level={3}
        style={{
          color: "#15213B",
          fontFamily: "tradeGothic",
          paddingTop: 11,
          paddingBottom: 10,
        }}
      >
        Settings
      </Title>
      <Row gutter={[24, 24]}>
        {sortedSettingRoutes
          .filter((route) => route.showCard !== false)
          .map((route, index) => {
            const { title, path, text } = route;
            return (
              <Col md={10} key={index}>
                <SettingCard
                  title={title}
                  onClick={() => dispatch(push(path))}
                  text={text}
                />
              </Col>
            );
          })}
      </Row>
    </section>
  );
};

export default SettingsMain;
