import { cloneDeep } from "lodash";
import moment from "moment";
import { setLoading } from "redux/actions/app.actions";
import { openNotificationWithIcon } from "utils/Notification";
import { DUMMY_CATEGORIES, DUMMY_SERVICES, setCategories } from "./data";
import { request } from "./verb.services";

export const getAllTriggerNotifications = (callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return (
      request("TriggerNotifications", "get", null, true, null)
        .then((res) => {
          return {
            status: "success",
            data: res.data,
          };
        })
        .catch((e) => {
          openNotificationWithIcon(
            "error",
            "Error!",
            e?.response?.data?.[0] ||
              e?.response?.data?.message ||
              e?.response?.message ||
              e?.message ||
              "Something went wrong"
          );
        })
        .finally(() => {
          dispatch(setLoading(false));
        })
    );
  };
};

export const getAllTriggers = (callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request("Triggers", "get", null, true, null)
      .then((res) => {
        return {
          status: "success",
          data: res.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getAllTemplates = (callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request("Templates", "get", null, true, null)
      .then((res) => {
        return {
          status: "success",
          data: res.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const updateNotificationTrigger = (id, data, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`TriggerNotifications/${id}`, "put", data, true, null, )
      .then(() => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Trigger notification updated successfully"
        );
        callback?.();
        return {
          status: "success",
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const createNotificationTrigger = (values, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`TriggerNotifications`, "post", values, true, null)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Notification trigger created successfully"
        );
        return { status: "success", data: res?.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const deleteNotificationTrigger = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`TriggerNotifications/${id}`, "delete", null, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Trigger Notification deleted successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

