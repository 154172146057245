import React, { useEffect, useRef } from "react";
import {
  Col,
  Drawer,
  Form,
  Row,
  Typography,
  Button as AntdButton,
  Upload,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import {
  FormInput,
  FormHiddenSubmitButton,
  FormCheckbox,
  FormDatePicker,
  FormTimePicker,
} from "components/FormItems/FlatFormItems";
import { useForm } from "antd/lib/form/Form";
import { Button } from "components/Buttons/Buttons";
import { useDispatch } from "react-redux";
import { getExclusionById } from "services/locationSettings.service";
import moment from "moment";

export default function ExclusionDrawer({ data, visible, onClose, onSuccess }) {
  const [form] = useForm();
  const submitBtnRef = useRef();

  const dispatch = useDispatch();

  const isClosed = Form.useWatch(`isClosed`, form);

  useEffect(() => {
    if (visible === false) {
      form.resetFields();
    } else if (data?.exclusionId !== undefined) {
      form.setFieldsValue({
        ...data,
        exclusionDate: !!data?.exclusionDate
          ? moment(data?.exclusionDate, "MM/DD/YYYY")
          : undefined,
        endTime: !!data?.endTime ? moment(data?.endTime, "HH:mm") : undefined,
        startTime: !!data?.startTime
          ? moment(data?.startTime, "HH:mm")
          : undefined,
      });
    }
  }, [visible]);

  return (
    <Drawer
      destroyOnClose
      headerStyle={{ border: "none" }}
      className="locationDrawer"
      width={"min(100vw, 420px)"}
      title={
        <div className="locationDrawerTitle">
          <p>
            {visible && (data?.exclusionId !== undefined ? "Edit" : "Create")}{" "}
            Exclusion Date
          </p>
          <CloseOutlined onClick={onClose} />
        </div>
      }
      placement="right"
      onClose={onClose}
      visible={visible}
      closable={false}
      footer={
        <div className="locationDrawerFooter">
          <AntdButton type="text" onClick={onClose}>
            Cancel
          </AntdButton>
          <Button
            rounded
            type={"primary"}
            style={{ height: 50, width: "50%" }}
            onClick={() => submitBtnRef?.current?.click()}
          >
            Save
          </Button>
        </div>
      }
    >
      <Form
        preserve={false}
        layout="vertical"
        form={form}
        requiredMark={false}
        onFinish={(values) => {
          const newValues = { ...values };
          if (!!values.startTime)
            newValues.startTime = values.startTime.format("HH:mm");
          if (!!values.endTime)
            newValues.endTime = values.endTime.format("HH:mm");
          if (!!values.exclusionDate)
            newValues.exclusionDate = values.exclusionDate.format("L");

          onSuccess(newValues);
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
      >
        <Row gutter={[24, 16]}>
          <Col xs={24}>
            <FormInput
              name="exclusionName"
              variant="underlined"
              label="Name"
              required
              placeholder="Name"
              formItemStyles={{
                marginBottom: 0,
              }}
            />
          </Col>
          <Col xs={24}>
            <FormDatePicker
              form={form}
              name="exclusionDate"
              label="Date"
              variant="underlined"
              formItemStyles={{ marginBottom: 0 }}
              rules={[
                {
                  required: true,
                  message: "Required",
                },
              ]}
            />
          </Col>
          <Col xs={24}>
            <FormTimePicker
              disabled={isClosed}
              variant="underlined"
              name={"startTime"}
              label="From"
              allowClear={false}
              initialValue={moment("9:00 am", "hh:mm a")}
              //   placeholder="Email Address"
              required={isClosed ? false : true}
              dependencies={["isClosed"]}
              formItemStyles={{ margin: 0 }}
            />
          </Col>
          <Col xs={24}>
            <FormTimePicker
              disabled={isClosed}
              variant="underlined"
              name={"endTime"}
              label="To"
              allowClear={false}
              initialValue={moment("5:00 pm", "hh:mm a")}
              //   placeholder="Email Address"
              formItemStyles={{ margin: 0 }}
              validateFirst={true}
              dependencies={["from", "isClosed"]}
              rules={[
                {
                  required: isClosed ? false : true,
                  message: "Required",
                },
                {
                  message: "Invalid",
                  validator: (_, value) => {
                    if (isClosed) {
                      return Promise.resolve();
                    }

                    const shiftStartValue = form.getFieldValue("startTime");
                    const shiftEndValue = form.getFieldValue("endTime");

                    if (
                      shiftStartValue >= shiftEndValue ||
                      shiftStartValue.format("LT") ===
                        shiftEndValue.format("LT")
                    ) {
                      return Promise.reject();
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            />
          </Col>
          <Col xs={24}>
            <FormCheckbox
              className="flatCheckbox"
              name={"isClosed"}
              formItemStyles={{ margin: 0 }}
              initialValue={false}
            >
              Closed All Day
            </FormCheckbox>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <FormHiddenSubmitButton submitRef={submitBtnRef} />
        </Row>
      </Form>
    </Drawer>
  );
}
