import React from "react";
import PropTypes from "prop-types";

import { Drawer as AntdDrawer } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { ReactComponent as CloseIcon } from "assets/icons/closeIco.svg";

function Drawer(props) {
  const {
    text,
    onClose,
    visible,
    footer,
    children,
    width,
    className,
    size,
    destroyOnClose = false,
  } = props;
  return (
    <AntdDrawer
      className={`${className}`}
      width={width}
      headerStyle={{ border: "none" }}
      size={size}
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: 60,
            color: "#000",
            borderBottom: "2px solid #EFF1F4",
          }}
        >
          <p
            style={{
              margin: 0,
              fontSize: 24,
              fontFamily: "tradeGothic",
              fontWeight: "bold",
            }}
          >
            {text}
          </p>
          <span style={{ cursor: "pointer" }}>
            <CloseIcon onClick={onClose} />
          </span>
        </div>
      }
      placement="right"
      onClose={onClose}
      visible={visible}
      closable={false}
      footer={footer}
      destroyOnClose={destroyOnClose}
    >
      {children}
    </AntdDrawer>
  );
}

Drawer.propTypes = {};

export default Drawer;
