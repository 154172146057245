import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Space,
  Form,
  Row,
  Col,
  Typography,
  Avatar,
  Tag,
  Collapse,
  Popover,
} from "antd";
import { Button } from "components/Buttons/Buttons";
import Drawer from "components/Drawer/Drawer";

import "./EventDrawer.scss";

import StatusPill from "./components/StatusPill";
import OutlinedSelector from "./components/OutlinedSelector";

import UserInfo from "components/UserInfo/UserInfo";
import BookingDetails from "components/BookingDetails/BookingDetails";

import {
  assignNurseToBooking,
  getBookingDetails,
  switchNurseForBooking,
  updateOrderStatus,
  updatePaymentMethod,
} from "services/booking.service";
import {
  getCustomerDetails,
  getCustomerPaymentMethods,
  getCustomerWalletCredits,
  getRewards,
} from "services/guests.services";
import MiniPaymentRow from "components/MiniPaymentRow/MiniPaymentRow";
import { ExclamationCircleOutlined, UserOutlined } from "@ant-design/icons";
import parsePhoneNumber from "libphonenumber-js";
import { checkIsTeleHealthBooking, isNurseRole } from "utils/common";
import { setCurrentBooking } from "redux/actions/booking.actions";
import { setLoading } from "redux/actions/app.actions";
import ProfileDrawer from "components/ProfileDrawer/ProfileDrawer";
import OrderPaymentDrawer from "containers/Appointments/OrderPaymentDrawer";
import NotesGrid from "components/NotesGrid/NotesGrid";
import { openNotificationWithIcon } from "utils/Notification";
import GiftCardRow from "components/GiftCardRow/GiftCardRow";
import { getCustomerGiftCards } from "services/giftCards.service";
import MembershipStatus from "components/MembershipStatus/MembershipStatus";
import ProgramRow from "components/ProgramsList/ProgramRow";
import { push } from "connected-react-router";
import CancelButton from "components/CancelButton";
import IntakeForms from "containers/IntakeForms/IntakeForms";

const { Title } = Typography;

export default function EventDrawer({
  orderId,
  visible,
  onClose,
  getData,
  inventoryLinks = true,
  setShowEventDrawer,
  openPaymentDrawerDirectly = false,
}) {
  // keeping states to gracefully go back to prev state on error
  const [status, setStatus] = useState();
  const [staffId, setStaffId] = useState(null);

  const [paymentMethods, setPaymentMethods] = useState();
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState();
  const [rewards, setRewards] = useState({
    data: [],
    isLoading: true,
  });
  const [credit, setCredits] = useState({
    data: 0,
    isLoading: true,
  });
  const [fetchingPaymentMethods, setFetchingPaymentMethods] = useState(false);

  const [customerInfo, setCustomerInfo] = useState();
  const [fetchingCustomerInfo, setFetchingCustomerInfo] = useState(false);

  const [profileVisible, setProfileVisible] = useState(false); // false or data
  const [paymentVisible, setPaymentVisible] = useState(false);

  const [giftCards, setGiftCards] = useState({
    data: [],
    isLoading: false,
  });

  const [notesForm] = Form.useForm();
  const [form] = Form.useForm();

  // const notes = useSelector((state) => state.user.userNotes);
  const {
    currentBooking: booking,
    wholeStaff,
    allRoles,
    currentUser,
  } = useSelector((state) => {
    return {
      currentBooking: state.bookings.currentBooking,
      wholeStaff: state.user.wholeStaff,
      allRoles: state.roles.allRoles,
      currentUser: state.user.currentUser,
    };
  });

  const dispatch = useDispatch();

  const isFirstVisit = booking?.isFirstVisit; // customerInfo?.totalOrdersCount <= 1;
  const { customerId } = booking ?? {};

  const getCustomerPaymentMethodsLists = async (customerId) => {
    setFetchingPaymentMethods(true);
    dispatch(
      getCustomerPaymentMethods(customerId, (paymentMethods) => {
        setPaymentMethods(paymentMethods);
      })
    ).finally(() => {
      setFetchingPaymentMethods(false);
    });
  };

  const populateCustomerGiftCards = (customerId) => {
    setGiftCards((data) => ({ ...data, isLoading: true }));
    dispatch(getCustomerGiftCards(customerId))
      .then((res) => {
        if (res?.status === "success") {
          setGiftCards((data) => ({ data: res?.data ?? [], isLoading: false }));
        }
      })
      .finally(() => {
        setGiftCards((data) => ({ ...data, isLoading: false }));
      });
  };
  const refreshCustomerRewards = () => {
    setRewards((state) => ({ ...state, isLoading: true }));
    return dispatch(getRewards(customerId)).then((res) => {
      if (res?.status === "success")
        setRewards((state) => ({
          ...state,
          data: res?.data,
          isLoading: false,
        }));
    });
  };

  const populateWalletCredits = (customerId) => {
    if (customerId) {
      setCredits((state) => ({ ...state, isLoading: true }));
      dispatch(getCustomerWalletCredits(customerId))
        .then((res) => {
          if (res?.status === "success") {
            setCredits((state) => ({ ...state, data: res?.data }));
          }
        })
        .finally(() => {
          setCredits((state) => ({ ...state, isLoading: false }));
        });
    }
  };

  useEffect(() => {
    // Get Data from API`
    if (booking?.orderId !== undefined && visible) {
      dispatch(
        getBookingDetails(booking.orderId, (booking) => {
          dispatch(setCurrentBooking(booking));
          setSelectedPaymentMethodId(booking.paymentMethodId);
        })
      );
      if (booking?.customerId !== undefined) {
        setFetchingCustomerInfo(true);
        getCustomerPaymentMethodsLists(booking?.customerId);
        populateCustomerGiftCards(booking?.customerId);
        refreshCustomerRewards();
        dispatch(
          getCustomerDetails(booking?.customerId, (customerDetail) => {
            setCustomerInfo(customerDetail);
          })
        ).finally(() => {
          setFetchingCustomerInfo(false);
        });
        populateWalletCredits(booking?.customerId);
      }
    }
  }, [booking?.orderId, visible]);

  useEffect(() => {
    if (visible) {
      setStatus(booking?.orderStatus);
      setStaffId(booking?.staffId);
      form.setFieldsValue({
        staffId: booking?.staffId,
      });
      if (openPaymentDrawerDirectly === true) setPaymentVisible(true);
    } else {
      setStatus();
      setSelectedPaymentMethodId();
      setStaffId();
      form.resetFields();
      setRewards({
        data: [],
        isLoading: true,
      });
      setCredits({
        data: 0,
        isLoading: false,
      });
      setGiftCards({
        data: [],
        isLoading: false,
      });
    }
  }, [visible, booking]);

  const phoneNumberFormatted = useMemo(() => {
    if (customerInfo?.phoneNumber) {
      let phoneNumber = undefined;
      try {
        phoneNumber = parsePhoneNumber(`+${customerInfo?.phoneNumber}`);
      } catch (e) {
        console.log("invalid phone number");
      }

      return (
        phoneNumber?.formatInternational?.() ??
        customerInfo?.phoneNumber ??
        "NA"
      );
    }
  }, [customerInfo]);

  // variables start
  const isOrderCompleted = booking?.orderStatus === 3;
  const isOrderCancelled = booking?.orderStatus === 2;
  const isInventorySubmitted = booking?.inventorySubmitted;
  const isPayButton = !inventoryLinks && !isOrderCompleted;
  // variables end
  return (
    <Drawer
      className="eventDrawer"
      visible={visible}
      onClose={onClose}
      text={
        <Space>
          <span className="eventDrawerTitle">Appointment Info</span>
          {/* {isOrderCancelled && <Tag className="ant-tag tag dangerTag">Cancelled</Tag>} */}
          {booking?.hasContraindication && (
            <Popover
              content={"Order has a contraindication."}
              title="Warning"
              trigger="hover"
            >
              <ExclamationCircleOutlined
                style={{ color: "#323232", fontSize: 24 }}
              />
            </Popover>
          )}
          {isOrderCompleted && <Tag className="primaryTag">Completed</Tag>}
        </Space>
      }
      destroyOnClose={true}
    >
      <OrderPaymentDrawer
        key={booking?.orderId}
        credit={credit?.data ?? 0}
        isCreditLoading={credit?.isLoading}
        customer={customerInfo}
        visible={paymentVisible}
        willHostPay={booking?.willHostPay}
        selectedPaymentMethod={selectedPaymentMethodId}
        refreshCustomerRewards={refreshCustomerRewards}
        closeCompletely={() => {
          setPaymentVisible(false);
          onClose();
        }}
        onClose={() => {
          setPaymentVisible(false);
          // onClose()
          // getData()
        }}
        isRewardsLoading={rewards?.isLoading}
        onSuccess={() => {
          getData?.();
          setPaymentVisible(false);
          onClose();
        }}
        rewards={rewards?.data ?? []}
        order={booking}
        setPaymentVisible={setPaymentVisible}
      />
      <ProfileDrawer
        key={customerInfo?.customerId}
        profileVisible={profileVisible}
        data={profileVisible}
        setProfileVisible={setProfileVisible}
        setShowEventDrawer={setShowEventDrawer}
        user={customerInfo}
        onSave={async () => {
          dispatch(setLoading(true));
          const user = await dispatch(
            getCustomerDetails(customerInfo.customerId),
            () => {}
          );
          setCustomerInfo(user.data);
          dispatch(setLoading(false));
        }}
      />

      <Form layout="vertical">
        <Row
          gutter={[16, 16]}
          style={{
            paddingBottom: 20,
            marginBottom: 16,
            borderBottom: "2px solid #EFF1F4",
          }}
        >
          <Col sm={24} md={12}>
            <StatusPill
              disabled={!customerInfo || !booking?.staffId || isOrderCompleted}
              label="Status"
              value={status}
              onChange={async (value) => {
                const prevStatus = status;
                setStatus(value);
                await dispatch(
                  updateOrderStatus(booking, value, () => {
                    setStatus(prevStatus);
                  })
                );
                getData?.();
                onClose?.();
              }}
            />
          </Col>
          <Col sm={24} md={12}>
            {/* <OutlinedSelector
              disabled={!customerInfo}
              value={paymentType}
              label="Payment"
              renderLabel="label"
              renderValue="value"
              options={[
                { label: "Pay", value: 2 },
                { label: "Cash", value: 1 },
                { label: "None", value: 0 },
              ]}
              onChange={(value) => {
                const prevPaymentType = paymentType;
                setPaymentType(value);
                dispatch(
                  updateOrderPaymentStatus(booking, value, () => {
                    setPaymentType(prevPaymentType);
                  })
                );
              }}
            /> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  fontSize: 16,
                  margin: 0,
                  fontWeight: "bold",
                  fontFamily: "tradeGothic",
                  marginBottom: 4,
                  paddingTop: 6,
                }}
              >
                Payments
              </p>
              <Button
                disabled={!booking?.staffId && !isOrderCancelled}
                style={{
                  height: 50,
                  width:
                    isOrderCompleted || !inventoryLinks || isOrderCancelled
                      ? 160
                      : 120,
                  borderRadius: 30,
                }}
                onClick={() => {
                  if (isOrderCancelled) {
                    setPaymentVisible(true);
                    return;
                  }
                  if (!booking?.willHostPay && !selectedPaymentMethodId) {
                    openNotificationWithIcon(
                      "error",
                      "Error",
                      "Need a payment method for this order, host is not paying for it."
                    );
                    return;
                  }
                  if (!isOrderCompleted && inventoryLinks) {
                    onClose();
                    dispatch(push(`/dashboard/inventory/${booking?.orderId}`));
                    return;
                  }
                  setPaymentVisible(true);
                }}
              >
                {isOrderCancelled
                  ? "Payment Details"
                  : isPayButton
                  ? "Pay"
                  : isOrderCompleted
                  ? "Payment Details"
                  : "Inventory"}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
      <UserInfo
        name={booking?.customerFullName}
        loading={fetchingCustomerInfo}
        isFirstVisit={isFirstVisit}
        email={customerInfo?.email}
        phoneNumber={phoneNumberFormatted}
        isTelehealth={checkIsTeleHealthBooking(booking)}
        startUrl={booking?.startUrl}
        meetingVendor={booking?.meetingVendor}
        address={`${booking?.serviceAddress}`}
        operationType={booking?.operationTypeId === 1 ? "Mobile" : "Clinic"}
        style={{
          paddingBottom: 8,
          marginBottom: 16,
          borderBottom: "2px solid #EFF1F4",
        }}
        onClick={async () => {
          dispatch(setLoading(true));
          const user = await dispatch(
            getCustomerDetails(customerInfo.customerId),
            () => {}
          );
          setCustomerInfo(user.data);
          dispatch(setLoading(false));
          setProfileVisible(true);
        }}
      />

      <BookingDetails
        onClose={onClose}
        rewards={rewards?.data ?? []}
        isRewardsLoading={rewards?.isLoading}
        disabled={isOrderCompleted || isOrderCancelled}
        isOrderCancelled={isOrderCancelled} // TODO: chaipi
        booking={booking}
        customer={customerInfo}
        setShowEventDrawer={setShowEventDrawer}
        style={{
          borderBottom: "2px solid #EFF1F4",
        }}
        refreshCustomerRewards={refreshCustomerRewards}
      />
      <Collapse
        className="eventNotesCollapse"
        expandIconPosition={"end"}
        style={{
          background: "#fff",
          border: "none",
          boxShadow: "none",
          marginBottom: 16,
        }}
      >
        <Collapse.Panel
          key="intake"
          style={{ background: "#fff", boxShadow: "none" }}
          header={
            <p
              style={{
                margin: 0,
                fontSize: 20,
                fontFamily: "tradeGothic",
                fontWeight: "bold",
                color: "#161F3A",
              }}
            >
              Intake Forms
            </p>
          }
        >
          <IntakeForms
            orderId={orderId}
            user={customerInfo}
            variant="full"
            refreshCustomer={() => {
              dispatch(
                getCustomerDetails(booking?.customerId, (customerDetail) => {
                  setCustomerInfo(customerDetail);
                })
              ).finally(() => {
                setFetchingCustomerInfo(false);
              });
            }}
          />
        </Collapse.Panel>
        <Collapse.Panel
          key="notes"
          style={{ background: "#fff", boxShadow: "none" }}
          header={
            <p
              style={{
                margin: 0,
                fontSize: 20,
                fontFamily: "tradeGothic",
                fontWeight: "bold",
                color: "#161F3A",
              }}
            >
              Notes
            </p>
          }
        >
          <NotesGrid
            customerId={customerId}
            form={notesForm}
            isEditable={false}
          />
        </Collapse.Panel>
      </Collapse>

      <Title
        level={4}
        style={{
          fontSize: 20,
          color: "#161F3A",
        }}
      >
        Billing Info
      </Title>
      <div
        style={{
          borderBottom: "2px solid #EFF1F4",
          marginBottom: 12,
          paddingBottom: 16,
        }}
      >
        {!!customerInfo?.hasMembership && (
          <div style={{ marginBottom: 4 }}>
            <MembershipStatus hasMembership={customerInfo?.hasMembership} />
          </div>
        )}
        <GiftCardRow
          credit={credit?.data ?? 0}
          isCreditLoading={credit?.isLoading}
          paymentMethod={paymentMethods?.find?.(
            (paymentMethod) =>
              selectedPaymentMethodId === paymentMethod?.paymentMethodId
          )}
          customer={customerInfo}
          customerId={customerId}
          loading={giftCards.isLoading}
          giftCards={giftCards.data ?? []}
          refetchGiftCards={() => {}}
          refreshGiftCards={() => populateCustomerGiftCards(customerId)}
          refreshCredits={() => populateWalletCredits(customerId)}
        />
        <MiniPaymentRow
          isParentBooking={!booking?.parentOrderId}
          isMultipleGuestBooking={booking?.isMultipleGuestBooking}
          willHostPay={booking?.willHostPay}
          disabled={isOrderCompleted}
          changeOnly={true}
          customerId={customerId}
          loading={fetchingPaymentMethods}
          paymentMethods={paymentMethods}
          paymentMethod={paymentMethods?.find?.(
            (paymentMethod) =>
              selectedPaymentMethodId === paymentMethod?.paymentMethodId
          )}
          refetchPaymentMethods={() => {
            getCustomerPaymentMethodsLists(customerId);
            populateCustomerGiftCards(customerId);
          }}
          onSave={(paymentMethodId) => {
            dispatch(
              updatePaymentMethod(booking?.orderId, paymentMethodId)
            ).then((result) => {
              if (result?.status === "success")
                setSelectedPaymentMethodId(paymentMethodId);
            });
          }}
          onChange={(paymentMethodId) => {
            dispatch(
              updatePaymentMethod(booking?.orderId, paymentMethodId)
            ).then((result) => {
              if (result?.status === "success")
                setSelectedPaymentMethodId(paymentMethodId);
            });
          }}
        />
        <Col xs={24}>
          <ProgramRow
            customerId={customerId}
            paymentMethodId={selectedPaymentMethodId}
            refreshRewards={refreshCustomerRewards}
          />
        </Col>
      </div>

      <Form form={form} layout="vertical">
        <OutlinedSelector
          value={staffId}
          name="staffId"
          // label="Assigned Clinician"
          label="Assigned Clinician"
          options={wholeStaff.filter((staff) => {
            return (
              (staff?.facilityIds.includes(booking?.facilityId) &&
                staff?.isActive &&
                isNurseRole(staff, allRoles)) ||
              (staff.roleName === "Admin" && staff.isActive)
            );
          })}
          filterOption={(input, option) => {
            return option?.label
              ?.toLowerCase?.()
              .includes?.(input.toLowerCase());
          }}
          onChange={(value) => {
            const prevStaffId = staffId;
            setStaffId(value);
            const cb = booking?.staffId
              ? switchNurseForBooking
              : assignNurseToBooking;
            dispatch(
              cb(booking, value, () => {
                setStaffId(prevStaffId);
              })
            );
          }}
          renderValue="staffId"
          renderLabel="name"
          isSearch
          showSearch={true}
          optionLabelProp="label"
          renderOption={(opt) => {
            return (
              <div>
                <Avatar
                  src={opt?.profileImageUrl}
                  icon={<UserOutlined />}
                  style={{ marginRight: 6 }}
                />
                {opt["name"]}
              </div>
            );
          }}
        />

        <CancelButton booking={booking} getData={getData} />
      </Form>
    </Drawer>
  );
}
