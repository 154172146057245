import { cloneDeep } from "lodash";
import moment from "moment";
import { setLoading } from "redux/actions/app.actions";
import { openNotificationWithIcon } from "utils/Notification";
import { DUMMY_CATEGORIES, DUMMY_SERVICES, setCategories } from "./data";
import { request } from "./verb.services";

export const getAllCategories = (callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request("Categories", "get", null, true, null)
      .then((res) => {
        return {
          status: "success",
          data: res.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getCategoryById = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return new Promise((resolve, reject) => {
      const category = DUMMY_CATEGORIES.find(
        (category) => category.categoryId === id
      );
      // todo: use callback
      setTimeout(() => resolve({ status: "success", data: category }), 250);
    })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const createCategory = (values, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Categories`, "post", values, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Category created successfully"
        );
        return { status: "success", data: res?.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const deleteCategory = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Categories/${id}/InActive`, "patch", null, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Category inactivated successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const restoreCategory = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Categories/${id}/Activate`, "patch", null, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Category activated successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const updateCategory = (id, data, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Categories/${id}`, "put", data, true)
      .then(() => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Category updated successfully"
        );
        callback?.();
        return {
          status: "success",
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
