import React from "react";
import PropTypes from "prop-types";

import { Button } from "antd";
import { ReactComponent as RightArrow } from "assets/icons/rightArrowIco.svg";

function ArrowButton(props) {
  return (
    <Button
      {...props}
      shape="circle"
      style={{
        border: "none",
      }}
    >
      <RightArrow />
    </Button>
  );
}

ArrowButton.propTypes = {};

export default ArrowButton;
