export const actions = {
  SET_TOTAL_BOOKINGS: "SET_TOTAL_BOOKINGS",
  SET_UPCOMING_BOOKINGS: "SET_UPCOMING_BOOKINGS",
  SET_NOSHOW_BOOKINGS: "SET_NOSHOW_BOOKINGS",
  CLEAR_CUSTOMER_BOOKINGS: "CLEAR_CUSTOMER_BOOKINGS"
};

export const setCustomerTotalBookings = (data, message) => ({
  type: actions.SET_TOTAL_BOOKINGS,
  data,
});

export const setCustomerUpcomingBookings = (data, message) => ({
  type: actions.SET_UPCOMING_BOOKINGS,
  data,
});

export const setCustomerNoShowBookings = (data, message) => ({
  type: actions.SET_NOSHOW_BOOKINGS,
  data,
});

export const clearCustomerBookings = ()=>({
    type: actions.CLEAR_CUSTOMER_BOOKINGS
})