import { openNotificationWithIcon } from "../utils/Notification";
import { request } from "./verb.services";

import {
  setAllFacilities,
  setClinicFacilities,
  setIsFetchingFacilities,
  setMobileFacilities,
} from "redux/actions/facilities.action";
import { setCenters, setFetching } from "redux/actions/locationCenter.action";
import { sortBy } from "lodash";
import { CalenderOperationType } from "utils/enums";

export const getFacilities = (streetAddress) => {
  return (dispatch) => {
    dispatch(setIsFetchingFacilities(true));
    return request(
      `Facilities?${
        streetAddress ? `streetAddress=${streetAddress}&` : ""
      }includeOnlyActive=false`,
      "get",
      null,
      true
    )
      .then((response) => {
        // we are going to sort the results for requirement #5715
        if (response.data) {
          const sortedData = sortBy(response.data ?? [], ["state"]);
          for (let item of sortedData) {
            if (!item.state) {
              item.state = "Unamed";
            }
          }
          dispatch(setAllFacilities(sortedData));
          const activeClinicFacilities = sortedData?.filter((it) => {
            return (
              (it?.operationTypeIds?.includes(2) ||
                it?.operationTypeIds === 2) &&
              it?.isActive === true
            );
          });
          const activeMobileFacilities = sortedData?.filter((it) => {
            return (
              (it?.operationTypeIds?.includes(1) ||
                it?.operationTypeIds === 1) &&
              it?.isActive === true
            );
          });
          dispatch(setClinicFacilities(activeClinicFacilities));
          dispatch(setMobileFacilities(activeMobileFacilities));
        }
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => [dispatch(setIsFetchingFacilities(false))]);
  };
};

export const getClinicFacilities = () => {
  return (dispatch) => {
    dispatch(setIsFetchingFacilities(true));

    return request(`Facilities/Clinics`, "get", null, true)
      .then((response) => {
        dispatch(setClinicFacilities(response.data));
        return response.data;
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setIsFetchingFacilities(false));
      });
  };
};

export const getMobileFacilities = () => {
  return (dispatch) => {
    dispatch(setIsFetchingFacilities(true));

    return request(`Facilities/Mobile`, "get", null, true)
      .then((response) => {
        dispatch(setMobileFacilities(response.data));
        return response.data;
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setIsFetchingFacilities(false));
      });
  };
};

export const getOutOfZoneErrorMessage = (operationTypeId) => {
  return operationTypeId === CalenderOperationType?.Telehealth
    ? "The ZIP code is out of zone."
    : "The address is out of zone.";
};

export const getFaclityInZone = (
  operationTypeId,
  { lat, lng },
  clearCascadingSelectors
) => {
  return (dispatch) => {
    dispatch(setFetching("isFetchingCenters", true));
    // TODO: Revert this later
    return request(
      `Facilities/InZone?operationTypeId=${operationTypeId}${
        lat ? `&latitude=${escape(lat)}` : ""
      }${lng ? `&longitude=${escape(lng)}` : ""}`,
      "get",
      null,
      true,
      null
    )
      .then((response) => {
        // TODO: contact backend and have it return array instead of object
        // then use it directly instead of array and object checks
        let data =
          response.data.constructor === Object
            ? [response.data]
            : response.data;
        if (data?.length > 0) {
          dispatch(setCenters(data, clearCascadingSelectors));
        } else {
          openNotificationWithIcon(
            "error",
            "Error!",
            getOutOfZoneErrorMessage(operationTypeId)
          );
        }
        return {
          status: data?.length > 0 ? "success" : "failure",
          data,
        };
      })
      .catch((e) => {
        if (e.message !== "canceled")
          openNotificationWithIcon(
            "error",
            "Error!",
            e?.response?.data?.message ||
              getOutOfZoneErrorMessage(operationTypeId)
          );
      })
      .finally(() => {
        dispatch(setFetching("isFetchingCenters", false));
      });
  };
};
