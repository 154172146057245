import { Avatar, Badge, Col, Dropdown, Menu } from "antd";
import { FormSelect } from "components/FormItems/FlatFormItems";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { getDefaultDaySlots } from "utils/common";
import { ReactComponent as ChevronDown } from "assets/icons/chevronDownIco.svg";
import { CloseCircleOutlined } from "@ant-design/icons";

export default function ShiftAvailabiltyWidget({
  monthOptions,
  facilityShifts,
  isLoadingFacilityShifts,
  calendarRosters,
  isCalendarRostersLoading,
  rosters,
  setRosters,
  daySlots,
  shift,
}) {
  const handleSubmit = () => {
    const submittableData = [];
    for (let i = 0; i < daySlots?.length; i++) {
      const slot = daySlots[i];
      if (slot.disabled === false) {
        const findShift = rosters.data?.find((it) => it.index === i);
        if (findShift)
          submittableData.push({
            date: slot.date?.format(),
            shiftId: findShift?.shiftId,
          });
      }
    }
  };

  const onShiftChange = useCallback(
    (index, shiftId) => {
      if (shiftId === undefined) {
        setRosters((prev) => ({
          ...prev,
          data: rosters?.data?.filter((it) => it.index !== index),
        }));
        return;
      }

      const data = rosters?.data;
      const dataFound = data.find(
        (it) => it.index === index && it.shiftId === shiftId
      );
      let newData = data;
      if (dataFound) {
        newData = data?.filter((it) => {
          if (it?.index === index && it.shiftId === shiftId) return false;
          return true;
        });
      } else newData = [...data, { index, shiftId, selectedFromField: false }];
      setRosters((prev) => ({
        ...prev,
        data: newData,
      }));
    },
    [rosters]
  );
  const renderDropdownContent = (item, shiftsFound) => {
    if (isLoadingFacilityShifts)
      return (
        <div
          className="ant-dropdown-menu"
          style={{
            borderRadius: 6,
            border: "2px solid rgb(205, 213, 216)",
            padding: "5px 12px",
          }}
        >
          Loading
        </div>
      );
    return (
      <Menu
        style={{ borderRadius: 6, border: "2px solid #CDD5D8 " }}
        selectedKeys
      >
        {facilityShifts?.map((it) => {
          const isFound = shiftsFound?.find(
            (it2) => it2.shiftId === it.shiftId
          );
          return (
            <Menu.Item
              disabled={item?.disabled || isFound?.isBackendFetched}
              style={{
                backgroundColor: isFound ? "#dbebfb" : "#fff",
              }}
              key={it?.shiftId}
              onClick={() => {
                onShiftChange(item?.index, it?.shiftId);
              }}
            >
              {it?.shiftDefinition}
            </Menu.Item>
          );
        })}
        {shiftsFound?.length > 0 && (
          <Menu.Item
            disabled={item?.disabled}
            onClick={() => {
              onShiftChange(item?.index, undefined);
            }}
          >
            Not Available
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const handleToogle = useCallback(
    (shiftsFound, daySlot) => {
      const shiftFound = shiftsFound?.[0];
      const isRejectedOrAbsent = shiftFound?.isRejected || shiftFound?.isAbsent;
      if (isRejectedOrAbsent) {
        let newShifts = rosters?.data?.filter?.((shift) => {
          if (shift?.rosterDate === shiftFound.rosterDate) return false;
          return true;
        });
        const day = daySlot.date?.format?.("dddd");
        const facilityShift =
          facilityShifts?.find(
            (facilityShift) =>
              facilityShift.day === day && facilityShift.shiftId === shift
          ) ?? {};
        newShifts = [
          ...newShifts,
          {
            ...facilityShift,
            rosterDate: daySlot?.date?.format("YYYY-MM-DD"),
          },
        ];
        setRosters((prev) => ({
          ...prev,
          data: newShifts,
        }));
      } else if (shiftFound) {
        const newShifts = rosters?.data?.filter?.((shift) => {
          if (shift?.rosterDate === shiftFound.rosterDate) return false;
          return true;
        });
        setRosters((prev) => ({
          ...prev,
          data: newShifts,
        }));
      } else {
        const day = daySlot.date?.format?.("dddd");
        const facilityShift =
          facilityShifts?.find(
            (facilityShift) =>
              facilityShift.day === day && facilityShift.shiftId === shift
          ) ?? {};
        const newShifts = [
          ...rosters?.data,
          {
            ...facilityShift,
            rosterDate: daySlot?.date?.format("YYYY-MM-DD"),
          },
        ];
        setRosters((prev) => ({
          ...prev,
          data: newShifts,
        }));
      }
    },
    [rosters, shift]
  );

  const getSlotBg = (daySlot, shiftsFound, calendarSlot) => {
    const slotNotAvailable = calendarSlot?.slotsAvailable <= 0;
    if (daySlot?.disabled) return "#f8f8f8";
    else if (shiftsFound?.isBackendFetched) {
      return "#e6f4ff";
    } else if (slotNotAvailable) return "#ffccc7";
    else if (shiftsFound?.isRejected) {
      return "#ffec3d";
    } else if (shiftsFound?.isAbsent) {
      return "#ffec3d";
    } else if (calendarSlot?.slotsAvailable > 0) return "#d9f7be";
    return "#fff";
  };

  return (
    <Col xs={24}>
      <div className="availabilityWidget">
        <div className="availabilityHeader">
          <div>Sun</div>
          <div>Mon</div>
          <div>Tue</div>
          <div>Wed</div>
          <div>Thu</div>
          <div>Fri</div>
          <div>Sat</div>
        </div>
        <div className="availabilityBody">
          {daySlots?.map((daySlot, index) => {
            const calendarSlot = calendarRosters?.find(
              (roster) =>
                roster?.rosterDate === daySlot?.date?.format?.("YYYY-MM-DD")
            );
            const shiftsFound = rosters?.data?.filter(
              (shift) =>
                shift?.rosterDate === daySlot?.date?.format?.("YYYY-MM-DD")
            );
            let shiftDetails = "";
            if (shiftsFound?.length > 0)
              shiftDetails = facilityShifts?.find(
                (it2) => it2?.shiftId === shiftsFound?.[0]?.shiftId
              );
            const isBackendFetched = shiftsFound?.find(
              (it) => it?.isBackendFetched
            );
            const isRejectedOrAbsent =
              shiftsFound?.[0]?.isRejected || shiftsFound?.[0]?.isAbsent;

            const showRemoveIcon = shiftsFound?.length > 0 && !isBackendFetched;

            return (
              // <Dropdown
              //   // disabled={it?.disabled || isBackendFetched}
              //   key={it?.index}
              //   overlay={() => renderDropdownContent(it, shiftsFound)}
              //   trigger={["click"]}
              // >
              <div
                style={{
                  background: getSlotBg(
                    daySlot,
                    shiftsFound?.[0],
                    calendarSlot
                  ),
                  cursor:
                    daySlot?.disabled ||
                    (isBackendFetched && !isRejectedOrAbsent) ||
                    calendarSlot?.slotsAvailable <= 0
                      ? "default"
                      : "pointer",
                }}
                onClick={() => {
                  if (
                    !(
                      daySlot?.disabled ||
                      (isBackendFetched && !isRejectedOrAbsent) ||
                      calendarSlot?.slotsAvailable <= 0
                    )
                  ) {
                    handleToogle(shiftsFound, daySlot);
                  }
                }}
              >
                <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
                  <span>{daySlot.value}</span>
                  {/* {shiftsFound?.length > 1 && (
                    <Badge
                      style={{ backgroundColor: "#3991f1" }}
                      count={shiftsFound?.length}
                      title={`${shiftsFound?.length} shifts have been selected for the day.`}
                    ></Badge>
                  )} */}
                </div>
                {shiftsFound && (
                  <div className="shiftName">
                    <span>{shiftDetails?.shiftName} </span>
                    {showRemoveIcon && (
                      <>
                        <CloseCircleOutlined
                          style={{ height: 12, width: 12 }}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
              // </Dropdown>
            );
          })}
        </div>
      </div>
    </Col>
  );
}
