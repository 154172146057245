import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Modal from "components/Modal/Modal";
import { Button } from "components/Buttons/Buttons";

import {
  Form,
  Typography,
  Checkbox,
  Skeleton,
  Button as AntdButton,
} from "antd";

import Signature, { useSignature } from "components/Signature/Signature";
import { openNotificationWithIcon } from "utils/Notification";
import { getForm, getFormByType } from "services/forms.service";
import FormParsedUI from "containers/FormsManagement/components/FormParseUi";
import moment from "moment";
import { postForm } from "services/intakeForm.service";
import { FormType } from "utils/enums";
import { FormHiddenSubmitButton } from "components/FormItems/FlatFormItems";

const { Title, Text } = Typography;

const DATE_INDICATOR = "{{DATE}}";

export default function IntakeFormModal({
  visible,
  title = "",
  type,
  onSubmit,
  onClose,
  initialValues = {},
  formId,
  isSubmitted = false,
  isEdit = false,
  customerId,
  formCode,
  orderId,
  className,
}) {
  const [form] = Form.useForm();
  const submitBtnRef = useRef();

  const [record, setRecord] = useState();
  const dispatch = useDispatch();

  console.log("record", record);

  const [signature, getSignatureValue, clearSignatureValue, setSignatureValue] =
    useSignature(className, form);
  const [
    signature1,
    getSignatureValue1,
    clearSignatureValue1,
    setSignatureValue1,
  ] = useSignature(className, form, "signature1");

  const [agreeAllConsensts, setAgreeAllConsents] = useState(
    isEdit ? false : true
  );
  const [error, setError] = useState(false);

  const loadData = (renderDesign = true) => {
    // get data
    if (!isEdit) {
      dispatch(
        getForm({
          formValueId: formId,
        })
      ).then((res) => {
        const data = res?.data;
        const {
          designJSON,
          valueJSON,
          formVersionId,
          formValueId,
          formType,
          formId,
        } = data ?? {};
        const design = JSON.parse(designJSON || "{}");
        const values = JSON.parse(valueJSON || "{}");

        setRecord({
          design,
          formVersionId,
          formValueId,
          formType,
          formId,
          values,
        });

        if (renderDesign) {
          let transformedValues = {};
          const keys = Object.keys(values);
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const value = values[key];
            if (value?.startsWith?.(DATE_INDICATOR)) {
              const dateString = value.split(DATE_INDICATOR).pop();
              const date = moment(dateString);
              transformedValues[key] = date;
            } else transformedValues[key] = value;
          }

          form.setFieldsValue(transformedValues);

          // if (values?.customerSignature)
          //   setSignatureValue(values?.customerSignature);
        }
      });
    } else {
      dispatch(getFormByType(formCode, true))
        .then((res) => {
          if (res?.status === "success") {
            setError(false);

            // const data = res?.data;
            // const formData = data?.formVersions?.[0];
            // const { formId, formJSON, formVersionId, versionNo } = formData;

            const data = res?.data;
            const {
              designJSON,
              latestVersionId,
              formValueId,
              formType,
              formId,
            } = data ?? {};
            const design = JSON.parse(designJSON || "{}");

            setRecord({
              design,
              formVersionId: latestVersionId,
              formValueId,
              formType,
              formId,
            });
          } else {
            setError(true);
          }
        })
        .catch(() => {
          setError(true);
        });
    }
  };

  useEffect(() => {
    if (visible) {
      loadData();
      if (isEdit) setAgreeAllConsents(false);
      else setAgreeAllConsents(true);
    } else {
      form.resetFields();
      setRecord();
      clearSignatureValue();
      setAgreeAllConsents(false);
    }
  }, [visible, isSubmitted, isEdit]);

  useEffect(() => {
    const customerSignature = record?.values?.customerSignature;
    const nurseSignature = record?.values?.nurseSignature;
    if (customerSignature && visible && !isEdit) {
      clearSignatureValue();
      setSignatureValue(customerSignature);
    } else {
      clearSignatureValue();
    }

    if (nurseSignature && visible && !isEdit) {
      clearSignatureValue1();
      setSignatureValue1(nurseSignature);
    } else {
      clearSignatureValue1();
    }
  }, [visible, record]);

  /** handleSave just updates the formValuesJson */
  const getSubmitableData = (data) => {
    const transformedValues = {};
    const keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = data[key];
      if (moment.isMoment(value))
        transformedValues[key] = DATE_INDICATOR + value?.toString();
      else transformedValues[key] = value;
    }
    return transformedValues;
  };

  const handleSubmit = (values) => {
    const { formId, formVersionId, formValueId } = record;

    const transformedValues = getSubmitableData(values);
    if (
      getSignatureValue &&
      record?.design?.find((item) => item?.key === "GuestSignature")
    ) {
      const signature = getSignatureValue();
      if (signature === "") {
        openNotificationWithIcon(
          "error",
          "Error!",
          "Please sign the consent form"
        );
        return;
      }
      values.customerSignature = signature;
      transformedValues.customerSignature = signature;
    }
    if (
      getSignatureValue1 &&
      record?.design?.find((item) => item?.key === "NurseSignature")
    ) {
      const signature1 = getSignatureValue1();
      if (signature1 === "") {
        openNotificationWithIcon(
          "error",
          "Error!",
          "Please provide nurse signature"
        );
        return;
      }
      values.nurseSignature = signature1;
      transformedValues.nurseSignature = getSignatureValue1();
    }

    dispatch(
      postForm(
        formId,
        formVersionId,
        transformedValues,
        customerId,
        type === FormType.historyOrPhysical ? "physicalHistory" : "consent"
      )
    ).then(() => {
      if (onSubmit) onSubmit();
    });
  };

  return (
    <Modal
      key={title}
      destroyOnClose={false}
      className={`intakeForm ${className}`}
      title={title}
      visible={visible}
      width={812}
      onCancel={onClose}
      footer={
        <div
          className="flex "
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          {title === "Consents" && (
            <Checkbox
              className="checkboxRegular flatFormItemCheckbox"
              checked={agreeAllConsensts}
              disabled={!isEdit}
              style={{ margin: 0, alignItems: "center" }}
              onChange={(e) => {
                setAgreeAllConsents((state) => {
                  return !state;
                });
                if (e.target.checked === false) {
                  clearSignatureValue();
                }
              }}
            >
              I agree to all consents
            </Checkbox>
          )}
          {isEdit && !error && record?.design && (
            <>
              <Button
                onClick={() => submitBtnRef?.current?.click()}
                rounded={true}
                type="submit"
                style={{ paddingLeft: 60, paddingRight: 60 }}
              >
                Save
              </Button>
            </>
          )}
        </div>
      }
    >
      <Form
        disabled={!isEdit}
        form={form}
        layout="vertical"
        className="intakeFormWrapper consentForm"
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
        onFinish={handleSubmit}
      >
        {error && (
          <div style={{ marginBottom: 16 }}>
            <Text>
              Error has occured while loading form.
              <AntdButton
                type="text"
                onClick={() => {
                  loadData();
                }}
                disabled={false}
              >
                Retry
              </AntdButton>
            </Text>
          </div>
        )}
        {record?.design ? (
          <FormParsedUI
            data={record?.design}
            form={form}
            signature={signature}
            getSignatureValue={getSignatureValue}
            clearSignatureValue={clearSignatureValue}
            setSignatureValue={setSignatureValue}
            isSignatureDisabled={!isEdit}
            signature1={signature1}
            getSignatureValue1={getSignatureValue1}
            clearSignatureValue1={clearSignatureValue1}
            setSignatureValue1={setSignatureValue1}
            isSignature1Disabled={!isEdit}
          />
        ) : (
          <Skeleton />
        )}
        <FormHiddenSubmitButton submitRef={submitBtnRef} />
      </Form>
    </Modal>
  );
}

IntakeFormModal.prototype = {};
