import React from "react";
import { Button, Space, Typography } from "antd";
import { goBack, push } from "connected-react-router";

import { useDispatch } from "react-redux";
import { ReactComponent as LeftArrowIco } from "assets/icons/leftArrow.svg";

import "./SettingsLayout.style.scss";
import { useHistory } from "react-router";

const { Title } = Typography;

export default function SettingsLayout({
  children,
  title,
  goBackURL,
  showHeaders,
}) {
  // hooks start
  const dispatch = useDispatch();
  const history = useHistory();
  // hooks end

  return (
    <div className="settingsContainer">
      {/* #settingHeader for portal */}
      {showHeaders && (
        <div id="settingHeader">
          <div className="settingsHeaderContent">
            <div className="settingsHeaderLeftSide">
              <Space align="center">
                <LeftArrowIco
                  style={{ cursor: "pointer", color: "#15213B" }}
                  onClick={() => {
                    // not using  goBackURL because history.goBack takes user out of site when back btn in setting layout is clicked
                    dispatch(goBack());
                    // if (goBackURL) {
                    //   dispatch(push(goBackURL));
                    // } else {
                    //   dispatch(goBack());
                    // }
                  }}
                />

                <Title
                  level={3}
                  style={{
                    margin: 0,
                    color: "#15213B",
                  }}
                >
                  {title}
                </Title>
                <div className="settingsHeaderLeftSideAddon"></div>
              </Space>
            </div>

            <div className="settingsHeaderRightSide"></div>
          </div>
        </div>
      )}
      <div className="white-bc r-4px">{children}</div>
    </div>
  );
}
