import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Form,
  Row,
  Col,
  Typography,
  Button as AntdButton,
  List,
  Avatar,
  Alert,
} from "antd";
import { Button } from "components/Buttons/Buttons";
import Modal from "components/Modal/Modal";
import {
  FormDatePicker,
  FormHiddenSubmitButton,
  FormInput,
  FormInputArea,
  FormInputNumber,
} from "components/FormItems/FlatFormItems";
import { useForm } from "antd/lib/form/Form";
import { FormSelect } from "components/FormItems/FlatFormItems";
import moment from "moment";
import { Carousel } from "antd";
import DayWeekSelector from "components/Calendar/components/DayWeekSelector";
import { useDispatch, useSelector } from "react-redux";
import {
  createRoster,
  getCalendarRosters,
  getRosters,
  updateRosterSequence,
} from "services/roster.service";
import { UserOutlined } from "@ant-design/icons";
import { RosterFilterType } from "utils/enums";
import { isNurseRole } from "utils/common";
import { vi } from "date-fns/locale";
import { openNotificationWithIcon } from "utils/Notification";

const { Title, Text } = Typography;

export default function AvailabilityModal({
  visible,
  onCancel,
  shifts,
  facilityId: schdulerFacilityId,
  facilities,
  date,
  onSuccess,
}) {
  const [form] = useForm();
  const submitBtnRef = useRef();
  const shift = Form.useWatch("shift", form);
  const facilityId = Form.useWatch("facilityId", form);
  const staffId = Form.useWatch("staffId", form);

  const dispatch = useDispatch();

  const { wholeStaff, allRoles } = useSelector((state) => {
    return {
      wholeStaff: state.user.wholeStaff,
      allRoles: state.roles.allRoles,
    };
  });

  const filteredShifts = useMemo(() => {
    if (!facilityId) return [];
    return shifts.filter((shift) => shift?.facilityId === facilityId);
  }, [facilityId]);
  const facility = facilities?.find?.(
    (facility) => facility.facilityId === facilityId
  );

  const [calenderRoster, setCalenderRoster] = useState({
    data: [],
    isLoading: false,
  });

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        facilityId: schdulerFacilityId,
      })
    } else {
      form.resetFields();
      setCalenderRoster({
        data: [],
        isLoading: false,
      });
    }
  }, [visible]);

  useEffect(() => {
    if (!visible) {
      return;
    }

    if (facilityId && shift) {
      setCalenderRoster({
        data: [],
        isLoading: true,
      });
      dispatch(
        getCalendarRosters(
          facilityId,
          shift,
          date?.format("YYYY-MM-DD"),
          date?.format("YYYY-MM-DD"),
          false
        )
      )
        .then((res) => {
          if (res?.status === "success") {
            console.log("res.data", res.data);
            setCalenderRoster((state) => ({
              ...state,
              data: res?.data,
            }));
          }
        })
        .finally(() => {
          setCalenderRoster((state) => ({
            ...state,
            isLoading: false,
          }));
        });
    }
  }, [visible, facilityId, shift, date]);

  let availableSlots = calenderRoster?.data?.[0]?.slotsAvailable ?? 0;

  return (
    <Modal
      className="scheduleShiftModal"
      //   closable={closable}
      destroyOnClose={true}
      //   headerBorder={false}
      width="720px"
      title="Add Nurse Availability"
      onCancel={() => onCancel()}
      visible={visible}
      onOk={() => console.log("ok")}
      okText="Add"
      centered={true}
      footer={
        <div className="d-flex justify-between align-center">
          <AntdButton type="text" onClick={onCancel}>
            Cancel
          </AntdButton>
          <Button
            style={{ height: 50 }}
            rounded={true}
            onClick={() => submitBtnRef?.current?.click()}
            //   disabled={!Boolean(setupIntent)}
          >
            Save
          </Button>
        </div>
      }
      //   cancelText="No"
    >
      <Typography.Title
        style={{
          color: "#000",
          fontSize: 16,
        }}
      >
        Date: {date?.format?.("DD MMM YYYY")}
      </Typography.Title>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={(values) => {
          console.log("values", values);
          if (availableSlots <= 0) {
            openNotificationWithIcon(
              "error",
              "Error!",
              "No slots available for this shift"
            );
            return;
          }

          let calendarSlot = calenderRoster?.data?.[0];
          dispatch(
            createRoster([
              {
                facilityId: facilityId,
                facilityShiftId: calendarSlot?.facilityShiftId,
                // operationTypeId: 2,
                rosterDate: date.format("YYYY-MM-DD"),
                shiftId: shift,
                staffId: staffId,
              },
            ])
          ).then((res) => {
            if (res?.status === "success") {
              onSuccess();
            }
          });
        }}
        onFinishFailed={(errorFields) => {
          // form.scrollToField(errorFields[0].name, {
          //   scrollMode: "if-needed",
          //   block: "center",
          //   behavior: "smooth",
          // });
        }}
      >
        {/* <Row gutter={[16, 16]}>
          {!facilityId ? (
            <Col xs={24}>
              <Alert
                message="No facility selected"
                type="error"
                style={{ marginBottom: 16 }}
              />
            </Col>
          ) : (
            <>
            <Col>
            </Col>
            </>
            <Typography.Title
              style={{
                color: "#000",
                fontSize: 17,
              }}
            >
              {facility?.facilityName} ({date?.format?.("DD MMM YYYY")})
            </Typography.Title>
          )}
        </Row> */}
        <Row gutter={[16, 16]} style={{ marginBottom: 12 }}>
          <Col xs={24} md={12}>
            <FormSelect
              label="Select Location"
              placeholder={"Select Location"}
              name="facilityId"
              variant="underlined"
              options={facilities}
              renderLabel="facilityName"
              renderValue="facilityId"
              formItemStyles={{ margin: 0 }}
              required={true}
            />
          </Col>
          <Col xs={24} md={12}>
            <FormSelect
              label="Select Shift"
              placeholder={"Select Shift"}
              name="shift"
              variant="underlined"
              options={filteredShifts}
              renderValue={"shiftId"}
              renderLabel={"shiftName"}
              formItemStyles={{ margin: 0 }}
              loading={calenderRoster?.isLoading}
              required={true}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginBottom: 12 }}>
          {/* <Col xs={24} md={12}>
            <FormSelect
              label="Select Shift"
              placeholder={"Select Shift"}
              name="shift"
              variant="underlined"
              options={filteredShifts}
              renderValue={"shiftId"}
              renderLabel={"shiftName"}
              formItemStyles={{ margin: 0 }}
              loading={calenderRoster?.isLoading}
            />
          </Col> */}
          <Col xs={24} md={12}>
            <FormSelect
              className={"searchWithIcon"}
              // disabled={isAppointmentFormDisabled}
              variant="underlined"
              name="staffId"
              label="Select Nurse"
              placeholder="Select Nurse"
              required={true}
              formItemStyles={{ marginBottom: 0 }}
              options={wholeStaff.filter((staff) => {
                // if (operationTypeId === 3) {
                //   const staffSkills = staff?.skillIds;
                //   for (let staffSkill of staffSkills) {
                //     if (
                //       skillIds?.includes(staffSkill) &&
                //       (facilityId
                //         ? staff?.facilityIds?.includes(facilityId)
                //         : staff?.facilityIds?.includes(data?.facilityId))
                //     )
                //       return true;
                //   }
                //   return false;
                // }
                return (
                  staff?.facilityIds?.includes(facilityId) &&
                  staff?.isActive &&
                  isNurseRole(staff, allRoles)
                );
              })}
              renderLabel="name"
              renderValue="staffId"
              optionLabelProp="label"
              showSearch={true}
              filterOption={(input, option) => {
                return option?.label
                  ?.toLowerCase?.()
                  .includes?.(input.toLowerCase());
              }}
              renderOption={(opt) => {
                return (
                  <div>
                    <Avatar
                      src={opt?.profileImageUrl}
                      icon={<UserOutlined />}
                      style={{ marginRight: 6 }}
                    />
                    {opt["name"]}
                  </div>
                );
              }}
            />
          </Col>
        </Row>
        {!!shift && facilityId && date && calenderRoster?.data?.length > 0 && (
          <Col>
            <Typography.Text>
              Total Slots Available: {availableSlots}
            </Typography.Text>
          </Col>
        )}
        <FormHiddenSubmitButton submitRef={submitBtnRef} />
      </Form>
    </Modal>
  );
}
