import React, { useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import useSettingsHeader from "hooks/useSettingsHeader";
import { Button } from "components/Buttons/Buttons";
import { Dropdown, Menu, Modal, Select, Space, Table } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import SearchAndFilterColumns from "components/SearchAndFilterColumns/SearchAndFilterColumns";
import usePortal from "hooks/usePortal";
import {
  createShift,
  deleteShift,
  getAllShifts,
  restoreShift,
  updateShift,
} from "services/shifts.service";
import moment from "moment";
import ShiftDrawer from "./components/ShiftDrawer";
import { getAllLocations } from "services/locationSettings.service";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET":
      return action.data;
    case "ADD":
      return [...state, action.data];
    case "UPDATE":
      return state.map((data) => {
        if (data.shiftId === action.data.shiftId) return action.data;
        return data;
      });
    case "DELETE":
      return state.filter((data) => {
        if (data.shiftId === action.data.shiftId) return false;
        return true;
      });
    default:
      return state;
  }
};

function Shifts(props) {
  //states start
  const [showAddEditModal, setShowAddEditModal] = useState();

  const [locations, setLocations] = useState({
    data: [],
    isLoading: true,
  });
  const [shifts, setShifts] = useState({
    data: [],
    isLoading: true,
  });

  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [activeFilter, setActiveFilter] = useState("active"); // all, active, inactive
  const [searchText, setSearchText] = useState("");
  //states end
  // non-data hooks start
  const dispatch = useDispatch();
  // non-data hooks end
  // use effect starts
  const loadData = () => {
    dispatch(getAllLocations()).then((result) => {
      if (result?.status === "success") {
        setLocations({
          data: result?.data?.filter((it) => it?.isActive),
          isLoading: false,
        });
        const shifts = [];
        for (let i = 0; i < result?.data?.length; i++) {
          const facility = result?.data?.[i];
          if (!facility?.isActive) continue;
          for (let j = 0; j < facility?.shifts?.length; j++) {
            const shift = facility?.shifts?.[j];
            shifts.push({
              shiftId: shift?.shiftId,
              shiftName: shift?.shiftName,
              facilityId: facility?.facilityId,
              facilityName: facility?.facilityName,
              isActive: shift?.isActive,
            });
          }
        }
        setShifts({
          data: shifts,
          isLoading: false,
        });
      }
    });
  };
  useEffect(() => {
    loadData();
  }, []);

  // use effects end
  // functions start
  const handleAdd = (values) => {
    dispatch(createShift(values)).then((result) => {
      if (result?.status === "success") {
        setShowAddEditModal();
        loadData();
        // loadData();
      }
    });
  };
  const handleUpdate = (facilityId, shiftId, values) => {
    dispatch(updateShift(facilityId, shiftId, values)).then((result) => {
      if (result?.status === "success") {
        setShowAddEditModal();
        loadData();
      }
    });
  };
  const handleDelete = (facilityId, shiftId) => {
    dispatch(deleteShift(facilityId, shiftId)).then((result) => {
      if (result?.status === "success") {
        loadData();
      }
    });
  };
  const handleRestore = (facilityId, shiftId) => {
    dispatch(restoreShift(facilityId, shiftId)).then((result) => {
      if (result?.status === "success") {
        loadData();
      }
    });
  };

  const deleteConfirmation = (facilityId, shiftId) => ({
    title: "Inactivate Shift",
    content: <>Are you sure that you want to inactivate the shift?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      handleDelete(facilityId, shiftId);
      close();
    },
  });

  const restoreConfirmation = (facilityId, shiftId) => ({
    title: "Activate Shift",
    content: <>Are you sure that you want to activate the shift?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      handleRestore(facilityId, shiftId);
      close();
    },
  });

  const checkActiveFilter = (item) => {
    if (activeFilter === "all") return true;
    else if (activeFilter === "active" && item?.isActive) return true;
    else if (activeFilter === "inactive" && !item?.isActive) return true;
    return false;
  };
  // functions end

  const settingHeaderContent = useSettingsHeader(
    <Button
      rounded={true}
      onClick={() => {
        setShowAddEditModal(true);
      }}
    >
      Create Shift
    </Button>
  );

  const columns = [
    {
      title: "Shift",
      key: "shifts",
      width: 240,
      render: (data) => {
        return data?.shiftName;
      },
      sorter: (a, b) => {
        if (a.shiftName?.toLowerCase?.() < b.shiftName?.toLowerCase?.())
          return -1;
        if (a.shiftName?.toLowerCase?.() > b.shiftName?.toLowerCase?.())
          return 1;
        return 0;
      },
    },
    {
      title: "Location",
      dataIndex: "facilityName",
      sorter: (a, b) => {
        if (a.facilityName?.toLowerCase?.() < b.facilityName?.toLowerCase?.())
          return -1;
        if (a.facilityName?.toLowerCase?.() > b.facilityName?.toLowerCase?.())
          return 1;
        return 0;
      },
    },
    {
      title: "Active",
      key: "active",
      width: 100,
      render: (data) => {
        if (data?.isActive) return "Active";
        return "Inactive";
      },
    },
    {
      title: "",
      key: "action",
      align: "right",
      width: 80,
      render: (data) => {
        return (
          <Dropdown
            overlay={
              <Menu style={{ borderRadius: 6, border: "2px solid #CDD5D8 " }}>
                <Menu.Item onClick={() => setShowAddEditModal(data)} key="edit">
                  Edit
                </Menu.Item>
                <Menu.Item
                  key="delete"
                  onClick={() => {
                    if (data?.isActive)
                      Modal.warning(
                        deleteConfirmation(data.facilityId, data.shiftId)
                      );
                    else
                      Modal.warning(
                        restoreConfirmation(data.facilityId, data.shiftId)
                      );
                  }}
                >
                  {data?.isActive ? "Inactivate" : "Activate"}
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
            getPopupContainer={(triggerNode) => triggerNode?.parentNode}
          >
            <MoreOutlined style={{ fontSize: 18 }} />
          </Dropdown>
        );
      },
    },
  ];

  const searchAndFiltersContent = usePortal(
    "#settingHeader .settingsHeaderLeftSideAddon",
    <Space>
      <SearchAndFilterColumns
        columns={columns}
        hiddenColumns={hiddenColumns}
        setHiddenColumns={(columnTitles) => {
          setHiddenColumns(columnTitles);
        }}
        searchText={searchText}
        setSearchText={(text) => {
          setSearchText(text);
        }}
      />
      <Select
        style={{ width: 120 }}
        value={activeFilter}
        onChange={(value) => {
          setActiveFilter(value);
        }}
        options={[
          {
            value: "all",
            label: "All",
          },
          {
            value: "active",
            label: "Active",
          },
          {
            value: "inactive",
            label: "Inactive",
          },
        ]}
      />
    </Space>
  );
  return (
    <div>
      {settingHeaderContent}
      {searchAndFiltersContent}
      <Table
        tableLayout="auto"
        locale={{ emptyText: "No Shifts" }}
        rowKey={"shiftId"}
        dataSource={shifts?.data?.filter(
          (it) =>
            checkActiveFilter(it) &&
            (it?.shiftName
              ?.toLowerCase?.()
              .includes?.(searchText.toLowerCase()) ||
              it?.facilityName
                ?.toLowerCase?.()
                .includes?.(searchText.toLowerCase()))
        )}
        columns={columns.filter((column) => {
          if (!hiddenColumns.includes(column.title)) return true;
          return false;
        })}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: [15, 25, 50, 100, 250],
        }}
        // scroll={{ y: "calc(100vh - 80px - 96px - 60px - 100px)", x: true }}
      />
      <ShiftDrawer
        isLoadingFacilities={shifts?.isLoading}
        facilities={locations?.data}
        data={showAddEditModal}
        visible={showAddEditModal}
        onClose={() => {
          setShowAddEditModal();
        }}
        onSuccess={(values) => {
          const isEdit = showAddEditModal?.shiftId !== undefined;
          if (isEdit)
            handleUpdate(
              showAddEditModal?.facilityId,
              showAddEditModal?.shiftId,
              values
            );
          else handleAdd(values);
        }}
      />
    </div>
  );
}

Shifts.propTypes = {};

export default Shifts;
