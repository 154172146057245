import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Calendar as BigCalendar, Views } from "react-big-calendar";

import { momentLocalizer } from "react-big-calendar";
import moment from "moment";

import { ReactComponent as ChevronLeft } from "assets/icons/chevronLeftIco.svg";
import { ReactComponent as ChevronRight } from "assets/icons/chevronRightIco.svg";
// import { ReactComponent as ChevronDown } from "assets/icons/chevronDown.svg";

import { Avatar, Select, Button, DatePicker, Space } from "antd";
const { Option } = Select;

function WeekSelector({ date: propsDate, onNavigate }) {
  const date = moment(propsDate);
  const [open, setOpen] = useState(false);
  return (
    <div className="dayWeekSelectorContainer">
      <button
        className="arrows"
        onClick={() => {
          onNavigate("prev", date.subtract(1, "week"));
        }}
      >
        <ChevronLeft />
      </button>
      <div style={{ position: "relative" }}>
        <DatePicker
          dropdownClassName="dayWeekSelectorDropdown"
          defaultValue={date}
          placement="bottomLeft"
          bordered={false}
          open={open}
          className="hiddenDatePicker"
          style={{ visibility: "hidden", width: 0 }}
          onOpenChange={(open) => setOpen(open)}
          dropdownAlign={{
            offset: [-57, 14],
          }}
          value={date}
          onChange={(date) => {
            onNavigate("current", date);
          }}
        />
        <button className="dateButton" onClick={() => setOpen(!open)}>
          {date.format("MMM YYYY")}
        </button>
      </div>
      <button
        className="arrows"
        onClick={() => {
          onNavigate("prev", date.add(1, "week"));
        }}
      >
        <ChevronRight />
      </button>
    </div>
  );
}

WeekSelector.propTypes = {};

export default WeekSelector;
