import { orderBy } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRosters } from "services/roster.service";
import { RosterFilterType } from "utils/enums";
import { Tag, Typography } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

const { Title } = Typography;

export default function AvailabilityReview({
  month,
  shift,
  rosters,
  facilityShifts,
  daySlots,
}) {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);

  const [data, setData] = useState({
    data: [],
    isLoading: true,
  });

  useEffect(() => {
    let year = moment().year();
    const monthDate = moment(`${year}-01-${month}`, "YYYY-DD-MM");
    dispatch(
      getRosters({
        staffId: currentUser?.staffId,
        shiftDate: monthDate?.format("YYYY-MM-DD"),
        filterType: RosterFilterType.Monthly,
      })
    ).then((res) => {
      const data = res?.data ?? [];
      const filteredData = res?.data?.filter(
        (availability) => availability?.facilityShiftId !== shift
      );
      //   const mergedData = [...filteredData, ...shifts?.data];
      for (let data of filteredData) {
        // const slot = daySlots.find((slot) => slot.index === data.index);
        // const shift = facilityShifts.find((shift) => shift.shiftId === data.shiftId);
        data.rosterDate = moment(data?.rosterDate, "YYYY-MM-DD")?.format(
          "YYYY-MM-DD"
        );
        // data.shiftDefinition =  shift?.shiftDefinition;
      }
      let mergedRoster = [
        ...rosters?.data?.filter((it) => !it?.isBackendFetched),
        ...filteredData,
      ];
      mergedRoster = orderBy(mergedRoster, ["rosterDate"], ["asc"]);

      setData({
        data: mergedRoster,
        isLoading: false,
      });
    });
  }, []);

  return (
    <div>
      <Title
        variant={5}
        style={{ margin: 0, fontSize: 20, color: "#161F3A", marginBottom: 16 }}
      >
        Availability Review
      </Title>
      {data?.data?.length > 0 && (
        <div className="availabilityReviewContainer">
          {data?.data?.map((roster, index) => {
            const rosterDate = moment(roster?.rosterDate);
            const day = rosterDate.format("ddd");
            const date = rosterDate.format("D");

            const shift = facilityShifts?.find(
              (facilityShift) => facilityShift?.shiftId === roster?.shiftId
            );

            return (
              <div key={index} className="availabilityReviewItem">
                <span>
                  {date} {day}&nbsp;
                  {!roster?.isBackendFetched && <Tag color="green">New</Tag>}
                  {!!roster?.isRejected && <Tag color="yellow">Rejected</Tag>}
                  {!!roster?.isAbsent && <Tag color="yellow">Absent</Tag>}
                </span>
                <span>{shift?.shiftDefinition}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
