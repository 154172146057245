import React from "react";
import { Modal, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { rejectStaffRoster } from "services/roster.service";

const { Title, Text } = Typography;

export default function ShiftSchedulerItem({
  event,
  containerClass,
  loadData,
  param
}) {
  const { staffName } = event;
  const dispatch = useDispatch();
  const currentStaff = useSelector((state) => state.user.currentUser);

  const handleReject = (rosterId) => {
    dispatch(rejectStaffRoster(currentStaff?.staffId, rosterId)).then(() => {
      loadData();
    });
  };

  const rejectConfirmation = (id) => ({
    title: "Delete Shift",
    content: <>Are you sure you want to delete this shift?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      handleReject(id);
      close();
    },
  });

  return (
    <div
      style={{ cursor: "pointer",...param?.style ?? {} }}
      className={`${containerClass}`}
      onClick={() => {
        Modal.warning(rejectConfirmation(event.staffRosterId));
      }}
    >
      <div
        className={`shiftSchedulerItem ${
          event?.isRejected ? "isRejected" : ""
        }`}
      >
        <Title
          level={5}
          style={{ margin: 0, color: "#161F3A" }}
          className="textEllipsis"
        >
          {staffName}
        </Title>
        <Text
          style={{ fontSize: 14, color: "#161F3A" }}
          className="textEllipsis"
        >
          {event.start.format("hh:mm a")} - {event.end.format("hh:mm a")}
        </Text>
      </div>
    </div>
  );
}
