import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { Button, Space, Typography } from "antd";
import CancellationPolicyModal from "./CancellationPolicyModal";
import { getSingleCancellationPolicy } from "services/misc.service";

const { Text } = Typography;

function CancellationPolicyCard(props) {
  const dispatch = useDispatch();
  const { data, setRefreshPolicies } = props;
  const { facilityName } = data;
  const [showAddEditModal, setShowAddEditModal] = useState();
  const [formData2, setFormData2] = useState(null);

  const editHandler = async (data) => {
    const result = await dispatch(getSingleCancellationPolicy(data.facilityId));

    if (result?.status === "success") {
      const obj = {
        facilityId: data.facilityId,
        clinic: {
          fees: null,
          preAppointmentTime: null,
          postAppointmentTime: null,
          termsAndConditions: "",
        },
        mobile: {
          fees: null,
          preAppointmentTime: null,
          postAppointmentTime: null,
          termsAndConditions: "",
        },
        telehealth: {
          fees: null,
          preAppointmentTime: null,
          postAppointmentTime: null,
          termsAndConditions: "",
        },
      };

      result.data.forEach((ele) => {
        let name = "";
        if (ele.operationTypeId == 1) {
          name = "mobile";
        } else if (ele.operationTypeId == 2) {
          name = "clinic";
        } else if (ele.operationTypeId == 3) {
          name = "telehealth";
        }

        obj[name].fees = ele.fees;
        obj[name].preAppointmentTime = ele.preAppointmentTime;
        obj[name].postAppointmentTime = ele.postAppointmentTime;
        obj[name].termsAndConditions = ele.termsAndConditions;
      });
      setFormData2(obj);
      setShowAddEditModal(true);
    }
  };

  return (
    <div className='cancellationPolicyCard'>
      <Text>{facilityName}</Text>
      <Space>
        <Button
          type='text'
          style={{
            padding: 0,
            height: "auto",
          }}
          onClick={() => {
            editHandler(data);
            // setFormData(data);
            // setFormData(data);
            // setShowAddEditModal(true);
          }}
        >
          Edit
        </Button>
      </Space>
      <CancellationPolicyModal
        visible={showAddEditModal}
        onCancel={() => setShowAddEditModal(false)}
        formData2={formData2}
        setRefreshPolicies={setRefreshPolicies}
      />
    </div>
  );
}

CancellationPolicyCard.propTypes = {};

export default CancellationPolicyCard;
