import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "components/Modal/Modal";
import { Button } from "components/Buttons/Buttons";

import { postForm, getForm } from "services/intakeForm.service";
import Signature, { useSignature } from "components/Signature/Signature";

import { openNotificationWithIcon } from "utils/Notification";

import { Col, Form, Row } from "antd";
import {
  FormInputArea,
  FormCheckboxGroup,
  FormSlider,
  FormRadioGroup,
  FormInput,
  FormDatePicker,
  FormInputNumber,
  FormSelect,
  FormCheckbox,
} from "components/FormItems/FlatFormItems";
import moment from "moment";
import {
  submitChart,
  updateChart,
  getLegacyChart,
  getChartNotes,
  addChartNotes,
} from "services/charts.services";
import { setLoading } from "redux/actions/app.actions";
import { isUserNurse } from "utils/common";

function HistoryIntakeForm({ visible, onSubmit, onClose, user }) {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const currentStaff = useSelector((state) => state.user.currentUser);
  const [chart, setChart] = useState([]);
  const [chartNotes, setChartNotes] = useState([]);

  useEffect(async () => {
    const orderId = window.location.pathname.split("/")[3];
    const lineItemId = window.location.pathname.split("/")[5];
    dispatch(setLoading(true));
    const chart = await dispatch(getLegacyChart(orderId, lineItemId));
    if (chart.length) {
      if (chart[0].chart) {
        const data = JSON.parse(chart[0].chart);
        setFormChecked(data);
        chart[0].chart = data;
        setChart(chart);
        form.setFieldsValue({
          ...data,
          expirationDate1: moment(data.expirationDate1),
          expirationDate2: moment(data.expirationDate2),
          expirationDate3: moment(data.expirationDate3),
        });
        setSignatureValue(data.signature);
        setSignatureValue1(chart[0].physicianSignature);
      } else {
        setChart(chart);
      }
    }
    const notes = await getChartNotes(chart[0].chartId);
    setChartNotes(notes);
    dispatch(setLoading(false));
  }, []);

  const [signature, getSignatureValue, clearSignatureValue, setSignatureValue] =
    useSignature("intakeForm", form);
  const [
    signature1,
    getSignatureValue1,
    clearSignatureValue1,
    setSignatureValue1,
  ] = useSignature("intakeForm1", form, "signature1");
  const [formChecked, setFormChecked] = useState({
    nameChecked: false,
    dobChecked: false,
    infusionChecked: false,
    historyReviewedChecked: false,
    contraindicationsChecked: false,
    consentsSignedChecked: false,
    allergieReviewedChecked: false,
    meetSanitationPolicyChecked: false,
    epinephrineChecked: false,
    benadrylChecked: false,
    ASAChecked: false,
    albuterolChecked: false,
    baseFluidNS500Checked: false,
    additiveAscorbicAcidChecked: false,
    additiveMineralBlendChecked: false,
    additiveVitaComplexChecked: false,
    additiveZincSulphateChecked: false,
    additiveGlutathioneChecked: false,
    boostGlutathioneChecked: false,
    boostLipoMinoChecked: false,
    boostToradolKeterolacChecked: false,
    boostTriImmuneChecked: false,
    boostVitaminDChecked: false,
    boostZincSulphateChecked: false,
    boostOndansetronZofranChecked: false,
    boostNS500Checked: false,
    attempt1Checked: false,
    attempt2Checked: false,
    instructionsReviewedChecked: false,
    vitalsChartedChecked: false,
    vitalStableChecked: false,
    removeIVandCleanChecked: false,
    complicationsNoted: false,
    alertAndOrientedChecked: false,
  });

  return (
    <Form
      initialValues={{}}
      // requiredMark={false}
      form={form}
      layout="vertical"
      className=""
      onFinishFailed={({ values, errorFields, outOfDate }) => {
        console.log(form.getFieldValue("infusionChecked"));
        form.scrollToField(errorFields[0].name, {
          scrollMode: "if-needed",
          block: "center",
          behavior: "smooth",
        });
      }}
      onFinish={async (values) => {
        values.signature = getSignatureValue();
        const data = {
          orderId: window.location.pathname.split("/")[3],
          orderLineItemId: window.location.pathname.split("/")[5],
          chart: JSON.stringify(values),
        };
        if (
          currentStaff?.staffId === chart[0]?.staffId &&
          !chart[0].staffSignature
        ) {
          if (getSignatureValue()) {
            data.staffSignature = getSignatureValue();
          } else {
            return openNotificationWithIcon(
              "warning",
              "Signature Required",
              "Please sign the chart before submitting"
            );
          }
        } else if (currentStaff?.staffId === chart[0]?.staffPhysicianId) {
          if (getSignatureValue1()) {
            data.physicianSignature = getSignatureValue1();
          } else {
            return openNotificationWithIcon(
              "warning",
              "Signature Required",
              "Please sign the chart before submitting"
            );
          }
        }
        dispatch(setLoading(true));
        if (chart.length) {
          await dispatch(updateChart(data, chart[0].chartId));
        } else {
          await dispatch(submitChart(data));
        }
        dispatch(setLoading(false));
        setTimeout(() => {
          window.close();
        }, 3000);
      }}
    >
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Guest Information:
      </p>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"nameChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({ ...formChecked, nameChecked: e.target.checked })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.nameChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="name"
            label="Confirmed Name?"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"dobChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({ ...formChecked, dobChecked: e.target.checked })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.dobChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="dob"
            label="Confirmed DOB?"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"infusionChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                infusionChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.infusionChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="infusion"
            label="Date of Infusion?"
            placeholder="Enter here..."
          />
        </Col>
      </Row>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "2px solid #EFF1F4",
          padding: "20px 0px",
          marginBottom: 20,
        }}
      >
        <h1
          style={{
            fontFamily: "tradeGothic",
            fontWeight: "bold",
            fontSize: 20,
            color: "#000",
          }}
        >
          Emergency Meds Standing Order
        </h1>
      </div>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        In case of adverse reaction to any infusion or injection*
      </p>

      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"epinephrineChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                epinephrineChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.epinephrineChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="epinephrine"
            label="Epinephrine: 1:1000 0.3 mg IM (opposite arm of IV site, repeat every 5 minutes x3 doses)"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"benadrylChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                benadrylChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.benadrylChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="benadryl"
            label="Benadryl: 25 mg IVP (every 4-6 hours)"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"ASAChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({ ...formChecked, ASAChecked: e.target.checked })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.ASAChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="ASA"
            label="ASA: 324 mg PO (chew)"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"albuterolChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                albuterolChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.albuterolChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="albuterol"
            label="Albuterol 2 puffs for wheezing"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "2px solid #EFF1F4",
          padding: "20px 0px",
          marginBottom: 20,
        }}
      >
        <h1
          style={{
            fontFamily: "tradeGothic",
            fontWeight: "bold",
            fontSize: 20,
            color: "#000",
          }}
        >
          Contraindications & Precautions
        </h1>
      </div>

      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Pregnant/breastfeeding
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Previous history of infusion issues
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Shunt or lymphectomy
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Allergic to any ingredient
      </p>

      <FormSelect
        required
        feedBack={false}
        variant="underlined"
        disabled={chart[0]?.staffSignature}
        name="IVCatheterGuage"
        label="IV Catheter Gauge"
        initialValue={null}
        renderValue="value"
        placeholder={"Select an option"}
        renderLabel="label"
        styles={{ border: "none" }}
        className={"multiSelect"}
        options={[
          { name: "20g" },
          { name: "22g" },
          { name: "24g" },
          { name: "other" },
        ].map((item) => ({
          key: item.name,
          label: item.name,
          value: item.name,
        }))}
      />
      <FormSelect
        required
        feedBack={false}
        variant="underlined"
        disabled={chart[0]?.staffSignature}
        name="IVLocation"
        label="IV Location"
        initialValue={null}
        renderValue="value"
        placeholder={"Select an option"}
        renderLabel="label"
        styles={{ border: "none" }}
        className={"multiSelect"}
        options={[
          { name: "Right Hand" },
          { name: "Right Wrist" },
          { name: "Right Forearm" },
          { name: "Right Antecubital (AC)" },
          { name: "Left Hand" },
          { name: "Left Wrist" },
          { name: "Left Forearm" },
          { name: "Left Antecubital (AC)" },
          { name: "Other" },
        ].map((item) => ({
          key: item.name,
          label: item.name,
          value: item.name,
        }))}
      />
      <FormSelect
        feedBack={false}
        variant="underlined"
        disabled={chart[0]?.staffSignature}
        name="IMNeedleGuage"
        label="IM Needle Gauge"
        initialValue={null}
        renderValue="value"
        placeholder={"Select an option"}
        renderLabel="label"
        styles={{ border: "none" }}
        className={"multiSelect"}
        options={[
          { name: "25 Gauge" },
          { name: "27 Gauge" },
          { name: "Other" },
          { name: "N/A" },
        ].map((item) => ({
          key: item.name,
          label: item.name,
          value: item.name,
        }))}
      />
      <FormSelect
        feedBack={false}
        variant="underlined"
        disabled={chart[0]?.staffSignature}
        name="IMNeedleLength"
        label="IM Needle Length"
        initialValue={null}
        renderValue="value"
        placeholder={"Select an option"}
        renderLabel="label"
        styles={{ border: "none" }}
        className={"multiSelect"}
        options={[
          { name: "1 inch" },
          { name: "1.25 inch" },
          { name: "1.5 inch" },
          { name: "Other" },
          { name: "N/A" },
        ].map((item) => ({
          key: item.name,
          label: item.name,
          value: item.name,
        }))}
      />
      <FormSelect
        feedBack={false}
        variant="underlined"
        disabled={chart[0]?.staffSignature}
        name="IMLocation"
        label="IM Location"
        initialValue={null}
        renderValue="value"
        placeholder={"Select an option"}
        renderLabel="label"
        styles={{ border: "none" }}
        className={"multiSelect"}
        options={[
          { name: "Left Deltoid" },
          { name: "Right Deltoid" },
          { name: "Upper left quadrant of left dorsogluteal" },
          { name: "Upper right quadrant of right dorsogluteal" },
          { name: "Other location" },
          { name: "N/A" },
        ].map((item) => ({
          key: item.name,
          label: item.name,
          value: item.name,
        }))}
      />

      <p
        style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}
      >{`IV Access *Do not attempt more than x2. **In notes please write if guest tolerated or any complications***`}</p>

      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"attempt1Checked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                attempt1Checked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.attempt1Checked}
            formItemStyles={{ width: "100%" }}
            // styles={{ width: '96%', fontSize: 18, height: 40, borderRadius: 8 }}
            name="attempt1"
            label="Attempt 1"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"attempt2Checked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                attempt2Checked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={false}
            formItemStyles={{ width: "100%" }}
            // styles={{ width: '96%', fontSize: 18, height: 40, borderRadius: 8 }}
            name="attempt2"
            label="Attempt 2"
            placeholder="Enter here..."
          />
        </Col>
      </Row>

      <Row wrap={false}>
        <Col flex="auto">
          <FormInputArea
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={false}
            formItemStyles={{ width: "100%" }}
            // styles={{ width: '96%', fontSize: 18, height: 40, borderRadius: 8 }}
            name="reasonsForEvents"
            label="Reason for Administration/Event(s) that occurred"
            placeholder="Enter here..."
          />
        </Col>
      </Row>

      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Vitals
      </p>

      <Row gutter={[36, 0]} style={{ marginBottom: 4 }}>
        <Col xs={24} md={6}>
          <FormInputNumber
            required
            disabled={chart[0]?.staffSignature}
            name="bodyTemp1"
            label="Body Temp. (°F)"
            placeholder="Body Temp. (°F)"
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInputNumber
            required
            disabled={chart[0]?.staffSignature}
            name="pulse1"
            label="Pulse (BPM)"
            placeholder="Pulse (BPM)"
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInputNumber
            required
            disabled={chart[0]?.staffSignature}
            name="respRate1"
            label="Resp. Rate (bpm)"
            placeholder="Resp. Rate (bpm)"
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            disabled={chart[0]?.staffSignature}
            required
            name="bp1"
            styles={{
              fontSize: 16,
              height: 49,
            }}
            label="BP (mmHG)"
            placeholder="BP (mmHG)"
          />
        </Col>
      </Row>

      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Vitals
      </p>

      <Row gutter={[36, 0]} style={{ marginBottom: 4 }}>
        <Col xs={24} md={6}>
          <FormInputNumber
            required
            disabled={chart[0]?.staffSignature}
            name="bodyTemp2"
            label="Body Temp. (°F)"
            placeholder="Body Temp. (°F)"
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInputNumber
            required
            disabled={chart[0]?.staffSignature}
            name="pulse2"
            label="Pulse (BPM)"
            placeholder="Pulse (BPM)"
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInputNumber
            required
            disabled={chart[0]?.staffSignature}
            name="respRate2"
            label="Resp. Rate (bpm)"
            placeholder="Resp. Rate (bpm)"
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            disabled={chart[0]?.staffSignature}
            required
            name="bp2"
            styles={{
              fontSize: 16,
              height: 49,
            }}
            label="BP (mmHG)"
            placeholder="BP (mmHG)"
          />
        </Col>
      </Row>

      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Vitals
      </p>

      <Row gutter={[36, 0]} style={{ marginBottom: 4 }}>
        <Col xs={24} md={6}>
          <FormInputNumber
            required
            disabled={chart[0]?.staffSignature}
            name="bodyTemp3"
            label="Body Temp. (°F)"
            placeholder="Body Temp. (°F)"
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInputNumber
            required
            disabled={chart[0]?.staffSignature}
            name="pulse3"
            label="Pulse (BPM)"
            placeholder="Pulse (BPM)"
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInputNumber
            required
            disabled={chart[0]?.staffSignature}
            name="respRate3"
            label="Resp. Rate (bpm)"
            placeholder="Resp. Rate (bpm)"
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            disabled={chart[0]?.staffSignature}
            required
            name="bp3"
            styles={{
              fontSize: 16,
              height: 49,
            }}
            label="BP (mmHG)"
            placeholder="BP (mmHG)"
          />
        </Col>
      </Row>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Vitals
      </p>

      <Row gutter={[36, 0]} style={{ marginBottom: 4 }}>
        <Col xs={24} md={6}>
          <FormInputNumber
            required
            disabled={chart[0]?.staffSignature}
            name="bodyTemp4"
            label="Body Temp. (°F)"
            placeholder="Body Temp. (°F)"
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInputNumber
            required
            disabled={chart[0]?.staffSignature}
            name="pulse4"
            label="Pulse (BPM)"
            placeholder="Pulse (BPM)"
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInputNumber
            required
            disabled={chart[0]?.staffSignature}
            name="respRate4"
            label="Resp. Rate (bpm)"
            placeholder="Resp. Rate (bpm)"
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            disabled={chart[0]?.staffSignature}
            required
            name="bp4"
            styles={{
              fontSize: 16,
              height: 49,
            }}
            label="BP (mmHG)"
            placeholder="BP (mmHG)"
          />
        </Col>
      </Row>

      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Chart vitals during event
      </p>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "2px solid #EFF1F4",
          padding: "20px 0px",
          marginBottom: 20,
        }}
      >
        <h1
          style={{
            fontFamily: "tradeGothic",
            fontWeight: "bold",
            fontSize: 20,
            color: "#000",
          }}
        >
          Discharge
        </h1>
      </div>
      <p
        style={{
          fontFamily: "tradeGothic",
          fontSize: 18,
          marginBottom: 24,
          marginTop: 24,
        }}
      >
        Check the following*
      </p>

      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"vitalsChartedChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                vitalsChartedChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.vitalsChartedChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="vitalsCharted"
            label="Vitals Charted"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"removeIVandCleanChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                removeIVandCleanChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.removeIVandCleanChecked}
            formItemStyles={{ width: "100%" }}
            rows={6}
            name="removeIVandClean"
            label="Remove IV catheter, apply pressure, and place clean dressing"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"instructionsReviewedChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                instructionsReviewedChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.instructionsReviewedChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="instructionsReviewed"
            label="Discharge instructions reviewed"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"vitalStableChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                vitalStableChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.vitalStableChecked}
            formItemStyles={{ width: "100%" }}
            rows={6}
            name="vitalStable"
            label="Vitals stable"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"complicationsNoted"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                complicationsNoted: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.complicationsNoted}
            formItemStyles={{ width: "100%" }}
            rows={6}
            name="complications"
            label="No complications noted"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"alertAndOrientedChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                alertAndOrientedChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.alertAndOrientedChecked}
            formItemStyles={{ width: "100%" }}
            rows={6}
            name="alertAndOriented"
            label="Alert &amp; Oriented x4"
            placeholder="Enter here..."
          />
        </Col>
      </Row>

      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Stop infusion immediately and call provider if:
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        &#8226; Guest displays any adverse reaction signs or symptoms
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        &#8226; Guest with any complaints of pain or discomfort
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        &#8226; Guest requests to stop
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        &#8226; Condition of guest indicates calling 911
      </p>

      <div
        style={{
          borderBottom: "2px solid #EFF1F4",
          padding: "20px 0px",
          marginBottom: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "2px solid #EFF1F4",
            padding: "20px 0px",
            marginBottom: 20,
          }}
        >
          <h1
            style={{
              fontFamily: "tradeGothic",
              fontWeight: "bold",
              fontSize: 20,
              color: "#000",
            }}
          >
            Notes
          </h1>
        </div>
        <div>
          <div>
            {chartNotes.map((note) => (
              <div style={{ marginBottom: 16 }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p
                    style={{
                      fontFamily: "tradeGothic",
                      fontSize: 18,
                      marginBottom: 0,
                    }}
                  >
                    {note.noteDescription}
                  </p>
                  <span>
                    Created on{" "}
                    {moment
                      .utc(note.createdOn)
                      .local()
                      .format("D MMM YYYY h:mm A")}
                  </span>
                </div>
                (Created By: {note.userName})
              </div>
            ))}
          </div>
          <FormInputArea
            name={"noteDescription"}
            label={"Note"}
            disabled={true}
          />
          <Button
            rounded={true}
            disabled={true}
            onClick={async () => {
              const noteDescription = form.getFieldValue("noteDescription");
              if (
                noteDescription === undefined ||
                noteDescription?.trim() === ""
              ) {
                openNotificationWithIcon(
                  "error",
                  "Error",
                  "Please enter a note description"
                );
                return;
              }
              dispatch(setLoading(true));
              await addChartNotes(chart[0].chartId, { noteDescription });
              const notes = await getChartNotes(chart[0].chartId);
              setChartNotes(notes);
              form.setFieldsValue({ noteDescription: "" });
              dispatch(setLoading(false));
            }}
          >
            Add Note
          </Button>
        </div>
      </div>

      <h1
        style={{
          fontFamily: "tradeGothic",
          fontWeight: "bold",
          fontSize: 20,
          color: "#000",
          marginTop: 28,
        }}
      >
        Clinician Signature
      </h1>
      <Signature name="sigature" signature={signature} disabled={true} />

      <h1
        style={{
          fontFamily: "tradeGothic",
          fontWeight: "bold",
          fontSize: 20,
          color: "#000",
          marginTop: 28,
        }}
      >
        Nurse Practitioner/Physician Signature
      </h1>
      <Signature name="signature1" signature={signature1} disabled={true} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          maxWidth: 367,
          paddingBottom: 20,
        }}
      >
        <Button
          rounded={true}
          style={{ marginRight: 20 }}
          disabled={true}
          onClick={async () => {
            const values = form.getFieldsValue(true);
            // form.getFieldsValue(async (values) => {
            console.log(values);
            dispatch(setLoading(true));
            values.signature = getSignatureValue();
            const data = {
              orderId: window.location.pathname.split("/")[3],
              orderLineItemId: window.location.pathname.split("/")[5],
              chart: JSON.stringify(values),
            };
            if (chart.length) {
              await dispatch(updateChart(data, chart[0].chartId));
            } else {
              await dispatch(submitChart(data));
            }
            dispatch(setLoading(false));
          }}
        >
          Save
        </Button>
        <Button rounded={true} htmlType="submit" style={{}} disabled={true}>
          Save & Submit
        </Button>
      </div>
    </Form>
  );
}

export default function EmergencyMeds() {
  return (
    <div style={{ overflow: "auto", height: "90vh", padding: 24 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "2px solid #EFF1F4",
          padding: "20px 0px",
          marginBottom: 20,
        }}
      >
        <h1
          style={{
            fontFamily: "tradeGothic",
            fontWeight: "bold",
            fontSize: 24,
            color: "#000",
          }}
        >
          Emergency Meds
        </h1>
      </div>
      <HistoryIntakeForm />
    </div>
  );
}
