import { Avatar, Tabs } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Dropdown, Menu, Space, Table, Select, Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "../Users.scss";
import { setCurrentDistributor } from "../../../redux/actions/user.actions";
import { setLoading } from "../../../redux/actions/app.actions";
import { parsePhoneNumber } from "libphonenumber-js";
import {
  activateGuest,
  getAllGuests,
  getCustomerDetails,
  getCustomerNotes,
  getGuests,
  getCachedGuests,
  inactivateGuest,
} from "services/guests.services";

import ProfileDrawer from "components/ProfileDrawer/ProfileDrawer";
import SearchAndFilterColumns from "components/SearchAndFilterColumns/SearchAndFilterColumns";

// const Menue = () => (

// );

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 10;

const Users = () => {
  // dispatch and useSelector Start
  const dispatch = useDispatch();
  // const allGuests = useSelector((state) => state.user.allGuests);

  // dispatch and useSelectors End

  // useStates Start
  const [customer, setCustomer] = useState(null);

  const [profileVisible, setProfileVisible] = useState(false); // false or data
  const [modalVisible, setModalVisible] = useState(false);

  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [searchText, setSearchText] = useState("");

  const [guests, setGuests] = useState({
    data: [],
    isLoading: true,
    totalRecords: 0,
  });
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [sorter, setSorter] = useState({ column: "FirstName", ascend: true });
  const [activeFilter, setActiveFilter] = useState("active"); // all, active, inactive

  const abortConRef = useRef();

  // useStates End

  // functions start
  const getData = (page, pageSize, sortBy, ascend, searchText, active) => {
    setGuests((state) => ({
      ...state,
      isLoading: true,
    }));
    if (abortConRef.current) abortConRef.current.abort();
    abortConRef.current = new AbortController();

    dispatch(
      getGuests(
        (page - 1) * pageSize,
        pageSize,
        sortBy,
        ascend,
        searchText,
        active,
        abortConRef?.current?.signal
      )
    )
      .then((res) => {
        if (res?.status === "success") {
          setGuests((state) => ({
            ...state,
            data: res?.data,
            totalRecords: res?.totalRecords,
          }));
        }
      })
      .finally(() => {
        setGuests((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  };
  // functions end

  // useEffects Start
  useEffect(() => {
    let active = true;
    if (activeFilter === "active") {
      active = true;
    } else if (activeFilter === "inactive") {
      active = false;
    } else {
      active = null;
    }
    getData(page, pageSize, sorter.column, sorter.ascend, searchText, active);
  }, [page, pageSize, sorter.column, sorter.ascend, searchText, activeFilter]); //eslint-disable-line
  // useEffects End

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "FirstName",
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <div style={{ height: 40, width: 40, borderRadius: 20, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src='https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&w=1000&q=80' style={{ width: '100%', }} />
          </div> */}
          <Avatar size="large" className="headerAvatar">
            {record?.firstName?.[0]} {record?.lastName?.[0]}
          </Avatar>
          <p
            style={{
              margin: 0,
              marginLeft: 15,
              fontFamily: "tradeGothic",
              fontSize: 16,
            }}
          >
            {" "}
            {`${record.firstName}  ${record.lastName}`}
          </p>
        </div>
      ),

      sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "Email",
      sorter: true,
    },
    {
      title: "Username",
      dataIndex: "userName",
      key: "UserName",

      sorter: true,
    },
    {
      title: "Mobile Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 150,
      render: (text, record) => {
        let phoneNumber = undefined;
        try {
          phoneNumber = parsePhoneNumber(`+${record?.phoneNumber}`);
        } catch (e) {
          console.log("invalid phone number");
        }
        return (
          <>
            {phoneNumber?.formatInternational?.() || record?.phoneNumber || ""}
          </>
        );
      },
    },
    {
      title: "Bookings",
      dataIndex: "totalOrdersCount",
      key: "TotalOrdersCount",
      width: 150,
      sorter: true,
    },
    {
      title: "Active",
      key: "IsActive",
      width: 80,
      sorter: true,
      align: "center",
      render: (record) => {
        return record.isActive ? (
          <CheckCircleOutlined style={{ fontSize: 20, color: "#52c41a" }} />
        ) : (
          <CloseCircleOutlined style={{ fontSize: 20, color: "#f5222d" }} />
        );
      },
    },
    {
      title: "Activated",
      key: "IsRegistrationComplete",
      width: 90,
      sorter: true,
      align: "center",
      render: (record) => {
        return record.isRegistrationComplete ? (
          <CheckCircleOutlined style={{ fontSize: 20, color: "#52c41a" }} />
        ) : (
          <CloseCircleOutlined style={{ fontSize: 20, color: "#f5222d" }} />
        );
      },
    },
    {
      title: "Actions",
      key: "action",
      align: "right",
      width: 90,
      render: (text, record) => (
        <Dropdown
          overlay={
            <Menu style={{ borderRadius: 6, border: "2px solid #CDD5D8 " }}>
              <Menu.Item
                onClick={async () => {
                  dispatch(setLoading(true));
                  const user = await dispatch(
                    getCustomerDetails(record.customerId),
                    () => {}
                  );
                  const notesResponse = await dispatch(
                    getCustomerNotes(record?.customerId)
                  );
                  if (notesResponse?.status === "success") {
                    user.data.notes = notesResponse?.data;
                  }

                  setCustomer(user.data);
                  dispatch(setLoading(false));
                  setProfileVisible(record);
                }}
              >
                View Profile
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  Modal.warning(toggleActiveConfirmation(record));
                }}
              >
                {record?.isActive ? "Inactivate" : "Activate"}
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
          getPopupContainer={(triggerNode) => triggerNode?.parentNode}
        >
          <a onClick={(e) => e.preventDefault()}>
            <MoreOutlined style={{ fontSize: 18 }} />
          </a>
        </Dropdown>
      ),
    },
  ];

  function toggleActiveConfirmation(record) {
    return {
      title: record?.isActive ? "Inactivate Guest" : "Activate Guest",
      content: `Are you sure you want to ${
        record?.isActive ? "inactivate" : "activate"
      } the guest?`,
      centered: true,
      maskClosable: true,
      onOk: (close) => {
        let active = true;
        if (activeFilter === "active") {
          active = true;
        } else if (activeFilter === "inactive") {
          active = false;
        } else {
          active = null;
        }
        if (record?.isActive)
          dispatch(inactivateGuest(record.customerId)).then(() => {
            getData(
              page,
              pageSize,
              sorter.column,
              sorter.ascend,
              searchText,
              active
            );
            close();
          });
        else
          dispatch(activateGuest(record.customerId)).then(() => {
            getData(
              page,
              pageSize,
              sorter.column,
              sorter.ascend,
              searchText,
              active
            );
            close();
          });
      },
    };
  }

  return (
    <div>
      <div
        style={{
          height: 80,
          background: "#e5e8ee",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Space size={"large"} style={{ marginBottom: 16 }}>
          <h2
            style={{
              color: "#15213B",
              fontFamily: "tradeGothicBold",
              margin: 0,
            }}
          >{`Guests (${guests?.totalRecords})`}</h2>
          <SearchAndFilterColumns
            debouceTimeout={1000}
            cancelDebounce={true}
            columns={columns}
            hiddenColumns={hiddenColumns}
            setHiddenColumns={(columnTitles) => {
              setHiddenColumns(columnTitles);
            }}
            searchText={searchText}
            setSearchText={(text) => {
              setPage(DEFAULT_PAGE);
              setPageSize(DEFAULT_PAGE_SIZE);
              setSearchText(text);
            }}
          />
          <Select
            style={{ width: 120 }}
            value={activeFilter}
            onChange={(value) => {
              setActiveFilter(value);
            }}
            options={[
              {
                value: "all",
                label: "All",
              },
              {
                value: "active",
                label: "Active",
              },
              {
                value: "inactive",
                label: "Inactive",
              },
            ]}
          />
        </Space>
      </div>
      <Table
        page={page}
        setP
        rowKey={(record) => record.customerId}
        tableLayout="auto"
        locale={{ emptyText: "No Guests" }}
        dataSource={guests?.data ?? []}
        columns={columns.filter((column) => {
          if (!hiddenColumns.includes(column.title)) return true;
          return false;
        })}
        onChange={(pagination, filters, sorter) => {
          console.log("pagination", pagination);
          console.log("sorter", sorter);
          const { current, pageSize } = pagination;
          setPage(current);
          setPageSize(pageSize);
          setSorter({
            column: sorter?.column?.key,
            ascend: sorter?.order
              ? sorter?.order === "ascend"
                ? true
                : false
              : undefined,
          });
        }}
        pagination={{
          current: page,
          pageSize: pageSize,
          defaultPageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: true,
          pageSizeOptions: [15, 25, 50, 100, 250],
          total: guests?.totalRecords,
        }}
        scroll={{ y: "calc(100vh - 80px - 96px - 60px - 100px)", x: true }}
      />
      <ProfileDrawer
        key={customer?.customerId}
        profileVisible={profileVisible}
        data={profileVisible}
        setProfileVisible={setProfileVisible}
        user={customer}
        refreshCustomer={async () => {
          dispatch(setLoading(true));
          const user = await dispatch(
            getCustomerDetails(customer.customerId),
            () => {}
          );

          setCustomer(user.data);
          dispatch(setLoading(false));
        }}
      />
    </div>
  );
};

export default Users;
