import { cloneDeep } from "lodash";
import moment from "moment";
import { setLoading } from "redux/actions/app.actions";
import { openNotificationWithIcon } from "utils/Notification";
import {
  DUMMY_SERVICES,
  DUMMY_CATEGORIES,
  DUMMY_BOOSTS,
  setServices,
  setBoosts,
} from "./data";
import { request } from "./verb.services";

export const getAllServices = (callback, showLoading = true) => {
  return (dispatch) => {
    if (showLoading) dispatch(setLoading(true));
    return request("Products", "get", null, true, null)
      .then((res) => {
        return {
          status: "success",
          data: res.data?.filter(
            (it) => it.productType === 1 || it.productType === 3
          ), // 1 is service
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        if (showLoading) dispatch(setLoading(false));
      });
  };
};

export const getServiceById = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Products/${id}`, "get", null, true)
      .then((res) => {
        return { status: "success", data: res.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const createService = (values, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Products`, "post", values, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Service created successfully"
        );
        return { status: "success", data: res.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const deleteService = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`products/${id}/InActive`, "patch", null, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Service inactivated successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const restoreService = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`products/${id}/Activate`, "patch", null, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Service activated successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const updateService = (id, data, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Products/${id}`, "put", data, true)
      .then(() => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Service updated successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

// Boosts services start
export const getAllBoosts = (callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request("Products?productType=2", "get", null, true, null)
      .then((res) => {
        return {
          status: "success",
          data: res.data?.filter((it) => it.productType === 2), // 2 is boost
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getBoostById = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return new Promise((resolve, reject) => {
      const record = DUMMY_BOOSTS.find((iterator) => iterator.boostId === id);
      // todo: use callback
      setTimeout(() => resolve({ status: "success", data: record }), 250);
    })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const createBoost = (values, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Products`, "post", values, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Add-on created successfully"
        );
        return { status: "success", data: res.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const deleteBoost = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`products/${id}/InActive`, "patch", null, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Add-on inactivated successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const restoreBoost = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`products/${id}/Activate`, "patch", null, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Add-on activated successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const updateBoost = (id, data, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Products/${id}`, "put", data, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Add-on updated successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
