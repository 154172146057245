import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Dropdown, Menu, Modal, Select, Space, Table, Typography } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../Users.scss";
import { setCurrentStaff } from "../../../redux/actions/user.actions";
import { setLoading } from "../../../redux/actions/app.actions";
import { Button } from "../../../components/Buttons/Buttons";
import StaffDrawer from "./StaffDrawer";
import {
  addNewStaff,
  getAllStaff,
  updateStaff,
  inactivateStaff,
  restoreStaff,
  deleteStaff,
} from "../../../services/staffs.services";
import { push } from "connected-react-router";
import userDp from "../../../assets/images/user-dp.png";
import { parsePhoneNumber } from "libphonenumber-js";
import SearchAndFilterColumns from "components/SearchAndFilterColumns/SearchAndFilterColumns";
import { getFacilities } from "services/facilities.services";
import { getAllSkills } from "services/skills.services";

const { Text } = Typography;

const items = [
  { label: "item 1", key: "item-1" }, // remember to pass the key prop
  { label: "item 2", key: "item-2" }, // which is required
  {
    label: "sub menu",
    key: "submenu",
    children: [{ label: "item 3", key: "submenu-item-1" }],
  },
];
const Menue = (record, dispatch, setModalVisible, onToggleActiveHandler, onDeleteHandler) => (
  <Menu style={{ borderRadius: 6, border: "2px solid #CDD5D8 " }}>
    <Menu.Item
      key="edit"
      onClick={() => {
        dispatch(setCurrentStaff(record));
        setModalVisible(true);
        // console.log(record)
      }}
    >
      Edit
    </Menu.Item>
    <Menu.Item
      key="toggleActive"
      onClick={() => {
        dispatch(setCurrentStaff(record));
        onToggleActiveHandler();
        // console.log(record)
      }}
    >
      {record?.isActive ? "Inactivate" : "Activate"}
    </Menu.Item>
    <Menu.Item
      key="delete"
      onClick={() => {
        dispatch(setCurrentStaff(record));
        onDeleteHandler();
        // console.log(record)
      }}
    >
      Delete
    </Menu.Item>
  </Menu>
);

const Staff = () => {
  const dispatch = useDispatch();

  const [modalVisible, setModalVisible] = useState(false);

  const wholeStaff = useSelector((state) => state.user.wholeStaff);
  const currentStaff = useSelector((state) => state.user.currentStaff);
  const allFacilities = useSelector((state) => state.facilities.allFacilities);
  const allSkills = useSelector((state) => state.skills.allSkills);

  const { roles } = useSelector((state) => ({
    roles: state.roles.allRoles,
  }));
  async function loadData() {
    dispatch(setLoading(true));

    await dispatch(getAllStaff());
    dispatch(setLoading(false));
  }
  const getData = async () => {
    dispatch(setLoading(true));
    dispatch(getFacilities());
    dispatch(getAllSkills());

    await dispatch(getAllStaff());
    dispatch(setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []); //eslint-disable-line

  const onAddHandler = async (values) => {
    dispatch(setLoading(true));
    values.name = values.firstName + " " + values.lastName;
    dispatch(addNewStaff({ ...values, facilityId: 1 }, wholeStaff)).then(
      (result) => {
        if (result?.status === "success") {
          dispatch(setCurrentStaff(null));
          setModalVisible(false);
          loadData();
        } else {
          dispatch(setLoading(false));
        }
      }
    );
  };

  const onUpdateHandler = async (values) => {
    dispatch(setLoading(true));
    dispatch(
      updateStaff(
        currentStaff?.staffId,
        { ...values, facilityId: 1 },
        wholeStaff
      )
    )
      .then((result) => {
        if (result?.status === "success") {
          dispatch(setCurrentStaff(null));
          setModalVisible(false);
        }
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const handleDelete = (id) => {
    dispatch(setLoading(true));
    dispatch(deleteStaff(id)).then((result) => {
      if (result?.status === "success") {
        dispatch(setCurrentStaff(null));
        loadData();
      } else {
        dispatch(setLoading(false));
      }
    });
  };

  const handleInactivate = (id) => {
    dispatch(setLoading(true));
    dispatch(inactivateStaff(id)).then((result) => {
      if (result?.status === "success") {
        dispatch(setCurrentStaff(null));
        loadData();
      } else {
        dispatch(setLoading(false));
      }
    });
  };
  const handleRestore = (id) => {
    dispatch(setLoading(true));
    dispatch(restoreStaff(id)).then((result) => {
      if (result?.status === "success") {
        dispatch(setCurrentStaff(null));
        loadData();
      } else {
        dispatch(setLoading(false));
      }
    });
  };
  const deleteConfirmation = (id) => ({
    title: "Delete Staff",
    content: <>Are you sure that you want to delete the staff?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      handleDelete(id);
      close();
    },
  });

  const inactivateConfirmation = (id) => ({
    title: "Inactivate Staff",
    content: <>Are you sure that you want to inactivate the staff?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      handleInactivate(id);
      close();
    },
  });

  const restoreConfirmation = (id) => ({
    title: "Activate Staff",
    content: <>Are you sure that you want to activate the staff?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      handleRestore(id);
      close();
    },
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => {
        let aName =
          a.firstName?.toLowerCase?.() + " " + a.lastName?.toLowerCase?.();
        let bName =
          b.firstName?.toLowerCase?.() + " " + b.lastName?.toLowerCase?.();
        if (aName < bName) return -1;
        if (aName > bName) return 1;
        return 0;
      },
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              height: 50,
              width: 50,
              borderRadius: 25,
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              alt="profilePic"
              src={record.profileImageUrl ? record.profileImageUrl : userDp}
              style={{ width: "100%" }}
            />
          </div>
          <p
            style={{
              margin: 0,
              marginLeft: 15,
              fontFamily: "tradeGothic",
              fontSize: 16,
            }}
          >
            {" "}
            {`${record.firstName}  ${record.lastName}`}
          </p>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => {
        if (a.email?.toLowerCase?.() < b.email?.toLowerCase?.()) return -1;
        if (a.email?.toLowerCase?.() > b.email?.toLowerCase?.()) return 1;
        return 0;
      },
    },
    {
      title: "Mobile Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (text, record) => {
        let phoneNumber = undefined;
        try {
          phoneNumber = parsePhoneNumber(`+${record?.phoneNumber}`);
        } catch (e) {}
        return (
          <>
            {phoneNumber?.formatInternational?.() ?? record?.phoneNumber ?? ""}
          </>
        );
      },
    },
    {
      title: "Facility",
      dataIndex: "facilityIds",
      key: "facilityIds",
      render: (text, record) => {
        const facilities = [];
        for (let i = 0; i < text.length; i++) {
          facilities.push(
            allFacilities?.find?.(
              (facility) => text[i] === facility?.facilityId
            )?.facilityName
          );
        }

        return (
          <>
            {
              // allFacilities?.map(f => {
              //   if (text.includes(f.facilityId)) {
              //     return `${f.facilityName}`
              //   }
              // }).map((p, index) => (
              //   <span style={{ margin: "0px 3px" }}>
              //     {p}
              //     {index < text?.length - 1 && ","}
              //   </span>
              // ))
              // allFacilities?.find?.(
              //   (facility) => record?.facilityId === facility?.facilityId
              // )?.facilityName

              facilities.map((p, index) => (
                <span style={{ margin: "0px 3px" }} key={p}>
                  {p}
                  {index < text?.length - 1 && ","}
                </span>
              ))
            }
          </>
        );
      },
    },
    {
      title: "Role",
      dataIndex: "booking",
      key: "booking",
      render: (text, record) => {
        let role = roles.find((role) => role.roleId === record.roleId);
        return <>{role?.roleName}</>;
      },
      sorter: (a, b) => {
        let aRole = roles.find((role) => role.roleId === a.roleId);
        let bRole = roles.find((role) => role.roleId === b.roleId);
        if (aRole?.roleName?.toLowerCase?.() < bRole?.roleName?.toLowerCase?.())
          return -1;
        if (aRole?.roleName?.toLowerCase?.() > bRole?.roleName?.toLowerCase?.())
          return 1;
        return 0;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 95,
      render: (text, record) => (
        <Text> {record?.isActive ? "Active" : "Inactive"} </Text>
      ),
    },
    {
      title: "Actions",
      key: "action",
      align: "right",
      width: 95,
      render: (text, record) => (
        <Dropdown
          overlay={() =>
            Menue(record, dispatch, setModalVisible, () => {
              if (record?.isActive)
                Modal.warning(inactivateConfirmation(record.staffId));
              else Modal.warning(restoreConfirmation(record.staffId));
            }, ()=>Modal.warning(deleteConfirmation(record.staffId)))
          }
          trigger={["click"]}
          getPopupContainer={(triggerNode) => triggerNode?.parentNode}
        >
          <a onClick={(e) => e.preventDefault()}>
            <MoreOutlined style={{ fontSize: 18 }} />
          </a>
        </Dropdown>
      ),
    },
  ];

  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [activeFilter, setActiveFilter] = useState("active"); // all, active, inactive

  const checkActiveFilter = (item) => {
    if (activeFilter === "all") return true;
    else if (activeFilter === "active" && item?.isActive) return true;
    else if (activeFilter === "inactive" && !item?.isActive) return true;
    return false;
  };

  const filteredStaff = useMemo(() => {
    return wholeStaff.filter((data) => {
      return checkActiveFilter(data);
    });
  }, [wholeStaff, activeFilter]);

  return (
    <div>
      <StaffDrawer
        visible={modalVisible}
        onClose={() => {
          setModalVisible(false);
          dispatch(setCurrentStaff(null));
        }}
        onAddHandler={onAddHandler}
        onUpdateHandler={onUpdateHandler}
      />
      <div
        style={{
          height: 80,
          background: "#e5e8ee",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Space size={"large"} style={{ marginBottom: 16 }}>
          <h2
            style={{
              color: "#15213B",
              fontFamily: "tradeGothicBold",
              margin: 0,
            }}
          >{`Staff (${filteredStaff?.length})`}</h2>

          <SearchAndFilterColumns
            columns={columns}
            hiddenColumns={hiddenColumns}
            setHiddenColumns={(columnTitles) => {
              setHiddenColumns(columnTitles);
            }}
            searchText={searchText}
            setSearchText={(text) => {
              setSearchText(text);
            }}
          />
          <Select
            style={{ width: 120 }}
            value={activeFilter}
            onChange={(value) => {
              setActiveFilter(value);
            }}
            options={[
              {
                value: "all",
                label: "All",
              },
              {
                value: "active",
                label: "Active",
              },
              {
                value: "inactive",
                label: "Inactive",
              },
            ]}
          />
        </Space>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontSize: 17,
              textDecoration: "underline",
              margin: 0,
              marginRight: 24,
              cursor: "pointer",
            }}
            onClick={() => dispatch(push("/dashboard/roles"))}
          >
            Manage Roles
          </p>
          <Button
            variant="filled"
            type="primary"
            style={{ height: 50, fontSize: 18 }}
            rounded
            onClick={() => setModalVisible(true)}
          >
            Add New Staff
          </Button>
        </div>
      </div>
      <Table
        rowKey={(record) => record.staffId}
        tableLayout="auto"
        locale={{ emptyText: "No Staff" }}
        dataSource={filteredStaff.filter((data) => {
          return (
            data.name.toLowerCase().includes(searchText.toLowerCase()) ||
            data.email.toLowerCase().includes(searchText.toLowerCase())
          );
        })}
        columns={columns.filter((column) => {
          if (!hiddenColumns.includes(column.title)) return true;
          return false;
        })}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: [15, 25, 50, 100, 250],
        }}
        scroll={{ y: "calc(100vh - 80px - 96px - 60px - 100px)" }}
      />
    </div>
  );
};

export default Staff;
