import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Drawer,
  Form,
  Row,
  Typography,
  Button as AntdButton,
  Upload,
  Table,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import {
  FormInput,
  FormHiddenSubmitButton,
  FormSelect,
} from "components/FormItems/FlatFormItems";
import { useForm } from "antd/lib/form/Form";
import { Button } from "components/Buttons/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { getClassById, getJournalEntryById } from "services/quickbook.service";
import { format_number } from "utils/common";

export default function JournalEntryDetailDrawer({
  data,
  visible,
  onClose,
  accounts,
  classes,
  subClasses,
  customers,
}) {
  const dispatch = useDispatch();
  const [completeData, setCompleteData] = useState({});

  const getData = async (id) => {
    const result = await dispatch(getJournalEntryById(id));
    if (result?.status === "success") {
      setCompleteData(result.data);
    }
  };

  useEffect(() => {
    if (visible === false) {
      setCompleteData({});
    } else if (data !== undefined) {
      getData(data);
    }
  }, [visible]);

  return (
    <Drawer
      destroyOnClose
      headerStyle={{ border: "none" }}
      className="journalEntriesDetailDrawer"
      width={"min(100vw, 800px)"}
      title={
        <div className="journalEntriesDetailTitle">
          <p>Details</p>
          <CloseOutlined onClick={onClose} />
        </div>
      }
      placement="right"
      onClose={onClose}
      visible={visible}
      closable={false}
    >
      <Table
        columns={[
          {
            width: 10,
            key: "#",
            title: "#",
            render: (record, _, index) => {
              return index + 1;
            },
          },
          {
            width: 200,
            key: "account",
            title: "Account ",
            render: (record) => {
              const account = accounts?.find(
                (it) => it?.accountId === record?.accountId
              );
              return account?.accountName;
            },
          },
          {
            width: 100,
            key: "debits",
            title: "Debits",
            render: (record) => {
              if (record?.journalPostingTypeId === 1)
                return format_number(
                  Number(record?.transactionAmount ?? 0),
                  2,
                  true
                );
              return "";
            },
          },
          {
            width: 100,
            key: "credits",
            title: "Credits",
            render: (record) => {
              if (record?.journalPostingTypeId === 2)
                return format_number(
                  Number(record?.transactionAmount ?? 0),
                  2,
                  true
                );
              return "";
            },
          },
          {
            key: "name",
            title: "Name",
            render: (record) => {
              const customer = customers?.find(
                (it) => it?.customerId === record?.customerId
              );
              return customer?.customerName;
            },
          },
          {
            key: "class",
            title: "Class",
            render: (record) => {
              const classItem = classes?.find(
                (it) => it?.classId === record?.classId
              );
              return classItem?.className;
            },
          },
          {
            key: "subClass",
            title: "Subclass",
            render: (record) => {
              const classItem = subClasses?.find(
                (it) => it?.classId === record?.subClassId
              );
              return classItem?.className;
            },
          },
        ]}
        bordered
        dataSource={completeData?.journalEntryDrafts ?? []}
        pagination={false}
        summary={() => {
          if (completeData?.journalEntryDrafts?.length > 0) {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={2}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {format_number(
                      Number(completeData?.totalDebit ?? 0),
                      2,
                      true
                    )}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell>
                    {format_number(
                      Number(completeData?.totalCredit ?? 0),
                      2,
                      true
                    )}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                  <Table.Summary.Cell></Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }
        }}
      />
    </Drawer>
  );
}
