import { cloneDeep } from "lodash";
import moment from "moment";
import { setLoading } from "redux/actions/app.actions";
import { openNotificationWithIcon } from "utils/Notification";
import { request } from "./verb.services";

let DUMMY_ACCOUNTS = [
  {
    referenceId: "0",
    accountName: "Accounts Receivable (A/R)",
  },
  {
    referenceId: "1",
    accountName: "Office expenses:Merchant account fees",
  },
  {
    referenceId: "2",
    accountName: "Accounts Receivable (A/R)",
  },
  {
    referenceId: "3",
    accountName: "Sales of Product Income:Infusion Revenue",
  },
  {
    referenceId: "4",
    accountName: "Sales of Product Income:Boost Revenue",
  },
  {
    referenceId: "5",
    accountName: "Sales of Product Income:Membership Revenue",
  },
  {
    referenceId: "6",
    accountName: "Sales of Product Income:Programs Revenue",
  },
  {
    referenceId: "7",
    accountName: "Sales",
  },
  {
    referenceId: "8",
    accountName: "Undistibuted Tips",
  },
  {
    referenceId: "9",
    accountName: "Cost of goods sold",
  },
  {
    referenceId: "10",
    accountName: "Inventory Asset",
  },
];

let DUMMY_CLASSES = [
  {
    referenceId: "11",
    className: "AZMK",
  },
  {
    referenceId: "12",
    className: "KCMK",
  },
];

let DUMMY_SUBCLASSES = [
  {
    referenceId: "13",
    subclassName: "AZC",
  },
  {
    referenceId: "14",
    subclassName: "AZM",
  },
  {
    referenceId: "15",
    subclassName: "CFOP",
  },
  {
    referenceId: "16",
    subclassName: "CFP",
  },
  {
    referenceId: "17",
    subclassName: "KCM",
  },
];

let DUMMY_CUSTOMERS = [
  {
    referenceId: "13",
    customerName: "Elevate Payments",
  },
];

// ACCOUNT SERVICES START
export const getAllAccounts = (showLoading = true) => {
  return (dispatch) => {
    if (showLoading) dispatch(setLoading(true));
    return request("Accounts", "get", null, true, null)
      .then((res) => {
        return {
          status: "success",
          data: res.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        if (showLoading) dispatch(setLoading(false));
      });
  };
};

export const getAccountById = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Accounts/${id}`, "get", null, true)
      .then((res) => {
        return { status: "success", data: res.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const createAccount = (values, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Accounts`, "post", values, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Account created successfully"
        );
        return { status: "success", data: res.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const deleteAccount = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    // return request(`Accounts/${id}/InActive`, "patch", null, true)
    return request(`Accounts/${id}`, "patch", null, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Account deleted successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const updateAccount = (id, data, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Accounts/${id}`, "put", data, true)
      .then(() => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Account updated successfully"
        );
        callback?.();
        return {
          status: "success",
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
// ACCOUNT SERVICES END

// CLASS SERVICES START
export const getAllClasses = (callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request("Classes", "get", null, true, null)
      .then((res) => {
        return {
          status: "success",
          data: res.data?.filter((it) => it.classTypeId == 1),
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getClassById = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Classes/${id}`, "get", null, true)
      .then((res) => {
        return { status: "success", data: res.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const createClass = (values, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Classes`, "post", values, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Class created successfully"
        );
        return { status: "success", data: res.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const deleteClass = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Classes/${id}`, "patch", null, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Class deleted successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const updateClass = (id, data, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Classes/${id}?classId=${id}`, "put", data, true)
      .then(() => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Class updated successfully"
        );
        callback?.();
        return {
          status: "success",
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
// CLASS SERVICES END

// SUBCLASSES SERVICES START
export const getAllSubclasses = (callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request("Classes", "get", null, true, null)
      .then((res) => {
        return {
          status: "success",
          data: res.data?.filter((it) => it.classTypeId == 2),
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getSubclassById = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Classes/${id}`, "get", null, true)
      .then((res) => {
        return { status: "success", data: res.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const createSubclass = (values, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Classes`, "post", values, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Subclass created successfully"
        );
        return { status: "success", data: res.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const deleteSubclass = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Classes/${id}`, "patch", null, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Subclass deleted successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const updateSubclass = (id, data, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Classes/${id}?classsId=${id}&classId=${id}`,
      "put",
      data,
      true
    )
      .then(() => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Subclass updated successfully"
        );
        callback?.();
        return {
          status: "success",
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
// SUBCLASSES SERVICES END

// CUSTOMERS SERVICES START
export const getAllCustomers = (callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request("JournalEntryCustomers", "get", null, true, null)
      .then((res) => {
        return {
          status: "success",
          data: res.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getCustomerById = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`JournalEntryCustomers/${id}`, "get", null, true)
      .then((res) => {
        return { status: "success", data: res.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const createCustomer = (values, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`JournalEntryCustomers`, "post", values, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Customer created successfully"
        );
        return { status: "success", data: res.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const deleteCustomer = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`JournalEntryCustomers/${id}`, "patch", null, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Customer deleted successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const updateCustomer = (id, data, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `JournalEntryCustomers?journalEntryCustomerId=${id}`,
      "put",
      data,
      true
    )
      .then(() => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Customer updated successfully"
        );
        callback?.();
        return {
          status: "success",
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
// CUSTOMERS SERVICES END

// RUNSCRIPT START
export const getAllJournalEntries = (callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request("JournalEntries", "get", null, true, null)
      .then((res) => {
        return {
          status: "success",
          data: res.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const runScript = (journalEntryHeaderId, userId) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `JournalEntries/${journalEntryHeaderId}/Complete?userId=${userId}`,
      "patch",
      null,
      true,
      null
    )
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Journal entry completed successfully"
        );
        return {
          status: "success",
          data: res.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getJournalEntryById = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`JournalEntries/${id}`, "get", null, true)
      .then((res) => {
        return { status: "success", data: res.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
// RUNSCRIPT END
