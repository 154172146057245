import React, { useEffect, useState } from "react";
import {
  Space,
  Typography,
  Button as AntdButton,
  Radio,
  Grid,
  Spin,
} from "antd";

import "./GiftCardRow.scss";
import GiftCardsList from "./GiftCardsList";
import GiftCardModal from "./GiftCardModal";
import { getCustomerWalletCredits } from "services/guests.services";
import { useDispatch } from "react-redux";
import GiftCardRedeemModal from "./GiftCardRedeemModal";

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

export default function GiftCardRow({
  view = "expanded", // "expanded" | "collapsed"
  credit,
  isCreditLoading,
  customerId,
  disabled,
  onSave,
  onChange,
  giftCards = [],
  loading,
  customer,
  paymentMethod,
  refreshGiftCards,
  refreshCredits,
}) {
  const screens = useBreakpoint();

  const [showAdd, setShowAdd] = useState(false);
  const [showViewAll, setShowViewAll] = useState(false);
  // const [showRedeem, setShowRedeem] = useState(false);

  return (
    <div
      className="giftCardsRow"
      style={{
        flexDirection: true && !screens.md ? "column" : "row",
        alignItems: true && screens.md ? "center" : "flex-end",
      }}
    >
      <Space style={{ width: "100%", justifyContent: "space-between" }}>
        {view !== "concise" && (
          <Title level={4} style={{ fontSize: 17, color: "#000", margin: 0 }}>
            Gift Cards
          </Title>
        )}

        {customerId && (
          <>
            {loading || isCreditLoading ? (
              <Spin style={{ marginLeft: 28 }} />
            ) : (
              <Text style={{ marginRight: 8 }}>
                Total Balance: ${credit ?? 0}
              </Text>
            )}
          </>
        )}
      </Space>
      {customerId && (
        <Space direction="horizontal" size={2}>
          {giftCards?.length > 0 && (
            <AntdButton
              type="text"
              //   disabled={disabled}
              //   onClick={() => {
              //     setShowChangeModal(true);
              //   }}
              onClick={() => {
                setShowViewAll(true);
              }}
            >
              View All
            </AntdButton>
          )}
          {/* <AntdButton
            type="text"
            //   disabled={disabled}
            onClick={() => {
              setShowRedeem(true);
            }}
          >
            Redeem
          </AntdButton> */}
          <AntdButton
            type="text"
            //   disabled={disabled}
            onClick={() => {
              setShowAdd(true);
            }}
          >
            Add
          </AntdButton>
        </Space>
      )}
      {/* Drawers Start */}
      <GiftCardsList
        paymentMethod={paymentMethod}
        customer={customer}
        onView={(giftCard) => setShowAdd(giftCard)}
        visible={showViewAll}
        onClose={() => {
          setShowViewAll(false);
        }}
        loading={loading}
        giftCards={giftCards}
        refreshGiftCards={refreshGiftCards}
        refreshCredits={refreshCredits}
      />
      <GiftCardModal
        paymentMethod={paymentMethod}
        customer={customer}
        visible={!!showAdd}
        data={showAdd}
        onCancel={() => setShowAdd()}
        onSave={() => {
          setShowAdd();
          refreshGiftCards();
        }}
      />
      {/* <GiftCardRedeemModal
        customer={customer}
        visible={showRedeem}
        onCancel={() => setShowRedeem()}
        onSave={() => {
          setShowRedeem();
          refreshCredits();
        }}
      /> */}

      {/* Drawers End */}
    </div>
  );
}
