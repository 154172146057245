import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Form,
  Row,
  Col,
  Typography,
  Button as AntdButton,
  List,
  Avatar,
} from "antd";
import { Button } from "components/Buttons/Buttons";
import Modal from "components/Modal/Modal";
import {
  FormDatePicker,
  FormHiddenSubmitButton,
  FormInput,
  FormInputArea,
  FormInputNumber,
} from "components/FormItems/FlatFormItems";
import { useForm } from "antd/lib/form/Form";
import { FormSelect } from "components/FormItems/FlatFormItems";
import moment from "moment";
import { Carousel } from "antd";
import DayWeekSelector from "components/Calendar/components/DayWeekSelector";
import { useDispatch } from "react-redux";
import { getRosters, updateRosterSequence } from "services/roster.service";
import { UserOutlined } from "@ant-design/icons";
import { RosterFilterType } from "utils/enums";

const { Title, Text } = Typography;

export default function ScheduleShiftModal({
  visible,
  onCancel,
  facilities,
  isFacilitiesLoadings,
  shifts,
  isShiftsLoading,
  loadData,
  schdulerFacilityId,
}) {
  const [form] = useForm();
  const submitBtnRef = useRef();

  const facilityId = Form.useWatch("facilityId", form);
  const shiftId = Form.useWatch("shiftId", form);
  const date = Form.useWatch("date", form);

  const [rosters, setRosters] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (visible) {
      if(schdulerFacilityId){
        form.setFieldsValue({
          facilityId: schdulerFacilityId,
        })
      }
    } else {
      form.resetFields();
      setRosters([]);
    }
  }, [visible]);

  useEffect(() => {
    if (!!facilityId && !!shiftId && !!date) {
      dispatch(
        getRosters({
          shiftDate: date?.format("YYYY-MM-DD"),
          shiftId,
          facilityId,
          filterType: RosterFilterType.Daily,

        })
      ).then((res) => {
        if (res?.status === "success") {
          setRosters(res?.data ?? []);
          form.setFieldsValue({
            rosters: (res?.data ?? [])?.map((roster) => {
              return {
                sequenceNumber: roster?.staffSequence,
                staffRosterId: roster?.staffRosterId,
              };
            }),
          });
        } else {
          setRosters([]);
        }
      });
    }
  }, [facilityId, shiftId, date]);

  const filteredShifts = useMemo(() => {
    if (!facilityId) return [];
    return shifts.filter((shift) => shift?.facilityId === facilityId);
  }, [facilityId]);

  return (
    <Modal
      className="scheduleShiftModal"
      //   closable={closable}
      destroyOnClose={true}
      //   headerBorder={false}
      width="720px"
      title="Scheduling Sequence"
      onCancel={() => onCancel()}
      visible={visible}
      onOk={() => console.log("ok")}
      okText="Add"
      centered={true}
      footer={
        <div className="d-flex justify-between align-center">
          <AntdButton type="text" onClick={onCancel}>
            Cancel
          </AntdButton>
          <Button
            style={{ height: 50 }}
            rounded={true}
            onClick={() => submitBtnRef?.current?.click()}
            //   disabled={!Boolean(setupIntent)}
          >
            Save
          </Button>
        </div>
      }
      //   cancelText="No"
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={(values) => {
          dispatch(updateRosterSequence(values?.rosters)).then((res) => {
            if (res?.status === "success") {
              loadData();
              onCancel();
            }
          });
        }}
        onFinishFailed={(errorFields) => {
          console.log("failed to submit values, ", errorFields);
          // form.scrollToField(errorFields[0].name, {
          //   scrollMode: "if-needed",
          //   block: "center",
          //   behavior: "smooth",
          // });
        }}
      >
        <Row gutter={[16, 16]} style={{ marginBottom: 12 }}>
          <Col xs={24} md={12}>
            <FormSelect
              label="Select Location"
              placeholder={"Select Location"}
              name="facilityId"
              variant="underlined"
              options={facilities}
              renderLabel="facilityName"
              renderValue="facilityId"
              formItemStyles={{ margin: 0 }}
            />
          </Col>
          <Col xs={24} md={12}>
            <FormSelect
              label="Select Shift"
              placeholder={"Select Shift"}
              name="shiftId"
              variant="underlined"
              options={filteredShifts}
              renderValue={"shiftId"}
              renderLabel={"shiftName"}
              formItemStyles={{ margin: 0 }}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <FormDatePicker
              form={form}
              variant="underlined"
              name="date"
              label="Select Date"
              placeholder="Select Date"
            />
          </Col>
        </Row>
        <List
          className="scheduledStaffList"
          header={
            <>
              <div style={{ width: 100 }}>ID</div>
              <div style={{ flex: 1 }}>Name</div>
              <div style={{ width: 100 }}>Sequences</div>
            </>
          }
          bordered
          dataSource={rosters}
          renderItem={(item, index) => (
            <List.Item>
              <div style={{ width: 100 }}>{item?.staffRosterId}</div>
              <div style={{ flex: 1 }}>
                <Avatar
                  icon={<UserOutlined />}
                  src={item?.staffUrl}
                  // size="large"
                  style={{
                    // backgroundColor: "#88ebc2",
                    marginRight: 8,
                    // minWidth: 40,
                  }}
                />
                <span>{item?.staffName}</span>
              </div>
              <div style={{ width: 100 }}>
                <FormInputNumber
                  type="number"
                  name={["rosters", index, "sequenceNumber"]}
                  variant="underlined"
                  formItemStyles={{ margin: 0, width: 80 }}
                  label=""
                />
                <FormInput
                  type="text"
                  name={["rosters", index, "staffRosterId"]}
                  variant="underlined"
                  hidden={true}
                  formItemStyles={{ margin: 0 }}
                  label=""
                />
              </div>
            </List.Item>
          )}
        />
        <FormHiddenSubmitButton submitRef={submitBtnRef} />
      </Form>
    </Modal>
  );
}
