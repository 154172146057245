import React, { useMemo, useState } from "react";
import { List, Typography } from "antd";
import "./Availability.scss";
import { Button } from "components/Buttons/Buttons";
import AvailabilityCard from "./components/AvailabilityCard";
import AvailabilityDrawer from "./components/AvailabilityDrawer";
import { useDispatch, useSelector } from "react-redux";
import { getRosters } from "services/roster.service";
import { getMyShifts } from "services/shifts.service";
import { getAllLocations } from "services/locationSettings.service";
import moment from "moment";

import { Months } from "utils/enums";

const { Title } = Typography;
const WINDOW = 2;

export default function Availability() {
  const [visible, setVisible] = useState(false);
  const [facilityShifts, setFacilityShifts] = useState({
    data: [],
    isLoading: true,
  });

  const [data, setData] = useState({
    data: [],
    isLoading: true,
  });
  const { currentUser } = useSelector((state) => ({
    currentUser: state.user.currentUser,
  }));
  const dispatch = useDispatch();
  useState(() => {
    dispatch(getMyShifts(false)).then((res) => {
      if (res?.status === "success") {
        setFacilityShifts({
          data: res?.data ?? [],
          isLoading: false,
        });
      } else {
        setFacilityShifts({
          data: [],
          isLoading: false,
        });
      }
    });

    // dispatch(getRosters()).then((res) => {
    //   if (res?.status === "success") {
    //     setData({
    //       isLoading: false,
    //       data: res?.data ?? {},
    //     });
    //   } else {
    //     setData({
    //       isLoading: false,
    //       data: {},
    //     });
    //   }
    // });
  }, []);

  const monthOptions = useMemo(() => {
    const options = [];
    const month = moment().month() + 1; // 0 based index
    const window = currentUser?.maxNurseAvailabilityInAdvance ?? WINDOW;
    options.push({
      label: Months?.[month]?.fullName,
      value: month,
      year: moment().year(),
    });
    for (let i = 1; i <= window; i++) {
      let nextMonth = month + i;
      if (month + i <= 12)
        options.push({
          label: Months?.[nextMonth]?.fullName,
          value: nextMonth,
          year: moment().year(),
        });
      else {
        nextMonth = month + i - 12;
        options.push({
          label: Months?.[nextMonth]?.fullName,
          value: nextMonth,
          year: moment().year() + 1,
        });
      }
    }
    return options;
  }, []);

  return (
    <div className="availabilityContainer">
      <div className="availabilityHeader">
        <Title className="availabilityTitle" style={{}} level={2}>
          My Availability
        </Title>
        <Button
          rounded
          onClick={() => {
            setVisible(true);
          }}
        >
          Add Availability
        </Button>
      </div>
      <div className="availabilityBody">
        <List
          locale={{ emptyText: "No Data" }}
          className="cancellationPolicyList"
          itemLayout="vertical"
          size="large"
          // loading={data?.isLoading}
          dataSource={monthOptions}
          renderItem={(it, index) => (
            <List.Item key={index}>
              <AvailabilityCard key={it.id} data={it} setVisible={setVisible} />
            </List.Item>
          )}
        />
      </div>
      <AvailabilityDrawer
        data={visible}
        monthOptions={monthOptions}
        facilityShifts={facilityShifts?.data}
        isLoadingFacilityShifts={facilityShifts?.isLoading}
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
      />
    </div>
  );
}
