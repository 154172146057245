import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "components/Modal/Modal";
import { Button } from "components/Buttons/Buttons";
import moment from "moment";
import { postForm, getForm } from "services/intakeForm.service";
import Signature, { useSignature } from "components/Signature/Signature";

import { openNotificationWithIcon } from "utils/Notification";

import { Col, Form, Grid, Row } from "antd";
import {
  FormInputArea,
  FormCheckboxGroup,
  FormSlider,
  FormRadioGroup,
  FormInput,
  FormDatePicker,
  FormInputNumber,
  FormSelect,
  FormCheckbox,
} from "components/FormItems/FlatFormItems";
import {
  submitChart,
  updateChart,
  getLegacyChart,
  getChartNotes,
  addChartNotes,
} from "services/charts.services";
import { setLoading } from "redux/actions/app.actions";
import { isUserNurse } from "utils/common";
const { useBreakpoint } = Grid;
const FORM_NAME = "PhysicalHistory";
function HistoryIntakeForm({ visible, onSubmit, onClose, user }) {
  const [form] = Form.useForm();
  const screens = useBreakpoint();

  const dispatch = useDispatch();
  const currentStaff = useSelector((state) => state.user.currentUser);

  const [chart, setChart] = useState([]);
  const [chartNotes, setChartNotes] = useState([]);
  useEffect(async () => {
    const orderId = window.location.pathname.split("/")[3];
    const lineItemId = window.location.pathname.split("/")[5];
    dispatch(setLoading(true));
    const chart = await dispatch(getLegacyChart(orderId, lineItemId));
    if (chart.length) {
      if (chart[0].chart) {
        const data = JSON.parse(chart[0].chart);
        setFormChecked(data);
        chart[0].chart = data;
        setChart(chart);
        form.setFieldsValue({
          ...data,
          additiveMineralBlendExpiry: data.additiveMineralBlendExpiry
            ? moment(data.additiveMineralBlendExpiry)
            : null,
          additiveVitaminDExpiry: data.additiveVitaminDExpiry
            ? moment(data.additiveVitaminDExpiry)
            : null,
          boostToradolKeterolacExpiry: data.boostToradolKeterolacExpiry
            ? moment(data.boostToradolKeterolacExpiry)
            : null,
          baseFluidNS500Expiry: data.baseFluidNS500Expiry
            ? moment(data.baseFluidNS500Expiry)
            : null,
          additiveAscorbicAcidExpiry: data.additiveAscorbicAcidExpiry
            ? moment(data.additiveAscorbicAcidExpiry)
            : null,
          additiveVitaComplexExpiry: data.additiveVitaComplexExpiry
            ? moment(data.additiveVitaComplexExpiry)
            : null,
          additiveGlutathioneExpiry: data.additiveGlutathioneExpiry
            ? moment(data.additiveGlutathioneExpiry)
            : null,
          additiveZincSulphateExpiry: data.additiveZincSulphateExpiry
            ? moment(data.additiveZincSulphateExpiry)
            : null,
          boostB12Expiry: data.boostB12Expiry
            ? moment(data.boostB12Expiry)
            : null,
          boostGlutathioneExpiry: data.boostGlutathioneExpiry
            ? moment(data.boostGlutathioneExpiry)
            : null,
          boostLipoMinoExpiry: data.boostLipoMinoExpiry
            ? moment(data.boostLipoMinoExpiry)
            : null,
          boostLipoPlusExpiry: data.boostLipoPlusExpiry
            ? moment(data.boostLipoPlusExpiry)
            : null,
          boostTriImmuneExpiry: data.boostTriImmuneExpiry
            ? moment(data.boostTriImmuneExpiry)
            : null,
          boostVitaminDExpiry: data.boostVitaminDExpiry
            ? moment(data.boostVitaminDExpiry)
            : null,
          boostZincSulphateExpiry: data.boostZincSulphateExpiry
            ? moment(data.boostZincSulphateExpiry)
            : null,
          boostNS500Expiry: data.boostNS500Expiry
            ? moment(data.boostNS500Expiry)
            : null,
          boostOndansetronZofranExpiry: data.boostOndansetronZofranExpiry
            ? moment(data.boostOndansetronZofranExpiry)
            : null,
          nicontinamideAdenineDinculceotideExpiry:
            data.nicontinamideAdenineDinculceotideExpiry
              ? moment(data.nicontinamideAdenineDinculceotideExpiry)
              : null,
          additiveDiluentExpiry: data.additiveDiluentExpiry
            ? moment(data.additiveDiluentExpiry)
            : null,
          baseMyresCocktailExpiry: data.baseMyresCocktailExpiry
            ? moment(data.baseMyresCocktailExpiry)
            : null,
        });
        setSignatureValue(data.signature);
        setSignatureValue1(chart[0].physicianSignature);
      } else {
        setChart(chart);
      }
    }
    const notes = await getChartNotes(chart[0].chartId);
    setChartNotes(notes);
    dispatch(setLoading(false));
  }, []);

  const [signature, getSignatureValue, clearSignatureValue, setSignatureValue] =
    useSignature("intakeForm", form);
  const [
    signature1,
    getSignatureValue1,
    clearSignatureValue1,
    setSignatureValue1,
  ] = useSignature("intakeForm1", form, "signature1");
  const [formChecked, setFormChecked] = useState({
    nameChecked: false,
    dobChecked: false,
    infusionChecked: false,
    historyReviewedChecked: false,
    contraindicationsChecked: false,
    consentsSignedChecked: false,
    allergieReviewedChecked: false,
    meetSanitationPolicyChecked: false,
    baseFluidNS5002HoursChecked: false,
    baseFluidNS500Checked: false,
    AdditiveMyersCocktailChecked: false,
    boostGlutathioneChecked: false,
    boostToradolKeterolacChecked: false,
    boostTriImmuneChecked: false,
    boostVitaminDChecked: false,
    boostZincSulphateChecked: false,
    boostOndansetronZofranChecked: false,
    boostNS500Checked: false,
    attempt1Checked: false,
    attempt2Checked: false,
    instructionsReviewedChecked: false,
    vitalsChartedChecked: false,
    vitalStableChecked: false,
    removeIVandCleanChecked: false,
    complicationsNoted: false,
    alertAndOrientedChecked: false,
    additiveNicontinamideAdenineDinculceotideChecked: false,
    additiveAiluentTenmlsWhenReconstitutingChecked: false,
    boostB12Checked: false,
    boostLipoMinoChecked: false,
    boostLipoPlusChecked: false,
    baseMyresCocktail10mlIVChecked: false,
  });
  return (
    <Form
      initialValues={{}}
      form={form}
      layout="vertical"
      className=""
      onFinishFailed={({ values, errorFields, outOfDate }) => {
        console.log(form.getFieldValue("infusionChecked"));
        form.scrollToField(errorFields[0].name, {
          scrollMode: "if-needed",
          block: "center",
          behavior: "smooth",
        });
      }}
      onFinish={async (values) => {
        values.signature = getSignatureValue();
        const data = {
          orderId: window.location.pathname.split("/")[3],
          orderLineItemId: window.location.pathname.split("/")[5],
          chart: JSON.stringify(values),
        };
        if (
          currentStaff?.staffId === chart[0]?.staffId &&
          !chart[0].staffSignature
        ) {
          if (getSignatureValue()) {
            data.staffSignature = getSignatureValue();
          } else {
            return openNotificationWithIcon(
              "warning",
              "Signature Required",
              "Please sign the chart before submitting"
            );
          }
        } else if (currentStaff?.staffId === chart[0]?.staffPhysicianId) {
          if (getSignatureValue1()) {
            data.physicianSignature = getSignatureValue1();
          } else {
            return openNotificationWithIcon(
              "warning",
              "Signature Required",
              "Please sign the chart before submitting"
            );
          }
        }
        dispatch(setLoading(true));
        if (chart.length) {
          await dispatch(updateChart(data, chart[0].chartId));
        } else {
          await dispatch(submitChart(data));
        }
        dispatch(setLoading(false));
        setTimeout(() => {
          window.close();
        }, 3000);
      }}
    >
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Guest Information:
      </p>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"nameChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({ ...formChecked, nameChecked: e.target.checked })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.nameChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="name"
            label="Confirmed Name?"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"dobChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({ ...formChecked, dobChecked: e.target.checked })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.dobChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="dob"
            label="Confirmed DOB?"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"infusionChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                infusionChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.infusionChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="infusion"
            label="Date of Infusion?"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"historyReviewedChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                historyReviewedChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.historyReviewedChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="historyReviewed"
            label="History and Physical Reviewed? Changes (document in box if yes)?"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"contraindicationsChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                contraindicationsChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.contraindicationsChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="contraindications"
            label="No Contraindications?"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"consentsSignedChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                consentsSignedChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.consentsSignedChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="consentsSigned"
            label="Consents and Waivers Signed?"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"allergieReviewedChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                allergieReviewedChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.allergieReviewedChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="allergieReviewed"
            label="Allergies Reviewed?"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"meetSanitationPolicyChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                meetSanitationPolicyChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.meetSanitationPolicyChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="meetSanitationPolicy"
            label="Environment meets sanitation policy?"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "2px solid #EFF1F4",
          padding: "20px 0px",
          marginBottom: 20,
        }}
      >
        <h1
          style={{
            fontFamily: "tradeGothic",
            fontWeight: "bold",
            fontSize: 20,
            color: "#000",
          }}
        >
          Contraindications &amp; Precautions
        </h1>
      </div>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        &#8226; Renal Disease
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        &#8226; Kidney Disease
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        &#8226; Liver Disease
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        &#8226; Congestive Heart Failure
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        &#8226; Pregnant/Breastfeeding
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        &#8226; Previous history of infusion issues
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        &#8226; Shunt or lymphectomy
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        &#8226; Ascorbic Acid: Do not use if kidney stones, sodium restricted
        diets, anticoagulant therapy.
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        &#8226; Glutathione: Do not use in asthma patients
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        &#8226; Methyl (B12): Do not use if current ongoing infection, low iron;
        taking PPI, alcohol, metformin. Avoid grapefruit consumption
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        &#8226; DO NOT GIVE LIPO INJECTION IF SULFA ALLERGY
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        &#8226; Toradol/keterolac: Do not use if hx of peptic ulcers, GI bleeds;
        DO NOT GIVE IF CURRENTLY TAKING ASPIRIN/NSAID/NAPROXEN
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        &#8226; Vitamin D: Do not use if high calcium/phos blood levels,
        arteriosclerosis, kidney stones; currently taking anticonvulsants,
        lipitor, dovonex, digoxin, cardizem, tiazac, thiazide diuretics,
        steroids, stimulant laxatives.
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Zofran/ondansetron: Do not use if low mag/potassium blood levels,
        serotonin syndrome, heart conditions
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "2px solid #EFF1F4",
          padding: "20px 0px",
          marginBottom: 20,
        }}
      >
        <h1
          style={{
            fontFamily: "tradeGothic",
            fontWeight: "bold",
            fontSize: 20,
            color: "#000",
          }}
        >
          Myer's Cocktail Standing Order
        </h1>
      </div>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Base Fluid
      </p>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"baseFluidNS500Checked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                baseFluidNS500Checked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={false}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="baseFluidNS500"
            label="0.9% NS 500 ml IV 45 minutes minimum"
          />
        </Col>
      </Row>
      <Row gutter={[36, 0]} style={{ marginBottom: 4 }}>
        <Col xs={24} md={6}>
          <FormDatePicker
            required={formChecked.baseFluidNS500Checked}
            styles={{
              width: "100%",
              fontSize: 16,
              height: 49,
              display: "flex",
              alignItems: "center",
              border: "1px solid #ddd",
              borderRadius: 4,
            }}
            name="baseFluidNS500Expiry"
            label="Expiration Date"
            form={form}
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            required={formChecked.baseFluidNS500Checked}
            name="baseFluidNS500LotNumber"
            label="Lot Number"
            placeholder=""
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            required={formChecked.baseFluidNS500Checked}
            name="baseFluidNS500Route"
            label="Route"
            placeholder=""
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInputNumber
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={formChecked.baseFluidNS500Checked}
            name="baseFluidNS500Dose"
            label="Dose (mL)"
            placeholder="Dose (mL)"
          />
        </Col>
      </Row>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Additives
      </p>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"baseMyresCocktail10mlIVChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                baseMyresCocktail10mlIVChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={false}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="baseMyresCocktail10mlIV"
            label="Myer's Cocktail 10 ml IV"
          />
        </Col>
      </Row>
      <Row gutter={[36, 0]} style={{ marginBottom: 4 }}>
        <Col xs={24} md={6}>
          <FormDatePicker
            required={formChecked.baseMyresCocktail10mlIVChecked}
            styles={{
              width: "100%",
              fontSize: 16,
              height: 49,
              display: "flex",
              alignItems: "center",
              border: "1px solid #ddd",
              borderRadius: 4,
            }}
            name="baseMyresCocktailExpiry"
            label="Expiration Date"
            form={form}
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            required={formChecked.baseMyresCocktail10mlIVChecked}
            name="baseMyresCocktailLotNumber"
            label="Lot Number"
            placeholder=""
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            required={formChecked.baseMyresCocktail10mlIVChecked}
            name="baseMyresCocktailRoute"
            label="Route"
            placeholder=""
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInputNumber
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={formChecked.baseMyresCocktail10mlIVChecked}
            name="baseMyresCocktailDose"
            label="Dose (mL)"
            placeholder="Dose (mL)"
          />
        </Col>
      </Row>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Boosts
      </p>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"boostGlutathioneChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                boostGlutathioneChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={false}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="boostGlutathione"
            label="Glutathione: IM- 1 ml (no more than 1x/week) IV- 3 ml (slow push to guest tolerance with saline flush before & after, no more than 3x/week)"
          />
        </Col>
      </Row>
      <Row gutter={[36, 0]} style={{ marginBottom: 4 }}>
        <Col xs={24} md={6}>
          <FormDatePicker
            required={formChecked.boostGlutathioneChecked}
            styles={{
              width: "100%",
              fontSize: 16,
              height: 49,
              display: "flex",
              alignItems: "center",
              border: "1px solid #ddd",
              borderRadius: 4,
            }}
            name="boostGlutathioneExpiry"
            label="Expiration Date"
            form={form}
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            required={formChecked.boostGlutathioneChecked}
            name="boostGlutathioneLotNumber"
            label="Lot Number"
            placeholder=""
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            required={formChecked.boostGlutathioneChecked}
            name="boostGlutathioneRoute"
            label="Route"
            placeholder=""
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInputNumber
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={formChecked.boostGlutathioneChecked}
            name="boostGlutathioneDose"
            label="Dose (mL)"
            placeholder="Dose (mL)"
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"boostLipoMinoChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                boostLipoMinoChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={false}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="boostLipoMino"
            label="Lipo-Mino: 1ml IM (no more than 3x/week)"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row gutter={[36, 0]} style={{ marginBottom: 4 }}>
        <Col xs={24} md={6}>
          <FormDatePicker
            required={formChecked.boostLipoMinoChecked}
            styles={{
              width: "100%",
              fontSize: 16,
              height: 49,
              display: "flex",
              alignItems: "center",
              border: "1px solid #ddd",
              borderRadius: 4,
            }}
            name="boostLipoMinoExpiry"
            label="Expiration Date"
            form={form}
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            required={formChecked.boostLipoMinoChecked}
            name="boostLipoMinoLotNumber"
            label="Lot Number"
            placeholder=""
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            required={formChecked.boostLipoMinoChecked}
            name="boostLipoMinoRoute"
            label="Route"
            placeholder=""
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInputNumber
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={formChecked.boostLipoMinoChecked}
            name="boostLipoMinoDose"
            label="Dose (mL)"
            placeholder="Dose (mL)"
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"boostLipoPlusChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                boostLipoPlusChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={false}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="boostLipoPlus"
            label="Lipo- Plus: 1ml IM (no more than 3x/week)"
            placeholder="Enter here..."
          />
        </Col>
      </Row>
      <Row gutter={[36, 0]} style={{ marginBottom: 4 }}>
        <Col xs={24} md={6}>
          <FormDatePicker
            required={formChecked.boostLipoPlusChecked}
            styles={{
              width: "100%",
              fontSize: 16,
              height: 49,
              display: "flex",
              alignItems: "center",
              border: "1px solid #ddd",
              borderRadius: 4,
            }}
            name="boostLipoPlusExpiry"
            label="Expiration Date"
            form={form}
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            required={formChecked.boostLipoPlusChecked}
            name="boostLipoPlusLotNumber"
            label="Lot Number"
            placeholder=""
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            required={formChecked.boostLipoPlusChecked}
            name="boostLipoPlusRoute"
            label="Route"
            placeholder=""
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInputNumber
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={formChecked.boostLipoPlusChecked}
            name="boostLipoPlusDose"
            label="Dose (mL)"
            placeholder="Dose (mL)"
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"boostToradolKeterolacChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                boostToradolKeterolacChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={false}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="boostToradolKeterolac"
            label="Toradol/Keterolac: 1 ml IV (slow push over 1 minute, give first; no more than 3x/week)"
          />
        </Col>
      </Row>
      <Row gutter={[36, 0]} style={{ marginBottom: 4 }}>
        <Col xs={24} md={6}>
          <FormDatePicker
            required={formChecked.boostToradolKeterolacChecked}
            styles={{
              width: "100%",
              fontSize: 16,
              height: 49,
              display: "flex",
              alignItems: "center",
              border: "1px solid #ddd",
              borderRadius: 4,
            }}
            name="boostToradolKeterolacExpiry"
            label="Expiration Date"
            form={form}
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            required={formChecked.boostToradolKeterolacChecked}
            name="boostToradolKeterolacLotNumber"
            label="Lot Number"
            placeholder=""
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            required={formChecked.boostToradolKeterolacChecked}
            name="boostToradolKeterolacRoute"
            label="Route"
            placeholder=""
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInputNumber
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={formChecked.boostToradolKeterolacChecked}
            name="boostToradolKeterolacDose"
            label="Dose (mL)"
            placeholder="Dose (mL)"
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"boostTriImmuneChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                boostTriImmuneChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={false}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="boostTriImmune"
            label="Tri-Immune Boost (Glutathione, ascorbic Acid, Zinc Sulfate): IV- 3 ml (slow push over 5 minutes longer if not tolerated by guest; no more than 3x/week) IM- 1 ml ( no more than 1x/week)"
          />
        </Col>
      </Row>
      <Row gutter={[36, 0]} style={{ marginBottom: 4 }}>
        <Col xs={24} md={6}>
          <FormDatePicker
            required={formChecked.boostTriImmuneChecked}
            styles={{
              width: "100%",
              fontSize: 16,
              height: 49,
              display: "flex",
              alignItems: "center",
              border: "1px solid #ddd",
              borderRadius: 4,
            }}
            name="boostTriImmuneExpiry"
            label="Expiration Date"
            form={form}
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            required={formChecked.boostTriImmuneChecked}
            name="boostTriImmuneLotNumber"
            label="Lot Number"
            placeholder=""
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            required={formChecked.boostTriImmuneChecked}
            name="boostTriImmuneRoute"
            label="Route"
            placeholder=""
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInputNumber
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={formChecked.boostTriImmuneChecked}
            name="boostTriImmuneDose"
            label="Dose (mL)"
            placeholder="Dose (mL)"
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"boostVitaminDChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                boostVitaminDChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={false}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="boostVitaminD"
            label="Vitamin D (empower 100,000iu/ml): 0.5 ml IM (no more than 1x/week)"
          />
        </Col>
      </Row>
      <Row gutter={[36, 0]} style={{ marginBottom: 4 }}>
        <Col xs={24} md={6}>
          <FormDatePicker
            required={formChecked.boostVitaminDChecked}
            styles={{
              width: "100%",
              fontSize: 16,
              height: 49,
              display: "flex",
              alignItems: "center",
              border: "1px solid #ddd",
              borderRadius: 4,
            }}
            name="boostVitaminDExpiry"
            label="Expiration Date"
            form={form}
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            required={formChecked.boostVitaminDChecked}
            name="boostVitaminDLotNumber"
            label="Lot Number"
            placeholder=""
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            required={formChecked.boostVitaminDChecked}
            name="boostVitaminDRoute"
            label="Route"
            placeholder=""
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInputNumber
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={formChecked.boostVitaminDChecked}
            name="boostVitaminDDose"
            label="Dose (mL)"
            placeholder="Dose (mL)"
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"boostZincSulphateChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                boostZincSulphateChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={false}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="boostZincSulphate"
            label="Zinc sulfate: 0.25 ml IV (slow push, longer if not tolerated by guest; no more than 3x/week)"
          />
        </Col>
      </Row>
      <Row gutter={[36, 0]} style={{ marginBottom: 4 }}>
        <Col xs={24} md={6}>
          <FormDatePicker
            required={formChecked.boostZincSulphateChecked}
            styles={{
              width: "100%",
              fontSize: 16,
              height: 49,
              display: "flex",
              alignItems: "center",
              border: "1px solid #ddd",
              borderRadius: 4,
            }}
            name="boostZincSulphateExpiry"
            label="Expiration Date"
            form={form}
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            required={formChecked.boostZincSulphateChecked}
            name="boostZincSulphateLotNumber"
            label="Lot Number"
            placeholder=""
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            required={formChecked.boostZincSulphateChecked}
            name="boostZincSulphateRoute"
            label="Route"
            placeholder=""
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInputNumber
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={formChecked.boostZincSulphateChecked}
            name="boostZincSulphateDose"
            label="Dose (mL)"
            placeholder="Dose (mL)"
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"boostOndansetronZofranChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                boostOndansetronZofranChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={false}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="boostOndansetronZofran"
            label="Ondansetron/Zofran(empower 2mg/ml): 2 ml IV (push first)"
          />
        </Col>
      </Row>
      <Row gutter={[36, 0]} style={{ marginBottom: 4 }}>
        <Col xs={24} md={6}>
          <FormDatePicker
            required={formChecked.boostOndansetronZofranChecked}
            styles={{
              width: "100%",
              fontSize: 16,
              height: 49,
              display: "flex",
              alignItems: "center",
              border: "1px solid #ddd",
              borderRadius: 4,
            }}
            name="boostOndansetronZofranExpiry"
            label="Expiration Date"
            form={form}
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            required={formChecked.boostOndansetronZofranChecked}
            name="boostOndansetronZofranLotNumber"
            label="Lot Number"
            placeholder=""
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            required={formChecked.boostOndansetronZofranChecked}
            name="boostOndansetronZofranRoute"
            label="Route"
            placeholder=""
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInputNumber
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={formChecked.boostOndansetronZofranChecked}
            name="boostOndansetronZofranDose"
            label="Dose (mL)"
            placeholder="Dose (mL)"
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"boostNS500Checked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                boostNS500Checked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={false}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="boostNS500"
            label="0.9% NS: 500 ml IV (hang after the initial 500 ml bag mixed with meds)"
          />
        </Col>
      </Row>
      <Row gutter={[36, 0]} style={{ marginBottom: 4 }}>
        <Col xs={24} md={6}>
          <FormDatePicker
            required={formChecked.boostNS500Checked}
            styles={{
              width: "100%",
              fontSize: 16,
              height: 49,
              display: "flex",
              alignItems: "center",
              border: "1px solid #ddd",
              borderRadius: 4,
            }}
            name="boostNS500Expiry"
            label="Expiration Date"
            form={form}
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            required={formChecked.boostNS500Checked}
            name="boostNS500LotNumber"
            label="Lot Number"
            placeholder=""
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            required={formChecked.boostNS500Checked}
            name="boostNS500Route"
            label="Route"
            placeholder=""
            disabled={chart[0]?.staffSignature}
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInputNumber
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={formChecked.boostNS500Checked}
            name="boostNS500Dose"
            label="Dose (mL)"
            placeholder="Dose (mL)"
          />
        </Col>
      </Row>{" "}
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Only give 1 lipo injection; Do not give glutathione or zinc if giving
        Tri immune boost
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "2px solid #EFF1F4",
          padding: "20px 0px",
          marginBottom: 20,
        }}
      >
        <h1
          style={{
            fontFamily: "tradeGothic",
            fontWeight: "bold",
            fontSize: 20,
            color: "#000",
          }}
        >
          Vital Signs and Equipment
        </h1>
      </div>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Upon Arrival
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        ** Do not administer if {`HR>120`} or {`BP>140`} or {`<90`} systolic
        &amp;/or {`>100`} or {`<50`} diastolic
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Vitals
      </p>
      <Row gutter={[36, 0]} style={{ marginBottom: 4 }}>
        <Col xs={24} md={6}>
          <FormInputNumber
            required
            disabled={chart[0]?.staffSignature}
            name="bodyTemp1"
            label="Body Temp. (°F)"
            placeholder="Body Temp. (°F)"
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInputNumber
            required
            disabled={chart[0]?.staffSignature}
            name="pulse1"
            label="Pulse (BPM)"
            placeholder="Pulse (BPM)"
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInputNumber
            required
            disabled={chart[0]?.staffSignature}
            name="respRate1"
            label="Resp. Rate (bpm)"
            placeholder="Resp. Rate (bpm)"
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            disabled={chart[0]?.staffSignature}
            required
            name="bp1"
            styles={{
              fontSize: 16,
              height: 49,
            }}
            label="BP (mmHG)"
            placeholder="BP (mmHG)"
          />
        </Col>
      </Row>
      <FormSelect
        required
        feedBack={false}
        variant="underlined"
        disabled={chart[0]?.staffSignature}
        name="IVCatheterGuage"
        label="IV Catheter Gauge"
        initialValue={null}
        renderValue="value"
        placeholder={"Select an option"}
        renderLabel="label"
        styles={{ border: "none" }}
        className={"multiSelect"}
        options={[
          { name: "20g" },
          { name: "22g" },
          { name: "24g" },
          { name: "other" },
        ].map((item) => ({
          key: item.name,
          label: item.name,
          value: item.name,
        }))}
      />
      <FormSelect
        required
        feedBack={false}
        variant="underlined"
        disabled={chart[0]?.staffSignature}
        name="IVLocation"
        label="IV Location"
        initialValue={null}
        renderValue="value"
        placeholder={"Select an option"}
        renderLabel="label"
        styles={{ border: "none" }}
        className={"multiSelect"}
        options={[
          { name: "Right Hand" },
          { name: "Right Wrist" },
          { name: "Right Forearm" },
          { name: "Right Antecubital (AC)" },
          { name: "Left Hand" },
          { name: "Left Wrist" },
          { name: "Left Forearm" },
          { name: "Left Antecubital (AC)" },
          { name: "Other" },
        ].map((item) => ({
          key: item.name,
          label: item.name,
          value: item.name,
        }))}
      />
      <FormSelect
        feedBack={false}
        variant="underlined"
        disabled={chart[0]?.staffSignature}
        name="IMNeedleGuage"
        label="IM Needle Gauge"
        initialValue={null}
        renderValue="value"
        placeholder={"Select an option"}
        renderLabel="label"
        styles={{ border: "none" }}
        className={"multiSelect"}
        options={[
          { name: "25 Gauge" },
          { name: "27 Gauge" },
          { name: "Other" },
          { name: "N/A" },
        ].map((item) => ({
          key: item.name,
          label: item.name,
          value: item.name,
        }))}
      />
      <FormSelect
        feedBack={false}
        variant="underlined"
        disabled={chart[0]?.staffSignature}
        name="IMNeedleLength"
        label="IM Needle Length"
        initialValue={null}
        renderValue="value"
        placeholder={"Select an option"}
        renderLabel="label"
        styles={{ border: "none" }}
        className={"multiSelect"}
        options={[
          { name: "1 inch" },
          { name: "1.25 inch" },
          { name: "1.5 inch" },
          { name: "Other" },
          { name: "N/A" },
        ].map((item) => ({
          key: item.name,
          label: item.name,
          value: item.name,
        }))}
      />
      <FormSelect
        feedBack={false}
        variant="underlined"
        disabled={chart[0]?.staffSignature}
        name="IMLocation"
        label="IM Location"
        initialValue={null}
        renderValue="value"
        placeholder={"Select an option"}
        renderLabel="label"
        styles={{ border: "none" }}
        className={"multiSelect"}
        options={[
          { name: "Left Deltoid" },
          { name: "Right Deltoid" },
          { name: "Upper left quadrant of left dorsogluteal" },
          { name: "Upper right quadrant of right dorsogluteal" },
          { name: "Other location" },
          { name: "N/A" },
        ].map((item) => ({
          key: item.name,
          label: item.name,
          value: item.name,
        }))}
      />
      <p
        style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}
      >{`IV Access *Do not attempt more than x2. **In notes please write if guest tolerated or any complications***`}</p>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"attempt1Checked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                attempt1Checked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.attempt1Checked}
            formItemStyles={{ width: "100%" }}
            // styles={{ width: '96%', fontSize: 18, height: 40, borderRadius: 8 }}
            name="attempt1"
            label="Attempt 1"
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"attempt2Checked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                attempt2Checked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={false}
            formItemStyles={{ width: "100%" }}
            // styles={{ width: '96%', fontSize: 18, height: 40, borderRadius: 8 }}
            name="attempt2"
            label="Attempt 2"
          />
        </Col>
      </Row>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Please chart pain prior to administration and 30 minutes after when
        giving toradol/keterolac
      </p>
      <FormSlider
        disabled={chart[0]?.staffSignature}
        required={false}
        name="painScale1"
        label="Pain Scale *Prior to Toradol/keterolac*"
        step={1}
        min={0}
        max={10}
        marks={{
          1: "1",
          2: "2",
          3: "3",
          4: "4",
          5: "5",
          6: "6",
          7: "7",
          8: "8",
          9: "9",
          10: "10",
        }}
      />
      <FormSlider
        disabled={chart[0]?.staffSignature}
        required={false}
        name="painScale2"
        label="30 minutes after giving Toradol/keterolac"
        step={1}
        min={0}
        max={10}
        marks={{
          1: "1",
          2: "2",
          3: "3",
          4: "4",
          5: "5",
          6: "6",
          7: "7",
          8: "8",
          9: "9",
          10: "10",
        }}
      />
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        15 minutes after infusion
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        ** Do not administer if {`HR>120`} or {`BP>140`} or {`<90`} systolic
        &amp;/or {`>100`} or {`<50`} diastolic
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Vitals
      </p>
      <Row gutter={[36, 0]} style={{ marginBottom: 4 }}>
        <Col xs={24} md={6}>
          <FormInputNumber
            required
            disabled={chart[0]?.staffSignature}
            name="bodyTemp2"
            label="Body Temp. (°F)"
            placeholder="Body Temp. (°F)"
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInputNumber
            required
            disabled={chart[0]?.staffSignature}
            name="pulse2"
            label="Pulse (BPM)"
            placeholder="Pulse (BPM)"
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInputNumber
            required
            disabled={chart[0]?.staffSignature}
            name="respRate2"
            label="Resp. Rate (bpm)"
            placeholder="Resp. Rate (bpm)"
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            disabled={chart[0]?.staffSignature}
            required
            name="bp2"
            styles={{
              fontSize: 16,
              height: 49,
            }}
            label="BP (mmHG)"
            placeholder="BP (mmHG)"
          />
        </Col>
      </Row>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        End of infusion up to 10 minutes after
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Vitals
      </p>
      <Row gutter={[36, 0]} style={{ marginBottom: 4 }}>
        <Col xs={24} md={6}>
          <FormInputNumber
            required
            disabled={chart[0]?.staffSignature}
            name="bodyTemp3"
            label="Body Temp. (°F)"
            placeholder="Body Temp. (°F)"
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInputNumber
            required
            disabled={chart[0]?.staffSignature}
            name="pulse3"
            label="Pulse (BPM)"
            placeholder="Pulse (BPM)"
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInputNumber
            required
            disabled={chart[0]?.staffSignature}
            name="respRate3"
            label="Resp. Rate (bpm)"
            placeholder="Resp. Rate (bpm)"
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            disabled={chart[0]?.staffSignature}
            required
            name="bp3"
            styles={{
              fontSize: 16,
              height: 49,
            }}
            label="BP (mmHG)"
            placeholder="BP (mmHG)"
          />
        </Col>
      </Row>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        PRN if needed
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        ** Do not administer if {`HR>120`} or {`BP>140`} or {`<90`} systolic
        &amp;/or {`>100`} or {`<50`} diastolic
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Vitals
      </p>
      <Row gutter={[36, 0]} style={{ marginBottom: 4 }}>
        <Col xs={24} md={6}>
          <FormInputNumber
            required={false}
            disabled={chart[0]?.staffSignature}
            name="bodyTemp4"
            label="Body Temp. (°F)"
            placeholder="Body Temp. (°F)"
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInputNumber
            required={false}
            disabled={chart[0]?.staffSignature}
            name="pulse4"
            label="Pulse (BPM)"
            placeholder="Pulse (BPM)"
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInputNumber
            required={false}
            disabled={chart[0]?.staffSignature}
            name="respRate4"
            label="Resp. Rate (bpm)"
            placeholder="Resp. Rate (bpm)"
          />
        </Col>
        <Col xs={24} md={6}>
          <FormInput
            disabled={chart[0]?.staffSignature}
            required={false}
            name="bp4"
            styles={{
              fontSize: 16,
              height: 49,
            }}
            label="BP (mmHG)"
            placeholder="BP (mmHG)"
          />
        </Col>
      </Row>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Stop infusion immediately and call provider if:
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        &#8226; Guest displays any adverse reaction signs or symptoms
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        &#8226; Guest with any complaints of pain or discomfort
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        &#8226; Guest requests to stop
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        &#8226; Condition of guest indicates calling 911
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "2px solid #EFF1F4",
          padding: "20px 0px",
          marginBottom: 20,
        }}
      >
        <h1
          style={{
            fontFamily: "tradeGothic",
            fontWeight: "bold",
            fontSize: 20,
            color: "#000",
          }}
        >
          Discharge
        </h1>
      </div>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Liquid Discharge Instructions for IV Infusion, IM Injection and Lab Test
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        • Apply pressure to site for 2 minutes after IV has been removed.
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        • Keep Band-aid or gauze w/coban in place for 1 hour after completion.
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        • Cold packs and arm elevation can be used for any bruising, pain
        relief, and to decrease swelling at the IV site.
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        • Any swelling that occurs should be significantly reduced in 24 hours
        after therapy.
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        • Post IV infusion symptoms are uncommon. Dehydration is the cause of
        most symptoms and concerns.
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        • We encourage drinking at least one or two 16oz bottles of water after
        your infusion.
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        • If dehydration is an issue for you, the following symptoms may be
        present:
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Headaches, nausea, joint pain, blurred vision, cramping (GI and
        Muscular), mental confusion, or disorientation.
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Most clients experience significant overall improvements:
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        • Greater hydration levels
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        • Increased energy levels
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        • Improve mental clarity
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        • Overall feeling of well-being
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Guests commonly report one of two patterns after IV infusion &amp; IM
        therapy:
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        • Guests generally feel better right away after therapy. Due to a busy
        lifestyle, many people are chronically dehydrated and deficient in
        vitamins and minerals causing them to not feel well or unbalanced. Once
        the guest is hydrated and the nutrients are replaced, their symptoms
        improve quickly.
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        • Guests sometimes feel tired or unwell. These clients are generally in
        the process of detoxifying.
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        During this detoxifying process initiated by IV therapy, toxins are
        pulled out of tissues. When they re-enter the blood stream, they
        continue to be toxic to the body until they are pushed out and
        eliminated, but they are now on their way OUT instead of on their way
        IN. Even when patients do not feel well at this stage, the process is
        one of healing and cleansing. After this period, an overall improvement
        in one’s sense of well-being is generally reported.
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Commonly asked question…
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        How often will I need IV &amp; IM Vitamin Therapy infusions? The number
        and frequency of treatments will vary depending on certain factors.
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        • Condition(s) being treated
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        • Current health status of the client and physical activity level
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        • Response of the client to the treatments
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        Most guests can repeat infusions as often as once a week. A more
        specific plan will be developed as we go along.
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        REMINDER: Call Liquid Mobile IV or your Primary Care Doctor for any of
        the following symptoms get progressively worse or you become
        uncomfortable with:
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        • Significant swelling over the IV site - Redness over the vein that is
        increasing in size.
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        • Pain in the vein/arm that is not improving over an 8–12-hour period
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        • Headache that does not resolve with increased hydration or
        over-the-counter pain relievers like aspirin, Acetaminophen or Ibuprofen
      </p>
      <p style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}>
        If you feel like you are having a life-threatening emergency, please
        call 911
      </p>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"vitalsChartedChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                vitalsChartedChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.vitalsChartedChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="vitalsCharted"
            label="Vitals Charted"
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"removeIVandCleanChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                removeIVandCleanChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.removeIVandCleanChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="removeIVandClean"
            label="Remove IV catheter, apply pressure, and place clean dressing"
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"instructionsReviewedChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                instructionsReviewedChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.instructionsReviewedChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="instructionsReviewed"
            label="Discharge instructions reviewed"
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"vitalStableChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                vitalStableChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.vitalStableChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="vitalStable"
            label="Vitals stable"
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"complicationsNoted"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                complicationsNoted: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.complicationsNoted}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="complications"
            label="No complications noted"
          />
        </Col>
      </Row>
      <Row wrap={false}>
        <Col>
          <FormCheckbox
            disabled={chart[0]?.staffSignature}
            name={"alertAndOrientedChecked"}
            style={{ marginRight: 12 }}
            onChange={(e) =>
              setFormChecked({
                ...formChecked,
                alertAndOrientedChecked: e.target.checked,
              })
            }
          />
        </Col>
        <Col flex="auto">
          <FormInput
            variant="underlined"
            disabled={chart[0]?.staffSignature}
            required={!formChecked.alertAndOrientedChecked}
            rows={6}
            formItemStyles={{ width: "100%" }}
            name="alertAndOriented"
            label="Alert &amp; Oriented x4"
          />
        </Col>
      </Row>
      <p
        style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}
      ></p>
      <p
        style={{ fontFamily: "tradeGothic", fontSize: 18, marginBottom: 4 }}
      ></p>
      <div
        style={{
          borderBottom: "2px solid #EFF1F4",
          padding: "20px 0px",
          marginBottom: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "2px solid #EFF1F4",
            padding: "20px 0px",
            marginBottom: 20,
          }}
        >
          <h1
            style={{
              fontFamily: "tradeGothic",
              fontWeight: "bold",
              fontSize: 20,
              color: "#000",
            }}
          >
            Notes
          </h1>
        </div>
        <div>
          <div>
            {chartNotes.map((note) => (
              <div style={{ marginBottom: 16 }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p
                    style={{
                      fontFamily: "tradeGothic",
                      fontSize: 18,
                      marginBottom: 0,
                    }}
                  >
                    {note.noteDescription}
                  </p>
                  <span>
                    Created on{" "}
                    {moment
                      .utc(note.createdOn)
                      .local()
                      .format("D MMM YYYY h:mm A")}
                  </span>
                </div>
                (Created By: {note.userName})
              </div>
            ))}
          </div>
          <FormInputArea
            name={"noteDescription"}
            label={"Note"}
            disabled={true}
          />
          <Button
            rounded={true}
            disabled={true}
            onClick={async () => {
              const noteDescription = form.getFieldValue("noteDescription");
              if (
                noteDescription === undefined ||
                noteDescription?.trim() === ""
              ) {
                openNotificationWithIcon(
                  "error",
                  "Error",
                  "Please enter a note description"
                );
                return;
              }
              dispatch(setLoading(true));
              await addChartNotes(chart[0].chartId, { noteDescription });
              const notes = await getChartNotes(chart[0].chartId);
              setChartNotes(notes);
              form.setFieldsValue({ noteDescription: "" });
              dispatch(setLoading(false));
            }}
          >
            Add Note
          </Button>
        </div>
      </div>
      <h1
        style={{
          fontFamily: "tradeGothic",
          fontWeight: "bold",
          fontSize: 20,
          color: "#000",
          marginTop: 28,
        }}
      >
        Clinician Signature
      </h1>
      <Signature name="sigature" signature={signature} disabled={true} />
      <h1
        style={{
          fontFamily: "tradeGothic",
          fontWeight: "bold",
          fontSize: 20,
          color: "#000",
          marginTop: 28,
        }}
      >
        Nurse Practitioner/Physician Signature
      </h1>
      <Signature name="signature1" signature={signature1} disabled={true} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          maxWidth: 367,
          paddingBottom: 20,
        }}
      >
        <Button
          rounded={true}
          style={{ marginRight: 20 }}
          disabled={true}
          onClick={async () => {
            const values = form.getFieldsValue(true);
            // form.getFieldsValue(async (values) => {
            console.log(values);
            dispatch(setLoading(true));
            values.signature = getSignatureValue();
            const data = {
              orderId: window.location.pathname.split("/")[3],
              orderLineItemId: window.location.pathname.split("/")[5],
              chart: JSON.stringify(values),
            };
            if (chart.length) {
              await dispatch(updateChart(data, chart[0].chartId));
            } else {
              await dispatch(submitChart(data));
            }
            dispatch(setLoading(false));
          }}
        >
          Save
        </Button>
        <Button rounded={true} htmlType="submit" style={{}} disabled={true}>
          Save & Submit
        </Button>
      </div>
    </Form>
  );
}

export default function MyersCocktail() {
  return (
    <div style={{ overflow: "auto", height: "90vh", padding: 24 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "2px solid #EFF1F4",
          padding: "20px 0px",
          marginBottom: 20,
        }}
      >
        <h1
          style={{
            fontFamily: "tradeGothic",
            fontWeight: "bold",
            fontSize: 24,
            color: "#000",
          }}
        >
          Myers Cocktail
        </h1>
      </div>
      <HistoryIntakeForm />
    </div>
  );
}
