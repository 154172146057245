import "./App.scss";
import "./App.less";
import { HTML5Backend } from "react-dnd-html5-backend";

// import { useDispatch } from 'react-redux';
// import { useEffect } from 'react';
// import { getItem } from './utils/storage';
// import { setUser } from './redux/actions/user.actions';
// import App from './containers/App/App'

// function AppRoot() {
//   const dispatch = useDispatch()
//   useEffect(() => {
//     const prevUser = getItem('user')
//     dispatch(setUser(prevUser))
//   }, []) // eslint-disable-line

//   return (
//     <div className='app'>
//       <App />
//     </div>
//   );
// }

// export default AppRoot;

import React, { Component, PureComponent } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import routes from "./utils/routes";
import App from "./containers/App/App";
import NotFound from "./components/ErrorPage/ErrorPage";
import { clearStorage, getItem } from "./utils/storage";
import { replace } from "connected-react-router";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import { setUser } from "./redux/actions/user.actions";
import { verifyUser } from "./services/auth.services";
import { verifyRoutePermission } from "utils/common";
import { DndProvider } from "react-dnd";
import AppLoading from "components/AppLoading/AppLoading.index";
import { getStates } from "services/location.service";

const CountryId = 3; // 3 for USA

const RouteWithSubRoutes = (route) => (
  <Route
    path={route.path}
    render={(props) => (
      // pass the sub-routes down to keep nesting
      <route.component {...props} routes={route.routes} />
    )}
  />
);

class AppRoot extends PureComponent {
  state = {
    error: null,
    errorInfo: null,
  };

  componentDidMount() {
    this.verifyUser();
    this.props.getStates(CountryId);
  }

  verifyUser = async () => {
    const user = getItem("user");
    if (user) {
      const data = await this.props.verifyUser();
      if (!data) {
        this.logOut();
      }
    }
  };

  logOut = () => {
    clearStorage();
    this.props.replace("/");
  };
  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
    // this.logOut()
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return (
        <ErrorPage
          onRefresh={() => {
            this.props.route(this.props.location.pathname);
          }}
        />
      );
    }
    return (
      <>
        {this.props.isTokenVerifying ? (
          <AppLoading />
        ) : (
          <DndProvider backend={HTML5Backend}>
            <App>
              <Switch>
                {routes
                  .filter((route) =>
                    verifyRoutePermission(
                      route,
                      this.props.currentUser?.permissions
                    )
                  )
                  .map((route, i) => {
                    return <RouteWithSubRoutes key={i} {...route} />;
                  })}
                <Route path="*" component={NotFound} />
              </Switch>
            </App>
          </DndProvider>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
    isTokenVerifying: state.user.isTokenVerifying,
  };
};

// Root.propTypes = {
//   store: PropTypes.object.isRequired,
//   history: PropTypes.object.isRequired,
//   route: PropTypes.func
// };
export default withRouter(
  connect(mapStateToProps, { replace, setUser, verifyUser, getStates })(AppRoot)
);
