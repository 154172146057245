import React from 'react'
import PropTypes from 'prop-types';
// import Img from '../../assets/images/error.svg'

const ErrorPage = ({ onClick, onRefresh }) => {
  return (
    <div className='ErrorPageContent'>
      {/* <img src={Img} alt="" /> */}
      <p>Page not found or you are unauthorized.</p>
      <button onClick={() => { }} className='ReportButton'>RELOAD</button>
    </div >
  )
}

ErrorPage.propTypes = {
  onClick: PropTypes.func,
  onRefresh: PropTypes.func
}

export default ErrorPage