import { setLoading } from "redux/actions/app.actions";
import { request } from "./verb.services";
import { openNotificationWithIcon } from "utils/Notification";
import { GIFTCARD_CONFIG_KEY } from "utils/constants";

export const updateGiftCard = (giftCardId, data, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`GiftCards/${giftCardId}`, "patch", data, true)
      .then(() => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Gift card updated successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getGiftCardById = (giftCardId, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`GiftCards/${giftCardId}`, "get", null, true)
      .then((res) => {
        return { status: "success", data: res.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

/**
 * @param {number} customerId  - id of the customer
 * */
export const getCustomerGiftCards = (customerId, callback) => {
  return (dispatch) => {
    return request(
      `GiftCards?customerId=${customerId}`,
      "get",
      null,
      true,
      null
    )
      .then((result) => {
        callback?.(result?.data);
        return {
          status: "success",
          data: result.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.message || e?.message || "Something went wrong"
        );
      })
      .finally(() => {});
  };
};

export const purchaseGiftCard = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Orders/GiftCard`, "post", data, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Gift Card Purchased",
          "Your gift card has been purchased successfully."
        );
        return {
          status: "success",
          data: res.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
        return {
          status: "error",
          message: e?.response?.data?.message || "Network error has occured",
        };
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const redeemGiftCard = (giftCardId) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`GiftCards/${giftCardId}/Redeem`, "post", {}, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Gift Card Redeemed",
          "Your gift card has been redeemed successfully."
        );
        return {
          status: "success",
          data: res.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
        return {
          status: "error",
          message: e?.response?.data?.message || "Network error has occured",
        };
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getGiftCardDesign = (loading, callback) => {
  return (dispatch) => {
    if (loading) dispatch(setLoading(true));
    return request(
      `Configurations/${GIFTCARD_CONFIG_KEY}`,
      "get",
      null,
      true,
      null
    )
      .then((res) => {
        let designValues = {};
        if (res?.data) {
          designValues = JSON.parse(decodeURI(res?.data?.value || "{}"));
        }
        return { status: "success", data: designValues };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        if (loading) dispatch(setLoading(false));
      });
  };
};
