import React, { useEffect, useReducer, useState } from "react";

import { Button } from "components/Buttons/Buttons";
import { Dropdown, Menu, Modal, Select, Space, Table, Typography } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import useSettingsHeader from "hooks/useSettingsHeader";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  createDiscoverySource,
  deleteDiscoverySource,
  getAllDiscorverySources,
  restoreDiscoverySource,
  updateDiscoverySource,
} from "services/discoverySource";
import confirm from "antd/lib/modal/confirm";
import usePortal from "hooks/usePortal";
import SearchAndFilterColumns from "components/SearchAndFilterColumns/SearchAndFilterColumns";
import { push } from "connected-react-router";
import MarkettingDrawer from "./components/MarkettingDrawer";
const { Title, Text, Link } = Typography;

const reducer = (state, action) => {
  switch (action.type) {
    case "SET":
      return action.data;
    case "ADD":
      return [...state, action.data];
    case "UPDATE":
      return state.map((data) => {
        if (data.sourceId === action.data.sourceId) return action.data;
        return data;
      });
    case "DELETE":
      return state.filter((data) => {
        if (data.sourceId === action.data.sourceId) return false;
        return true;
      });
    default:
      return state;
  }
};

export default function Marketting() {
  //states start
  const [showAddEditModal, setShowAddEditModal] = useState({
    index: null,
    data: null,
    visible: false,
  });
  const [data, localDispatch] = useReducer(reducer, []);

  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [activeFilter, setActiveFilter] = useState("active"); // all, active, inactive
  const [searchText, setSearchText] = useState("");
  //states end

  // non-data hooks start
  const dispatch = useDispatch();
  // non-data hooks end

  // use effect starts
  const loadData = async () => {
    const result = await dispatch(getAllDiscorverySources());
    if (result?.status === "success") {
      localDispatch({
        type: "SET",
        data: result.data,
      });
    }
  };
  useEffect(() => {
    loadData();
  }, []);
  // use effects end

  // functions start
  const handleAdd = (values) => {
    let newData = [...data, { sourceName: values.sourceName }];

    let newDataJSON = JSON.stringify(newData);
    let finalString = encodeURI(newDataJSON);

    dispatch(createDiscoverySource(finalString)).then((result) => {
      if (result?.status === "success") {
        setShowAddEditModal({ data: null, visible: false, index: null });
        loadData();
        // loadData();
      }
    });
  };
  const handleUpdate = (index, values) => {
    let newData = data.map((item, index2) => {
      if (index === index2) {
        return { sourceName: values.sourceName };
      }
      return item;
    });

    let newDataJSON = JSON.stringify(newData);
    let finalString = encodeURI(newDataJSON);

    dispatch(updateDiscoverySource(finalString)).then((result) => {
      if (result?.status === "success") {
        setShowAddEditModal({
          data: null,
          visible: false,
          index: null,
        });
        loadData();
        // loadData();
      }
    });
  };
  const handleDelete = (index) => {
    let newData = data.filter((item, index2) => {
      if (index === index2) {
        return false;
      }
      return true;
    });

    let newDataJSON = JSON.stringify(newData);
    let finalString = encodeURI(newDataJSON);

    dispatch(deleteDiscoverySource(finalString)).then((result) => {
      if (result?.status === "success") {
        loadData();
      }
    });
  };

  const deleteConfirmation = (index) => ({
    title: "Delete Marketing Source",
    content: (
      <>Are you sure that you want to delete the Marketing Source?</>
    ),
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      handleDelete(index);
      close();
    },
  });

  const checkActiveFilter = (item) => {
    if (activeFilter === "all") return true;
    else if (activeFilter === "active" && item?.isActive) return true;
    else if (activeFilter === "inactive" && !item?.isActive) return true;
    return false;
  };
  // functions end

  const settingHeaderContent = useSettingsHeader(
    <Button
      rounded={true}
      onClick={() => {
        setShowAddEditModal({
          data: null,
          visible: true,
          index: null,
        });
      }}
    >
      Create Marketing Source
    </Button>
  );

  const columns = [
    {
      title: "Source Name",
      dataIndex: "sourceName",
      key: "sourceName",

      sorter: (a, b) => {
        if (a.sourceName?.toLowerCase?.() < b.sourceName?.toLowerCase?.())
          return -1;
        if (a.sourceName?.toLowerCase?.() > b.sourceName?.toLowerCase?.())
          return 1;
        return 0;
      },
    },
    {
      key: "action",
      align: "right",
      width: 80,
      render: (data, _, index) => {
        return (
          <Dropdown
            overlay={
              <Menu style={{ borderRadius: 6, border: "2px solid #CDD5D8 " }}>
                <Menu.Item
                  onClick={() =>
                    setShowAddEditModal({
                      index: index,
                      data: data,
                      visible: true,
                    })
                  }
                  key="edit"
                >
                  Edit
                </Menu.Item>
                <Menu.Item
                  key="delete"
                  onClick={() => {
                    Modal.warning(deleteConfirmation(index));
                  }}
                >
                  Delete
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
            getPopupContainer={(triggerNode) => triggerNode?.parentNode}
          >
            <MoreOutlined style={{ fontSize: 18 }} />
          </Dropdown>
        );
      },
    },
  ];

  const searchAndFiltersContent = usePortal(
    "#settingHeader .settingsHeaderLeftSideAddon",
    <Space>
      <SearchAndFilterColumns
        columns={columns}
        hiddenColumns={hiddenColumns}
        setHiddenColumns={(columnTitles) => {
          setHiddenColumns(columnTitles);
        }}
        searchText={searchText}
        setSearchText={(text) => {
          setSearchText(text);
        }}
      />
      {/* <Select
        style={{ width: 120 }}
        value={activeFilter}
        onChange={(value) => {
          setActiveFilter(value);
        }}
        options={[
          {
            value: "all",
            label: "All",
          },
          {
            value: "active",
            label: "Active",
          },
          {
            value: "inactive",
            label: "Inactive",
          },
        ]}
      /> */}
    </Space>
  );
  console.log("data", data);
  return (
    <div>
      {settingHeaderContent}
      {searchAndFiltersContent}

      <Table
        rowKey={(data) => data.sourceId}
        tableLayout="auto"
        locale={{ emptyText: "No Marketing Source" }}
        dataSource={data?.filter?.((it) =>
          it?.sourceName?.toLowerCase?.().includes?.(searchText.toLowerCase())
        )}
        columns={columns.filter((column) => {
          if (!hiddenColumns.includes(column.title)) return true;
          return false;
        })}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: [15, 25, 50, 100, 250],
        }}
        // scroll={{ y: "calc(100vh - 80px - 96px - 60px - 100px)", x: true }}
      />

      <MarkettingDrawer
        data={showAddEditModal.data}
        visible={showAddEditModal.visible}
        onClose={() => {
          setShowAddEditModal({
            data: null,
            visible: false,
            index: null,
          });
        }}
        onSuccess={(values) => {
          console.log("add/update");
          const isEdit = !!showAddEditModal?.data;
          if (isEdit) handleUpdate(showAddEditModal?.index, values);
          else handleAdd(values);
        }}
      />
    </div>
  );
}
