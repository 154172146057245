import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import {
  Row,
  Col,
  Upload,
  Input,
  List,
  Typography,
  Empty,
  Button as AntdButton,
  Modal,
} from "antd";
import { Button } from "components/Buttons/Buttons";

// import { uploadFile } from "services/common.services";
import { useDispatch, useSelector } from "react-redux";

import SearchOutlined from "@ant-design/icons/SearchOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import { setLoading } from "redux/actions/app.actions";
import {
  deleteCustomerFile,
  getCustomerFiles,
  uploadCustomerFile,
} from "services/guests.services";
import moment from "moment";
import { openNotificationWithIcon } from "utils/Notification";

const { Title, Text } = Typography;

function FilesTab({ user }) {
  const dispatch = useDispatch();

  const [filesList, setFilesList] = useState([]);

  const [file, setFile] = useState(null);
  const [base64, setBase64] = useState(null);
  const [search, setSearch] = useState("");

  const currentUser = useSelector((state) => state.user.currentUser);

  const hasDeletePermission = currentUser?.permissions?.find?.((permission) => {
    return permission?.permissionName === "FileDelete";
  });

  const ref = useRef();

  const getData = async () => {
    dispatch(setLoading(true));
    const { data } = await dispatch(getCustomerFiles(user?.customerId));
    setFilesList(data);
    dispatch(setLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(async () => {
    if (base64) {
      dispatch(setLoading(true));
      const data = {
        documentName: file.name,
        documentBase64String: base64,
        customerId: user.customerId,
      };
      try {
        const document = await uploadCustomerFile(data);
        if (document) {
          setFilesList([...filesList, document.data]);
        }
        dispatch(setLoading(false));
      } catch (error) {
        dispatch(setLoading(false));
      }
    }
  }, [base64]);

  function deleteFile(documentId) {
    dispatch(deleteCustomerFile(documentId)).then((res) => {
      if (res?.status === "success") {
        getData();
      }
    });
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (
        e.target.files[0].type.includes("image") ||
        e.target.files[0].type.includes("pdf")
      ) {
        setFile(e.target.files[0]);
        getBase64(e.target.files[0]).then((data) => {
          if (data && data.includes("base64")) {
            setBase64(data.split("base64,")[1]);
          }
        });
      } else {
        openNotificationWithIcon(
          "warning",
          "Invalid File",
          "Please upload image or PDF files only"
        );
      }
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <Row>
        <Col xs={24} md={24}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={24}>
              <Input
                onChange={(e) => setSearch(e.target.value)}
                className="documentsSearchbar"
                placeholder="Search Files"
                prefix={<SearchOutlined />}
              />
            </Col>
            {/* <Col xs={10} md={6}>
              <Upload {...fileProps} fileList={[]}>
                <Button className="tradeGothicBold">Upload File</Button>
              </Upload>
            </Col> */}

            <Col span={24}>
              {filesList.length ? (
                <List
                  className="documentsList"
                  header={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Title
                        level={5}
                        className="tradeGothic colorPrimary"
                        style={{ margin: 0 }}
                      >
                        File Name
                      </Title>
                      <Title
                        level={5}
                        className="tradeGothic colorPrimary"
                        style={{
                          margin: 0,
                          paddingRight: hasDeletePermission ? 40 : 0,
                        }}
                      >
                        Upload Date
                      </Title>
                    </div>
                  }
                  dataSource={
                    !search
                      ? filesList
                      : filesList.filter((d) =>
                          d.documentName
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        )
                  }
                  renderItem={(item, index) => {
                    return (
                      <div className="documentListItem">
                        <Text
                          style={{ fontSize: 15, cursor: "pointer" }}
                          onClick={() =>
                            window.open(item.documentUrl, "_blank")
                          }
                        >
                          {" "}
                          {item.documentName}
                        </Text>
                        <div className="flex align-center gap-12">
                          <Text style={{ fontSize: 14, color: "#7F889A" }}>
                            {moment(item.uploadDate).format("DD MMM YYYY")}
                          </Text>
                          {hasDeletePermission && (
                            <AntdButton
                              type="danger"
                              shape="circle"
                              icon={<DeleteOutlined />}
                              onClick={() => {
                                Modal.confirm({
                                  title: "Delete File",
                                  content: "Are you sure you want to delete?",
                                  okText: "Yes",
                                  cancelText: "No",
                                  onOk: () => {
                                    deleteFile(item.documentId);
                                  },
                                });
                              }}
                            />
                          )}
                        </div>
                      </div>
                    );
                  }}
                />
              ) : (
                <Empty description={"No Files Available"} />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ display: "flex", justifyContent: "center" }}>
        <Button
          rounded
          type={"primary"}
          style={{ height: 50, width: "50%", borderRadius: 30, marginTop: 16 }}
          onClick={() => {
            ref.current.click();
          }}
        >
          <input
            type="file"
            ref={ref}
            style={{ display: "none" }}
            accept="images/*"
            onChange={onSelectFile}
          />
          Upload
        </Button>
      </Row>
    </div>
  );
}

FilesTab.propTypes = {};

export default FilesTab;
