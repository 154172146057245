import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Col, Row, Typography } from "antd";
import {
  BENEFIT_TYPE,
  EXCLUSION_TYPE,
  getEnumKeyByValue,
  OPERATION_TYPE,
  PROGRAM_TYPE,
} from "utils/enums";
import "./ReviewProgram.style.scss";
import moment from "moment";

const { Text, Title } = Typography;

function ReviewProgram({ data, style, products, facilities }) {
  const {
    programType,
    programName,
    purchaseCount,
    freeCount,
    productIds,
    excludedProductIds,
    fromDate,
    tillDate,
    discountType,
    operationTypeIds = [],
    facilityIds = [],
    amount,
    productExcludeType,
    benefitType,
    sellingPrice,
  } = data;
  const productNames = useMemo(() => {
    const productNames = [];

    for (let i = 0; i < productIds?.length; i++) {
      const productId = productIds[i];
      const product = products?.find((it) => it?.productId === productId);
      if (product) productNames.push(product?.productName);
    }
    return productNames?.join(", ");
  }, [products, productIds]);

  const excludedProductNames = useMemo(() => {
    const excludedProductNames = [];

    for (let i = 0; i < excludedProductIds?.length; i++) {
      const productId = excludedProductIds[i];
      const product = products?.find((it) => it?.productId === productId);
      if (product) excludedProductNames.push(product?.productName);
    }
    return excludedProductNames?.join(", ");
  }, [products, excludedProductIds]);

  const operationTypeNames = useMemo(() => {
    return operationTypeIds?.map((it) => {
      return getEnumKeyByValue(OPERATION_TYPE, it);
    });
  }, [operationTypeIds]);

  const facilityNames = useMemo(() => {
    return facilityIds.map((it) => {
      const facility = facilities.find((it2) => it2?.facilityId === it);
      return facility?.facilityName;
    });
  }, [facilityIds, facilities]);

  const startDateFormatted = fromDate?.format?.("DD MMM YYYY");
  const endDateFormatted = tillDate?.format?.("DD MMM YYYY");
  const discountTypeFormatted = getEnumKeyByValue(BENEFIT_TYPE, discountType);
  const benefitTypeFormatted = getEnumKeyByValue(BENEFIT_TYPE, benefitType);
  return (
    <div
      className="reviewProgram"
      style={{
        ...style,
      }}
    >
      <Row gutter={[32, 16]}>
        <Col xs={12}>
          <Title>Program Type</Title>
          <Text>{getEnumKeyByValue(PROGRAM_TYPE, programType)}</Text>
        </Col>
        <Col xs={12}>
          <Title>Program Name</Title>
          <Text>{programName}</Text>
        </Col>
        {programType === PROGRAM_TYPE.Program && (
          <>
            <Col xs={12}>
              <Title>Purchase Number</Title>
              <Text>{purchaseCount}</Text>
            </Col>
            <Col xs={12}>
              <Title>Free</Title>
              <Text>{freeCount}</Text>
            </Col>
            <Col xs={12}>
              <Title>Services</Title>
              <Text>
                {productExcludeType === EXCLUSION_TYPE["All Included"]
                  ? "All"
                  : productNames}
              </Text>
            </Col>
            <Col xs={12}>
              <Title>Duration</Title>
              <Text>
                {startDateFormatted} - {!!tillDate ? endDateFormatted : "NA"}
              </Text>
            </Col>
          </>
        )}
        {programType === PROGRAM_TYPE.Membership && (
          <>
            <Col xs={12}>
              <Title>Selling Price</Title>
              <Text>${sellingPrice}</Text>
            </Col>
            <Col xs={12}>
              <Title>Discount Type</Title>
              <Text>{benefitTypeFormatted}</Text>
            </Col>
            <Col xs={12}>
              <Title>Services</Title>
              <Text>
                {productExcludeType === EXCLUSION_TYPE["All Included"]
                  ? "All"
                  : productNames}
              </Text>
            </Col>
            {/* <Col xs={12}>
              <Title>Excluded Services</Title>
              <Text>{excludedProductNames || "-"}</Text>
            </Col> */}
            <Col xs={12}>
              <Title>Duration</Title>
              <Text>
              {startDateFormatted} - {!!tillDate ? endDateFormatted : "NA"}
              </Text>
            </Col>
          </>
        )}
        {programType === PROGRAM_TYPE["Voucher Code"] && (
          <>
            <Col xs={12}>
              <Title>Amount</Title>
              <Text>{amount}</Text>
            </Col>
            <Col xs={12}>
              <Title>Discount Type</Title>
              <Text>{discountTypeFormatted}</Text>
            </Col>

            <Col xs={12}>
              <Title>Service Type</Title>
              <Text>{operationTypeNames?.join?.(", ")}</Text>
            </Col>
            <Col xs={12}>
              <Title>Locations</Title>
              <Text>{facilityNames?.join(", ")}</Text>
            </Col>
            <Col xs={12}>
              <Title>Services</Title>
              <Text>{productNames}</Text>
            </Col>
          </>
        )}
        {/* <Col xs={12}>
          <Title>Usage</Title>
          <Text>Once</Text>
        </Col> */}
      </Row>
    </div>
  );
}

ReviewProgram.propTypes = {};

export default ReviewProgram;
