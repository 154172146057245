import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "components/Modal/Modal";

import { postForm,  getLegacyForm } from "services/intakeForm.service";
import Signature, { useSignature } from "components/Signature/Signature";

import { openNotificationWithIcon } from "utils/Notification";

import { Col, Form, Row } from "antd";
import {
  FormInputArea,
  FormCheckboxGroup,
} from "components/FormItems/FlatFormItems";

const Contraindications = [
  "Renal Disease",
  "Kidney Disease",
  "Congestive Heart Failure",
  "Pregnant",
  "Liver Disease",
  "Breast Feeding",
];

const Diseases = [
  "High Blood Pressure",
  "Migraines",
  "Hives or Eczema",

  "Irregular Heartbeat",
  "Bone or Joint Disease",
  "Gallbladder Disease",

  "High Cholesterol",
  "COPD",

  "Diabetes",
  "Asthma",
  "Cancer",

  "Anemia",
  "Anxiety",
  "AIDS/HIV",

  "Bleeding Disorder",
  "Depression",
  "Hepatitis B or C",

  "Stomach Pain or Reflux",
  "Stroke",
  "G6PD Deficiency",

  "Arthritis or Rheumatism",
  "Epilepsy or Seizure",
  "Frequent Urination",
  "Thyroid Disease",
  "Colitis or another Bowel Disease",
];

const ExersiseDaysFrequency = [
  { label: 0, value: 0 },
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
];

const YesNoOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const FORM_NAME = "LegacyPhysicalHistory";
function HistoryIntakeForm({ visible, onSubmit, onClose, user, disabled }) {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const { intakeForms } = useSelector((state) => {
    return { intakeForms: state.intakeForms };
  });

  const [signature, getSignatureValue, clearSignatureValue, setSignatureValue] =
    useSignature("intakeForm", form);

  const { isSubmitting, physicalHistory } = intakeForms;
  const {
    legacyIntakeFormId,
  } = user;
  const isFormSubmitted = user?.isIntakeFormSubmitted && legacyIntakeFormId;

  useEffect(() => {
    // get data
    if (isFormSubmitted) {
      dispatch(getLegacyForm(user?.customerId, FORM_NAME));
    }
  }, [user]);

  useEffect(() => {
    if (isFormSubmitted) {
      form.setFieldsValue({
        chiefComplaint: isFormSubmitted ? physicalHistory.chiefComplaint : "",
        prescriptions: isFormSubmitted ? physicalHistory.prescriptions : "",
        hyperSensitivityDescription: isFormSubmitted
          ? physicalHistory.hyperSensitivityDescription
          : "",
        diseasesDescription: isFormSubmitted
          ? physicalHistory.diseasesDescription
          : "",
        energyRating: isFormSubmitted ? physicalHistory.energyRating : 1,
        excerciseDays: isFormSubmitted ? physicalHistory.excerciseDays : 0,
        difficultyinLosingWeight: isFormSubmitted
          ? physicalHistory.difficultyinLosingWeight
          : false,
        frequentlyExperienceHeadaches: isFormSubmitted
          ? physicalHistory.frequentlyExperienceHeadaches
          : false,
        skinCondition: isFormSubmitted ? physicalHistory.skinCondition : "",
        experienceConfusion: isFormSubmitted
          ? physicalHistory.experienceConfusion
          : false,
        fatigue: isFormSubmitted ? physicalHistory.fatigue : false,
        comments: isFormSubmitted ? physicalHistory.comments : "",
        contraindications: isFormSubmitted
          ? physicalHistory.contraindications
          : [],
        diseases: isFormSubmitted ? physicalHistory.diseases : [],
      });
      clearSignatureValue()
      setSignatureValue(physicalHistory?.customerSignature);
    }

  }, [physicalHistory]);

  useEffect(() => {
    clearSignatureValue()
    setSignatureValue(physicalHistory?.customerSignature);
  }, [visible]);

  return (
    <Modal
      className="intakeForm historyForm"
      title="History/Physical and Consents"
      visible={visible}
      width={812}
      onCancel={onClose}
      footer={[]}
    >
      <Form
        disabled
        initialValues={{
          energyRating: isFormSubmitted ? physicalHistory.energyRating : 1,
        }}
        requiredMark={false}
        form={form}
        layout="vertical"
        className="intakeFormWrapper"
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
        onFinish={(values) => {
          const signature = getSignatureValue();
          if (signature === "") {
            openNotificationWithIcon(
              "error",
              "Error!",
              "Please sign the consent form"
            );
            return;
          }
          values.customerSignature = signature;

          // dispatch(postForm(user?.customerId, FORM_NAME, values)).then(() => {
          //   if (onSubmit) onSubmit();
          // });
        }}
      >
        <FormInputArea
          required={true}
          rows={6}
          name="chiefComplaint"
          label="Chief Complaint (reason for appointment)"
          placeholder="Enter here..."
          disabled
          // formItemStyles={{ marginBottom: 0 }}
        />
        <Row>
          <Col span={24}>
            <FormCheckboxGroup
              className="contradictionsChecboxes"
              name="contraindications"
              label="Please check if you have any of the following contraindications"
              options={Contraindications}
              formItemStyles={{ marginBottom: 12 }}
              disabled
            />
          </Col>
        </Row>
        <FormInputArea
          rows={6}
          name="prescriptions"
          label="Please list any prescriptions, over the counter medications, or vitamins/supplements you have taken in the last 60 days and dosages:"
          placeholder="Enter here..."
          disabled
          // formItemStyles={{ marginBottom: 0 }}
        />
        <FormInputArea
          rows={6}
          name="hyperSensitivityDescription"
          label="Are you hypersensitive to any foods, drugs, environmental toxins or chemicals? If yes, please list and explain."
          placeholder="Enter here..."
          disabled
          // formItemStyles={{ marginBottom: 0 }}
        />
        <Row>
          <Col span={24}>
            <FormCheckboxGroup
              className="diseasesCheckboxes"
              name="diseases"
              label="Please check if you ever had any of the following:"
              options={Diseases}
              disabled
            />
          </Col>
        </Row>
        <FormInputArea
          rows={6}
          name="diseasesDescription"
          label="If “yes” to any of the above, please describe further i.e (surgeries, autoimmune disorders, etc.)"
          placeholder="Enter here..."
          disabled
          // formItemStyles={{ marginBottom: 0 }}
        />

        {/* <FormSlider
          required={true}
          name="energyRating"
          label="Rate, on a scale of 1-10 (1 being minimal, 10 being max) on your level of energy during an average week."
          step={1}
          min={1}
          max={10}
          marks={{
            1: "1",
            2: "2",
            3: "3",
            4: "4",
            5: "5",
            6: "6",
            7: "7",
            8: "8",
            9: "9",
            10: "10",
          }}
        />

        <FormRadioGroup
          required={true}
          name="excerciseDays"
          label="How many days a week do you exercise?"
          options={ExersiseDaysFrequency}
        />

        <FormRadioGroup
          required={true}
          name="difficultyinLosingWeight"
          label="Do you have difficulty losing weight?"
          options={YesNoOptions}
        />

        <FormRadioGroup
          required={true}
          name="frequentlyExperienceHeadaches"
          label="Do you frequently experience headaches?"
          options={YesNoOptions}
        />

        <FormRadioGroup
          required={true}
          name="skinCondition"
          label="Do you have any skin conditions, such as, acne, itchy skin, or rosacea?"
          options={YesNoOptions}
        />

        <FormRadioGroup
          required={true}
          name="experienceConfusion"
          label="Do you experience confusion, “brain fog” or mood swings?"
          options={YesNoOptions}
        />

        <FormRadioGroup
          required={true}
          name="fatigue"
          label="Do you have fatigue that is unrelieved by sleep?"
          options={YesNoOptions}
        /> */}

        <FormInputArea
          rows={6}
          name="comments"
          label="Comments that you wish to express or additional things that you would like us to know prior to your visit?"
          placeholder=""
          disabled
          // formItemStyles={{ marginBottom: 0 }}
        />

        <Signature signature={signature} disabled />

        {/* <Button
          rounded={true}
          type="submit"
          style={{ paddingLeft: 60, paddingRight: 60 }}
          loading={isSubmitting}
        >
          Save
        </Button> */}
      </Form>
    </Modal>
  );
}

HistoryIntakeForm.propTypes = {};

export default HistoryIntakeForm;
