
export const actions = {
  SET_ALL_CHARTS: "SET_ALL_CHARTS",
  SET_CURRENT_CHART: "SET_CURRENT_CHART",
};

export const setAllCharts = data => ({
  type: actions.SET_ALL_CHARTS,
  data
})

export const setCurrentChart = data => ({
  type: actions.SET_CURRENT_CHART,
  data
})


