import { Spin } from "antd";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import "./AuthLayout.scss";
import LoginScreenImage from "assets/images/login_screen.png";

import logo from "../../assets/images/logo.png";

const AuthLayout = ({ children, loading, message }) => {
  const dispatch = useDispatch();

  return (
    <div className="whole">
      <div
        style={{
          backgroundColor: "#00000057",
          height: "100vh",
          position: "absolute",
          width: "100%",
          zIndex: 10000000,
          display: loading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Spin spinning={loading} size="large" style={{ color: "#D4008E" }} />
        <h1 style={{ color: "#D4008E", marginLeft: 8, marginBottom: 2 }}>
          {message}
        </h1>
      </div>
      <div
        className="whole_left"
        style={{
          background: "#fff",
        }}
      >
        <img
          src={`${LoginScreenImage}`}
          alt=""
          height={"80%"}
          // style={{ opacity: 0.1, position: "absolute", width: "65%" }}
        />
        <div style={{ margin: "2%" }}>
          <p style={{ fontSize: "32pt", color: "#fff" }}> </p>
        </div>
      </div>
      <div className="whole_right">
        <div className="whole_right_container">
          {/* <img src={logo} width={120} style={{ borderRadius: '60px', marginBottom: '15%' }} /> */}
          <p className="wellcomeText">Welcome to Admin Portal</p>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              cursor: "pointer",
              marginBottom: 40,
            }}
          >
            <img src={logo} style={{width:250, objectFit:'cover'}} alt="logo" />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
