import { actions } from "../actions/customerInfo.actions";

const INITIAL_STATE = {
  totalBookings: [],
  upcomingBookings: [],
  noShowBookings: [],
};

const reducer = (state = INITIAL_STATE, data) => {
  switch (data.type) {
    case actions.SET_TOTAL_BOOKINGS:
      return { ...state, totalBookings: data.data };
    case actions.SET_UPCOMING_BOOKINGS:
      return { ...state, upcomingBookings: data.data };
    case actions.SET_NOSHOW_BOOKINGS:
      return { ...state, noShowBookings: data.data };
    case actions.CLEAR_CUSTOMER_BOOKINGS:
      return {
        ...state,
        totalBookings: [],
        upcomingBookings: [],
        noShowBookings: [],
      };
    case "RESET":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
