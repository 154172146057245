import { Space, Button as AntdButton, Typography, Divider, Empty } from "antd";
import { Button } from "components/Buttons/Buttons";
import Modal from "components/Modal/Modal";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { BENEFIT_TYPE } from "utils/enums";
import moment from "moment";

import "./RewardPopup.scss";
import { getFirstRewardIdFromRewards, getProgramText } from "utils/common";

const { Text, Title } = Typography;

export default function RewardPopup({
  values,
  visible,
  onCancel,
  handleSave,
  availedRewards,
  onRedeem,
  rewards,
  facilityId
}) {
  console.log('values', values)
  const [firstAvailableRewardId, availableRewards] = useMemo(() => {
    const firstAvailableRewardIds = getFirstRewardIdFromRewards(
      rewards ?? [],
      values,
      facilityId,
      availedRewards
    );
    const filteredRewards = rewards?.filter((reward) => {
      for (let customerRewardId of reward?.customerRewardIds) {
        if (firstAvailableRewardIds?.includes(customerRewardId)) return true;
        else continue;
      }
      return false;
    });
    return [firstAvailableRewardIds, filteredRewards];
  }, [rewards, values, availedRewards, facilityId]);
  


  const getBenefitType = (reward) => {
    switch (reward.benefitType) {
      case BENEFIT_TYPE["Discount Percentage"]:
        return "Discount Percentage";
      case BENEFIT_TYPE["Discount Amount"]:
        return "Discount Amount";
      case BENEFIT_TYPE["Bonus Service"]:
        return "Bonus Service";
      case BENEFIT_TYPE["REFERAL"]:
        return "Referral Discount";
      default:
        return "Reward";
    }
  };

  const renderReward = (reward, index) => {
    const description = "";
    const amount = "";
    const expiry = "";
    const reedemableCount = "";

    return (
      <div className="rewardItem">
        <div className="rewardItemHead">
          <Title
            style={{
              fontFamily: "tradeGothic",
              fontSize: 19,
              margin: 0,
              color: "#000",
            }}
          >
            {getBenefitType(reward)}{" "}
            {!!reward?.programName && <>({reward?.programName})</>}
          </Title>
          <Button
            rounded={true}
            onClick={() => onRedeem(firstAvailableRewardId[index])}
            style={{
              padding: "4px 16px",
              height: "auto",
              fontSize: 15,
            }}
          >
            Redeem
          </Button>
        </div>
        <div className="rewardItemBody">
          {/* <span>Can be used before: {moment(reward)}</span>   */}
          {/* <span>Redemption Count {reward.redemptionLimit}</span> */}
          <span>{getProgramText(reward)}</span>
          <span>
            Expires on:{" "}
            {moment
              .utc(reward.expiryDateUtc)
              .local()
              .format("MMMM DD, yyyy - h:mmA")}
          </span>
        </div>
      </div>
    );
  };

  return (
    <Modal
      destroyOnClose={true}
      className="boostPopup"
      title="Rewards List"
      headerBorder={true}
      visible={visible}
      onCancel={onCancel}
      okText="Save & Order"
      onOk={handleSave}
      //   okButtonProps={{
      //     disabled: !initialValue || initialValue?.length <= 0,
      //   }}
      footer={<></>}
    >
      <div className="availableRewardsList">
        {availableRewards?.length === 0 && <Empty description="No Rewards" />}
        {availableRewards?.length > 0 && (
          <>
            <Text>Available rewards:</Text>
            {availableRewards?.map((reward, index) => {
              return <div key={index}>{renderReward(reward, index)}</div>;
            })}
          </>
        )}
      </div>
    </Modal>
  );
}
