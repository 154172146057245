import { openNotificationWithIcon } from "utils/Notification";
import { request } from "./verb.services";

import {
  setIntakeForms,
  setIsSubmitting,
} from "redux/actions/intakeForm.action";
import { setLoading } from "redux/actions/app.actions";

export const postForm = (formId, formVersionId, data, customerId, formName) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Forms/${formId}/Versions/${formVersionId}/Values`,
      "post",
      {
        valueJSON: JSON.stringify(data ?? "{}"),
        guestId: customerId,
      },
      true
    )
      .then(async (response) => {
        dispatch(
          setIntakeForms(formName, {
            ...data,
            isFilled: true,
          })
        );
        openNotificationWithIcon(
          "success",
          "Form submitted!",
          "Form has been submitted successfully"
        );
        return {
          status: "success",
          data: response?.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getLegacyForm = (profileId, name) => {
  return (dispatch) => {
    return request(`Guests/${profileId}/${name}`, "get", null, true)
      .then((response) => {
        const nameLower = name.toLowerCase();

        dispatch(
          setIntakeForms(
            nameLower === "legacyconsent" ? "consent" : "physicalHistory",
            response.data
          )
        );
        return response.data;
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};

export const intakeFormEmailReminder = (customerId) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Guests/IntakeReminder/${customerId}`, "patch", null, true)
      .then((response) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Email reminder has been sent successfully"
        );
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.[0] ||
            e?.message ||
            "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
