import { cloneDeep } from "lodash";
import moment from "moment";
import { setLoading } from "redux/actions/app.actions";
import { openNotificationWithIcon } from "utils/Notification";
import { request } from "./verb.services";

export const getAllPricingSchedules = (callback, isLoading = true) => {
  return (dispatch) => {
    if (isLoading) dispatch(setLoading(true));
    return request("PriceSchedule", "get", null, true, null)
      .then((res) => {
        return {
          status: "success",
          data: res.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        if (isLoading) dispatch(setLoading(false));
      });
  };
};

export const getAllPricingScheduleById = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`PriceSchedule/${id}?includePrices=true`, "get", null, true)
      .then((res) => {
        return { status: "success", data: res.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const createPricingSchedule = (values, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`PriceSchedule`, "post", values, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Pricing Schedule created successfully"
        );
        return { status: "success", data: res?.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const deletePricingSchedule = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`PriceSchedule/${id}/InActive`, "patch", null, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Pricing Schedule inactivated successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const restorePricingSchedule = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`PriceSchedule/${id}/Activate`, "patch", null, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Pricing Schedule activated successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const updatePricingSchedule = (id, data, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`PriceSchedule/${id}`, "put", data, true)
      .then(() => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Pricing Schedule updated successfully"
        );
        callback?.();
        return {
          status: "success",
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const copyPricingSchedule = (id, data, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`PriceSchedule/${id}?includePrices=true`, "get", null, true)
      .then((res) => {
        const data = res?.data;
        if (res?.data) {
          const { priceScheduleName, prices } = res?.data;
          return request(
            `PriceSchedule`,
            "post",
            { priceScheduleName: `${priceScheduleName} (copy)`, prices },
            true
          )
            .then((res) => {
              openNotificationWithIcon(
                "success",
                "Success",
                "Pricing Schedule copied successfully"
              );
              return { status: "success", data: res?.data };
            })
            .catch((e) => {
              openNotificationWithIcon(
                "error",
                "Error!",
                e?.response?.data?.[0] ||
                  e?.response?.data?.message ||
                  e?.response?.message ||
                  e?.message ||
                  "Something went wrong"
              );
            })
            .finally(() => {
              dispatch(setLoading(false));
            });
        }
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

//PriceSchedule/Copy
