import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearAllData } from "redux/actions/locationCenter.action";
import { getFaclityInZone } from "services/facilities.services";

import {
  getLocationsByStreetAddress,
  getOperationTypesByLocationCenter,
  getProducts,
  getAddons,
} from "services/locationCenter.services";
import { GOOGLE_MAP_KEY } from "utils/constants";
import { openNotificationWithIcon } from "utils/Notification";

import { CalenderOperationType } from "utils/enums";

// TODO: At the moment street address, consult backend team for new update
const useLocationCenter = ({
  initialFacilityId,
  initialStreetAddress,
  initialOperationTypeId,
  productId,
  addonOnly = false,
} = {}) => {
  const dispatch = useDispatch();
  const abortConRef = useRef();

  const locationCenters = useSelector((state) => state.locationCenter);

  const [error, setError] = useState();
  const [outZoneError, setOutZoneError] = useState();
  const clearOutZoneError = () => setOutZoneError();
  const [isFetchingCoords, setIsFetchingCoords] = useState(false);

  const [operationTypeId, setOperationTypeId] = useState(
    initialOperationTypeId
  );

  const [facilityId, setFacilityId] = useState(initialFacilityId);
  const [lngLats, setLngLats] = useState({});
  const [addOns, setAddons] = useState([]);
  const [isFetchingAddons, setIsFetchingAddons] = useState(false);

  const setOperationType = useCallback(
    async (operationId) => {
      const { lat, lng } = lngLats ?? {};
      if (lat === undefined || lng === undefined) return;
      // bring facility
      const response = await dispatch(
        getFaclityInZone(operationId, lngLats, true)
      );
      if (response?.status === "success") {
        setFacilityId(response.data?.[0]?.facilityId);
        dispatch(
          getProducts(response?.data?.[0]?.facilityId, operationId, true)
        );
      } else {
        // Can can errors
        setFacilityId();
      }
    },

    [lngLats]
  );

  const setProduct = useCallback(
    (productId) => {
      if (addonOnly) setIsFetchingAddons(true);
      dispatch(
        getAddons(
          facilityId,
          operationTypeId,
          productId,
          addonOnly ? false : true
        )
      ).then((data) => {
        setIsFetchingAddons(false);
        setAddons(data);
      });
    },
    [facilityId, operationTypeId]
  );

  const hydradeGuestForm = ({ lat, lng }, operationTypeId, facilityId) => {
    if (operationTypeId !== undefined && facilityId !== undefined) {
      dispatch(getProducts(facilityId, operationTypeId, false));
    }
    if (operationTypeId && facilityId && productId) {
      dispatch(getAddons(facilityId, operationTypeId, productId)).then((data) =>
        setAddons(data)
      );
    }
    //   // setAddons(data)
    // }
  };

  const setStreetAddress = useCallback(
    (streetAddress, orderOperationTypeId, orderFacilityId) => {
      if (!streetAddress) return;

      if (abortConRef.current) abortConRef.current.abort();
      abortConRef.current = new AbortController();
      const urlEncodedStreetAddress = encodeURIComponent(streetAddress);
      setIsFetchingCoords(true);
      return fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_MAP_KEY}&fields=formatted_address&address=${
          operationTypeId === CalenderOperationType.Telehealth ? "USA " : ""
        }${urlEncodedStreetAddress}`,
        {
          signal: abortConRef?.current?.signal,
        }
      )
        .then((response) => response.json())
        .then(async (data) => {
          const lat = data.results[0]?.geometry?.location?.lat;
          const lng = data.results[0]?.geometry?.location?.lng;

          let state = "";
          let country = "US";
          let zipCode = "";
          for (let addressComponent of data.results[0]?.[
            "address_components"
          ]) {
            if (
              addressComponent?.["types"]?.includes(
                "administrative_area_level_1"
              )
            )
              state = addressComponent?.["short_name"];
            else if (addressComponent?.["types"]?.includes("country"))
              country = addressComponent?.["short_name"];
            else if (addressComponent?.["types"]?.includes("postal_code"))
              zipCode = addressComponent?.["short_name"];
          }

          if (
            lat !== undefined &&
            lng !== undefined &&
            !!state &&
            !!country &&
            !!zipCode
          ) {
            setError();
            setLngLats({ lat, lng });

            const response = await dispatch(
              getFaclityInZone(
                orderOperationTypeId,
                {
                  lat,
                  lng,
                },
                true
              )
            );
            if (response?.status === "success") {
              setFacilityId(response.data?.[0]?.facilityId);
              dispatch(
                getProducts(
                  response?.data?.[0]?.facilityId,
                  orderOperationTypeId,
                  true
                )
              );
              setOutZoneError();
            } else {
              setOutZoneError("out of zone");
              // Can can errors
              setFacilityId();
            }
            if (orderOperationTypeId && orderFacilityId) {
              hydradeGuestForm(
                { lat, lng },
                orderOperationTypeId,
                orderFacilityId
              );
            }
          } else {
            setError("Please provide a valid street address");
            setLngLats({ lat, lng });
          }
        })
        .catch((e) => {
          if (e.message !== "The user aborted a request.")
            openNotificationWithIcon(
              "error",
              "Error!",
              e?.response?.data?.[0] ||
                e?.response?.data?.message ||
                e?.response?.data?.Message ||
                e?.response?.message ||
                e?.message ||
                "Something went wrong"
            );
        })
        .finally(() => {
          setIsFetchingCoords(false);
        });
    },
    [operationTypeId]
  );

  const setFacility = (facilityId, orderOperationTypeId, orderProductId) => {
    setFacilityId(facilityId);
    if (orderOperationTypeId) {
      dispatch(getProducts(facilityId, operationTypeId, false));
      if (orderProductId) {
        dispatch(
          getAddons(facilityId, operationTypeId, orderProductId, false)
        ).then((data) => {
          setIsFetchingAddons(false);
          setAddons(data);
        });
      }
    } else {
      dispatch(getProducts(facilityId, operationTypeId, true));
    }
  };

  const clearLocationCenterData = () => {
    setFacilityId();
    dispatch(clearAllData());
  };

  return {
    locationCenters,
    setStreetAddress,
    setOperationType,
    setOperationTypeId,
    setProduct,
    addOns,
    isFetchingAddons,
    error,
    isFetchingCoords,
    facilityId,
    clearLocationCenterData,
    setFacility,
    outZoneError,
    clearOutZoneError,
  };
};

export default useLocationCenter;
