import React, { useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";

import FormBuilder, { Registry } from "./FormBuilder/index";
import * as variables from "./variables";
import { Route, Switch, useHistory } from "react-router";
import FormDesigner from "./components/FormDesigner";
import { MoreOutlined, UserSwitchOutlined } from "@ant-design/icons";

import {
  Button as AntdButton,
  Dropdown,
  Menu,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
// Add our stylesheets for the demo.
import "./scss/main.scss";
import { goBack, push } from "connected-react-router";
import { useDispatch } from "react-redux";
import SearchAndFilterColumns from "components/SearchAndFilterColumns/SearchAndFilterColumns";
import { Button } from "components/Buttons/Buttons";
import {
  copyForm,
  createForm,
  getAllForms,
  getFormById,
  postFormVersion,
  toggleFormActiveStatus,
} from "services/forms.service";
import moment from "moment";
import FormCreate from "./components/FormCreate";
import { getAllServices } from "services/servicesAndBoostsSettings.service";
import { FormType } from "utils/enums";
import usePortal from "hooks/usePortal";
import useSettingsHeader from "hooks/useSettingsHeader";
import { setLoading } from "redux/actions/app.actions";

const url = "/api/formdata";
const saveUrl = "/api/formdata";

const { Text, Title } = Typography;

const reducer = (state, action) => {
  switch (action.type) {
    case "SET":
      return action.data;
    case "ADD":
      return [...state, action.data];
    case "UPDATE":
      return state.map((data) => {
        if (data.categoryId === action.data.categoryId) return action.data;
        return data;
      });
    case "DELETE":
      return state.filter((data) => {
        if (data.categoryId === action.data.categoryId) return false;
        return true;
      });
    default:
      return state;
  }
};

function FormsMangement(props) {
  const dispatch = useDispatch();

  const [data, localDispatch] = useReducer(reducer, []);
  const [showAddModal, setShowAddModal] = useState(false);
  const [products, setProducts] = useState([]);
  const [isLoadingProducts, setIsloadingProducts] = useState(true);
  const [activeFilter, setActiveFilter] = useState("active"); // all, active, inactive

  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [searchText, setSearchText] = useState("");

  // use effect starts
  const loadData = async () => {
    const result = await dispatch(getAllForms());
    if (result?.status === "success") {
      localDispatch({
        type: "SET",
        data: result.data,
      });
    }
  };
  useEffect(() => {
    loadData();
    dispatch(getAllServices(false))
      .then((res) => {
        if (res?.status === "success") {
          setProducts(
            res.data
              ?.filter((it) => it?.isActive)
              ?.map((it) => {
                return {
                  value: it?.productId,
                  label: it?.productName,
                };
              })
          );
        }
      })
      .finally(() => {
        setIsloadingProducts(false);
      });
  }, []);
  // use effects end

  // handleers
  const toggleActive = (formId, status) => {
    dispatch(toggleFormActiveStatus(formId, status)).then(() => {
      loadData();
    });
  };

  const makeCopy = (record) => {
    dispatch(setLoading(true));
    const {
      createdOn,
      designJSON,
      formDescription,
      formId,
      formName,
      formType,
      isActive,
      latestVersionId,
      referenceId,
    } = record;
    const formData = {
      formName: record?.formName + " (Copy)",
      formDescription,
      referenceId,
      formType,
      isActive: false,
    };
    dispatch(copyForm(formData, formId, loadData));
  };

  const columns = [
    {
      title: "Name",
      key: "formName",
      dataIndex: "formName",
      sorter: (a, b) => {
        if (a.formName?.toLowerCase?.() < b.formName?.toLowerCase?.())
          return -1;
        if (a.formName?.toLowerCase?.() > b.formName?.toLowerCase?.()) return 1;
        return 0;
      },
      width: 280,
    },
    {
      title: "Description",
      key: "formDescription",
      width: 400,
      style: {
        background: "red",
      },
      render: (record) => {
        return (
          <p
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              maxWidth: 400,
              width: "100%",
              margin: 0,
            }}
          >
            {record.formDescription}
          </p>
        );
      },
    },
    {
      title: "Category",
      key: "category",
      render: (record) => {
        if (record.formType === FormType.chart) return "Chart";
        else return "Intake Form";
      },
      sorter: (a, b) => {
        if (a.formType < b.formType) return -1;
        if (a.formType > b.formType) return 1;
        return 0;
      },
    },

    {
      title: "Chart or Form",
      key: "referenceId",
      defaultSortOrder: "descend",
      render: (record) => {
        // return date
        if (record.formType === FormType.chart) {
          if (isLoadingProducts) return "...";
          else {
            const product = products.find(
              (p) => p.value === record.referenceId
            );
            return product?.label;
          }
        } else if (record.formType === FormType.consent) return "Consent";
        else if (record.formType === FormType.historyOrPhysical)
          return "History & Physical";
        else if (record.formType === FormType.goodFaithExam)
          return "Good Faith Exam"
        else return "";
      },
    },
    {
      title: "Active",
      key: "isActive",
      render: (record) => {
        return record.isActive ? "Active" : "Inactive";
      },
      sorter: (a, b) => {
        if (b.isActive) return -1;
        if (a.isActive) return 1;
        return 0;
      },
    },
    {
      title: "Actions",
      key: "actions",
      align: "center",

      render: (record) => {
        return (
          <Dropdown
            overlay={
              <Menu style={{ borderRadius: 6, border: "2px solid #CDD5D8 " }}>
                <Menu.Item
                  key="edit"
                  onClick={() => {
                    dispatch(
                      push(
                        `/dashboard/settings/forms-managment/edit/${record?.formId}`
                      )
                    );
                  }}
                >
                  Edit
                </Menu.Item>
                <Menu.Item
                  key="copy"
                  onClick={() => {
                    makeCopy(record);
                  }}
                >
                  Make a Copy
                </Menu.Item>
                <Menu.Item
                  key="delete"
                  onClick={() => {
                    toggleActive(record?.formId, record?.isActive);
                  }}
                >
                  {record?.isActive ? "Inactivate" : "Activate"}
                </Menu.Item>
                {/* <Menu.Item
                  onClick={() => {
                    // Modal.warning(deleteConfirmation(data.categoryId))
                  }}
                >
                  Delete
                </Menu.Item> */}
              </Menu>
            }
            trigger={["click"]}
            getPopupContainer={(triggerNode) => triggerNode?.parentNode}
          >
            <MoreOutlined style={{ fontSize: 18 }} />
          </Dropdown>
        );
      },
    },
  ];

  const settingHeaderContent = useSettingsHeader(
    <Button
      variant="filled"
      type="primary"
      style={{ height: 50, fontSize: 18 }}
      rounded
      onClick={() => setShowAddModal(true)}
      // onClick={() => dispatch(push("/dashboard/forms-managment/create"))}
    >
      Create New Form
    </Button>
  );

  const searchAndFiltersContent = usePortal(
    "#settingHeader .settingsHeaderLeftSideAddon",
    <Space>
      <SearchAndFilterColumns
        columns={columns}
        hiddenColumns={hiddenColumns}
        setHiddenColumns={(columnTitles) => {
          setHiddenColumns(columnTitles);
        }}
        searchText={searchText}
        setSearchText={(text) => {
          setSearchText(text);
        }}
      />
      <Select
        style={{ width: 120 }}
        value={activeFilter}
        onChange={(value) => {
          setActiveFilter(value);
        }}
        options={[
          {
            value: "all",
            label: "All",
          },
          {
            value: "active",
            label: "Active",
          },
          {
            value: "inactive",
            label: "Inactive",
          },
        ]}
      />
    </Space>
  );
  const checkActiveFilter = (item) => {
    if (activeFilter === "all") return true;
    else if (activeFilter === "active" && item?.isActive) return true;
    else if (activeFilter === "inactive" && !item?.isActive) return true;
    return false;
  };

  return (
    <div>
      {settingHeaderContent}
      {searchAndFiltersContent}
      <Table
        tableLayout="auto"
        locale={{ emptyText: "No Forms" }}
        rowKey={(record) => record.formId}
        dataSource={data?.filter((record) => {
          const text = searchText.toLowerCase();
          return (
            checkActiveFilter(record) &&
            (record.formName?.toLowerCase?.().includes?.(text) ||
              record.formDescription?.toLowerCase?.().includes?.(text))
          );
        })}
        columns={columns.filter((column) => {
          if (!hiddenColumns.includes(column.title)) return true;
          return false;
        })}
      />
      <FormCreate
        products={products}
        visible={showAddModal}
        onCancel={() => setShowAddModal(false)}
      />
    </div>
  );
}

FormsMangement.propTypes = {};

export default FormsMangement;
