import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Form, Row, Col, Button as AntdButton, Tabs, Typography } from "antd";
import { Button } from "components/Buttons/Buttons";
import Modal from "components/Modal/Modal";
import { useForm } from "antd/lib/form/Form";
import {
  FormHiddenSubmitButton,
  FormInput,
  FormInputArea,
  FormSelect,
} from "components/FormItems/FlatFormItems";
import TabPane from "antd/lib/tabs/TabPane";
import { useDispatch, useSelector } from "react-redux";

import { hoursList } from "utils/common";
import {
  addNewGoodFaithPolicy,
  updateGoodFaithPolicy,
} from "services/misc.service";

const { Text, Title } = Typography;

function GoodFaithPolicyModal({
  visible,
  onCancel,
  onSuccess,
  data,
  facilities,
}) {
  const dispatch = useDispatch();
  const [form] = useForm();
  const submitBtnRef = useRef();
  const [activeTabKey, setActiveTabKey] = useState("2");
  const [operationTypeIds, setOperationTypeIds] = useState([]);

  useEffect(() => {
    if (visible && data && Object.keys(data).length > 0) {
      form.setFieldsValue({
        facilityId: data?.facilityId,
        policyFee: data?.policyFee,
      });
      getOperationTypeIds(data.facilityId);
    }
  }, [data, visible]);

  const getOperationTypeIds = (facilityId) => {
    const selectedFac = facilities.find((res) => res.facilityId == facilityId);
    if (selectedFac.operationTypeIds.length > 0) {
      setActiveTabKey(selectedFac.operationTypeIds[0].toString());
    } else {
      setActiveTabKey("4");
    }
    setOperationTypeIds(selectedFac.operationTypeIds);
  };

  return (
    <Modal
      //   closable={closable}
      destroyOnClose={true}
      //   headerBorder={false}
      width="720px"
      className="cancellationPolicyModal"
      title={`${data ? "Edit" : "Add"} Good Faith Fee Policy`}
      onCancel={() => {
        onCancel();
        form.resetFields();
        setOperationTypeIds([]);
      }}
      visible={visible}
      onOk={() => console.log("ok")}
      okText="Add"
      centered={true}
      footer={
        <div className="d-flex justify-between align-center">
          <AntdButton
            type="text"
            onClick={() => {
              onCancel();
              form.resetFields();
            }}
          >
            Cancel
          </AntdButton>
          <Button
            style={{ height: 50 }}
            rounded={true}
            //   disabled={!Boolean(setupIntent)}
            onClick={() => submitBtnRef?.current?.click()}
          >
            Save
          </Button>
        </div>
      }
      //   cancelText="No"
    >
      <Form
        form={form}
        initialValues={{}}
        layout="vertical"
        requiredMark={false}
        onError={(error) => console.log(error)}
        onFinish={(values) => {
          if (data) {
            // update cancellation policy
            const updateObj = {
              facilityId: data.facilityId,
              policyFee: values.policyFee ? Number(values.policyFee) : 0,
              isActive: true,
              termsAndCondition: "",
            };

            dispatch(
              updateGoodFaithPolicy(data.goodFaithPolicyId, {
                ...updateObj,
              })
            ).then((result) => {
              if (result?.status === "success") {
                form.resetFields();
                onSuccess();
              }
            });
          } else {
            // add cancellation policy

            const addObj = {
              facilityId: values.facilityId,
              policyFee: values.policyFee,
              isActive: true,
              termsAndCondition: "",
            };

            dispatch(
              addNewGoodFaithPolicy({
                ...addObj,
              })
            ).then((result) => {
              if (result?.status === "success") {
                form.resetFields();
                onSuccess();
              }
            });
          }
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={12}>
            <FormSelect
              disabled={data?.facilityId ? true : false}
              required
              variant="underlined"
              name={"facilityId"}
              formItemStyles={{
                marginBottom: 0,
              }}
              // options={facilities ?? []}
              options={facilities}
              renderValue="facilityId"
              placeholder={"Select Facilities"}
              renderLabel="facilityName"
              emptyMessage="No Faciliteis"
              onChange={(value) => {
                getOperationTypeIds(value);
                // dispatch(setTimer(moment().add(15, "minutes")));
              }}
              rules={[
                {
                  required: true,
                  message: false,
                },
              ]}
            />
          </Col>

          <Col xs={24}>
            <Tabs
              destroyInactiveTabPane={true}
              className="cancellationTab"
              onChange={(activeKey) => {
                setActiveTabKey(activeKey);
              }}
              // defaultActiveKey={activeTabKey}
              defaultActiveKey={activeTabKey}
              activeKey={activeTabKey}
            >
              <TabPane
                tab="Clinic"
                key={2}
                disabled={!operationTypeIds.includes(2)}
                forceRender={operationTypeIds.includes(2)}
              ></TabPane>
              <TabPane
                tab="Mobile"
                key={1}
                disabled={!operationTypeIds.includes(1)}
                forceRender={operationTypeIds.includes(1)}
              ></TabPane>
              <TabPane
                tab="Telehealth"
                key={3}
                disabled={!operationTypeIds.includes(3)}
                forceRender={operationTypeIds.includes(3)}
              ></TabPane>
            </Tabs>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <FormInput
              prefix="$"
              variant="underlined"
              name="policyFee"
              label="Fee"
              placeholder="Fee"
              required={true}
              min={0}
              rules={[
                {
                  required: true,
                },
              ]}
              formItemStyles={{
                margin: 0,
              }}
              formItemClassName={"input-noborder"}
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <FormHiddenSubmitButton submitRef={submitBtnRef} />
        </Row>
      </Form>
    </Modal>
  );
}

GoodFaithPolicyModal.propTypes = {};

export default GoodFaithPolicyModal;
