import React, { useEffect, useReducer, useState } from "react";
import moment from "moment";
import { MoreOutlined } from "@ant-design/icons";
import {
  Dropdown,
  Menu,
  Space,
  Table,
  Button as AntdButton,
  Modal,
  Select,
} from "antd";
import { Button } from "components/Buttons/Buttons";
import useSettingsHeader from "hooks/useSettingsHeader";

import { useDispatch } from "react-redux";
import {
  deleteShiftSchedule,
  getAllShiftSchedules,
  restoreShiftSchedule,
} from "services/locationSettings.service";
import { push, replace } from "connected-react-router";
import SearchAndFilterColumns from "components/SearchAndFilterColumns/SearchAndFilterColumns";
import usePortal from "hooks/usePortal";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET":
      return action.data;
    case "ADD":
      return [...state, action.data];
    case "UPDATE":
      return state.map((data) => {
        if (data.categoryId === action.data.categoryId) return action.data;
        return data;
      });
    case "DELETE":
      return state.filter((data) => {
        if (data.categoryId === action.data.categoryId) return false;
        return true;
      });
    default:
      return state;
  }
};

export default function ShiftsSchedule() {
  const dispatch = useDispatch();
  const [data, localDispatch] = useReducer(reducer, []);

  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [activeFilter, setActiveFilter] = useState("active"); // all, active, inactive
  const [searchText, setSearchText] = useState("");

  const loadData = async () => {
    const result = await dispatch(
      getAllShiftSchedules((data) => {
        localDispatch({ action: "SET", data });
      })
    );
    if (result?.status === "success") {
      localDispatch({
        type: "SET",
        data: result.data,
      });
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  // functions start

  const handleDelete = (id) => {
    dispatch(deleteShiftSchedule(id)).then((result) => {
      if (result?.status === "success") {
        loadData();
      }
    });
  };

  const handleRestore = (id) => {
    dispatch(restoreShiftSchedule(id)).then((result) => {
      if (result?.status === "success") {
        loadData();
      }
    });
  };
  const deleteConfirmation = (id) => ({
    title: "Inactivate Shift Schedule",
    content: <>Are you sure that you want to inactivate the shift schedule?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      handleDelete(id);
      close();
    },
  });
  const restoreConfirmation = (id) => ({
    title: "Activate Shift Schedule",
    content: <>Are you sure that you want to activate the shift schedule?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      handleRestore(id);
      close();
    },
  });

  const checkActiveFilter = (item) => {
    if (activeFilter === "all") return true;
    else if (activeFilter === "active" && item?.isActive) return true;
    else if (activeFilter === "inactive" && !item?.isActive) return true;
    return false;
  };
  // functions end

  const columns = [
    {
      title: "Schedule Name",
      dataIndex: "scheduleName",
      key: "scheduleName",
      sorter: (a, b) => {
        if (a.scheduleName?.toLowerCase?.() < b.scheduleName?.toLowerCase?.())
          return -1;
        if (a.scheduleName?.toLowerCase?.() > b.scheduleName?.toLowerCase?.())
          return 1;
        return 0;
      },
    },
    {
      title: "Active",
      key: "active",
      width: 100,
      render: (data) => {
        if (data?.isActive) return "Active";
        return "Inactive";
      },
    },
    // {
    //   title: "Locations Assigned",
    //   key: "locationsAssigned",
    //   render: (data) => {
    //     return data?.locations?.length || 0;
    //   },
    // },
    {
      title: "",
      key: "action",
      align: "right",
      width: 80,
      render: (data) => (
        <Dropdown
          overlay={
            <Menu style={{ borderRadius: 6, border: "2px solid #CDD5D8 " }}>
              <Menu.Item
                key="edit"
                onClick={() =>
                  dispatch(
                    push(
                      `/dashboard/settings/shift-schedule/${data?.facilityScheduleId}`
                    )
                  )
                }
              >
                Edit
              </Menu.Item>
              <Menu.Item
                key="delete"
                onClick={() => {
                  if (data.isActive)
                    Modal.warning(deleteConfirmation(data.facilityScheduleId));
                  else
                    Modal.warning(restoreConfirmation(data.facilityScheduleId));
                }}
              >
                {data?.isActive ? "Inactivate" : "Activate"}
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
          getPopupContainer={(triggerNode) => triggerNode?.parentNode}
        >
          <MoreOutlined style={{ fontSize: 18 }} />
        </Dropdown>
      ),
    },
  ];

  const settingHeaderContent = useSettingsHeader(
    <Space align="center" size="large">
      <Button
        rounded={true}
        onClick={() => {
          dispatch(push("/dashboard/settings/shift-schedule"));
        }}
      >
        Create Schedule
      </Button>
    </Space>
  );

  const searchAndFiltersContent = usePortal(
    "#settingHeader .settingsHeaderLeftSideAddon",
    <Space>
      <SearchAndFilterColumns
        columns={columns}
        hiddenColumns={hiddenColumns}
        setHiddenColumns={(columnTitles) => {
          setHiddenColumns(columnTitles);
        }}
        searchText={searchText}
        setSearchText={(text) => {
          setSearchText(text);
        }}
      />
      <Select
        style={{ width: 120 }}
        value={activeFilter}
        onChange={(value) => {
          setActiveFilter(value);
        }}
        options={[
          {
            value: "all",
            label: "All",
          },
          {
            value: "active",
            label: "Active",
          },
          {
            value: "inactive",
            label: "Inactive",
          },
        ]}
      />
    </Space>
  );

  return (
    <div>
      {settingHeaderContent}
      {searchAndFiltersContent}

      <Table
        tableLayout="auto"
        locale={{ emptyText: "No Shift Schedule" }}
        rowKey={(data) => data.facilityScheduleId}
        dataSource={data?.filter(
          (it) =>
            checkActiveFilter(it) &&
            it?.scheduleName
              ?.toLowerCase?.()
              .includes?.(searchText.toLowerCase())
        )}
        columns={columns.filter((column) => {
          if (!hiddenColumns.includes(column.title)) return true;
          return false;
        })}
        // dataSource={allGuests.filter((data) => {
        //   return data.name.toLowerCase().includes(searchText.toLowerCase());
        // })}
        // columns={columns.filter((column) => {
        //   if (!hiddenColumns.includes(column.title)) return true;
        //   return false;
        // })}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: [15, 25, 50, 100, 250],
        }}
        // scroll={{ y: "calc(100vh - 80px - 96px - 60px - 100px)", x: true }}
      />
    </div>
  );
}
