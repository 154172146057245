import { Alert, Input, Modal, Button } from "antd";
import React from "react";
import { openNotificationWithIcon } from "utils/Notification";

export default function PinModal({
  open,
  onCancel,
  onDelete,
  input: inputProp,
  onOk,
}) {
  const [input, setInput] = React.useState(inputProp || "");
  const inputRef = React.useRef(null);
  React.useEffect(() => {
    if (!open) {
      setInput("");
    } else {
      setInput(inputProp || "");
      inputRef.current.focus({
        cursor: "start",
      });
    }
  }, [open]);

  return (
    <Modal
      title={inputProp ? "Edit Pin" : "Add Pin"}
      open={open}
      onCancel={onCancel}
      maskClosable={true}
      closable={true}
      footer={
        <div>
          <Button onClick={onCancel}>Cancel</Button>
          {inputProp && (
            <Button
              onClick={() => {
                onDelete();
                onCancel();
              }}
              type="danger"
            >
              Delete
            </Button>
          )}
          <Button
            onClick={() => {
              if (input.length <= 0) {
                openNotificationWithIcon(
                  "error",
                  "Input Empty",
                  "Please enter a valid input"
                );
              } else onOk(input);
            }}
            color="primary"
            style={{
              backgroundColor: "#486baf",
              color: "#fff",
              border: "none",
            }}
          >
            Save
          </Button>
        </div>
      }
    >
      <Input
        ref={inputRef}
        value={input}
        onChange={(e) => {
          setInput(e.target.value);
        }}
      />
    </Modal>
  );
}
