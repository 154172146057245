import { cloneDeep } from "lodash";
import moment from "moment";
import { setLoading } from "redux/actions/app.actions";
import { openNotificationWithIcon } from "utils/Notification";
import { request } from "./verb.services";

export const getAllRestrictions = (callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request("ProductRestrictions", "get", null, true, null)
      .then((res) => {
        return {
          status: "success",
          data: res.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getRestrictionsById = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`ProductRestrictions/${id}`, "get", null, true, null)
      .then((res) => {
        return {
          status: "success",
          data: res.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const createRestriction = (values, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`ProductRestrictions`, "post", values, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Restriction created successfully"
        );
        return { status: "success", data: res?.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const deleteRestriction = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `ProductRestrictions/Status?productRestrictionId=${id}`,
      "PATCH",
      null,
      true
    )
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Restriction changed successfully"
        );
        callback?.();
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const updateRestrictions = (id, data, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`ProductRestrictions/${id}`, "put", data, true)
      .then(() => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Restriction updated successfully"
        );
        callback?.();
        return {
          status: "success",
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
export const validateRestriction = (values, facilityId, customerID) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Orders/Validate?facilityId=${facilityId}`,
      "post",
      values,
      true
    )
      .then((res) => {
        return { mstatus: true };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
        return { mstatus: false };
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
var DATA = [];
