import React, { useEffect, useReducer, useState } from "react";

import { Button } from "components/Buttons/Buttons";
import {
  Dropdown,
  Menu,
  Modal,
  Space,
  Table,
  Button as AntdButton,
  Select,
} from "antd";
import { MoreOutlined } from "@ant-design/icons";
import "./Locations.style.scss";
import useSettingsHeader from "hooks/useSettingsHeader";
import LocationDrawer from "./components/LocationDrawer.index";
import { useDispatch } from "react-redux";
import {
  createLocation,
  deleteLocation,
  getAllLocations,
  getAllShiftSchedules,
  restoreLocation,
  updateLocation,
} from "services/locationSettings.service";
import { push } from "connected-react-router";
import { getAllPricingSchedules } from "services/pricingSchedule.service";
import usePortal from "hooks/usePortal";
import SearchAndFilterColumns from "components/SearchAndFilterColumns/SearchAndFilterColumns";
import { checkIsMobileBooking } from "utils/common";
import { CalenderOperationType } from "utils/enums";
import { GOOGLE_MAP_KEY } from "utils/constants";
import { getStateAndCountryFromZip } from "services/locationCenter.services";
import { setLoading } from "redux/actions/app.actions";
import { openNotificationWithIcon } from "utils/Notification";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET":
      return action.data;
    case "ADD":
      return [...state, action.data];
    case "UPDATE":
      return state.map((data) => {
        if (data.categoryId === action.data.categoryId) return action.data;
        return data;
      });
    case "DELETE":
      return state.filter((data) => {
        if (data.categoryId === action.data.categoryId) return false;
        return true;
      });
    default:
      return state;
  }
};

export default function Locations() {
  const [showAddEditModal, setShowAddEditModal] = useState();

  const [priceSchedules, setPriceSchedules] = useState({
    data: [],
    isLoading: true,
  });
  const [shiftSchedules, setShiftSchedules] = useState({
    data: [],
    isLoading: true,
  });

  const dispatch = useDispatch();
  const [data, localDispatch] = useReducer(reducer, []);

  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [activeFilter, setActiveFilter] = useState("active"); // all, active, inactive
  const [searchText, setSearchText] = useState("");

  const loadData = async () => {
    const result = await dispatch(
      getAllLocations((data) => {
        localDispatch({ action: "SET", data });
      })
    );
    if (result?.status === "success") {
      localDispatch({
        type: "SET",
        data: result.data,
      });
    }
  };

  useEffect(() => {
    dispatch(getAllPricingSchedules(null, false))
      .then((result) => {
        if (result?.status === "success") {
          const sortedData = result?.data?.sort?.((a, b) => {
            if (
              a.priceScheduleName?.toLowerCase?.() <
              b.priceScheduleName?.toLowerCase?.()
            )
              return -1;
            if (
              a.priceScheduleName?.toLowerCase?.() >
              b.priceScheduleName?.toLowerCase?.()
            )
              return 1;
            return 0;
          });
          setPriceSchedules({
            data: sortedData,
            isLoading: false,
          });
        }
      })
      .catch(() => {
        setPriceSchedules({
          data: [],
          isLoading: false,
        });
      });
    dispatch(getAllShiftSchedules(false))
      .then((result) => {
        if (result?.status === "success") {
          const sortedData = result?.data?.sort?.((a, b) => {
            if (
              a.scheduleName?.toLowerCase?.() < b.scheduleName?.toLowerCase?.()
            )
              return -1;
            if (
              a.scheduleName?.toLowerCase?.() > b.scheduleName?.toLowerCase?.()
            )
              return 1;
            return 0;
          });
          setShiftSchedules({
            data: sortedData,
            isLoading: false,
          });
        }
      })
      .catch(() => {
        setShiftSchedules({
          data: [],
          isLoading: false,
        });
      });
    loadData();
  }, []);

  // functions start
  const handleAdd = async (values) => {
    dispatch(setLoading(true));
    const { state, country, zipCode } =
      (await getStateAndCountryFromZip(`USA ${values.zipCode}`)) ?? {};
    if (!!state && !!country && !!zipCode) {
      // values.state = values.state ?? state;
      values.country = country;
      values.zipCode = zipCode;
      dispatch(createLocation(values, false))
        .then((result) => {
          if (result?.status === "success") {
            setShowAddEditModal();
            loadData();
            // loadData();
          }
        })
        .finally(() => dispatch(setLoading(false)));
    } else {
      openNotificationWithIcon(
        "error",
        "ZIP Code Error!",
        "Invalid ZIP code provided. No address found against it."
      );
      dispatch(setLoading(false));
    }
  };

  const handleUpdate = async (id, values) => {
    dispatch(setLoading(true));
    const { state, country, zipCode } =
      (await getStateAndCountryFromZip(`USA ${values.zipCode}`)) ?? {};
    if (!!state && !!country && !!zipCode) {
      // values.state = values.state ?? state;
      values.country = country;
      values.zipCode = zipCode;
      dispatch(updateLocation(id, values, false))
        .then((result) => {
          if (result?.status === "success") {
            setShowAddEditModal();
            loadData();
          }
        })
        .finally(() => dispatch(setLoading(false)));
    } else {
      openNotificationWithIcon(
        "error",
        "ZIP Code Error!",
        "Invalid ZIP code provided. No address found against it."
      );
      dispatch(setLoading(false));
    }
  };
  const handleDelete = (id) => {
    dispatch(deleteLocation(id)).then((result) => {
      if (result?.status === "success") {
        loadData();
      }
    });
  };
  const handleRestore = (id) => {
    dispatch(restoreLocation(id)).then((result) => {
      if (result?.status === "success") {
        loadData();
      }
    });
  };
  const deleteConfirmation = (id) => ({
    title: "Inactivate Location",
    content: <>Are you sure that you want to inactivate the location?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      handleDelete(id);
      close();
    },
  });
  const restoreConfirmation = (id) => ({
    title: "Activate Location",
    content: <>Are you sure that you want to activate the location?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      handleRestore(id);
      close();
    },
  });

  const checkActiveFilter = (item) => {
    if (activeFilter === "all") return true;
    else if (activeFilter === "active" && item?.isActive) return true;
    else if (activeFilter === "inactive" && !item?.isActive) return true;
    return false;
  };
  // functions end

  const columns = [
    {
      title: "Location Name",
      dataIndex: "facilityName",
      key: "facilityName",
      sorter: (a, b) => {
        if (a.facilityName?.toLowerCase?.() < b.facilityName?.toLowerCase?.())
          return -1;
        if (a.facilityName?.toLowerCase?.() > b.facilityName?.toLowerCase?.())
          return 1;
        return 0;
      },
    },
    {
      title: "Staff",
      dataIndex: "staffCount",
      key: "staff",
      width: 150,
      sorter: (a, b) => {
        if (a.staffCount < b.staffCount) return -1;
        if (a.staffCount > b.staffCount) return 1;
        return 0;
      },
    },
    {
      title: "Service Type",
      width: 140,
      key: "serviceTypeName",
      render: (record) => {
        return record?.operationTypeIds
          ?.map?.((it) => {
            if (it === CalenderOperationType.Mobile) return "Mobile";
            else if (it === CalenderOperationType.Clinic) return "Clinic";
            else if (it === CalenderOperationType.Telehealth)
              return "Telehealth";
            else return "";
          })
          ?.join(", ");
      },
    },
    {
      title: "Active",
      key: "active",
      width: 100,
      render: (data) => {
        if (data?.isActive) return "Active";
        return "Inactive";
      },
    },
    {
      title: "",
      key: "action",
      align: "right",
      width: 80,
      render: (record) => (
        <Dropdown
          overlay={
            <Menu
              style={{ borderRadius: 6, border: "2px solid #CDD5D8 " }}
              key="edit"
            >
              <Menu.Item
                onClick={() => {
                  setShowAddEditModal(record);
                }}
              >
                Edit
              </Menu.Item>
              <Menu.Item
                key="delete"
                onClick={() => {
                  if (record?.isActive)
                    Modal.warning(deleteConfirmation(record.facilityId));
                  else Modal.warning(restoreConfirmation(record.facilityId));
                }}
              >
                {record?.isActive ? "Inactivate" : "Activate"}
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
          getPopupContainer={(triggerNode) => triggerNode?.parentNode}
        >
          <MoreOutlined style={{ fontSize: 18 }} />
        </Dropdown>
      ),
    },
  ];

  const settingHeaderContent = useSettingsHeader(
    <Space align="center" size="large">
      <AntdButton
        type="text"
        style={{ padding: 0 }}
        onClick={() => {
          dispatch(push("/dashboard/settings/shift-schedules"));
        }}
      >
        Manage Business Hours
      </AntdButton>
      <Button
        rounded={true}
        onClick={() => {
          setShowAddEditModal(true);
        }}
      >
        Add New Location
      </Button>
    </Space>
  );

  const searchAndFiltersContent = usePortal(
    "#settingHeader .settingsHeaderLeftSideAddon",
    <Space>
      <SearchAndFilterColumns
        columns={columns}
        hiddenColumns={hiddenColumns}
        setHiddenColumns={(columnTitles) => {
          setHiddenColumns(columnTitles);
        }}
        searchText={searchText}
        setSearchText={(text) => {
          setSearchText(text);
        }}
      />
      <Select
        style={{ width: 120 }}
        value={activeFilter}
        onChange={(value) => {
          setActiveFilter(value);
        }}
        options={[
          {
            value: "all",
            label: "All",
          },
          {
            value: "active",
            label: "Active",
          },
          {
            value: "inactive",
            label: "Inactive",
          },
        ]}
      />
    </Space>
  );

  return (
    <div>
      {settingHeaderContent}
      {searchAndFiltersContent}
      <LocationDrawer
        shiftSchedules={shiftSchedules.data}
        isShiftSchedulesLoading={shiftSchedules.isLoading}
        priceSchedules={priceSchedules.data}
        isPriceSchedulesLoading={priceSchedules.isLoading}
        visible={showAddEditModal}
        onClose={() => {
          setShowAddEditModal();
          //  setModalVisible(false);
          //  dispatch(setCurrentRole(null));
        }}
        data={showAddEditModal}
        onSuccess={(values) => {
          const isEdit = showAddEditModal?.facilityId !== undefined;
          if (isEdit) handleUpdate(showAddEditModal?.facilityId, values);
          else handleAdd(values);
        }}
        //  onAddHandler={onAddHandler}
        //  onUpdateHandler={onUpdateHandler}
      />

      <Table
        tableLayout="auto"
        locale={{ emptyText: "No Locations" }}
        rowKey={(data) => data.facilityId}
        dataSource={data?.filter(
          (it) =>
            checkActiveFilter(it) &&
            it?.facilityName
              ?.toLowerCase?.()
              .includes?.(searchText.toLowerCase())
        )}
        columns={columns.filter((column) => {
          if (!hiddenColumns.includes(column.title)) return true;
          return false;
        })}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: [15, 25, 50, 100, 250],
        }}
        // scroll={{ y: "calc(100vh - 80px - 96px - 60px - 100px)", x: true }}
      />
    </div>
  );
}
