import React from "react";

export default function PaymentInfoRow({ title, cost }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <p
        style={{
          margin: 0,
          fontSize: 18,
          fontFamily: "tradeGothic",
          fontWeight: "bold",
        }}
      >
        {title}
      </p>
      <p
        style={{
          margin: 0,
          fontSize: 18,
          fontFamily: "tradeGothic",
          fontWeight: "bold",
        }}
      >
        {cost}
      </p>
    </div>
  );
}
