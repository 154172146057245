import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import Calendar from "components/Calendar/Calendar";
import "./ResourceScheduler.scss";

import AppointmentDrawer from "./components/AppointmentDrawer";
import GuestDrawer from "./components/GuestDrawer";

import { getFacilities } from "services/facilities.services";
import {
  getAllStaff,
  getAllTelehealthStaff,
  getCalendarStaff,
} from "services/staffs.services";
import { getBookings } from "services/booking.service";
import EventDrawer from "components/EventDrawer/EventDrawer";
import {
  setCurrentFacilityIds,
  setCurrentOperationType,
  setCurrentStaffIds,
  setCurrentTelehalthFacility,
} from "redux/actions/calendar.actions";
import {
  clearAllStaff,
  setAllStaff,
  setCurrentStaff,
} from "redux/actions/user.actions";
import { useLocation } from "react-router";
import queryString from "query-string";
import { replace } from "connected-react-router";

import { findWindows } from "windows-iana";
import { CalenderOperationType } from "utils/enums";
import Modal from "components/Modal/Modal";
import NurseSelector from "components/NurseSelector/NurseSelector";
import { Button } from "components/Buttons/Buttons";
import { setAppointmentDrawer } from "redux/actions/drawer.actions";

function ResourceScheduler(props) {
  const abortRef = useRef();
  const intervalRef = useRef();

  // const [showAppointmentDrawer, setAppointmentDrawer] = useState(false);
  const [showGuestDrawer, setshowGuestDrawer] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  // TODO: set showEventDrawer to false
  const [showEventDrawer, setShowEventDrawer] = useState(false);
  const dispatch = useDispatch();

  // const [facility, setFacility] = useState();
  // TODO: set today as date value
  const [date, setDate] = useState(moment(moment().format("L")));
  const location = useLocation();
  const qs = queryString.parse(location.search, { arrayFormat: "bracket" });

  const {
    currentFacilityIds,
    currentTeleFacility,
    currentOperationType,
    facilities,
    isFetchingFacilities,
    allStaff,
    currentStaff,
  } = useSelector((state) => {
    return {
      currentFacilityIds: state.calendar.currentFacilityIds,
      currentTeleFacility: state.calendar.currentTeleFacility,
      currentOperationType: state.calendar.currentOperationType,
      currentStaff: state.calendar.currentStaff,
      facilities: state.facilities.allFacilities,
      isFetchingFacilities: state.facilities.isFetchingFacilities,
      allStaff: state.user.allStaff,
    };
  });

  const [view, setView] = useState(
    currentOperationType === CalenderOperationType.All ? "day" : "agenda"
  );
  useEffect(() => {
    dispatch(clearAllStaff());
    dispatch(getFacilities());
    dispatch(getAllStaff());
    if (qs.facilityId) {
      setView("day");
      dispatch(setCurrentOperationType(CalenderOperationType.All));
      dispatch(setCurrentFacilityIds(qs.facilityId.map((i) => Number(i))));
    } else if (qs.teleFacilityId) {
      setView("agenda");
      dispatch(setCurrentOperationType(CalenderOperationType.Telehealth));
      dispatch(
        setCurrentTelehalthFacility(qs.teleFacilityId.map((i) => Number(i)))
      );
    }
  }, []);

  useEffect(() => {
    const windowsTimezone = findWindows(moment.tz.guess());
    if (
      (currentFacilityIds.length > 0 ||
        currentOperationType === CalenderOperationType.Telehealth) &&
      date
    ) {
      if (abortRef.current) abortRef.current.abort();
      abortRef.current = new AbortController();
      dispatch(
        getBookings(
          {
            facilityIds:
              currentOperationType === CalenderOperationType.Telehealth
                ? []
                : currentFacilityIds,
            operationTypeId:
              currentOperationType === CalenderOperationType.Telehealth
                ? CalenderOperationType.Telehealth
                : undefined,
            requestedDate: moment.utc(date).local().format("YYYY-MM-DD"),
            filterType: 1,
            staffAssigned: true,
            timezone: windowsTimezone?.[0],
          },
          true,
          abortRef?.current?.signal
        )
      );
      intervalRef.current = setInterval(() => {
        dispatch(
          getBookings(
            {
              facilityIds:
                currentOperationType === CalenderOperationType.Telehealth
                  ? []
                  : currentFacilityIds,
              operationType:
                currentOperationType === CalenderOperationType.Telehealth
                  ? CalenderOperationType.Telehealth
                  : undefined,
              requestedDate: moment.utc(date).local().format("YYYY-MM-DD"),
              filterType: 1,
              staffAssigned: true,
              timezone: windowsTimezone?.[0],
            },
            false,
            abortRef?.current?.signal
          )
        );
      }, 20000); // 10 seconds
    }
    return () => {
      if (intervalRef?.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [date, currentFacilityIds, currentOperationType]);

  useEffect(() => {
    if (
      currentFacilityIds &&
      currentOperationType === CalenderOperationType.All &&
      date
    ) {
      dispatch(
        getCalendarStaff(
          currentFacilityIds,
          ["Admin", "Nurse", "Nurse Lead", "Nurse Practitioner"],
          date?.format?.("YYYY-MM-DD")
        )
      );
    } else if (currentOperationType === CalenderOperationType.Telehealth) {
      dispatch(
        getAllTelehealthStaff(["Nurse", "Nurse Lead", "Nurse Practitioner"])
      );
    } else {
      dispatch(setAllStaff([]));
    }
  }, [currentFacilityIds, currentOperationType, date]);

  useEffect(() => {
    const qsObj = {};
    if (
      currentTeleFacility?.length > 0 &&
      currentOperationType === CalenderOperationType.Telehealth
    )
      qsObj.teleFacilityId = currentTeleFacility;
    if (
      currentFacilityIds.length > 0 &&
      currentOperationType === CalenderOperationType.All
    )
      qsObj.facilityId = currentFacilityIds;
    const searchQuery = queryString.stringify(qsObj, {
      arrayFormat: "bracket",
      // arrayFormatSeparator: "|",
    });
    dispatch(replace({ search: searchQuery }));
  }, [currentFacilityIds, currentTeleFacility, currentOperationType]);

  return (
    <>
      <Calendar
        view={view}
        setView={setView}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        currentFacilityIds={currentFacilityIds}
        operationType={currentOperationType}
        setOperationType={(operationType) => {
          dispatch(setCurrentOperationType(operationType));
        }}
        setFacility={(facilityIds) => {
          dispatch(setCurrentFacilityIds(facilityIds));
          // dispatch(setCurrentFacility(facility));
          const searchQuery = queryString.stringify({
            facilityId: facilityIds,
          });
          dispatch(replace({ search: searchQuery }));
          // history.replace({
          //   search: new URLSearchParams({ activetab: 1 }).toString(),
          // });
        }}
        currentStaff={currentStaff}
        staff={
          currentOperationType === CalenderOperationType.All &&
          currentStaff?.length > 0
            ? allStaff?.filter((staff) => {
                return currentStaff?.includes?.(staff.staffId);
              })
            : allStaff
        }
        date={date}
        setDate={setDate}
        facilities={facilities}
        isFetchingFacilities={isFetchingFacilities}
        bookAnAppointmentClick={(value, data) => {
          // setAppointmentDrawer(data ?? true)
          dispatch(
            setAppointmentDrawer(
              data
                ? {
                    show: true,
                    data: data,
                  }
                : {
                    show: true,
                    data: null,
                  }
            )
          );
        }}
        appointmentDetailClick={() => setShowEventDrawer(true)}
      />
      {/* <AppointmentDrawer
        key={showAppointmentDrawer}
        addGuestClick={() => {
          setAppointmentDrawer(false);
          setshowGuestDrawer(true);
        }}
        data={showAppointmentDrawer}
        visible={showAppointmentDrawer}
        onClose={() => setAppointmentDrawer(false)}
      /> */}
      {/* <GuestDrawer
        showAppointmentDrawer={() => {
          setAppointmentDrawer(true);
        }}
        visible={showGuestDrawer}
        onClose={() => setshowGuestDrawer(false)}
      /> */}
      <EventDrawer
        visible={showEventDrawer}
        setShowEventDrawer={setShowEventDrawer}
        onClose={() => setShowEventDrawer(false)}
      />
      <Modal
        title="Filters"
        open={showFilter}
        // onOk={() => {}}
        onCancel={() => {
          setShowFilter(false);
        }}
        footer={[]}
      >
        <NurseSelector />
        <Button
          rounded={true}
          onClick={() => {
            dispatch(setCurrentStaffIds([]));
          }}
        >
          Clear
        </Button>
      </Modal>
    </>
  );
}

ResourceScheduler.propTypes = {};

export default ResourceScheduler;
