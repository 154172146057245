import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Avatar,
  Row,
  Col,
  Space,
  Typography,
  Button,
  Form,
  Modal as AntdModal,
} from "antd";

import moment from "moment";
import Modal from "components/Modal/Modal";
import {
  FormHiddenSubmitButton,
  FormSelect,
} from "components/FormItems/FlatFormItems";
import { useDispatch } from "react-redux";
import {
  getAddons,
  getOperationTypesByFacility,
  getProducts,
} from "services/locationCenter.services";
import {
  addLineItem,
  addLineItems,
  deleteLineItem,
  updateAppointmentDateTime,
  updateLineItem,
} from "services/booking.service";
import { setLoading } from "redux/actions/app.actions";
import { addonsTextReducer, openChartURL } from "utils/common";
import { push } from "connected-react-router";
import ChangeAppointmentModal from "components/ChangeAppointmentModal/ChangeAppointmentModal";

import { convertMinsToHrsMins } from "utils/common";
import BookingDetailRow from "./BookingDetailRow";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

const { confirm, warning, success, error } = AntdModal;

const { Title, Text } = Typography;

const useService = (facilityId, operationId) => {
  const [products, setProducts] = useState([]);
  const [isFetchingProducts, setIsFetchingProducts] = useState(true);

  const [addOns, setAddons] = useState([]);
  const [isFetchingAddons, setIsFetchingAddons] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProducts(facilityId, operationId)).then((data) => {
      setProducts(data);
      setIsFetchingProducts(false);
    });
  }, []);

  const setProduct = useCallback(
    (productId) => {
      setIsFetchingAddons(true);
      dispatch(getAddons(facilityId, operationId, productId)).then((data) => {
        setAddons(data);
        setIsFetchingAddons(false);
      });
    },
    [facilityId, operationId]
  );

  return {
    products,
    isFetchingProducts,
    setProduct,
    addOns,
    isFetchingAddons,
  };
};

export default function BookingDetails({
  booking,
  customer,
  disabled,
  isOrderCancelled,
  style,
  setShowEventDrawer,
  refreshCustomerRewards,
  rewards,
  isRewardsLoading,
  onClose,
}) {
  const {
    serviceDate,
    lineItems,
    facilityId,
    operationTypeId,
    orderId,
    orderDuration,
    isInventorySubmitted,
    staffId,
  } = booking;

  const instructions = booking?.notes?.[0];
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const submitBtnRef = useRef();
  const [showAddUpdateService, setShowAddUpdateService] = useState(false);
  const [showUpdateAppointmentDate, setShowUpdateAppointmentDate] =
    useState(false);

  const { products, isFetchingProducts, isFetchingAddons, setProduct, addOns } =
    useService(facilityId, operationTypeId);
  const facilityServiceDate = moment(serviceDate);

  const isEditModal = typeof showAddUpdateService === "object";
  const selectedAddOns = Form.useWatch("addOns", form);

  useEffect(() => {
    if (showAddUpdateService === false) {
      form.resetFields();
    } else if (typeof showAddUpdateService === "object") {
      if (showAddUpdateService.productId) {
        setProduct(showAddUpdateService.productId);
      }
      const productId = showAddUpdateService.productId;
      const addOns = showAddUpdateService?.addOns?.map?.(
        (addOn) => addOn.productId
      );
      form.setFieldsValue({
        productId,
        addOns,
      });
    }
  }, [showAddUpdateService]);

  const handleAddService = (values) => {
    // add new line item
    dispatch(addLineItems(orderId, [values])).then((res) => {
      if (res?.status === "success") {
        setShowAddUpdateService(false);
        setShowEventDrawer(false);
        refreshCustomerRewards();
      }
    });
    // update booking
  };

  const handleUpdateService = (lineItemId, values) => {
    // delete existing line item
    // add new line item
    // update booking
    if (!values.addOns) {
      values.addOns = [];
    }
    dispatch(updateLineItem(orderId, lineItemId, [values])).then((res) => {
      if (res?.status === "success") {
        setShowAddUpdateService(false);
        setShowEventDrawer(false);
        refreshCustomerRewards();
      }
    });
  };

  const handleUpdateAppointmentDateTime = (orderId, values) => {
    const serviceDate = moment(
      values.date.format("L") + " " + values.time
    ).format("YYYY-MM-DDTHH:mm:ss[Z]");
    dispatch(updateAppointmentDateTime(orderId, serviceDate)).then((res) => {
      if (res?.status === "success") {
        setShowUpdateAppointmentDate(false);
        // setShowEventDrawer(false);
      }
    });
  };

  return (
    <Row span={[16, 16]} style={style}>
      <Col span={24}>
        <Title
          variant={5}
          style={{ margin: 0, fontSize: 20, color: "#161F3A" }}
        >
          Booking Information
        </Title>
      </Col>
      <Col span={24}>
        <Row>
          <Col flex="auto" style={{ marginTop: 8, marginBottom: 8 }}>
            <Text
              style={{
                fontWeight: 700,
              }}
            >
              {facilityServiceDate.format("h:mm A")} -{" "}
              {moment(facilityServiceDate)
                .add(orderDuration ?? 1, "hour")
                .format("h:mm A")}{" "}
              {facilityServiceDate.format("[(]DD MMM YYYY[)]")}
            </Text>
          </Col>
          <Col>
            <Button
              type="text"
              // TODO: isOrderCancelled chaipi remove
              disabled={disabled || isOrderCancelled}
              onClick={() => {
                setShowUpdateAppointmentDate(true);
              }}
              style={{ padding: 0 }}
            >
              Change
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row
          span={[16, 16]}
          style={{
            paddingBottom: 12,
          }}
        >
          {lineItems?.map((lineItem, index) => {
            return (
              <BookingDetailRow
                rewards={rewards}
                key={lineItem?.lineItemId}
                orderId={orderId}
                lineItem={lineItem}
                lineItems={lineItems}
                index={index}
                setShowAddUpdateService={setShowAddUpdateService}
                deleteLineItem={deleteLineItem}
                refreshCustomerRewards={refreshCustomerRewards}
                isRewardsLoading={isRewardsLoading}
                disabled={disabled}
                customer={customer}
                facilityId={facilityId}
              />
            );
          })}
          <div className="d-flex align-center gap-8">
            <Button
              disabled={!staffId}
              // disabled={booking?.orderStatusString!=='Arrived'}
              type="text"
              style={{ padding: 0 }}
              onClick={() => {
                onClose?.();
                dispatch(push(`/dashboard/inventory/${booking?.orderId}`));
              }}
            >
              Inventory
            </Button>
            {isInventorySubmitted ? (
              <CheckCircleOutlined
                style={{
                  color: "#52c41a",
                }}
              />
            ) : (
              <CloseCircleOutlined
                style={{
                  color: "#f5222d",
                }}
              />
            )}
          </div>

          {instructions && (
            <Col span={24} style={{ marginTop: 10 }}>
              <Title
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  color: "#486BAF",
                  marginBottom: 4,
                }}
              >
                Address Instructions
              </Title>
              {instructions && <Text>{instructions}</Text>}
            </Col>
          )}

          {/* <Col span={24} style={{ marginTop: 6, marginBottom: 12 }}>
            <Button
              type="text"
              style={{ padding: 0 }}
              onClick={() => {
                setShowAddUpdateService(true);
              }}
            >
              Add Service
            </Button>
          </Col> */}
        </Row>
      </Col>
      <ChangeAppointmentModal
        // order={}
        booking={booking}
        visible={showUpdateAppointmentDate}
        onSave={handleUpdateAppointmentDateTime}
        onClose={() => {
          setShowUpdateAppointmentDate(false);
        }}
      />
      <Modal
        headerBorder={false}
        width="460px"
        title={`${isEditModal ? "Edit Service" : "Add Service"}`}
        onCancel={() => {
          setShowAddUpdateService(false);
        }}
        visible={showAddUpdateService}
        onOk={() => submitBtnRef?.current?.click()}
        okText="Save"
        destroyOnClose
      >
        <Form
          requiredMark={false}
          form={form}
          layout="vertical"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            borderBottom: "2px solid #EFF1F4",
            paddingBottom: 12,
          }}
          preserve={false}
          onFinish={async (values) => {
            values.firstName = customer?.firstName;
            values.lastName = customer?.lastName;
            if (isEditModal) {
              handleUpdateService(showAddUpdateService?.lineItemId, values);
            } else {
              handleAddService(values);
            }
          }}
          onFinishFailed={({ values, errorFields, outOfDate }) => {
            form.scrollToField(errorFields[0].name, {
              scrollMode: "if-needed",
              block: "center",
              behavior: "smooth",
            });
          }}
        >
          <Row style={{ width: "100%" }}>
            <Col span={24}>
              <FormSelect
                // disabled={isAppointmentFormDisabled}
                variant="underlined"
                name="productId"
                label="Services"
                placeholder="Services"
                required={true}
                formItemStyles={{ marginBottom: 12 }}
                options={products}
                loading={isFetchingProducts}
                renderLabel="productName"
                renderValue="productId"
                onChange={(value) => {
                  form.setFieldsValue({
                    addOns: undefined,
                  });
                  setProduct(value);
                }}
                showSearch={true}
                optionLabelProp="label"
                filterOption={(input, option) => {
                  return option?.label
                    ?.toLowerCase?.()
                    .includes?.(input.toLowerCase());
                }}
              />
            </Col>
            {/* TODO: Add rules here as well */}
            <Col span={24}>
              <FormSelect
                // disabled={isAppointmentFormDisabled}
                variant="underlined"
                name="addOns"
                label="Add-ons"
                placeholder="Add-ons"
                // required={true}
                formItemStyles={{ marginBottom: 0 }}
                loading={isFetchingAddons}
                options={addOns}
                renderLabel="productName"
                renderValue="productId"
                mode="multiple"
                optionLabelProp="label"
                filterOption={(input, option) => {
                  return option?.label
                    ?.toLowerCase?.()
                    .includes?.(input.toLowerCase());
                }}
              />
            </Col>
          </Row>
          <FormHiddenSubmitButton submitRef={submitBtnRef} />
        </Form>
      </Modal>
    </Row>
  );
}
