import React from "react";
import PropTypes from "prop-types";

import "./Buttons.scss";

import { Button as AntdButton } from "antd";

export const Button = ({
  htmlType,
  children,
  size,
  style,
  onClick,
  loading,
  disabled,
  variant = "filled",
  className,
  rounded = false,
  icon = null,
  type,
}) => {
  return (
    <AntdButton
      type={type}
      icon={icon}
      size={size}
      onClick={onClick ? onClick : () => {}}
      style={style}
      loading={loading}
      disabled={disabled}
      htmlType={htmlType ? htmlType : "button"}
      className={`
      button
      ${variant === "filled" ? "flatButtonFilled" : "flatButtonOutlined"} 
      ${rounded ? "rounded" : ""}
      ${className}`}
    >
      {children}
    </AntdButton>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  style: PropTypes.object,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(["filled", "outlined"]),
  className: PropTypes.string,
  rounded: PropTypes.bool,
};
