import React, { useEffect, useMemo, useState } from "react";
import {
  Typography,
  Col,
  Modal as AntdModal,
  Button,
  Space,
  Modal,
} from "antd";
import {
  addonsTextReducer,
  convertMinsToHrsMins,
  getRewardsText,
} from "utils/common";
import { useDispatch } from "react-redux";
import { getChart } from "services/forms.service";
import { removeLineitemReward } from "services/booking.service";
import { openNotificationWithIcon } from "utils/Notification";
import ManageRewardsPopup from "components/ManageRewardsPopup/ManageRewardsPopup";
import { GreenText, RowStyle, TitleStyle } from "./styles";
import FillChartBtn from "./FillChartBtn";

const { Title, Text, Link } = Typography;
const { confirm } = AntdModal;

function BookingDetailRow({
  orderId,
  lineItem,
  lineItems,
  index,
  disabled,
  setShowAddUpdateService,
  deleteLineItem,
  rewards,
  refreshCustomerRewards,
  isRewardsLoading,
  customer,
  facilityId
}) {
  const dispatch = useDispatch();
  const { lineItemId, productName, durationHours, addOns, rewardId } = lineItem;

  const [chart, setChart] = useState({
    isLoading: true,
    data: {},
  });

  const [showManageRewards, setShowManageRewards] = useState(false);

  useEffect(() => {
    if (orderId && lineItemId)
      dispatch(getChart(orderId, lineItemId, false))
        .then((res) => {
          if (res?.status === "success") {
            setChart({
              isLoading: false,
              data: res.data,
            });
          } else {
            setChart({
              isLoading: false,
              data: {},
            });
          }
        })
        .catch(() => {
          setChart({
            isLoading: false,
            data: {},
          });
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const availedRewards = useMemo(() => {
    let orderRewards = [];
    for (let i = 0; i < lineItems?.length; i++) {
      const lineItem = lineItems[i];
      if (lineItem.rewardId) orderRewards.push(lineItem.rewardId);
    }
    return orderRewards;
  }, [lineItems]);

  const deleteConfirmation = () => ({
    title: "Remove Reward",
    content: (
      <>Are you sure that you want to remove the reward from the service?</>
    ),
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      dispatch(removeLineitemReward(orderId, lineItem?.lineItemId)).then(() => {
        refreshCustomerRewards();
      });
      close();
    },
  });

  return (
    <Col span={24} key={lineItemId}>
      <Title style={TitleStyle}>Service {index + 1}</Title>
      <div style={RowStyle}>
        <Text
          style={{
            paddingRight: 20,
          }}
        >
          {productName}{" "}
          {durationHours !== undefined &&
            durationHours >= 1 &&
            convertMinsToHrsMins(Number(durationHours) * 60)}
          {addOns?.length > 0 && (
            <>
              <>&nbsp;with </>
              {addOns?.length > 1
                ? addOns.reduce(addonsTextReducer, "")
                : addOns?.[0]?.productName}
            </>
          )}
        </Text>

        <Space size="small">
          <Button
            type="text"
            disabled={disabled}
            style={{ padding: 0 }}
            onClick={() => {
              if (isRewardsLoading) {
                openNotificationWithIcon(
                  "error",
                  "Error",
                  "Please wait while we are fetching rewards."
                );
                return;
              }
              setShowManageRewards(true);
            }}
          >
            Manage Rewards
          </Button>
          <Button
            type="text"
            disabled={disabled}
            style={{ padding: 0 }}
            onClick={() => {
              setShowAddUpdateService(lineItem);
            }}
          >
            Change
          </Button>
          {lineItems?.length > 1 && (
            <Button
              type="text"
              style={{ padding: 0, color: "red" }}
              disabled={disabled}
              onClick={() =>
                confirm({
                  title: "Remove Service",
                  content: (
                    <>Are you sure that you want to delete this service?</>
                  ),
                  centered: true,
                  maskClosable: true,
                  onOk: (close) => {
                    dispatch(deleteLineItem(orderId, lineItemId)).then(
                      (result) => {
                        if (result?.status === "success") {
                          close();
                        }
                      }
                    );
                  },
                })
              }
            >
              Delete
            </Button>
          )}
        </Space>
      </div>

      {rewardId && (
        <div
          className="flex alignCenter justifySpaceBetween"
          style={{
            marginBottom: 14,
          }}
        >
          <Space size={"middle"}>
            <Title level={5} className="tradeGothic" style={GreenText}>
              Service Rewards: {getRewardsText(lineItem)}
            </Title>
            {/* <Link onClick={() => setServiceModal(item.lineItemId)} underline disabled={orderId === undefined}>
                Change 
              </Link> */}
          </Space>
          <Link
            disabled={disabled}
            onClick={() => {
              if (isRewardsLoading) {
                openNotificationWithIcon(
                  "error",
                  "Loading Rewards",
                  "Please wait while rewards are loading"
                );
                return;
              }
              Modal.warning(deleteConfirmation(lineItem.lineItemId));
            }}
            underline
            style={{
              color: disabled ? "#b7b7b7" : "#ea5961",
            }}
          >
            Remove
          </Link>
        </div>
      )}
      <FillChartBtn
        chart={chart}
        orderId={orderId}
        lineItemId={lineItemId}
        customer={customer}
      />
      <ManageRewardsPopup
        rewards={rewards ?? []}
        availedRewards={availedRewards}
        visible={showManageRewards}
        onCancel={() => setShowManageRewards(false)}
        lineItem={lineItem}
        orderId={orderId}
        facilityId={facilityId}
        refreshCustomerRewards={refreshCustomerRewards}
        isRewardsLoading={isRewardsLoading}
      />
    </Col>
  );
}

BookingDetailRow.propTypes = {};

export default BookingDetailRow;
