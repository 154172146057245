import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Drawer,
  Form,
  Row,
  Typography,
  Button as AntdButton,
  Upload,
  Space,
  Divider,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import {
  FormInput,
  FormInputArea,
  FormHiddenSubmitButton,
  FormPhoneNumber,
  FormSelect,
  FormCheckbox,
} from "components/FormItems/FlatFormItems";
import { useForm } from "antd/lib/form/Form";
import { Button } from "components/Buttons/Buttons";
import FilesUploader from "components/FilesUploader/FilesUploader";
import PhotoUpload from "components/PhotoUpload/PhotoUpload";
import userDp from "assets/images/user-dp.png";
import { getBoostById } from "services/servicesAndBoostsSettings.service";
import { useDispatch } from "react-redux";
import { FormSwitch } from "components/FormItems/RoundedFormItems";

const { Title, Text } = Typography;
const { Dragger } = Upload;

export default function BoostDrawer({
  visible,
  onClose,
  data,
  onSuccess,
  taxCategories = [],
  accounts = [],
  isAccountsLoading = true,
}) {
  const [form] = useForm();
  const submitBtnRef = useRef();

  const [base64, setBase64] = useState("");
  const [file, setFile] = useState(null);

  const dispatch = useDispatch();

  // const getData = async (id) => {
  //   const result = await dispatch(getBoostById(id));
  //   if (result?.status === "success") {
  //     form.setFieldsValue({
  //       ...result.data,
  //     });
  //     setBase64(result.data.imageBase64String);
  //   }
  // };

  useEffect(() => {
    if (visible === false) {
      form.resetFields();
      setBase64("");
    } else if (data?.productId !== undefined) {
      form.setFieldsValue({
        ...data,
      });
      setBase64(data.url);
      // getData(data?.productId);
    } else {
      setBase64("");
    }
  }, [visible]);

  return (
    <Drawer
      destroyOnClose
      headerStyle={{ border: "none" }}
      className="boostDrawer"
      width={"min(100vw, 420px)"}
      title={
        <div className="drawerTitle">
          <p>
            {visible && (data?.productId !== undefined ? "Edit" : "Create")}{" "}
            Add-on
          </p>
          <CloseOutlined onClick={onClose} />
        </div>
      }
      placement="right"
      onClose={onClose}
      visible={visible}
      closable={false}
      footer={
        <div className="drawerFooter">
          <AntdButton type="text" onClick={onClose}>
            Cancel
          </AntdButton>
          <Button
            rounded
            type={"primary"}
            style={{ height: 50, width: "50%" }}
            onClick={() => submitBtnRef?.current?.click()}
          >
            Save
          </Button>
        </div>
      }
    >
      <Form
        preserve={false}
        layout="vertical"
        form={form}
        requiredMark={false}
        onFinish={(values) => {
          values.productType = 2; // Boost
          onSuccess({
            ...values,
            imageBase64String: base64?.split?.("base64,")?.[1],
            imageExt: file?.type?.split("/")[1],
          });
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
      >
        <Row gutter={[24, 16]}>
          <Col xs={24}>
            <FormInput type="hidden" name="url" />
            {visible && (
              <PhotoUpload
                base64={base64}
                setBase64={setBase64}
                defaultImage={userDp}
                setFile={setFile}
                //   isEdit={currentStaff}
              />
            )}
          </Col>
          <Col xs={24}>
            <FormInput
              variant="underlined"
              name="productName"
              label="Add-on Name"
              placeholder=""
              required={true}
              formItemStyles={{ marginBottom: 0 }}
            />
          </Col>
          <Col xs={24}>
            <FormSelect
              variant="underlined"
              name="productTaxCategoryId"
              label="Tax Category"
              placeholder="Select a tax category"
              formItemStyles={{ marginBottom: 0 }}
              initialValue={[]}
              options={taxCategories}
              // isOptionDisabled={(it) => !it.isActive}
              filterOption={(input, option) => {
                return option?.label
                  ?.toLowerCase?.()
                  .includes?.(input.toLowerCase());
              }}
              renderLabel="taxCategoryName"
              renderValue="taxCategoryId"
              required
            />
          </Col>
          <Col xs={24}>
            <FormSelect
              // mode={"multiple"}
              required
              feedBack={false}
              variant="underlined"
              name="accountId"
              // loading={isFetchingFacilities}
              label="Account"
              renderLabel="accountName"
              renderValue="accountId"
              placeholder={"Select Account"}
              styles={{ border: "none" }}
              // initialValue={currentStaff?.skillIds}
              // formItemStyles={{ marginBottom: 12 }}
              options={accounts}
              // optionLabelProp="label"
              // filterOption={(input, option) => {
              //   return option?.acountName
              //     ?.toLowerCase?.()
              //     .includes?.(input.toLowerCase());
              // }}
              loading={isAccountsLoading}
              // styles={{ background: '#ddd' }}
            />
          </Col>
          <Col xs={24}>
            <FormSwitch
              name="hasPreferenceCard"
              required
              label="Enable Fetch Preference Card"
              initialValue={true}
            />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <FormHiddenSubmitButton submitRef={submitBtnRef} />
        </Row>
      </Form>
    </Drawer>
  );
}
