import React from "react";
import PropTypes from "prop-types";

import { Button, Typography } from "antd";

import { ReactComponent as EditIcon } from "assets/icons/editIco.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/deleteIco.svg";

const { Text } = Typography;

function DataRow({ name = "", onEdit, onDelete }) {
  return (
    <div className="domainRow">
      <Text
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          marginBottom: 0,
          fontSize: 15,
        }}
      >
        {name}
      </Text>
      <div className="domainRowControls">
        {onEdit && (
          <Button
            type="primary"
            shape="circle"
            size="small"
            icon={
              <EditIcon
                style={{
                  height: 20,
                }}
                onClick={onEdit}
              />
            }
          />
        )}
        {onDelete && (
          <Button
            type="primary"
            shape="circle"
            size="small"
            onClick={onDelete}
            icon={
              <DeleteIcon
                style={{
                  height: 17,
                }}
              />
            }
          />
        )}
      </div>
    </div>
  );
}

DataRow.propTypes = {
  name: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default DataRow;
