import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchLots } from "services/inventory.service";

import { Table, Typography, Skeleton, Avatar, Tooltip } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import Spinner from "components/Spinner/Spinner";

import { UserOutlined } from "@ant-design/icons";
import AddonIcon from "assets/icons/addon.png";

const { Text, Title } = Typography;

export default function SubmittedList({
  snapshotData,
  kits,
  productId,
  productName,
  hasPreferenceCard,
  kitNo,
}) {
  if (snapshotData.loading) {
    return (
      <div>
        <Skeleton.Input active block={true} style={{ marginBottom: 12 }} />
        <Skeleton.Input active block={true} style={{ marginBottom: 12 }} />
        <Skeleton.Input active block={true} style={{ marginBottom: 12 }} />
      </div>
    );
  }
  let data = snapshotData.data?.filter((item) => item.comment == productId);

  const columns = [
    {
      title: "SKU",
      key: "inventoryProductId",
      width: 160,
      render: (it) => {
        let product = kits?.data?.inventoryProducts?.find((item) => {
          return item.facilityProductId == it?.facilityProductId;
        });
        return (
          <div
            style={{
              display: "flex",
              gap: 8,
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontSize: 16,
              }}
            >
              {product?.inventoryProductId}
            </Text>
            <CheckCircleOutlined style={{ fontSize: 20, color: "#52c41a" }} />
          </div>
        );
      },
    },
    {
      title: "Description",
      key: "productName",
      render: (it) => {
        let product = kits?.data?.inventoryProducts?.find((item) => {
          return item.facilityProductId == it?.facilityProductId;
        });
        return (
          <div
            style={{
              display: "flex",
              gap: 8,
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontSize: 16,
              }}
            >
              {product?.productName}
            </Text>
          </div>
        );
      },
    },
    {
      title: "Lot",
      key: "lot",
      width: 250,
      render: (it, _, index) => {
        let product = kits?.data?.inventoryProducts?.find((item) => {
          return item.facilityProductId == it?.facilityProductId;
        });
        return <SubmittedLot item={it} product={product} kitNo={kitNo} />;
      },
    },
    // {
    //   title: "Unit",
    //   key: "unit",
    //   width: 80,
    //   render: (it) => {
    //     return 1;
    //   },
    // },
    {
      title: "Qty",
      width: 200,

      render: (it, _, index) => {
        return (
          <div>{Math.abs(it?.qty ?? 0)}</div>
          //   <Incrementor
          //     disabled={isComplete}
          //     onChangeQuantity={(quantity) => onChangeQuantity(index, quantity)}
          //     maxQuantity={it?.maxQuantity}
          //     quantity={it?.quantity}
          //     index={index}
          //     form={form}
          //     keyProp={it?.key}
          //   />
        );
      },
    },
  ];

  return (
    <div>
      <div
        id={`inventorySection-${productId}`}
        className="kitSearch"
        style={
          {
            // borderBottom: "2px solid #f0f0f0",
          }
        }
      >
        <div className="userInfo">
          <Avatar
            size={45}
            icon={<UserOutlined />}
            style={{
              backgroundColor: "#f5f2ef",
            }}
            src={AddonIcon}
          />
          <div className="names">
            <Title>{productName}</Title>
          </div>
        </div>
        <div
          className="controls"
          style={{
            display: "flex",
            gap: 16,
          }}
        >
          {!hasPreferenceCard && (
            <Tooltip title="Fetch preferennce card is disabled from product settings">
              <CloseCircleOutlined style={{ color: "#f5222d", fontSize: 20 }} />
            </Tooltip>
          )}
        </div>
      </div>
      <Table
        tableLayout="auto"
        locale={{
          emptyText: !hasPreferenceCard
            ? "Preference Card Fetch Disabled"
            : snapshotData?.loading
            ? "Loading"
            : "No Items",
        }}
        dataSource={data ?? []} // Replace with data
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
        style={{ marginBottom: 16 }}
      />
    </div>
  );
}

function SubmittedLot({ item, product, kitNo }) {
  const disaptch = useDispatch();

  const [lots, setLots] = useState({
    loading: true,
    data: [],
  });

  useEffect(() => {
    if (kitNo && item.facilityProductId)
      disaptch(fetchLots(kitNo, item.facilityProductId)).then((res) => {
        if (res?.status === "success") {
          setLots((state) => ({
            ...state,
            loading: false,
            data: res?.data,
          }));
        } else {
          setLots((state) => ({
            ...state,
            loading: false,
          }));
        }
      });
  }, [item, kitNo]);

  if (lots?.loading) {
    return <Spinner loading={true} />;
  }

  let foundLot = lots?.data?.find((lot) => {
    if (lot?.inventoryId == item.inventoryId) return true;
    return false;
  });

  return <div>{foundLot?.lotNumber}</div>;
}
