import React, { useEffect, useRef, useState } from "react";
import "./Inventory.scss";
import InventoryKitSearch from "./component/InventoryKitSearch";
import { Alert, Typography, Button as AntdButton, Avatar, Form } from "antd";
import ProductsList from "./component/ProductsList";
import { useDispatch, useSelector } from "react-redux";
import { completeOrder, getBookingDetails } from "services/booking.service";
import { setCurrentBooking } from "redux/actions/booking.actions";
import InventoryKits from "./InventoryKits";
import { useLocation, useParams } from "react-router";
import EventDrawer from "components/EventDrawer/EventDrawer";
import { Button } from "components/Buttons/Buttons";
import queryString from "query-string";
import { UserOutlined } from "@ant-design/icons";
import { set } from "lodash";
import { sub } from "date-fns";
import moment from "moment";
import { openNotificationWithIcon } from "utils/Notification";
import {
  getSnapshotInfo,
  updateInventoryProduct,
} from "services/inventory.service";
import { setLoading } from "redux/actions/app.actions";

const { Title, Text } = Typography;

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default function Inventory() {
  const [showEventDrawer, setShowEventDrawer] = useState({
    visible: false,
    openPaymentDrawerDirectly: false,
  });
  const [searchKitFlag, setSearchKitsFlag] = useState({
    flag: false,
    kitNo: null,
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [snapshotData, setSnapshotData] = useState({
    loading: true,
    data: [],
  });

  const [booking, setBooking] = useState();

  const dispatch = useDispatch();

  const location = useLocation();
  const params = useParams();
  const query = queryString.parse(location.search);

  const [forms, setForms] = useState({});

  let submissionData = useRef({});
  let formsRef = useRef({});

  const loadData = async () => {
    dispatch(
      getBookingDetails(params.orderId, async (booking) => {
        setBooking(booking);
        dispatch(setCurrentBooking(booking));

        if (booking?.isInventorySubmitted) {
          setSnapshotData({
            loading: true,
            data: [],
          });
          dispatch(setLoading(true));
          let snapshotResponse = null;
          if (booking?.inventoryKitNo)
            snapshotResponse = await dispatch(getSnapshotInfo(params.orderId));
          if (snapshotResponse?.status === "success") {
            setSnapshotData({
              loading: false,
              data: snapshotResponse.data,
            });
          } else {
            setSnapshotData({
              loading: false,
              data: [],
            });
          }
        }

        let data = {};
        let productForms = {};
        let product = booking?.lineItems?.[0];
        data[product?.productId || ""] = null;
        productForms[product?.productId || ""] = null;

        for (let addOn of product?.addOns || []) {
          data[addOn?.productId || ""] = null;
        }
        submissionData.current = data;
        formsRef.current = productForms;
      })
    );
  };

  async function submitData() {
    dispatch(setLoading(true));
    await delay(2500);

    let data = [];
    for (let [productId, inventory] of Object.entries(submissionData.current)) {
      for (let item of inventory || []) {
        item.comment = productId;
      }
      data = data.concat(inventory ?? []);
    }

    dispatch(
      updateInventoryProduct(booking?.orderId, searchKitFlag?.kitNo, data)
    ).then((res) => {
      if (res?.status === "success") {
        setIsSubmitted(true);
        dispatch(
          setCurrentBooking({
            ...booking,
            isInventorySubmitted: true,
          })
        );
        setShowEventDrawer({
          visible: true,
          openPaymentDrawerDirectly: true,
        });
      }
    });
  }
  useEffect(() => {
    // GET ORDER BY ID
    loadData();
    // if order is completed
    // SHOW ORDER HAS BEEN COMPLETED
    // else
  }, []);

  function updateSubmissionKit(productId, productData) {
    if (submissionData.current) {
      submissionData.current[productId] = productData;
    }
  }

  const isOrderCompleted = booking?.orderStatus === 3;
  const isInventorySubmitted = booking?.isInventorySubmitted || isSubmitted;
  const addOns = booking?.lineItems?.[0]?.addOns;

  const getKitsInfo = () => {};

  if (!booking) {
    return <></>;
  }

  return (
    <div className="inventory">
      <div className="head">
        <h2
          style={{
            color: "#15213B",
            fontFamily: "tradeGothicBold",
            margin: 0,
          }}
        >
          Inventory Management
        </h2>
        <AntdButton
          type="text"
          onClick={() => {
            dispatch(setCurrentBooking(booking));
            setShowEventDrawer({
              visible: true,
              openPaymentDrawerDirectly: false,
            });
          }}
        >
          Order Details
        </AntdButton>
      </div>
      <div className="body">
        {isOrderCompleted && (
          <Alert
            style={{
              marginBottom: 20,
            }}
            type="success"
            message="This order is completed."
          />
        )}
        {!!booking?.orderId && (
          <div>
            <InventoryHead
              booking={booking}
              isOrderComplete={isOrderCompleted}
              onSubmit={(kitNo) => {
                submissionData.current = {};
                setSearchKitsFlag({
                  flag: true,
                  kitNo,
                });
              }}
              searchKitFlag={searchKitFlag}
              setSearchKitsFlag={setSearchKitsFlag}
            />
          </div>
        )}
        <Form.Provider
          onFormFinish={(name) => {
            console.log("form submit", name);
          }}
        >
          {booking && (
            <InventoryKits
              ref={formsRef}
              isAddon={false}
              customerName={booking?.customerFullName}
              orderId={booking?.orderId}
              productId={booking?.lineItems?.[0]?.productId}
              productName={booking?.lineItems?.[0]?.productName}
              hasPreferenceCard={booking?.lineItems?.[0]?.hasPreferenceCard}
              isOrderComplete={isOrderCompleted}
              updateSubmissionKit={updateSubmissionKit}
              searchKitFlag={searchKitFlag?.flag}
              kitNo={searchKitFlag?.kitNo}
              isInventorySubmitted={isInventorySubmitted}
              snapshotData={snapshotData}
              isViewOnly={booking?.inventoryKitNo}
            />
          )}

          {addOns?.length > 0 && (
            <Title
              style={{
                fontSize: 19,
                color: "#000",
              }}
            >
              Add-ons
            </Title>
          )}

          {addOns?.map((addon) => {
            return (
              <InventoryKits
                ref={formsRef}
                isAddon={true}
                customerName={booking?.customerFullName}
                orderId={booking?.orderId}
                productId={addon?.productId}
                productName={addon?.productName}
                hasPreferenceCard={addon?.hasPreferenceCard}
                isOrderComplete={isOrderCompleted}
                updateSubmissionKit={updateSubmissionKit}
                searchKitFlag={searchKitFlag?.flag}
                kitNo={searchKitFlag?.kitNo}
                isInventorySubmitted={isInventorySubmitted}
                snapshotData={snapshotData}
                isViewOnly={booking?.inventoryKitNo}
              />
            );
          })}
        </Form.Provider>
        <br />
        <div
          style={{
            display: "flex",
            gap: 16,
          }}
        >
          <Button
            rounded={true}
            disabled={isInventorySubmitted}
            onClick={async () => {
              if (!formsRef.current) return;
              let hasError = false;
              for (let [productId, form] of Object.entries(formsRef.current)) {
                if (form?.current) {
                  let result = await form.current
                    .validateFields()
                    .then(() => {
                      form.current.submit();
                    })
                    .catch(({ errorFields }) => {
                      form.current.scrollToField(errorFields[0].name, {
                        scrollMode: "if-needed",
                        block: "center",
                        behavior: "smooth",
                      });
                      return errorFields;
                    });

                  if (result) {
                    hasError = true;
                    break;
                  }
                }
              }

              if (hasError) return;
              submitData();
            }}
          >
            {isInventorySubmitted ? "Submitted" : "Submit"}
          </Button>
          <Button
            disabled={isOrderCompleted || !isInventorySubmitted}
            onClick={async () => {
              dispatch(
                setCurrentBooking({
                  ...booking,
                })
              );
              setShowEventDrawer({
                visible: true,
                openPaymentDrawerDirectly: true,
              });
            }}
            // disable={isFetched}
            htmlType="submit"
            rounded={true}

            // disabled={isComplete}
          >
            Pay
          </Button>
        </div>
      </div>

      <EventDrawer
        inventoryLinks={false}
        visible={showEventDrawer?.visible}
        openPaymentDrawerDirectly={showEventDrawer?.openPaymentDrawerDirectly}
        setShowEventDrawer={(visible) =>
          setShowEventDrawer({
            visible,
            openPaymentDrawerDirectly: false,
          })
        }
        getData={loadData}
        onClose={() => setShowEventDrawer(false)}
      />
    </div>
  );
}

function BookingInfo({ booking }) {
  return (
    <div className="kitSearch" style={{ borderBottom: 0 }}>
      <div className="userInfo">
        <Avatar
          size={45}
          icon={<UserOutlined />}
          style={{
            backgroundColor: "#f5f2ef",
          }}
        />
        <div className="names">
          <Title>{booking?.customerFullName}</Title>
          {<Text>{booking?.orderId}</Text>}
        </div>
      </div>
    </div>
  );
}

function InventoryHead({
  booking,
  isOrderComplete,
  onSubmit,
  searchKitFlag,
  setSearchKitsFlag,
}) {
  let { customerFullName, orderId } = booking;
  return (
    <div
      style={{
        borderBottom: "2px solid #f0f0f0",
        marginBottom: 16,
      }}
    >
      <InventoryKitSearch
        booking={booking}
        isComplete={isOrderComplete}
        customerName={customerFullName}
        orderId={orderId}
        onSubmit={onSubmit}
        isFetched={searchKitFlag?.flag}
        onKitNumberChange={() => {
          setSearchKitsFlag((state) => ({
            ...state,
            flag: false,
          }));
        }}
        // onSubmit={getKitsInfo}
      />
    </div>
  );
}
