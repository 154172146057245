import { cloneDeep } from "lodash";
import moment from "moment";
import { setLoading } from "redux/actions/app.actions";
import { openNotificationWithIcon } from "utils/Notification";
import {
  DUMMY_CATEGORIES,
  DUMMY_SERVICES,
  DUMMY_SERVICE_TYPES,
  setCategories,
  setServiceTypes,
} from "./data";
import { request } from "./verb.services";

export const getAllServiceTypes = (callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return new Promise((resolve, reject) => {
      // todo: use callback
      setTimeout(
        () =>
          resolve({
            status: "success",
            data: cloneDeep(DUMMY_SERVICE_TYPES),
          }),
        250
      );
    })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getServiceTypeById = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return new Promise((resolve, reject) => {
      const record = DUMMY_SERVICE_TYPES.find(
        (iterator) => iterator.serviceTypeId === id
      );
      // todo: use callback
      setTimeout(() => resolve({ status: "success", data: record }), 250);
    })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const createServiceType = (values, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return new Promise((resolve, reject) => {
      values.serviceTypeId = DUMMY_SERVICE_TYPES?.length + 1;
      values.dateCreated = moment().format(`YYYY-MM-DD`);
      values.dateUpdated = moment().format(`YYYY-MM-DD`);
      DUMMY_SERVICE_TYPES.push(values);
      setTimeout(() => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Service type created successfully"
        );
        resolve({ status: "success", data: values });
      }, 250);
    })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const deleteServiceType = (id, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return new Promise((resolve, reject) => {
      const DATA = DUMMY_SERVICE_TYPES.filter((iterator) => {
        return iterator.serviceTypeId !== id;
      });
      setServiceTypes(DATA);

      setTimeout(() => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Service type deleted successfully"
        );
        callback?.();
        resolve({ status: "success" });
      }, 250);
    })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const updateServiceType = (id, data, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return new Promise((resolve, reject) => {
      const DATA = DUMMY_SERVICE_TYPES.map((iterator) => {
        if (iterator.serviceTypeId === id)
          return {
            ...iterator,
            dateUpdated: moment().format(),
            ...data,
          };
        return iterator;
      });

      setServiceTypes(DATA);

      setTimeout(() => {
        openNotificationWithIcon(
          "success",
          "Success",
          "Service type updated successfully"
        );
        callback?.();
        resolve({ status: "success" });
      }, 250);
    })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
